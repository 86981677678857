import moment from "moment";
import avatars from "../model/avatars";
import musicPlayer from "./Audio/MusicPlayer";
import soundFxPlayer from "./Audio/SoundFxPlayer";
import { acceptedLang } from "./Helpers/AcceptedLangs";
import { removeEmpty, sliceObject, sortObjectByKeyDesc } from "./Helpers/ObjectHelper";
import { calcPercentage } from "./Helpers/PercentageHelper";
import generateRandomInteger from "./Helpers/RandNumbersRange";
import { isNumeric, showerMessageHelper } from "./Helpers/StringHelper";
import { filterIsolatedBets } from "./IsolatedBets/isolatedBets";
import storage from "./Storage/Storage";
import { storageEnabled } from "./StorageEnabled";
import translate, { languageReinit } from "./Translation/lang";
import TransportAbstract from "./TransportAbstract";
import advancedAutoPlayStateHandler from "./AdvancedAutoPlay/AdvancedAutoPlayStateHandler";
import R7RGSWindowBridge from "./WindowBridge/R7-RGS-WindowBridge";
import { acceptedRegion } from "./Helpers/Acceptedregions";
import showMessageNow from "./MeteorShowerChecker/ShowerChecker";

const MAINTANCE_FILE = '/maintenance.txt'
const MY_BETS_LIST_LIMIT = 102;
const MULTIPLIER_HISTORY_LIMIT = 205;
const MY_CHAT_MSGS_LIMIT = 50;
const CASHOUTS_LIMIT = 2;
const REQ_BALANCE_INTERVAL_TIME = 30000; // 30 sec
const DELAY_SHOWER_MESSAGE_TIME = 2000;
// const SERVER_ERROR_LOG = "https://log.elbet.com";
const NOTIFICATION = {
  FREETICKET: 'FREETICKET',
  PROMOCREDIT: 'PROMOCREDIT',
  JACKPOTWINNER: 'JACKPOTWINNER',
  SOMEONEWONJACKPOT: 'SOMEONEWONJACKPOT',
  TOURNAMENTAWARD: 'TOURNAMENTAWARD',
}

Object.freeze(NOTIFICATION);

var reqBalanceInterval,reqCancelCashoutTimeout;

class RocketmanTransport extends TransportAbstract {

  /**
   * Singleton RocketmanTransport
   * 
   * @param {*} natsServerOrigin 
   * @param {*} uid 
   * @param {*} serverKeepaliveTimeout 
   * @param {*} secure_conn 
   * @returns 
   */
  constructor(natsServerOrigin, uid, serverKeepaliveTimeout, secure_conn = true, r7RgsWindowBridge) {
    super(natsServerOrigin, uid, serverKeepaliveTimeout, secure_conn);    
    if (RocketmanTransport.instance == null) { 
      this.data = {};
      this.gameStarted = false;
      this.randLengthMultiplier = false;
      this.myCurrency = "";
      this.uncreditedWins = 0;
      this.countryCode = "";
      this.historyTabIndex = 0;
      this.maxAutoPlayGames = 100;
      this.username = "";
      this.chatFocused = false;
      this.changeUsernameFocused = false;
      this.once = false;
      this.zeroOnce = false;
      this.onceFillHistory = true;
      this.currRound = 0;
      this.totalFreeTicketsWinAmount = 0;
      this.hadFreeTickets = false;
      this.currentFreeTicketsCount = 0;
      this.myBetsByRound = [];
      this.onceTriggerRoundCounter = true;
      this.onceTriggerRoundCounterOff = true;
      this.firstLoad = true;
      this.userIsVip = 0;
      this.animationLoaded = false;
      this.rocketmanRTP = 0;
      this.maintenenceMode = false;
      this.turnamentAnnnouncedisLeaked = false;
      this.button0AutobetDelay = false;
      this.button1AutobetDelay = false;
      this.weeklyTopWins = [];
      this.monthlyTopWins = [];
      this.alreadyInitated = false;
      this.showMainScreen = undefined;
      this.showRetailScreen = undefined;
      this.mobileCloseButton = false;
      this.singleBet = false;
      this.removeJackpotRules = false;
      this.yearlyTopWins = [];
      this.weeklyTopOdds = [];
      this.goToMaintenceMode = storage.get('goToMaintenceMode') || null;
      this.showBonusHeader = false;
      this.playFreeBets = false;
      this.freeTicketCount = 0;
      this.freeTicketsLength = 0;
      this.jackpotValue = 0;
      this.freeTicketMoney = 0;
      this.hasCashedOut = false;
      this.updateChat = false;
      this.setMessagesOnce = false;
      this.initialyLoadedChat = false;
      this.messagesCounter = 0;
      this.animation = true;
      this.lastCancelTime = null;
      this.desktopResolution = true;
      this.clickedStartPlayFreeBets = false;
      this.lastCashoutTime = null;
      this.lastBalanceRequestTime = new Date().getTime();
      this.isolated = false;
      this.isolatedList = [];
      this.minBet = 30;
      this.maxBet = 500;
      this.maxWin = 100;
      this.defBet = 30;
      this.predefinedBets = [];
      this.fixedBet = [];
      this.buttons = {
        0: null,
        1: null
      }
      this.avatarId = 0;
      this.autoBetStartRound = {
        0 : null,
        1 : null
      }
      this.balance = 0;
      this.promoBalance = 0;
      this.monthlyTopOdds = [];
      this.yearlyTopOdds = [];
      this.weeklyBiggestOdds = [];
      this.monthlyBiggestOdds = [];
      this.yearlyBiggestOdds = [];
      this.myBetsList = [];
      this.cashouts = [];
      this.cashoutTick = false;
      this.sound = false;
      this.music = false;
      this.tempSound = false;
      this.tempMusic = false;
      this.myTicketIdentifier = "";
      this.autoBet = {
        0: false,
        1: false
      };
      this.tempBet = {};
      this.tempCancel = {};
      this.tempCashout = {};
      this.onceSnapFlag = true;
      this.multipliersHistory = {};
      this.allPlayersBetsByRound = [];
      this.player_id = null;

      //new launcher params
      this.launcherFullScreen = null;
      this.launcherAutoBet = null;
      this.launcherAutCashout = null;
      this.launcherBackUrl = null;
      this.launcherChatRoom = null;
      this.launcherLanguage = null;
      this.launcherHideHeader = null;
      this.tournamentStatus = 1;
      this.launcherCountryCode = null;
      this.demoAutoBet = true;
      this.launcherCurrency = null; //
      this.launcherUid = null;
      this.launcherCompanyId = null;
      this.launcherCrypto = null;
      this.launcherShowHelp = null;
      this.launcherToken = null; //
      this.launcherVersion = null;
      this.loggedIn = false;
      this.platformId = null;
      this.tournamentStartTime = null;
      this.roundCalledTournamentInfo = 1;
      this.tournamentEndTime = null;
      this.tournamentInfoCurrency = "";
      this.tournamentDescription = "";
      this.retailTicketsArr = [];
      this.tournamentName = "";
      this.turnamentResultsInprogressCalledBefore = false;
      this.tournamentInfoPlaceAwards = [];
      this.tournamentHistory = [];
      this.currentLeaderboard = [];
      this.retail = (uid.retail === "true" || uid.retail === '1' ) ? true : false;
      this.isChatVisible = true;
      this.isFlagEnabled = true;
      this.usernamePopup = true;
      this.isCurrencyVisible = true;
      this.cancelButtonDisabled = false;
      this.amountFormat = "";
      this.showAdvancedAutoplay = false;
      this.showInsurancePromo = false;
      this.advancedAutoplayInProgress = {
        0: {
          inProgress: false
        },
        1: {
          inProgress: false
        }
      };
      this.advancedAutoPlayOnWinPercent = {
        0: {
          percent: 0
        },
        1: {
          percent: 0
        }
      };
      this.advancedAutoPlayOnLossPercent = {
        0: {
          percent: 0
        },
        1: {
          percent: 0
        }
      };
      this.advancedAutoPlayTotalBetsValue = {
        0: {
          val: ''
        },
        1: {
          val:''
        }
      };
      this.advancedAutoPlayProfitValue = {
        0: {
          val: 0
        },
        1: {
          val:0
        }
      };
      this.advancedAutoPlayLossValue = {
        0: {
          val: 0
        },
        1: {
          val:0
        }
      };
      this.advancedAutoPlayStopOnProfitValue = {
        0: {
          val: 0
        },
        1: {
          val:0
        }
      };
      this.advancedAutoPlayStopOnLossValue = {
        0: {
          val: 0
        },
        1: {
          val:0
        }
      }
      this.advancedRoundsPlayed = {
        0: {
          played: 0
        },
        1: {
          played:0
        }
      }
      this.advancedBalanceDiff = {
        0: {
          balance: 0
        },
        1: {
          balance:0
        }
      }
      this.advancedOriginalBet = {
        0 : {
          bet: 0
        },
        1 : {
          bet: 0
        }
      }
      this.tournamentStartTime = null;
      this.roundCalledTournamentInfo = 1;
      this.tournamentEndTime = null;
      this.tournamentInfoCurrency = "";
      this.tournamentDescription = "";
      this.tournamentName = "";
      this.turnamentResultsInprogressCalledBefore = false;
      this.tournamentInfoPlaceAwards = [];
      this.tournamentHistory = [];
      this.currentLeaderboard = [];
      this.r7RgsWindowBridge = r7RgsWindowBridge;
      this.r7css = false;
      this.betLimit = false;
      this.meteor_shower_active = false;
      this.meteor_shower_max_amount_per_player = 100;
      this.meteor_shower_max_players = 10;
      this.useInsurance = false;
      this.setPromoCreditNotif = false;
      this.setShowFreeBetsNotif = false;
      this.branding = '';
      RocketmanTransport.instance = this;

      window.addEventListener('online', () => {
        this.reinitButtons();
      });
    }
    
    return RocketmanTransport.instance;
  }

  async login(data) {
    console.log("LOGIN", data);
    console.log("Version: v2.3.5");
    //new launcher params
    let tempLang;
    if (data?.launcher_params?.language) {
      tempLang = data?.launcher_params?.language;
    } else {
      tempLang = acceptedLang(urlParams.get('language') || urlParams.get('lang')) ? urlParams.get('language') || urlParams.get('lang') : "en" ;
    }
    if (tempLang === 'rs') {
      tempLang = 'sr';
    } 

    // const slides = ['rocketman','game_explanation','max_multiplier','bonus_launch','default']
    // let currentSlideIndex = 0;

    // setInterval(() => {
    //   window.setShowScreen(slides[currentSlideIndex])
    //   currentSlideIndex = (currentSlideIndex + 1) % slides.length;
    // }, 6000)

    
    //storage.remove('freeBetNotifActive');

    this.userIsVip = data?.player_data?.is_vip;
    this.player_id = data?.player_data?.id;
    this.meteor_shower_active = data?.player_data?.meteor_shower_active;
    this.meteor_shower_max_amount_per_player = data?.player_data?.meteor_shower_max_amount_per_player;
    this.meteor_shower_max_players = data?.player_data?.meteor_shower_max_players;

    window.setIsVip(this.userIsVip);

    this.rocketmanRTP = data?.rocketman_rtp;
    
    if (this.userIsVip) {
      window.setIsVipModal(this.userIsVip);
    }
    // TO DO CHECK IN lancherParams for exchange rate data
    this.myExchangeRate = data?.player_data?.currency_exchange_rate || data?.currency_exchange_rate;
    this.platformId = data?.player_data?.platform_id;
    this.loggedIn = true;

    this.launcherFullScreen = data?.launcher_params?.fullscreen || urlParams.get('fullScreen');
    if (data?.open_tickets?.length) {
      this.launcherAutoBet = false;
    } else {
      this.launcherAutoBet =  data?.launcher_params?.auto_bet|| urlParams.get('autobet');
    }
    this.launcherAutCashout = data?.launcher_params?.auto_cashout || urlParams.get('autocashout');
    this.launcherBackUrl = data?.launcher_params?.back_url || urlParams.get('backUrl');
    this.launcherChatRoom = data?.launcher_params?.chat_room || urlParams.get('chatRoom');
    this.launcherLanguage = tempLang.toLowerCase();
    this.launcherHideHeader = data?.launcher_params?.hide_header || urlParams.get('hideHeader');
    this.countryCode = data?.launcher_params?.countryCode || urlParams.get('countryCode');
    this.myCurrency = data?.launcher_params?.currency ? data.launcher_params.currency : data?.player_data?.currency_code;
    this.launcherUid = data?.launcher_params?.uid;
    this.launcherCompanyId = data?.launcher_params?.companyId || urlParams.get('companyId');
    this.launcherCrypto = data?.launcher_params?.crypto || urlParams.get('crypto');
    this.launcherShowHelp = data?.launcher_params?.show_help || urlParams.get('showHelp');
    this.launcherToken = data?.launcher_params?.token || urlParams.get('token'); //
    this.launcherVersion = data?.launcher_params?.version || urlParams.get('version'); //

    this.checkConfig(data?.customization);
    // FULLSCREEN ( fullScreen) U PLAYNOW SUBCOMPONENT SEARCH FOR IT
    languageReinit(this.launcherLanguage);
    window.setFullScreen(this.launcherFullScreen);
    try {
      window.setCurrUserMsgLang(this.launcherLanguage);
      window.setMyBetsLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }
    try {
      window.setUserMsgLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setAllBetsTabLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }
    try {
      window.setPreviousBetsTabLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setTopOddsTabLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setChatLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setHomeLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }

    try {
      window.setBottomLang(this.launcherLanguage);
    } catch (error) {
      //console.log("ERROR ", error)
    }
    if (!this.retail) {
      this.maintenenceMode = await this.checkMaintenceMode();
      if (this.maintenenceMode) {
        window.setDissalowBets0(true);
        window.setDissalowBets1(true);
      } else {
        window.setDissalowBets0(false);
        window.setDissalowBets1(false);
        storage.remove('goToMaintenceMode');
        this.goToMaintenceMode = null;
      }
      if (data?.player_data?.new_player) {
        window.setShowHelp(true);
      } else {
                // if(!storage.get('isAdvancedPromo') && storage.get('showUsername')) {
        //   this.setShowAdvancedAutoplayPromo(true);
        //   storage.set('isAdvancedPromo', true);
        // } 
        if(!storage.get('isInsurancePromo') && storage.get('showUsername') && this.useInsurance === true) {
          this.setShowInsurancePromo(true);
          storage.set('isInsurancePromo', true);
        }
      }
    }
    
    this.noOfDecimals = Boolean(this.launcherCrypto) === true ? 4 : this.amountFormat !== "" && !/\./.test(this.amountFormat) ? 0 : 2;
    this.isolated = data.isolated;
    this.isolatedList = data?.isolated_list?.split(',');
    
    // if (!this.playFreeBets && (data.free_tickets_count > 0)) {
    //   window.setShowFreeBetsNotif(true);
    //   storage.set('freeBetNotifActive', true);
    //   window.setFreeBetsNotifParams({
    //     betCount : data.free_tickets_count,
    //     betMoney: data.free_tickets_money,
    //     currency: this.myCurrency
    //   })
    // }

    // SET DEFAULT BET VALUES
    this.defBet = data?.player_data?.company_currency_data?.default_bet || this.defBet;
    this.maxBet = data?.player_data?.company_currency_data?.max_bet || this.maxBet;
    this.minBet = data?.player_data?.company_currency_data?.min_bet || this.minBet;
    this.maxWin = data?.player_data?.company_currency_data?.max_win || this.maxWin;
    this.predefinedBets = data?.player_data?.company_currency_data?.prefined_bets || this.predefinedBets;
    if (!this.retail) {
      // SET IN BUTTON 0
      window.setMin0(this.minBet);
      window.setMax0(this.maxBet);
      window.setMin1(this.minBet);
      window.setMax1(this.maxBet);
      window.setButton0Params({
        autoBet: Boolean(this.launcherAutoBet) ||  false,
        autoCashout: this.launcherAutCashout ||  "",
        ticketId: null,
        roundId: null,
        val: (storage.get('lastBetValue0') && storage.get('lastBetCurrency') === rocketmanTransport.myCurrency) ? storage.get('lastBetValue0') : this.defBet.toFixed(this.noOfDecimals),
        buttonBusy: false,
        gameState: 'initialState'
      });
      window.setButton1Params({
        autoBet: false,
        autoCashout: "",
        ticketId: null,
        roundId: null,
        val: (storage.get('lastBetValue1') && storage.get('lastBetCurrency') === rocketmanTransport.myCurrency) ? storage.get('lastBetValue1') : this.defBet.toFixed(this.noOfDecimals),
        buttonBusy: false,
        gameState: 'initialState'
      });
      window.setPredefinedBets0(this.predefinedBets);
      window.setPredefinedBets1(this.predefinedBets);

      //SET FREE BETS
      this.setFreeTickets(data);

      this.myTicketIdentifier = `${data?.player_data?.username} (${data?.player_data?.company})`;
      this.username = data?.player_data?.username || "";

      try {
        window.setChatUsername(this.username);
      } catch (error) { 
        //
      }

      window.setUsername(this.username);
      this.avatarId = data?.player_data?.avatar_id || 0;
      window.setAvatarId(this.avatarId);

      this.sound = Boolean(data?.player_data?.sound_enabled);
      this.music = Boolean(data?.player_data?.music_enabled);
      
      this.tempSound = this.sound;
      this.tempMusic = this.music;
      if (this.music) {
        this.playGameMusic();
      }

      this.printUserBalance(data?.player_data?.balance)

      this.balance = data?.player_data?.balance || 0;

      try {
        window.setButton0Balance(this.balance);
        window.setButton1Balance(this.balance);
      } catch (error) {
        console.log("CANNOT SET BUTTON BALANCE")
      }

      [0,1].forEach((buttonIndex) => {
        advancedAutoPlayStateHandler.handleBalance(buttonIndex,this.advancedAutoplayInProgress[buttonIndex].inProgress,this.balance)
      });

      this.promoBalance = data?.player_data?.promobalance;
      window.setPromoBalance(this.promoBalance);
      window.setBalance(this.balance);

      if (this.promoBalance > 0) {
        window.setPromoCreditVal(this.promoBalance);
        // if (this.promoBalance >= this.minBet) {
        //   window.setPromoCreditNotif(true);
        // }
      }

      // SET UNCREDITED BALANCE 
      this.uncreditedWins = 0;
      if (data?.player_data?.bonus_free_tickets_currency_code && data?.player_data?.bonus_free_tickets_currency_code == this.myCurrency) {
        this.uncreditedWins += data.player_data.bonus_free_tickets_money;
      }

      if (data?.player_data?.bonus_promo_currency_code && data?.player_data?.bonus_promo_currency_code == this.myCurrency) {
        this.uncreditedWins += data.player_data.bonus_promo_money;
      }
      window.setUncreditedGlobalWins(this.uncreditedWins);

      
      // set open tickets
      if (data?.open_tickets?.length) {
        const myBetsFromStorage = storage.get('myBetsByRound');
        data.open_tickets = data.open_tickets.filter(({currency_code}) => String(currency_code) == String(this.myCurrency));
        data.open_tickets.forEach((openTicket) => {
          openTicket.buttonIndex = openTicket.bet_button_id - 1;
          openTicket.autoBbet = false;
          // if (openTicket.ticket_id === storage.get(`insurance-played-${openTicket.ticket_id}`)) {
          //   openTicket.betInsurance = true;
          // }
          if (openTicket.ticket_type === 3) {
            this.playFreeBets = true;
          }
          const foundInStorage = myBetsFromStorage ? myBetsFromStorage.find(storageBet => String(storageBet.ticket_id) === String(openTicket.ticket_id) 
            && String(storageBet.currency_code) == String(this.myCurrency)) : null;
          this.myBetsByRound.push(foundInStorage || openTicket);
        });
        window.setMyBetsByRound(this.myBetsByRound);
        await this.isCurrRoundActive();
        this.setLoginOpenTickets();
        storage.set('myBetsByRound',this.myBetsByRound);
        try {
          window.setShowStartButton(!this.playFreeBets);
          window.setShowStartButtonMobile(!this.playFreeBets);
        } catch (error) {
          this.localErrors(error);
          console.error("ERROR is:", error);
        }
      }

      // Add current and next round tickets
      // console.log("existing_current_and_next_round_tickets", data.existing_current_and_next_round_tickets)

      if (data?.existing_current_and_next_round_tickets && Object.keys(data?.existing_current_and_next_round_tickets)?.length) {
        Object.keys(data.existing_current_and_next_round_tickets).forEach(ticket => {
          const ticketToInsert = data.existing_current_and_next_round_tickets[ticket]
          ticketToInsert.ticket_id = ticket;
          ticketToInsert.bet = data.existing_current_and_next_round_tickets[ticket].currency_code == this.myCurrency ? data.existing_current_and_next_round_tickets[ticket].bet : Number(data.existing_current_and_next_round_tickets[ticket].bet_eur) * Number(this.myExchangeRate);
          ticketToInsert.win = data.existing_current_and_next_round_tickets[ticket].currency_code == this.myCurrency ? data.existing_current_and_next_round_tickets[ticket].win : Number(data.existing_current_and_next_round_tickets[ticket].win_eur) * Number(this.myExchangeRate);
          this.allPlayersBetsByRound[ticket] = ticketToInsert;
        });
        this.allPlayersBetsByRound = removeEmpty(this.allPlayersBetsByRound);
        window.setAllPlayerBets(this.allPlayersBetsByRound);
      }

      // Add ticket history
      data?.player_ticket_history?.forEach(historyTicket => {
        let ticket = historyTicket;
        //ticket.created_time = moment(historyTicket.created_time).format("DD MMM");
        ticket.bet_amount = Number(historyTicket.bet_eur) * Number(this.myExchangeRate);
        ticket.player_bet = historyTicket.currency_code == this.myCurrency ? historyTicket.bet : Number(historyTicket.bet_eur) * Number(this.myExchangeRate);
        ticket.win = historyTicket.currency_code == this.myCurrency ? historyTicket.win : Number(historyTicket.win_eur) * Number(this.myExchangeRate);
        this.myBetsList.push(ticket);
      });
      this.myBetsList = this.myBetsList.reverse();
      try {
        window.setMyBetsList(this.myBetsList);
      } catch (error) {
      //
      }

      if (!storageEnabled()) this.localErrors("PLEASE ENABLE COOKIES FOR FULL GAME FEATURES!")

      this.maintenenceMode = await this.checkMaintenceMode();

      if (this.maintenenceMode) {
        window.setDissalowBets0(true);
        window.setDissalowBets1(true);
      } else {
        window.setDissalowBets0(false);
        window.setDissalowBets1(false);
        storage.remove('goToMaintenceMode');
        this.goToMaintenceMode = null;
      }
      if (data?.player_data?.new_player) {
        window.setShowHelp(true);
      }

      // Call torunament info onload and every fift round
      this.roundCalledTournamentInfo = 1;
      this.getTournamentInfoInAbstract();

      //this.getTournamentResultsInProgress();
      this.getPlayerTournamentsHistoryInAbstract();

      reqBalanceInterval = setInterval(() => {
        if (new Date().getTime() - this.lastBalanceRequestTime > REQ_BALANCE_INTERVAL_TIME) {
          this.requestUserBalanceInAbstract();
        }
      },[REQ_BALANCE_INTERVAL_TIME]);

      this.r7RgsWindowBridge.gameLoaded(this.balance)
    }

    // Add multipliers history 
    if (data?.multiplier_history?.length) {
      let tempMpHistory = {};
      try {
        for(let elem in data.multiplier_history) {
          tempMpHistory[Object.keys(data.multiplier_history[elem])[0]] = Object.values(data.multiplier_history[elem])[0];
        }
      } catch (error) {
        console.error("ERROR GETTING HISTORY MULTIPLIERS");
      }
      
      this.multipliersHistory = sortObjectByKeyDesc(tempMpHistory);
      try {
        window.setMultipliersHistory(Object.values(this.multipliersHistory));
        window.setRetailMultipliersHistory(Object.values(this.multipliersHistory));
      } catch (error) {
        //
      }
    }
    
  }

  requestUserBalanceInAbstract() {
    if (!this.retail) {
      this.requestUserBalance();
    }
  }

  getTournamentInfoInAbstract() {
    if (!this.retail) {
      this.getTournamentInfo();
    }
  }

  getPlayerTournamentsHistoryInAbstract() {
    if (!this.retail) {
      this.getPlayerTournamentsHistory();
    }
  }

  checkConfig(data) {
    // REATIL
    if (this.retail === true) {
      this.isChatVisible = false;
    }
    //console.log("non paresd data", data)
    if(!data) {
      return
    }
    let parserdData;
    try {
      parserdData = JSON.parse(data);
    } catch (error) {
      console.error("COULD NOT parse config")
    }
    // parserdData.isCurrencyVisible = false;
    // parserdData.amountFormat = "###,###.00";
    // parserdData.feEvents = true;
    // parserdData.cancelButtonDisabled = true;
    // parserdData.branding = "BetConstruct";
    if (typeof parserdData === 'object') {
      //console.log("DATA",parserdData)
      // TBI
      // CHAT
      if ('isChatVisible' in parserdData) {
        this.isChatVisible = parserdData.isChatVisible;
      }
      if (this.retail === true) {
        this.isChatVisible = false;
      }
      // FLAGS
      if ('isFlagEnabled' in parserdData) {
        this.isFlagEnabled = parserdData.flagsEnabled;
      }
      // MAX AUTO PLAY GAMES
      if ('maxAutoPlayGames' in parserdData) {
        if (parserdData.maxAutoPlayGames === '') {
          this.maxAutoPlayGames = 100;
        } else if (Number(parserdData.maxAutoPlayGames) === 0) {
          this.maxAutoPlayGames = 0;
        } else {
          this.maxAutoPlayGames = parserdData.maxAutoPlayGames || 100
        } 
      } else {
        this.maxAutoPlayGames = 100
      }

      // BRANDING
      if ('branding' in parserdData) {
        if (parserdData.branding) {
          this.branding = parserdData.branding.toLowerCase()
        }
      }

      // R7RUBY INTEGRATION
      if ('feEvents' in parserdData) {
        if (parserdData.feEvents === true) {
          this.r7RgsWindowBridge.use7RGSWintegration(true);
          this.r7css = true;
        }
      }

      // BET LIMIT
      if ('betLimit' in parserdData) {
        if (parserdData.betLimit === true) {
          this.betLimit = parserdData.betLimit;
        }
      }

      //mobileCloseButton in game rules
      if ('mobileCloseButton' in parserdData) {
        if (parserdData.mobileCloseButton === true) {
          this.mobileCloseButton = parserdData.mobileCloseButton;
        }
      }
      
      // USERNAME POPUP
      if ('usernamePopup' in parserdData && parserdData.usernamePopup === false) {
        this.usernamePopup = parserdData.usernamePopup;
        storage.set("showUsername", true);
      } else {
        this.usernamePopup = true
      }

      // FIXED BET
      if ('fixedBet' in parserdData) {
        this.fixedBet = parserdData.fixedBet.split("|").map(Number);
        window.setFixedBet0(this.fixedBet);
        window.setFixedBet1(this.fixedBet);
      }

       // DISABLE ADD ANOTHER BET
       if ('singleBet' in parserdData) {
        if (parserdData.singleBet === true) {
          this.singleBet = true;
        } 
      }

      // HIDE JACKPOTS RULES
       if ('removeJackpotRules' in parserdData) {
        if (parserdData.removeJackpotRules === true) {
          this.removeJackpotRules = true;
        } 
      }

      // CURRENCY VISIBLE
      if ('isCurrencyVisible' in parserdData) {
        if (parserdData.isCurrencyVisible === false) {
          this.isCurrencyVisible = false;
        } else {
          this.isCurrencyVisible = true;
        }
      }

      // CANCEL BUTTON DISABLED
      if ('cancelButtonDisabled' in parserdData) {
        if (parserdData.cancelButtonDisabled === true) {
          this.cancelButtonDisabled = true;
        } 
      }

       // USE INSURANCE FEATURE
       if ('useInsurance' in parserdData) {
        if (parserdData.useInsurance === true) {
          this.useInsurance = true;
        } else {
          storage.remove('use-bet-insurance-0');
          storage.remove('use-bet-insurance-1');
        }
      } else {
        storage.remove('use-bet-insurance-0');
        storage.remove('use-bet-insurance-1');
      }

      // AMOUNT FORMAT
      if ('amountFormat' in parserdData) {
        this.amountFormat = parserdData.amountFormat
      }

    } else {
      console.error("COULD NOT parse config")
    }
  }

  setChatFocused(val) {
    this.chatFocused = val;
  }

  setChangeUsernameFocused(val) {
    this.changeUsernameFocused = val;
  }

  playerBonusHistory(data) {
    window.setBonusingHistory(Object.values(data));
  }

  tournamentPersonalizedResultsHistory(data) {
    //console.log("tournamentPersonalizedResultsHistory", data);
    try {
      if (data?.results) {
        window.setTournamentPersonalizedResultsHistory(data.results);
      }
    } catch (error) {
      console.info("Failed to set tournamentPersonalizedResultsHistory in UI tab", error)
    }
  }

  setLoginOpenTickets() {
   if (this.myBetsByRound.find(({round_id}) => round_id === this.currRound+1) || 
      this.myBetsByRound.find(({round_id}) => round_id === this.currRound)) {      
        // NEW LOGIC
      if (this.buttons[0]) {
        const firstButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 0 && round_id === this.currRound + 1);
        
        if (firstButtonBet) {
          this.buttons[0].gameState = 'betActive';
          this.buttons[0].ticketId = firstButtonBet.ticket_id;
          this.buttons[0].roundId = firstButtonBet.round_id;
          this.buttons[0].val = firstButtonBet.bet;
          this.buttons[0].ticketType  = firstButtonBet?.ticket_type || "";
          this.buttons[0].minCashoutMultiplier = firstButtonBet?.min_cashout_multiplier;
          this.buttons[0].betInsurance  = firstButtonBet?.insurance_enabled || 0;
          window.setButton0Params(this.buttons[0]);
        }
      } else {  
        const firstButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 0 && (this.currRound+1 === round_id || this.currRound === round_id));
       
        if (firstButtonBet) {
          this.buttons[0] = {
            autoBet: firstButtonBet.autoBbet,
            autoCashout: firstButtonBet.cashoutAmount,
            ticketId: firstButtonBet.ticket_id,
            ticketType: firstButtonBet.ticket_type,
            minCashoutMultiplier: firstButtonBet?.min_cashout_multiplier,
            roundId: firstButtonBet.round_id,
            val: firstButtonBet.bet,
            buttonBusy: true,
            gameState: 'betActive',
            betInsurance: firstButtonBet?.insurance_enabled || 0
          }
          window.setButton0Params(this.buttons[0]);
        }
      }
      if (this.buttons[1]) {
        const secondButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 1 && round_id === this.currRound + 1);
        if (secondButtonBet) {
          this.buttons[1].gameState = 'betActive';
          this.buttons[1].ticketId = secondButtonBet.ticket_id;
          this.buttons[1].roundId = secondButtonBet.round_id;
          this.buttons[1].val = secondButtonBet.bet;
          this.buttons[1].ticketType  = secondButtonBet?.ticket_type || "";
          this.buttons[1].minCashoutMultiplier = secondButtonBet?.min_cashout_multiplier;
          this.buttons[1].betInsurance  = secondButtonBet?.insurance_enabled || 0;
          window.setButton1Params(this.buttons[1]);
          window.setFirstButtonPlayed(true);
        }
      } else {
        const secondButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 1 && (this.currRound+1 === round_id || this.currRound === round_id));
        if (secondButtonBet) {
          window.setBothButtonsActive(true);
          this.buttons[1] = {
            autoBet: secondButtonBet.autoBbet,
            autoCashout: secondButtonBet.cashoutAmount,
            ticketId: secondButtonBet.ticket_id,
            ticketType: secondButtonBet.ticket_type,
            minCashoutMultiplier: secondButtonBet?.min_cashout_multiplier,
            roundId: secondButtonBet.round_id,
            val: secondButtonBet.bet,
            buttonBusy: true,
            gameState: 'betActive',
            betInsurance: secondButtonBet?.insurance_enabled || 0
          }
          window.setButton1Params(this.buttons[1]);
          window.setFirstButtonPlayed(true);
        }
      }
  
      if (this.buttons[0] && this.buttons[1]) {
        window.setBothButtonsActive(true);
      } else {
        window.setBothButtonsActive(false);
      }
        window.gameState("betActive");
    } else {
      window.gameState("initialState");
    }
  }

  resize() {
    if (this.tournamentStatus == 2) {
      this.getTournamentInfoInAbstract();
      this.getPlayerTournamentsHistoryInAbstract();
    } else if (this.tournamentStatus == 3) {
      this.getTournamentInfoInAbstract();
      this.getPlayerTournamentsHistoryInAbstract();
      this.getTournamentResultsInProgress();
    }
  }

  // setShowAdvancedAutoplayPromo = (val) => {
  //   this.showAdvancedAutoplay = val;
  //   try {
  //     window.setShowAdvancedAutoplayPromo(this.showAdvancedAutoplay);
  //   } catch (error) {
  //     // console.log(error)
  //   }

  //   try {
  //     window.setShowAdvancedAutoplayPromoButton(this.showAdvancedAutoplay);
  //   } catch (error) {
  //     // console.log(error)
  //   }
  // }

    setShowInsurancePromo = (val) => {
    this.showInsurancePromo = val;
    try {
      window.setShowInsurancePromo(this.showInsurancePromo);
    } catch (error) {
      // console.log(error)
    }
  }

  async tournamentInfo (data) {    
    console.log("%c tournamentInfo", 'color: #D1202F', data);   
    if (data && Object.keys(data).length) {

      if (data.CurrencyID !== this.myCurrency) {
        return;
      }

      this.turnamentAnnnouncedisLeaked = false;
      data.StartTime = moment.utc(data.StartTime).local().format('YYYY-MM-DD HH:mm:ss');
      data.EndTime = moment.utc(data.EndTime).local().format('YYYY-MM-DD HH:mm:ss');
      this.tournamentStatus = data.TournamentStatus;

      this.tournamentName = data.TournamentName;
      // if (data.StartTime <= moment.utc().local().format('YYYY-MM-DD HH:mm:ss')) {
      //   data.TournamentStatus = 3;
      // }

      if (!data?.TournamentStatus) return;
      if (Number(data.TournamentStatus) < 2 || Number(data.TournamentStatus) > 3) {
        window.showTorunametInfo(false);
      }

      if (Number(data.TournamentStatus) === 2 || Number(data.TournamentStatus) === 3) {
        this.tournamentDescription = data.TournamentDescription;
        this.tournamentStartTime = data.StartTime;
        this.tournamentEndTime = data.EndTime;
        this.tournamentInfoCurrency = data.CurrencyID;
        this.tournamentInfoPlaceAwards = data.PlaceAwards.split(",");
      }

      if (Number(data.TournamentStatus) === 2) {
        window.showTorunametInfo(true);
        window.showTournametInProgress(false);
      };

      if (Number(data.TournamentStatus) === 3) {
        window.showTournametInProgress(true);
        window.showTorunametInfo(true);
        if (!this.turnamentResultsInprogressCalledBefore) {
          this.turnamentResultsInprogressCalledBefore = true;
          // call ggetTournamentResultsInProgresset if tournament is in progress and if it is not called before ie onload
          this.getTournamentResultsInProgress();
        }
      } else {
        try {
          window.setLeaderboard([]); 
        } catch (error) {
          //console.error(error);
        }

        try {
          window.setTableLeaderboard([]);
        } catch (error) {
          //  console.error(error);
        }
      }

      try {
        window.setTournamentStatus(this.tournamentStatus);
      } catch (error) {
        //console.error("ERROR",error)
      }

      try {
        window.setCounterTournamentStatus(this.tournamentStatus)
      } catch (error) {
        //console.error("ERROR",error)
      }

      try {
        window.setLeaderboardTournamentStatus(this.tournamentStatus)
      } catch (error) {
        //console.error("ERROR",error)
      }
    } else {
      try {
        window.setLeaderboard([]); 
      } catch (error) {
        //console.error(error);
      }

      try {
        window.setTableLeaderboard([]);
      } catch (error) {
        //  console.error(error);
      }

      try {
        window.setTournamentStatus(0);
      } catch (error) {
        //console.error("ERROR",error)
      }

      try {
        window.setCounterTournamentStatus(0)
      } catch (error) {
        //console.error("ERROR",error)
      }

      try {
        window.setLeaderboardTournamentStatus(0)
      } catch (error) {
        //console.error("ERROR",error)
      }

      try {
        window.showTournametInProgress(false);
      } catch (error) {
        //
      }

      try {
        window.showTorunametInfo(false);
      } catch (error) {
        //
      }
    }
  }

  async tournamentResultsInProgress (data) {
   console.log("%c tournamentResultsInProgress", 'color: #D1202F', data);   
   
    if (data && Object.keys(data).length) {
      this.currentLeaderboard = data;
      
      try {
        window.setTableLeaderboard(data);
      } catch (error) {
        //  console.error(error);
      }

      try {
        window.setLeaderboard(data); 
      } catch (error) {
        //console.error(error);
      }
    }
  }

  async playerTournamentsHistory (data) {
    //console.log("%c playerTournamentsHistory", 'color: #D1202F', data);  
    if (data && Object.keys(data).length) {
      this.tournamentHistory = data.filter(d => d.CurrencyID == this.myCurrency)
    }
  }

  async isCurrRoundActive () {
    return new Promise((res, rej) => {
     var checkRoundActiveInterval = setInterval(() => {
      if (this.currRound) {
        clearInterval(checkRoundActiveInterval);
        res(true);
      }
     },400);
    })
  }

  incomingOfferMeteorShower() {
    this.localErrors(translate('meteor_shower_incoming'))
  }

  toggleAnimation(val) {
    if (!val) {
      soundFxPlayer.stop('rocket_engine_1');
      soundFxPlayer.stop('rocket_engine_2');
      soundFxPlayer.stop('rocket_engine_3');
      soundFxPlayer.stop('rocket_engine_4');
      soundFxPlayer.stop('planet');
      soundFxPlayer.stop('rocket_blast_off_1');
      soundFxPlayer.stop('rocket_blast_off_2');
    }
    this.animation = val;
    window.setAnimation(this.animation);
    window.setAnimationMenu(this.animation);
  }

  // async sendErrorLogToServer(errorName, errorValue) {
  //   await fetch(SERVER_ERROR_LOG, {
  //     method:"POST",
  //     body: JSON.stringify({[errorName]: errorValue})
  //   });
  // }

  sessionStatus(data) {
    if (data === "STALE") {
      clearInterval(reqBalanceInterval);
      this.disconnect();
      window.setGameDissconnected(true);
    } 
  }

  toggleMaintenenceMode(data) {
    if (data === true) {
      this.disconnect();
      this.toggleSound(false);
      this.toggleMusic(false);
    }

    window.setMaintenanceMode(data);
  }

  setDemoBetAlreadyActivated(val) {
    this.demoAutoBet = val;
  }
  
  lostConn() {
    window.setLostConnection(true);
    this.reinitButtons();
  }

  connected() {
    window.setLostConnection(false);
    this.reinitButtons();
  }

  printUserBalance(balance) {
    //console.log("userBalance from API", balance)
  }

  handleStopOnLoss(buttonIndex, betValue) {
    if (rocketmanTransport.advancedAutoplayInProgress[buttonIndex].inProgress) {
      if (Number(this.advancedAutoPlayLossValue[buttonIndex].val) > 0 && (Number(this.balance.toFixed(this.noOfDecimals)) <= Number(this.advancedAutoPlayLossValue[buttonIndex].val.toFixed(this.noOfDecimals)))) {
        this.setAdvancedAutoPlay(buttonIndex,false,betValue);
        if (buttonIndex == 0) window.handleCHangeAutoBet0(null, false);
        if (buttonIndex == 1) window.handleCHangeAutoBet1(null, false);
        return true;
      }
    }

    return false;
  }
  
  userBalance(data) {
    this.currentFreeTicketsCount = data.current_free_tickets_count;
    clearTimeout(reqCancelCashoutTimeout);
    this.printUserBalance(data.balance)
    if (this.lastCancelTime) {
      if (reqCancelCashoutTimeout) clearTimeout(reqCancelCashoutTimeout)
        if (new Date().getTime() - this.lastCancelTime < 1000 && this.balance === data.balance) {
          reqCancelCashoutTimeout = setTimeout(() => {
          this.requestUserBalanceInAbstract();
          this.lastBalanceRequestTime = new Date().getTime();
          this.lastCancelTime = null;
        },2000)
      }
    }

    if (this.lastCashoutTime) {
      if (reqCancelCashoutTimeout) clearTimeout(reqCancelCashoutTimeout)
        if (new Date().getTime() - this.lastCashoutTime < 1000 && this.balance === data.balance) {
          reqCancelCashoutTimeout = setTimeout(() => {
            this.requestUserBalanceInAbstract();
            this.lastBalanceRequestTime = new Date().getTime();
            this.lastCashoutTime = null;
          },2000);
        }
    }

    this.balance = data.balance;
    this.promoBalance = data.promobalance;
    if (data.free_tickets_count || data.current_free_tickets_count) {
      if (data.current_free_tickets_count > 0) {
        this.freeTicketCount = data.current_free_tickets_count;
        window.setFreeBetsCount(this.freeTicketCount);
        this.freeTicketMoney = data.current_free_tickets_money;
        window.setFreeBetsMoney(this.freeTicketMoney);
      } else if (data.current_free_tickets_count === 0 && data.free_tickets_count > 0) {
        this.freeTicketCount = data.free_tickets_count;
        window.setFreeBetsCount(this.freeTicketCount);
        this.freeTicketMoney = data.free_tickets_money;
        window.setFreeBetsMoney(this.freeTicketMoney);

        // if (!storage.get('freeBetNotifActive')) {
        //   window.setShowFreeBetsNotif(true);
        //   storage.set('freeBetNotifActive', true);
        // }
        
        window.setFreeBetsNotifParams({
          betCount: data.free_tickets_count,
          betMoney: data.free_tickets_money,
          currency: this.myCurrency
        });

        try {
          window.setShowStartButton(true);
          window.setShowStartButtonMobile(true);
        } catch (error) {
          console.log("NO START BUTTON");
        }
      }
    } else {
      storage.remove('freeBetNotifActive');
      this.freeTicketCount = 0;
      window.setFreeBetsCount(this.freeTicketCount);
      this.freeTicketMoney = 0;
      window.setFreeBetsMoney(this.freeTicketMoney);
      this.playFreeBets = false;
      window.setShowStartButton(false);
      try {
        window.setShowStartButtonMobile(false);
      } catch (error) {
        console.log("NO MOBILE START BUTTON");
      }
    }
     // SET UNCREDITED BALANCE 
     this.uncreditedWins = 0;

     if (data.bonus_free_tickets_currency_code && data.bonus_free_tickets_currency_code == this.myCurrency) {
      this.uncreditedWins = data.bonus_free_tickets_money;
    }

    if (data.bonus_promo_currency_code && data.bonus_promo_currency_code == this.myCurrency) {
      this.uncreditedWins += data.bonus_promo_money;
    }

    window.setUncreditedGlobalWins(this.uncreditedWins);
    window.setBalance(this.balance);
    window.setPromoBalance(this.promoBalance);

    try {
      window.setButton0Balance(this.balance);
      window.setButton1Balance(this.balance);
    } catch (error) {
      console.log("CANNOT SET BUTTON BALANCE")
    }

    [0,1].forEach((buttonIndex) => {
      advancedAutoPlayStateHandler.handleBalance(buttonIndex,this.advancedAutoplayInProgress[buttonIndex].inProgress,this.balance)
    });
  }

  playGameMusic() {
    //musicPlayer.volume('rocketman_ingame_theme', 0.7);
    musicPlayer.loop('rocketman_ingame_theme', true);
    musicPlayer.play('rocketman_ingame_theme');
  }

  reinitButtons () {
    if (this.retail) {
      return
    }
    console.log("BUTTONS REINIT AFTER NET LOSS")
    window.setButton0Params({
      autoBet: false,
      autoCashout: "",
      ticketId: null,
      roundId: null,
      val: (storage.get('lastBetValue0') && storage.get('lastBetCurrency') === rocketmanTransport.myCurrency) ? storage.get('lastBetValue0') : this.defBet,
      buttonBusy: false,
      gameState: 'initialState'
    })
    window.setButton1Params({
      autoBet: false,
      autoCashout: "",
      ticketId: null,
      roundId: null,
      val: (storage.get('lastBetValue1') && storage.get('lastBetCurrency') === rocketmanTransport.myCurrency) ? storage.get('lastBetValue1') : this.defBet,
      buttonBusy: false,
      gameState: 'initialState'
    })
  }

  increaseOnWinAdvancedAutoPlay(buttonTicket, data) {
    if (this.advancedAutoplayInProgress[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].inProgress) {
      if (Number(this.advancedAutoPlayOnWinPercent[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].percent) > 0) {
        const prevButtonValue = Number(buttonTicket.bet);
        const perc = calcPercentage(prevButtonValue, this.advancedAutoPlayOnWinPercent[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].percent); // 1
        const increased = Number(prevButtonValue + perc);
        this.advancedPlayButtonVal = increased > Number(this.maxBet) ? this.maxBet.toFixed(rocketmanTransport.noOfDecimals) : increased.toFixed(rocketmanTransport.noOfDecimals);
      } else {
        if (this.advancedOriginalBet[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].bet) {
          this.advancedPlayButtonVal = this.advancedOriginalBet[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].bet;
        } else {
          this.advancedPlayButtonVal = buttonTicket.bet;
        }
      }
    } else {
      this.advancedPlayButtonVal = buttonTicket.bet;
    }
  }

  increaseOnLossAdvancedAutoPlay() {
    // check button 0
    if (this.advancedAutoplayInProgress[0].inProgress) {
      if (Number(this.advancedAutoPlayOnLossPercent[0].percent) > 0) {
        const prevButtonValue0 = Number(this.buttons[0].val);
        const perc = calcPercentage(prevButtonValue0, this.advancedAutoPlayOnLossPercent[0].percent);
        const increased0 = Number(prevButtonValue0 + perc);
        this.buttons[0].val = increased0 > Number(this.maxBet) ? this.maxBet.toFixed(rocketmanTransport.noOfDecimals) : increased0.toFixed(rocketmanTransport.noOfDecimals);
        window.setButton0Params(this.buttons[0]);
      } else {
        if (this.advancedOriginalBet[0].bet) {
          this.buttons[0].val = this.advancedOriginalBet[0].bet;
          window.setButton0Params(this.buttons[0]);
        }
      }
    }
      // check button 1
      if (this.advancedAutoplayInProgress[1].inProgress) {
        if (Number(this.advancedAutoPlayOnLossPercent[1].percent) > 0) {
          const prevButtonValue = Number(this.buttons[1].val);
          const perc = calcPercentage(prevButtonValue, this.advancedAutoPlayOnLossPercent[1].percent);
          const increased = Number(prevButtonValue + perc);
          this.buttons[1].val = increased > Number(this.maxBet) ? this.maxBet.toFixed(rocketmanTransport.noOfDecimals) : increased.toFixed(rocketmanTransport.noOfDecimals);
          window.setButton1Params(this.buttons[1]);
        } else {
          if (this.advancedOriginalBet[1].bet) {
            this.buttons[1].val = this.advancedOriginalBet[1].bet;
            window.setButton0Params(this.buttons[1]);
          }
        }    
      }
  }

  forceCancelAutoBetChecker(ticketId = null) {
    if (!this.advancedAutoplayInProgress[0].inProgress) {
      if (this.buttons[0] && this.autoBetStartRound[0]) {
        if (this.currRound - this.autoBetStartRound[0] >= (this.maxAutoPlayGames - 1)) {
          try {
            window.handleCHangeAutoBet0(null, false)
            if (ticketId) {
              this.tempCashout[ticketId].autoBet = false;
            }
          } catch (error) {
            console.error("cannot force cancel auto bet on button 0")
          }
        }
      }
    } 

    if (!this.advancedAutoplayInProgress[1].inProgress) {
      if (this.buttons[1] && this.autoBetStartRound[1]) {
        if (this.currRound -this.autoBetStartRound[1] >= (this.maxAutoPlayGames - 1)) {
          try {
            window.handleCHangeAutoBet1(null, false)
            if (ticketId) {
              this.tempCashout[ticketId].autoBet = false;
            }
          } catch (error) {
            console.error("cannot force cancel auto bet on button 1")
          }
        }
      }
    }
  }

  checkAutoBetProgress(buttonIndex, forceAutoBetFalse = false) {
    if (!this.advancedAutoplayInProgress[buttonIndex].inProgress) {
      if (this.buttons[buttonIndex]) {
        if (this.buttons[buttonIndex].autoBet && this.buttons[buttonIndex].ticketId) {
          if (!this.autoBetStartRound[buttonIndex]) {
            this.autoBetStartRound[buttonIndex] = this.buttons[buttonIndex].roundId;
          }
        }
  
        if (!this.buttons[buttonIndex].autoBet || forceAutoBetFalse) {
          this.autoBetStartRound[buttonIndex] = null;
        }
      }
    }
  }

  async retailTickets(data) {
    try {
      this.retailTicketsArr = data.tickets;
      window.setRetailTickets(this.retailTicketsArr);
      window.setRetailTicketsLenght(data.total_tickets);
    } catch (error) {
      console.error('cannot set retail tickets')
    }
  }

  retailParachute(shop, win) {
    const randomX = Math.floor(Math.random() * 200 - 100); 
      window.PixiComponent.parachuteAvatar(shop, 
        (win < 0.01 ? "<0.0.1" : 
        win.toFixed(2)) + " " + (this.myCurrency === "ETBB" ? "ETB" : this.myCurrency) , 1.0, randomX, -100,
          avatars[`avatar${Math.floor(Math.random() * 41)}`]);
  }

  setMainShowScreen(screen) {
    this.showMainScreen = screen;
    window.setMainShowScreen('READY_TO_FLY');
  }

  async retailGameStateUpdate(data) {
    if (this.showRetailScreen !== data?.state) {
      this.showRetailScreen = data?.state;
      window.setShowScreen(data?.state);
      //window.setMainShowScreen(data?.state);
      if (typeof data?.bonus !== 'undefined') {
        window.setRetailBonusRound(data?.bonus)
      }
    }

    if (data?.state === 'FLY') {
      let updatedTickets = false; 

        for (let i = 0; i < this.retailTicketsArr.length; i++) {
            const ticket = this.retailTicketsArr[i];
            if (data.mp >= ticket.Multiplier && !ticket.CashedOut) {
                ticket.CashedOut = true;
                const win = ticket.Multiplier * ticket.StakeEUR;
                ticket.Win = win;
                const randomX = Math.floor(Math.random() * 250 - 125);
                setTimeout(() => {
                    window.PixiComponent.parachuteAvatarRetail(
                        ticket.Shop,
                        (win < 0.01 ? "<0.1" : 
                        this.myCurrency == 'eur' ? win.toFixed(2) : (win * this.myExchangeRate).toFixed(2)) + " " + (this.myCurrency === "ETBB" ? "ETB" : this.myCurrency),
                        1.0,
                        randomX,
                        40
                    );
                }, [(i + 1) * 100]);
                updatedTickets = true; // Set the flag to indicate ticket update
            }
        }

        if (updatedTickets) {
            window.setRetailTickets(this.retailTicketsArr);
        }
    }
    
      if (!this.gameStarted) {
        rocketmanTransport.requestJackpotData(this.launcherCompanyId);
        window.setGameStarted(true);
        window.setInitialGameStart(true);
        this.gameStarted = true;
      }

    this.data = data;
    if (data.rnd !== this.currRound) {
      this.currRound = data.rnd;
      window.setCurrRound(this.currRound);
    }

    // if (data.end) {
    //   if (this.onceSnapFlag) { // prevent for loop setting snapped state
    //     this.onceSnapFlag = false;
    //   }
    // }

    if (data.mp === 1 && data.end === false && data.rs === 0) {
      if (this.onceTriggerRoundCounter) {
        this.onceTriggerRoundCounter = false;
        try {
          window.setShowRoundCounter(true);
        } catch (error) {
          //
        }
        
      }
    }

    if (data.rs === 1 && data.end === false) {
      if (this.onceTriggerRoundCounterOff) {
        this.onceTriggerRoundCounterOff = false;
        try {
          window.setShowRoundCounter(true);
        } catch (error) {
          //
        }
      }
    }

    if (data.end === true ) {
      this.onceTriggerRoundCounter = true;
      this.onceTriggerRoundCounterOff = true;
      // Set Multiplier History
      if (this.onceFillHistory) {
        // this.retailTicketsArr = [];
        // window.setRetailTickets(this.retailTicketsArr);
        // window.setRetailTicketsLenght(0);
        this.onceFillHistory = false;
        // Clean History Multipliers only latest by Multipliers
        this.multipliersHistory[this.currRound] = data.mp;
        this.randLengthMultiplier = !this.randLengthMultiplier;
        const tempMultiplierLength = Object.keys(this.multipliersHistory).length;
        if (tempMultiplierLength >= MULTIPLIER_HISTORY_LIMIT) {
          this.multipliersHistory = sliceObject({...this.multipliersHistory}, tempMultiplierLength - MULTIPLIER_HISTORY_LIMIT - 1 - Number(this.randLengthMultiplier), tempMultiplierLength);
        }
        try {
          window.setMultipliersHistory(Object.values(this.multipliersHistory));
          window.setRetailMultipliersHistory(Object.values(this.multipliersHistory));
        } catch (error) {
          //
        }
        
        if (this.gameStarted) {
          window.PixiComponent.hideParachuteAvatars();
        }
      }
    }

    if (data.end === false /* && data.rs === 0 */) { 
      this.onceFillHistory = true;
      // if (!this.onceSnapFlag) { 
      //   //window.setRocketSnapped(false);
      //   //window.setEndedAndCashouted(false);
      //   this.onceSnapFlag = true;
      // }
    }

    //window.setMp(data.mp);
  }

  async gameStateUpdate(data) {
    if (!this.gameStarted && musicPlayer.state('rocketman_ingame_theme') === 'loaded' && this.animationLoaded) {
      rocketmanTransport.requestJackpotData(this.launcherCompanyId);
      window.setGameStarted(true);
      window.setInitialGameStart(true);
      this.gameStarted = true;
    }

		this.data = data;
    if (data.rnd !== this.currRound) {
      this.currRound = data.rnd;
      [0,1].forEach((index) => {
        advancedAutoPlayStateHandler.handleRoundsPlayed(index, this.advancedAutoplayInProgress[index].inProgress,this.currRound,
          this.myBetsByRound.find(r => r.buttonIndex === index)?.round_id);
      })
      window.setCurrRound(this.currRound);
    }

    if (data.end) {
      if (this.onceSnapFlag) { // prevent for loop setting snapped state
        // send event to parent if integration is true
        this.r7RgsWindowBridge.roundResult(this.balance, this.myBetsList, this.currRound, this.jackpotValue);
        // clearTimeout(timeoutId);
        this.r7RgsWindowBridge.roundComplete(this.balance);
        if (this.currentFreeTicketsCount === 0 && this.hadFreeTickets) {
          this.r7RgsWindowBridge.freeSpinEnd(this.totalFreeTicketsWinAmount);
          this.totalFreeTicketsWinAmount = 0;
          this.hadFreeTickets = false;
        }
        if (!this.hasCashedOut) {
          if (!this.chatFocused && !this.changeUsernameFocused) {
            if (this.useInsurance && data.mp <= 1) {
              const thisRoundPlayedBets = this.myBetsByRound.filter((bet) => bet.round_id === this.currRound && 
               bet.ticket_type === "1" && bet.insurance_enabled === 1);
              const total = thisRoundPlayedBets.reduce((sum, bet) => sum + bet.bet, 0);
              if (Number(total) > 0) {
                window.setInsuranceSnapped(total);
              } else {
                window.setInsuranceSnapped(null);
              }
            } else {
              window.setInsuranceSnapped(null);
            }

            window.setRocketSnapped(true);
            this.increaseOnLossAdvancedAutoPlay();
            try {
              window.setRoundEnded(true)
            } catch (error) {
              console.info("COULD NOT SET END OF GAME ON ADVANCED AUTO PLAY COMPOENENT", error)
            }
          }
        } else {
          window.setEndedAndCashouted(true);
        }
        if(this.setPromoCreditNotif) {
          window.setPromoCreditNotif(true);
          this.setPromoCreditNotif = false;
        }
        if(this.setShowFreeBetsNotif) {
          window.setShowFreeBetsNotif(true);
          this.setShowFreeBetsNotif = false;
        }
        // window.setEndedAndCashouted(true);
        this.onceSnapFlag = false;
      }
    }
    //mp: 1, end: false, rs: 0
    if (data.mp === 1 && data.end === false && data.rs === 0) {
      if (this.onceTriggerRoundCounter) {
        this.onceTriggerRoundCounter = false;

        if (Number(this.tournamentStatus) === 3) {
          this.getTournamentResultsInProgress();
        }

        if (this.turnamentAnnnouncedisLeaked) {
          setTimeout(() => {
            this.getTournamentInfoInAbstract();
          },generateRandomInteger(3000, 5000));
        }
        
        // Calling get tournament info every sixth round
        this.roundCalledTournamentInfo++;
        if (this.roundCalledTournamentInfo > 6) {
          setTimeout(() => {
            this.getTournamentInfoInAbstract();
          },generateRandomInteger(2000, 5000));
          this.roundCalledTournamentInfo = 1;
        }
        // Check mainintence mode with actions
        if (this.maintenenceMode && !this.goToMaintenceMode) {
          this.goToMaintenceMode = this.currRound;
          storage.set('goToMaintenceMode',this.currRound);
        }
        if (this.maintenenceMode && this.goToMaintenceMode) {
          if (this.currRound > this.goToMaintenceMode + 1) {
            this.toggleMaintenenceMode(true);
          }
        }
        if (this.maintenenceMode) {
          window.setDissalowBets0(true);
          window.setDissalowBets1(true);
        } else {
          window.setDissalowBets0(false);
          window.setDissalowBets1(false);
          storage.remove('goToMaintenceMode');
          this.goToMaintenceMode = null;
        }
        try {
          window.setShowRoundCounter(true);
        } catch (error) {
          //
        }
      }
    }

    if (data.rs === 1 && data.end === false) {
      if (this.onceTriggerRoundCounterOff) {
        this.onceTriggerRoundCounterOff = false;
        // send event to parent if activated integration
        this.r7RgsWindowBridge.roundStart(this.balance,this.myBetsByRound, this.currRound);
        try {
          window.setShowRoundCounter(false);
        } catch (error) {
          //
        }
        try {
          window.setRoundEnded(false)
        } catch (error) {
          console.info("COULD NOT SET END OF GAME ON ADVANCED AUTO PLAY COMPOENENT", error)
        }
      }
    }

    if (data.rs === 1) {
      if (this.onceTriggerRoundCounterOff) {
        //this.onceTriggerRoundCounterOff = false;
        //window.setShowRoundCounter(false);
      }
    }

    if (data.end === true ) {
     
      this.onceTriggerRoundCounter = true;
      this.onceTriggerRoundCounterOff = true;
      // Set Multiplier History
      if (this.onceFillHistory) {
        this.onceFillHistory = false;
        // Clean History Multipliers only latest by Multipliers
        this.multipliersHistory[this.currRound] = data.mp;
        this.randLengthMultiplier = !this.randLengthMultiplier;
        const tempMultiplierLength = Object.keys(this.multipliersHistory).length;
        if (tempMultiplierLength >= MULTIPLIER_HISTORY_LIMIT) {
          this.multipliersHistory = sliceObject({...this.multipliersHistory}, tempMultiplierLength - MULTIPLIER_HISTORY_LIMIT - 1 - Number(this.randLengthMultiplier), tempMultiplierLength);
        }
        
        try {
          window.setMultipliersHistory(Object.values(this.multipliersHistory));
          window.setRetailMultipliersHistory(Object.values(this.multipliersHistory));
        } catch (error) {
          //
        }
        this.forceCancelAutoBetChecker();
        this.cleanBets();
        if (this.gameStarted) {
          window.PixiComponent.hideParachuteAvatars();
        }
      }
    }
    
    if (data.end === false /* && data.rs === 0 */) { 
      this.onceFillHistory = true;
      if (!this.onceSnapFlag) { // prevent for loop setting snapped state
        // var requestBalanceTimeOut = setTimeout(() => {
        //   this.requestUserBalanceInAbstract();
        //   clearTimeout(requestBalanceTimeOut);
        // },1500);
        window.setRocketSnapped(false);
        window.setEndedAndCashouted(false);
        this.onceSnapFlag = true;
      }
    }
    
    window.setMp(data.mp);
    if (this.myBetsByRound.find(({round_id}) => round_id === this.currRound) && data.rs === 1 && data.end === false) {
      // NEW LOGIC
      if (this.buttons[0]) {
        const firstButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 0 && round_id === this.currRound);
        if (firstButtonBet) {
          this.buttons[0].gameState = 'betInProgress';
          this.buttons[0].ticketId = firstButtonBet.ticket_id;
          this.buttons[0].roundId = firstButtonBet.round_id;
          this.buttons[0].val = firstButtonBet.bet;
          this.buttons[0].ticketType  = firstButtonBet?.ticket_type || "";
          this.buttons[0].minCashoutMultiplier = firstButtonBet?.min_cashout_multiplier;
          this.buttons[0].buttonBusy = true;
          this.buttons[0].betInsurance = firstButtonBet?.insurance_enabled || 0;
          window.setButton0Params(this.buttons[0]);
        }
      } else {
        const firstButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 0 && this.currRound === round_id);
        if (firstButtonBet) {
          this.buttons[0] = {
            autoBet: firstButtonBet.autoBbet,
            autoCashout: firstButtonBet.cashoutAmount,
            ticketId: firstButtonBet.ticket_id,
            ticketType: firstButtonBet.ticket_type,
            minCashoutMultiplier : firstButtonBet?.min_cashout_multiplier,
            roundId: firstButtonBet.round_id,
            val: firstButtonBet.bet,
            buttonBusy: true,
            gameState: 'betInProgress',
            betInsurance: firstButtonBet?.insurance_enabled || 0
          }
          window.setButton0Params(this.buttons[0]);
        }
      }
      if (this.buttons[1]) {
        const secondButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 1 && this.currRound === round_id);
        if (secondButtonBet) {
          this.buttons[1].gameState = 'betInProgress';
          this.buttons[1].ticketId = secondButtonBet.ticket_id;
          this.buttons[1].roundId = secondButtonBet.round_id;
          this.buttons[1].val = secondButtonBet.bet;
          this.buttons[1].ticketType  = secondButtonBet?.ticket_type || "";
          this.buttons[1].minCashoutMultiplier = secondButtonBet?.min_cashout_multiplier;
          this.buttons[1].buttonBusy = true;
          this.buttons[1].betInsurance = secondButtonBet?.insurance_enabled || 0;
          window.setButton1Params(this.buttons[1]);
          window.setFirstButtonPlayed(true);
        }
      } else {
        const secondButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 1  && this.currRound === round_id);
        if (secondButtonBet) {
          window.setBothButtonsActive(true);
          this.buttons[1] = {
            autoBet: secondButtonBet.autoBbet,
            autoCashout: secondButtonBet.cashoutAmount,
            ticketId: secondButtonBet.ticket_id,
            ticketType: secondButtonBet.ticket_type,
            minCashoutMultiplier: secondButtonBet?.min_cashout_multiplier,
            roundId: secondButtonBet.round_id,
            val: secondButtonBet.bet,
            buttonBusy: true,
            gameState: 'betInProgress',
            betInsurance: secondButtonBet?.insurance_enabled || 0
          }
          window.setButton1Params(this.buttons[1]);
          window.setFirstButtonPlayed(true);
        }
      }
      window.gameState("betInProgress");

    } else if (this.myBetsByRound.find(({round_id}) => round_id === this.currRound+1) || 
      this.myBetsByRound.find(({round_id}) => round_id === this.currRound)) {       
        // NEW LOGIC
      if (this.buttons[0]) {
        const firstButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 0 && round_id === this.currRound + 1);
        
        if (firstButtonBet) {
          this.buttons[0].gameState = 'betActive';
          this.buttons[0].ticketId = firstButtonBet.ticket_id;
          this.buttons[0].roundId = firstButtonBet.round_id;
          this.buttons[0].val = firstButtonBet.bet;
          this.buttons[0].ticketType  = firstButtonBet?.ticket_type || "";
          this.buttons[0].minCashoutMultiplier = firstButtonBet?.min_cashout_multiplier;
          this.buttons[0].betInsurance = firstButtonBet?.insurance_enabled || 0;
          window.setButton0Params(this.buttons[0]);
        }
      } else {  
        const firstButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 0 && (this.currRound+1 === round_id || this.currRound === round_id));
        if (firstButtonBet) {
          this.buttons[0] = {
            autoBet: firstButtonBet.autoBbet,
            autoCashout: firstButtonBet.cashoutAmount,
            ticketId: firstButtonBet.ticket_id,
            ticketType: firstButtonBet.ticket_type,
            minCashoutMultiplier : firstButtonBet?.min_cashout_multiplier,
            roundId: firstButtonBet.round_id,
            val: firstButtonBet.bet,
            buttonBusy: true,
            gameState: 'betActive',
            betInsurance: firstButtonBet?.insurance_enabled || 0
          }
          window.setButton0Params(this.buttons[0]);
        }
      }
      if (this.buttons[1]) {
        const secondButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 1 && round_id === this.currRound + 1);
        if (secondButtonBet) {
          this.buttons[1].gameState = 'betActive';
          this.buttons[1].ticketId = secondButtonBet.ticket_id;
          this.buttons[1].roundId = secondButtonBet.round_id;
          this.buttons[1].val = secondButtonBet.bet;
          this.buttons[1].ticketType  = secondButtonBet?.ticket_type || "";
          this.buttons[1].minCashoutMultiplier = secondButtonBet?.min_cashout_multiplier;
          window.setButton1Params(this.buttons[1]);
          window.setFirstButtonPlayed(true);
        }
      } else {
        const secondButtonBet = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 1 && (this.currRound+1 === round_id || this.currRound === round_id));
        if (secondButtonBet) {
          window.setBothButtonsActive(true);
          this.buttons[1] = {
            autoBet: secondButtonBet.autoBbet,
            autoCashout: secondButtonBet.cashoutAmount,
            ticketId: secondButtonBet.ticket_id,
            ticketType: secondButtonBet.ticket_type,
            minCashoutMultiplier : secondButtonBet?.min_cashout_multiplier,
            roundId: secondButtonBet.round_id,
            val: secondButtonBet.bet,
            buttonBusy: true,
            gameState: 'betActive',
            betInsurance: secondButtonBet?.insurance_enabled || 0
          }
          window.setButton1Params(this.buttons[1]);
          window.setFirstButtonPlayed(true);
        }
      }
  
      if (this.buttons[0] && this.buttons[1]) {
        window.setBothButtonsActive(true);
      } else {
        window.setBothButtonsActive(false);
      }
        window.gameState("betActive");
    } else {
      window.gameState("initialState");
    }
	}

  cleanBets() {
    this.hasCashedOut = false;
    // REQ USER BALANCE IF THERE ARE FREE TICKETS
    if(this.myBetsByRound.filter(b => b.ticket_type === 3).length) {
      var freeTicketsBalanceTimeOut = setTimeout(() => {
        this.requestUserBalanceInAbstract();
        clearTimeout(freeTicketsBalanceTimeOut);
      },7000);
    }

    setTimeout(() => {
      this.jackpotValue = 0;
    }, 2000);

    this.myBetsByRound = this.myBetsByRound.filter(({round_id}) => round_id > this.currRound);

    if (this.buttons[0]) {
      const tempBetVal = this.buttons[0].val || this.defBet;
        if (this.buttons[0].autoBet) {
          this.button0AutobetDelay = true;
          if (this.buttons[0].gameState === "betInProgress" || this.buttons[0].gameState === "betActive" ) {
              // check is there any bet already for next round if not buy ticket
              if (!this.myBetsByRound.filter(({buttonIndex}) => buttonIndex === 0).length) {
                const buttonVal = this.buttons[0].val;
                const cashoutVal =  this.buttons[0].autoCashout;
                //const autoBetVal = this.buttons[0].autoBet;
                // DELAY TO WAIT NEXT ROUND TO BUY TICKETS
                const delay = setTimeout(() => {
                  this.button0AutobetDelay = false;
                  if (!this.handleStopOnLoss(0, buttonVal)) {
                    if (this.buttons[0].autoBet) {
                      this.bet(buttonVal, cashoutVal,this.buttons[0].autoBet,0);
                    }                    
                  }
                  clearTimeout(delay);
               },2000);
              }
          }
        }
      
      const tempAutoBet0 = this.buttons[0].autoBet;
      const tempAutoCashout0 = this.buttons[0].autoCashout;
      const firstTicketButton = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 0 && (round_id === this.currRound || round_id === this.currRound +1));
      if (!firstTicketButton) {
        //this.buttons[0] = null;
        this.buttons[0] = {
          autoBet: tempAutoBet0,
          autoCashout: tempAutoCashout0,
          ticketId: '',
          roundId: '',
          val: tempBetVal,
          minCashoutMultiplier: "",
          buttonBusy: false,
          gameState: 'initialState'
        }
        window.setButton0Params({
          autoBet: tempAutoBet0,
          autoCashout: tempAutoCashout0,
          ticketId: null,
          roundId: null,
          val: tempBetVal,
          minCashoutMultiplier: "",
          buttonBusy: false,
          gameState: 'initialState'
        })
      }
    }
    if (this.buttons[1]) {
      const tempBetVal1 = this.buttons[1].val || this.defBet;
      const tempAutoBet1 = this.buttons[1].autoBet;
      const tempAutoCashout1 = this.buttons[1].autoCashout;
      if (this.buttons[1].autoBet) {
        this.button1AutobetDelay = true;
        if (this.buttons[1].gameState === "betInProgress" || this.buttons[1].gameState === "betActive") {
          // check is there any bet already for next round if not buy ticket
          if (!this.myBetsByRound.filter(({buttonIndex}) => buttonIndex === 1).length) {
            const buttonVal = this.buttons[1].val;
            const cashoutVal =  this.buttons[1].autoCashout;
            const delay = setTimeout(() => {
              this.button1AutobetDelay = false;
              if (!this.handleStopOnLoss(1, buttonVal)) {
                if (this.buttons[1].autoBet) {
                  this.bet(buttonVal, cashoutVal,this.buttons[1].autoBet,1);
                }
              }
              clearTimeout(delay);
            },2300)
          }
        }
      }
      
      const secoundTicketButton = this.myBetsByRound.find(({buttonIndex, round_id}) => buttonIndex === 1 && (round_id === this.currRound || round_id === this.currRound +1));
      if (!secoundTicketButton) {
        //this.buttons[1] = null;
        this.buttons[1] = {
          autoBet: tempAutoBet1,
          autoCashout: tempAutoCashout1,
          ticketId: '',
          roundId: '',
          val: tempBetVal1,
          minCashoutMultiplier: "",
          buttonBusy: false,
          gameState: 'initialState'
        };
        window.setButton1Params({
          autoBet: tempAutoBet1,
          autoCashout: tempAutoCashout1,
          ticketId: null,
          roundId: null,
          val: tempBetVal1,
          minCashoutMultiplier: "",
          buttonBusy: false,
          gameState: 'initialState'
        })
        window.setFirstButtonPlayed(false);
      }
    }
  
    // Clear all playerBets after last round is over
    for (let key in this.allPlayersBetsByRound) {
      if (this.allPlayersBetsByRound[key].round_id  < this.currRound) {
        delete this.allPlayersBetsByRound[key];
      }
    }

    // Clean myBetsLIST only latest by MYBETSLIMIT
    const tempmyBetsListLength = this.myBetsList.length;
    if (tempmyBetsListLength >= MY_BETS_LIST_LIMIT) {
      this.myBetsList = this.myBetsList.slice(tempmyBetsListLength - MY_BETS_LIST_LIMIT, tempmyBetsListLength);
      try {
        window.setMyBetsList(this.myBetsList);
      } catch (error) {
        //
      }
    }
    this.allPlayersBetsByRound = removeEmpty(this.allPlayersBetsByRound);
    window.setAllPlayerBets(this.allPlayersBetsByRound);

    // clean chat msg id-s if longer than MY_CHAT_MSGS_LIMIT
    const myChatIdsFromStorage = storage.findLike('myMsg');
    if (myChatIdsFromStorage.length >= MY_CHAT_MSGS_LIMIT) {
      for (let c = 0; c < myChatIdsFromStorage.length - MY_CHAT_MSGS_LIMIT; c++) {
        storage.remove(myChatIdsFromStorage[c]?.key);
      }
    }
  }

  setAdvancedAutoPlay(buttonIndex, val, betAmount) {
    this.advancedAutoplayInProgress[buttonIndex].inProgress = val;
    advancedAutoPlayStateHandler.handleBalance(buttonIndex,this.advancedAutoplayInProgress[buttonIndex].inProgress,this.balance)
    if (!this.advancedAutoplayInProgress[buttonIndex].inProgress) {
      this.advancedOriginalBet[buttonIndex].bet = 0;
      advancedAutoPlayStateHandler.handleRoundsPlayed(buttonIndex, this.advancedAutoplayInProgress[buttonIndex].inProgress,this.currRound,
        this.myBetsByRound.find(r => r.buttonIndex === buttonIndex)?.round_id);
    }
    // SET INITIAL BET VALUE FOR ADVANCED WIN PERCENT
    if ((this.advancedAutoplayInProgress[buttonIndex].inProgress && Number(this.advancedAutoPlayOnWinPercent[buttonIndex].percent) > 0) || 
      (this.advancedAutoplayInProgress[buttonIndex].inProgress && Number(this.advancedAutoPlayOnLossPercent[buttonIndex].percent) > 0)) {
      if (!this.advancedOriginalBet[buttonIndex].bet) {
        this.advancedOriginalBet[buttonIndex].bet = betAmount;
      }
    }
  }

  cancelTicket(ticketId, buttonIndex, amount) {
    // do nothing if canelButtonDisabled is true from custimisation
    if (this.cancelButtonDisabled == true) {
      this.localErrors("CANNOT CANCEL BET");
      return;
    }

    this.tempCancel = {ticketId, buttonIndex, amount};
    // NEW LOGIC
    this.cancelBet(ticketId);
    try {
      window.setByButtonAdvancedAutoPlay(false, buttonIndex);
    } catch (error) {
      //
    }
   
    this.setAdvancedAutoPlay(buttonIndex, false, 0);
    soundFxPlayer.play('cancelBTN');
  }

  betCancelAck(data) {
    this.checkAutoBetProgress(this.tempCancel?.buttonIndex, true)
    this.myBetsByRound = this.myBetsByRound.filter(({ticket_id}) => String(ticket_id) !== String(data.bet_cancel_ack.ticket_id));
    window.setMyBetsByRound(this.myBetsByRound);
    storage.set('myBetsByRound',this.myBetsByRound);
    this.buttons[this.tempCancel?.buttonIndex] = null;
    if (this.tempCancel?.buttonIndex == 0) this.button0AutobetDelay = false;
    if (this.tempCancel?.buttonIndex == 1) this.button1AutobetDelay = false;
    var requestBalanceTimeOut = setTimeout(() => {
      this.requestUserBalanceInAbstract();
      this.lastCancelTime = new Date().getTime();
      this.lastBalanceRequestTime = new Date().getTime();
      clearTimeout(requestBalanceTimeOut);
    },1000);
    if (this.tempCancel?.buttonIndex === 0) {
      window.setButton0Params({
        autoBet: false,
        autoCashout: "",
        ticketId: null,
        roundId: null,
        val: this.tempCancel?.amount,
        buttonBusy: false,
        gameState: 'initialState'
      })
    } else {
      if (window.setButton1Params) {
        window.setButton1Params({
          autoBet: false,
          autoCashout: "",
          ticketId: null,
          roundId: null,
          val: this.tempCancel?.amount,
          buttonBusy: false,
          gameState: 'initialState'
        })
        window.setFirstButtonPlayed(false);
      }
      
    }
    this.myBetsList = this.myBetsList.filter(({ticket_id}) => String(ticket_id) !== String(data.bet_cancel_ack.ticket_id));
    try {
      window.setMyBetsList(this.myBetsList);
      window.setRefreshMyBetsTable(String(new Date().valueOf()));
    } catch (error) {
      //
    }
    this.tempCancel = {};
  }

  highScoresRound(data){
    try {
      window.setHighScores(data);
    } catch (error) {
      //
    }
  }

  totalOnline(data) {
    if (typeof window.setOnlinePlayersMobile === 'function') window.setOnlinePlayersMobile(data);
    if (typeof window.setOnlinePlayersDesktop === 'function') window.setOnlinePlayersDesktop(data);
  }

  cashoutBet(is_autocashout, buttonIndx, ticketId, autoBet, maxMp = 0) {
    this.cashout(ticketId, Boolean(is_autocashout) ? is_autocashout : maxMp ? maxMp : this.data.mp , Boolean(is_autocashout));
    soundFxPlayer.play('cashout_close');
    this.tempCashout[ticketId] = {is_autocashout,buttonIndx,ticketId,autoBet};
  }

  cashoutAck(data) {
    this.forceCancelAutoBetChecker(data.cashout_ack.ticket_id);
    //console.log("CASHOUT ACK",data)
    var requestBalanceTimeOut = setTimeout(() => {
      this.requestUserBalanceInAbstract();
      this.lastCashoutTime = new Date().getTime();
      this.lastBalanceRequestTime = new Date().getTime();
      clearTimeout(requestBalanceTimeOut);
    },1000);
    // NEW LOGIC
    if (this.tempCashout[data.cashout_ack.ticket_id]?.autoBet) {
      const buttonTicket = this.myBetsByRound.find(({ticket_id}) => ticket_id === data.cashout_ack.ticket_id);
      this.buttons[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx] = null;
      // ONLY IF FREE TICKET 
      if (buttonTicket.ticket_type == "3") {
        this.totalFreeTicketsWinAmount += data?.cashout_ack?.win;
        // if (this.currentFreeTicketsCount === 1 && buttonTicket.ticket_type == "3") {
        //   this.r7RgsWindowBridge.freeSpinEnd(this.totalFreeTicketsWinAmount);
        //   this.totalFreeTicketsWinAmount = 0;
        // }
      }
      this.advancedPlayButtonVal = null;
        // checks if there is increse percentage on autoplay and setts coressponding values
      this.increaseOnWinAdvancedAutoPlay(buttonTicket,data);
      if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx === 0) {
        window.setButton0Params({
          autoBet: this.tempCashout[data.cashout_ack.ticket_id].autoBet,
          autoCashout: "",
          ticketId: null,
          roundId: null,
          val: this.advancedPlayButtonVal,
          minCashoutMultiplier: "",
          buttonBusy: false,
          gameState: 'initialState'
        })
      } else {
        window.setButton1Params({
          autoBet: this.tempCashout[data.cashout_ack.ticket_id].autoBet,
          autoCashout: "",
          ticketId: null,
          roundId: null,
          val: this.advancedPlayButtonVal,
          buttonBusy: false,
          gameState: 'initialState'
        })
        window.setFirstButtonPlayed(false);
      }

      if (buttonTicket) {
        if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx == 0) this.button0AutobetDelay = false;
        if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx == 1) this.button1AutobetDelay = false;
        
        if (this.advancedAutoplayInProgress[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].inProgress) {
          // DONT BUY TICKET ON CASHOUT IF PROFIT VALUE IS BALANCE IS GREATER THAN THE PROFIT VALUE
          if (Number(this.advancedAutoPlayProfitValue[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].val) > 0 && (Number(this.balance) + Number(data?.cashout_ack?.win)) >= Number(this.advancedAutoPlayProfitValue[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].val)) {
            this.setAdvancedAutoPlay(this.tempCashout[data.cashout_ack.ticket_id].buttonIndx, false, buttonTicket?.bet);
            if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx == 0) window.handleCHangeAutoBet0(null, false);
            if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx == 1) window.handleCHangeAutoBet1(null, false);
          } else {
            this.bet(this.advancedAutoplayInProgress[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].inProgress ? this.advancedPlayButtonVal : buttonTicket.bet, this.tempCashout[data.cashout_ack.ticket_id].is_autocashout, true, this.tempCashout[data.cashout_ack.ticket_id].buttonIndx);
          }
        } else {
          this.bet(this.advancedAutoplayInProgress[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx].inProgress ? this.advancedPlayButtonVal : buttonTicket.bet, this.tempCashout[data.cashout_ack.ticket_id].is_autocashout, true, this.tempCashout[data.cashout_ack.ticket_id].buttonIndx);
        }
        
        if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx == 0 && this.tempCashout[data.cashout_ack.ticket_id].autoBet) {
          this.button0AutobetDelay = true;
        }
        if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx == 1 && this.tempCashout[data.cashout_ack.ticket_id].autoBet) {
          this.button1AutobetDelay = true;
        }
      }
      this.myBetsByRound = this.myBetsByRound.filter(({ticket_id}) => ticket_id !== data.cashout_ack.ticket_id);
      window.setMyBetsByRound(this.myBetsByRound);
    } else {
      if (!(data?.cashout_ack?.ticket_id in this.tempCashout)) {
        console.error("TICKET ID NOT IN TEMP CASHOUT");
      } else {
        //this.cashout(ticketId,this.data.mp, Boolean(is_autocashout));
        const buttonTicket = this.myBetsByRound.find(({buttonIndex}) => buttonIndex === this.tempCashout[data.cashout_ack.ticket_id].buttonIndx);
        
        // ONLY IF FREE TICKET 
      if (buttonTicket.ticket_type == "3") {
        this.totalFreeTicketsWinAmount += data?.cashout_ack?.win;
        // if (this.currentFreeTicketsCount === 1 && buttonTicket.ticket_type == "3") {
        //   this.r7RgsWindowBridge.freeSpinEnd(this.totalFreeTicketsWinAmount);
        //   this.totalFreeTicketsWinAmount = 0;
        // }
      }
        this.myBetsByRound = this.myBetsByRound.filter(({ticket_id}) => ticket_id !== data.cashout_ack.ticket_id); 
        window.setMyBetsByRound(this.myBetsByRound);
        this.buttons[this.tempCashout[data.cashout_ack.ticket_id].buttonIndx] = null;
        
        if (this.tempCashout[data.cashout_ack.ticket_id].buttonIndx === 0) {
          if (this.tempCashout[data.cashout_ack.ticket_id].is_autocashout) {
            this.buttons[0] = {
              autoBet: false,
              autoCashout: this.tempCashout[data.cashout_ack.ticket_id].is_autocashout,
              ticketId: null,
              roundId: null,
              minCashoutMultiplier: "",
              val: buttonTicket?.bet,
              buttonBusy: false,
              gameState: 'initialState'
            }
          }
          window.setButton0Params({
            autoBet: false,
            autoCashout: this.tempCashout[data.cashout_ack.ticket_id]?.is_autocashout,
            ticketId: null,
            roundId: null,
            val: buttonTicket?.bet,
            minCashoutMultiplier: "",
            buttonBusy: false,
            gameState: 'initialState'
          })
        } else {
          if (this.tempCashout[data.cashout_ack.ticket_id].is_autocashout) {
            this.buttons[1] = {
              autoBet: false,
              autoCashout: this.tempCashout[data.cashout_ack.ticket_id].is_autocashout,
              ticketId: null,
              roundId: null,
              minCashoutMultiplier: "",
              val: buttonTicket?.bet,
              buttonBusy: false,
              gameState: 'initialState'
            }
          }
          window.setButton1Params({
            autoBet: false,
            autoCashout: this.tempCashout[data.cashout_ack.ticket_id]?.is_autocashout,
            ticketId: null,
            roundId: null,
            minCashoutMultiplier: "",
            val: buttonTicket?.bet,
            buttonBusy: false,
            gameState: 'initialState'
          })
          window.setFirstButtonPlayed(false);
        }
      }
    }

    const ticketsLeftInThisRound = this.myBetsByRound.filter(({round_id}) => round_id === this.currRound);
    if (!ticketsLeftInThisRound.length) {
      this.hasCashedOut = true;
    }

    this.cashouts.push(data.cashout_ack);
    const tempCashoutsLength = this.cashouts.length;
    if (this.cashouts.length >= CASHOUTS_LIMIT) {
      this.cashouts = this.cashouts.slice(tempCashoutsLength - CASHOUTS_LIMIT, tempCashoutsLength);
    }
    window.setCashedOuts(this.cashouts);
    window.setMyBetsByRound(this.myBetsByRound);
    storage.set('myBetsByRound',this.myBetsByRound);
    delete this.tempCashout[data.cashout_ack.ticket_id];
    removeEmpty(this.tempCashout);

    const foundBetForMyBetsList = this.myBetsList.findIndex(({ticket_id}) => String(ticket_id) === String(data?.cashout_ack?.ticket_id));
    if (foundBetForMyBetsList > -1) {
      this.myBetsList[foundBetForMyBetsList].win = data?.cashout_ack?.win;
      this.myBetsList[foundBetForMyBetsList].win_eur = data?.cashout_ack?.win_eur;
      this.myBetsList[foundBetForMyBetsList].currency_code = this.myCurrency;
      window.setMyBetsList && window.setMyBetsList(this.myBetsList);
    }

    try {
      window.setRefreshMyBetsTable(String(new Date().valueOf()));
    } catch (error) {
      //
    }
  }

  noCurrentBetsForButton(buttonIndex) {
    const betAlreadyIn = this.myBetsList.find((b) => b.bet_button_id == buttonIndex + 1 && (b.round_id === this.currRound || b.round_id == this.currRound + 1 ));
    return !betAlreadyIn;
  }

  checkBetLimit(amount, buttonIndex) {
    if (this.betLimit === true) {
      const activeBet = Object.values(this.buttons).find((b) => b?.gameState === 'betActive');
      if (activeBet) {
        if (Number(activeBet.val) + Number(amount) > Number(this.maxBet)) {
          this.localErrors(`${translate('max_bet_is')} ${this.maxBet} ${translate('already_active_bet_of')} ${activeBet.val}.`);
          
          // Populate button bet amount with difference
          const buttonMaxBetValue = Number(Number(this.maxBet) - Number(activeBet.val)).toFixed(this.noOfDecimals);
          try {
            if (buttonIndex == 0) {
              window.setButton0MaxBetVal(buttonMaxBetValue)
            } 
            if (buttonIndex == 1) {
              window.setButton1MaxBetVal(buttonMaxBetValue);
            }
          } catch (error) {
            console.error("error setting button max val" + error);
          }
          return false;
        }
      }
    }
    
    return true;
  }

  async bet(amount, autocashout, autoBet, buttonIndex, advanced = false) {
    if (this.maintenenceMode) return;
    const betAlreadyIn = this.myBetsList.find((b) => b.bet_button_id == buttonIndex + 1 && (b.round_id === this.currRound || b.round_id == this.currRound + 1 ));
    // FILL THE GAP DELAY TO DISABLE USER BET ON BUTTON BETWEEN ROCKET SNAPS AND START NEXT ROUND WHEN AUTO BET IS INITIATED ( ON ANOTHER ROUND START )
    if (buttonIndex == 0 && this.button0AutobetDelay && betAlreadyIn) {console.error("BET DENIED BECAUSE AUTO BET IS ACTIVE ON BUTTON 0 ", betAlreadyIn); return}
    if (buttonIndex == 1 && this.button1AutobetDelay && betAlreadyIn) {console.error("BET DENIED BECAUSE AUTO BET IS ACTIVE ON BUTTON 1 ", betAlreadyIn); return}

    if (!amount || (buttonIndex < 0 && buttonIndex > 1)) {
      if (!amount) {
        console.error("NO AMOUNT PROVIDED TRYING TO RESET BUTTON", amount);
        // RESET BUTTON 
        if (buttonIndex == 0 || buttonIndex == 1)
        this.buttons[buttonIndex] = {
          autoBet: false,
          autoCashout: '',
          ticketId: '',
          roundId: '',
          val: this.defBet,
          buttonBusy: false,
          gameState: 'initialState'
        }
        if (buttonIndex == 0) {
          window.setButton0Params({
            autoBet: false,
            autoCashout: '',
            ticketId: null,
            roundId: null,
            val: this.defBet,
            buttonBusy: false,
            gameState: 'initialState'
          })
        } 
        if (buttonIndex == 1) {
          window.setButton1Params({
            autoBet: false,
            autoCashout: '',
            ticketId: null,
            roundId: null,
            val: this.defBet,
            buttonBusy: false,
            gameState: 'initialState'
          })
        } 
      }
      if (buttonIndex < 0 && buttonIndex > 1) console.error("WRONG BUTTON INDEX PROVIDED", buttonIndex);
    } else {
      if (!this.checkBetLimit(amount,buttonIndex)) {
        return;
      }
      if (!isNumeric(String(amount))) {
        if (!isNumeric(amount)) console.error("AMOUNT IS NOT A NUMBER", amount);
      } else {
        if (Number(amount) > (this.balance + this.promoBalance)) {
          window.setShowNoFunds(true);
          this.r7RgsWindowBridge.outOfCredits(this.balance + this.promoBalance);
          return;
        } 
        if (Number(amount) > this.maxBet) {
          this.localErrors(`MAX BET IS ${this.maxBet} ${this.myCurrency}`);
          return;
        }
        if (Number(amount) < this.minBet) {
          this.localErrors(`${translate('min_bet_is')} ${this.minBet} ${this.myCurrency}`);
          return;
        }
        
        soundFxPlayer.play('betBTN');
        this.tempBet[buttonIndex] = {autocashout, autoBet, advanced};
        let sosageBananaForBackend = {};
        if (this.advancedAutoplayInProgress[buttonIndex].inProgress) {
          sosageBananaForBackend[buttonIndex] = {
            betAmount: amount,
            autoCashout: this.tempBet[buttonIndex].autocashout,
            totalBets: this.advancedAutoPlayTotalBetsValue[buttonIndex].val,
            onWin: this.advancedAutoPlayOnWinPercent[buttonIndex].percent,
            onLoss: this.advancedAutoPlayOnLossPercent[buttonIndex].percent,
            stopOnProfit: this.advancedAutoPlayStopOnProfitValue[buttonIndex].val,
            stopOnLoss: this.advancedAutoPlayStopOnLossValue[buttonIndex].val
          }
          
          storage.set(`sosageBanana${[buttonIndex]}`,JSON.stringify(sosageBananaForBackend[buttonIndex]));
        }

        if (!this.advancedAutoplayInProgress[buttonIndex].inProgress && autoBet) {
          sosageBananaForBackend[buttonIndex] = {
            autoCashout: this.tempBet[buttonIndex].autocashout,
          }
          
          //storage.set(`sosageBanana${[buttonIndex]}`,JSON.stringify(sosageBananaForBackend[buttonIndex]));
        }

        if (buttonIndex + 1 == 1 && autoBet) {
          const delay = setTimeout(() => {     
            this.placeBet(amount, buttonIndex + 1, autoBet, this.advancedAutoplayInProgress[buttonIndex].inProgress || autoBet ? JSON.stringify(sosageBananaForBackend[buttonIndex]) : '', storage.get(`use-bet-insurance-${buttonIndex}`));
            clearTimeout(delay);
          },300)
        } else {
          this.placeBet(amount, buttonIndex + 1, autoBet, this.advancedAutoplayInProgress[buttonIndex].inProgress || autoBet ? JSON.stringify(sosageBananaForBackend[buttonIndex]) : '', storage.get(`use-bet-insurance-${buttonIndex}`));
        }
        
        this.buttons[buttonIndex] = {};
        this.buttons[buttonIndex].autoCashout = autocashout;
        this.buttons[buttonIndex].autoBet = autoBet;
        
        //window.setButton0Params(this.buttons[buttonIndex])
      }
    }
  }

  async checkMaintenceMode() {
    const response = await (await fetch(MAINTANCE_FILE)).text();
    // waits until the request completes...
    return Boolean(Number(response));
  }

  betAck(data) {
    //console.log("BET ACK", data)
    this.printUserBalance(data.bet_ack.balance)
    if (data.bet_ack.bet_passed === true) {
      this.balance = data.bet_ack.balance;
      this.promoBalance = data.bet_ack.promobalance;
      window.setBalance(this.balance);
      window.setPromoBalance(this.promoBalance);

      try {
        window.setButton0Balance(this.balance);
        window.setButton1Balance(this.balance);
      } catch (error) {
        console.log("CANNOT SET BUTTON BALANCE")
      }

      [0,1].forEach((buttonIndex) => {
        advancedAutoPlayStateHandler.handleBalance(buttonIndex,this.advancedAutoplayInProgress[buttonIndex].inProgress,this.balance)
      });

      if (!this.buttons[data.bet_ack.bet_button_id - 1]) {
        this.buttons[data.bet_ack.bet_button_id -1] = {
          autoBet: false,
          autoCashout: '',
          ticketId: '',
          roundId: '',
          val: '',
          minCashoutMultiplier: "",
          buttonBusy: false,
          gameState: 'initialState'
        }
      }

      this.buttons[data.bet_ack.bet_button_id - 1].ticketId = data.bet_ack.ticket_id;
      this.buttons[data.bet_ack.bet_button_id - 1].ticketType = data.bet_ack.ticket_type;
      this.buttons[data.bet_ack.bet_button_id - 1].roundId = data.bet_ack.round_id;
      this.buttons[data.bet_ack.bet_button_id - 1].val = data.bet_ack.bet;
      this.buttons[data.bet_ack.bet_button_id - 1].buttonBusy = true;
      this.buttons[data.bet_ack.bet_button_id - 1].minCashoutMultiplier = data?.bet_ack.min_cashout_multiplier
      this.buttons[data.bet_ack.bet_button_id - 1].gameState = 'betActive';
      this.buttons[data.bet_ack.bet_button_id - 1].betInsurance = data.bet_ack?.insurance_enabled;

     // storage.set(`insurance-played-${data.bet_ack.ticket_id}`, true);
      
      if (data?.bet_ack?.min_cashout_multiplier > 0) {
        try {
          window.setMinCashoutMultiplierPlayNow(data?.bet_ack.min_cashout_multiplier)
        } catch (error) {
          // console.log("")
        }
        try {
          window.setMinCashoutMultiplier(data?.bet_ack.min_cashout_multiplier);
        } catch (error) {
          // console.log("")
        }
      }
      
      if (data.bet_ack.bet_button_id - 1 === 0) {
        window.setButton0Params(this.buttons[0])
      }
      if (data.bet_ack.bet_button_id - 1 === 1) {
        window.setButton1Params(this.buttons[1])
        window.setFirstButtonPlayed(true);
      }
      this.myBetsByRound.push({...data.bet_ack,...{bet_eur: Number(data.bet_ack.bet_eur) / Number(this.myExchangeRate)}, ...{currency_code: this.myCurrency},   ...{cashoutAmount: this.tempBet[data.bet_ack.bet_button_id - 1].autocashout}, ...{autoBbet: this.tempBet[data.bet_ack.bet_button_id - 1].autoBet}, ...{buttonIndex: data.bet_ack.bet_button_id - 1}});   
      window.setMyBetsByRound(this.myBetsByRound);
      storage.set('myBetsByRound',this.myBetsByRound);   
      this.myBetsList.push({...data.bet_ack, ...{currency_code: this.myCurrency}, ...{player_bet: data.bet_ack.bet}, ...{round: data.bet_ack.round_id}, ...{cashoutAmount: this.tempBet[data.bet_ack.bet_button_id - 1].autocashout}, ...{autoBbet: this.tempBet[data.bet_ack.bet_button_id - 1].autoBet}, ...{win: null}, ...{created_time: moment()}});
      try {
        window.setMyBetsList(this.myBetsList);
        window.setRefreshMyBetsTable(String(new Date().valueOf()));
      } catch (error) {
        //
      }
    } else {
      this.localErrors(translate('bet_failed'));
    }

    advancedAutoPlayStateHandler.handleRoundsPlayed(data.bet_ack.bet_button_id - 1, this.advancedAutoplayInProgress[data.bet_ack.bet_button_id - 1].inProgress,this.currRound,
      this.myBetsByRound.find(r => r.buttonIndex === data.bet_ack.bet_button_id - 1)?.round_id);

    this.checkAutoBetProgress(data.bet_ack.bet_button_id - 1)
    delete this.tempBet[data.bet_ack.bet_button_id - 1];
    removeEmpty(this.tempBet);
	}

  tickBets(data) {
    Object.keys(data).forEach(ticketBet => {
      let ticket = data[ticketBet]
      ticket.ticket_id = ticketBet;
      ticket.bet = ticket.currency_code == this.myCurrency ? ticket.bet : Number(Number(ticket.bet_eur) * Number(this.myExchangeRate)).toFixed(this.noOfDecimals);
      ticket.win = null;
      ticket.win_eur = null;
      if (!(ticketBet in this.allPlayersBetsByRound)) {
        this.allPlayersBetsByRound[ticketBet] = ticket;
      } else {
        //console.log("ALREADY IN")
      }
    });
    this.allPlayersBetsByRound = removeEmpty(this.allPlayersBetsByRound);
    window.setAllPlayerBets(this.allPlayersBetsByRound);
  }

  tickCanceledBets(data) {
    Object.keys(data).forEach(ticketBet => {
      delete this.allPlayersBetsByRound[ticketBet];
    });
    this.allPlayersBetsByRound = removeEmpty(this.allPlayersBetsByRound);
    window.setAllPlayerBets(this.allPlayersBetsByRound);
  }

  exception(data) {
    window.setWarning(data?.desc);
  }

  localErrors(data) {
    window.setWarning(data);
  }

  toggleSound(value) {
    if (!value) {
      soundFxPlayer.stop('rocket_engine_1');
      soundFxPlayer.stop('rocket_engine_2');
      soundFxPlayer.stop('rocket_engine_3');
      soundFxPlayer.stop('rocket_engine_4');
      soundFxPlayer.stop('planet');
      soundFxPlayer.stop('rocket_blast_off_1');
      soundFxPlayer.stop('rocket_blast_off_2');
    }
    soundFxPlayer.play('menu_switch_1');
    this.sound = value;
    this.tempSound = this.sound;
    this.requestUpdateSettings(this.sound, this.music);
    this.r7RgsWindowBridge.toggleSFX(value);
  }

  toggleMusic(value) {
    if (value) {
      //musicPlayer.volume('rocketman_ingame_theme', 0.7);
      musicPlayer.loop('rocketman_ingame_theme', true);
      musicPlayer.play('rocketman_ingame_theme');
    } else {
      musicPlayer.stop('rocketman_ingame_theme');
    }
    soundFxPlayer.play('menu_switch_1');
    this.music = value;
    this.tempMusic = this.music;
    this.requestUpdateSettings(this.sound, this.music);
    this.r7RgsWindowBridge.toggleMusic(value);
  }

  toggleVideoSoundAndMusic(sound, music) {
    if (music) {
      if (!this.music) {
        if (this.tempMusic) {
          // play music only if initial music settings was set to true and if is not already running
          //musicPlayer.volume('rocketman_ingame_theme', 0.7);
          musicPlayer.loop('rocketman_ingame_theme', true);
          musicPlayer.play('rocketman_ingame_theme');
        }
      }
    } else {
      musicPlayer.stop('rocketman_ingame_theme');
    }

    if (!sound) {
      soundFxPlayer.stop('rocket_engine_1');
      soundFxPlayer.stop('rocket_engine_2');
      soundFxPlayer.stop('rocket_engine_3');
      soundFxPlayer.stop('rocket_engine_4');
      soundFxPlayer.stop('planet');
      soundFxPlayer.stop('rocket_blast_off_1');
      soundFxPlayer.stop('rocket_blast_off_2');
    }

    this.sound = sound;
    this.music = music;
  }

  topWinsWeekly(data) {
    this.weeklyTopWins = data;
    window.setTopWins(data);
  }

  topWinsMonthly(data) {
    this.monthlyTopWins = data;
    window.setTopWins(data);
  }

  topWinsYearly(data) {
    this.yearlyTopWins = data;
    window.setTopWins(data);
  }

  topOddsWeekly(data) {
    this.weeklyTopOdds = data;
    window.setTopOdds(data);
  }

  topOddsMonthly(data) {
    this.monthlyTopOdds = data;
    window.setTopOdds(data);
  }

  topOddsYearly(data) {
    this.yearlyTopOdds = data;
    window.setTopOdds(data);
  }

  biggestOddsWeekly(data) {
    this.weeklyBiggestOdds = data;
    window.setBiggestOdds(data);
  }

  biggestOddsMonthly(data) {
    this.monthlyBiggestOdds = data;
    window.setBiggestOdds(data);
  }

  biggestOddsYearly(data) {
    this.yearlyBiggestOdds = data;
    window.setBiggestOdds(data);
  }

  // roundMpByHash(data) {
  //   window.setShowRoundMP(data);
  // }

  ticketDetails(data) {
    try {
      window.setTicketDetails(data);
    } catch (error) {
      //console.log("BET INFO NOT ACTIVE")
    } 
  }
 
  tickCashouts(data) {
    for (const cashout of Object.keys(data)) {
      if ( !(cashout in this.allPlayersBetsByRound) ) {
        console.log("CASHOUT ID NOT FOUND");
      } else {
        this.allPlayersBetsByRound[cashout].win = this.allPlayersBetsByRound[cashout].currency_code == this.myCurrency ? data[cashout].win_in_player_currency : Number(data[cashout].win_eur) * Number(this.myExchangeRate);
        this.allPlayersBetsByRound[cashout].win_eur = data[cashout].win_eur;
        const ticketData = this.allPlayersBetsByRound[cashout];
        if (filterIsolatedBets(ticketData)) {
          const randomX = Math.floor(Math.random() * 200 - 100); // -100 do 100
          let parachuteText = ticketData.win < 0.01 ? "<0.01" : ticketData.win.toFixed(this.noOfDecimals)
          parachuteText += this.isCurrencyVisible ? " " + (this.myCurrency === "ETBB" ? "ETB" : this.myCurrency) : ""
          window.PixiComponent.parachuteAvatar(ticketData.username, parachuteText, 1.0, randomX, -100, avatars[`avatar${ticketData.avatar_id}`]);
        } 
      }
    }
    window.setAllPlayerBets(this.allPlayersBetsByRound);
    
    this.cashoutTick = !this.cashoutTick;
    window.setCashoutTick(this.cashoutTick);
  }

  jackpotData(data) {
    window.setJackpotData(data);
    window.setHeaderJackpotData(data);
  }

  jackpotHistory(data) {
    if (data.length) {
      switch (data[0]?.jackpot_level) {
        case 1:
          window.setJackpotHistory(data);  
          break;
        case 2:
          window.setRocketPotHistory(data);  
          break;
        case 3:
          window.setBoosterPotHistory(data);  
        break;   
        default:
          break;
      }
    }
  }

  setFreeTickets(data) {
    this.currentFreeTicketsCount = data.current_free_tickets_count;
    if (this.currentFreeTicketsCount > 0) {
      this.hadFreeTickets = true;
    }
    //SET FREE BETS
  if (data?.free_tickets_count || data?.player_data?.current_free_tickets_count) {
    if (data.player_data.current_free_tickets_count > 0) {
      this.freeTicketCount = data.player_data.current_free_tickets_count;
      window.setFreeBetsCount(this.freeTicketCount);
      this.freeTicketMoney = data.player_data.current_free_tickets_money;
      window.setFreeBetsMoney(this.freeTicketMoney);
    } else if (data.player_data.current_free_tickets_count === 0 && data.free_tickets_count > 0) {
        this.freeTicketCount = data.free_tickets_count;
        window.setFreeBetsCount(this.freeTicketCount);
        this.freeTicketMoney = data.free_tickets_money;
        window.setFreeBetsMoney(this.freeTicketMoney);
      }
    } else {
      window.setFreeBetsCount(this.freeTicketCount);
      this.freeTicketMoney = 0;
      window.setFreeBetsMoney(this.freeTicketMoney);
      this.playFreeBets = false;
      window.setShowStartButton(false);
      try {
        window.setShowStartButtonMobile(false);
      } catch (error) {
        console.log("NO MOBILE START BUTTON");
      }
    }
  }

  newChatMessage(data) {
    if (data.attraction == true) {
      if(Number(this.launcherCompanyId) === Number(data.company_id)) {
        window.setRunConfetti(true)
      }
    }
    this.messagesCounter = this.messagesCounter + 1;
  }

  chatUpdate(data) {
    try {
      window.setMessagesCounter(this.messagesCounter)
    } catch (error) {
      // console.log("NO MESSAGE COUNTER");
    }

    if (this.updateChat) {
      // try {
      //   const unclaimedMessages = chatData.filter(c => c.msg_lvl === 22 && this.meteor_shower_active && showerMessageHelper(c.msg).noOfFreeBets > Object.keys(c.claims || {}).length);
      //   const chat = unclaimedMessages.concat(chatData.filter(c => !unclaimedMessages.includes(c)))
      //     .slice(0)
      //     .reverse()
      //   window.setChat(chat);
      //   this.chat = chat;
      // } catch (error) {
      //    console.log("ERRR",error);
      // }

      try {
        if (showMessageNow(this.myBetsList,data,this.currRound,this.player_id)) {
          window.setChat(data);
        } else {
          setTimeout(() => {
            try {
              window.setChat(data);
            } catch (error) {
               // console.log("");
            }
          },DELAY_SHOWER_MESSAGE_TIME);
        }
      } catch (error) {
        // console.log("");
      }
    }
    if(!this.updateChat) {
     if(!this.setMessagesOnce) {
      this.messagesCounter = data.length;
      this.setMessagesOnce = true;
     }
    }
  }

  roundHashData(data) {
    if (data?.hash) {
      window.setRoundHash(data);
    }
  }

  startPlayFreeBets() {
    if (this.myBetsByRound.length) {
      this.localErrors(translate('please_wait_active_bets'));
      // toggle off autobet on both buttons
      try {
        window.toggleButton0AutoBet(false);
        if (this.buttons[0]) this.buttons[0].autoBet = false;
        window.toggleButton1AutoBet(false);
        if (this.buttons[1]) this.buttons[1].autoBet = false;
      } catch (error) {
        console.error("There was an error toggling auto bet : ", error)
      }
    } else {
      if (this.clickedStartPlayFreeBets) {
        return;
      }
      this.clickedStartPlayFreeBets = true;
      
      this.playFreeTickets();
      try {
        window.setStartBtnWaitForResponse(this.clickedStartPlayFreeBets);
        window.setStartBtnMobileWaitForResponse(this.clickedStartPlayFreeBets)
      } catch (error) {
        console.log(error)
      }
    }
  }

  playedFreeTickets(data) {
    this.clickedStartPlayFreeBets = false;
    this.hadFreeTickets = true;
    try {
      window.setStartBtnWaitForResponse(this.clickedStartPlayFreeBets);
      window.setStartBtnMobileWaitForResponse(this.clickedStartPlayFreeBets)
    } catch (error) { 
      console.error(error);
    }
    //console.log("PLAYED FREE TICKETS",data)
    if (data.length) {
      this.playFreeBets = true;
      this.r7RgsWindowBridge.freeSpinStart(data.length);
      window.setShowStartButton(false);
      try {
        window.setShowStartButtonMobile(false);
      } catch (error) {
        console.log("NO MOBILE START BUTTON");
      }

      data.slice().reverse().forEach((ticket) => {
        const allPlayersTicket = ticket;
        allPlayersTicket.username = this.username;
        allPlayersTicket.win = 0;
        allPlayersTicket.win_eur = 0;
        allPlayersTicket.currency_code = this.myCurrency;
        ticket.buttonIndex = 0;
        ticket.avatar_id = this.avatarId;
        ticket.autoBbet = false;
        ticket.autoCashout = "";
        ticket.company_id = Number(this.launcherCompanyId);
        ticket.country_code = this.countryCode;
        ticket.bet = ticket.bet;
        this.allPlayersBetsByRound[ticket.ticket_id] = allPlayersTicket;
        this.myBetsByRound.push(ticket);
        this.myBetsList.push({...ticket, ...{currency_code: this.myCurrency}, ...{player_bet: ticket.bet}, ...{round: ticket.round_id}, ...{cashoutAmount: ticket.autocashout}, ...{autoBbet: false}, ...{win: null}, ...{created_time: moment()}});
      });
      
      try {
        window.setMyBetsList(this.myBetsList);
        window.setRefreshMyBetsTable(String(new Date().valueOf()));
      } catch (error) {
        //
      }
    } else {
      this.localErrors(translate('bet_failed'));
    }
    window.setAllPlayerBets(this.allPlayersBetsByRound);
    window.setMyBetsByRound(this.myBetsByRound);
    storage.set('myBetsByRound',this.myBetsByRound);
  }

  showFreeTicketsNotification(msg) {
    //storage.remove('freeBetNotifActive');
    const numbers = msg.match(/[+-]?\d+(?:\.\d+)?/g).map(String);
    let betCount, betMoney;
    let currency = msg.split(' ').pop();

    numbers.forEach((number) => {
      if (number.indexOf(".") === -1) {
        betCount = number;
      } else {
        betMoney = number;
      }
    })

    if (!this.myBetsByRound.filter(b => b.ticket_type === 3).length) {
      if (numbers.length === 2 && currency === this.myCurrency) {
        // window.setShowFreeBetsNotif(true);
        this.setShowFreeBetsNotif = true;
        window.setFreeBetsMsg(msg);
        window.setFreeBetsNotifParams({
          betCount,
          betMoney,
          currency: this.myCurrency
        })
      } else {
        // window.setShowFreeBetsNotif(true);
        this.setShowFreeBetsNotif = true;
        window.setFreeBetsMsg(msg);
        window.setFreeBetsNotifParams({
          betCount,
          betMoney,
          currency
        })
      }
    }

    if (!this.myBetsByRound.filter(b => b.ticket_type === 3).length) {
      window.setShowStartButton(true);
    }
    
    try {
      if (!this.myBetsByRound.filter(b => b.ticket_type === 3).length) {
        window.setShowStartButtonMobile(true);
      }
    } catch (error) {
      console.log("NO MOBILE START BUTTON");
    }

    setTimeout(() => {
      if (!this.myBetsByRound.filter(b => b.ticket_type === 3).length) {
       window.setShowStartButton(true);
      }
      try {
        if (!this.myBetsByRound.filter(b => b.ticket_type === 3).length) {
          window.setShowStartButtonMobile(true);
        }
      } catch (error) {
        console.log("NO MOBILE START BUTTON");
      }
    },500)
  }

  setHistoryTabIndex(index) {
    this.historyTabIndex = index;
  }

  showPromoCreditNotification(msg) {
    const numbers = msg.match(/[+-]?\d+(?:\.\d+)?/g).map(String);
    if (numbers.length) {
      
      this.requestUserBalanceInAbstract();
      this.lastBalanceRequestTime = new Date().getTime();
      this.promoBalance = numbers[0];
      window.setPromoBalance(this.promoBalance);
      window.setPromoCreditVal(numbers[0]);
      if (this.promoBalance >= this.minBet) {
        window.PixiComponent.promoAstronaut("Promo credit:", msg, 1.7);
        
        this.setPromoCreditNotif = true;
        window.setAstroPromoCredit(numbers[0]);
      }
    }
  }

  showMeteorShowerErrorMessage(msg) {
    window.setShowMeteorShowerErrorMessage(msg);
  }

  showTournamentWinnerNotification(msg) {
    const numbers = msg.match(/[+-]?\d+(?:\.\d+)?/g).map(String);
    if (numbers.length) {
      this.requestUserBalanceInAbstract();
      this.lastBalanceRequestTime = new Date().getTime();
      window.setTournamentWinnerBalance(numbers[0]);
      window.setTournamentWinnerNotif(true);
    }
  }

  showJackpotNotification(msg) {
    const numbers = msg.match(/[+-]?\d+(?:\.\d+)?/g).map(String);
    const valute = msg.match(/[a-zA-Z]+/).map(String);

      let jackpotLevel, jackpotMoney, jackpotValute;

      if (!valute) {
        jackpotValute = '';
      } else {
        if (!valute.length) {
          jackpotValute = '';
        } else {
          jackpotValute = valute[0];
        }
      }

      numbers.forEach((number) => {
        if (number.indexOf(".") === -1) {
          jackpotLevel = number;
        } else {
          jackpotMoney = number;
        }
      });

      this.jackpotValue += Number(jackpotMoney);

      window.setJackpotValue(jackpotMoney);
      window.setShowJackpotPanel(jackpotLevel);
  }

  showSomeOneWonJackpot(msg) {
    const strings = msg.split(';').map(String);

      let jackpotLevel, jackpotMoney, jackpotName, jackpotType, message;
  
      if (strings) {
        if (strings.length > 2) {
          jackpotLevel = strings[0];
          jackpotMoney = strings[1];
          jackpotName = strings[2];

         if (Number(jackpotLevel) === 1) {
          jackpotType = 'Jackpot';
         } 

         if (Number(jackpotLevel) === 2) {
          jackpotType = 'Rocketpot';
         } 

         if (Number(jackpotLevel) === 3) {
          jackpotType = 'Boosterpot';
         }

         message = `${jackpotType} ${translate('winner')} ${jackpotName} ${translate('wins')} ${Number(Number(jackpotMoney) * Number(this.myExchangeRate)).toFixed(this.noOfDecimals)} ${this.isCurrencyVisible ? this.myCurrency : ""}`;
         this.localErrors(message);
        }
      }
  }

  toggleAutoBet(buttonIndex, ticketId, autoBet) {
    if (ticketId) {
      this.myBetsByRound.map((bet) => {
        if (bet?.ticket_id === ticketId) {
          bet.autoBbet = autoBet;
        }
  
        return bet;
      });
      window.setMyBetsByRound(this.myBetsByRound);
      storage.set('myBetsByRound',this.myBetsByRound); 
      this.checkAutoBetProgress(buttonIndex)  
    }
  }

  setAdvancedBalanceDiff(buttonIndex, balanceDiff) {
    this.advancedBalanceDiff[buttonIndex].balance = balanceDiff;
    try {
      window.forceButton0BallanceDiffRerender()
    } catch (error) {
      console.info("unable to set AdvancedBalanceDiff on button 0")
    }
    try {
      window.forceButton1BallanceDiffRerender()
    } catch (error) {
      console.info("unable to set AdvancedBalanceDiff on button 1")
    }
  }

  notification(data) {
    switch (data.type) {
      case NOTIFICATION.FREETICKET:
        this.showFreeTicketsNotification(data.msg);
        break;
      case NOTIFICATION.PROMOCREDIT:
        this.showPromoCreditNotification(data.msg);
        break;
      case NOTIFICATION.TOURNAMENTAWARD:
        this.showTournamentWinnerNotification(data.msg);
        break;
      case NOTIFICATION.JACKPOTWINNER:
        this.showJackpotNotification(data.msg);
        break;
      case NOTIFICATION.SOMEONEWONJACKPOT:
        this.showSomeOneWonJackpot(data.msg);
        break;
      default:
        break;
    }
  }

  claimMeteorTicketAck(data) {
    this.showMeteorShowerErrorMessage(data);
  }
}



 export const urlParams = new URLSearchParams(window.location.search);

 const uid = urlParams.get('uid');
 const companyId = urlParams.get('companyId');
 const token = urlParams.get('token');
 const currency = urlParams.get('currency');
 export let language = acceptedLang(urlParams.get('language') || urlParams.get('lang')) ? urlParams.get('language') || urlParams.get('lang') : "en";
 const backUrl = urlParams.get('backUrl');
 const chatRoom = urlParams.get('chatRoom');
 const countryCode = urlParams.get('countryCode');
 const autoBet = urlParams.get('autobet');
 const autoCashout = urlParams.get('autocashout');
 const hideHeader = urlParams.get('hideHeader');
 const launchId = urlParams.get('launchId');
 const userDefinedUsername = storage.get('userDefinedUsername') || '';
 const version = urlParams.get('version');
 const region = acceptedRegion(urlParams.get('region')) ? urlParams.get('region') : 'default';
 const retail = urlParams.get('region') ==  'retail' ? '1' : '0';

 if (language === 'rs') {
  language = 'sr';
}

let platformParams = {userDefinedUsername: userDefinedUsername, companyId: companyId || 1, launchId, uid: uid || String(new Date().valueOf()),
   token, currency, language, backUrl, chatRoom, countryCode, autoBet, autoCashout, hideHeader, version, region, retail};
const r7RgsWindowBridge = new R7RGSWindowBridge();
const rocketmanTransport = new RocketmanTransport("", platformParams, 1000, false,r7RgsWindowBridge);
export default rocketmanTransport;
