export let langArr = {};
langArr.en = {
'your_insurance_returns_you': 'Your insurance returns you',
'bet_insurance': 'Bet Insurance',
'max_bet_is': 'Max bet is',
'already_active_bet_of': 'and there is already active bet of',
'1_from_game_history': '1. From game history',
'2_in_settings_window': '2. In settings window',
'3_online_calculator': '3. Online calculator',
'active_tournament_1': 'Active tournament ',
'active_tournament_2': 'has no bets yet.',
'add_another_bet': 'Add another bet',
'advanced_autoplay': 'ADVANCED AUTOPLAY',
'advanced_autoplay_for_bet': 'Advanced Autoplay For Bet',
'alien_saucer_free_bets': 'Alien Saucer Free Bets',
'all_bets': 'All Bets',
'all_in_your_hands': 'All in your hands!',
'allow': 'Allow',
'at': 'at',
'austronaut_promo_credit': 'Astronaut Promo Credit',
'auto_play_and_autocash': 'AUTOPLAY AND AUTOCASHOUT ',
'autoplay': 'Autoplay',
'autoplay_and_cashout': '02. Auto Play & auto cashout',
'back_to_home': 'Back to home',
'balance': 'Balance',
'bet': 'BET',
'bet_amount': 'BET AMOUNT',
'bet_and_cashout': '01. Bet & Cashout',
'bet_bets_tab': 'STAKE',
'bet_failed': 'Bet Failed',
'bet_statistic': 'Bet Statistic',
'bet_time': 'Bet Time',
'bet_wins': 'Bet Wins',
'bets': 'Bets',
'biggest_odds': 'Biggest Odds',
'bonus_type': 'BONUS TYPE',
'bonusing_history': 'Bonusing History',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot winners',
'but_remember_paragraph': 'But remember, if you did not have time to make a Cashout before the Lucky Rocket explodes,',
'calculate_raw_mp_to_decimal': 'Calculate Raw Multiplier from decimal, and round it down to nearest integer',
'cancel': 'Cancel',
'cant_post_media_content': 'Can\'t post media content frequently',
'cashed_out': 'Cashed out',
'cashout': 'Cashout',
'cashout_at': 'CASHOUT AT',
'cashout_before': 'Cashout before the rocket explodes and the money is yours!',
'change_avatar': 'Change Avatar',
'change_username': 'Change Username',
'characters_long': 'characters long',
'chat': 'Chat',
'chat_room': 'Chat Room',
'checking_for_spam': 'Checking for spam...',
'close': 'Close',
'coeff': 'COEFF.',
'collect_win': 'Collect Win',
'combined_seed': 'Combined seed',
'company': 'Company',
'congratulations_you_have_won_free_bets': 'Congratulations! You have won Bonus Bets! You can bet',
'convert_first_13_to_decimal': ' Convert first 13 bytes to decimal',
'd': 'd',
'date': 'DATE',
'date_and_time': 'Date and Time',
'deny': 'Deny',
'detailed_game_rules': 'Detailed Game Rules',
'dissconnection_policy': 'DISCONNECTION POLICY',
'easy_as_one_two_three': 'Easy as one, two, three',
'enter_username': 'Please enter desired username',
'error_handling': 'ERROR HANDLING',
'exit_full_screen': 'Exit full-screen mode',
'first': 'first',
'first_time_playing': 'First time playing "Rocketman"?',
'folow_the_three_easy_steps': 'Follow the three easy steps, and enjoy Rocketman! ',
'free_bets': 'FREE BETS',
'free_bets_desc': 'You won free bets! You can take advantage of free bets at any time by pressing the start button.',
'free_bets_header': 'Free Bets',
'from': 'FROM',
'fullscreen_mode': 'For the better user experience the game will start in fullscreen mode.',
'function': 'Function',
'game_functions': 'Game Functions',
'game_history': 'GAME HISTORY',
'game_r_1_paragraph': 'A player may activate the autoplay option where a bet is automatically placed for each new round using the bet amount from the previous bet.',
'game_r_10_paragraph': 'Once the promo credits are awarded, for any bet that a player places, the money will first be deducted from promo credits until it\'s used up, before continuing withdrawing money from the players wallet.',
'game_r_11_paragraph': 'The promo credits can not be cashed out, but any winning on such bets are credited to the player in full.',
'game_r_12_paragraph': 'Promo credits have a 30 day validity period after which they expire and can not be used.',
'game_r_13_paragraph': 'A player may be randomly awarded with free bets. A popup graphic will appear on screen notifying the player about awarded free bets, number of bets and amount per bet.',
'game_r_14_paragraph': 'Once the free bets are awarded player may choose when to start the free bet round. Once the free bets round is started, automatic bet with the specified stake is placed, and it is up to the player to cashout at any given moment. If the player disconnects during free bets, free bets will still take place and they will follow the “Disconnection Policy”.',
'game_r_15_paragraph': 'Free bets have a 30 day validity period after which they expire and can not be used.',
'game_r_16a_paragraph': 'The overall theoretical return to player is ',
'game_r_17_paragraph': 'RTP percentages are continuously monitored and verified by independent third parties.',
'game_r_18_paragraph': 'If connection is lost after a bet is placed, the bet will not be cancelled. If the multiplier reaches 20.000x your bet will be auto cashed out and credits will be applied to your account.',
'game_r_19_paragraph': 'You can chat in game with other players. Enter your message in the chat field, or share your winning bet with other players.',
'game_r_2_paragraph': 'An autocashout multiplier can be entered in the “Autocashout” field, and when entered, the game will automatically cash out if the set multiplier is reached.',
'game_r_20_paragraph': 'Players who misuse the Chat by abusing other players, or by using inappropriate and/or foul language will have their Chat privileges disabled.',
'game_r_21_paragraph': 'Rocketman is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through the drop down menu.',
'game_r_22_paragraph': 'By clicking on the “My Bets” button a player may review his latest bets. For further play history (eg. longer periods), a player may need to access the player history within the gaming platform itself.',
'game_r_23_paragraph': 'In event of an error rounds may not start on time. If that is the case our support team will try to resolve the issue as soon as possible, and resume with regular game rounds. In case of an issue with a game round itself, all bets will be cancelled and stake amount returned to players.',
'game_r_24_paragraph': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration. ',
'game_r_3_paragraph': 'A player can use shortcut keys to quickly place a bet or cashout in the game. The key mappings are as follows',
'game_r_4_paragraph': '1% from each stake is dedicated to a progressive jackpot fund. This fund serves progressive jackpot system',
'game_r_5_paragraph': ' • Jackpot – Highest level, biggest prize, least frequent',
'game_r_6_paragraph': ' • Rocketpot – Medium level, medium prize',
'game_r_7_paragraph': ' • Boosterpot – Lowest level, low prize, most frequent ',
'game_r_8_paragraph': 'All jackpot levels are awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known. Jackpot is won by a first player whose stake causes the jackpot to go over the mystery value. Jackpot winner is announced after the round is finished.',
'game_r_8a_paragraph': 'Note: Jackpot winner can be only one single Rocketman player actively playing in the round when the jackpot has been triggered. There is no possibility that if the player have won the jackpot, would have to share it with any other Rocketman player.',
'game_r_9_paragraph': 'A player may be randomly awarded with promo credits. A popup graphic will appear on screen notifying the player about awarded promo credits and its amount.',
'game_round': 'game rounds!',
'game_rules': 'Game Rules',
'game_rules_1_paragraph': 'Rocketman is a gambling entertainment of new generation. Win many times more in seconds! Rocketmanis built on a',
'game_rules_10_paragraph': ' • Auto Play is activated from “Auto” tab in Bet Panel, by checking “Auto Bet” checkbox. After activated, bets will place automatically, but for cash out, you should press “cashout” button in each round. If you want bet to cash out automatically, then use',
'game_rules_11_paragraph': '• Auto Cashout is available from “Auto” tab on bet panel. After activating, your bet will be automatically cashed out, when reaches entered coefficient.',
'game_rules_2_paragraph': ', which is currently the only real guarantee of honesty in the gambling industry.',
'game_rules_3_paragraph': '• The win multiplier starts at 1x and grows more and more as the Lucky Plane takes off.',
'game_rules_4_paragraph': ' • Your winnings are the multiplier at which you made a Cashout multiplied by your bet. ',
'game_rules_5_paragraph': '• Before the start of each round, our provably fair random number generator generates the coefficient at which the Lucky Plane will fly away. You can check the honesty of this generation by clicking on opposite the result in the History tab.',
'game_rules_6_paragraph': '• Select amount and press “Bet” button to make a bet. ',
'game_rules_7_paragraph': '• You can make two bets simultaneously, by adding second bet panel. To add second bet panel press plus icon, located on top right corner of first bet panel.',
'game_rules_8_paragraph': ' • Press “Cashout” button to cash out your winnings. Your win is bet multiplied by cashout coefficient.',
'game_rules_9_paragraph': '• Your bet is lost, if you didn’t cash out before plane flies away.',
'general_rules': 'GENERAL RULES',
'gifs': 'GIFS',
'go_back': 'Go Back',
'good_job_buddy': 'Good job buddy, keep it going',
'h': 'h',
'hashed_in_sha_512': 'Hashed in SHA512',
'hex': 'Hex',
'hey_username': 'Hey',
'hide_hex_to_decimal': 'Hide hex to decimal conversion',
'highscore': 'High Score',
'how_it_works': 'How it works ?',
'how_to_check': 'How to check ?',
'how_to_play': 'How to play',
'if_the_maximum_multiplier': '• If the maximum multiplier of 20.000 is reached, all open bets are automatically cashed out at the maximum multiplier',
'increase': 'Increase',
'input_bet_amount': 'Input bet amount and place your bet before takeoff.',
'insufficient_funds': 'Insufficient funds',
'it_blew_up': 'It blew up! Oh well...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpot winners',
'key': 'Key',
'load_more': 'LOAD MORE',
'loading': 'Loading',
'loading_next_round': 'Loading next round....',
'loss_gifs': 'LOSS',
'lost_connection': 'Lost Connection',
'make_a_bet': 'Make a Bet',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'maximum_payout': 'Maximum payout is ',
'menu': 'Menu',
'min_bet_is': 'Min bet is',
'month': 'Month',
'more_details': 'More Details',
'more_information': 'More information',
'multiplier': 'Multiplier',
'multiplier_starts': '• The multiplier starts at 1 and goes up to 20.000',
'multipliers_larger': 'Multipliers larger than x20.000 is capped at max multiplier value',
'music_settings': 'Music Settings',
'my_bet_history': 'My Bet History',
'my_bets': 'My Bets',
'my_bonysing_history': 'My Bonusing History',
'network_latency': 'NETWORK LATENCY',
'network_latency_content': 'Network latency is unavoidable part of internet connectivity. We consider all bets and cashouts valid only at the time they reach our server, regardless of the network latency duration.',
'network_latency_title': 'NETWORK LATENCY',
'no_thanks': 'No, thanks!',
'not_right_now': 'Not right now',
'off': 'off',
'oh_snap': 'Oh Snap!',
'ok_close': 'Okay, Close!',
'ok_thanks': 'Okay, Thanks!',
'on': 'on',
'on_loss': 'ON LOSS',
'on_win': 'ON WIN',
'online': 'Online',
'per_bet': 'per bet',
'pick_your_avatar_img': 'Pick your avatar image',
'place_bet_with_last': 'Place a bet with last selected stake ',
'play_now': 'Play Now',
'player_1_seed': 'Player 1 seed',
'player_2_seed': 'Player 2 seed',
'player_3_seed': 'Player 3 seed',
'please_wait_active_bets': 'Please wait for current bets to complete in order to activate free bets',
'points': 'POINTS',
'powered_by': 'Powered by',
'previous_hand': 'Previous Hand',
'prize': 'PRIZE',
'prizes': 'prizes',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'promo_credit': 'Promo Credit',
'promo_credit_condradulations': 'Congratulations! You have won an astronomically good Promo credit that you can use in the game!',
'promo_credits': 'PROMO CREDITS',
'promo_history': 'Promo History',
'provably_fair': 'Provably Fair',
'provably_fair_1_paragraph': '\'Rocketman\' is based on cryptographic technology called \'Provably Fair\'. This technology guarantees 100% fairness of game result. With this technology, it\'s impossible for any third party to interfere in game process.',
'provably_fair_2_paragraph': 'Result of each round (Game\'s \'Fly away\' coefficient ) is not generated on our servers. It\'s generated with help of round players and is fully transparent. This way, it\'s impossible for anyone to manipulate game output. Also, anyone can check and confirm game fairness.',
'provably_fair_3_paragraph': 'Round result is generated from four independent participants of the round',
'provably_fair_4_paragraph': 'When round starts, game merges server seed with three client seeds. From merged symbols is generated SHA512 hash, and from this hash - game result.',
'provably_fair_5_paragraph': 'You can check fairness of each round from game history, by clicking on green icon. In opened window, you will see server seed, 3 pair of players seeds, combined hash and round result.',
'provably_fair_6_paragraph': 'Hashed version of next rounds server seed is available publicly in settings window (In user menu, check "Provably Fair Settings" and then "Next server seed SHA256"). You can also change your client seed here. If you want to participate in round result generation, make sure you are between first 3 players who make bet in that round.',
'provably_fair_7_paragraph': 'Correctness of hash can be checked in any online calculator, for example',
'provably_fair_system': 'provably fair system',
'quick_explanation': 'Quick explenation',
'randomly_generated_server_hash': 'Randomly generated server HASH',
'rank': 'RANK',
'raw_multiplier': 'raw multiplier',
'raw_multiplier_to_edged': 'Raw Multiplier to Edged Multiplier, using',
'remove_additional_bet': 'Remove additional bet',
'reset': 'Reset',
'return_to_player': 'RETURN TO PLAYER',
'rocketman_is_pure_excitement': 'Rocketman is pure excitement! Risk and win.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot winners',
'round': 'Round',
'round_id': 'Round Id',
'round_multiplier': 'Round Multiplier',
'round_results': 'round results',
'rounds': 'rounds',
'rtp': 'RTP',
'rules': 'rules',
'rules_details': 'Tournament rules',
'save': 'Save',
'schedule': 'schedule',
'second': 'second',
'seconds_left': 's left',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'PLEASE WAIT FOR CURRENT BETS TO COMPLETE',
'min_autocashout_value_must_be': 'MIN AUTOCASHOUT VALUE MUST BE',
'min_bet_value_must_be': 'MIN BET VALUE MUST BE',
'server_seed': 'Server Seed',
'session_dissconnected': 'Session Disconnected',
'multisession_not_allowed': 'Multisession is not allowed',
'set_auto_bet': 'SET AUTO BET',
'set_auto_cashout': 'SET AUTO CASHOUT',
'shourtcut_keys': 'SHORTCUT KEYS',
'show_hex_to_deimal': 'Show hex to decimal conversion',
'sound': 'SOUND',
'sound_settigs': 'Sound Settings',
'start_with_autoplay': 'Start with autoplay',
'stop_autoplay': 'STOP AUTOPLAY',
'stop_on_loss': 'STOP ON LOSS',
'stop_on_loss_tooltip': 'Use Stop on Loss to stop autobet after losing a specified amount.<br/> For example, if you start with $100 and you set Stop on Loss to $25',
'stop_on_profit': 'STOP ON PROFIT',
'stop_on_profit_tooltip': 'Use Stop on Profit to stop autobet after profiting a specified amount.<br/> For example, if you start with $100 and you set Stop on Profit to $25',
'there_are_no_active_torunament': 'There are no active tournaments at this moment.',
'third': 'third',
'this_game_is': 'This game is',
'this_tool_recalculates': 'This tool re-calculates the multiplier from the game hash. You may copy/paste any game hash to verify the multiplier.',
'time': 'TIME',
'time_frame': 'Time Frame',
'to': 'TO',
'toggle_animation': 'Toggle Animation',
'top_odds': 'Top Odds',
'top_wins': 'Top Wins',
'total_bets': 'Total Bets',
'tournament': 'Tournament',
'tournament_information': 'Tournament information',
'try_advanced_autoplay': 'Try new advanced autoplay feature! ',
'uncredited_wins': 'Uncredited wins',
'user': 'USER',
'user_and_points': 'USER & POINTS',
'username': 'Username',
'username_must_be_at_least': 'Username must be at least',
'verify': 'Verify',
'vip_popup_text': 'You may choose one of the special VIP avatars available exclusively to our VIP members!',
'vip_popup_title': 'Congratulations on becoming a VIP player',
'waiting_for_the_next_round': 'Waiting for the next round...',
'warnings_are_calculated': ' • Winnings are calculated by multiplying the bet stake by the multiplier at the moment of the Cashout event',
'watch_take_off': 'Watch as your Lucky Rocket takes off and your winnings increase.',
'watch_the_rocket': 'Watch the Rocket',
'we_have_noticed': '`We have noticed it\'s your first time playing "Rocketman". Would you like to see a quick video explaining how to play it?',
'week': 'Week',
'welcome_to_rocketman': 'Welcome to Rocketman - a novel and exciting game! With Rocketman you have a chance of winning up to 20.000x your original stake.',
'what_is_provably_fair': 'What is Provably fair?',
'will_be_deposited': 'Will be deposited to players balance with first regular win',
'win': 'WIN',
'win_amount': 'Win Amount',
'win_gifs': 'WIN',
'winner': 'winner',
'wins': 'wins',
'write_a_replay': 'Write a reply ...',
'year': 'Year',
'yes': 'YES',
'yes_start_the_round': 'Yes, start the round',
'you_have_cashed_out': 'You have cashed out, your win is ',
'you_have_won': 'YOU HAVE WON',
'your_bet_is_lost': 'your bet is lost.',
'current': 'Current',
'previous': 'Previous',
'claim': 'Claim',
'this_feature_will_give': 'This feature will give selected amount to random users in chat',
'amount_per_player': 'Amount Per Player',
'no_of_players': 'Number of players',
'meteor_shower': 'Meteor Shower',
'claim_your_free_bet': 'Claim Your Free Bet',
'meteor_shower_total': 'Meteor Shower Total',
'shower': 'SHOWER',
'total': 'Total',
'meteor_shower_incoming': 'Meteor Shower incoming, look at chat!',
'insurance': 'INSURANCE',
'insurance_paragraph': 'Players may purchase insurance for their bet by clicking on the "Bet Insurance" checkbox beside the bet button. Insurance costs 10% of the total bet amount and will pay back the bet amount to the player should the rocket explode at a 1.00 multiplier.',
'insure_your_bet': 'INSURE YOUR BET',
'bet_insurance_promo_text': 'Select "Bet Insurance" option to insure your bet from rocket crashing at 1.00 multiplier.',
'bet_insurance_promo_text_helper': 'Read more in "Game Rules" section...',
'game_explanation_1': 'Choose your multiplier, and if the game multiplier exceeds your chosen multiplier, your win will be',
'game_explanation_2': 'Your Multiplier x Your Bet',
'retail_max_mp_1': 'Win up to',
'retail_max_mp_2': 'your bet!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'Win x2 on all bets in the „Bonus Launch“ round!',
'multiplier_history': 'Multiplier History',
'will_start_soon': 'will start soon',
'hurry_up_and_place_your_bets': 'Hurry up and place your bets!',
'active_bets': 'Active Bets',
'shop': 'SHOP',
'game_meteor_shower_paragraph': 'Meteor Shower awards one free bet of a predefined value to a predefined number of players. It can be initiated either by the server or by a player. Once Meteor Shower is activated, a message drops in-game, directing users to look at the chat where the Meteor Shower message is located. Players can claim the free bet by clicking on the “Claim” button. Free bets are awarded to players who click on the “Claim” button first. Using autoclickers or any kind of automation software to emulate player clicks and claim prizes is prohibited and may result in a ban from this feature.',
'claming_failed': 'Claiming failed',
'player_on_blacklist': 'The player is on the blacklist',
'you_can_claim_ticket': 'You can claim ticket only if you are active player with regular bets',
'you_already_have_free_tickets': 'You already have free tickets, please spend it first',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
};
langArr.sw = {
'your_insurance_returns_you': 'Bima yako inakurudishia',
'bet_insurance': 'Bima ya Dau',
'max_bet_is': 'Kiasi cha juu cha dau ni',
'already_active_bet_of': 'na tayari kuna dau amilifu la',
'1_from_game_history': '1. Kutoka kwenye historia ya mchezo',
'2_in_settings_window': '2. Katika kidirisha cha mipangilio',
'3_online_calculator': '3. Kikokotoo cha mtandaoni',
'active_tournament_1': 'Shindano amilifu ',
'active_tournament_2': 'bado halina madau.',
'add_another_bet': 'Ongeza dau lingine',
'advanced_autoplay': 'UCHEZAJI WA KIOTOMATIKI WA HALI YA JUU',
'advanced_autoplay_for_bet': 'Uchezaji wa Kiotomatiki wa Hali ya Juu kwa Dau',
'alien_saucer_free_bets': 'Madau ya Bila Malipo ya Alien Saucer',
'all_bets': 'Madau Yote',
'all_in_your_hands': 'Yote mikononi mwako!',
'allow': 'Ruhusu',
'at': 'katika',
'austronaut_promo_credit': 'Salio la Promo ya Mwanaanga',
'auto_play_and_autocash': ' UCHEZAJI WA KIOTOMATIKI NA UTOAJI PESA KIOTOMATIKI ',
'autoplay': 'Uchezaji wa kiotomatiki',
'autoplay_and_cashout': '02. Uchezaji wa kiotomatiki na utoaji pesa kiotomatiki',
'back_to_home': 'Rudi ukurasa wa nyumbani',
'balance': 'Salio',
'bet': 'DAU',
'bet_amount': 'KIASI CHA DAU',
'bet_and_cashout': '01. Weka Dau na Toa Pesa',
'bet_bets_tab': 'KIASI CHA DAU',
'bet_failed': 'Dau Limeshindwa',
'bet_statistic': 'Takwimu ya Dau',
'bet_time': 'Muda wa Dau',
'bet_wins': 'Ushindi wa Dau',
'bets': 'Madau',
'biggest_odds': 'Matumaini Makubwa',
'bonus_type': 'AINA YA BONASI',
'bonusing_history': 'Historia ya Bonasi',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Washindi wa Boosterpot',
'but_remember_paragraph': 'Lakini kumbuka, ikiwa hukuwa na muda wa kutoa pesa kabla ya Roketi ya Bahati kulipuka,',
'calculate_raw_mp_to_decimal': 'Kokotoa Kizidishaji Kibichi kuanzia kwenye desimali, na ukadirie hadi kwenye nambari kamili ya karibu',
'cancel': 'Sitisha',
'cant_post_media_content': 'Huwezi kuchapisha maudhui ya midia mara kwa mara',
'cashed_out': 'Pesa Imetolewa',
'cashout': 'Toa Pesa',
'cashout_at': 'TOA PESA KATIKA',
'cashout_before': 'Toa pesa kabla ya roketi haijalipuka na fedha zitakuwa zako!',
'change_avatar': 'Badilisha Avatar',
'change_username': 'Badilisha Jina la Mtumiaji',
'characters_long': 'urefu wa vibambo',
'chat': 'Soga',
'chat_room': 'Chumba cha Soga',
'checking_for_spam': 'Inaangalia barua taka...',
'close': 'Funga',
'coeff': 'MATUMAINI',
'collect_win': 'Kusanya Ushindi',
'combined_seed': 'Mbegu Mchanganyiko',
'company': 'Kampuni',
'congratulations_you_have_won_free_bets': 'Hongera! Umeshinda Madau ya Bonasi! Unaweza kuweka dau',
'convert_first_13_to_decimal': ' Badilisha baiti 13 za kwanza kuwa desimali',
'd': 'd',
'date': 'TAREHE',
'date_and_time': 'Tarehe na Muda',
'deny': 'Kana',
'detailed_game_rules': 'Sheria za Kina za Mchezo',
'dissconnection_policy': 'SERA YA KUTENGANISHA',
'easy_as_one_two_three': 'Rahisi kama vile moja, mbili, tatu',
'enter_username': 'Tafadhali ingiza jina pendwa la mtumiaji',
'error_handling': 'HITILAFU YA USHUGHULIKIAJI',
'exit_full_screen': 'Toka kwenye modi ya skrini nzima',
'first': 'kwanza',
'first_time_playing': 'Mara ya kwanza kucheza "Rocketman"?',
'folow_the_three_easy_steps': 'Fuata hatua hizi tatu rahisi, na ufurahie Rocketman! ',
'free_bets': 'MADAU YA BILA MALIPO',
'free_bets_desc': 'Umeshinda madau ya bila malipo! Unaweza kutumia madau ya bila malipo wakati wowote kwa kubofya kitufe cha anza.',
'free_bets_header': 'Madau ya Bila Malipo',
'from': 'KUANZIA',
'fullscreen_mode': 'Kwa uzoefu bora wa mtumiaji mchezo utaanza katika modi ya skrini nzima.',
'function': 'Utendaji',
'game_functions': 'Utendaji wa Mchezo',
'game_history': 'HISTORIA YA MCHEZO',
'game_r_1_paragraph': 'Mchezaji anaweza kuamilisha chaguo la kucheza kiotomatiki ambapo dau huwekwa kiotomatiki kwa kila raundi mpya kwa kutumia kiasi cha dau kutoka kwenye dau la awali.',
'game_r_10_paragraph': 'Mara baada ya pesa za promo kutolewa, kwa dau lolote ambalo mchezaji anaweka, fedha itakatwa kwanza kutoka kwenye pesa za promo mpaka itumike, kabla ya kuendelea kutoa fedha kutoka kwenye pochi ya mchezaji.',
'game_r_11_paragraph': 'Pesa za promo haziwezi kutolewa, lakini ushindi wowote kwenye madau hayo huwekwa kwa mchezaji kikamilifu.',
'game_r_12_paragraph': 'Pesa za Promo Ina kipindi cha uhalali cha siku 30 ambapo baada ya hapo muda wake unaisha na haziwezi kutumika.',
'game_r_13_paragraph': 'Mchezaji anaweza kupewa madau ya bila malipo kinasibu. Mchoro ibukizi utaonekana kwenye skrini ukimjulisha mchezaji kuhusu kupewa madau ya bila malipo, idadi ya madau na kiasi kwa kila dau.',
'game_r_14_paragraph': 'Mara baada ya madau ya bila malipo yanayopotolewa, mchezaji anaweza kuchagua wakati wa kuanza raundi ya madau ya bila malipo. Mara baada ya raundi ya madau ya bila malipo kuanza, dau la kiotomatiki lenye kiasi hususa litawekwa, na ni juu ya mchezaji kufanya malipo wakati wowote. Ikiwa mawasiliano ya mchezaji yatakata wakati wa madau ya bila malipo, madau ya bila malipo bado yatawekwa na yatafuata "Sera ya Kutenganisha".',
'game_r_15_paragraph': 'Madau ya bila malipo yana kipindi cha uhalali cha siku 30 ambapo baada ya hapo muda wake utaisha na hayawezi kutumika.',
'game_r_16a_paragraph': 'Kurudi kwa mchezaji kinadharia kwa jumla ni ',
'game_r_17_paragraph': 'Asilimia za RTP zinaendelea kufuatiliwa na kuthibitishwa na wahusika wa tatu wasioegemea upande wowote.',
'game_r_18_paragraph': 'Ikiwa muunganisho utapotea baada ya dau kuwekwa, dau halitafutwa. Ikiwa kizidishaji kitafikia 20.000x pesa ya dau lako itatolewa kiotomatiki na salio litawekwa kwenye akaunti yako.',
'game_r_19_paragraph': 'Unaweza kupiga soga katika mchezo na wachezaji wengine. Andika ujumbe wako kwenye uga wa soga, au shiriki dau lako la kushinda na wachezaji wengine.',
'game_r_2_paragraph': 'Kizidishaji cha Kutoa pesa kiotomatiki kinaweza kuingizwa katika uga wa "Kutoa Pesa Kiotomatiki", na kikiingizwa, mchezo utalipwa kiotomatiki ikiwa kizidishaji kilichowekwa kimefikiwa.',
'game_r_20_paragraph': 'Wachezaji ambao hutumia vibaya Soga kwa kuwanyanyasa wachezaji wengine, au kwa kutumia lugha isiyofaa na/au chafu haki yao ya kutumia Soga italemazwa.',
'game_r_21_paragraph': 'Rocketman imebuniwa ikiwa na muziki maridadi kwa nyuma na madoido ya sauti. Ikiwa unataka kuzima muziki na/au madoido ya sauti unaweza kufanya hivyo kupitia menyu kunjuzi.',
'game_r_22_paragraph': 'Kwa kubofya kitufe cha "Madau Yangu" mchezaji anaweza kukagua madau yake ya hivi karibuni. Kwa historia zaidi ya kucheza (k.v. vipindi virefu), mchezaji anaweza kuhitaji kufikia historia ya mchezaji ndani ya jukwaa la michezo ya kubahatisha.',
'game_r_23_paragraph': 'Hitilafu yoyote ikitokea, raundi haziwezi kuanza kwa wakati. Ikiwa ndivyo ilivyo timu yetu ya msaada itajaribu kutatua changamoto hiyo haraka iwezekanavyo, na kuendelea na raundi za kawaida za mchezo. Kukiwa na changamoto ya raundi ya mchezo, madau yote yatafutwa na kiasi cha dau kurejeshwa kwa wachezaji.',
'game_r_24_paragraph': 'Ukawiaji wa mtandao ni sehemu isiyoweza kuepukika ya muunganisho wa intaneti. Tunazingatia madau na malipo yote kuwa halali pale tu yanapokuwa yamefikia kwenye seva yetu, bila kujali muda wa ukawiaji wa mtandao. ',
'game_r_3_paragraph': 'Mchezaji anaweza kutumia njia za mkato kuweka dau au malipo katika mchezo. Ramani muhimu ni kama ifuatavyo',
'game_r_4_paragraph': 'Asilimia 1 ya kila dau imewekwa kwa mfuko wa jackpot unaoendelea. Mfuko huu unatumika kwa mfumo wa jackpot unaoendelea ',
'game_r_5_paragraph': ' • Jackpot – Ngazi ya juu, zawadi kubwa, si mara kwa mara',
'game_r_6_paragraph': ' • Rocketpot – Ngazi ya kati, zawadi ya kati',
'game_r_7_paragraph': ' • Boosterpot – Ngazi ya chini, zawadi ya chini, mara kwa mara ',
'game_r_8_paragraph': 'Ngazi zote za jackpot zinatolewa kwa kutumia mbinu ya "Fumbo" au "lazima-ishinde-kwa". Thamani ya kila jackpot ya "lazima-ishinde-kwa" inaamuliwa mara tu baada ya jackpot iliyotangulia kushinda na kutengeneza nambari ya kinasibu na kuhifadhiwa kama thamani iliyosimbwa kwa njia fiche kwenye hifadhidata ya mbali. Thamani imesimbwa kwa njia fiche, na hivyo imefichwa na haijulikani. Jackpot inashinda na mchezaji wa kwanza ambaye dau lake husababisha jackpot kwenda juu ya thamani ya fumbo. Mshindi wa Jackpot atangazwa baada ya raundi kukamilika.',
'game_r_8a_paragraph': 'Kumbuka: Mshindi wa Jackpot anaweza tu kuwa mchezaji mmoja wa Rocketman anayecheza kikamilifu katika raundi wakati jackpot imeanza. Hakuna uwezekano wa kwamba ikiwa mchezaji ameshinda jackpot, atagawana na mchezaji mwingine yoyote wa Rocketman.',
'game_r_9_paragraph': 'Mchezaji anaweza kupewa pesa za promo kinasibu. Mchoro ibukizi utaonekana kwenye skrini ukimjulisha mchezaji kuhusu pesa za promo zilizotolewa na kiasi chake.',
'game_round': 'raundi za mchezo!',
'game_rules': 'Sheria za Mchezo',
'game_rules_1_paragraph': 'Rocketman ni burudani ya kamari ya kizazi kipya. Shinda mara nyingi zaidi katika sekunde! Rocketmani imejengwa juu ya',
'game_rules_10_paragraph': ' • Cheza Kiotomatiki inaamilishwa kutoka kwenye kichupo cha "Kiotomatiki" kwenye Paneli ya Dau, kwa kualamisha kisanduku cha "Dau la Kiotomatiki". Baada ya kuamilishwa, madau yatawekwa kiotomatiki, lakini kuhusu kutoa pesa, unapaswa kubofya kitufe cha "toa pesa" katika kila raundi. Kama unataka pesa za dau zitolewe kiotomatiki, basi tumia',
'game_rules_11_paragraph': '• Toa Pesa Kiotomatiki inapatikana kwenye kichupo cha "Kiotomatiki" katika paneli ya dau. Baada ya kuamilisha, pesa za dau lako zitatolewa kiotomatiki, wakati litakapofikia matumaini yaliyowekwa.',
'game_rules_2_paragraph': ', ambapo kwa sasa ndiyo uhakikisho pekee wa uaminifu katika sekta ya kamari.',
'game_rules_3_paragraph': '• kizidishaji cha kushinda kinaanzia 1x na kinakua zaidi na zaidi kadri Ndege ya Bahati inavyopaa.',
'game_rules_4_paragraph': ' • Fedha zako zaushindi ni kizidishaji ambacho ulifanya Utoaji wa Pes kuzidisha kwa dau lako. ',
'game_rules_5_paragraph': '• Kabla ya kuanza kwa kila raundi, kitengenezaji chetu cha kinasibu cha uthibitishaji wa matokeo kinatengeneza matumaini ambayo Ndege ya Bahati itapaa. Unaweza kuangalia uaminifu wa kitengenezaji hiki kwa kubofya mkabala na na matokeo katika kichupo cha Historia.',
'game_rules_6_paragraph': '• Chagua kiasi na ubofye kitufe cha "Dau" ili kuweka dau. ',
'game_rules_7_paragraph': '• Unaweza kuweka madau mawili kwa wakati mmoja, kwa kuongeza paneli ya dau la pili. Ili kuongeza paneli ya dau la pili bofya ikoni ya jumlisha, iliyoko kwenye kona ya juu kulia ya paneli ya dau la kwanza.',
'game_rules_8_paragraph': ' • Bofya kitufe cha "Toa Pesa" ili kutoa pesa zako za ushindi. Ushindi wako ni dau kuzidisha matumaini ya kutoa pesa.',
'game_rules_9_paragraph': '• Dau lako linapoteza, ikiwa hutatoa pesa kabla ya ndege kupaa.',
'general_rules': 'KANUNI ZA JUMLA',
'gifs': 'GIFS',
'go_back': 'Rudi Nyuma',
'good_job_buddy': 'Kazi nzuri rafiki, endelea kusonga mbele',
'h': 'h',
'hashed_in_sha_512': 'Hashi katika SHA512',
'hex': 'Heksi',
'hey_username': 'Hey',
'hide_hex_to_decimal': 'Ficha heksi kwa ubadilishaji wa desimali',
'highscore': 'Matokeo ya Juu',
'how_it_works': 'Jinsi inavyofanya kazi?',
'how_to_check': 'Jinsi ya kuangalia?',
'how_to_play': 'Jinsi ya kucheza',
'if_the_maximum_multiplier': '• Ikiwa kizidishaji cha juu cha 20.000 kitafikiwa, pesa za madau yote ya wazi zitztolewa kiotomatiki kwa kizidishaji cha juu',
'increase': 'Ongeza',
'input_bet_amount': 'Ingiza kiasi cha dau na uweke dau lako kabla ya kupaa.',
'insufficient_funds': 'Fedha hazitoshelezi',
'it_blew_up': 'Imelipuka! Sawa...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Washindi wa Jackpot',
'key': 'Muhimu',
'load_more': 'PAKIA ZAIDI',
'loading': 'Inapakia',
'loading_next_round': 'Inapakia raundi inayofuata...',
'loss_gifs': 'KUPOTEZA',
'lost_connection': 'Muunganisho Umepotea',
'make_a_bet': 'Weka Dau',
'malfunction_voids': 'Uharibifu hubatilisha Malipo na Michezo yote.',
'maximum_payout': 'Upeo wa malipo ni ',
'menu': 'Menyu',
'min_bet_is': 'Kiasi cha chini cha dau ni',
'month': 'Mwezi',
'more_details': 'Maelezo zaidi',
'more_information': 'Taarifa zaidi',
'multiplier': 'Kizidishaji',
'multiplier_starts': '• Kizidishaji kinaanzia 1 na kinapanda juu hadi 20.000',
'multipliers_larger': 'Vizidishaji vikubwa kuliko x20.000 vinawekwa katika thamani ya vizidishaji vya juu',
'music_settings': 'Mipangilio ya Muziki',
'my_bet_history': 'Historia ya Madau Yangu',
'my_bets': 'Madau Yangu',
'my_bonysing_history': 'Historia ya Bonasi Zangu',
'network_latency': 'UKAWIAJI WA MTANDAO',
'network_latency_content': 'Ukawiaji wa mtandao ni sehemu isiyoepukika ya muunganisho wa intaneti. Tunachukulia madau yote na utaoji pesa kuwa halali pale tu yanapokuwa yamefika kwenye seva yetu, bila kujali muda wa ukawiaji wa mtandao.',
'network_latency_title': 'UKAWIAJI WA MTANDAO',
'no_thanks': 'Hapana, asante!',
'not_right_now': 'Si kwa sasa',
'off': 'zima',
'oh_snap': 'Lahaula!',
'ok_close': 'Sawa, Funga!',
'ok_thanks': 'Sawa, Asante!',
'on': 'washa',
'on_loss': 'KWENYE KUPOTEZA',
'on_win': 'KWENYE KUSHINDA',
'online': 'Mtandaoni',
'per_bet': 'kwa kila dau',
'pick_your_avatar_img': 'Chagua picha yako ya avatar',
'place_bet_with_last': 'Weka dau kwa kiasi kilichochaguliwa mwisho ',
'play_now': 'Cheza Sasa',
'player_1_seed': 'Mbegu ya Mchezaji wa 1',
'player_2_seed': 'Mbegu ya Mchezaji wa 2',
'player_3_seed': 'Mbegu ya Mchezaji wa 3',
'please_wait_active_bets': 'Tafadhali subiri madau ya sasa yakamilike ili kuamilisha madau ya bila malipo',
'points': 'POINTI',
'powered_by': 'Imewezeshwa na',
'previous_hand': 'Mkono wa Awali',
'prize': 'ZAWADI',
'prizes': 'zawadi',
'progressive_jackpot': 'JACKPOT ENDELEVU',
'promo_credit': 'Pesa za Promo',
'promo_credit_condradulations': 'Hongera! Umeshinda donge nono la pesa za Promo ambazo unaweza kuzitumia katika mchezo!',
'promo_credits': 'PESA ZA PROMO',
'promo_history': 'Historia ya Promo',
'provably_fair': 'Uthibitishaji wa Matokeo (Provably Fair)',
'provably_fair_1_paragraph': '\'Rocketman\' inajengwa juu ya teknolojia ya kriptografia inayoitwa \'Provably Fair\'. Teknolojia hii inahakikisha usawa wa 100% wa matokeo ya mchezo. Kwa teknolojia hii, haiwezekani kwa wahusika wengine kuingilia mchakato wa mchezo.',
'provably_fair_2_paragraph': 'Matokeo ya kila raundi (Matumaini ya \'Fly away\' ya Mchezo) hayatengenezwi kwenye seva zetu. Yanatengenezwa kwa usaidizi wa wachezaji wa pande zote na ni ya wazi kabisa. Kwa njia hii, haiwezekani kwa mtu yeyote kudhibiti matokeo ya mchezo. Pia, mtu yeyote anaweza kuangalia na kuthibitisha haki ya mchezo.',
'provably_fair_3_paragraph': 'Matokeo ya pande zote yanatengenezwa kutoka kwa washiriki wanne binafsi na wanaojitegemea wa raundi',
'provably_fair_4_paragraph': 'Wakati raundi inaanza, mchezo unaunganisha mbegu za seva na mbegu tatu za mteja. Kutoka kwa alama zilizounganishwa hutengenezwa hashi ya SHA512, na kutokana na hashi hii - matokeo ya mchezo.',
'provably_fair_5_paragraph': 'Unaweza kuangalia uzingatiaji wa haki wa kila raundi kutoka kwenye historia ya mchezo, kwa kubofya ikoni ya kijani. Katika kidirisha kilichofunguliwa, utaona mbegu za seva, jozi 3 za mbegu za wachezaji, hashi pamoja na matokeo ya pande zote.',
'provably_fair_6_paragraph': 'Toleo la hashi la mbegu za seva ya raundi zinazofuata linapatikana hadharani katika kidirisha cha mipangilio (Katika menyu ya watumiaji, angalia "Mipangilio ya Uthibitishaji wa Matokeo" na kisha "Mbegu ya seva ya SHA256 inayofuata"). Unaweza pia kubadilisha mbegu zako za mteja hapa. Ikiwa ungependa kushiriki katika uzalishaji wa matokeo ya raundi, hakikisha uko kati ya wachezaji 3 wa kwanza wanaoweka dau katika raundi hiyo.',
'provably_fair_7_paragraph': 'Usahihi wa hashi unaweza kuangaliwa kwenye kikokotoo chochote cha mtandaoni, kwa mfano',
'provably_fair_system': 'mfumo wa uthibitishaji wa matokeo',
'quick_explanation': 'Ufafanuzi wa haraka',
'randomly_generated_server_hash': 'HASHI ya Seva inayozalishwa kinasibu',
'rank': 'CHEO',
'raw_multiplier': 'kizidishaji kibichi',
'raw_multiplier_to_edged': 'Kizidishaji Kibichi kwa Kizidishaji chenye Makali, kwa kutumia',
'remove_additional_bet': 'Ondoa dau la ziada',
'reset': 'Weka upya',
'return_to_player': 'RUDI KWA MCHEZAJI',
'rocketman_is_pure_excitement': 'Rocketman ni msisimko kamili! Bashiri na ushinde.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Washindi wa Rocketpot',
'round': 'Raundi',
'round_id': 'Kitambulisho cha raundi',
'round_multiplier': 'Kizidishaji cha Raundi',
'round_results': 'matokeo ya raundi',
'rounds': 'raundi',
'rtp': 'RTP',
'rules': 'kanuni',
'rules_details': 'Sheria za shindano',
'save': 'Hifadhi',
'schedule': 'ratiba',
'second': 'pili',
'seconds_left': 's imetoka',
'server_hash': 'HASHI ya Seva',
'please_wait_current_bets': 'TAFADHALI SUBIRI MADAU YA SASA YAKAMILIKE',
'min_autocashout_value_must_be': 'THAMANI YA CHINI YA KUTOA PESA KIOTOMATIKI LAZIMA IWE',
'min_bet_value_must_be': 'THAMANI YA CHINI YA DAU LAZIMA IWE',
'server_seed': 'Mbegu ya Seva',
'session_dissconnected': 'Kipindi Kimekatika',
'multisession_not_allowed': 'Multisession hairuhusiwi',
'set_auto_bet': 'PANGA DAU LA KIOTOMATIKI',
'set_auto_cashout': 'PANGA TOA PESA KIOTOMATIKI',
'shourtcut_keys': 'NJIA YA MKATO',
'show_hex_to_deimal': 'Onesha heksi kwa ubadilishaji wa desimali',
'sound': 'SAUTI',
'sound_settigs': 'Mipangilio ya Sauti',
'start_with_autoplay': 'Anza kwa kucheza kiotomatiki',
'stop_autoplay': 'SITISHA KUCHEZA KIOTOMATIKI',
'stop_on_loss': 'SITISHA KATIKA KUPOTEZA',
'stop_on_loss_tooltip': 'Tumia Sitisha katika Kupoteza ili kusitisha dau la kiotomatiki baada ya kupoteza kiasi ainishwa.<br/> Kwa mfano, ikiwa unaanza na $100 na umepanga Sitisha katika Kupoteza hadi $25',
'stop_on_profit': 'SITISHA KATIKA FAIDA',
'stop_on_profit_tooltip': 'Tumia Sitisha katika Faida ili kusitisha kuweka dau la kiotomatiki baada ya kufaidika kiasi ainishwa.<br/> Kwa mfano, ukianza na $100 na umepanga Sitisha katika Faida hadi $25',
'there_are_no_active_torunament': 'Hakuna mashindano yanayoendelea kwa sasa.',
'third': 'tatu',
'this_game_is': 'Mchezo huu ni',
'this_tool_recalculates': 'Zana hii inajojotoa tena kizidishaji kutoka kwenye heshi ya mchezo. Unaweza kunakili/kubandika heshi yoyote ya mchezo ili kuthibitisha kizidishaji.',
'time': 'MUDA',
'time_frame': 'Ratiba ya Muda',
'to': 'HADI',
'toggle_animation': 'Geuza Uhuishaji',
'top_odds': 'Matumaini ya Juu',
'top_wins': 'Ushindi wa Juu',
'total_bets': 'Jumla ya Madau',
'tournament': 'Shindano',
'tournament_information': 'Taarifa za shindano',
'try_advanced_autoplay': 'Jaribu kipengele kipya cha hali ya juu cha kucheza kiotomatiki! ',
'uncredited_wins': 'Ushindi ambao haujatolewa',
'user': 'MTUMIAJI',
'user_and_points': 'MTUMIAJI & POINTI',
'username': 'Jina la mtumiaji',
'username_must_be_at_least': 'Jina la mtumiaji lazima liwe na angalau',
'verify': 'Thibitisha',
'vip_popup_text': 'Unaweza kuchagua mojawapo ya avatars maalum za VIP zinazopatikana kwa wanachama wetu wa VIP pekee!',
'vip_popup_title': 'Hongera kwa kuwa mchezaji wa VIP',
'waiting_for_the_next_round': 'Inasubiri raundi inayofuata...',
'warnings_are_calculated': ' • Fedha za ushindi zinakokotolewa kwa kuzidisha kiasi cha dau kwa kizidishaji wakati wa tukio la Kutoa Pesa',
'watch_take_off': 'Tazama Roketi yako ya Lucky inavyopaa na ushindi wako unaongezeka.',
'watch_the_rocket': 'Tazama Roketi',
'we_have_noticed': '`Tumegundua ni mara yako ya kwanza kucheza "Rocketman". Je, ungependa kuona video fupi inayoeleza jinsi ya kucheza?',
'week': 'Wiki',
'welcome_to_rocketman': 'Karibu Rocketman – mchezo mpya na wa kusisimua! Ukiwa na Rocketman una nafasi ya kushinda hadi 20.000x ya kiasi cha dau lako la awali.',
'what_is_provably_fair': 'Uthibitishaji wa matokeo ni nini?',
'will_be_deposited': 'Itawekwa kwenye salio la wachezaji pamoja na ushindi wa kwanza wa kawaida',
'win': 'SHINDA',
'win_amount': 'Kiasi cha Ushindi',
'win_gifs': 'SHINDA',
'winner': 'mshindi',
'wins': 'ushindi',
'write_a_replay': 'Andika jibu ...',
'year': 'Mwaka',
'yes': 'NDIO',
'yes_start_the_round': 'Ndio, anza raundi',
'you_have_cashed_out': 'Umefanya malipo, ushindi wako ni ',
'you_have_won': 'UMESHINDA',
'your_bet_is_lost': 'dau lako limepoteza.',
'current': 'Sasa',
'previous': 'Iliyotangulia',
'claim': 'Dai',
'this_feature_will_give': 'Kipengele hiki kitatoa kinasibu kiasi kilichochaguliwa kwa watumiaji katika soga',
'amount_per_player': 'Kiasi kwa Kila Mchezaji',
'no_of_players': 'Idadi ya wachezaji',
'meteor_shower': 'Rasharasha za Kimondo',
'claim_your_free_bet': 'Dai Dau Lako la Bila Malipo',
'meteor_shower_total': 'Rasharasha za Kimondo',
'shower': 'RASHARASHA',
'total': 'Jumla',
'meteor_shower_incoming': 'Rasharasha za Kimondo zinakuja, angalia soga!',
'insurance': 'BIMA',
'insurance_paragraph': 'Wachezaji wanaweza kununua bima kwa dau lao kwa kubofya kisanduku cha "Bima ya Dau" kando ya kitufe cha dau. Bima inagharimu 10% ya jumla ya kiasi cha dau na itamlipa mchezaji kiasi cha dau iwapo roketi italipuka katika kizidishaji cha 1.00.',
'insure_your_bet': 'KATIA BIMA DAU LAKO',
'bet_insurance_promo_text': 'Chagua "Bima ya Dau" ili kulikatia bima dau lako la kwenye rocket crashing kwa kizidishaji cha 1.00.',
'bet_insurance_promo_text_helper': 'Soma zaidi katika sehemu ya "Sheria za Mchezo"...',
'game_explanation_1': 'Chagua kizidishaji chako, na ikiwa kizidishaji cha mchezo kinazidi kizidishaji ulichochagua, ushindi wako utakuwa',
'game_explanation_2': 'Kizidishaji Chako x Dau Lako',
'retail_max_mp_1': 'Shinda hadi',
'retail_max_mp_2': 'dau lako!',
'play_multibets': 'Cheza',
'multibets': 'MBINU',
'bonus_game': 'MCHEZO WA BONUS',
'all_wins_doubled': 'Ushindi wote uliongezeka maradufu!',
'multibets_paragraph_1': 'ili kuongeza odds zako!',
'multibets_paragraph_2': 'Unaweza kuchanganya dau kwenye hadi raundi kumi katika multibet moja na kushinda malipo makubwa!',
'multibets_paragraph_3': 'Kila wakati duru inaposhinda, mgao wa faida kutoka kwa raundi hiyo huwekwa kwenye raundi inayofuata, hadi raundi zote zishinde.',
'bonus_launch': 'Shinda x2 kwenye dau zote kwenye raundi ya "Uzinduzi wa Bonasi"!',
'multiplier_history': 'Historia ya Vizidishaji',
'will_start_soon': 'itaanza hivi karibuni',
'hurry_up_and_place_your_bets': 'Harakisha na uweke madau yako!',
'active_bets': 'Madau Amilifu',
'shop': 'DUKA',
'game_meteor_shower_paragraph': 'Metero Shower hutoa dau moja la bila malipo lenye thamani iliyobainishwa awali kwa idadi iliyobainishwa awali ya wachezaji. Inaweza kuanzishwa ama na seva au na mchezaji. Mara baada ya Meteor Shower kuamilishwa, ujumbe unatumwa wakati wa mchezo, ukiwaelekeza watumiaji kutazama soga ambapo ujumbe wa Meteor Shower unapatikana. Wachezaji wanaweza kudai dau la bila malipo kwa kubofya kitufe cha "Dai". Madau ya bila malipo huzawadiwa kwa wachezaji wanaobofya kitufe cha "Dai" kwanza. Kutumia vibofya kiotomatiki au aina yoyote ya programu ya otomatiki kuiga mibofyo ya wachezaji na kudai zawadi ni marufuku na kunaweza kusababisha kupigwa marufuku katika kipengele hiki.',
'claming_failed': 'Imeshindikana Kudai',
'player_on_blacklist': 'Mchezaji yupo kwenye orodha ya waliozuiwa',
'you_can_claim_ticket': 'Unaweza kudai tiketi ikiwa tu wewe ni mchezaji amilifu kwenye madau ya kawaida',
'you_already_have_free_tickets': 'Tayari una tiketi za bila malipo, tafadhali zitumie kwanza',
'please_play_the_game_in_portait_mode': 'Tafadhali cheza mchezo katika hali wima.',
};
langArr.zu = {
'your_insurance_returns_you': 'Umshwalense wakho uyakubuyisela',
'bet_insurance': 'Umshwalense Wokubheja',
'max_bet_is': 'Umkhawulo wokubheja uthi',
'already_active_bet_of': 'futhi sekukhona kakade ukubheja okusebenzayo kwe-',
'1_from_game_history': '1. Kusukela emlandweni wegeyimu',
'2_in_settings_window': '2. Ewindini lamasethingi',
'3_online_calculator': '3. I-calculator eku-inthanethi',
'active_tournament_1': 'Ithonamenti eqhubekayo ',
'active_tournament_2': 'ayinakho ukubheja okwamanje.',
'add_another_bet': 'Faka okunye ukubheja',
'advanced_autoplay': 'UKUDLALA OKUZENZEKELAYO OKUTHUTHUKISIWE',
'advanced_autoplay_for_bet': 'Ukudlala Okuzenzekelayo Okuthuthukisiwe Kokubheja',
'alien_saucer_free_bets': 'Ukubheja Kwamahhala Kwe-Alien Saucer',
'all_bets': 'Konke Ukubheja',
'all_in_your_hands': 'Konke kusezandleni zakho!',
'allow': 'Vumela',
'at': 'kokuthi',
'austronaut_promo_credit': 'Ikhredithi Yephromo Ye-Astronaut',
'auto_play_and_autocash': 'UKUDLALA OKUZENZEKELAYO NE-CASHOUT EZENZEKELAYO ',
'autoplay': 'Ukudlala okuzenzekelayo',
'autoplay_and_cashout': '02. Ukudlala Okuzenzekelayo ne-cashout ezenzekelayo',
'back_to_home': 'Buyela ekuqaleni',
'balance': 'Ibhalansi',
'bet': 'UKUBHEJA',
'bet_amount': 'INANI LOKUBHEJA',
'bet_and_cashout': '01. Ukubhejat Ne-cashout',
'bet_bets_tab': 'ISITEKI',
'bet_failed': 'Ukubheja Kuhlulekile',
'bet_statistic': 'Isibalo Sokubheja',
'bet_time': 'Isikhathi Sokubheja',
'bet_wins': 'Ukubheja Okuwinile',
'bets': 'Ukubheja',
'biggest_odds': 'Amathuba Amakhulu',
'bonus_type': 'UHLOBO LWEBHONASI',
'bonusing_history': 'Umlando Webhonasi',
'boosterpot': 'I-Boosterpot',
'boosterpot_winners': 'Abawine i-Boosterpot',
'but_remember_paragraph': 'Kodwa khumbula, uma ungazange uthole isikhathi sokwenza i-Cashout ngaphambi kokuthi i-Lucky Rocket iqhume,',
'calculate_raw_mp_to_decimal': 'Bala i-Raw Multiplier ukusuka kudesimali, futhi uyifinyeze ibe inombolo ephelele eseduze',
'cancel': 'Khansela',
'cant_post_media_content': 'Ayikwazi ukuphosta okuqukethwe kwemidiya njalo',
'cashed_out': 'Ukhiphe imali',
'cashout': 'I-cashout',
'cashout_at': 'I-CASHOUT KOKUTHI',
'cashout_before': 'Sebenzisa i-Cashout ngaphambi kokuthi irokhethi iqhume futhi imali eyakho!',
'change_avatar': 'Shintsha I-Avatar',
'change_username': 'Shintsha Igama Lomsebenzisi',
'characters_long': 'izinhlamvu zinde',
'chat': 'Ingxoxo',
'chat_room': 'Igumbi Lengxoxo',
'checking_for_spam': 'Ihlola i-spam...',
'close': 'Vala',
'coeff': 'I-COEFF.',
'collect_win': 'Qoqa Okuwinile',
'combined_seed': 'Imbewu ehlanganisiwe',
'company': 'Inkampani',
'congratulations_you_have_won_free_bets': 'Siyakuhalalisela! Uwine Ibhonasi Yokubheja! Ungabheja',
'convert_first_13_to_decimal': ' Guqula amabhayithi angu-13 okuqala abe yidesimali',
'd': 'd',
'date': 'USUKU',
'date_and_time': 'Usuku Nesikhathi',
'deny': 'Nqaba',
'detailed_game_rules': 'Imithetho Yegeyimu Eningiliziwe',
'dissconnection_policy': 'INQUBOMGOMO YOKUNQAMULA',
'easy_as_one_two_three': 'Kulula njengokuthi, kunye, kubili, kuthathu',
'enter_username': 'Sicela ufake igama lomsebenzisi olithandayo',
'error_handling': 'UKUSINGATHA IPHUTHA',
'exit_full_screen': 'Phuma kumodi yesikrini esigcwele',
'first': 'okokuqala',
'first_time_playing': 'Ingabe uyaqala ukudlala okuthi "Rocketman"?',
'folow_the_three_easy_steps': 'Landela izinyathelo ezintathu ezilula, futhi ujabulele i-Rocketman! ',
'free_bets': 'UKUBHEJA KWAMAHHALA',
'free_bets_desc': 'Uwine ukubheja kwamahhala! Ungasebenzisa ngokugcwele ukubheja kwamahhala nganoma yisiphi isikhathi ngokucindezela inkinobho yokuqalisa.',
'free_bets_header': 'Ukubheja Kwamahhala',
'from': 'KUSUKA',
'fullscreen_mode': 'Ukuze umsebenzisi ayijabulele kangcono igeyimu izoqala kumodi yesikrini esigcwele.',
'function': 'Umsebenzi',
'game_functions': 'Ukusebenza Kwegeyimu',
'game_history': 'UMLANDO WEGEYIMU',
'game_r_1_paragraph': 'Umdlali angase akhethe i-autoplay lapho ukubheja kufakwa ngokuzenzekelayo emjikelezweni ngamunye omusha kusetshenziswa inani lokubheja lokubheja okwedlule.',
'game_r_10_paragraph': 'Uma amakhredithi ephromo eseklonyelisiwe, kunoma yikuphi ukubheja okufakwe umdlali, imali izoqale ithathwe kumakhredithi ephromo kuze kube yilapho isisetshenzisiwe yonke, ngaphambi kokuqhubeka nokukhipha imali kuwolethi wabadlali.',
'game_r_11_paragraph': 'Amakhredithi ephromo awakwazi ukukhishwa, kodwa noma yikuphi ukuwina okuthole ekubhejeni okunjalo kufakelwa umdlali ngokuphelele.',
'game_r_12_paragraph': 'Amakhredithi ephromo asebenza izinsuku ezingu-30 ngemva kwalokho aphelelwa isikhathi futhi ngeke esakwazi ukusetshenziswa.',
'game_r_13_paragraph': 'Umdlali angase aklonyeliswe ngokungahleliwe ngokubheja kwamahhala. Umfanekiso ozivelelayo uzovela esikrinini ukuze wazise umdlali mayelana nokubheja kwamahhala okuklonyelisiwe, inani lokubheja nentengo ngokubheja ngakunye.',
'game_r_14_paragraph': 'Uma ukubheja kwamahhala sekuklonyelisiwe umdlali angase akhethe ukuthi uqala nini umjikelezo wokubheja kwamahhala. Uma umjikelezo wokubheja kwamahhala usuqalile, ukubheja okuzenzekelayo onesiteki esishiwo kuyafakwa, futhi kukumdlali ukukhetha ukuthi uyikhipha nini imali. Uma uma umdlali enqamuka phakathi nokubheja kwamahhala, ukubheja kwamahhala kusazoqhubeka futhi bazolandela “Inqubomgomo Yokunqamuka”.',
'game_r_15_paragraph': 'Ukubheja kwamahhala kusebenza izinsuku ezingu-30 ngemva kwalokho kuphelelwa isikhathi futhi ngeke kusakwazi ukusetshenziswa.',
'game_r_16a_paragraph': 'Inani lokubuyela kumdlali ngokomthetho lingu ',
'game_r_17_paragraph': 'Amaphesenti e-RTP agadwa ngokuqhubekayo futhi aqinisekiswa izinkampani zangaphandle ezizimele.',
'game_r_18_paragraph': 'Uma uxhumano lulahleka ngemva kokubheja, ukubheja ngeke kukhanselwe. Uma isiphindi sifinyelela ku-20.000x lokho okubhejile kuzokhishwa ngokuzenzekelayo futhi amakhredithi azosetshenziswa ku-akhawunti yakho.',
'game_r_19_paragraph': 'Ungakwazi ukuxoxa egeyimini nabanye abadlali. Faka umyalezo esikhaleni sengxoxo, noma wabelane ngokubheja kwakho okuwinile nabanye abadlali.',
'game_r_2_paragraph': 'Isiphindi se-autocashout singafakwa esikhaleni esithi "Autocashout", futhi uma sifakiwe, igeyimu izokhipha imali ngokuzenzekelayo uma isiphindi esisethiwe sifinyelelwa.',
'game_r_20_paragraph': 'Abadlali abayisebenzisa kabi Ingxoxo ngokuthi bahlukumeze abanye abadlali, noma ngokusebenzisa ulimi olungafanele kanye/noma olungcolile bazovalelwa amalungelo abo eNgxoxo.',
'game_r_21_paragraph': 'I-Rocketman iklanywe yaba nomculo omnandi okhalela ngemuva nemisindo yokufakelwa ethinta ingqondo. Uma ufisa ukuwuvala umculo kanye/noma imisindo yokufakelwa ungakwenza lokhu ngemenyu yokwehlisela phansi.',
'game_r_22_paragraph': 'Ngokuchofoza inkinobho ethi “My Bets” umdlali angase abuyekeze ukubheja kwakhe kwakamuva. Ukuze uthole umlando wokudlala owengeziwe (isib. izikhathi ezide), umdlali angase adinge ukufinyelela umlando womdlali ngaphakathi kwenkundla yokudlala ngokwayo.',
'game_r_23_paragraph': 'Esimweni lapho kunephutha imijikelezo ingase ingaqali ngesikhathi. Uma kunjalo ithimba lethu losekelo lizozama ukuxazulula inkinga ngokushesha ngokunokwenzeka, futhi uqhubeke ngemijikelezo yegeyimu evamile. Uma kunenkinga yomjikelezo wegeyimu ngokwawo, konke ukubheja kuzokhanselwa futhi inani lesiteki lizobuyiselwa kubadlali.',
'game_r_24_paragraph': 'Ukunensa kwenethiwekhi kuyingxenye engagwemeki yoxhumano lwe-inthanethi. Sibheka konke ukubheja nama-cashout njengokusebenzayo ngesikhathi kufika kuseva yethu, naphezu kwesikhathi sokunensa kwenethiwekhi. ',
'game_r_3_paragraph': 'Umdlali angasebenzisa izinkinobho zezinqamuleli ukuze abheje noma akhiphe imali egeyimini. Ukuma kwezinkinobho kukanje',
'game_r_4_paragraph': 'I-1% yayo yonke imali ebekwayo inikezelwa kufund i-jackpot eqhubekayo. Leli lifa lisebenza ohlelweni lwe-jackpot eqhubekayo',
'game_r_5_paragraph': ' • I-Jackpot – Izinga eliphezulu, umklomelo omkhulu, iyimvelakancane',
'game_r_6_paragraph': ' • I-Rocketpot – Izinga elimaphakathi, umklomelo omaphakathi',
'game_r_7_paragraph': ' • I-Boosterpot – Izinga eliphansi, umklomelo ophansi, ivame kakhulu ',
'game_r_8_paragraph': 'Wonke amazinga e-jackpot aklonyeliswa kusetshenziswa izindlela "Eziyimfihlo" noma "ezinomkhawulo". Inani layo yonke i-jackpot "enomkhawulo" linqunywa ngokushesha ngemva kokuthi i-jackpot eyandulele isiwiniwe ngesikhiqizi senombolo engahleliwe futhi kugcinwe enanini elinekhodi kudathabheyisi ekude. I-Jackpot iwinwa umdlali wokuqala onesiteki esibangela ukuthi i-jackpot yeqe inani eliyimfihlo. Owine i-Jackpot umenyezelwa ngemva kokuphela komjikelezo.',
'game_r_8a_paragraph': 'Qaphela: Owina i-Jackpot kungaba umdlali oyedwa kuphela we-Rocketman odlala ngokuqhubekayo kumjikelezo uma i-jackpot isiqalisiwe. Alikho ithuba lokuthi uma umdlali esewinile i-jackpot, kufanele ayihlanganyele nabanye abadlali be-Rocketman.',
'game_r_9_paragraph': 'Umdlali angase ngokungahleliwe aklonyeliswe amakhredithi ephromo. Umfanekiso ozivelelayo uzovela esikrinini ukuze kwaziswe umdlali mayelana namakhredithi ephromo aklonyelisiwe kanye nenani layo.',
'game_round': 'imijikelezo yegeyimu!',
'game_rules': 'Imithetho Yegeyimu',
'game_rules_1_paragraph': 'I-Rocketman iwukuzijabulisa kokugembula kwesizukulwane esisha. Wina izikhathi eziningi emizuzwaneni! I-Rocketman yakhelwe kokuthi',
'game_rules_10_paragraph': ' • Ukudlala Okuzenzekelayo kusetshenziswa usebenzisa ithebhu ethi “Auto” Kuphaneli Yokubheja, ngokuqoka ibhokisi lokuqoka elithi “Auto Bet”. Ngemva kokuvulwa, ukubheja kuzofakwa ngokuzenzekelayo, kodwa ekukhishweni kwemali, kufanele ucindezele inkinobho ethi “cashout” kumjikelezo ngamunye. Uma ufuna ukuthi okubhejile kukhokhe ngokuzenzekelayo, sebenzisa okuthi',
'game_rules_11_paragraph': '• I-Cashout Ezenzekelayo itholakala kuthebhu ethi “Auto” kuphaneli yokubheja. Ngemva kokusetshenziswa, okubhejile kuzokhokha ngokuzenzekelayo, uma kufika enanini lokukhokha elifakiwe.',
'game_rules_2_paragraph': ', okwamanje okuwukuphela kwesiqinisekiso sokwethembeka embonini yokugembula.',
'game_rules_3_paragraph': '• Isiphindi sokuwina siqala ku-1x futhi siyakhula kakhulu njengoba Indiza Yenhlanhla isuka.',
'game_rules_4_paragraph': ' • Okuwinile kuyisiphindi ofake kuso i-Cashout ephindwa ngalokho okubhejile. ',
'game_rules_5_paragraph': '• Ngaphambi kokuqala komjikelezo ngamunye, isikhiqizi sethu senombolo engahleliwe sikhiqiza inani elizokwenza ukuthi Indiza Yenhlanhla indize ihambe. Ungahlola ubuqotho balokhu kukhiqiza ngokuchofoza maqondana nomphumela kuthebhu Yomlando.',
'game_rules_6_paragraph': '• Khetha inani bese ucindezela inkinobho ethi “Bet” ukuze ubheje. ',
'game_rules_7_paragraph': '• Ungakwazi ukufaka ukubheja okubili ngesikhathi esifanayo, ngokufaka iphaneli yesibili. Ukuze ufake iphaneli yokubheja kwesibili cindezela isithonjana sophawu lokuhlanganisa, esitholakala ekhoneni lesokudla eliphezulu lephaneli yokubheja yokuqala.',
'game_rules_8_paragraph': ' • Cindezela inkinobho ethi “Cashout” ukuze ukhiphe imali oyiwinile. Okuwinayo kuhlanganisa lokho okubhejile kuphindwa ngenani le-cashout.',
'game_rules_9_paragraph': '• Lokho okubhejile kuyadliwa, uma ungayikhiphi imali ngaphambi kokuthi indiza ihambe.',
'general_rules': 'IMITHETHO EVAMILE',
'gifs': 'GIFS',
'go_back': 'Buyela Emuva',
'good_job_buddy': 'Umsebenzi omuhle mngane wami, qhubeka',
'h': 'h',
'hashed_in_sha_512': 'Ifakwe ku-SHA512',
'hex': 'Hex',
'hey_username': 'Sawubona',
'hide_hex_to_decimal': 'Hide hex to decimal conversion',
'highscore': 'Umphumela Ophezulu',
'how_it_works': 'Indlela okusebenza ngayo?',
'how_to_check': 'Indlela yokuhlola?',
'how_to_play': 'Indlela yokukhokha',
'if_the_maximum_multiplier': '• Uma umkhawulo wesiphindi ka-20.000 ufinyelelwa, konke ukubheja okuvuliwe kukhokhwa ngokuzenzekelayo emkhawulweni wesiphindi',
'increase': 'Nyusa',
'input_bet_amount': 'Faka inani lokubheja futhi ubheje ngaphambi kokuthi indize.',
'insufficient_funds': 'Imali ayanele',
'it_blew_up': 'Iqhumile! Akusenani...',
'jackpot': 'I-Jackpot',
'jackpot_winners': 'Abawine i-Jackpot',
'key': 'Ukhiye',
'load_more': 'LAYISHA OKWENGEZIWE',
'loading': 'Iyalayisha',
'loading_next_round': 'Ilayisha umjikelezo olandelayo....',
'loss_gifs': 'UHLULIWE',
'lost_connection': 'Ulahlekelwe Uxhumano',
'make_a_bet': 'Yenza Ukubheja',
'malfunction_voids': 'Ukungasebenzi kahle kuhoxisa konke Ukukhokha Nokudlala.',
'maximum_payout': 'Inkokhelo enkulu ingu ',
'menu': 'Imenyu',
'min_bet_is': 'Inani eliphansi ongalibheja',
'month': 'Inyanga',
'more_details': 'Imininingwane Eyengeziwe',
'more_information': 'Ulwazi olwengeziwe',
'multiplier': 'Isiphindi',
'multiplier_starts': '• Isiphindi siqala ku-1 futhi iya ku-20.000',
'multipliers_larger': 'Iziphindi ezinkulu kuno-x20.000 zinqandwa enanini lomkhawulo wesiphindi',
'music_settings': 'Amasethingi Omculo',
'my_bet_history': 'Umlando Wokubheja Kwami',
'my_bets': 'Ukubheja Kwami',
'my_bonysing_history': 'Umlando Webhonasi Yami',
'network_latency': 'UKUNENSA KWENETHIWEKHI',
'network_latency_content': 'Ukunensa kwenethiwekhi kuyingxenye engagwemeki yoxhumano lwe-inthanethi. Sibheka konke ukubheja nama-cashout njengokusebenzayo ngesikhathi kufika kuseva yethu, naphezu kwesikhathi sokunensa kwenethiwekhi.',
'network_latency_title': 'UKUNENSA KWENETHIWEKHI',
'no_thanks': 'Cha, ngiyabonga!',
'not_right_now': 'Hhayi manje',
'off': 'kuvaliwe',
'oh_snap': 'Eish!',
'ok_close': 'Kulungile, Vala!',
'ok_thanks': 'Kulungile, Ngiyabonga!',
'on': 'kuvuliwe',
'on_loss': 'VULA UKUHLULWA',
'on_win': 'VULA UKUWINA',
'online': 'Ku-inthanethi',
'per_bet': 'ngokubheja ngakunye',
'pick_your_avatar_img': 'Khetha isithombe se-avatar yakho',
'place_bet_with_last': 'Bheja ngesiteki esigcine ukukhethwa ',
'play_now': 'Dlala Manje',
'player_1_seed': 'Imbewu yoMdlali 1',
'player_2_seed': 'Imbewu yoMdlali 2',
'player_3_seed': 'Imbewu yoMdlali 3',
'please_wait_active_bets': 'Sicela ulinde ukuthi ukubheja kwamanje kuqedelwe ukuze uqalise ukubheja kwamahhala',
'points': 'AMAPHUZU',
'powered_by': 'Inikwe amandla okuthi',
'previous_hand': 'Isandla Esidlule',
'prize': 'UMKLOMELO',
'prizes': 'imiklomelo',
'progressive_jackpot': 'I-JACKPOT ETHUTHUKISIWE',
'promo_credit': 'Ikhredithi Yephromo',
'promo_credit_condradulations': 'Siyakuhalalisela! Uwine ikhredithi Yephromo enhle kakhulu ongayisebenzisa kugeyimu!',
'promo_credits': 'AMAKHREDITHI EPHROMO',
'promo_history': 'Umlando Wephromo',
'provably_fair': 'I-Provably Fair',
'provably_fair_1_paragraph': '\'I-Rocketman\' isekelwe ebuchwephesheni obu-cryptographic obubizwa ngokuthi \'Provably Fair\'. Lobu buchwepheshe buqinisekisa ukuthi umphumela wegeyimu awuchemile ngokuphelele. Ngalobu buchwepheshe, akunakwenzeka ukuthi umuntu wangaphandle aphazamise inqubo yegeyimu.',
'provably_fair_2_paragraph': 'Umphumela womjikelezo ngamunye (I-cofficient ethi \'Ndiza\' yegeyimu) ayiqinisekisiwe kumaseva ethu. Ikhiqizwa ngosizo lwabadlali bomjikelezo futhi ayifihli lutho. Ngale ndlela, akunakwenzeka ukuthi noma ubani athonye umphumela wegeyimu. Futhi, noma ubani angakwazi ukuhlola nokuqinisekisa ukungachemi kwegeyimu.',
'provably_fair_3_paragraph': 'Umphumela womjikelezo ukhiqizwa kubabambiqhaza abane abazimele bomjikelezo',
'provably_fair_4_paragraph': 'Uma umjikelezo uqala, igeyimu ihlanganisa imbewu yeseva ngezimbewu zamaklayenti amathathu. Kusuka kuzimpawu ezihlanganisiwe kukhiqizwa i-SHA512 hash, futhi kusukela kule hash - umphumela wegeyimu.',
'provably_fair_5_paragraph': 'Ungahlola ukungachemi komjikelezo ngamunye emlandweni wegeyimu, ngokuchofoza isithonjana esiluhlaza. Efasiteleni elivuliwe, uzobona imbewu yeseva, amapheya angu-3 ezinhlamvu zabadlali, i-hash ehlanganisiwe nomphumela womjikelezo.',
'provably_fair_6_paragraph': 'Inguqulo eheshisiwe yembewu yeseva yomjikelezo olandelayo itholakala esidlangalaleni efasiteleni lamasethingi (Kumenyu yomsebenzisi, hlola okuthi "Amasethingi E-Provably Fair" bese uthi "Imbewu yeseva elandelayo ithi SHA256"). Ungakwazi futhi ukushintsha imbewu yeklayenti lapha. Uma ufuna ukubamba iqhaza ekukhiqizweni komphumela womjikelezo, qiniseka ukuthi uphakathi kwabadlali bokuqala abangu-3 ababheja kulowo mjikelezo.',
'provably_fair_7_paragraph': 'Ukunemba kwe-hash kungahlolwa kunoma iyiphi i-calculator ye-inthanethi, ngokwesibonelo',
'provably_fair_system': 'isistimu ye-provably fair',
'quick_explanation': 'Incazelo esheshayo',
'randomly_generated_server_hash': 'I-HASH yeseva ekhiqizwe ngokungahleliwe',
'rank': 'ISIKHUNDLA',
'raw_multiplier': 'isiphindi se-raw',
'raw_multiplier_to_edged': 'Isiphindi se-Raw ukuya kusiphindi se-Edged, kusetshenziswa',
'remove_additional_bet': 'Susa ukubheja okwengeziwe',
'reset': 'Setha kabusha',
'return_to_player': 'BUYELA KUMDLALI',
'rocketman_is_pure_excitement': 'I-Rocketman iyinjabulo yangempela! Zama inhlanhla futhi uwine.',
'rocketpot': 'I-Rocket',
'rocketpot_winners': 'Abawinile ku-Rocketpot',
'round': 'Umjikelezo',
'round_id': 'I-Id Yomjikelezo',
'round_multiplier': 'Isiphindi Somjikelezo',
'round_results': 'imiphumela yomjikelezo',
'rounds': 'imijikelezo',
'rtp': 'RTP',
'rules': 'imithetho',
'rules_details': 'Imithetho yethonamethi',
'save': 'Londoloza',
'schedule': 'hlela',
'second': 'okwesibili',
'seconds_left': 's kusele',
'server_hash': 'I-HASH Yeseva',
'please_wait_current_bets': 'SICELA ULINDE UKUBHEJA KWAMANJE KUQEDE',
'min_autocashout_value_must_be': 'INANI LE-AUTOCASHOUT ELIYISISEKELO KUMELE',
'min_bet_value_must_be': 'INANI LOKUBHEJA ELIYISISEKELO KUMELE LIBE',
'server_seed': 'Imbewu Yeseva',
'session_dissconnected': 'Iseshini Inqanyuliwe',
'multisession_not_allowed': 'I-Multisession ayivunyelwe',
'set_auto_bet': 'SETHA UKUBHEJA OKUZENZEKELAYO',
'set_auto_cashout': 'SETHA I-CASHOUT EZENZEKELAYO',
'shourtcut_keys': 'IZINKINOBHO ZESINQAMULELI',
'show_hex_to_deimal': 'Bonisa ukuguqulwa okusuka ku-hex ukuya kudesimali',
'sound': 'UMSINDO',
'sound_settigs': 'Amasethingi Omsindo',
'start_with_autoplay': 'Qala nge-autoplay',
'stop_autoplay': 'MISA I-AUTOPLAY',
'stop_on_loss': 'MISA EKUHLULWENI',
'stop_on_loss_tooltip': 'Sebenzisa okuthi Misa Ekuhlulweni ukuze umise ukubheja okuzenzakalelayo ngemva kokulahlekelwa inani elishiwo.<br/> Ngokwesibonelo, uma uqala ngo-$100 bese usetha okuthi Misa Ekuhlulweni kuya ku-$25',
'stop_on_profit': 'MISA ENZUZWENI',
'stop_on_profit_tooltip': 'Sebenzisa okuthi Misa Inzuzo ukuze umise ukubheja ngokuzenzakalelayo ngemva kokuzuza inani elithile.<br/> Ngokwesibonelo, uma uqala ngo-$100 bese usetha okuthi Misa Inzuzo ibe ngu-$25.',
'there_are_no_active_torunament': 'Awekho amathonamenti akhona ngalesi sikhathi.',
'third': 'okwesithathu',
'this_game_is': 'Le geyimu',
'this_tool_recalculates': 'Leli thuluzi libala kabusha isiphindi ku-hash yegeyimu.',
'time': 'ISIKHATHI',
'time_frame': 'Isikhathi Esimisiwe',
'to': 'IYA',
'toggle_animation': 'Guqula Upopayi',
'top_odds': 'Amathuba Aphezulu',
'top_wins': 'Ukuwina Okuphezulu',
'total_bets': 'Ingqikithi Yokubheja',
'tournament': 'Ithonamenti',
'tournament_information': 'Ulwazi lwethonamenti',
'try_advanced_autoplay': 'Zama isici esisha se-autoplay esithuthukisiwe! ',
'uncredited_wins': 'Ukuwina okungakhredithiwe',
'user': 'UMSEBENZISI',
'user_and_points': 'UMSEBENZISI NAMAPHOYINTI',
'username': 'Igama lomsebenzisi',
'username_must_be_at_least': 'Igama lomsebenzisi kufanele okungenani',
'verify': 'Qinisekisa',
'vip_popup_text': 'Ungase ukhethe okungenani enye yama-avathar e-VIP akhethekile atholakalayo ngokukhethekile kumalungu ethu e-VIP!',
'vip_popup_title': 'Siyakubongela ngokuba umdlali oyi-VIP',
'waiting_for_the_next_round': 'Ilinde umjikelezo olandelayo...',
'warnings_are_calculated': ' • Ukuwina kubalwa ngokuphinda isiteki sokubheja ngesiphindi ngesikhathi sesenzakalo se-Cashout',
'watch_take_off': 'Buka i-Lucky Rocket njengoba indiza futhi ukuwina kwakho kukhula.',
'watch_the_rocket': 'Buka i-Rocket',
'we_have_noticed': '`Siqaphele ukuthi uyaqala ukudlala "i-Rocketman". Ungathanda ukubona ividiyo esheshayo echaza indlela yokuyidlala?',
'week': 'Iviki',
'welcome_to_rocketman': 'Siyakwamukela i-Rocketman - igeyimu entsha nejabulisayo! Nge-Rocketman unethuba lokuwina okufika ku-20.000x wesiteki sakho soqobo.',
'what_is_provably_fair': 'Yini i-Provably fair?',
'will_be_deposited': 'Izofakwa kubhalansi yabadlali ngokuwina kokuqala okuvamile',
'win': 'WINA',
'win_amount': 'Wina Inani',
'win_gifs': 'WINA',
'winner': 'owinile',
'wins': 'ukuwina',
'write_a_replay': 'Bhala impendulo ...',
'year': 'Unyaka',
'yes': 'YEBO',
'yes_start_the_round': 'Yebo, qalisa umjikelezo',
'you_have_cashed_out': 'Usukhiphe imali, ukuwina kuthi ',
'you_have_won': 'UWINILE',
'your_bet_is_lost': 'okubhejile kulahlekile.',
'current': 'Okwamanje',
'previous': 'Okwedlule',
'claim': 'Thola',
'this_feature_will_give': 'Lesi sici sizonikeza inani elikhethiwe kubasebenzisi abangahleliwe engxoxweni',
'amount_per_player': 'Inani Ngomdlali Ngamunye',
'no_of_players': 'Inani labadlali',
'meteor_shower': 'I-Meteor Shower',
'claim_your_free_bet': 'Thola Ukubheja Kwakho Kwamahhala',
'meteor_shower_total': 'Ingqikithi Ye-Meteor Shower',
'shower': 'I-SHOWER',
'total': 'Ingqikithi',
'meteor_shower_incoming': 'I-Meteor Shower engenayo, buka ingxoxo!',
'insurance': 'UMSHWALENSE',
'insurance_paragraph': 'Abadlali bangathenga umshwalense ngokubheja kwabo ngokuchofoza ibhokisi elithi "Bet Insurance " eduze kwenkinobho yokubheja. Umshwalense ubiza u-10% wenani eliphelele lokubheja futhi uzobuyisela inani lokubheja kumdlali uma i-rocket iqhuma ekuphindeni okungu-1.00.',
'insure_your_bet': 'THATHA UMSHWALENSE WALOKHO OKUBHEJILE',
'bet_insurance_promo_text': 'Khetha okuthi "Bet Insurance" ukuze uthathe umshwalense wokubheja osiza lapho i-rocket iqhuma ekuphindeni okungu-1.00.',
'bet_insurance_promo_text_helper': 'Funda okwengeziwe engxenyeni ethi "Game Rules"...',
'game_explanation_1': 'Khetha okuphindayo kwakho, futhi uma okuphindayo kwakho kwegeyimu kwedlula okuphindayo kwakho okukhethile, ukuwina kwakho kuzoba',
'game_explanation_2': 'Okuphindayo Kwakho x Lokho Okubhejile',
'retail_max_mp_1': 'Wina kuze kufike',
'retail_max_mp_2': 'kulokho okubhejile!',
'play_multibets': 'Dlala',
'multibets': 'AMANINGI',
'bonus_game': 'IBHONASI UMDLALO',
'all_wins_doubled': 'Konke ukuwina kuphindwe kabili!',
'multibets_paragraph_1': 'ukwandisa amathuba akho!',
'multibets_paragraph_2': 'Ungahlanganisa ukubheja emizuliswaneni efika kweyishumi ube yi-multibet eyodwa futhi uzuze izinkokhelo ezinkulu!',
'multibets_paragraph_3': 'Ngaso sonke isikhathi uma kuwinwa umzuliswano, i-dividend yokuwina kulowo mzuliswano ibhejwa emzuliswaneni olandelayo, kuze kube yilapho yonke imizuliswano iyawina.',
'bonus_launch': 'Wina x2 kukho konke ukubheja emzuliswaneni othi "Ibhonasi Yokwethulwa"!',
'multiplier_history': 'Umlando Wokuphindayo',
'will_start_soon': 'uzoqala maduzane',
'hurry_up_and_place_your_bets': 'Shesha futhi ubheje!',
'active_bets': 'Ukubheja Okuqhubekayo',
'shop': 'ISITOLO',
'game_meteor_shower_paragraph': 'I-Meteor Shower iklomelisa ngokubheja okukodwa kwamahhala kwenenani elinqunyiwe enanini elinqunywe kusengaphambili labadlali. Ingaqaliswa iseva noma umdlali. Uma i-Meteor Shower isisebenza, kungena umyalezo ngaphakathi kwegeyimu, oyala abasebenzisi ukuthi babheke ingxoxo lapho okutholakala khona umyalezo we-Meteor Shower. Abadlali bangathola ukubheja kwamahhala ngokuchofoza inkinobho ethi “Claim”. Ukubheja kwamahhala kunikezwa abadlali abachofoza inkinobho ethi “Claim” kuqala. Ukusebenzisa ama-autoclicker nanoma yiluphi uhlobo lwesofthiwe ezenzelayo ukuze kulingiswe ukuchofoza komdlali futhi kutholwe imiklomelo kwenqatshelwe futhi kungase kuphumele ekususweni kulesi sici.',
'claming_failed': 'Ukuthola kuhlulekile',
'player_on_blacklist': 'Umdlali usohlwini oluvinjelwe',
'you_can_claim_ticket': 'Ungakwazi ukufuna ithikithi kuphela uma ungumdlali oqhubekayo obheja njalo',
'you_already_have_free_tickets': 'Usunawo kakade amathikithi amahhala, sicela uwasebenzise kuqala',
'please_play_the_game_in_portait_mode': 'Ngicela udlale umdlalo kumodi ethe mpo.',
};
langArr.xh = {
'your_insurance_returns_you': 'I-inshorensi yakho ikubuyisela',
'bet_insurance': 'I-inshorensi yokubheja',
'max_bet_is': 'Okona kubheja kukhulu ngu',
'already_active_bet_of': 'kwaye sele kukho ukubheja okukhoyo kwe-',
'1_from_game_history': '1. Ukusuka kwimbali yomdlalo',
'2_in_settings_window': '2. Kwiwindow yeesethingi',
'3_online_calculator': '3. Isibali-manani seintanethi',
'active_tournament_1': 'Itumente esebenzayo ',
'active_tournament_2': 'ayikabhejwa okwangoku.',
'add_another_bet': 'Faka okunye ukubheja',
'advanced_autoplay': 'UKUDLALA NGOKUZENZEKELAYO OKUPHUCULIWEYO',
'advanced_autoplay_for_bet': 'Ukudlala Okuzenzekelayo Okuphuculiweyo Kokubheja',
'alien_saucer_free_bets': 'Ukubheja Kwamahala Kwe-Alien Saucer',
'all_bets': 'Konke Ukubheja',
'all_in_your_hands': 'Konke ezandleni zakho!',
'allow': 'Vumela',
'at': 'e',
'austronaut_promo_credit': 'Ikhredithi Yesophulelo Kasomajukujuku',
'auto_play_and_autocash': 'UKUDLALA NGOKUZENZEKELAYO NOKUKHUPHA IMALI NGOKUZENZEKELAYO ',
'autoplay': 'Ukudlala ngokuzenzekelayo',
'autoplay_and_cashout': '02. Ukudlala ngokuzenzekelayo nokukhupha imali ngokuzenzekelayo',
'back_to_home': 'Buyela ekhaya',
'balance': 'Ibhalansi',
'bet': 'UKUBHEJA',
'bet_amount': 'IXABISO YOKUBHEJA',
'bet_and_cashout': '01. Ukubheja Nokukhupha imali',
'bet_bets_tab': 'ISICHENGE',
'bet_failed': 'Ukubheja Akuphumelelanga',
'bet_statistic': 'Iinkcukacha-manani Zokubheja',
'bet_time': 'Ixesha Lokubheja',
'bet_wins': 'Imiwino Yokubheja',
'bets': 'Ukubheja',
'biggest_odds': 'Amathuba Amakhulu',
'bonus_type': 'UHLOBO LWEBHONASI',
'bonusing_history': 'Ibhonasi Yembali',
'boosterpot': 'I-boosterpot',
'boosterpot_winners': 'Abaphumeleleyo kwi-boosterpot',
'but_remember_paragraph': 'Kodwa khumbula, ukuba khange ube nexesha Lokukhupha imali ngaphambi kokuba iLucky Rocket iqhushumbe,',
'calculate_raw_mp_to_decimal': 'Bala i-Multiplier ekrwada ukusuka kwidesimali, uze uyise kwinani elikufutshane elipheleleyo',
'cancel': 'Rhoxisa',
'cant_post_media_content': 'Awukwazi ukupowusta ikhontenti yemidiya rhoqo',
'cashed_out': 'Imali ikhutshiwe',
'cashout': 'Ukhupha imali',
'cashout_at': 'UKHUPHA IMALI E',
'cashout_before': 'Khupha imali ngaphambi kokuba irokethi iqhushumbe ize imali ibe yeyakho!',
'change_avatar': 'Tshintsha i-Avatar',
'change_username': 'Tshintsha Igama Lomsebenzisi',
'characters_long': 'ubude beekharektha',
'chat': 'Incoko',
'chat_room': 'Igumbi Lokuncokola',
'checking_for_spam': 'Ukukhangela i-spam...',
'close': 'Vala',
'coeff': 'I-COEFF.',
'collect_win': 'Qokelela Ukuwina',
'combined_seed': 'Imbewu edityanisiweyo',
'company': 'Inkampani',
'congratulations_you_have_won_free_bets': 'Sivuyisana nawe! Uwine Ibhonasi Yokubheja! Ungabheja',
'convert_first_13_to_decimal': ' Tshintsha ii-byte eziyi-13 zokuqala zibe yidesimali',
'd': 'd',
'date': 'UMHLA',
'date_and_time': 'Umhla kunye neXesha',
'deny': 'Yala',
'detailed_game_rules': 'Imithetho Yemidlalo Eneenkcukacha',
'dissconnection_policy': 'IPOLISI YOQHWUKO LONXEBELELWANO',
'easy_as_one_two_three': 'Kulula njengokuthi inye, zimbini, zintathu',
'enter_username': 'Nceda ufake igama lomsebenzisi olifunayo',
'error_handling': 'INGXAKI EKUBANJWENI',
'exit_full_screen': 'Phuma kwimowudi yesikrini esigcweleyo',
'first': 'okokuqala',
'first_time_playing': 'Uyaqala ukudlala "i-Rocketman"?',
'folow_the_three_easy_steps': 'Landela la manyathelo mathathu alula, uze wonwabele i-Rocketman! ',
'free_bets': 'UKUBHEJA KWASIMAHLA',
'free_bets_desc': 'Uwine ukubheja kwasimahla! Unokuxhamla ithuba lokubheja simahla nangaliphi na ixesha ngokucofa iqhosha lokuqalisa.',
'free_bets_header': 'Ukubheja Kwamahala',
'from': 'UKUSUKA',
'fullscreen_mode': 'Ukuze wonwatyelwe ngcono ngumsebenzisi umdlalo uza kuqala kwimowudi yesikrini esigcweleyo.',
'function': 'Umsebenzi',
'game_functions': 'Imisebenzi Yomdlalo',
'game_history': 'IMBALI YOMDLALO',
'game_r_1_paragraph': 'Umdlali angalwenza lusebenze ukhetho lokudlala ngokuzenzekelayo apho ukubheja kubekwa ngokuzenzekelayo kumjikelo ngamnye omtsha kusetyenziswa imali yokubheja evela kubhejo lwangaphambili.',
'game_r_10_paragraph': 'Zakuba Iikhredithi zesaphulelo zinikezelwe, nakuphi na ukubheja okufakwa ngumdlali, imali iza kuqala itsalwe kwikhredithi yesaphulelo de ibe iphelile, ngaphambi kokuba iqhubeke itsala imali kwiwalethi yomdlali.',
'game_r_11_paragraph': 'Iikhredithi zesaphulelo azikwazi ukukhutshwa, kodwa nakuphi na ukuwina ekubhejeni okunjalo kufakwa kumdlali ngokupheleleyo.',
'game_r_12_paragraph': 'Ikhredithi zesaphulelo zineentsuku eziyi-30 zokusebenza zize emva koko ziphelelwe lixesha zize zingasebenziseki.',
'game_r_13_paragraph': 'Umdlali unokuvela anikwe ukubheja kwasimahla. Umzobo ovelayo uza kuvela kwisikrini esazisa umdlali ngokunikwa ukubheja kwasimahla, inani lokubheja kunye nexabiso lokubheja ngakunye.',
'game_r_14_paragraph': 'Kwakuba ukubheja kwasimahla kunikezelwe umdlali unokukhetha ukuba uwuqala nini umjikelo wokubheja simahla. Xa umjikelo wokubheja simahla uqalisiwe, ukubheja okuzenzekelayo okunesiteykhi esichaziweyo kuyafakwa, kuze kuxhomekeke kumdlali ukuba akhuphe imali nangaliphi na ixesha. Ukuba umdlali uyayeka ukukhonektha ngexesha lokubheja simahla, ukubheja simahla kuza kuqhubeka kwaye kuza kulandela "Ipolisi Yoqhawuko lonxebelelwano".',
'game_r_15_paragraph': 'Ukubheja kwasimahla kunentsuku eziyi-30 zokusebenza kuze emva koko kuphelelwe lixesha kuze kungasebenziseki.',
'game_r_16a_paragraph': 'Ukubuyela okungokubanzi kubadlali ngesiseko setiyori ngu ',
'game_r_17_paragraph': 'Iipesenti ze-RTP zihlala zijongwa zize ziqinisekiswe ngamaqela esithathu azimeleyo.',
'game_r_18_paragraph': 'Ukuba unxibelelwano lulahlekile emveni kokubheja, ukubheja akuzukurhoxiswa. Ukuba isiphindaphindi sifikelela kwi-20.000x ukubheja kwakho kuza kubhatalwa ngokuzenzekelayo ize ikhredithi ifakwe kwiakhawunti yakho.',
'game_r_19_paragraph': 'Ungancokola kunye nabanye abadlali kumdlalo. Faka umyalezo wakho kwindawo yencoko, okanye uchazele abanye abadlali ngokubheja kwakho okuwinayo.',
'game_r_2_paragraph': 'Isiphindaphindi sokukhupha imali ngokuzenzekelayo singafakwa kwindawo ethi “Ukukhupha imali ngokuzenzekelayo”, ibe xa sifakiwe, umdlalo uza kukhupha imali ngokuzenzekelayo ukuba kufikelelwe kwindawo ebesisetwe kuyo isiphindaphindi.',
'game_r_20_paragraph': 'Abadlali abayisebenzisa kakubi indawo Yencoko ngokuxhaphaza abanye abadlali, okanye ngokusebenzisa ulwimi olungafanelekanga kunye/okanye olungcolileyo amalungelo abo Encoko aza kuyekiswa ukusebenza.',
'game_r_21_paragraph': 'I-Rocketman iyilwe yanomculo omnandi okhala ngasemva kunye nempembelelo zezandi engqondweni. Ukuba unqwenela ukuba umculo kunye/okanye isandi ziyeke ukusebenza, ungayenza loo nto kwimenyu eyehlayo.',
'game_r_22_paragraph': 'Ngokucofa iqhosha elithi "Ukubheja Kwam" umdlali usenokuhlola ukubheja kwakhe kwakutshanje. Ukuze ufumane imbali yomdlalo eyongezelelweyo (umz. ixesha elide), kusenokufuneka ukuba ungene kwimbali yomdlali kwiqonga lomdlalo ngokwalo.',
'game_r_23_paragraph': 'Kwimeko apho kukho ingxaki imijikelezo isenokungaqali ngexesha. Ukuba kunjalo iqela lethu lenkxaso liza kuzama ukulungisa ingxaki ngokukhawuleza kangangoko kunokwenzeka, uze uqhubeke nemijikelo yemidlalo eqhelekileyo. Kwimeko apho kukho ingxaki kumjikelo womdlalo ngokwawo, konke ukubheja kuza kurhoxiswa ize imali esesichengeni ibuyiselwe kubadlali.',
'game_r_24_paragraph': 'Ukulibaziseka kwenethiwekhi yinxalenye engenakuphepheka yonxibelelwano lwe-intanethi. Sijonga konke ukubheja nokukhutshwa kwemali kusebenza kuphela ngexesha ekufike ngalo kwiseva yethu, kungakhathaliseki ukuba kungexesha lokulibaziseka kwenethiwekhi. ',
'game_r_3_paragraph': 'Umdlali angasebenzisa izitshixo zokunqamlela ukuze akhawuleze abheje okanye akhuphe imali kumdlalo. Iimephu eziphambili zezi zilandelayo',
'game_r_4_paragraph': 'I-1% kwisiseko ngasinye inikezelwa kwi-jackpot esiqhubekayo. Le mali isebenza kwinkqubo ye-jackpot eqhubekayo ',
'game_r_5_paragraph': ' • IJackpot – Elona nqanaba liphezulu, ibhaso elikhulu, lixhaphake kancinci',
'game_r_6_paragraph': ' • IRocketpot – Inqanaba eliphakathi, ibhaso eliphakathi',
'game_r_7_paragraph': ' • I-Boosterpot – Elona nqanaba liphantsi, ibhaso eliphantsi, lixhaphake kakhulu ',
'game_r_8_paragraph': 'Onke amanqanaba ejackpot anikezelwa kusetyenziswa ubugcisa - "Obuyimfihlo" okanye obe-"must-hit-by". Ixabiso lazo zonke ijackpot ze-"must-hit-by" kufunyaniswa ngoko nangoko emva kokuba ijackpot yangaphambili iwinwe ngumvelisi wenombolo kwaza kwagcinwa njengexabiso elifihliweyo kwindawo yedatha ekude. Ixabiso liyakhuselwa, ngoko kufihlakele kwaye akwaziwa. IJackpot iwinwa ngumdlali wokuqala othi isteyikhi sakhe sibangele ukuba ijackpot idlule kwixabiso eliyimfihlo. Owine iJackpot waziswa emva kokuba umjikelo ugqityiwe.',
'game_r_8a_paragraph': 'Qaphela: Owine iJackpot unokuba ngumdlali omnye kuphela we-Rocketman osadlalayo kumjikelo xa ijackpot iqalisiwe. Ayinakwenzeka into yokuba, xa umdlali ewine ijackpot, kufuneke ukuba abelane ngayo kunye naye nawuphi na omnye umdlali we-Rocketman.',
'game_r_9_paragraph': 'Umdlali usenokuvela anikwe ikhredithi yesaphulelo. Umzobo ovelayo uza kuvela kwisikrini uchazela umdlali ngekhredithi enikezelweyo yesaphulelo kunye nexabiso.',
'game_round': 'imijikelo yemidlalo!',
'game_rules': 'Imithetho Yomdlalo',
'game_rules_1_paragraph': 'I-Rocketman yinto yokuzonwabisa yokungcakaza yesizukulwana esitsha. Wina izihlandlo ezininzi ngemizuzwana! I-Rocketman yakhelwe kwi',
'game_rules_10_paragraph': ' • Ukudlala Ngokuzenzekelayo kwenziwa kusebenze kwithebhu ethi "Ngokuzenzekelayo" Kwiphaneli Yokubheja, ngokutikisha ibhokisi yokutikisha ethi "Ukubheja Ngokuzenzekelayo". Emva kokuyenza isebenze, ukubheja kuza kwenziwa ngokuzenzekelayo, kodwa ukuze ukhuphe imali, kufuneka ucofe iqhosha elithi “ukukhutshwa kwemali” kumjikelo ngamnye. Ukuba ufuna ukubheja ukuze ukhuphe imali ngokuzenzekelayo, ngoko sebenzisa',
'game_rules_11_paragraph': '• Ukukhutshwa Kwemali Ngokuzenzekelayo kufumaneka kwithebhu ethi "Okuzenzekelayo" kwiphaneli yokubheja. Emva kokuyenza isebenze, ukubheja kwakho kuza kubhatalwa ngokuzenzekelayo, xa kufikelela kwi-coefficient efakiweyo.',
'game_rules_2_paragraph': ', ekukuphela kwesiqinisekiso esinyanisekileyo kwishishini lokungcakaza okwangoku.',
'game_rules_3_paragraph': '• Isiphindaphindi sokuwina siqala ku-1x size siye sikhula ngakumbi njengoko Inqwelomoya Yethamsanqa isuka.',
'game_rules_4_paragraph': ' • Ukuwina kwakho sisiphindaphindi Okhuphe Imali kuso saphindaphindwa ngokubheja kwakho. ',
'game_rules_5_paragraph': '• Ngaphambi kokuqala komjikelo ngamnye, ijenereyitha yethu yokuvelisa inombolo ngokungacwangciswannga engqineka ngokufanalekileyo, ivelisa i-coefficient apho Inqwelomoya Yethamsanqa iza kundizela khona. Ungajonga ukunyaniseka koku kuveliswa ngokucofa ngokuchasene nesiphumo kwithebhu yeMbali.',
'game_rules_6_paragraph': '• Khetha imali uze ucofe iqhosha elithi “Bheja” ukuze ubheje. ',
'game_rules_7_paragraph': '• Unokubheja kabini ngaxeshanye, ngokongeza iphaneli yokubheja yesibini. Ukuze wongeze iphaneli yesibini yokubheja cofa kwi-ayikhoni yophawu lokongeza, ekwikona ephezulu ngasekunene kwephaneli yokubheja yokuqala.',
'game_rules_8_paragraph': ' • Cofa iqhosha elithi “Ukukhupha Imali” ukuze ukhuphe okuwinileyo. Okuwinayo yimali yokubheja ephindwe nge-coefficient yokukhupha imali.',
'game_rules_9_paragraph': '• Ukubheja kwakho kulahlekile, ukuba khange ukhuphe imali ngaphambi kokuba inqwelomoya ibhabhe.',
'general_rules': 'IMITHETHO EQHELEKILEYO',
'gifs': 'II-GIF',
'go_back': 'Buyela Emva',
'good_job_buddy': 'Usebenzile mhlobo, yigcine iqhubeka',
'h': 'h',
'hashed_in_sha_512': 'Iheshwe ku-SHA512',
'hex': 'I-Hex:',
'hey_username': 'Molo',
'hide_hex_to_decimal': 'Fihla i-hex ukuya kutshintsho lwedesimali',
'highscore': 'Inqaku Eliphezulu',
'how_it_works': 'Isebenza njani?',
'how_to_check': 'Uyijonga njani?',
'how_to_play': 'Kudlalwa njani',
'if_the_maximum_multiplier': '• Ukuba kufikelelwe kwesona siphindaphindi siphezulu se-20.000, konke ukubheja okuvulekileyo kukhutshwa ngokuzenzekelayo kwisiphindaphindi esiphezulu',
'increase': 'Yandisa',
'input_bet_amount': 'Faka imali yokubheja uze ubheje ngaphambi kokuba ihambe.',
'insufficient_funds': 'Imali ayonelanga',
'it_blew_up': 'Iqhushumbile! Yho ke...',
'jackpot': 'Ijackpot',
'jackpot_winners': 'Abawini bejackpot',
'key': 'Isitshixo',
'load_more': 'FAKA EZINGAKUMBI',
'loading': 'Iyalowuda',
'loading_next_round': 'Ilowuda umjikelo olandelayo....',
'loss_gifs': 'ILAHLEKO',
'lost_connection': 'Unxibelelwano Olulahlekileyo',
'make_a_bet': 'Yenza uBhejo',
'malfunction_voids': 'Ukungasebenzi kakuhle kwenza kungasebenzi konke Ukubhatala Nokudlala.',
'maximum_payout': 'Ubuninzi bentlawulo bu ',
'menu': 'Imenyu',
'min_bet_is': 'Okona kubheja kuncinci ngu',
'month': 'Inyanga',
'more_details': 'Iinkcukacha Ezingakumbi',
'more_information': 'Ulwazi olungakumbi',
'multiplier': 'Isiphindaphindi',
'multiplier_starts': '• Isiphindaphindi siqala ku-1 size sinyuke ukuya kutsho ku-20.000',
'multipliers_larger': 'Iziphindaphindi ezinkulu kuno-x20.000 zivalwe kwelona xabiso liphezulu lesiphindaphindi',
'music_settings': 'Iisethingi Zomculo',
'my_bet_history': 'Imbali Yam Yokubheja',
'my_bets': 'Ukubheja Kwam',
'my_bonysing_history': 'Imbali Yam Yokubhonasa',
'network_latency': 'UKULIBAZISEKA KWENETHIWEKHI',
'network_latency_content': 'Ukulibaziseka kwenethiwekhi yinxalenye engenakuphepheka yonxibelelwano lwe-intanethi. Sijonga konke ukubheja nokukhutshwa kwemali kusebenza kuphela ngexesha ekufike ngalo kwiseva yethu, kungakhathaliseki ukuba kungexesha lokulibaziseka kwenethiwekhi.',
'network_latency_title': 'UKULIBAZISEKA KWENETHIWEKHI',
'no_thanks': 'Hayi enkosi!',
'not_right_now': 'Hayi ngoku',
'off': 'cima',
'oh_snap': 'Owu Snap!',
'ok_close': 'Kulungile, Vala!',
'ok_thanks': 'Kulungile, Enkosi!',
'on': 'ivuliwe',
'on_loss': 'IKWILAHLEKO',
'on_win': 'ISEKUWINENI',
'online': 'Ikwi-intanethi',
'per_bet': 'ukubheja ngakunye',
'pick_your_avatar_img': 'Khetha ifoto yakho ye-avatar',
'place_bet_with_last': 'Bheja ngesteykhi sokugqibela esikhethiweyo ',
'play_now': 'Dlala Ngoku',
'player_1_seed': 'Imbewu Yomdlali 1',
'player_2_seed': 'Imbewu yomdlali 2',
'player_3_seed': 'Imbewu Yomdlali 3',
'please_wait_active_bets': 'Nceda ulinde ukubheja okuqhubayo kugqitywe ukuze wenze ukubheja kwasimahla kusebenze',
'points': 'AMANQAKU',
'powered_by': 'Ixhaswa ngu',
'previous_hand': 'Isandla Sangaphambili',
'prize': 'IBHASO',
'prizes': 'amabhaso',
'progressive_jackpot': 'IJACKPOT EQHUBEKAYO',
'promo_credit': 'Ikhredithi Yesaphulelo',
'promo_credit_condradulations': 'Sivuyisana nawe! Uwine ikhredithi Yesaphulelo elunge ngokweenkwenkwezi onokuyisebenzisa kumdlalo!',
'promo_credits': 'IKHREDITHI ZESAPHULELO',
'promo_history': 'Imbali Yesaphulelo',
'provably_fair': 'Okungqineka Ngokufanelekileyo',
'provably_fair_1_paragraph': '\'I-Rocketman\' isekelwe kwiteknoloji e-cryptographic ebizwa ngokuba yi-Provably Fair \'. Le teknoloji iqinisekisa ukuba iziphumo zomdlalo zinobulungisa nge-100%. Ngale teknoloji, akunakwenzeka ukuba nawuphi na omnye umntu angenelele kwinkqubo yomdlalo.',
'provably_fair_2_paragraph': 'Isiphumo somjikelo ngamnye (I-coefficient Yokubhabha Yomdlalo) asiveliswa kwiiseva zethu. Siveliswa ngoncedo lwabadlali bomjikelo ibe icace gca. Ngale ndlela, akunakwenzeka ukuba nabani na alawule isiphumo somdlalo. Kwakhona, nabani na unokujonga aze aqinisekise ukulunga komdlalo.',
'provably_fair_3_paragraph': 'Isiphumo somjikelo siveliswa ngabathathinxaxheba abane abazimeleyo bomjikelo',
'provably_fair_4_paragraph': 'Xa umjikelo uqala, umdlalo udibanisa imbewu yeseva neembewu zomthengi ezintathu. Ukusuka kwiimpawu ezidityanisiweyo kuveliswa i-hash ye-SHA512, kwaye ukusuka kule hash - isiphumo somdlalo.',
'provably_fair_5_paragraph': 'Ungajonga ubulungisa bomjikelo ngamnye kwimbali yomdlalo, ngokucofa iayikhon eluhlaza. Kwifestile evuliweyo, uza kubona imbewu yeseva, iipere ezi-3 zeembewu zabadlali, i-hash edibeneyo nesiphumo somjikelo.',
'provably_fair_6_paragraph': 'Uhlobo lwe-Hash lwembewu yeseva elandelayo lufumaneka esidlangalaleni kwiwindow yeesethingi (Kwimenyu yomsebenzisi, jonga "Iisethingi Zokungqineka Ngokufanelekileyo" uze emva koko "Imbewu yeseva elandelayo SHA256"). Ungakwazi nokutshintsha imbewu yomthengi wakho apha. Ukuba ufuna ukuthatha inxaxheba ekuvelisweni kweziphumo zomjikelo, qiniseka ukuba uphakathi kwabadlali aba-3 bokuqala ababheja kuloo mjikelo.',
'provably_fair_7_paragraph': 'Ukuchaneka kwehash kunokujongwa nakwesiphi na isibali-manani esikwi-intanethi, umzekelo',
'provably_fair_system': 'isistim yokungqineka ngokufanelekileyo',
'quick_explanation': 'Ukucacisa okukhawulezayo',
'randomly_generated_server_hash': 'I-HASH yeseva eveliswe ngokungacwangciswanga',
'rank': 'IRENKI',
'raw_multiplier': 'isiphindaphindi esikrwada',
'raw_multiplier_to_edged': 'Isiphindaphindi Esikrwada ukuya Kwisiphindaphindi Esisekoneni, kusetyenziswa',
'remove_additional_bet': 'Susa ukubheja okongezelelekileyo',
'reset': 'Seta kwakhona',
'return_to_player': 'BUYELA KUMDLALI',
'rocketman_is_pure_excitement': 'IRocketman lulonwabo lodwa! Zibeke esichengeni uze uwine.',
'rocketpot': 'IRocketpot',
'rocketpot_winners': 'Abawini kwirocketpot',
'round': 'Umjikelo',
'round_id': 'I-Id Yomjikelo',
'round_multiplier': 'Isiphindaphindi Somjikelo',
'round_results': 'iziphumo zomjikelo',
'rounds': 'imijikelo',
'rtp': 'I-RTP',
'rules': 'imithetho',
'rules_details': 'Imithetho yetumente',
'save': 'Gcina',
'schedule': 'ishedyuli',
'second': 'imizuzwana',
'seconds_left': 'eshiyekileyo',
'server_hash': 'I-HASH yeseva',
'please_wait_current_bets': 'NCEDA ULINDE UKUBHEJA OKUQHUBAYO KUGQITYWE',
'min_autocashout_value_must_be': 'IXABISO LOKUKHUPHA IMALI NGOKUZENZEKELAYO ELIPHANTSI KUFUNEKA LIBE',
'min_bet_value_must_be': 'IXABISO LOKUBHEJA ELIPHANTSI KUFUNEKA LIBE',
'server_seed': 'Imbewu Yeseva',
'session_dissconnected': 'Iseshoni Iqhawuke unxibelelwano',
'multisession_not_allowed': 'Ukuphindaphinda akuvumelekanga',
'set_auto_bet': 'SETA UKUBHEJA OKUZENZEKELAYO',
'set_auto_cashout': 'SETA UKUKHUPHA IMALI NGOKUZENZEKELAYO',
'shourtcut_keys': 'AMAQHOSHA OKUNQAMLELA',
'show_hex_to_deimal': 'Bonisa utshintsho lwe-hex ukuya kwidesimali',
'sound': 'ISANDI',
'sound_settigs': 'Iisetingi Zesandi',
'start_with_autoplay': 'Qala ngokudlala ngokuzenzekelayo',
'stop_autoplay': 'MISA UKUDLALA NGOKUZENZEKELAYO',
'stop_on_loss': 'YIMA KWILAHLEKO',
'stop_on_loss_tooltip': 'Sebenzisa Ukuma Kwilahleko ukuze umise ukubheja ngokuzenzekelayo emva kokulahlekelwa yimali echaziweyo.<br/> Ngokomzekelo, ukuba uqala nge-$100 uze usete Ukuma Kwilahleko kube yi-$25',
'stop_on_profit': 'YIMA KWINGENISO',
'stop_on_profit_tooltip': 'Sebenzisa Ukuma Kwinzuzo ukuze umise ukubheja ngokuzenzekelayo emva kokufumana imali echaziweyo.<br/> Ngokomzekelo, ukuba uqala nge-$100 uze usete Ukuma Kwinzuzo kube yi-$25',
'there_are_no_active_torunament': 'Azikho iitumente ezisebenzayo okwangoku.',
'third': 'isithathu',
'this_game_is': 'Lo mdlalo u',
'this_tool_recalculates': 'Esi sixhobo sibala kwakhona isiphindaphindi ukusuka kwihash yomdlalo. Unokukopa/uncamathisele nayiphi na ihash yomdlalo ukuze uqinisekise isiphindaphindi.',
'time': 'IXESHA',
'time_frame': 'Ixesha Elibekiweyo',
'to': 'IYA',
'toggle_animation': 'Guqula Oopopayi',
'top_odds': 'Amathuba Aphezulu',
'top_wins': 'Imiwino Ephambili',
'total_bets': 'Kukonke Ukubheja',
'tournament': 'Itumente',
'tournament_information': 'Inkcazelo ngetumente',
'try_advanced_autoplay': 'Zama ifitsha entsha ephuculiweyo yokudlala ngokuzenzekelayo! ',
'uncredited_wins': 'Imiwino engabhatalwanga',
'user': 'UMSEBENZISI',
'user_and_points': 'UMSEBENZISI NAMANQAKU',
'username': 'Igama lomsebenzisi',
'username_must_be_at_least': 'Igama lomsebenzisi kufuneka ubuncinane libe',
'verify': 'Qinisekisa',
'vip_popup_text': 'Ungakhetha enye yee-avatar ezikhethekileyo ze-VIP ezifumaneka kuphela kumalungu ethu e-VIP!',
'vip_popup_title': 'Siyavuyisana nawe ngokuba ngumdlali weVIP',
'waiting_for_the_next_round': 'Ilinde umjikelo olandelayo...',
'warnings_are_calculated': ' • Imiwino ibalwa ngokuphindaphinda isteykhi sokubheja ngesiphindaphindi ngexesha Lokukhupha Imali',
'watch_take_off': 'Bukela njengoko iLucky Rocket yakho isuka yaye imali oyiwinayo isanda.',
'watch_the_rocket': 'Bukela iRocket',
'we_have_noticed': '`Siqaphele ukuba uyaqala ukudlala "iRocketman". Ungathanda ukubona ividiyo ekhawulezayo ecacisa indlela yokuyidlala?',
'week': 'Iveki',
'welcome_to_rocketman': 'Wamkelekile kwi-Rocketman - umdlalo oyinoveli nowonwabisayo! NgeRocketman unethuba lokuwina ukuya kutsho kwi-20.000x kwisteykhi sakho sokuqala.',
'what_is_provably_fair': 'Yintoni Okungqineka ngokufanelekileyo?',
'will_be_deposited': 'Iza kufakwa kwibhalansi yabadlali ngokuwina kokuqala',
'win': 'WINA',
'win_amount': 'Imali Oyiwinileyo',
'win_gifs': 'WINA',
'winner': 'owinileyo',
'wins': 'imiwino',
'write_a_replay': 'Bhala impendulo...',
'year': 'Unyaka',
'yes': 'EWE',
'yes_start_the_round': 'Ewe, qala umjikelo',
'you_have_cashed_out': 'Ukhuphe imali, umwino lwakho yi- ',
'you_have_won': 'UWINILE',
'your_bet_is_lost': 'ukubheja kwakho kulahlekile.',
'current': 'Yangoku',
'previous': 'Engaphambili',
'claim': 'Banga',
'this_feature_will_give': 'Le fitsha iza kunikise ngemali ekhethiweyo kubasebenzisi kwicoko',
'amount_per_player': 'Imali Ngomdlali Ngamnye',
'no_of_players': 'Inani labadlali',
'meteor_shower': 'I-Meteor Shower',
'claim_your_free_bet': 'Banga Ukubheja Kwakho Kwasimahla',
'meteor_shower_total': 'Iyonke i-Meteor Shower',
'shower': 'ISHOWER',
'total': 'Iyonke',
'meteor_shower_incoming': 'I-Meteor Shower engenayo, jonga kwincoko!',
'insurance': 'I-INSHORENSI',
'insurance_paragraph': 'Abadlali banokuthenga i-inshorensi yokubheja kwabo ngokucofa kwibhokisi lokutikisha elithi "I-inshorensi yokubheja" ecaleni kweqhosha lokubheja. I-inshorensi ixabisa i-10% yemali epheleleyo yokubheja kwaye iya kubuyisela imali yokubheja kumdlali ukuba irokethi iqhushumba kwi-1.00 yesiphindaphindi.',
'insure_your_bet': 'YENZELA UKUBHEJA KWAKHO I-INSHORENSI',
'bet_insurance_promo_text': 'Khetha u "i-Inshorensi Yokubheja" ukuze wenzele ukubheja kwakho i-inshorensi yakho kwirocket kukuntlitheka ku-1.00 yophindaphinda.',
'bet_insurance_promo_text_helper': 'Funda ngakumbi kwicandelo "Lemithetho Yomdlalo"…',
'game_explanation_1': 'Khetha isiphindaphindi sakho, kwaye ukuba isiphindaphindi somdlalo siyagqitha kwisiphindaphindi sakho esikhethiweyo, ukuwina kwakho kuza kuba',
'game_explanation_2': 'Isiphindaphindi Sakho x Ukubheja Kwakho',
'retail_max_mp_1': 'Wina ukuya kufikelela',
'retail_max_mp_2': 'kukubheja kwakho',
'play_multibets': 'Dlala',
'multibets': 'IIMULTIBETS',
'bonus_game': 'IBHONASI UMDLALO',
'all_wins_doubled': 'Zonke iimpumelelo ziphindwe kabini!',
'multibets_paragraph_1': 'ukwandisa amathuba akho!',
'multibets_paragraph_2': 'Ungadibanisa ukubheja ukuya kwimijikelo elishumi kwi-multibet enye kwaye uphumelele iintlawulo ezinkulu!',
'multibets_paragraph_3': 'Ngalo lonke ixesha kuphunyelelwa umjikelo, isahlulo esiphumeleleyo kulo mjikelo sibhejwa kumjikelo olandelayo, de yonke imijikelo iphumelele.',
'bonus_launch': 'Wina i-x2 kuko konke ukubheja kumjikelo othi "Ukuqalisa Ibhonasi"!',
'multiplier_history': 'Imbali Yesiphindaphindi',
'will_start_soon': 'iza kuqalisa kungekudala',
'hurry_up_and_place_your_bets': 'Khawuleza uze ubeke ukubheja kwakho!',
'active_bets': 'Ukubheja Okusebenzayo',
'shop': 'THENGA',
'game_meteor_shower_paragraph': 'I-Meteor Shower ikunika ukubheja okunye kwasimahla kwexabiso elichazwe kwangaphambili kwinani elichazwe kwangaphambili labadlali. Inokuqalwa nokuba yiseva okanye ngumdlali. Yakuba iMeteor Shower yenziwe yasebenza, umyalezo uwela ngaphakathi kumdlalo, uyalela abasebenzisi ukuba bajonge kwincoko apho umyalezo weMeteor Shower ukhoyo. Abadlali bangabanga ukubheja kwasimahla ngokucofa kwiqhosha elithi "Banga". Ukubheja kwasimahla kunikezelwa kubadlali abacofa kwiqhosha elithi "Banga" kuqala. Ukusebenzisa into yokucofa ngokuzenzekelayo okanye naluphi na uhlobo lwesoftware ezenzekelayo ukulinganisa ukucofa komdlali nokubanga amabhaso akuvumelekanga kwaye kunokubangela ukuba ivalwe le fitsha.',
'claming_failed': 'Ukubanga akuphumelelanga',
'player_on_blacklist': 'Umdlali ukuluhlu lwabangathebakalanga',
'you_can_claim_ticket': 'Unokubanga itikiti kuphela ukuba ungumdlali oqhubekayo nokubheja rhoqo',
'you_already_have_free_tickets': 'Sele unamatikiti asimahla, nceda uwasebenzise kuqala',
'please_play_the_game_in_portait_mode': 'Nceda udlale umdlalo kwimowudi ethe nkqo.',
};
langArr.af = {
'your_insurance_returns_you': 'Jou versekering gee vir jou terug',
'bet_insurance': 'Weddenskapversekering',
'max_bet_is': 'Maks weddenskap is',
'already_active_bet_of': 'en daar is reeds ŉ aktiewe weddenskap van',
'1_from_game_history': '1. Vanaf spelgeskiedenis',
'2_in_settings_window': '2. In stellingsvenster',
'3_online_calculator': '3. Aanlynrekenaar',
'active_tournament_1': 'Aktiewe toernooi ',
'active_tournament_2': 'het nog geen weddenskappe nie.',
'add_another_bet': 'Voeg ŉ ander weddenskap by',
'advanced_autoplay': 'GEVORDERDE OUTOSPEEL',
'advanced_autoplay_for_bet': 'Gevorderde outospeel vir weddenskap',
'alien_saucer_free_bets': ' Gratis Vlieënde Piering-weddenskappe',
'all_bets': 'Alle weddenskappe',
'all_in_your_hands': 'Alles in jou hande!',
'allow': 'Laat toe',
'at': 'by',
'austronaut_promo_credit': 'Ruimtevaarder-promosiekrediet',
'auto_play_and_autocash': 'OUTOSPEEL EN OUTO-UITBETALING ',
'autoplay': 'Outospeel',
'autoplay_and_cashout': '02. Outospeel & outo-uitbetaling',
'back_to_home': 'Terug tuisblad toe',
'balance': 'Saldo',
'bet': 'WEDDENSKAP',
'bet_amount': 'WEDDENSKAPBEDRAG',
'bet_and_cashout': '01. Weddenskap en uitbetaling',
'bet_bets_tab': 'SPEELGELD',
'bet_failed': 'Weddenskap het misluk',
'bet_statistic': 'Weddenskapstatistiek',
'bet_time': 'Weddenskaptyd',
'bet_wins': 'Weddenskapwenne',
'bets': 'Weddenskappe',
'biggest_odds': 'Grootste wedsyfer',
'bonus_type': 'BONUSTIPE',
'bonusing_history': 'Bonusgeskiedenis',
'boosterpot': 'Versterkerpot',
'boosterpot_winners': 'Versterkerpot-wenners',
'but_remember_paragraph': 'Onthou egter dat, indien jy nie voor die Gelukkige Vuurpyl ontplof het, tyd gehad het om ŉ uitbetaling te maak nie,',
'calculate_raw_mp_to_decimal': 'Bereken Rou Vermenigvuldiger vanaf die desimaal en rond dit af tot by die naaste integrale getal',
'cancel': 'Kanselleer',
'cant_post_media_content': 'Kan/nie gereeld media-inhoud plaas nie',
'cashed_out': 'Uitbetaal',
'cashout': 'Uitbetaling',
'cashout_at': 'UITBETALING BY',
'cashout_before': 'Betaal uit voor die vuurpyl ontplof en die geld is joune!',
'change_avatar': 'Verander avatar',
'change_username': 'Verander gebruikersnaam',
'characters_long': 'karakters lank',
'chat': 'Klets',
'chat_room': 'Kletskamer',
'checking_for_spam': 'Kyk vir gemorspos...',
'close': ' Maak toe',
'coeff': 'KOËFF.',
'collect_win': 'Vorder wengeld in',
'combined_seed': 'Gekombineerde gekeurde speler',
'company': 'Maatskappy',
'congratulations_you_have_won_free_bets': 'Geluk! Jy het Bonusweddenskappe gewen. Jy kan xx wed.',
'convert_first_13_to_decimal': ' Omreken eerste 13 grepe tot desimaal',
'd': 'd',
'date': 'DATUM',
'date_and_time': 'Datum en Tyd',
'deny': 'Weier',
'detailed_game_rules': 'Gedetailleerde spelreëls',
'dissconnection_policy': 'DISKONNEKSIEBELEID',
'easy_as_one_two_three': 'Maklik soos een, twee, drie',
'enter_username': 'Sleutel asseblief gewenste gebruikersnaam in',
'error_handling': 'FOUTHANTERING',
'exit_full_screen': 'Gaan uit volskermmodus uit',
'first': 'eerste',
'first_time_playing': 'Is dit die eerste keer dat jy Rocketman speel?',
'folow_the_three_easy_steps': 'Volg die drie maklike stappe en geniet Rocketman! ',
'free_bets': 'GRATIS WEDDENSKAPPE',
'free_bets_desc': 'Jy het gratis weddenskappe gewen! Jy kan gratis weddenskappe enige tyd geniet deur die Begin-knoppie te druk.',
'free_bets_header': 'Gratis weddenskappe',
'from': 'VAN',
'fullscreen_mode': 'Vir ŉ beter gebruikerservaring sal die spel in volskermmodus begin.',
'function': 'Funksie',
'game_functions': 'Spelfunksies',
'game_history': 'SPELGESKIEDENIS',
'game_r_1_paragraph': 'ŉ Speler kan die outospeelopsie aktiveer, waarin ŉ weddenskap outomaties vir elke nuwe rondte aangegaan word deur die weddenskapbedrag van die vorige weddenskap te gebruik.',
'game_r_10_paragraph': 'Sodra die promosiekrediete toegeken is, sal die geld vir elke weddenskap wat ŉ speler aangaan eers van die promosiekrediete afgetrek word tot dit opgebruik is, voordat geld uit die speler se beursie onttrek word.',
'game_r_11_paragraph': 'Die promosiekrediete kan nie uitbetaal word nie, maar enige wengeld op sodanige weddenskappe word ten volle aan die speler gekrediteer.',
'game_r_12_paragraph': 'Promosiekrediete geld vir 30 dae, waarna dit verval en nie gebruik kan word nie.',
'game_r_13_paragraph': 'Gratis weddenskappe kan lukraak aan ŉ speler toegeken word. ŉ Opwipgrafika sal op die skerm verskyn wat die speler in kennis stel van gratis weddenskappe, die aantal weddenskappe en die bedrag per weddenskap.',
'game_r_14_paragraph': 'Sodra die gratis weddenskappe toegeken word, kan die speler kies wanneer om die gratis weddenskap-rondte te begin. Sodra die gratis weddenskap-rondte begin is, word ŉ outomatiese weddenskap met die gespesifiseerde speelgeld aangegaan en dit berus by die speler om dit op enige bepaalde oomblik te laat uitbetaal. Indien die speler tydens die gratis weddenskappe ontkoppel, sal die gratis weddenskappe steeds plaasvind en dit sal die Ontkoppelingsbeleid volg.',
'game_r_15_paragraph': 'Gratis weddenskappe geld vir 30 dae, waarna dit verval en nie gebruik kan word nie.',
'game_r_16a_paragraph': 'Die algehele teoretiese opbrengs aan die speler is ',
'game_r_17_paragraph': 'TAS-persentasies word voordurend gemoniteer en deur onafhanklike derde partye geverifieer.',
'game_r_18_paragraph': 'Indien die verbinding verbreek word nadat ŉ weddenskap aangegaan is, sal die weddenskap nie gekanselleer word nie. Indien die vermenigvuldiger 20.000 x bereik, sal jou weddenskap outomaties uitbetaal word en krediete sal teen jou rekening gekrediteer word.',
'game_r_19_paragraph': 'Jy kan in die spel met ander spelers praat. Sleutel jou boodskap in die kletsveld in of deel jou wenweddenskap met ander spelers.',
'game_r_2_paragraph': 'ŉ Outomatiese uitbetalingsvermenigvuldiger kan in die "Outomatiese uitbetaling"-veld ingesleutel word. Die spel sal outomaties uitbetaal indien die stelvermenigvuldiger bereik word.',
'game_r_20_paragraph': 'Die kletsvoorregte van spelers wat die kletsfunksie misbruik deur ander spelers te mishandel of deur onvanpaste en/of vuil taal te gebruik, sal buite werking gestel word.',
'game_r_21_paragraph': 'Rocketman is ontwerp met pragtige agtergrondmusiek en klankeffekte in gedagte. Indien jy die musiek en/of klankeffekte wil afskakel, kan jy dit op die aftrekkieslys doen.',
'game_r_22_paragraph': 'Deur op die "My weddenskappe"-knoppie te klik, kan ŉ speler sy jongste weddenskappe hersien. Vir verdere spelergeskiedenis (bv. langer tydperke), sal ŉ speler dalk die spelergeskiedenis binne die spelplatform self moet raadpleeg.',
'game_r_23_paragraph': 'In geval van ‘n fout, kan rondtes dalk nie betyds begin nie. In daardie geval, sal ons bystandspan probeer om die probleem so gou moontlik op te los en gereelde spelrondtes hervat. In geval van ŉ probleem met ŉ spelrondte self sal alle weddenskappe gekanselleer word en die speelgeldbedrag sal aan spelers teruggegee word.',
'game_r_24_paragraph': 'Netwerklatentheid is ŉ onvermydelike deel van internetkonnektiwiteit. Ons beskou alle weddenskappe en uitbetalings slegs as geldig wanneer hulle ons bediener bereik, ongeag die duur van die netwerklatentheid. ',
'game_r_3_paragraph': 'ŉ Speler kan die kortpadikone gebruik om ŉ weddenskap vinnig aan te gaan of in die spel uit te betaal. Die belangrikste afbeeldings is die volgende:',
'game_r_4_paragraph': '1% van elke weddenskap word toegewy aan \'n progressiewe jackpotfonds. Hierdie fonds dien die progressiewe jackpotsisteem',
'game_r_5_paragraph': '  • Boerpot – Hoogste vlak, grootste prys, minste gereeld',
'game_r_6_paragraph': ' • Vuurpylpot – Medium vlak, medium prys',
'game_r_7_paragraph': ' • Versterker-pot – Laagste vlak, lae prys, mees gereeld ',
'game_r_8_paragraph': 'Alle boerpotvlakke word toegeken deur ŉ "Geheime" of "moet-tref-deur"-meganika te gebruik. Die waarde van elke "moet-tref-deur"-boerpot word dadelik ná die vorige boerpot gewen is, bepaal deur ŉ lukrake nommergenereerder en as ŉ geënkripteerde waarde in die afstanddatabasis geberg. Die waarde is geënkripteerd en dus versteek en nie bekend nie. Die boerpot word gewen deur die eerste speler wie se speelgeld die boerpot oor die geheime waarde laat gaan. Die boerpotwenner word aangekondig ná die rondte klaar is.',
'game_r_8a_paragraph': 'Nota: Boerpotwenner kan slegs een enkele Rocketman-speler wees wat aktief in die rondte speel wanneer die boerpot begin is. Daar is geen moontlikheid dat die speler wat die boerpot gewen het dit met enige ander Rocketman-speler sal moet deel nie.',
'game_r_9_paragraph': 'Promosiekrediete kan lukraak aan ŉ speler toegeken word. ŉ Opwipgrafika sal op die skerm verskyn en die speler in kennis stel van die toegekende promosiekrediete en die bedrag daarvan.',
'game_round': 'spelrondtes!',
'game_rules': 'Spelreëls',
'game_rules_1_paragraph': 'Rocketman is dobbelvermaak van ’n nuwe geslag. Wen verskeie kere meer binne sekondes! Rocketman is gebou op ŉ ',
'game_rules_10_paragraph': ' • Outospeel word vanaf die "Outo"-oortjie in die weddenskappaneel geaktiveer deur die "Outoweddenskap"-blokkie te merk. Nadat dit geaktiveer is, sal weddenskappe outomaties aangegaan word, maar om uit te betaal, moet jy die "Betaal uit"-knoppie in elke rondte druk. As jy wil hê die weddenskap moet outomaties uitbetaal, gebruik ',
'game_rules_11_paragraph': ' • Outo-uitbetaling is beskikbaar onder die "Outo"-oortjie op die weddenskappaneel. Nadat dit geaktiveer is, sal jou weddenskap outomaties uitbetaal word wanneer dit die ingesleutelde koëffisiënt bereik.',
'game_rules_2_paragraph': ', wat tans die enigste ware eerlikheidswaarborg in die dobbelbedryf is.',
'game_rules_3_paragraph': ' • Die wenvermenigvuldiger begin by 1x en groei meer en meer soos die Gelukkige Vliegtuig opstyg.',
'game_rules_4_paragraph': ' •  Jou wengeld is die vermenigvuldiger waarmee jy ŉ uitbetaling gemaak het, met jou weddenskap vermenigvuldig. ',
'game_rules_5_paragraph': ' • Voor die begin van elke rondte genereer ons lukrake nommergenereerder, wat bewysbaar billik is, die koëffisiënt waarmee die Gelukkige Vliegtuig sal wegvlieg. Jy kan die eerlikheid van hierdie generering nagaan deur op die "Geskiedenis"-oortjie aan die teenoorgestelde kant van die resultaat te klik.',
'game_rules_6_paragraph': ' • Kies die bedrag en druk die "Wed"-knoppie om ŉ weddenskap aan te gaan. ',
'game_rules_7_paragraph': ' • Jy kan twee weddenskapppe gelyktydig aangaan deur ŉ tweede weddenskappaneel by te voeg. Om ŉ tweede weddenskappaneel by te voeg, druk die plusikoon wat regs bo in die hoek van die eerste weddenskappaneel geleë is.',
'game_rules_8_paragraph': ' •  Druk die "Betaal uit"-knoppie om jou wengeld te laat uitbetaal. Jou wengeld is jou weddenskap vermenigvuldig met die uitbetalingskoëffisiënt.',
'game_rules_9_paragraph': ' • Jy verloor jou weddenskap as jy nie uitbetaal het voor die vliegtuig wegvlieg nie.',
'general_rules': 'ALGEMENE REËLS',
'gifs': 'GIF\'e',
'go_back': 'Gaan terug',
'good_job_buddy': 'Goeie werk, vriend, hou so aan!',
'h': 'h',
'hashed_in_sha_512': 'Gehuts in SHA512',
'hex': 'Heks.',
'hey_username': 'Hallo',
'hide_hex_to_decimal': 'Versteek heks-tot-desimaal-omskakeling',
'highscore': 'Hoë telling',
'how_it_works': 'Hoe dit werk',
'how_to_check': 'Hoe om na te gaan',
'how_to_play': 'Hoe om te speel',
'if_the_maximum_multiplier': ' • Indien die maksimum vermenigvuldiger van 20.000 bereik is, word alle oop weddenskappe outomaties teen die maksimum vermenigvuldiger uitbetaal.',
'increase': 'Verhoog',
'input_bet_amount': 'Sleutel weddenskapbedrag in en plaas jou weddenskap voor opstyging.',
'insufficient_funds': 'Onvoldoende fondse',
'it_blew_up': 'Dit het ontplof! Nou ja...',
'jackpot': 'Boerpot',
'jackpot_winners': 'Boerpotwenners',
'key': 'Sleutel',
'load_more': 'LAAI MEER',
'loading': 'Laai',
'loading_next_round': 'Laai volgende rondte',
'loss_gifs': 'VERLIES',
'lost_connection': 'Verbinding verloor',
'make_a_bet': 'ŉ Weddenskap aangaan',
'malfunction_voids': 'Foutwerking maak alle betalings en spele ongeldig.',
'maximum_payout': 'Maksimum uitbetaling is ',
'menu': 'Kieslys',
'min_bet_is': 'Min. weddenskap is',
'month': 'Maand',
'more_details': 'Meer besonderhede',
'more_information': 'Meer inligting',
'multiplier': 'Vermenigvuldiger',
'multiplier_starts': ' • Die vermenigvuldiger begin by 1 en gaan op na 20.000',
'multipliers_larger': 'Vermenigvuldigers groter as x20.000 word tot die maksimum vermenigvuldigerwaarde beperk',
'music_settings': 'Musiekstellings',
'my_bet_history': 'My weddenskapgeskiedenis',
'my_bets': 'My weddenskappe',
'my_bonysing_history': 'My bonusgeskiedenis',
'network_latency': 'NETWERKLATENTHEID',
'network_latency_content': 'Netwerklatentheid is ŉ onvermydelike deel van internetkonnektiwiteit. Ons oorweeg alle weddenskappe en kontantuitbetalings slegs op die tydstip wanneer dit ons bediener bereik, ongeag die duur van die netwerklatentheid.',
'network_latency_title': 'NETWERKLATENTHEID',
'no_thanks': 'Nee, dankie!',
'not_right_now': 'Nie nou dadelik nie',
'off': 'af',
'oh_snap': 'O, gits!',
'ok_close': 'OK, naby!',
'ok_thanks': 'OK, dankie!',
'on': 'aan',
'on_loss': 'OP VERLIES',
'on_win': 'OP WEN',
'online': 'Aanlyn',
'per_bet': 'per weddenskap',
'pick_your_avatar_img': 'Kies jou avatarprentjie',
'place_bet_with_last': 'Gaan ŉ weddenskap aan met die laaste gekose speelgeld ',
'play_now': 'Speel nou',
'player_1_seed': 'Speler 1 gekeur',
'player_2_seed': 'Speler 2 gekeur',
'player_3_seed': 'Speler 3 gekeur',
'please_wait_active_bets': 'Wag asseblief vir huidige weddenskappe om uit te speel om gratis weddenskappe te aktiveer',
'points': 'PUNTE',
'powered_by': 'Aangedryf deur',
'previous_hand': 'Vorige hand',
'prize': 'PRYS',
'prizes': 'pryse',
'progressive_jackpot': 'PROGRESSIEWE BOERPOT',
'promo_credit': 'Promosiekrediet',
'promo_credit_condradulations': 'Geluk! Jy het ŉ astronomies goeie promosiekrediet gewen wat jy in die spel kan gebruik!',
'promo_credits': 'PROMOSIEKREDIETE',
'promo_history': 'Promosiegeskiedenis',
'provably_fair': 'Bewysbaar billik',
'provably_fair_1_paragraph': '\'Rocketman\' is gebaseer op ŉ kriptografiese tegnologie wat Bewysbaar Billik\' genoem word. Hierdie tegnologie waarborg 100% regverdigheid van die spelresultaat. Met hierdie tegnologie is dit onmoontlik vir enige derde party om in te meng met die spelproses.',
'provably_fair_2_paragraph': 'Resultaat van elke rondte (Spel Vlieg weg se maksimum koëffisiënt) word nie op ons bedieners gegenereer nie. Dit word met behulp van spelers in die rondte gedoen en is ten volle deursigtig. Op hierdie manier is dit onmoontlik vir enigiemand om die spel se uitset te manipuleer. Enigiemand kan ook die spel nagaan en die regverdigheid daarvan bevestig.',
'provably_fair_3_paragraph': 'Rondte se resultaat word uit vier onafhanklike deelnemers in die rondte gegenereer',
'provably_fair_4_paragraph': 'Wanneer die rondte begin, smelt die spel die bediener-gekeurde speler saam met drie kliënt-gekeurde spelers. Uit die saamgetelde simbole word SHA512-telling gegenereer, en hieruit – die spelresultaat.',
'provably_fair_5_paragraph': 'Jy kan die regverdigheid van elke rondte vanaf die spelgeskiedenis nagaan deur op die groen ikoon te klik. In die geopende venster sal jy die bediener se gekeurde speler sien, 3 pare gekeurde spelers, gekombineerde tellings en die rondte se resultaat.',
'provably_fair_6_paragraph': 'Die getelde weergawe van die volgende rondtes se gekeurde speler op die bediener is publiek beskikbaar in die stellingsvenster. (Op die gebruikerkieslys, merk "Provably Fair Settings" en dan "Next server seed SHA256".) Jy kan ook jou kliëntgekeurde spelers hier verander. Indien jy wil deelneem aan die generering van die rondte se resultaat, maak seker jy is tussen die eerste drie spelers wat in daardie rondte ŉ weddenskap aangaan.',
'provably_fair_7_paragraph': 'Die korrektheid van die telling kan byvoorbeeld op enige aanlynrekenaar gekontroleer word.',
'provably_fair_system': 'bewysbaar billike stelsel',
'quick_explanation': 'Vinnige verduideliking',
'randomly_generated_server_hash': 'Lukraak gegenereerde bedienertelling',
'rank': 'RANG',
'raw_multiplier': 'rou vermenigvuldiger',
'raw_multiplier_to_edged': 'Rou vermenigvuldiger na afgekante vermenigvuldiger, wat xx gebruik.',
'remove_additional_bet': 'Verwyder bykomende weddenskap',
'reset': 'Herstel',
'return_to_player': 'GEE TERUG / GAAN TERUG NA SPELER',
'rocketman_is_pure_excitement': 'Rocketman is pure opwinding! Waag en wen.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot-wenners',
'round': 'Rondte',
'round_id': 'Rondte-ID',
'round_multiplier': 'Rondtevermenigvuldiger',
'round_results': 'rondteresultate',
'rounds': 'rondtes',
'rtp': 'RTP',
'rules': 'reëls',
'rules_details': 'Toernooireëls',
'save': 'Spaar',
'schedule': 'skeduleer',
'second': 'tweede',
'seconds_left': 's. oor',
'server_hash': 'Bedienertelling',
'please_wait_current_bets': 'WAG ASSEBLIEF VIR HUIDIGE WEDDENSKAPPE OM UIT TE SPEEL',
'min_autocashout_value_must_be': 'MINIMUM OUTO-UITBETALINGSWAARDE MOET WEES',
'min_bet_value_must_be': 'MINIMUM WEDDENSKAPWAARDE MOET WEES',
'server_seed': 'Bediener-gekeurde speler',
'session_dissconnected': 'Sessie gediskonnekteer',
'multisession_not_allowed': 'Multisessie word nie toegelaat nie',
'set_auto_bet': 'STEL OUTOMATIESE WEDDENSKAP',
'set_auto_cashout': 'STEL OUTOMATIESE UITBETALING',
'shourtcut_keys': 'KORTPADTOETSE',
'show_hex_to_deimal': 'Wys omskakeling van hex na desimaal',
'sound': 'KLANK',
'sound_settigs': 'Klankstellings',
'start_with_autoplay': 'Begin met outospel',
'stop_autoplay': 'STOP AUTOSPEL',
'stop_on_loss': 'STOP OP VERLIES',
'stop_on_loss_tooltip': 'Gebruik Stop on Loss/Stop op verlies om outoweddenskappe te stop nadat jy ŉ gespesifiseerde bedrag verloor het. <br/>As jy byvoorbeeld met $100 begin en jy stel Stop on Loss/Stop op verlies op $25',
'stop_on_profit': 'STOP OP WINS',
'stop_on_profit_tooltip': 'Gebruik Stop on Profit/Stop op Wins om die outoweddenskap te stop na ŉ gespesifiseerde bedrag. <br/> Byvoorbeeld, indien jy met $100 begin en jy stel Stop on Profit/Stop op wins op $25',
'there_are_no_active_torunament': 'Daar is tans geen aktiewe toernooie nie.',
'third': 'derde',
'this_game_is': 'Hierdie spel is',
'this_tool_recalculates': 'Hierdie instrument herbereken die vermenigvuldiger uit die speltelling. Jy kan enige speltelling kopieër/plak om die vermenigvuldiger te verifieer.',
'time': 'TYD',
'time_frame': 'Tydraamwerk',
'to': 'NA / AAN',
'toggle_animation': 'Wissel animasie',
'top_odds': 'Top wedsyfer',
'top_wins': 'Top wenne',
'total_bets': 'Totale weddenskappe',
'tournament': 'Toernooi',
'tournament_information': 'Toernooi-inligting',
'try_advanced_autoplay': 'Probeer nuwe gevorderde outospeelfunksie! ',
'uncredited_wins': 'Ongekrediteerde wenne',
'user': 'GEBRUIKER',
'user_and_points': 'GEBRUIKER & PUNTE',
'username': 'Gebruikernaam',
'username_must_be_at_least': 'Gebruikernaam moet minstens xx lank wees.',
'verify': 'Verifieer',
'vip_popup_text': 'Jy kan een van die spesiale BBP-avatare kies wat eksklusief vir ons BBP-lede beskikbaar is!',
'vip_popup_title': 'Geluk dat jy ŉ BBP-speler geword het',
'waiting_for_the_next_round': 'Wag vir die volgende rondte...',
'warnings_are_calculated': ' • Wengeld word bereken deur die weddenskapspeelgeld met die vermenigvuldiger te vermenigvuldig die oomblik wanneer dit uitbetaal word.',
'watch_take_off': 'Kyk hoe jou Gelukkige Vuurpyl opstyg en jou wengeld vermeerder.',
'watch_the_rocket': 'Hou die Vuurpyl dop',
'we_have_noticed': 'Ons merk dat dit die eerste keer is dat jy “Rocketman” speel. Wil jy vinnig na ‘n video kyk oor hoe om dit te speel?',
'week': 'Week',
'welcome_to_rocketman': 'Welkom by Rocketman – ŉ nuwe en opwindende spel! Met Rocketman het jy ŉ kans om tot 20.000 keer jou oorspronklike speelgeld te wen.',
'what_is_provably_fair': 'Wat is bewysbaar billik?',
'will_be_deposited': 'Dit sal by spelers se saldo bygevoeg word met die eerste gereelde wen.',
'win': 'WEN',
'win_amount': 'Wenbedrag',
'win_gifs': 'WEN',
'winner': 'wenner',
'wins': 'wenne',
'write_a_replay': 'Skryf ŉ antwoord ...',
'year': 'Jaar',
'yes': 'JA',
'yes_start_the_round': 'Ja, begin die rondte.',
'you_have_cashed_out': 'Jy het jou geld uitbetaal, jou wengeld is ',
'you_have_won': 'JY HET GEWEN',
'your_bet_is_lost': 'jy het jou weddenskap verloor.',
'current': 'Huidige',
'previous': 'Vorige',
'claim': 'Eis',
'this_feature_will_give': 'Hierdie kenmerk sal die geselekteerde bedrag vir lukrake gebruikers in die kletskamer gee.',
'amount_per_player': 'Bedrag per speler',
'no_of_players': 'Aantal spelers',
'meteor_shower': 'Meteoorreën',
'claim_your_free_bet': 'Eis jou gratis weddenskap op',
'meteor_shower_total': 'Totale meteoorreën',
'shower': 'REËN',
'total': 'Totaal',
'meteor_shower_incoming': 'Meteoorreën op pad – kyk na die kletskamer!',
'insurance': 'VERSEKERING',
'insurance_paragraph': 'Spelers kan versekering aankoop vir hul weddenskap deur op die "Weddenskapversekering"-blokkie langs die weddenskapknoppie te klik. Versekering kos 10% van die totale weddenskapbedrag en sal die weddenskapbedrag aan die speler terugbetaal indien die vuurpyl op ŉ 1.00-vermenigvuldiger ontplof.',
'insure_your_bet': 'VERSEKER JOU WEDDENSKAP',
'bet_insurance_promo_text': 'Kies die "Weddenskapversekering"-opsie om jou weddenskap te verseker teen ’n vuurpyl wat val teen ŉ vermenigvuldiger van 1.00.',
'bet_insurance_promo_text_helper': 'Lees meer in die "Spelreëls"-afdeling.',
'game_explanation_1': 'Kies jou vermenigvuldiger en as die spelvermenigvuldiger jou gekose vermenigvuldiger oorskry, sal jy xx wen.',
'game_explanation_2': 'Jou vermenigvuldiger x jou weddenskap',
'retail_max_mp_1': 'Wen tot',
'retail_max_mp_2': 'jou weddenskap!',
'play_multibets': 'Speel',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPEL',
'all_wins_doubled': 'Alle oorwinnings het verdubbel!',
'multibets_paragraph_1': 'om jou kans te verhoog!',
'multibets_paragraph_2': 'Jy kan weddenskappe op tot tien rondtes in \'n enkele multiweddenskap kombineer en massiewe uitbetalings wen!',
'multibets_paragraph_3': 'Elke keer as \'n rondte gewen word, word wendividend uit daardie rondte in die volgende rondte gewed totdat alle rondtes wen.',
'bonus_launch': 'Wen x2 op alle weddenskappe in die "Bonuslansering"-rondte!',
'multiplier_history': 'Vermenigvuldigergeskiedenis',
'will_start_soon': 'sal binnekort begin',
'hurry_up_and_place_your_bets': 'Maak gou en gaan jou weddenskappe aan!',
'active_bets': 'Aktiewe weddenskappe',
'shop': 'WINKEL',
'game_meteor_shower_paragraph': 'Die meteoorreën ken een gratis weddenskap van ŉ voorafbepaalde waarde aan ŉ voorafbepaalde getal spelers toe. Dit kan deur die bediener of ŉ speler geaktiveer word. Sodra die meteoorreën geaktiveer is, sal ŉ boodskap in die spel verskyn wat spelers vra om na die kletskamer te kyk waar die meteoorreënboodskap sal wees. Spelers kan die gratis weddenskap opeis deur op die "Eis"-knoppie te klik. Gratis weddenskappe word toegeken aan spelers wat eerste op die "Eis"-knoppie klik. Die gebruik van outoklikkers of enige soort outomatiseringsagteware om spelers se klikke na te boots en pryse op te eis, is verbode en kan daartoe lei dat jy van hierdie kenmerk verban word.',
'claming_failed': 'Eis het misluk',
'player_on_blacklist': 'Die speler is op die swartlys',
'you_can_claim_ticket': 'Jy kan die kaartjie slegs opeis as jy ŉ aktiewe speler met gereelde weddenskappe is',
'you_already_have_free_tickets': 'Jy het reeds gratis kaartjies. Spandeer dit asseblief eers.',
'please_play_the_game_in_portait_mode': 'Speel asseblief die spel in portretmodus.',
};
langArr.so = {
'your_insurance_returns_you': 'Inshorense e khutlisa',
'bet_insurance': 'Becha Inshorense',
'max_bet_is': 'Becha ho fihla ho',
'already_active_bet_of': 'ho so ho bechuoe',
'1_from_game_history': '1. Nalane ea ho becha',
'2_in_settings_window': '2. Lebokoseng la liphetolelo',
'3_online_calculator': '3. Sebali sa Inthanete',
'active_tournament_1': 'Thonamente e tsoelang-pele ',
'active_tournament_2': 'ha u so beche',
'add_another_bet': 'Becha hape',
'advanced_autoplay': 'MPAPALLE',
'advanced_autoplay_for_bet': 'Mpapalle ho Becha',
'alien_saucer_free_bets': 'Becha Mahala ho Alien Saucer',
'all_bets': 'Lipapali Tsohle',
'all_in_your_hands': 'Becha ka bolokolohi!',
'allow': 'Amohela',
'at': 'ho',
'austronaut_promo_credit': 'Chelete ea Promo ea Astronaut',
'auto_play_and_autocash': 'MPAPALLE, NTS’ETSE CHELETE ',
'autoplay': 'MPAPALLE',
'autoplay_and_cashout': '02. Mpapalle le ho Nts’etse Chelete',
'back_to_home': 'Khutlela Morao',
'balance': 'Chetete e teng',
'bet': 'BECHA',
'bet_amount': 'CHELETE E BECHANG',
'bet_and_cashout': '01. Becha, Nts’a chelete',
'bet_bets_tab': 'CHELETE E BECHANG',
'bet_failed': 'U sitiloe ho Becha',
'bet_statistic': 'Lipalopalo tsa ho Becha',
'bet_time': 'Nako ea ho Becha',
'bet_wins': 'Meputso',
'bets': 'Lipapali tse bechoang',
'biggest_odds': 'Monyetla o Moholo oa ho Hapa',
'bonus_type': 'MOFUTA OA BONASE',
'bonusing_history': 'NALANE EA LIBONASE',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Bahloli ba Boosterpot',
'but_remember_paragraph': 'Hopola hore haeba ha u ea ba le monyetla oa ho nts’a chelete pele rokete e phahlooa,',
'calculate_raw_mp_to_decimal': 'U kopanya ‘ngoe le palo e ngotsoeng ebe u tlosa palo e kamora feloane ‘me u tla fumana karabo eleng palo e tlang pele ho feloane.',
'cancel': 'Emisa',
'cant_post_media_content': 'E ka se hlaise litaba hangata',
'cashed_out': 'Chelete e nts’itsoeng',
'cashout': 'Nts’a chelete',
'cashout_at': 'NTS’A CHELETE HO',
'cashout_before': 'Nts’a chelete pele rokete e phahlooa ‘me u hape chelete!',
'change_avatar': 'Fetola sets’oants’o',
'change_username': 'Fetola lebitso',
'characters_long': 'litlhaku li telele',
'chat': 'Qoqa',
'chat_room': 'Qoqa le ba bang',
'checking_for_spam': 'Sheba spam',
'close': 'Koala',
'coeff': 'KOEFE.',
'collect_win': 'Amohela Moputso',
'combined_seed': 'Pokello ea liqeto tsa papali',
'company': 'Khamphani',
'congratulations_you_have_won_free_bets': 'Rea u lebohisa! U hapile bonase ea ho becha! U ka becha!',
'convert_first_13_to_decimal': ' Fetolela lipalo tse 13 ebe tse nang le feloane',
'd': 'd',
'date': 'LETSATSI',
'date_and_time': 'Letsatsi le Nako',
'deny': 'Hana',
'detailed_game_rules': 'Melao ea Papali ka Botlalo',
'dissconnection_policy': 'LEANO LA HO IKHULA',
'easy_as_one_two_three': 'Ha hona bothata ba letho',
'enter_username': 'Kenya lebitso la khetho ea hau',
'error_handling': 'THAROLLO EA BOTHATA',
'exit_full_screen': 'Fokotsa Boholo ba sekirine',
'first': 'lekhetlo la pele',
'first_time_playing': 'Ke lekhetlo la pele u bapala “Rocketman”?',
'folow_the_three_easy_steps': 'Latela methati e meraro e latelang, u natefeloe ke Rocketman! ',
'free_bets': 'BECHA MAHALA',
'free_bets_desc': 'U hapile monyetla oa ho becha mahala! U ka sebelisa monyetla ona nako eohle ka ho tobetsa konopo ea bapala.',
'free_bets_header': 'Becha Mahala',
'from': 'E TSOA HO',
'fullscreen_mode': 'E le hore u tlo u bapale ka thello, papali e tla qala e tletse sekirine kaofela',
'function': 'Taolo',
'game_functions': 'Likarolo tsa Taolo ea Papali',
'game_history': 'NALANE EA PAPALI',
'game_r_1_paragraph': 'Sebapali se ka khetha mokhoa oa ho becheloa mokhahlelong o latelang oa papali ho ipapisitsoe le chelete ea ho becha ea mokhahlelo o fetileng.',
'game_r_10_paragraph': 'Hang ha chelete ea promo e fanoe, nako eohle eo sebapali se bechang, chelete e tla nts’uoa ho ea promo ho fihlela e felile, pele ho ka sebelisoa chelete e sepacheng sa libapali.',
'game_r_11_paragraph': 'Chelete ea promo e ka se nts’uoe, empa moo e sebelisitsoeng ho becha ‘me sebapali sa hapa, chelete eno e fuoa sebapali kaofela.',
'game_r_12_paragraph': 'Chelete ea promo e na le matsatsi a 30 feela, kamora mono e felloa ke nako ‘me e ke se sebelisoe.',
'game_r_13_paragraph': 'Sebapali se ka fuoa monyetla oa ho becha mahala ka nako e ‘ngoe. Molaetsa oa tlhokomeliso o tla hlaha, (popup), o tsebisang sebapali ka ho becha mahala, makhetlo a ho becha mahala le chelete ea ho becha mokhahlelo ka ‘ngoe.',
'game_r_14_paragraph': 'Hang ha sebapali se fuoa monyetla oa ho becha mahala, se ka ikhethela nako eo se ka qalang ho becha. Hang ha nako eo e fihla, sebapali se tla becheloa, ‘me sebapali se ka nts’a chelete nako eohle. Haeba sebapali se ikhula papaling nakong eo, monyetla o joalo o tla nne o tsoele-pele ‘me se tla latela “Leano la ho Ikhula”.',
'game_r_15_paragraph': 'Menyetla ea ho becha mahala ke oa matsatsi a 30 feela, kamora mono menyetla e joalo e felloa ke nako ‘me e ke se sebelisoe.',
'game_r_16a_paragraph': 'Celceliska soo noqoshada aragtida ee ciyaaryahanka waa ',
'game_r_17_paragraph': 'Liperesente tsa RTP li lula li beiloe leihlo le ho netefatsoa ke bafani ba lits’ebeletso ba ikemetseng.',
'game_r_18_paragraph': 'Ha ho ba le ho khaoha hoa likhokahanyo kamora ho becha, papali e tla nne e tsoele-pele. Ha chelete e imenahanya ha 20.000x, e tla nts’uoa e romelloe akhaoteng ea hau.',
'game_r_19_paragraph': 'Qoqa le libapali tse ling. Ngola molaetsa oa hau ka lebokoseng la lipuisano, kapa u arolelane le libapali tse ling ka boipabolo ba hau ka ho becha.',
'game_r_2_paragraph': 'Chelete eo u batlang u e nts’etsoe, e ngola ho lebokose la “Nts’etse Chelete” ‘me ha u entse joalo, papali e tla e nts’a ha palo ea ho menahanya chelete e fihleloa.',
'game_r_20_paragraph': 'Libapali tse sebelisang Lebokose la Lipuisano hampe ka ho hlekefetsa libapali tse ling kapa ho sebelisa puo e bohla ba tla haneloa ho ngola melaetsa le ho nts’a maikutlo.',
'game_r_21_paragraph': 'Rocketman e u ts’oaretse ‘mino o monate o hlabollang. Haeba u sa batle ho mamela ‘mino tobetsa menu.',
'game_r_22_paragraph': 'Ka ho tobetsa “Lipapali Tsaka” sebapali se ka lekola lipapali tseo se sa tsoa li becha. Bakeng sa lintlha tsa lipapali tse fetileng (mohlala, tsa nako e telele), sebapali se tlameha ho kena ka hara papali ka bo eona ho ithuta ka lipapali tseo a li bapetseng.',
'game_r_23_paragraph': 'Moo ho ka bang le bothata, papali e ka se qale ka nako. Ha ho le joalo basebeletsi ba rona batla itlola matjato to rarolla bothata boo, ebe papali e tsoela-pele ka thello. Haeba bothata bo hlaha nakong ea papali, ho becha ho entsoeng hotla hlakoloa ebe chelete e khutlela ho libapali.',
'game_r_24_paragraph': 'Neteoeke e sokolisang ke ketsahalo e tloaelehileng. Ho becha le ho nts’a chelete ho netefatsoa feela ha molaetsa o fihla li-komporong tsa rona, ho sa tsotelletse hore na neteoeke e sokolisitse nako e kae. ',
'game_r_3_paragraph': 'Sebapali se ka sebelisa mats’oao a potlako ho becha kapele kapa ho nts’a chelete. Mats’oao ano ke a latelang.',
'game_r_4_paragraph': '1% ee qamaarka kasta waxaa loo qoondeeyay sanduuqa progressive jackpot. Sanduuqan wuxuu u adeegsadaa nidaamka progressive jackpot',
'game_r_5_paragraph': ' • Jackpot – Mokhahlelo o ka holimo, moputso o holimo, hanyane',
'game_r_6_paragraph': '  • Rocketpot – Mokhahlelo o bohareng, moputso o bohareng,',
'game_r_7_paragraph': '  • Boosterpot – Mokhahlelo o ka tlase, moputso o monyane, hangata ',
'game_r_8_paragraph': 'Mekhahelo eohle ea chelete e hapuoang ke "lekunutu" kapa “ho thuloa ke lehlohonolo” libapali ha li e tsebe. Chelete e fanoang mokhahlelong o latelang e ipapisa le boleng ba chelete e hapuoeng papaling e fetileng ka nomoro ea khetho ea motho e notlelloang tlasa tšireletso e matla ea li-komporo. Boleng ba jackpot bo notletsoe ke lekunutu. Jackpot e hapuoa ke sebapali sa pele se tholang boleng ba chelete bo fetang ba eo eleng lekunutu. Mohloli oa jackpot o phatlalatsoa ha papali e fihla pheletsong.',
'game_r_8a_paragraph': 'Hlokomela: Mohloli oa Jackpot o ba mong feela eleng sebapali se nkileng karolo papaling ea Rocketman ha jackpot e kenngoa. Ha hona khonahalo ea hore sebapali se hapileng jackpot se arolelane le libapali tse ling tse bapalang Rocketman.',
'game_r_9_paragraph': 'Sebapali se ka abeloa chelete ea promo ka nako engoe. Molaetsa oa tlhokomeliso (popup) o tla halha o tsebisang sebapali ka chelete ea promo e fanoeng le hore na ke bokae.',
'game_round': 'mekhahlelo ea papali!',
'game_rules': 'Melao ea Papali',
'game_rules_1_paragraph': 'Rocketman ke sehlahisoa sa ho becha (gambling) sa boithabiso sa morao-rao. Hapa hangata ka ho panya hoa nts’i! Rocketman e bopuoe ka',
'game_rules_10_paragraph': ' • Hore u Becheloe, u ea ho “Auto” Lebokoseng la ho Becha, ka ho ts’oaea “Auto Bet”. Kamorao ho moo, u tla becheloa, empa ha u batla ho nts’a chelete, u tobetsa ho “cashout” mokhahlelong ka ‘ngoe. Ha u batla ho nts’etsoa chelete, sebelisa',
'game_rules_11_paragraph': ' • Hore u Nts’etsoe Chelete, u ea ho “Auto” lebokoseng la ho becha, ka ho ts’oaea “Auto Bet”. Kamorao ho moo, u tla nts’etsoa chelete, ha chelete e kentsoeng e fihleloa.',
'game_rules_2_paragraph': ', eo eleng eona feela ts’epo ea rona khoebong ena ea ho becha (gambling).',
'game_rules_3_paragraph': ' • Ho menahanyoa hoa chelete eo u e ka e hapang ho qaloa ho 1x ‘me e eketsetsa hanyane ka hanyane ha Lucky Plane e tloha.',
'game_rules_4_paragraph': '  • Chelete eo u e hapileng ea menahanyoa ha u e Nts’a (Cashout) e eketsoa ka palo. ',
'game_rules_5_paragraph': ' • Pele mokhahlelo oa papali ka ‘ngoe o qala, mokhoa oa ho netefatsa toka le ponaletso papaling, provably fair, o etsa ka lotho nomoro e bechang o ipapisa le hore na Lucky Plane e tla fofa joang. Ithute ka bots’epehi ba mokhoa ona ka ho tobetsa lebokosaneng la Nalane ea papali.',
'game_rules_6_paragraph': ' • Ho becha, khetha boleng ba chelete ebe u tobetsa ho “Bet”. ',
'game_rules_7_paragraph': '  • U ka becha habeli ka nako ele ‘ngoe, ka ho tobetsa lebokose la ho becha hape. Ho becha hape tobetsa letso’ao la sefapano, le ka holimo ka letsohong le letona.',
'game_rules_8_paragraph': '  • Tobetsa “Cashout” ho nts’a chelete eo u e hapileng. Chelete eo e eketsoa ka eo u bechileng ka eona.',
'game_rules_9_paragraph': ' • Ha sefofane se nyamela pele u nts’a chelete, chelete eo u bechileng ka eona e oelelletse.',
'general_rules': 'MELAO E AKARETSANG',
'gifs': 'GIFS',
'go_back': 'Khutlela Morao',
'good_job_buddy': 'Mosebetsi o motle, ts’oara joalo',
'h': 'h',
'hashed_in_sha_512': 'Hashed in SHA512',
'hex': 'Hex',
'hey_username': 'Lumela',
'hide_hex_to_decimal': 'Pata hex ha u fetolela lipalo tse nang le feloane.',
'highscore': 'Lintlha tse Holimo',
'how_it_works': 'E sebetsa joang?',
'how_to_check': 'U sheba joang?',
'how_to_play': 'Mokhoa oa ho bapala',
'if_the_maximum_multiplier': ' • Ha chelete e u bechileng ka eona e imenahanyoa ha 20.000, ea nts’uoa ha e fihletse maemo a ho qetela a ho menahanya.',
'increase': 'Eketsa',
'input_bet_amount': 'Kenya chelete ebe ua becha pele sefofane se tloha.',
'insufficient_funds': 'Ha una chelete e lekaneng',
'it_blew_up': 'E phahlohile! Jonna oe!',
'jackpot': 'Jackpot',
'jackpot_winners': 'Bahloli ba Jackpot',
'key': 'Tlhaku',
'load_more': 'LOADA TSE LING',
'loading': 'Ea loada',
'loading_next_round': 'E loada mokhahlelo o latelang….',
'loss_gifs': 'TAHLEHELO',
'lost_connection': 'Khokahanyo e Khaohile',
'make_a_bet': 'Becha',
'malfunction_voids': 'Ha mathata a hlaha Lipatala le Lipapali lia hlakoloa.',
'maximum_payout': 'Boholo ba tefo ke ',
'menu': 'Leqephe la Pele',
'min_bet_is': 'Becha ho tloha ka',
'month': 'Khoeli',
'more_details': 'Lintlha tse ling',
'more_information': 'Litaba tsa tlatsetso',
'multiplier': 'Ho Menahanya',
'multiplier_starts': ' • Ho menahanya ho qala ho 1 ho fihlela ho 20.000',
'multipliers_larger': 'Ha chelete e bechuoeng e imena ho feta 20.000 ea emisoa.',
'music_settings': 'Liphetolelo tsa ‘mino',
'my_bet_history': 'Nalane Eaka ea ho Becha',
'my_bets': 'Lipapali tseo ke li bechileng',
'my_bonysing_history': 'Nalane ea Libonase Tsaka',
'network_latency': 'MATHATA A NETEOEKE',
'network_latency_content': 'Neteoeke e sokolisang ke ketsahalo e tloaelehileng. Ho becha le ho nts’a chelete ho netefatsoa feela ha molaetsa o fihla li-komporong tsa rona, ho sa tsotelletse hore na neteoeke e sokolisitse nako e kae.',
'network_latency_title': 'MATHATA A NETEOEKE',
'no_thanks': 'Che!',
'not_right_now': 'Eseng hona joale',
'off': 'e timme',
'oh_snap': 'Jonna oe!',
'ok_close': 'Ho lokile, Koala!',
'ok_thanks': 'Ho lokile, Kea Leboha',
'on': 'ea bapala',
'on_loss': 'TAHLEHELONG',
'on_win': 'MOTHATING OA HAPA',
'online': 'Marang-rang',
'per_bet': 'ho becha ka ‘ngoe',
'pick_your_avatar_img': 'Khetha sets’oants’o sa hau',
'place_bet_with_last': 'Becha ka chelete eo u khethileng ',
'play_now': 'Bapala Honajoale',
'player_1_seed': 'Sebapali sa pele',
'player_2_seed': 'Sebapali sa bobeli',
'player_3_seed': 'Sebapali sa boraro',
'please_wait_active_bets': 'Emela hore lipapali tseo u li bechileng tse ntse li tsoela-pele li fihle pheletsong pele o ka becha mahala',
'points': 'LINTLHA',
'powered_by': 'E ts’ehelitsoe ka matla ke',
'previous_hand': 'Letsoho le fetileng',
'prize': 'Moputso',
'prizes': 'meputso',
'progressive_jackpot': 'JACKPOT E IMENAHANYANG',
'promo_credit': 'Chelete ea Promo',
'promo_credit_condradulations': 'Rea u lebohisa! U hapile chelete e ngata ea Promo eo u ka e sebelisang ho becha papaling ena.',
'promo_credits': 'CHELETE EA PROMO',
'promo_history': 'Nalane ea Chelete ea Promo',
'provably_fair': 'Ponaletso ea Provably',
'provably_fair_1_paragraph': '\'Rocketman\' e sebelisa thekenoloji e sireletsehileng e bitsoang \'Provably Fair\'. Thekenoloji ena e netefatsa hore sephetho sa lipapali se ba le ponaletso 100%. Ka thekeloji ena ho ke ke hoa etsahala hore ho be le bo nts’unya-khare ho sitisa taolo ea papali.',
'provably_fair_2_paragraph': 'Sephetho sa papali ka ‘ngoe (Game\'s \'Fly away\' coefficient) ha se tsoe li-komporong tsa rona. Se entsoe ka thuso ea libapali ‘me sena le ponaletso. Ka tsela ena, ho ke ke hoa khoneha hore mang kapa mang a fetole sephetho sa papali. Hape, mang kapa mang a ka hlahloba le ho netefatsa ponaletso ea papali.',
'provably_fair_3_paragraph': 'Sephetho sa papali se hlaha libapaling tse nne tse ikemetseng ebile li nkile karolo papaling',
'provably_fair_4_paragraph': 'Ha papali e qala, ho hokangoa li-komporo le libapali tse tharo. Kopanong ena ho tsoaloa linomoro tsa ts’ireletso tsa boemo ba SHA512 ebe sephetho sa papali se ea tsoa.',
'provably_fair_5_paragraph': 'U ka ithuta ka ponaletso ea papali ka ‘ngoe ha u sheba nalane ea papali, ka ho tobetsa lets’oao le letala. Lebokose le buleileng, u tla bona phepelo e tsoang likomporong tsa rona, le ea libapali tse 3, linomoro tsa ts’ireletso le sephetho sa papali.',
'provably_fair_6_paragraph': 'Linomoro tsa ts’ireletso tsa lipapali tse latelang li ka bonoa ke bohle lebokosaneng la liphetolelo (ho menu ua hau, sheba “Provably Fair Settings” u ntano ea ho “Next server seed SHA256”). U ka ikhethela hore na phepelo e hlahe hokae. Haeba u batla ho ba karolo ea papali e latelang, netefatsa hore ke bonyane libapali tse tharo tse kenelang papali ho becha.',
'provably_fair_7_paragraph': 'Ho netefatsa ea linomoro tsa ts’ireletso sebelisa libali tsa lipalo inthaneteng, mohlala',
'provably_fair_system': 'ponaletso ea provably',
'quick_explanation': 'Tlhaloso ea kapele',
'randomly_generated_server_hash': 'Linomoro tsa ts’ireletso tse entsoeng ka lotho',
'rank': 'BOEMO',
'raw_multiplier': 'Semenahanyi ',
'raw_multiplier_to_edged': 'Semenahanyi ho ea ho Semenahanyi se Lokisitsoeng',
'remove_additional_bet': 'Hlakola moo o bechileng hape',
'reset': 'Khutlisetsa sohle qalong',
'return_to_player': 'KHUTLELA HO SEBAPALI',
'rocketman_is_pure_excitement': 'Rocketman ea hlabolla! Leka lehlohonolo ‘me u hape meputso',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Bahloli ba Rocketpot',
'round': 'Mokhahlelo oa Papali',
'round_id': 'Boitsebiso ba Sebapali',
'round_multiplier': 'Semenahanyi sa Mokhahlelong oa Papali',
'round_results': 'sephetho sa papali',
'rounds': 'mekhahlelo ea papali',
'rtp': 'RTP',
'rules': 'melao',
'rules_details': 'Melao ea Thonamente',
'save': 'Boloka',
'schedule': 'beha nako',
'second': 'bobeli',
'seconds_left': 's lehlakoreng le lets’ehali',
'server_hash': 'Linomoro tsa Ts’ireletso',
'please_wait_current_bets': 'EMELA HORE LIPAPALI TSEO U LI BECHILENG LI FELE',
'min_autocashout_value_must_be': 'CHELETE EO U KA E NTS’ETSOANG E TS’OANELA E BE BONYANE',
'min_bet_value_must_be': 'CHELETE EO U KA BECHANG KA EONA E TS’OANELA E BE BONYANE',
'server_seed': 'Phepelo ea Komporo',
'session_dissconnected': 'Ho bile le ts’itiso',
'multisession_not_allowed': 'Kulanka badan lama ogola',
'set_auto_bet': 'BECHELOA',
'set_auto_cashout': 'NTS’ETSE CHELETE',
'shourtcut_keys': 'MATS’OAO A POTLAKO',
'show_hex_to_deimal': 'Bonts’a hex ha u fetolela lipalo ho tse nang le feloane.',
'sound': '‘MINO',
'sound_settigs': 'Liphetolelo tsa ‘Mino',
'start_with_autoplay': 'Qala papali e itsamaisang',
'stop_autoplay': 'EMISA PAPALI E ITSAMAISANG',
'stop_on_loss': 'EMISA HA KE HLOLOA',
'stop_on_loss_tooltip': 'E ea ho Emisa ha ke Hloloa ho thibela hore u becheloe kamora hore u lahleheloe ke chelete e bechileng. <br/> Mohlala, ha u qalile ho becha ka $100 ebe u sebelisa Emisa ha ke Hloloa ha e fihla ho $25',
'stop_on_profit': 'EMISA HA KE THOLA PHAELLO',
'stop_on_profit_tooltip': 'E ea ho Emisa ha ke Thola Phaello ho thibela hore u becheloe kamora hore u thole phaello cheleteng e bechileng. <br/> Mohlala, ha u qalile ho becha ka $100 ebe u sebelisa Emisa ha ke Thola Phaello ha e fihla ho $25',
'there_are_no_active_torunament': 'Ha hona li-thonamente tse ntseng li tsoela-pele',
'third': 'boraro',
'this_game_is': 'Papali ena e',
'this_tool_recalculates': 'Karolo ena e ithuta ka semenahanyi se hlahang linomorong tsa ts’ireletso. U lokolohile ho qopitsa/ngola linomoro tsa ts’ireletso ho netefatsa semenahanyi.',
'time': 'NAKO',
'time_frame': 'Nako e Kae',
'to': 'BULA',
'toggle_animation': 'Hlahisa/Pata',
'top_odds': 'Menyetla e Holimo ea ho Hapa',
'top_wins': 'Meputso e Holimo',
'total_bets': 'Kakaretso ea ho Becha',
'tournament': 'Thonamente',
'tournament_information': 'Lintlha tsa Tournamente',
'try_advanced_autoplay': 'Leka mokhoa o mocha oa morao-rao oa papali e itsamaisang! ',
'uncredited_wins': 'Meputso e sa Hlahelleng',
'user': 'SEBAPALI',
'user_and_points': 'SEBAPALI LE LINTLHA',
'username': 'Lebitso',
'username_must_be_at_least': 'Lebitso le ts’oanela hore bonyale le',
'verify': 'Netefatsa',
'vip_popup_text': 'U ka khetha engoe ea lits’oantso tse etselitsoeng lipapali tse khethehileng (VIP) feela.',
'vip_popup_title': 'Rea u lebohisa ka hoba sebapali se khethehileng!',
'waiting_for_the_next_round': 'Emetse papali e latelang…',
'warnings_are_calculated': '  • Chelete e hapuoang e eketsoa ka eo ho bechiloeng ka eona nakong eo e nts‘uoang',
'watch_take_off': 'Boha ha Lucky Rocket e tloha ‘me chelete ea hau e eketseha.',
'watch_the_rocket': 'Boha Rocket',
'we_have_noticed': 'Re elelletsoe hore u qala ho bapala “Rocketman”. U ka lakatsa ho shebella video e khuts’oane ho u tataisa hore na e bapaloa joang?',
'week': 'Beke',
'welcome_to_rocketman': 'Rea u amohela ho Rocketman – papali ea morao-rao e hlabollang! Rocketman e u fa monyetla oa hore chelete eo u bechileng ka eona e imenahanye ha 20.000.',
'what_is_provably_fair': 'Provably fair keng?',
'will_be_deposited': 'E tla romelloa li-akhaonteng tsa libapali ha ba qala ho hapa hanyane ka hanyane',
'win': 'HAPA',
'win_amount': 'Chelete e Hapuoeng',
'win_gifs': 'HAPA',
'winner': 'mohloli',
'wins': 'meputso',
'write_a_replay': 'Arabela…',
'year': 'Selemo',
'yes': 'HO JOALO',
'yes_start_the_round': 'Tsoela-pele, qala papali',
'you_have_cashed_out': 'U nts’itse chelete, e kaalo ka ',
'you_have_won': 'U MOHLOLI',
'your_bet_is_lost': 'u hlotsoe ke papali',
'current': 'Hajoale',
'previous': 'Fetileng',
'claim': 'Amohela',
'this_feature_will_give': 'Mokhoa ona o fana ka chelete e itseng ka lotho ho lipapali tse ka lebokoseng la moqoqo',
'amount_per_player': 'Chelete ea Sebapali ka Seng',
'no_of_players': 'Palo ea Libapali',
'meteor_shower': 'Linaleli',
'claim_your_free_bet': 'Becha Mahala',
'meteor_shower_total': 'Kakaretso ea Linaleli',
'shower': 'LINALELI',
'total': 'Kakaretso',
'meteor_shower_incoming': 'Linaleli ke tseo, sheba lebokose la moqoqo!',
'insurance': 'INSHORENSE',
'insurance_paragraph': 'Libapali li ka sireletsa chelete ea bona ea ho becha ka inshorense ka ho tobetsa "Becha Inshorense” (Bet Insurance) lebokoseng la "Becha Inshorense" pela konopo ea ho becha. Inshorense e nka 10% ea chelete e bechileng \'me chelete eno e tla khutlisetsoa sebapali haeba rokete e ka phatloha mokhahlelong oa pele (1.00) oa ho menahanya chelete.',
'insure_your_bet': 'SIRELETSA CHELETE EA HAU KA INSHORENSE',
'bet_insurance_promo_text': 'Khetha “Becha Inshorense” ho sireletsa chelete ea hau ha rokete e ka phatloha mokhahelong oa pele (1.00) oa ho menahanya chelete.',
'bet_insurance_promo_text_helper': 'Bala karolo ea “Melao ea Papali” ho ithuta tse ling',
'game_explanation_1': 'Khetha semenahanyi sa hau, ‘me haeba semenahanyi sa papali se feta sa khetho ea hau, chelete eo u e hapileng e tla',
'game_explanation_2': 'Semenahanyi sa hao x ho betjha ha hao',
'retail_max_mp_1': 'Hapa ho fihlela ho',
'retail_max_mp_2': 'chelete ea ho becha',
'play_multibets': 'Ciyaar',
'multibets': 'MULTIBETS',
'bonus_game': 'CIYAAR BONUS',
'all_wins_doubled': 'Guulaha oo dhan waa la labanlaabay!',
'multibets_paragraph_1': 'si aad u kordhiso nasiibkaaga!',
'multibets_paragraph_2': 'Waxaad isku dari kartaa sharad ilaa toban wareeg ah oo aad ku guulaysato lacag bixinno badan!',
'multibets_paragraph_3': 'Mar kasta oo wareeg la guuleysto, faa\'iidada ka soo baxda wareeggaas ayaa lagu shubaa wareegga xiga, ilaa dhammaan wareegyada ay guuleystaan.',
'bonus_launch': 'Chelete e bechileng e imenahanya ha beli (x2) ho mokhahelo oa “Qala Bonase”!',
'multiplier_history': 'Nalane ea Semenahanyi',
'will_start_soon': 'e tla qala haufinyane',
'hurry_up_and_place_your_bets': 'Itlole matjato ‘me u beche!',
'active_bets': 'Lipapali tse Bechoang',
'shop': 'SHOPO',
'game_meteor_shower_paragraph': 'Linaleli li fana ka monyetla oa ho becha mahala ka chelete e lekantsoeng esita le palo la libapali. E ka kenngoa ts’ebetsong ke li-komporo tsa rona kapa sebapali. Hang ha Linaleli li kena, molaetsa oa tlhokomeliso oa hlaha ka hara papali, ho tataisa libapali ho sheba lebokose la lipuisano moo molaetsa oa Linaleli o leng teng. Libapali li ka amohela monyetla oa ho becha mahala ka ho tobetsa konopo ea “Amohela”. Monyetla oa ho becha mahala o fuoa libapali tse tobetsang kopopo ea “Amohela” pele. Ts’ebeliso ea lithusa-papali kapa software e thusang sebapali ho tobetsa le ho amohela chelete ha e ea lumelloa ho hang, ‘me sebapali se ka thibeloa ho sebelisa monyetla ona ka moso.',
'claming_failed': 'U sitiloe ho amohela',
'player_on_blacklist': 'Sebapali sena se behelletsoe ka thoko',
'you_can_claim_ticket': 'U ka nka tekete ha feela u le sebapali se nkang karolo ebile u becha',
'you_already_have_free_tickets': 'U s’o ntso u na le menyetla ea ho becha mahala, re kopa u e sebelise pele',
'please_play_the_game_in_portait_mode': 'Fadlan ciyaarta ku ciyaar hab muuqaal ah.',
};
langArr.tn = {
'your_insurance_returns_you': 'Inshorense ya gago e go busetsa',
'bet_insurance': 'Inshorense ya go Betšha',
'max_bet_is': 'Bogolo ba go betšha ke',
'already_active_bet_of': 'mme go setse go na le go betšha go go leng teng',
'1_from_game_history': '1. Go tswa go hisitori ya motshameko',
'2_in_settings_window': '2. Mo dithulaganyong tsa tiriso ya window',
'3_online_calculator': '3. Khalekhuleitara ya mafaratlhatlha',
'active_tournament_1': 'Thonamente e e tsweletseng ',
'active_tournament_2': 'ga e a betšhiwa ga jaana.',
'add_another_bet': 'Betšha gape',
'advanced_autoplay': 'ITSHAMEKISO E E KWA GODINGWANA',
'advanced_autoplay_for_bet': 'Itshamekiso e e kwa Godingwana ya go Betšha',
'alien_saucer_free_bets': 'Go betšha ga Mahala ga Alien Saucer',
'all_bets': 'Go Betšha Gotlhe',
'all_in_your_hands': 'Tsotlhe mo diatleng tsa gago!',
'allow': 'Letla',
'at': 'kwa',
'austronaut_promo_credit': 'Madi a a ka mo Letloleng a Papatso ya Astronaut',
'auto_play_and_autocash': 'ITSHAMEKISO LE ITUELO ',
'autoplay': 'Itshamekiso',
'autoplay_and_cashout': '02. Itshamekiso le ituelo',
'back_to_home': 'Boela tshimologong',
'balance': 'Madi a a leng teng',
'bet': 'BETšHA',
'bet_amount': 'TLHWATLHWA YA GO BETšHA',
'bet_and_cashout': '01. Betšha le Tuelo',
'bet_bets_tab': 'MOPUTSO',
'bet_failed': 'Go Betšha go Padile',
'bet_statistic': 'Dipalopalo tsa go Betšha',
'bet_time': 'Nako ya go Betšha',
'bet_wins': 'Diphenyo tsa go Betšha',
'bets': 'Go Betšha',
'biggest_odds': 'Bogolo jwa Kgonagalo ya Phenyo',
'bonus_type': 'MOFUTA WA MOROKOTSO',
'bonusing_history': 'Hisitori ya go bona Morokotso',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Bafenyi ba Boosterpot',
'but_remember_paragraph': 'Fela gopola, fa o tlhokile nako ya go dira Tuelo pele ga Lucky Rocket e thunya,',
'calculate_raw_mp_to_decimal': 'Bala Seatisi se se sa Tlhotlhiwang go tswa mo tesimaleng, mme o e sobokanyetse go ya kwa tlase go ya kwa integer e e gaufi',
'cancel': 'Khansela',
'cant_post_media_content': 'Ga go kgonagale go phasalatsa diteng tsa kgaso ka metlha',
'cashed_out': 'Madi a a duetsweng',
'cashout': 'Tuelo',
'cashout_at': 'TUELO KWA',
'cashout_before': 'Dira tuelo pele ga rokhete e thunya mme madi ke a gago!',
'change_avatar': 'Fetola Avatar',
'change_username': 'Fetola Leina la Modirisi',
'characters_long': 'boleele jwa ditlhaka',
'chat': 'Motlotlo',
'chat_room': 'Lefelo la Motlotlo',
'checking_for_spam': 'Lekola spam…',
'close': 'Tswalela',
'coeff': 'COEFF',
'collect_win': 'Tsaya Phenyo',
'combined_seed': 'Di seed tse di kopantsweng',
'company': 'Khampani',
'congratulations_you_have_won_free_bets': 'Re a go lebogisa! O fentse Morokotso wa go Betšha! O ka betšha',
'convert_first_13_to_decimal': ' Fetolela dibaetse tse 13 go tesimale',
'd': 'd',
'date': 'LETLHA',
'date_and_time': 'Letlha le Nako',
'deny': 'Ganetsa',
'detailed_game_rules': 'Melao ya Motshameko ka Botlalo',
'dissconnection_policy': 'PHOLISI YA KGAOLELO',
'easy_as_one_two_three': 'Bonolo jaaka, nngwe, pedi, tharo',
'enter_username': 'Tsenya leina la modirisi le o le ratang',
'error_handling': 'TSAMAISO YA PHOSO',
'exit_full_screen': 'Etswa mo tirisong ya sekerini se se tletseng',
'first': 'sa ntlha',
'first_time_playing': 'A ke lantlha o tshameka "Rocketman"?',
'folow_the_three_easy_steps': 'Latela dikgato tse tharo tse di bonolo, mme o itumelele RocketMan! ',
'free_bets': 'GO BETŠHA MAHALA',
'free_bets_desc': 'O fentse go betšha ya mahala! O ka iponna go betšha ga mahala nako nngwe le nngwe fa o tobetsa konopo ya go simolola.',
'free_bets_header': 'Go betšha mahala',
'from': 'GO TSWA GO',
'fullscreen_mode': 'Go bona maitemogelo a a botoka jaaka modirisi, motshameko o tla simolola ka tiriso ya sekerini se se tletseng',
'function': 'Tiriso',
'game_functions': 'Ditiriso tsa Motshameko',
'game_history': 'HISITORI YA MOTSHAMEKO',
'game_r_1_paragraph': 'Motshameki a ka dirisa boikgethelo jwa itshamekiso foo go betšha go diragalang ka go itirisa mo tikologong nngwe le nngwe e ntšhwa a dirisa madi a go betšha go tswa mo go betšheng go go fetileng.',
'game_r_10_paragraph': 'Fela fa madi a a ka mo letloleng a papatso a sena go abelwa, mo go betšheng gongwe le gongwe go motshameki a go dirang, madi a tla gogwa pele go tswa mo mading a a ka mo letloleng a papatso go fitlhela a dirisiwa otlhe, pele go tswelelwa go gogwa madi go tswa mo sekgwameng sa batshameki.',
'game_r_11_paragraph': 'Madi a a ka mo letloleng a papatso a ka se kgonwe go duelwa, mme diphenyo tse dingwe mo go betšheng go go jalo di duelwa ka botlalo go motshameki.',
'game_r_12_paragraph': 'Madi a a ka mo letloleng a papatso a na le sebaka sa malatsi a le 30 fela mme morago ga moo a felelwa ke tiriso mme ga a sa kgona go diriswa.',
'game_r_13_paragraph': 'Motshameki a ka abelwa go betšha ga mahala go sa rulaganngwa. Kerafiki e e tlhagelelang fela e tla tlhagelela mo sekerining go itsise motshameki ka go betšha ga mahala go go abetsweng, palo ya go betšha le madi a betšho nngwe le nngwe.',
'game_r_14_paragraph': 'Fela fa go betšha ga mahala go abetswe motshameki a ka tlhopha gore o simolola leng tikologo ya mahala ya go betšha. Fa fela tikologo ya mahala ya go betšha e simolotswe, go betšha ga itiriso ka moputso o o rileng o a beeletswa, mme go tswa mo motshameking go dira tuelo ka nako nngwe le nngwe. Fa motshameki a kgaotsa ka nako ya go betšha ga mahala, go betšha ga mahala go tla tswela pele mme go tla latela “Pholisi ya Kgaolelo”.',
'game_r_15_paragraph': 'Go betšha ga mahala go nale sebaka sa malatsi a le 30 fela mme morago ga moo a felelwa ke tiriso mme ga a sa kgona go diriswa.',
'game_r_16a_paragraph': 'Palo e ya kakaretso ya semelo ya go kgutla go sebapadi ke ',
'game_r_17_paragraph': 'Diperesente tsa RTP di tsweletswe go elwa tlhoko le go sekasekwa ke makoko a a kwa thoko a a ikemetseng.',
'game_r_18_paragraph': 'Fa kgolagano e latlhega morago ga go betšha, go betšha go ka seka ga khanselwa. Fa seatisi sa fitlha 20.000x go betšha ga gago go tla duelwa ka itiriso mme madi a a ka mo letloleng a tla tsenngwa mo akhaontong ya gago.',
'game_r_19_paragraph': 'O ka tlotla mo motshamekong le batshameki ba bangwe. Tsenya molaetsa wa gago mo lefelong la motlotlo, kgotsa abelana diphenyo tsa gago tsa go betšha le batshameki ba bangwe.',
'game_r_2_paragraph': 'Seatisi sa tuelo ya itiriso se ka tsenngwa mo lefelong la “Tuelo ya itiriso”, mme fa le tsentswe, motshameko o tla duelwa ka itiriso fa seatasi se se beilweng se fitlheletswe.',
'game_r_20_paragraph': 'Batshameki ba ba dirisang Motlotlo botlhaswa ka go sotla batshameki ba bangwe, kgotsa ka go dirisa go tlotla go go sa nnang sentle le/kgotsa matlhapa ba tla kgaolelwa boemo jwa bona jwa Motlotlo.',
'game_r_21_paragraph': 'Rocketman e tlhamilwe ka kgopolo ya lemorago la mmino le le ntle le di dira modumo. Fa o eletsa go kgaotsa mmino le/kgotsa di dira modumo o ka dira jalo ka go latela lenaneo la ditaelo tsa motshameko.',
'game_r_22_paragraph': 'Ka go tobetsa konopo ya “Go Betšha ga me” motshameki a ka sekaseka go betšha ga gagwe ga sesheng. Go bona hisitori ya go tshameka go ya pele (sk. mabaka a maleele), motshameki a ka tlhoka go fitlhelela hisitori ya motshameki mo polatefomong ya motshameko ka bo yona.',
'game_r_23_paragraph': 'Mo tiragalong ya phoso ditikologo di ka nna tsa se simolole ka nako. Fa go le jalo setlhopha sa rona sa tshegetso ba tla leka go rarabolola bothata ka bonako jo bo kgonagalang, mme go simolole ditikologo tse di tlwaeledileng tsa motshameko. Mo lobakeng la bothata ka tikologo ya motshameko ka bo yona, go betšha gotlhe go tlile go khanselwa mme tlhwatlhwa ya moputso e tla busediwa go batshameki.',
'game_r_24_paragraph': 'Tiego ya Network ke karolo ya se se sa efosegeng mo kgokaganyong ya inthanete. Re tsaya gore go betšha gotlhe le dituelo di letleletswe fela ka nako e di fitlhang go server ya rona, go sa kgathaletsege lobaka la tiego ya network. ',
'game_r_3_paragraph': 'Motshameki a ka dirisa dikonopo tsa phitlhelelo ka pele go betšha ka bonako kgotsa go dira tuelo mo motshamekong. Ditaelo tsa dikonopo di ka tsela e e latelang',
'game_r_4_paragraph': '1% ya selekanyo sengwe le sengwe e neelwa mokgopong wa progressive jackpot. Mokgopo o o sebeletsa tsamaiso ya progressive jackpot.',
'game_r_5_paragraph': ' • Jekepoto – Selekanyo se se kwa godimo go gaisa, sekgele se segolo go gaisa, se se sa diragaleng kgapetsa-kgapetsa',
'game_r_6_paragraph': ' • Rocketpot – Selekanyo se se magareng, sekgele se se magareng',
'game_r_7_paragraph': ' • Boosterpot – Selekanyo se se kwa tlase-tlase, sekgele se se kwa tlase, se se diragalang kgapetsa-kgapetsa thata ',
'game_r_8_paragraph': 'Maemo a dijekepoto tsotlhe a abelwa go diriswa ditiriso tsa “Mystery” kgotsa “must-hit-by”. Boleng jwa jekepoto ya “must-hit-by” nngwe le nngwe e swediwa ka bonako morago ga jekepoto e e tlang pele e sena go fenngwa ka setlhagisi sa palo e e sa rulaganngwang mme e bolokilwe jaaka boleng jo bo fitlhegileng mo polokelotshedimosetsong e e kwa kgakajana. Boleng bo fitlhegile, ka jalo bo fitlhilwe ebile ga bo itsiwe. Jekepoto e fenngwa ke motshameki wa ntlha yo moputso wa gagwe o dirang jekepoto go feta boleng jwa sephiri. Mofenyi wa jekepoto o itsisiwe morago ga tikologo e sena go fela.',
'game_r_8a_paragraph': 'Ela tlhoko: Mofenyi wa jekepoto e ka nna fela motshameki a le mongwe wa Rocketman yo o tshamekang mo tikologong ka nako e jekepoto e setse e simolotswe. Ga gona kgonagalo ya gore fa motshameki a fentse jekepoto, o tla tshwanela ke go abelana le motshameki mongwe wa Rocketman.',
'game_r_9_paragraph': 'Motshameki a ka abelwa madi a a ka mo letloleng a papatso go sa rulaganngwa. Kerafiki e e tlhagelelang e tla bonala mo sekerining go itsise motshameki ka ga madi a a ka mo letloleng a papatso a a abilweng le madi a yona.',
'game_round': 'ditikologo tsa motshameko!',
'game_rules': 'Melao ya Motshameko',
'game_rules_1_paragraph': 'Rocketman ke boitapoloso jwa motshameko jwa leloko le le ntšhwa. Fenya gantsi mo metsotswaneng! Rocketman e agilwe mo',
'game_rules_10_paragraph': ' • Itshamekiso e diragadiwa go tswa mo go konopong ya “Itiriso” mo Phaneleng ya go Betšha, ka go lekola lebokoso la “Go Betšha ga Itiriso”. Morago ga go diragadiwa, go betšha go tla beeletswa ka itiriso, mme ka ga go duelwa, o tshwanetse go tobetsa konopo ya “tuelo” mo tikologong nngwe le nngwe. Fa o batla go betšha go ntsha tuelo ka itiriso, ka jalo dirisa',
'game_rules_11_paragraph': '• Tuelo ya Itiriso e fitlhelwa go tswa mo konopong ya “Itiriso” mo phaneleng ya go betšha. Morago ga go diragatsa, go betšha ga gago go tla duelwa ka itiriso, fa e fitlha mo coefficient e e tsentsweng.',
'game_rules_2_paragraph': ', se e leng sona fela ga jaana tlhomamiso ya boammaruri mo intasetering ya motshameko wa go betšha.',
'game_rules_3_paragraph': '• Seatisi sa phenyo se simolola kwa 1x mme segola go feta le go feta fa Lucky Plane e tloga.',
'game_rules_4_paragraph': ' • Diphenyo tsa gago ke diatisi tse o dirileng tuelo e e atisitsweng ke go betšha ga gago. ',
'game_rules_5_paragraph': '• Pele o simolola tikologo nngwe le nngwe, setlhagisi sa palo e e sa rulaganngwang sa provably fair se tlhagisa coefficient e Lucky Plane e tla fofang ka yona. O ka lekola boammaruri ba tlhagiso eno ka go tobetsa mo letlhakoreng le lengwe la dipholo mo konopong ya Hisitori.',
'game_rules_6_paragraph': '• Tlhopha tlhwatlhwa mme o tobetse konopo ya “Betšha” gore o betšhe. ',
'game_rules_7_paragraph': '• O ka betšha gabedi ka nako e le nngwe, ka go lokela phanele ya go betšha ya bobedi. Go lokela phanele ya go betšha ya bobedi tobetsa aekhone ya koketso, e e leng fa godimo ga sekhutlo sa moja sa phanele ya go betšha ya ntlha.',
'game_rules_8_paragraph': ' • Tobetsa konopo ya “Tuelo” go duelwa diphenyo tsa gago. Phenyo ya gago e atisitswe ka coefficient ya tuelo.',
'game_rules_9_paragraph': '• O latlhegetswe ke go betšha, fa o sa dira tuelo pele sefofane se fofa.',
'general_rules': 'MELAO KAKARETSO',
'gifs': 'GIFS',
'go_back': 'Boela Morago',
'good_job_buddy': 'Tiro e ntle mmonkane, tswelela jalo',
'h': 'h',
'hashed_in_sha_512': 'Hashed mo SHA512',
'hex': 'Hex',
'hey_username': 'Dumela',
'hide_hex_to_decimal': 'Fitlha hex go ya go phetolelo ya tesimale',
'highscore': 'Maduo a a kwa Godimo',
'how_it_works': 'E dira jang?',
'how_to_check': 'O tlhola jang?',
'how_to_play': 'O tshameka jang',
'if_the_maximum_multiplier': '• Fa bogolo ba seatisi jwa 20.000 bo fitlheletswe, go betšha gotlhe go go buletsweng go dira tuelo ka itiriso go tswa mo bogolong jwa seatisi',
'increase': 'Oketsa',
'input_bet_amount': 'Tsenya tlhwatlhwa ya go betšha mme o betšhe pele ga phofo.',
'insufficient_funds': 'Letlole ga le lekane',
'it_blew_up': 'E thuntse! Legale…',
'jackpot': 'Jekepoto',
'jackpot_winners': 'Bafenyi ba Jekepoto',
'key': 'Konopo',
'load_more': 'LAISA GAPE',
'loading': 'E a laisa',
'loading_next_round': 'E laisa tikologo e e latelang….',
'loss_gifs': 'TATLHEGELO',
'lost_connection': 'Kgokaganyo e Latlhegile',
'make_a_bet': 'Betšha',
'malfunction_voids': 'Go sa dire go dira tatlhegelo ya dituelo tsotlhe le metshameko yotlhe.',
'maximum_payout': 'Bogolo jwa tuelo ke ',
'menu': 'Leneneo la ditaelo tsa diteng',
'min_bet_is': 'Bonnye jwa go betšha ke',
'month': 'Kgwedi',
'more_details': 'Dintlha ka botlalo',
'more_information': 'Tshedimosetso ka botlalo',
'multiplier': 'Seatisi',
'multiplier_starts': '• Seatisi se simolola mo 1 mme se namelela go fitlha go 20.000',
'multipliers_larger': 'Seatisi se segolo go feta x20.000 se fitlhisiwa go bogolong jwa boleng jwa seatisi',
'music_settings': 'Dithulaganyo tsa Mmino',
'my_bet_history': 'Hisitori ya go Betšha ga Me',
'my_bets': 'Go Betšha ga me',
'my_bonysing_history': 'Hisitori ya go Bona Morokotso ga Me',
'network_latency': 'TIEGO YA NETWORK',
'network_latency_content': 'Tiego ya Network ke karolo ya se se sa efosegeng mo kgokaganyong ya inthanete. Re tsaya gore go betšha gotlhe le dituelo di letleletswe fela ka nako e di fitlhang go server ya rona, go sa kgathaletsege lobaka la tiego ya network.',
'network_latency_title': 'TIEGO YA NETWORK',
'no_thanks': 'Nnyaa, ke a leboga!',
'not_right_now': 'E seng jaanong',
'off': 'timile',
'oh_snap': 'Bathong!',
'ok_close': 'Ee, Gaufi Thata!',
'ok_thanks': 'Ee, Ke a leboga!',
'on': 'tshumile',
'on_loss': 'MO TATLHEGELONG',
'on_win': 'MO PHENYONG',
'online': 'Mafaratlhatlheng',
'per_bet': 'ka go betšha',
'pick_your_avatar_img': 'Tlhopha setshwantsho sa sa gago sa Avatar',
'place_bet_with_last': 'Betšha ka moputso wa bofelo o o tlhophilweng ',
'play_now': 'Tshameka Jaanong',
'player_1_seed': 'Sesupo sa Motshameki 1',
'player_2_seed': 'Sesupo sa Motshameki 2',
'player_3_seed': 'Sesupo sa Motshameki 3',
'please_wait_active_bets': 'Tsweetswee emela go betšha ga jaanong go konosediwe gore go betšha ga mahala go kgone go dira',
'points': 'DINTLHA',
'powered_by': 'E diragadiwa ke',
'previous_hand': 'Tsa Logato lo lo Fetileng',
'prize': 'SEKGELE',
'prizes': 'dikgele',
'progressive_jackpot': 'JEKEPOTO E E TSWELELANG',
'promo_credit': 'Madi a a ka mo Letloleng a Papatso',
'promo_credit_condradulations': 'Re a go lebogisa! O fentse phitlhelelo e e kwa godimodimo ya Madi a a ka mo letloleng a papatso a o ka a dirisang mo motshamekong!',
'promo_credits': 'MADI A A KA MO LETLOLENG A PAPATSO',
'promo_history': 'Hisitori ya Papatso',
'provably_fair': 'Provably Fair',
'provably_fair_1_paragraph': '\'Rocketman\' e ikaegile ka tekenoloji ya bofitlha e e bidiwang \'Provably Fair\'. Tekenoloji eno e tlhomamisa tetlelesego e 100% ya dipoelo tsa motshameko. Ka tekenoloji eno, ga go kgonagale gore modirisi wa kwa ntle a tsenelele tsamaiso ya motshameko.',
'provably_fair_2_paragraph': 'Dipoelo tsa tikologo nngwe le nngwe (Mo/Metshameko \'Fofile\' coefficient) ga e tlhagisiwe mo di server tsa rona. E tlhagisiwa ka thuso mo tikologong ya batshameki mme e na le boammaruri jo bo tletseng. Ka tsela e, ga go kgonagale gore mongwe a ka dira bonokwane mo dipoelong tsa motshameko. Gape, mongwe le mongwe a ka lekola le go netefatsa boammaruri jwa motshameko.',
'provably_fair_3_paragraph': 'Dipholo tsa tikologo di tlhagisiwa go tswa go batsayakarolo ba ba ikemetseng ba le bane ba tikologo',
'provably_fair_4_paragraph': 'Fa tikologo e simolola, motshameko o kopannya server seed le client seeds di le tharo. Go tswa mo matshwaong a a kopantsweng go tlhagisiwa SHA512 hash, mme go tswa go hash eno – dipholo tsa motshameko.',
'provably_fair_5_paragraph': 'O ka lekola boammaruri jwa tikologo nngwe le nngwe go tswa mo hisitoring ya motshameko, ka go tobetsa aekhone e tala. Mo go butsweng teng, o tla bona server seed, ditlhopha di le 3 tsa seeds tsa batshameki, hash e e kopantsweng le dipholo tsa tikologo.',
'provably_fair_6_paragraph': 'Mofuta o o dirilweng wa hash wa tikologo e e latelang wa server seeds o bonwa phatlhalatsa mo dithulaganyong tsa window (Mo lenaneong la ditaelo tsa diteng la modirisi, lekola “Dithulaganyo tsa Provably Fair” mme e e latelang “E e latelang ya server seed ya SHA256”). O ka fetola client seed ya gago fano. Fa o batla go tsaya karolo mo tlhagisong ya dipholo tsa tikologo, netefatsa gore o magareng ga batshameki ba le bararo ba ntlha ba ba betšhang mo tikologong eo.',
'provably_fair_7_paragraph': 'Nepagalo ya hash e ka lekolwa mo khalekhuleitareng nngwe le nngwe ya mafaratlhatlha, sekao',
'provably_fair_system': 'tsamaiso ya provably fair',
'quick_explanation': 'Tlhaloso ya ka bonako',
'randomly_generated_server_hash': 'Server ya HASH e e tlhagisitsweng e sa rulaganngwa',
'rank': 'BOEMO',
'raw_multiplier': 'seatisi se se sa tlhotlhiwang',
'raw_multiplier_to_edged': 'Seatisi se se sa Tlhotlhiwang go ya go Seatisi se se oketsang Dintlha, e dirisa',
'remove_additional_bet': 'Tlosa go betšha ga tlaleletso',
'reset': 'Simolola gape',
'return_to_player': 'BOELA GO MOTSHAMEKI',
'rocketman_is_pure_excitement': 'Rocketman ke ya boitumediso fela! Lekelela mme o fenye.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Bafenyi ba Rocketpot',
'round': 'Tikologo',
'round_id': 'Id ya Tikologo',
'round_multiplier': 'Seatisi sa Tikologo',
'round_results': 'dipholo tsa tikologo',
'rounds': 'ditikologo',
'rtp': 'RTP',
'rules': 'melao',
'rules_details': 'Melao ya dithonamente',
'save': 'Boloka',
'schedule': 'go rulaganya',
'second': 'metsotswana',
'seconds_left': 'e e setseng',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'TSWEETSWEE EMELA GO BETšHA GA JAANONG GO KONOSETSE',
'min_autocashout_value_must_be': 'BONNYE JWA BOLENG JWA ITUELO BO TSHWANETSE GO NNA',
'min_bet_value_must_be': 'BONNYE JWA BOLENG JWA GO BETšHA BO TSHWANETSE GO NNA',
'server_seed': 'Server Seed',
'session_dissconnected': 'Tshameko e Kgaotswe',
'multisession_not_allowed': 'Dithulaganyo tse dintsi ga di a letlelelwa',
'set_auto_bet': 'DIRA GO BETšHA GA ITIRISO',
'set_auto_cashout': 'DIRA TUELO YA ITIRISO',
'shourtcut_keys': 'DIKONOPO TSA PHITLHELELO KA PELE',
'show_hex_to_deimal': 'Bontsha hex go ya go phetolelo ya tesimale',
'sound': 'MODUMO',
'sound_settigs': 'Dithulaganyo tsa Modumo',
'start_with_autoplay': 'Simolola ka itshamekiso',
'stop_autoplay': 'EMISA ITSHAMEKISO',
'stop_on_loss': 'EMISA MO TATLHEGELONG',
'stop_on_loss_tooltip': 'Dirisa Emisa mo Tatlhegelong go emisa go betšha ga itiriso morago ga go latlhegelwa ke tlhwatlhwa e e rileng.<br/> Sekao, fa o simolola ka $100 mme o dira Emisa mo Tatlhegelong go $25',
'stop_on_profit': 'EMISA MO DIPOELONG',
'stop_on_profit_tooltip': 'Dirisa Emisa mo Dipoelong go emisa go betšha ga itiriso morago ga go bona dipoelo tsa tlhwatlhwa e e rileng. <br/> Sekao, fa o simolola ka $100 mme o dira Emisa mo Dipoelong go $25',
'there_are_no_active_torunament': 'Ga gona dithonamente tse di tsweletseng ga jaana.',
'third': 'boraro',
'this_game_is': 'Motshameko ono o',
'this_tool_recalculates': 'Sediriswa se bala bosha seatisi go tswa mo hash ya motshameko. O ka kopolola/mamaretsa hash nngwe le nngwe ya motshameko go netefatsa seatisi.',
'time': 'NAKO',
'time_frame': 'Selekanyetso sa Nako',
'to': 'GO',
'toggle_animation': 'Taolo ya Animation',
'top_odds': 'Dikgonagalo tse di kwa Godimo',
'top_wins': 'Diphenyo tse di kwa Godimo',
'total_bets': 'Go Betšha Gotlhelele',
'tournament': 'Thonamente',
'tournament_information': 'Tshedimosetso ya thonamente',
'try_advanced_autoplay': 'Leka dikarolo tse dintšhwa tse di tlhabolotsweng tsa itshamekiso ',
'uncredited_wins': 'Diphenyo tse di sa duelwang',
'user': 'MODIRISI',
'user_and_points': 'MODIRISI LE DINTLHA',
'username': 'Leina la modirisi',
'username_must_be_at_least': 'Leina la modirisi le tshwanetse go nna bokanaka',
'verify': 'Netefatsa',
'vip_popup_text': 'O ka tlhopha nngwe ya di avatar tse di kgethegileng tsa VIP tse di leng teng go maloko a rona a VIP a a kgethegileng!',
'vip_popup_title': 'Re a go lebogisa go nna motshameki wa VIP',
'waiting_for_the_next_round': 'Emetse tikologo e e latelang…',
'warnings_are_calculated': ' • Diphenyo di badiwa ka go atisa meputso ya go betšha ka seatisi mo tiragalong ya nako ya Tuelo',
'watch_take_off': 'Lebelela fa Lucky Rocket ya gago e fofa mme diphenyo tsa gago di oketsega',
'watch_the_rocket': 'Lebelela Rocket',
'we_have_noticed': '‘Re lemogile gore ke lwantlha o tshameka “Rocketman”. A o ka rata go bona bidio ya ka bonako e e tlhalosang gore o e tshameka jang?',
'week': 'Beke',
'welcome_to_rocketman': 'O amogetswe mo Rocketman – motshameko o montšhwa ebile o itumedisa! Ka Rocketman o na le tšhono ya go fenya go fitlha go 20.000x ya moputso wa gago wa ntlha.',
'what_is_provably_fair': 'Provably Fair ke eng?',
'will_be_deposited': 'E tla tsenngwa mo mading a a leng teng a motshameko ka phenyo ya ntlha e e tlwaelegileng',
'win': 'PHENYO',
'win_amount': 'Tlhwatlhwa ya Phenyo',
'win_gifs': 'PHENYO',
'winner': 'mofenyi',
'wins': 'diphenyo',
'write_a_replay': 'Kwala karabo…',
'year': 'Ngwaga',
'yes': 'EE',
'yes_start_the_round': 'Ee, simolola tikologo',
'you_have_cashed_out': 'O dirile tuelo, phenyo ya gago ke ',
'you_have_won': 'O FENTSE',
'your_bet_is_lost': 'o latlhegetswe ke go betšha',
'current': 'Ga jaana',
'previous': 'Go go fetileng',
'claim': 'Batla',
'this_feature_will_give': 'Karolo eno e tla naya tlhwatlhwa e e tlhophilweng go badirisi ba ba sa rulaganngwang mo motlotlong',
'amount_per_player': 'Tlhwatlhwa Go ya ka Motshameki',
'no_of_players': 'Palo ya Batshameki',
'meteor_shower': 'Meteor Shower',
'claim_your_free_bet': 'Batla Go Betšha ga Mahala ga Gago',
'meteor_shower_total': 'Palogotlhe ya Meteor Shower',
'shower': 'SHOWER',
'total': 'Palogotlhe',
'meteor_shower_incoming': 'Meteor Shower e e etla, lebelela motlotlo',
'insurance': 'INSHORENSE',
'insurance_paragraph': 'Batshameki ba ka reka inshorense ya go betšha ka tobetsa mo lebokosong la “Inshorense ya go Betšha” gaufi le konopo ya go betšha. Inshorense e duelelwa 10% ya palogatlhe ya tlhwatlhwa ya go betšha mme e tla duela morago tlhwatlhwa ya go betšha go motshameki fa rokhete e ka thunya kwa seatisi se leng kwa 1.00.',
'insure_your_bet': 'INSHORA GO BETšHA GA GAGO',
'bet_insurance_promo_text': 'Tlhopha boikgethelo jwa “Inshorense ya go Betšha” go dira inshorense ya go betšha ga gago mo go thubaganeng ga rocket kwa seatising sa 1.00.',
'bet_insurance_promo_text_helper': 'Buisa go ya pele mo kgaolong ya “Melao ya Motshameko”…',
'game_explanation_1': 'Tlhopha seatisi sa gago, mme fa seatisi sa motshameko se feta seatisi se o se tlhophileng, phenyo ya gago e tla',
'game_explanation_2': 'Seatisi sa gago x Go Betšha ga gago',
'retail_max_mp_1': 'Fenya go fitlha go',
'retail_max_mp_2': 'go betšha ga gago!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'MOtshameko wa BONASE',
'all_wins_doubled': 'Diphenyo tsotlhe di menagane gabedi!',
'multibets_paragraph_1': 'go oketsa ditšhono tsa gago!',
'multibets_paragraph_2': 'O ka nna wa kopanya dibetšhe mo ditikologong tse di ka fitlhang go di le lesome go nna multibet e le nngwe mme wa fenya dituelo tse dintsi!',
'multibets_paragraph_3': 'Nako le nako fa go fenngwa tikologo, karolo e e fentsweng go tswa mo tikologong eo e betšha mo tikologong e e latelang, go fitlha ditikologo tsotlhe di fenya.',
'bonus_launch': 'Fenya x2 mo go betšheng gotlhe ga gago mo "tikologong ya Bonus Launch"!',
'multiplier_history': 'Hisitori ya Seatisi',
'will_start_soon': 'e tla simolola go sa le gale',
'hurry_up_and_place_your_bets': 'Itlhaganele mme o betšhe!',
'active_bets': 'Go Betšha go go Tsweletseng',
'shop': 'LEBENKELE',
'game_meteor_shower_paragraph': 'Meteor Shower e abelana ka go betšha gangwe ga boleng jo bo setseng bo tlhalositswe go ya go palo e e setseng e tlhalositswe ya batshameki. E ka simololwa gongwe ke server kgotsa ke motshameki. Fa fela Meteor Shower e setse e tsweletse, molaetsa o tsena mo motshamekong, o laela badirisi go lebelela motlotlo wa molaetsa wa Meteor Shower o leng teng. Batshameki ba ka batla go betšha ga mahala ka go tobetsa mo konopong ya “Batla”. Go betšha ga mahala go abelwa batshameki ba ba tobetsang konopo ya “Batla” pele. Tiriso ya itobetso kgotsa software nngwe le nngwe ya itiriso go etsisetsa go tobetsa ga motshameki le go batla dikgele ga go a letlelelwa ebile go ka dira gore o ganetswe mo tirisong e.',
'claming_failed': 'Go batla go padile',
'player_on_blacklist': 'Motshameki o mo palong ya ba ba sa letlelelwang',
'you_can_claim_ticket': 'O ka batla tekete fa fela o le motshameki yo o tsweletseng ka go betšha ka tlwaelo',
'you_already_have_free_tickets': 'O setse o na le ditekete tsa mahala, tsweetswee di dirise pele',
'please_play_the_game_in_portait_mode': 'Tsweetswe tshameka papadi ka mokgwa wa sefahleho.',
};
langArr.ar = {
'your_insurance_returns_you': 'التأمين الخاص بك يعود لك',
'bet_insurance': 'تأمين الرهان',
'max_bet_is': 'الرهان الأقصى هو',
'already_active_bet_of': 'وهناك بالفعل رهان نشط',
'1_from_game_history': '1. من سجل اللعبة',
'2_in_settings_window': '2. في نافذة الإعدادات',
'3_online_calculator': '3. الآلة الحاسبة أونلاين',
'active_tournament_1': 'لا تحتوي البطولة النشطة ',
'active_tournament_2': 'على أي رهانات بعد.',
'add_another_bet': 'إضافة رهان آخر',
'advanced_autoplay': 'اللعب التلقائي المتقدم',
'advanced_autoplay_for_bet': 'اللعب التلقائي المتقدم للرهان',
'alien_saucer_free_bets': 'الرهانات المجانية على الصحن الفضائي',
'all_bets': 'جميع الرهانات',
'all_in_your_hands': 'جميع ذلك طوع بنانك!',
'allow': 'سماح ',
'at': 'عند',
'austronaut_promo_credit': 'رصيد برومو رائد الفضاء',
'auto_play_and_autocash': 'اللعب التلقائي وجمع النقود التلقائي ',
'autoplay': 'اللعب التلقائي',
'autoplay_and_cashout': '02. لعب تلقائي وجمع تلقائي للنقود',
'back_to_home': 'عودة إلى الصفحة الرئيسية',
'balance': 'الرصيد',
'bet': 'رهان',
'bet_amount': 'مبلغ الرهان',
'bet_and_cashout': '01. الرهان وجمع النقود',
'bet_bets_tab': 'رهان',
'bet_failed': 'فشل الرهان',
'bet_statistic': 'إحصاءات الرهان',
'bet_time': 'وقت الرهان',
'bet_wins': 'انتصارات الرهان',
'bets': 'رهانات',
'biggest_odds': 'أهم الفرص',
'bonus_type': 'نوع المكافأة',
'bonusing_history': 'سجل المكافآت',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'الفائزون بـ Boosterpot',
'but_remember_paragraph': 'انتبه، إذا لم يكن لديك متسع من الوقت لجمع النقود قبل أن ينفجر الصاروخ،',
'calculate_raw_mp_to_decimal': 'حساب المضاعف الأولي من العدد العشري، وتقريبه إلى أقرب عدد صحيح',
'cancel': 'إلغاء',
'cant_post_media_content': 'لا يمكن نشر محتوى الوسائط مرارًا',
'cashed_out': 'النقود التي تم جمعها',
'cashout': 'جمع النقود',
'cashout_at': 'سحب النقود في',
'cashout_before': 'قم بجمع النقود قبل أن ينفجر الصاروخ وسيكون المال من نصيبك!',
'change_avatar': 'تغيير صورة الآفاتار',
'change_username': 'تغيير اسم المستخدم',
'characters_long': 'حرف',
'chat': 'دردشة',
'chat_room': 'غرفة الدردشة',
'checking_for_spam': 'جاري التحقق من البريد العشوائي...',
'close': 'إغلاق',
'coeff': 'المعامل',
'collect_win': 'تحصيل الفوز',
'combined_seed': 'القيمة المجمّعة',
'company': 'الشركة',
'congratulations_you_have_won_free_bets': 'تهانينا! لقد فزت بـBonus Bets! يمكنك الرهان',
'convert_first_13_to_decimal': ' تحويل أول 13 بايت إلى عدد عشري',
'd': 'أيام',
'date': 'التاريخ',
'date_and_time': 'الوقت والتاريخ',
'deny': 'رفض',
'detailed_game_rules': 'قواعد اللعبة المفصلة',
'dissconnection_policy': 'سياسة انقطاع الاتصال',
'easy_as_one_two_three': 'بسهولة تامة',
'enter_username': 'يُرجى إضافة اسم مستخدم مرغوب فيه',
'error_handling': 'معالجة الخطأ',
'exit_full_screen': 'الخروج من وضع ملء الشاشة',
'first': 'الأول',
'first_time_playing': 'هل هذه أول مرة تلعب فيها "Rocketman"؟',
'folow_the_three_easy_steps': 'اتبع ثلاث خطوات سهلة واستمتع بـ Rocketman! ',
'free_bets': 'الرهانات المجانية',
'free_bets_desc': 'فزت برهانات مجانية! يمكنك الاستفادة من الرهانات المجانية في أي وقت عبر النقر على زر بدء.',
'free_bets_header': 'رهانات مجانية',
'from': 'من',
'fullscreen_mode': 'لتحسين تجربة المستخدم، ستبدأ اللعبة في وضع ملء الشاشة.',
'function': 'الوظيفة',
'game_functions': 'وظائف اللعبة',
'game_history': 'سجل اللعبة',
'game_r_1_paragraph': 'بإمكان لاعب أن يقوم بتفعيل خيار التشغيل التلقائي حيث يتم وضع الرهان تلقائيًا لكل جولة جديدة باستخدام مبلغ الرهان من الرهان السابق.',
'game_r_10_paragraph': 'حالما يتم منح أرصدة البرومو، لأي رهان يضعه اللاعب، سيتم خصم الأموال في البداية من أرصدة البرومو حتى نفادها، قبل الاستمرار في سحب الأموال من محفظة اللاعبين.',
'game_r_11_paragraph': 'لا يمكن جمع نقود أرصدة البرومو، لكن سيتم إضافة أي أرباح من مثل هذه الرهانات إلى اللاعب بالكامل.',
'game_r_12_paragraph': 'لأرصدة البرومو فترة صلاحية تستمر لـ 30 يومًا وبعد ذلك تنتهي صلاحيتها ولا يمكن استخدامها.',
'game_r_13_paragraph': 'قد يتم منح الاعب رهانات مجانية عشوائيًا. سيظهر رسم منبثق على الشاشة يخطر اللاعب بالرهانات المجانية الممنوحة وعدد الرهانات ومبلغ كل رهان.',
'game_r_14_paragraph': 'حالما يتم منح الرهانات المجانية،‎ ‎يمكن للاعب اختيار وقت بدء جولة الرهان المجانية. حالما تبدأ جولة الرهان المجانية، سيتم وضع رهان تلقائي بمبلغ محدد، ويعود الأمر للاعب فيما إذا رغب بجمع النقود في أي لحظة. إذا انقطع اتصال اللاعب بالإنترنت أثناء الرهانات الم',
'game_r_15_paragraph': 'للرهانات المجانية فترة صلاحية تستمر لـ 30 يومًا وبعد ذلك تنتهي صلاحيتها ولا يمكن استخدامها.',
'game_r_16a_paragraph': 'العائد النظري الإجمالي للاعب هو ' ,
'game_r_17_paragraph': 'يتم مراقبة النسب المئوية للعائد المادي للاعبين باستمرار والتحقق منها من قبل أطراف ثالثة مستقلة.',
'game_r_18_paragraph': 'إذا فُقد الاتصال بالإنترنت بعد وضع الرهان، فلن يتم إلغاء الرهان. إذا وصل المضاعف إلى X‏20.000، فسيتم جمع نقود رهانك تلقائيًا وسيتم تحويل الأرصدة إلى حسابك.',
'game_r_19_paragraph': 'يمكنك الدردشة مع اللاعبين الآخرين في اللعبة. أدخل رسالتك في حقل الدردشة أو شارك رهانك الفائز مع اللاعبين الآخرين.',
'game_r_2_paragraph': 'يمكن‎ ‎إدراج مضاعف جمع النقود التلقائي في حقل "جمع النقود التلقائي"، وعندما يتم إدراجه، ستقوم اللعبة بجمع النقود تلقائيًا إذا تم الوصول إلى المضاعف المحدد.',
'game_r_20_paragraph': 'سيتم تعطيل امتيازات الدردشة لدى اللاعبين الذين يستغلون خاصية الدردشة للإساءة إلى لاعبين آخرين أو الذين يستخدمون لغة غير لائقة و‎/‎أو بذيئة.',
'game_r_21_paragraph': 'تم تصميم Rocketman مع وضع الموسيقى الخلفية الجميلة والمؤثرات الصوتية في الاعتبار. إذا كنت ترغب بتعطيل الموسيقى و‎/‎أو المؤثرات الصوتية يمكنك القيام بذلك من خلال القائمة المنسدلة.',
'game_r_22_paragraph': 'من خلال النقر على زر "رهاناتي" يمكن للاعب مراجعة آخر رهاناته. لاستعراض المزيد من سجل اللعب (على سبيل المثال، فترات أطول)، قد يحتاج اللاعب إلى الوصول إلى سجل اللاعب داخل منصة الألعاب نفسها.',
'game_r_23_paragraph': 'قد لا تبدأ الجولات في الوقت المحدد في حالة حدوث خطأ ما. إذا كان الأمر كذلك، فسيحاول فريق الدعم لدينا حل المشكلة في أقرب وقت ممكن، واستئنافها بجولات اللعبة العادية. في حالة وجود مشكلة في جولة اللعبة نفسها، سيتم إلغاء جميع الرهانات وإعادة مبلغ الرهان إلى ال',
'game_r_24_paragraph': 'يُعدّ كمون الشبكة جزءًا لا يتجزأ من الاتصال بالإنترنت. نعتبر جميع الرهانات والسحوبات النقدية صالحة فقط في لحظة وصولها إلى خادمنا، بغض النظر عن مدة كمون الشبكة. ',
'game_r_3_paragraph': 'بإمكان اللاعب استخدام مفاتيح الاختصار لوضع رهان بسرعة أو لجمع النقود في اللعبة. تعيينات المفاتيح هي كالتالي',
'game_r_4_paragraph': 'يتم تخصيص 1٪ من كل رهان لصندوق الجاكبوت التدريجي. يستخدم هذا الصندوق لنظام الجاكبوت التدريجي',
'game_r_5_paragraph': ' ‎•‏ Jackpot ‎–‎ المستوى الأعلى، الجائزة الكبرى، الأقل تكرارًا',
'game_r_6_paragraph': ' ‎•‏ Rocketpot ‎–‎ المستوى المتوسط، الجائزة المتوسطة',
'game_r_7_paragraph': ' ‎•‏ Bootsterpot ‎–‎ المستوى الأدنى، الجائزة الأدنى، الأكثر تكرارًا ',
'game_r_8_paragraph': 'يتم منح جميع مستويات jackpot باستخدام آلتيّ "Mystery" أو "must-hit-by". يتم تحديد قيمة كل "must-hit-by‏" jackpot مباشرةً بعد الفوز بـ jackpot السابقة بواسطة مولد أرقام عشوائي ويتم تخزينها كقيمة مشفرة في قاعدة البيانات البعيدة. القيمة مخفية وغير معروفة كون',
'game_r_8a_paragraph': 'ملاحظة: يمكن أن يكون الفائز بالجائزة الكبرى لاعبًا واحدًا فقط من Rocketman يلعب بشكل نشط في الجولة عند تفعيل الجائزة الكبرى. ليس هناك احتمال أنه إذا فاز اللاعب بالجائزة الكبرى، فسيتعين عليه مشاركتها مع أي لاعب Rocketman آخر.',
'game_r_9_paragraph': 'قد يتم منح لاعب أرصدة البرومو عشوائيًا. سيظهر رسم منبثق على الشاشة يخطر اللاعب عن أرصدة البرومو الممنوحة وقيمتها.',
'game_round': 'جولة اللعبة!',
'game_rules': 'قواعد اللعبة',
'game_rules_1_paragraph': 'Rocketman هي لعبة مراهنة ترفيهية من الجيل الجديد. قم بالفوز بكثير من المرات في ثوان! تم تأسيس Rocketmanis على',
'game_rules_10_paragraph': ' ‎•‎ يتم تفعيل خاصية اللعب التلقائي من تبويب "تلقائي" في لوحة الرهان عبر التحقق من خانة الاختيار "رهان تلقائي". بعد التفعيل، سيتم وضع الرهانات تلقائيًا، لكن فيما يخص جمع النقود، يجب أن تضغط زر "جمع النقود" في كل جولة. إذا أردت أن يتم جمع نقود الرهان تلقائ',
'game_rules_11_paragraph': '‎•‎ الجمع التلقائي للنقود متاح من تبويب "تلقائي" على لوحة الرهان. بعد التفعيل، سيتم جمع نقود رهانك تلقائيًا، عندما تصل إلى المعامل المسجّل.',
'game_rules_2_paragraph': 'والذي يعد حاليًا الضامن الحقيقي الوحيد للأمانة في مجال المراهنة.',
'game_rules_3_paragraph': '‎•‎ يبدأ مضاعف الفوز بـ‎1X ويكبر أكثر فأكثر بينما تقلعLucky Rocket.',
'game_rules_4_paragraph': ' ‎•‎ أرباحك هي المضاعف الذي تجمع‎ ‎من خلاله النقود المضروبة في رهانك. ',
'game_rules_5_paragraph': '‎•‎ قبل البدء في كل جولة، يقوم مولد رقم provably fair العشوائي الخاص بنا بتوليد المعامل الذي ستحلّق عنده Lucky Rocket. يمكنك التحقق من أمانة هذا التوليد عبر النقر على عكس النتيجة في تبويب السجل.',
'game_rules_6_paragraph': '‎•‎ قم باختيار مبلغ واضغط زر "رهان" للقيام برهان. ',
'game_rules_7_paragraph': '‎•‎ يمكنك القيام برهانين في آن معًا عبر إضافة لوحة رهان ثانية. لإضافة لوحة رهان ثانية، انقر على أيقونة زائد الموجودة في الزاوية العلوية اليمنى من لوحة الرهان الأول.',
'game_rules_8_paragraph': ' ‎•‎ اضغط زر "جمع النقود" لجمع أرباحك. فوزك هو رهان مضروب في معامل جمع النقود.',
'game_rules_9_paragraph': '‎•‎ يخسر رهانك إذا لم تجمع النقود قبل أن يطير الصاروخ.',
'general_rules': 'القواعد العامة',
'gifs': 'GIFS',
'go_back': 'رجوع',
'good_job_buddy': 'أحسنت، استمر بذلك',
'h': 'ساعات',
'hashed_in_sha_512': 'تم إجراء الـhash في SHA512',
'hex': 'سداسي عشري',
'hey_username': 'مرحبًا',
'hide_hex_to_decimal': 'إخفاء تحويل السداسي العشري إلى عدد عشري',
'highscore': 'درجة عالية',
'how_it_works': 'كيف يعمل؟',
'how_to_check': 'كيفية التحقق؟',
'how_to_play': 'كيفية اللعب',
'if_the_maximum_multiplier': '‎•‎ إذا تم الوصول إلى الحد الأقصى للمضاعف وهو 20.000، فسيتم جمع جميع نقود الرهانات المفتوحة تلقائيًا بأقصى مضاعف',
'increase': 'زيادة',
'input_bet_amount': 'أدخل مبلغ رهان وضع رهانك قبل الإقلاع.',
'insufficient_funds': 'رصيد غير كافٍ',
'it_blew_up': 'لقد انفجر! حسنًا...',
'jackpot': 'Jackpot',
'jackpot_winners': 'الفائزون بـ Jackpot',
'key': 'المفتاح',
'load_more': 'تحميل المزيد',
'loading': 'جاري التحميل',
'loading_next_round': 'يتم تحميل الجولة التالية...',
'loss_gifs': 'خسارة',
'lost_connection': 'فقدان الاتصال بالإنترنت',
'make_a_bet': 'قم برهان',
'malfunction_voids': 'يؤدي الخلل إلى إبطال كافة عمليات الدفع والتشغيل.',
'maximum_payout': 'الحد الأقصى للدفع هو',
'menu': 'القائمة',
'min_bet_is': 'الحد الأدنى للرهان هو',
'month': 'شهر',
'more_details': 'تفاصيل أكثر',
'more_information': 'مزيد من المعلومات',
'multiplier': 'المضاعف',
'multiplier_starts': '‎•‎ يبدأ المضاعف من 1 ويزداد حتى يصل إلى 20.000',
'multipliers_larger': 'يتم تحديد المضاعفات الأكبر من x20.000 بالحد الأقصى لقيمة المضاعف',
'music_settings': 'إعدادات الموسيقى',
'my_bet_history': 'سجل رهاني',
'my_bets': 'رهاناتي',
'my_bonysing_history': 'سجل مكافآتي',
'network_latency': 'كمون الشبكة',
'network_latency_content': 'يُعدّ كمون الشبكة جزءًا أساسيًا من الاتصال بالإنترنت. نعتبر جميع الرهانات والسحوبات النقدية صالحة في لحظة وصولها إلى خادمنا، بغض النظر عن مدة كمون الشبكة.',
'network_latency_title': 'كمون الشبكة',
'no_thanks': 'لا، شكرًا!',
'not_right_now': 'ليس حاليًا',
'off': 'غير نشط',
'oh_snap': 'عجبًا!',
'ok_close': 'حسنًا، إغلاق!',
'ok_thanks': 'حسنًا، شكرًا!',
'on': 'تشغيل',
'on_loss': 'على الخسارة',
'on_win': 'على الفوز',
'online': 'أونلاين',
'per_bet': 'لكل رهان',
'pick_your_avatar_img': 'قم باختيار صورة الآفاتار الخاصة بك',
'place_bet_with_last': 'وضع رهان بآخر مبلغ تم اختياره ',
'play_now': 'العب الآن',
'player_1_seed': 'قيمة اللاعب 1',
'player_2_seed': 'قيمة اللاعب 2',
'player_3_seed': 'قيمة اللاعب 3',
'please_wait_active_bets': 'يُرجى الانتظار حتى استكمال الرهانات الحالية لتفعيل الرهانات المجانية',
'points': 'النقاط',
'powered_by': 'تم التشغيل بواسطة',
'previous_hand': 'اليد السابقة',
'prize': 'الجائزة',
'prizes': 'الجوائز',
'progressive_jackpot': 'Jackpot المتزايد',
'promo_credit': 'رصيد البرومو',
'promo_credit_condradulations': 'تهانينا! لقد فزت برصيد برومو جيد للغاية بحيث يمكنك استعماله في اللعبة!',
'promo_credits': 'أرصدة البرومو',
'promo_history': 'سجل البرومو',
'provably_fair': 'عادل على ما يبدو',
'provably_fair_1_paragraph': 'تستند "Rocketman" على تقنية مشفرة تُسمى "Provably‏ Fair". تضمن هذه التقنية 100% نزاهة في نتيجة اللعبة. بوجود هذه التقنية، من المستحيل أن يتدخل أي طرف آخر في سير اللعبة.',
'provably_fair_2_paragraph': 'لا يتم توليد نتيجة كل جولة (معامل لعبة "Fly away") في‎ ‎خوادمنا. يتم توليدها بمساعدة لاعبيّ الجولة وبشفافية كاملة. بهذه الطريقة، من المستحيل لأي شخص أن يتلاعب بنتيجة اللعبة. أيضًا، يمكن لأي شخص التحقق والتأكد من نزاهة اللعبة',
'provably_fair_3_paragraph': 'يتم توليد نتيجة الجولة من أربعة مشاركين مستقلين للجولة',
'provably_fair_4_paragraph': 'عندما تبدأ الجولة، تدمج اللعبة قيمة الخادم مع ثلاث قيم للزبائن. يتم توليد hash‏ SHA512 من الرموز المندمجة ومن هذا الـhash - نتيجة اللعبة.',
'provably_fair_5_paragraph': 'يمكنك التحقق من نزاهة كل جولة من سجل اللعبة، عبر النقر على الأيقونة الخضراء. في النافذة المفتوحة، سترى قيمة الخادم و3 أزواج من قيم اللاعبين والدالة hash المجمّعة ونتيجة الجولة.',
'provably_fair_6_paragraph': 'تكون نسخة خادم الجولات المقبلة التي تم إجراء Hash عليها متاحة للكل في نافذة الإعدادات (في قائمة المستخدم، تحقق من "إعدادات Provably Fair" وثم "قيمة الخادم التالي SHA256"). يمكنك أيضًا تغيير قيمة الزبون الخاص بك من هنا. إذا أردت المشاركة في توليد نتيجة الج',
'provably_fair_7_paragraph': 'يمكن التحقق من إنصاف الـhash في أي آلة حاسبة أونلاين، على سبيل المثال',
'provably_fair_system': 'نظام‎ provably fair ',
'quick_explanation': 'شرح سريع',
'randomly_generated_server_hash': 'HASH السيرفر المستحدث عشوائيًا',
'rank': 'الترتيب',
'raw_multiplier': 'المضاعف الأولي',
'raw_multiplier_to_edged': 'المضاعف الأولي إلى المضاعف المقارب، باستعمال',
'remove_additional_bet': 'حذف رهان إضافي',
'reset': 'إعادة ضبط',
'return_to_player': 'العائد المادي للاعبين',
'rocketman_is_pure_excitement': 'Rocketman هي محض تسلية! مجازفة وفوز.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'الفائزون بـ Rocketpot',
'round': 'الجولة',
'round_id': 'معرف الجولة',
'round_multiplier': 'مضاعف الجولة',
'round_results': 'نتائج الجولة',
'rounds': 'جولات',
'rtp': 'العائد المئوي للاعبين',
'rules': 'القواعد',
'rules_details': 'قواعد البطولة',
'save': 'حفظ',
'schedule': 'الجدول',
'second': 'الثاني',
'seconds_left': 'ثانية متبقية',
'server_hash': 'Hash الخادم',
'please_wait_current_bets': 'يرجى الانتظار حتى تكتمل الرهانات الحالية',
'min_autocashout_value_must_be': 'يجب أن تكون قيمة الحد الأدنى للتحويل التلقائي',
'min_bet_value_must_be': 'يجب أن تكون قيمة الحد الأدنى للرهان',
'server_seed': 'قيمة الخادم',
'session_dissconnected': 'انقطاع الجلسة',
'multisession_not_allowed': 'لا يسمح بالجلسات المتعددة',
'set_auto_bet': 'الضبط التلقائي للرهان',
'set_auto_cashout': 'الضبط التلقائي لجمع النقود',
'shourtcut_keys': 'مفاتيح الاختصار',
'show_hex_to_deimal': 'إظهار تحويل السداسي العشري إلى عدد عشري',
'sound': 'الصوت',
'sound_settigs': 'إعدادات الصوت',
'start_with_autoplay': 'ابدأ باللعب التلقائي',
'stop_autoplay': 'إيقاف اللعب التلقائي',
'stop_on_loss': 'إيقاف على الخسارة',
'stop_on_loss_tooltip': 'استعمل "إيقاف على الخسارة" لإيقاف الرهان التلقائي بعد خسارة مبلغ محدد.<br/> على سبيل المثال، إذا بدأت بـ 100$ وعينت "إيقاف على الخسارة" عند 25$',
'stop_on_profit': 'إيقاف على الربح',
'stop_on_profit_tooltip': 'استعمل "إيقاف على الربح" لإيقاف الرهان التلقائي بعد ربح مبلغ محدد.<br/> على سبيل المثال، إذا بدأت بـ 100$ وعينت "إيقاف على الربح" عند 25$',
'there_are_no_active_torunament': 'لا تُوجد أي بطولات نشطة في هذه اللحظة.',
'third': 'الثالث',
'this_game_is': 'هذه اللعبة هي',
'this_tool_recalculates': 'تقوم هذه الأداة بإعادة حساب المضاعف من Hash اللعبة. يمكنك نسخ‎/‎لصق Hash أي لعبة للتحقق من المضاعف.',
'time': 'الوقت',
'time_frame': 'الإطار الزمني',
'to': 'إلى',
'toggle_animation': 'تشغيل الصور المتحركة',
'top_odds': 'أفضل الفرص',
'top_wins': 'أفضل الانتصارات',
'total_bets': 'مجموع الرهانات',
'tournament': 'البطولة',
'tournament_information': 'معلومات البطولة',
'try_advanced_autoplay': 'جرب ميزة التشغيل التلقائي المتقدمة الجديدة!',
'uncredited_wins': 'انتصارات غير مضافة',
'user': 'المستخدم',
'user_and_points': 'المستخدم والنقاط',
'username': 'اسم المستخدم',
'username_must_be_at_least': 'يجب أن يكون اسم المستخدم على الأقل',
'verify': 'تحقق',
'vip_popup_text': 'يحق لك أن تختار إحدى صور VIP الخاصة المتاحة حصريًا لأعضاء VIP!',
'vip_popup_title': 'تهانينا لأنك أصبحت لاعب VIP',
'waiting_for_the_next_round': 'جاري انتظار الجولة التالية...',
'warnings_are_calculated': ' ‎•‎ يتم احتساب الأرباح بضرب مبلغ الرهان بالمضاعف في لحظة حدث جمع النقود',
'watch_take_off': 'قم بالمشاهدة بينما يقلع صاروخك ويزداد مضاعف أرباحك.',
'watch_the_rocket': 'شاهد الصاروخ',
'we_have_noticed': '‎`‎ لاحظنا أنك تلعب "Rocketman" للمرة الأولى. هل ترغب برؤية فيديو سريع يشرح لك كيفية اللعب؟',
'week': 'أسبوع',
'welcome_to_rocketman': 'مرحبًا بك في Rocketman – لعبة جديدة ومثيرة! مع Rocketman لديك فرصة لربح ما يصل إلى 20.000 ضعف حصتك الأصلية.',
'what_is_provably_fair': 'ما هو Provably Fair؟',
'will_be_deposited': 'ستُودع في رصيد اللاعبين مع أول فوز عادي',
'win': 'فوز',
'win_amount': 'مبلغ الفوز',
'win_gifs': 'فوز',
'winner': 'الفائز',
'wins': 'انتصارات',
'write_a_replay': 'كتابة رد...',
'year': 'سنة',
'yes': 'نعم',
'yes_start_the_round': 'نعم، ابدأ الجولة',
'you_have_cashed_out': 'لديك نقود تم جمعها، فوزك هو ',
'you_have_won': 'لقد فزت',
'your_bet_is_lost': 'فسيخسر رهانك.',
'current': 'حاضِر',
'previous': 'سابق',
'claim': 'مطالبة',
'this_feature_will_give': 'ستمنح هذه الميزة مبلغًا محددًا للمستخدمين العشوائيين في الدردشة',
'amount_per_player': 'المبلغ لكل لاعب',
'no_of_players': 'عدد اللاعبين',
'meteor_shower': 'دش نيزك',
'claim_your_free_bet': 'المطالبة بالرهان المجاني الخاص بك',
'meteor_shower_total': 'مجموع دش النيزك',
'shower': 'مطر',
'total': 'المجموع',
'meteor_shower_incoming': 'دش النيزك واردة، انظر إلى الدردشة!',
'insurance': 'تأمين',
'insurance_paragraph': 'التأمين يمكن للاعبين شراء التأمين لرهانهم عن طريق النقر على خانة "تأمين الرهان" بجوار زر الرهان. يبلغ تكلفة التأمين 10% من إجمالي مبلغ الرهان وسيعيد المبلغ المراهن عليه إلى اللاعب في حالة انفجار الصاروخ بمعدل',
'insure_your_bet': 'قم بتأمين رهانك',
'bet_insurance_promo_text': 'حدد خيار "تأمين الرهان" لتأمين رهانك من تحطم الصاروخ عند مضاعف 1.00.',
'bet_insurance_promo_text_helper': 'اقرأ المزيد في قسم "قواعد اللعبة"...',
'game_explanation_1': 'اختر المضاعف الخاص بك، وإذا تجاوز مضاعف اللعبة المضاعف الذي اخترته، فسيكون فوزك هو',
'game_explanation_2': 'المضاعف × رهانك',
'retail_max_mp_1': 'الفوز بما يصل الى',
'retail_max_mp_2': 'رهان!',
'play_multibets': 'يلعب',
'multibets': 'الرهانات المتعددة',
'bonus_game': 'يتم مضاعفة جميع المكاسب!',
'all_wins_doubled': 'لزيادة احتمالاتك!',
'multibets_paragraph_1': 'يمكنك الجمع بين الرهانات على ما يصل إلى عشر جولات في رهان متعدد واحد والفوز بمبالغ ضخمة!',
'multibets_paragraph_2': 'في كل مرة يتم فيها الفوز بجولة، يتم المراهنة على الأرباح الفائزة من تلك الجولة في الجولة التالية، حتى تصبح جميع الجولات فائزة.',
'multibets_paragraph_3': 'كل مرة',
'bonus_launch': 'اربح ×2 على جميع الرهانات في جولة "Bonus Launch"!',
'multiplier_history': 'التاريخ المضاعف',
'will_start_soon': 'سوف تبدأ قريبا',
'hurry_up_and_place_your_bets': 'عجلوا ووضع الرهانات الخاصة بك!',
'active_bets': 'الرهانات النشطة',
'shop': 'محل',
'game_meteor_shower_paragraph': 'يمنح Meteor Shower رهانًا مجانيًا واحدًا بقيمة محددة مسبقًا لعدد محدد مسبقًا من اللاعبين. يمكن أن يبدأ إما عن طريق الخادم أو عن طريق اللاعب. بمجرد تنشيط Meteor Shower، تظهر رسالة داخل اللعبة لتوجيه المستخدمين للنظر إلى الدردشة التي توجد بها رسالة Meteor Shower. يمكن للاعبين المطالبة بالرهان المجاني من خلال النقر على زر "المطالبة". يتم منح الرهانات المجانية للاعبين الذين ينقرون على زر "المطالبة" أولاً. يحظر استخدام أدوات النقر التلقائي أو أي نوع من برامج التشغيل الآلي لمحاكاة نقرات اللاعب والمطالبة بالجوائز وقد يؤدي إلى حظر هذه الميزة.',
'claming_failed': 'فشلت المطالبة',
'player_on_blacklist': 'اللاعب موجود في القائمة السوداء',
'you_can_claim_ticket': 'لا يمكنك المطالبة بالتذكرة إلا إذا كنت لاعبًا نشطًا ولديك رهانات منتظمة',
'you_already_have_free_tickets': 'لديك بالفعل تذاكر مجانية، يرجى إنفاقها أولاً',
'please_play_the_game_in_portait_mode': 'يرجى لعب اللعبة في وضع عمودي.',
};
langArr.bg = {
'your_insurance_returns_you': 'Вашата застраховка ви връща',
'bet_insurance': 'Застраховка за залагане',
'max_bet_is': 'Максималният залог е',
'already_active_bet_of': 'и вече има активен залог на',
'1_from_game_history': '‎1. От история на играта',
'2_in_settings_window': '‎2. В прозореца с настройки',
'3_online_calculator': '‎3. Онлайн калкулатор',
'active_tournament_1': 'Aктивeн турнир ',
'active_tournament_2': 'все още няма залози.',
'add_another_bet': 'Добави друг залог',
'advanced_autoplay': 'РАЗШИРЕНА АВТОМАТИЧНА ИГРА',
'advanced_autoplay_for_bet': 'Разширена автоматична игра за залог',
'alien_saucer_free_bets': 'Безплатни Залози Извънземна Чиния',
'all_bets': 'Всички Залози',
'all_in_your_hands': 'Всичко е в твои ръце!',
'allow': 'Разреши',
'at': 'в',
'austronaut_promo_credit': 'Промо Кредит Астронавт',
'auto_play_and_autocash': 'АВТОМАТИЧНА ИГРА И АВТОМАТИЧЕН КЕШАУТ ',
'autoplay': 'Автоматична игра',
'autoplay_and_cashout': '‎02. Автоматична Игра и Автоматичен Кешаут',
'back_to_home': 'Обратно към Началната страница',
'balance': 'Баланс',
'bet': 'ЗАЛОГ',
'bet_amount': 'СУМА НА ЗАЛОГА',
'bet_and_cashout': '‎01. Залог и Кешаут',
'bet_bets_tab': 'СУМА НА ЗАЛОГА',
'bet_failed': 'Неуспешен залог',
'bet_statistic': 'Статистика на Залога',
'bet_time': 'Време на Залога',
'bet_wins': 'Печеливши Залози',
'bets': 'Залози',
'biggest_odds': 'Най-големи Коефициенти',
'bonus_type': 'ВИД БОНУС',
'bonusing_history': 'Хронология на бонусите',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Победители в Boosterpot',
'but_remember_paragraph': 'Но не забравяй, че ако не направиш кешаут преди избухването на ракетата',
'calculate_raw_mp_to_decimal': 'Изчисляване на множител за ред от десетичен и закръгляне до най-близкото цяло число',
'cancel': 'Отмени',
'cant_post_media_content': 'Не мога да публикувам често медийно съдържание',
'cashed_out': 'Кешаут',
'cashout': 'Кешаут',
'cashout_at': 'ПЛАЩАНЕ НА',
'cashout_before': 'Направи кешаут преди избухването на ракетата и парите са твои!',
'change_avatar': 'Промяна на Аватара',
'change_username': 'Промяна на потребителско име',
'characters_long': 'знака',
'chat': 'Чат',
'chat_room': 'Чат Стая',
'checking_for_spam': 'Проверка за спам…',
'close': 'Затвори',
'coeff': 'КОЕФ.',
'collect_win': 'Събери печалбата',
'combined_seed': 'Комбиниран низ',
'company': 'Компания',
'congratulations_you_have_won_free_bets': 'Поздравления! Спечели Бонус Залози! Можеш да заложиш',
'convert_first_13_to_decimal': ' Превръщане на първите 13 байта в десетични',
'd': 'д',
'date': 'ДАТА',
'date_and_time': 'Дата и час',
'deny': 'Откажи',
'detailed_game_rules': 'Подробни Правила на Играта',
'dissconnection_policy': 'ПОЛИТИКА ЗА ПРЕКЪСВАНЕ НА ВРЪЗКА',
'easy_as_one_two_three': 'Лесно като едно, две, три',
'enter_username': 'Моля, въведете желано потребителско име',
'error_handling': 'ОБРАБОТВАНЕ НА ГРЕШКИ',
'exit_full_screen': 'Изход от режим на цял екран',
'first': 'първа',
'first_time_playing': 'За първи път играете Rocketman?',
'folow_the_three_easy_steps': 'Следвай тези три лесни стъпки и се наслади на Rocketman! ',
'free_bets': 'БЕЗПЛАТНИ ЗАЛОЗИ',
'free_bets_desc': 'Спечелихте безплатни залози! Можете да се възползвате от безплатните залози по всяко време, като натиснете бутона за стартиране.',
'free_bets_header': 'Безплатни залози',
'from': 'ОТ',
'fullscreen_mode': 'За по-добро потребителско изживяване играта ще стартира в режим на цял екран.',
'function': 'Функция',
'game_functions': 'Функции на Играта',
'game_history': 'ИСТОРИЯ НА ИГРАТА',
'game_r_1_paragraph': 'Играчът може да активира опцията автоматична игра, при която автоматично се поставя залог във всеки нов кръг, използвайки сумата на предишния залог.',
'game_r_10_paragraph': 'След като се присъдят промоционалните кредити, за всеки залог, който играчът направи, парите първо се приспадат от промоционалните кредити, докато бъдат изразходвани, след което се продължава с тегленето на пари от портфейла на играча.',
'game_r_11_paragraph': 'Промоционалните кредити не се изплащат, но залозите, направени с тях, се начисляват на играча в пълен размер.',
'game_r_12_paragraph': 'Промоционалните кредити имат период на валидност 30 дни, след което изтичат и не могат да се използват.',
'game_r_13_paragraph': 'Играчът може да получи безплатни залози на случаен принцип. На екрана се появява изскачаща графика, която уведомява играча за присъдените безплатни залози, броя на залозите и сумата на залог.',
'game_r_14_paragraph': 'След като се присъдят безплатните залози, играчът може избере кога да започне кръга с безплатни залози. След като започне кръга с безплатни залози, се поставя автоматичен залог с посочената стойност и от играча зависи в кой момент ще използва кешаут. Ако ',
'game_r_15_paragraph': 'Безплатните залози имат период на валидност 30 дни, след което изтичат и не могат да се използват.',
'game_r_16a_paragraph': 'Общата теоретична възвръщаемост за играча е ',
'game_r_17_paragraph': 'Процентите на възвръщаемостта се наблюдават и проверяват непрекъснато от независими трети страни.',
'game_r_18_paragraph': 'Ако след поставяне на залог връзката бъде прекъсната, залогът не се отменя. Ако множителят стигне до 20 000х, залогът ти се изплаща автоматично и се начисляват кредити към твоята сметка.',
'game_r_19_paragraph': 'Можеш да говориш в играта с други играчи. Въведи съобщението си в полето за чат или сподели печелившия си залог с други играчи.',
'game_r_2_paragraph': 'Можеш да въведеш множител за автоматичен кешаут в полето „Автоматичен кешаут“. След въвеждането му играта плаща автоматично при достигането на зададения множител.',
'game_r_20_paragraph': 'За играчите, които злоупотребяват с чата, обиждайки други играчи или използвайки неподходящ и/или нецензуриран език, ще бъдат деактивирани привилегиите за чат.',
'game_r_21_paragraph': 'Играта Rocketman има приятна фонова музика и звукови ефекти. Ако искаш да деактивираш музиката и/или звуковите ефекти, можеш да го направиш чрез падащото меню.',
'game_r_22_paragraph': 'Чрез натискането на бутона „Моите залози“ играчът може да прегледа последните си залози. За повече история на играта (например по-дълги периоди) играчът трябва да влезе в историята си в самата игрална платформа.',
'game_r_23_paragraph': 'При възникване на грешка кръговете може да не започнат навреме. В такъв случай нашият екип за поддръжка ще се опита да разреши проблема възможно най-бързо и да възобнови редовните кръгове на играта. В случай на проблем със самия кръг на играта всички зало',
'game_r_24_paragraph': 'Мрежовото закъснение е неизменна част от интернет връзката. Всички залози и плащания се считат за валидни само в момента, в който стигнат до сървъра ни, независимо от продължителността на мрежовото закъснение. ',
'game_r_3_paragraph': 'Играчът може да използва клавишни комбинации, за да постави бърз залог или да направи кешаут в играта. Клавишите са следните',
'game_r_4_paragraph': '1% от всеки залог се отделя за фонд за прогресивен джакпот. Този фонд служи за системата на прогресивния джакпот',
'game_r_5_paragraph': ' ‎• Jackpot – Най-високо ниво, най-големи награди, най-рядък',
'game_r_6_paragraph': ' ‎• Rocketpot – Средно ниво, средна награда',
'game_r_7_paragraph': ' ‎• Boosterpot – Най-ниско ниво, ниска награда, най-чест ',
'game_r_8_paragraph': 'Всички нива на джакпота се присъждат, използвайки механика „Мистерия“ или „\'ще падне при“. Стойността на всеки джакпот \'„ще падне при“ се определя веднага след като предишният джакпот е спечелен от генератор на случайни числа и е съхранен като шифрована',
'game_r_8a_paragraph': 'Забележка: Победителят в джакпота може да бъде само един играч на Rocketman, който играе активно в рунда, когато джакпотът е бил задействан. Няма възможност, ако играчът е спечелил джакпота, да трябва да го сподели с друг играч на Rocketman.',
'game_r_9_paragraph': 'Играчът може да получи промоционални кредити на случаен принцип. На екрана се появява изскачаща графика, която уведомява играча за присъдените промоционални кредити и тяхната стойност.',
'game_round': 'кръг на игра!',
'game_rules': 'Правила на Играта',
'game_rules_1_paragraph': 'Rocketman е хазартно забавление от ново поколение. Спечели много пъти повече за секунди! Rocketman е изграден върху',
'game_rules_10_paragraph': ' ‎• Автоматична Игра се активира от раздела \'\'Авто\'\' в Панела на Залога с поставянето на отметка в квадратчето \'\'Автоматичен Залог\'\'. След активирането, залозите ще се правят автоматично, но за кешаут, трябва да натискаш бутона \'\'кешаут\'\' във ',
'game_rules_11_paragraph': '‎• Автоматичен Кешаут се намира в раздела \'\'Авто\'\' в панела на залога. След активирането му, твоят залог ще се затваря автоматично, когато достигне зададения коефициент.',
'game_rules_2_paragraph': '‎, която в момента е единствената реална гаранция за честност в хазартната индустрия.',
'game_rules_3_paragraph': '‎• Множителят на печалбата започва от 1х и расте повече и повече с издигането на Късметлийската Ракета.',
'game_rules_4_paragraph': ' ‎• Твоите печалби са множителят, при който си направил Кешаут, умножен по твоя залог. ',
'game_rules_5_paragraph': '‎• Преди началото на всеки кръг нашият provably fair генератор на случайни числа генерира коефициентът, при който Късметлийската Ракета ще отлети. Можеш да провериш честността на това генериране, като натиснеш срещу резултата в раздела История.',
'game_rules_6_paragraph': '‎• Избери сума и натисни бутона \'\'Залог\'\', за да направиш залог. ',
'game_rules_7_paragraph': '‎• Можеш да направиш два залога едновременно, като добавиш втори панел за залог. За да добавиш втори панел за залог, натисни върху иконата с плюс, намираща се в горния десен ъгъл на първия панел за залог.',
'game_rules_8_paragraph': ' ‎• За да затвориш залога, натисни бутона \'\'Кешаут\'\'. Твоята печалба е залогът, умножен по коефициента за кешаут.',
'game_rules_9_paragraph': '‎• Твоят залог губи, ако не си направил кешаут преди избухването на ракетата.',
'general_rules': 'ОБЩИ ПРАВИЛА',
'gifs': 'GIF файлове',
'go_back': 'Назад',
'good_job_buddy': 'Браво, приятелю, продължавай все така',
'h': 'ч',
'hashed_in_sha_512': 'Хеширан в SHA512',
'hex': 'Шестнадесет.',
'hey_username': 'Хей',
'hide_hex_to_decimal': 'Скриване на преобразуването на шестнадесетична в десетична система',
'highscore': 'Висока оценка',
'how_it_works': 'Как работи?',
'how_to_check': 'Как да проверя?',
'how_to_play': 'Как се играе',
'if_the_maximum_multiplier': '‎• Ако е достигнат максималният множител от 20 000, всички отворени залози се изплащат с максималния множител',
'increase': 'Увеличаване',
'input_bet_amount': 'Въведи сума на залога и направи залога си преди излитането.',
'insufficient_funds': 'Недостатъчно средства',
'it_blew_up': 'Взриви се! Ами добре…',
'jackpot': 'Джакпот',
'jackpot_winners': 'Победители в Jackpot',
'key': 'Клавиш',
'load_more': 'ЗАРЕЖДАНЕ НА ПОВЕЧЕ',
'loading': 'Зареждане',
'loading_next_round': 'Зареждане на следващ кръг…',
'loss_gifs': 'ЗАГУБА',
'lost_connection': 'Прекъсната връзка',
'make_a_bet': 'Направи Залог',
'malfunction_voids': 'Неизправност анулира всички плащания и игри.',
'maximum_payout': 'Максималното изплащане е ',
'menu': 'Меню',
'min_bet_is': 'Мин. Залог е',
'month': 'Месец',
'more_details': 'Повече Информация',
'more_information': 'Още информация',
'multiplier': 'Множител',
'multiplier_starts': '‎• Множителят започва от 1 и стига до 20 000',
'multipliers_larger': 'Множители, по-големи от х20 000 се ограничават до максималната стойност за множители',
'music_settings': 'Настройки на Музиката',
'my_bet_history': 'Моята История на Залозите',
'my_bets': 'Моите Залози',
'my_bonysing_history': 'Моята хронология на бонусите',
'network_latency': 'МРЕЖОВО ЗАКЪСНЕНИЕ',
'network_latency_content': 'Мрежовото закъснение е неизменна част от интернет връзката. Всички залози и плащания се считат за валидни само в момента, в който стигнат до сървъра ни, независимо от продължителността на мрежовото закъснение.',
'network_latency_title': 'МРЕЖОВО ЗАКЪСНЕНИЕ',
'no_thanks': 'Не, благодаря!',
'not_right_now': 'Не сега',
'off': 'изкл.',
'oh_snap': 'Ужас!',
'ok_close': 'ОК, да се затвори!',
'ok_thanks': 'Добре, Благодаря!',
'on': 'вкл.',
'on_loss': 'ПРИ ЗАГУБА',
'on_win': 'ПРИ ПЕЧАЛБА',
'online': 'Онлайн',
'per_bet': 'на залог',
'pick_your_avatar_img': 'Избери изображение за аватара си',
'place_bet_with_last': 'Залагане с последния избран залог ',
'play_now': 'Играй Сега',
'player_1_seed': 'Низ на Играч 1',
'player_2_seed': 'Низ на Играч 2',
'player_3_seed': 'Низ на Играч 3',
'please_wait_active_bets': 'За да активирате безплатните залози, моля, изчакайте текущите залози да завършат',
'points': 'ТОЧКИ',
'powered_by': 'Разработена от',
'previous_hand': 'Предишна Ръка',
'prize': 'НАГРАДА',
'prizes': 'награди',
'progressive_jackpot': 'ПРОГРЕСИВЕН ДЖАКПОТ',
'promo_credit': 'Промокредит',
'promo_credit_condradulations': 'Поздравления! Спечели астрономически добър Промо кредит, който можеш да използваш в играта!',
'promo_credits': 'ПРОМОЦИОНАЛНИ КРЕДИТИ',
'promo_history': 'История на промоциите',
'provably_fair': 'Доказуемо справедливо',
'provably_fair_1_paragraph': 'Играта \'\'Rocketman\'\' се базира на криптографска технология, наречена \'\'Provably Fair\'\'. Тази технология гарантира 100% справедливост на резултата от играта. С тази технология е невъзможно трета страна да се намеси в процеса на играта.',
'provably_fair_2_paragraph': 'Резултатът от всеки кръг (Коефициентът за "Отлитане" в играта) не се генерира на нашите сървъри. Той се генерира с помощта на серия от играчи и е напълно прозрачен. По този начин е невъзможно някой да манипулира изхода на играта. Също така, всеки може да ',
'provably_fair_3_paragraph': 'Резултатът от кръга се генерира от четирима независими участници в кръга',
'provably_fair_4_paragraph': 'Когато кръгът започне, играта обединява низа на сървъра с три клиентски низа. От обединените символи се генерира хеш SHA 512, а от този хеш – резултатът от играта.',
'provably_fair_5_paragraph': 'Можеш да провериш честността на всеки кръг от история на играта, като натиснеш върху зелената икона. В отворения прозорец ще видиш низа на сървъра, 3 чифта низове на играчи, комбиниран хеш и резултат от кръг.',
'provably_fair_6_paragraph': 'Хешираната версия на низа на сървъра за следващите кръгове е достъпна публично в прозореца с настройки (В потребителското меню виж \'\'Настройки на Provably Fair\'\', а след това \'\'Низ на следващия сървър SHA256\'\'). Също така, тук можеш да промениш св',
'provably_fair_7_paragraph': 'Точността на хеша може да бъде проверена с всеки онлайн калкулатор, например',
'provably_fair_system': 'система provably fair',
'quick_explanation': 'Бързо обяснение',
'randomly_generated_server_hash': 'Случайно генериран HASH на сървъра',
'rank': 'ПОЗИЦИЯ',
'raw_multiplier': 'необработен множител',
'raw_multiplier_to_edged': 'Необработен Множител към краен множител, използвайки',
'remove_additional_bet': 'Премахни допълнителен залог',
'reset': 'Нулиране',
'return_to_player': 'ВЪЗВРЪЩАЕМОСТ',
'rocketman_is_pure_excitement': 'Rocketman e истинско вълнение! Рискувай и спечели.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Победители в Rocketpot',
'round': 'Кръг',
'round_id': 'Id на Кръга',
'round_multiplier': 'Множител на кръга',
'round_results': 'резултат от кръга',
'rounds': 'рунда',
'rtp': 'RTP',
'rules': 'правила',
'rules_details': 'Правила на турнира',
'save': 'Запази',
'schedule': 'график',
'second': 'втора',
'seconds_left': 'Оставащи с',
'server_hash': 'ХЕШ на сървъра',
'please_wait_current_bets': 'МОЛЯ, ИЗЧАКАЙТЕ ЗАВЪРШВАНЕТО НА ТЕКУЩИТЕ ЗАЛОЗИ',
'min_autocashout_value_must_be': 'МИН. АВТОМАТИЧНО КЕШАУТ СТОЙНОСТ ТРЯБВА ДА БЪДЕ',
'min_bet_value_must_be': 'МИН. СТОЙНОСТ НА ЗАЛОГА ТРЯБВА ДА БЪДЕ',
'server_seed': 'Низ на Сървъра',
'session_dissconnected': 'Сесията е прекъсната',
'multisession_not_allowed': 'Мултисесията не е разрешена',
'set_auto_bet': 'НАСТРОЙ АВТОМАТИЧЕН ЗАЛОГ',
'set_auto_cashout': 'НАСТРОЙ АВТОМАТИЧЕН КЕШАУТ',
'shourtcut_keys': 'КЛАВИШНИ КОМБИНАЦИИ',
'show_hex_to_deimal': 'Показване на преобразуването на шестнадесетична в десетична система',
'sound': 'ЗВУК',
'sound_settigs': 'Настройки на Звука',
'start_with_autoplay': 'Стартиране с автоматичната игра',
'stop_autoplay': 'СПИРАНЕ НА АВТОМАТИЧНАТА ИГРА',
'stop_on_loss': 'СПИРАНЕ ПРИ ЗАГУБА',
'stop_on_loss_tooltip': 'Използвайте функцията „Спиране при загуба“, за да спрете автоматичната игра след изгубването на определена сума. <br/> Например ако започнете със 100 щ.д. и настроите „Спиране при загуба“ на 25 щ.д.',
'stop_on_profit': 'СПИРАНЕ ПРИ ПЕЧАЛБА',
'stop_on_profit_tooltip': 'Използвайте функцията „Спиране при печалба“, за да спрете автоматичната игра след спечелването на определена сума. <br/> Например ако започнете със 100 щ.д. и настроите „Спиране при печалба“ на 25 щ.д.',
'there_are_no_active_torunament': 'В момента няма активни турнири.',
'third': 'трета',
'this_game_is': 'Тази игра е',
'this_tool_recalculates': 'Този инструмент преизчислява множителя от хеша на играта. Можеш да копираш/поставиш хеша на всяка игра, за да провериш множителя.',
'time': 'ВРЕМЕ',
'time_frame': 'Времеви Обхват',
'to': 'ДО',
'toggle_animation': 'Превключване на анимацията',
'top_odds': 'Топ Коефициенти',
'top_wins': 'Топ Печалби',
'total_bets': 'Общо Залози',
'tournament': 'Турнир',
'tournament_information': 'Информация за турнира',
'try_advanced_autoplay': 'Опитайте нова разширена функция за автоматично пускане!',
'uncredited_wins': 'Неначислени печалби',
'user': 'ПОТРЕБИТЕЛ',
'user_and_points': 'ПОТРЕБИТЕЛ И ТОЧКИ',
'username': 'Потребителско име',
'username_must_be_at_least': 'Потребителското име трябва да съдържа най-малко',
'verify': 'Проверка',
'vip_popup_text': 'Може да изберете един от специалните ВИП аватари, които се предлагат само за нашите ВИП членове!',
'vip_popup_title': 'Поздравления! Вече сте ВИП играч',
'waiting_for_the_next_round': 'Изчакване на следващия кръг…',
'warnings_are_calculated': ' ‎• Печалбите се изчисляват чрез умножаване на стойността на залога по множителя в момента на кешаута',
'watch_take_off': 'Гледай как ракетата ти излита и множителят на печалбата ти се увеличава.',
'watch_the_rocket': 'Наблюдавай Ракетата',
'we_have_noticed': 'Забелязахме, че играете Rocketman за първи път. Искате ли да видите кратко видео с инструкции как се играе?',
'week': 'Седмица',
'welcome_to_rocketman': 'Добре дошли в Rocketman – нова и вълнуваща игра! С Rocketman имаш шанс да спечелиш до 20 000х първоначалния си залог.',
'what_is_provably_fair': 'Какво е Provably fair?',
'will_be_deposited': 'Депозират се в баланса на играча с първа редовна победа',
'win': 'ПЕЧАЛБА',
'win_amount': 'Спечелена Сума',
'win_gifs': 'ПЕЧАЛБА',
'winner': 'победител',
'wins': 'печалби',
'write_a_replay': 'Напиши отговор…',
'year': 'Година',
'yes': 'ДА',
'yes_start_the_round': 'Да, започни кръга',
'you_have_cashed_out': 'Кешаут, печалбата ти е ',
'you_have_won': 'СПЕЧЕЛИХТЕ',
'your_bet_is_lost': 'губиш залога.',
'current': 'Текущ',
'previous': 'Предишен',
'claim': 'Иск',
'this_feature_will_give': 'Тази функция ще даде избрана сума на произволни потребители в чата',
'amount_per_player': 'Сума на играч',
'no_of_players': 'Брой играчи',
'meteor_shower': 'Метеоритен дъжд',
'claim_your_free_bet': 'Изискайте своя безплатен залог',
'meteor_shower_total': 'Метеоритен дъжд Общо',
'shower': 'ДЪЖД',
'total': 'Обща сума',
'meteor_shower_incoming': 'Предстои метеоритен дъжд, вижте чата!',
'insurance': 'ЗАСТРАХОВКА',
'insurance_paragraph': 'Играчите могат да закупят застраховка за техния залог, като кликнат върху полето "Залагане на застраховка" до бутона за залагане. Застраховката струва 10% от общата сума на залога и ще върне залога на играча, ако ракетата експлодира с множител 1.00.',
'insure_your_bet': 'ЗАСТРАХОВЕТЕ ВАШИЯ ЗАЛОГ',
'bet_insurance_promo_text': 'Изберете опцията "Застраховка на залога", за да застраховате залога си от разбиване на ракета при множител 1,00.',
'bet_insurance_promo_text_helper': 'Прочетете повече в раздел "Правила на играта"...',
'game_explanation_1': 'Изберете вашия множител и ако множителят на играта надвиши избрания от вас множител, вашата печалба ще бъде',
'game_explanation_2': 'Вашият множител x Вашият залог',
'retail_max_mp_1': 'Спечелете до',
'retail_max_mp_2': 'вашият залог!',
'play_multibets': 'Играйте',
'multibets': 'МУЛТИБЕТ',
'bonus_game': 'БОНУС ИГРА',
'all_wins_doubled': 'Всички печалби се удвояват!',
'multibets_paragraph_1': 'за да увеличите шансовете си!',
'multibets_paragraph_2': 'Можете да комбинирате залози на до десет рунда в един мулти залог и да спечелите масивни печалби!',
'multibets_paragraph_3': 'Всеки път, когато бъде спечелен рунд, печалбата от този рунд се залага на следващия рунд, докато всички рундове са печеливши.',
'bonus_launch': 'Спечелете x2 на всички залози в рунда „Bonus Launch“!',
'multiplier_history': 'История на множителя',
'will_start_soon': 'ще започне скоро',
'hurry_up_and_place_your_bets': 'Побързайте и залагайте!',
'active_bets': 'Активни залози',
'shop': 'МАГАЗИН',
'game_meteor_shower_paragraph': 'Meteor Shower дава един безплатен залог с предварително определена стойност на предварително определен брой играчи. Може да бъде инициирано от сървъра или от играч. След като Meteor Shower се активира, в играта се появява съобщение, което насочва потребителите да погледнат чата, където се намира съобщението за Meteor Shower. Играчите могат да изискат безплатния залог, като кликнат върху бутона „Искане“. Безплатни залози се присъждат на играчи, които първо кликнат върху бутона „Искане“. Използването на автокликери или какъвто и да е вид софтуер за автоматизация за емулиране на кликвания на играчи и получаване на награди е забранено и може да доведе до забрана на тази функция.',
'claming_failed': 'Заявяването не бе успешно',
'player_on_blacklist': 'Играчът е в черния списък',
'you_can_claim_ticket': 'Можете да поискате билет само ако сте активен играч с редовни залози',
'you_already_have_free_tickets': 'Вече имате безплатни билети, моля, първо ги похарчете',
'please_play_the_game_in_portait_mode': 'Моля, играйте играта в портретен режим.',
};
langArr.de = {
'your_insurance_returns_you': 'Ihre Versicherung bringt Sie zurück',
'bet_insurance': 'Wettversicherung',
'max_bet_is': 'Maximaler Einsatz ist',
'already_active_bet_of': 'und es gibt bereits eine aktive Wette',
'1_from_game_history': '‎1. Über den Spielverlauf',
'2_in_settings_window': '‎2. Im Einstellungsfenster',
'3_online_calculator': '‎3. Online-Rechner',
'active_tournament_1': 'Aktives Turnier ',
'active_tournament_2': 'hat noch keine Wetten',
'add_another_bet': 'Weitere Wette hinzufügen',
'advanced_autoplay': 'ERWEITERTES AUTOPLAY',
'advanced_autoplay_for_bet': 'Erweitertes Autoplay für die Wette',
'alien_saucer_free_bets': 'Fliegende Untertasse-Freispiele',
'all_bets': 'Alle Wetten',
'all_in_your_hands': 'Es liegt in Ihren Händen!',
'allow': 'Erlauben',
'at': 'bei',
'austronaut_promo_credit': 'Astronauten-Promocredit',
'auto_play_and_autocash': 'AUTO-PLAY UND AUTO-CASHOUT ',
'autoplay': 'Autoplay',
'autoplay_and_cashout': '‎2. Auto-Play & Auto-Auszahlung',
'back_to_home': 'Zurück zur Homepage',
'balance': 'Guthaben',
'bet': 'WETTE',
'bet_amount': 'EINSATZBETRAG',
'bet_and_cashout': '‎1. Wetten & Auszahlen',
'bet_bets_tab': 'EINSATZ',
'bet_failed': 'Wette fehlgeschlagen',
'bet_statistic': 'Wettstatistik',
'bet_time': 'Wettzeit',
'bet_wins': 'Wettgewinne',
'bets': '‎Wetten',
'biggest_odds': 'Höchste Quoten',
'bonus_type': 'BONUSTYP',
'bonusing_history': 'Bonus-Verlauf',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot-Gewinner',
'but_remember_paragraph': 'Beachten Sie, wenn Sie keine Zeit für eine Auszahlung hatten, bevor die Rakete explodiert,',
'calculate_raw_mp_to_decimal': 'Raw-Multiplikator aus der Dezimalzahl berechnen und auf die nächste Ganzzahl abrunden',
'cancel': 'Abbrechen',
'cant_post_media_content': 'Kann nicht oft Medieninhalte posten',
'cashed_out': 'Ausgezahlt',
'cashout': 'Auszahlung',
'cashout_at': 'AUSZAHLUNG BEI',
'cashout_before': 'Zahlen Sie aus, bevor die Rakete explodiert und das Geld gehört Ihnen!',
'change_avatar': 'Avatar ändern',
'change_username': 'Benutzername ändern',
'characters_long': 'Zeichen lang sein',
'chat': 'Chat',
'chat_room': 'Chat-Raum',
'checking_for_spam': 'Auf Spam überprüfen...',
'close': 'Schließen',
'coeff': 'FAKTOR',
'collect_win': 'Gewinn einsammeln',
'combined_seed': 'Kombinierter Seed',
'company': 'Firma',
'congratulations_you_have_won_free_bets': 'Herzlichen Glückwunsch! Sie haben Bonuswetten gewonnen! Sie können folgendes wetten',
'convert_first_13_to_decimal': ' Die ersten 13 Bytes in Dezimal umwandeln',
'd': 'T',
'date': 'DATUM',
'date_and_time': 'Datum und Zeit',
'deny': 'Ablehnen',
'detailed_game_rules': 'Detaillierte Spielregeln',
'dissconnection_policy': 'RICHTLINIE ZUR VERBINDUNGSUNTERBRECHUNG',
'easy_as_one_two_three': 'Kinderleicht',
'enter_username': 'Bitte geben Sie Ihren gewünschten Benutzernamen ein',
'error_handling': 'FEHLERBEHEBUNG',
'exit_full_screen': 'Vollbild-Modus beenden',
'first': 'erster',
'first_time_playing': 'Spielen Sie zum ersten Mal „Rocketman“?',
'folow_the_three_easy_steps': 'Folgen Sie diesen drei einfachen Schritten und genießen Sie Rocketman! ',
'free_bets': 'FREISPIELE',
'free_bets_desc': 'Sie haben Freispiele gewonnen! Sie können die Freispiele jederzeit nutzen, indem Sie den Start-Knopf drücken.',
'free_bets_header': 'Freispiele',
'from': 'VON',
'fullscreen_mode': 'Das Spiel wird für eine bessere Nutzererfahrung im Vollbildmodus gestartet.',
'function': 'Funktion',
'game_functions': 'Spielfunktionen',
'game_history': 'SPIELVERLAUF',
'game_r_1_paragraph': 'Ein Spieler kann die Autoplay-Option aktivieren, damit eine Wette automatisch für jede neue Runde mit dem Einsatzbetrag der letzten Runde platziert wird.',
'game_r_10_paragraph': 'Sobald Aktionscredits vergeben wurden, wird jeder Einsatz, den ein Spieler platziert, erst von den Aktionscredits abgezogen, bis keine Credits mehr zur Verfügung stehen, bevor die Gelder wieder von der Wallet des Spielers abgezogen werden.',
'game_r_11_paragraph': 'Die Aktionscredits können nicht ausgezahlt werden, aber alle Gewinne aus solchen Wetten werden dem Spieler komplett gutgeschrieben.',
'game_r_12_paragraph': 'Aktionscredits sind für einen Zeitraum von 30 Tagen gültig, danach laufen sie ab und können nicht mehr genutzt werden.',
'game_r_13_paragraph': 'Es können zufällig Freispiele an einen Spieler vergeben werden. Eine Popup-Grafik erscheint auf dem Bildschirm und benachrichtigt den Spieler über vergebene Freispiele sowie die Anzahl und den Betrag pro Wette.',
'game_r_14_paragraph': 'Sobald die Freispiele vergeben wurden, kann der Spieler wählen, wann die Freispielrunde beginnen soll. Sobald die Freispielrunde gestartet wurde, wird eine automatische Wette mit dem festgelegten Einsatz platziert und es steht dem Spieler frei, zu jeder Z',
'game_r_15_paragraph': 'Freispiele sind für einen Zeitraum von 30 Tagen gültig, danach laufen sie ab und können nicht mehr genutzt werden.',
'game_r_16a_paragraph': 'Die theoretische Gesamtrendite für den Spieler beträgt ',
'game_r_17_paragraph': 'RTP-Prozentsätze werden durchgehend von unabhängigen Dritten überwacht und verifiziert.',
'game_r_18_paragraph': 'Wenn die Verbindung unterbrochen wird, nachdem eine Wette platziert wurde, wird die Wette nicht storniert. Wenn der Multiplikator das 20.000-fache erreicht, wird Ihr Einsatz automatisch ausgezahlt und die Credits werden Ihrem Konto gutgeschrieben.',
'game_r_19_paragraph': 'Sie können innerhalb des Spiels mit anderen Spielern chatten. Geben Sie Ihre Nachricht in das Chatfeld ein oder teilen Sie Ihre gewinnende Wette mit anderen Spielern.',
'game_r_2_paragraph': 'Ein Auto-Cashout-Multiplikator kann in das Feld „Auto-Cashout“ eingegeben werden. Sobald er eingegeben wurde, zahlt das Spiel automatisch aus, wenn der festgelegte Multiplikator erreicht ist.',
'game_r_20_paragraph': 'Spieler, die die Chatfunktion missbrauchen, indem Sie andere Spieler beleidigen oder eine unangebrachte/anstößige Sprache verwenden, werden in Zukunft vom Chat ausgeschlossen.',
'game_r_21_paragraph': 'Rocketman wurde mit wunderschöner Hintergrundmusik und Soundeffekten entworfen. Sollten Sie die Musik und/oder Soundeffekte deaktivieren wollen, können Sie dies über das Drop-Down-Menü tun.',
'game_r_22_paragraph': 'Wenn Sie auf „Meine Wetten“ klicken, können Sie Ihre letzten Wetten ansehen. Für länger zurückliegende Spiele (längerer Zeitraum) muss der Spieler den Spielverlauf innerhalb der Gaming-Plattform selbst aufrufen.',
'game_r_23_paragraph': 'Im Falle eines Fehlers starten Runden eventuell nicht pünktlich. In diesem Fall wird unser Kundendienstteam versuchen, das Problem so schnell wie möglich zu lösen und die reguläre Spielrunde fortzuführen. Im Falle eines Problems mit der Spielrunde selbst,',
'game_r_24_paragraph': 'Netzwerk-Verzögerungen sind ein unumgänglicher Teil von Internetverbindungen. Wir sehen alle Wetten und Auszahlungen nur zu dem Zeitpunkt, an dem sie unseren Server erreichen, als gültig an, unabhängig von der Dauer der Netzwerk-Verzögerung. ',
'game_r_3_paragraph': 'Ein Spieler kann Tastenkombinationen nutzen, um im Spiel schnell einen Einsatz zu platzieren oder eine Auszahlung zu tätigen. Die Tastenkombinationen lauten wie folgt',
'game_r_4_paragraph': '1 % jedes Einsatzes wird einem progressiven Jackpot-Fonds gewidmet. Dieser Fonds dient dem progressiven Jackpot-System',
'game_r_5_paragraph': ' ‎• Jackpot – Höchste Stufe, größte Preise, am seltensten',
'game_r_6_paragraph': ' ‎• Rocketpot – Mittlere Stufe, mittlere Preise',
'game_r_7_paragraph': ' ‎• Boosterpot – Niedrigste Stufe, niedrige Preise, am häufigsten ',
'game_r_8_paragraph': 'Alle Jackpot-Stufen werden nach einer „Mystery“- oder „Must-Hit-By“-Mechanik vergeben. Der Wert jedes „Must-Hit-By“-Jackpots wird unmittelbar nach dem Gewinn des vorherigen Jackpots von einem Zufallszahlengenerator ermittelt und als verschlüsselter Wert i',
'game_r_8a_paragraph': 'Hinweis: Jackpot-Gewinner kann nur ein einziger Rocketman-Spieler sein, der in der Runde aktiv ist, wenn der Jackpot ausgelöst wurde. Es besteht keine Möglichkeit, dass der Spieler, wenn er den Jackpot gewonnen hat, ihn mit einem anderen Rocketman-Spieler teilen muss.',
'game_r_9_paragraph': 'Es können zufällig Aktionscredits an einen Spieler vergeben werden. Eine Popup-Grafik erscheint auf dem Bildschirm und benachrichtigt den Spieler über vergebene Aktionscredits und deren Betrag.',
'game_round': 'Spielrunde!',
'game_rules': 'Spielregeln',
'game_rules_1_paragraph': 'Rocketman ist Glücksspielunterhaltung einer neuen Generation. Gewinnen Sie in Sekunden viele Male mehr! Rocketman basiert auf einem',
'game_rules_10_paragraph': ' ‎• Auto-Play wird im „Auto“-Tab im Wettfeld aktiviert, indem Sie das „Auto-Wette“-Kästchen aktivieren. Nach der Aktivierung werden Wetten automatisch platziert, aber für Auszahlungen sollten Sie jede Runde die Schaltfläche „Auszahlung“ betätigen. Wenn Si',
'game_rules_11_paragraph': '‎• Auto-Auszahlung ist im „Auto“-Tab im Wettfeld verfügbar. Nach der Aktivierung wird Ihr Einsatz automatisch ausgezahlt, wenn der eingegebene Faktor erreicht wird.',
'game_rules_2_paragraph': '‎, das momentan die einzige wahre Garantie auf Ehrlichkeit in der Glücksspielindustrie ist.',
'game_rules_3_paragraph': '‎• Der Gewinnmultiplikator beginnt bei 1x und wächst immer weiter, nachdem die Glücksrakete gestartet ist.',
'game_rules_4_paragraph': ' ‎• Ihre Gewinne entsprechen Ihrem Einsatz multipliziert mit dem Multiplikator, der zu dem Zeitpunkt Ihrer Auszahlung gültig war. ',
'game_rules_5_paragraph': '‎• Vor dem Start jeder Runde generiert unser Provably Fair-Zufallsgenerator den Faktor bei dem die Glücksrakete abheben wird. Sie können die Fairness diese Generierung prüfen, indem Sie auf das Ergebnis im Verlauf-Tab klicken.',
'game_rules_6_paragraph': '‎• Wählen Sie einen Betrag und klicken Sie auf „Wetten“, um eine Wette zu platzieren. ',
'game_rules_7_paragraph': '‎• Sie können zwei Wetten gleichzeitig abgeben, indem Sie ein zweites Wettfeld hinzufügen. Um ein zweites Wettfeld hinzuzufügen, klicken Sie auf das Plus-Symbol, das sich in der oberen rechten Ecke des ersten Wettfeldes befindet.',
'game_rules_8_paragraph': ' ‎• Klicken Sie auf „Auszahlung“, um Ihren Gewinn auszahlen zu lassen. Ihr Gewinn entspricht Ihrem Einsatz multipliziert mit Ihrem Auszahlungsfaktor.',
'game_rules_9_paragraph': '‎• Ihre Wette verliert, wenn Sie sich nicht auszahlen lassen, bevor die Rakete davonfliegt.',
'general_rules': 'ALLGEMEINE REGELN',
'gifs': 'GIFS',
'go_back': 'Zurück',
'good_job_buddy': 'Gut gemacht Kumpel! Weiter so',
'h': 'S',
'hashed_in_sha_512': 'Hash in SHA512',
'hex': 'Hex',
'hey_username': 'Hi',
'hide_hex_to_decimal': 'Hexadezimal- zu Dezimal-Umwandlung ausblenden',
'highscore': 'High Score',
'how_it_works': 'So funktioniert’s',
'how_to_check': 'So können Sie nachprüfen',
'how_to_play': 'So wird gespielt',
'if_the_maximum_multiplier': '‎• Wenn der maximale Multiplikator von 20.000 erreicht ist, werden alle offenen Wetten automatisch mit dem maximalen Multiplikator ausgezahlt',
'increase': 'Erhöhen',
'input_bet_amount': 'Geben Sie Ihren Einsatzbetrag ein und platzieren Sie ihn vor dem Abflug.',
'insufficient_funds': 'Unzureichendes Guthaben',
'it_blew_up': 'Sie ist explodiert! Naja...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpot-Gewinner',
'key': 'Taste',
'load_more': 'MEHR LADEN',
'loading': 'Lädt',
'loading_next_round': 'Nächste Runde wird geladen...',
'loss_gifs': '‎VERLUST',
'lost_connection': 'Verbindung unterbrochen',
'make_a_bet': 'Eine Wette platzieren',
'malfunction_voids': 'Fehlfunktionen machen alle Spiele und Gewinne ungültig.',
'maximum_payout': 'Die maximale Auszahlung beträgt ',
'menu': 'Menü',
'min_bet_is': 'Der mindesteinsatz ist',
'month': 'Monat',
'more_details': 'Weitere Informationen',
'more_information': 'Weitere Informationen',
'multiplier': 'Multiplikator',
'multiplier_starts': '‎• Der Multiplikator startet bei 1 und geht bis zu 20.000',
'multipliers_larger': 'Multiplikatoren, die größer sind als x20.000 werden auf den maximalen Multiplikatorwert beschränkt',
'music_settings': 'Musik-Einstellungen',
'my_bet_history': 'Mein Wettverlauf',
'my_bets': 'Meine Wetten',
'my_bonysing_history': 'Mein Bonus-Verlauf',
'network_latency': 'NETZWERK-VERZÖGERUNG',
'network_latency_content': 'Netzwerk-Wartezeiten sind ein unumgänglicher Teil von Internetverbindungen. Wir sehen alle Wetten und Auszahlungen nur zu dem Zeitpunkt, an dem sie unseren Server erreichen, als gültig an, unabhängig von der Dauer der Netzwerk-Wartezeit.',
'network_latency_title': 'NETZWERK-WARTEZEIT',
'no_thanks': 'Nein, danke!',
'not_right_now': 'Jetzt nicht',
'off': 'aus',
'oh_snap': 'Hoppla!',
'ok_close': 'Okay, schließen!',
'ok_thanks': 'Okay, danke!',
'on': 'an',
'on_loss': 'AUF VERLUST',
'on_win': 'AUF GEWINN',
'online': 'Online',
'per_bet': 'pro Wette',
'pick_your_avatar_img': 'Wählen Sie Ihr Avatarbild',
'place_bet_with_last': 'Platzieren Sie eine Wette mit dem vorherigen Einsatz ',
'play_now': 'Jetzt spielen',
'player_1_seed': 'Seed Spieler 1',
'player_2_seed': 'Seed Spieler 2',
'player_3_seed': 'Seed Spieler 3',
'please_wait_active_bets': 'Bitte warten Sie, bis aktuelle Wetten abgeschlossen sind, um die Freispiele zu aktivieren',
'points': 'PUNKTE',
'powered_by': 'Betrieben von',
'previous_hand': 'Vorherige Hand',
'prize': 'PREIS',
'prizes': 'Preise',
'progressive_jackpot': 'PROGRESSIVER JACKPOT',
'promo_credit': 'Promo-Guthaben',
'promo_credit_condradulations': 'Herzlichen Glückwunsch! Sie haben einen astronomisch guten Promocredit gewonnen, den Sie im Spiel nutzen können!',
'promo_credits': 'AKTIONSCREDITS',
'promo_history': 'Aktionsverlauf',
'provably_fair': 'Nachweislich fair',
'provably_fair_1_paragraph': '‎„Rocketman“ basiert auf kryptografischer Technologie, die „Provably fair“ (nachweisbar fair) genannt wird. Diese Technologie garantiert die 100 %ige Fairness des Spielergebnisses. Mit dieser Technologie ist es Dritten unmöglich, Einfluss auf den Spielabl',
'provably_fair_2_paragraph': 'Das Ergebnis jeder Runde (der „Fly Away“-Faktor) wird nicht auf unseren Servern generiert. Er wird mithilfe der Spieler der Runde generiert und ist vollkommen nachvollziehbar. Auf diese Weise ist es niemandem möglich, das Spielergebnis zu beeinflussen.',
'provably_fair_3_paragraph': 'Das Rundenergebnis wird von vier unabhängigen Teilnehmern der Runde generiert.',
'provably_fair_4_paragraph': 'Wenn die Runde beginnt, fügt das Spiel den Server-Seed mit drei Client-Seeds zusammen. Aus den zusammengefügten Symbolen wird ein SHA512-Hash generiert und aus diesem Hash ergibt sich das Spielergebnis.',
'provably_fair_5_paragraph': 'Sie können die Fairness jeder Runde im Spielverlauf nachprüfen, indem Sie auf das grüne Symbol klicken. In dem sich öffnenden Fenster sehen Sie den Server-Seed, 3 Spieler-Seeds, den kombinierten Hash und das Rundenergebnis.',
'provably_fair_6_paragraph': 'Die gehashte Version des Server-Seeds der nächsten Runde ist öffentlich im Einstellungsfenster verfügbar (Im Benutzermenü „Provably Fair Einstellungen“ und dann auf „Nächster Server-Seed SHA256“). Hier können Sie auch Ihren Client-Seed ändern. Wenn Sie an',
'provably_fair_7_paragraph': 'Die Korrektheit des Hash kann durch jeden Online-Rechner geprüft werden, zum Beispiel',
'provably_fair_system': '‎„Provably Fair“-System',
'quick_explanation': 'Schnelle Erklärung',
'randomly_generated_server_hash': 'Zufällig generierter Server-HASH',
'rank': 'RANG',
'raw_multiplier': 'Raw-Multiplikator',
'raw_multiplier_to_edged': 'Raw-Multiplikator zu Edge-Multiplikator, mit',
'remove_additional_bet': 'Weitere Wette entfernen',
'reset': 'Zurücksetzen',
'return_to_player': 'KEHRE ZUM SPIELER ZURÜCK',
'rocketman_is_pure_excitement': 'Rocketman ist pure Aufregung! Riskieren und gewinnen Sie!',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot-Gewinner',
'round': 'Runde',
'round_id': 'Runden-ID',
'round_multiplier': 'Rundenmultiplikator',
'round_results': 'Rundenergebnis',
'rounds': 'Runden',
'rtp': 'RTP',
'rules': 'Regeln',
'rules_details': 'Turnierregeln',
'save': 'Speichern',
'schedule': 'Zeitplan',
'second': 'zweiter',
'seconds_left': 'Sek. verbleiben',
'server_hash': 'Server-HASH',
'please_wait_current_bets': 'Bitte warten Sie, bis die aktuellen Wetten abgeschlossen sind',
'min_autocashout_value_must_be': 'Der Mindestwert für die automatische Auszahlung muss sein',
'min_bet_value_must_be': 'Der Mindesteinsatzwert muss betragen',
'server_seed': 'Server-Seed',
'session_dissconnected': 'Sitzung unterbrochen',
'multisession_not_allowed': 'Multisession ist nicht erlaubt',
'set_auto_bet': 'AUTO-WETTE FESTLEGEN',
'set_auto_cashout': 'AUTO-AUSZAHLUNG FESTLEGEN',
'shourtcut_keys': 'SHORTCUTS',
'show_hex_to_deimal': 'Hexadezimal- zu Dezimal-Umwandlung einblenden',
'sound': 'TON',
'sound_settigs': 'Sound-Einstellungen',
'start_with_autoplay': 'Starten mit Autoplay',
'stop_autoplay': 'STOP AUTOPLAY',
'stop_on_loss': 'STOPP BEI VERLUST',
'stop_on_loss_tooltip': 'Nutzen Sie „Stopp bei Verlust“, um Autowetten zu stoppen, nachdem Sie einen festgelegten Betrag verloren haben.</br> Zum Beispiel, wenn Sie mit 100 $ beginnen und den Stopp bei Verlust auf 25 $ festlegen',
'stop_on_profit': 'STOPP BEI GEWINN',
'stop_on_profit_tooltip': 'Nutzen Sie „Stopp bei Gewinn“, um Autowetten zu stoppen, nachdem Sie einen festgelegten Betrag gewonnen haben.</br> Zum Beispiel, wenn Sie mit 100 $ beginnen und den Stopp bei Gewinn auf 25 $ festlegen',
'there_are_no_active_torunament': 'Momentan gibt es keine aktiven Turniere.',
'third': 'dritter',
'this_game_is': 'Dieses Spiel ist',
'this_tool_recalculates': 'Dieses Tool berechnet den Multiplikator der Hash-Funktion des Spiel neu. Sie können einen beliebigen Spiel-Hash kopieren/einfügen, um den Multilplikator zu verifizieren.',
'time': 'ZEIT',
'time_frame': 'Zeitrahmen',
'to': 'BIS',
'toggle_animation': 'Animation umschalten',
'top_odds': 'Top-Quoten',
'top_wins': 'Top-Gewinne',
'total_bets': 'Gesamtwetten',
'tournament': 'Turnier',
'tournament_information': 'Turnierinformationen',
'try_advanced_autoplay': 'Probieren Sie die neue erweiterte Autoplay-Funktion aus!',
'uncredited_wins': 'Nicht gutgeschriebene Gewinne',
'user': 'BENUTZER',
'user_and_points': 'BENUTZER & PUNKTE',
'username': 'Benutzername',
'username_must_be_at_least': 'Benutzername muss mindestens X',
'verify': 'Verifizieren ',
'vip_popup_text': 'Sie können einen der speziellen VIP-Avatare auswählen, die exklusiv für unsere VIP-Teilnehmer verfügbar sind!',
'vip_popup_title': 'Herzlichen Glückwunsch, dass Sie ein VIP-Spieler geworden sind',
'waiting_for_the_next_round': 'Warten auf die nächste Runde...',
'warnings_are_calculated': ' ‎• Gewinne werden berechnet, indem der Einsatz mit dem Multiplikator, im Moment der Auszahlung, multipliziert wird',
'watch_take_off': 'Sehen Sie Ihrer Rakete und Ihren Gewinnmultiplikatoren beim Abheben zu.',
'watch_the_rocket': 'Sehen Sie der Rakete zu',
'we_have_noticed': 'Wir haben festgestellt, dass Sie zum ersten Mal „Rocketman“ spielen. Möchten Sie ein kurzes Video sehen, indem das Spiel erklärt wird?',
'week': 'Woche',
'welcome_to_rocketman': 'Willkommen bei Rocketman – ein neues und spannendes Spiel! Bei Rocketman haben Sie die Chance, das bis zu 20.000-fache Ihres ursprünglichen Einsatzes zu gewinnen.',
'what_is_provably_fair': 'Was ist „Provably fair“?',
'will_be_deposited': 'Wird dem Spielerguthaben mit dem ersten regulären Gewinn gutgeschrieben',
'win': 'GEWINN',
'win_amount': 'Gewinnbetrag',
'win_gifs': 'GEWINN',
'winner': 'Gewinner',
'wins': 'gewinnt',
'write_a_replay': 'Antworten...',
'year': 'Jahr',
'yes': 'JA',
'yes_start_the_round': 'Ja, Runde starten',
'you_have_cashed_out': 'Sie haben sich auszahlen lassen, Ihr Gewinn ist',
'you_have_won': 'SIE HABEN GEWONNEN',
'your_bet_is_lost': 'ist Ihre Wette verloren.',
'current': 'Aktuell',
'previous': 'Vorherige',
'claim': 'Beanspruchen',
'this_feature_will_give': 'Diese Funktion gibt zufällig ausgewählten Benutzern im Chat einen ausgewählten Betrag',
'amount_per_player': 'Betrag pro Spieler',
'no_of_players': 'Anzahl der Spieler',
'meteor_shower': 'Meteorregen',
'claim_your_free_bet': 'Fordern Sie Ihre Gratiswette an',
'meteor_shower_total': 'Meteorschauer insgesamt',
'shower': 'REGEN',
'total': 'Gesamt',
'meteor_shower_incoming': 'Meteorschauer kommt, siehe Chat!',
'insurance': 'VERSICHERUNG',
'insurance_paragraph': 'Spieler können eine Versicherung für ihren Einsatz kaufen, indem sie auf das Kontrollkästchen "Wettenversicherung" neben dem Wettknopf klicken. Die Versicherung kostet 10% des Gesamtbetrag der Wette und wird dem Spieler den Wetteinsatz zurückzahlen, falls die Rakete mit einem 1,00-Faktor explodieren sollte.',
'insure_your_bet': 'Versichern Sie Ihre Wette',
'bet_insurance_promo_text': 'Wählen Sie die Option „Wettversicherung“, um Ihre Wette mit einem Multiplikator von 1,00 vor einem Raketenabsturz zu versichern.',
'bet_insurance_promo_text_helper': 'Lesen Sie mehr im Abschnitt „Spielregeln“...',
'game_explanation_1': 'Wählen Sie Ihren Multiplikator. Wenn der Spielmultiplikator den von Ihnen gewählten Multiplikator übersteigt, erhalten Sie einen Gewinn',
'game_explanation_2': 'Ihr Multiplikator x Ihr Einsatz',
'retail_max_mp_1': 'Gewinne bis zu',
'retail_max_mp_2': 'deine Wette!',
'play_multibets': 'Spielen',
'multibets': 'MULTIWETTEN',
'bonus_game': 'BONUSSPIEL',
'all_wins_doubled': 'Alle Gewinne werden verdoppelt!',
'multibets_paragraph_1': 'um Ihre Chancen zu erhöhen!',
'multibets_paragraph_2': 'Sie können Wetten auf bis zu zehn Runden zu einer einzigen Kombiwette kombinieren und riesige Auszahlungen gewinnen!',
'multibets_paragraph_3': 'Jedes Mal, wenn eine Runde gewonnen wird, wird die Gewinndividende dieser Runde auf die nächste Runde gesetzt, bis alle Runden gewinnen.',
'bonus_launch': 'Gewinnen Sie x2 bei allen Wetten in der „Bonus Launch“-Runde!',
'multiplier_history': 'Multiplikatorgeschichte',
'will_start_soon': 'wird bald beginnen',
'hurry_up_and_place_your_bets': 'Beeilen Sie sich und platzieren Sie Ihre Wetten!',
'active_bets': 'Aktive Wetten',
'shop': 'GESCHÄFT',
'game_meteor_shower_paragraph': 'Meteor Shower vergibt eine Gratiswette mit einem vordefinierten Wert an eine vordefinierte Anzahl von Spielern. Es kann entweder vom Server oder von einem Spieler initiiert werden. Sobald Meteorschauer aktiviert ist, wird im Spiel eine Nachricht angezeigt, die Benutzer dazu auffordert, sich den Chat anzusehen, in dem sich die Meteorschauer-Nachricht befindet. Spieler können die Gratiswette anfordern, indem sie auf die Schaltfläche „Anfordern“ klicken. Gratiswetten werden an Spieler vergeben, die zuerst auf die Schaltfläche „Anfordern“ klicken. Die Verwendung von Autoclickern oder jeglicher Art von Automatisierungssoftware zur Nachahmung von Spielerklicks und zur Erlangung von Preisen ist verboten und kann zu einem Ausschluss dieser Funktion führen.',
'claming_failed': 'Die Geltendmachung ist fehlgeschlagen',
'player_on_blacklist': 'Der Spieler steht auf der schwarzen Liste',
'you_can_claim_ticket': 'Sie können ein Ticket nur dann beanspruchen, wenn Sie ein aktiver Spieler mit regelmäßigen Wetten sind',
'you_already_have_free_tickets': 'Sie haben bereits Freikarten, bitte geben Sie diese zuerst aus',
'please_play_the_game_in_portait_mode': 'Bitte spielen Sie das Spiel im Hochformatmodus.',
};
langArr.el = {
'your_insurance_returns_you': 'Η ασφάλισή σας σας επιστρέφει',
'bet_insurance': 'Ασφάλιση στοιχήματος',
'max_bet_is': 'Το μέγιστο στοίχημα είναι',
'already_active_bet_of': 'και υπάρχει ήδη ενεργό στοίχημα',
'1_from_game_history': '‎1. Στο ιστορικό παιχνιδιού',
'2_in_settings_window': '‎2. Στο παράθυρο ρυθμίσεων',
'3_online_calculator': '‎3. Online εργαλείου υπολογισμού',
'active_tournament_1': 'Το ενεργό τουρνουά ',
'active_tournament_2': 'δεν έχει ακόμη στοιχήματα.',
'add_another_bet': 'Προσθήκη επιπλέον στοιχήματος',
'advanced_autoplay': 'ΠΡΟΗΓΜΕΝΟ ΑΥΤΟΜΑΤΟ ΠΑΙΧΝΙΔΙ',
'advanced_autoplay_for_bet': 'Προηγμένο αυτόματο παιχνίδι για στοίχημα',
'alien_saucer_free_bets': 'Δωρεάν Στοιχήματα Εξωγήινου Ιπτάμενου Δίσκου',
'all_bets': 'Όλα τα Στοιχήματα',
'all_in_your_hands': 'Όλα είναι στα χέρια σας!',
'allow': 'Αποδοχή',
'at': 'σε',
'austronaut_promo_credit': 'Πόντοι Προσφοράς Αστροναύτη',
'auto_play_and_autocash': 'ΑΥΤΟΜΑΤΟ ΠΑΙΧΝΙΔΙ ΚΑΙ ΑΥΤΟΜΑΤΗ ΕΞΑΡΓΥΡΩΣΗ ',
'autoplay': 'Αυτόματο παιχνίδι',
'autoplay_and_cashout': '‎02. Αυτόματο Παιχνίδι και αυτόματη εξαργύρωση',
'back_to_home': 'Επιστροφή στην αρχική',
'balance': 'Υπόλοιπο',
'bet': 'ΣΤΟΙΧΗΜΑ',
'bet_amount': 'ΠΟΣΟ ΣΤΟΙΧΗΜΑΤΟΣ',
'bet_and_cashout': '‎01. Στοίχημα και Εξαργύρωση',
'bet_bets_tab': 'ΠΟΝΤΑΡΙΣΜΑ',
'bet_failed': 'Αποτυχία Στοιχήματος',
'bet_statistic': 'Στατιστικά Στοιχημάτων',
'bet_time': 'Ώρα Στοιχήματος',
'bet_wins': 'Κέρδη Στοιχημάτων',
'bets': 'Στοιχήματα',
'biggest_odds': 'Οι Μεγαλύτερες Πιθανότητες',
'bonus_type': 'ΕΙΔΟΣ ΜΠΟΝΟΥΣ',
'bonusing_history': 'Ιστορικό μπόνους',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Νικητές Boosterpot',
'but_remember_paragraph': 'Να θυμάστε, εάν δεν προλάβατε να κάνετε Εξαργύρωση προτού εκραγεί ο πύραυλος,',
'calculate_raw_mp_to_decimal': 'Υπολογισμός Ακατέργαστου Πολλαπλασιαστή από δεκαδικό και στρογγυλοποίηση στον μικρότερο πλησιέστερο ακέραιο',
'cancel': 'Ακύρωση',
'cant_post_media_content': 'Χωρίς δυνατότητα συχνής δημοσίευσης περιεχομένου μέσων',
'cashed_out': 'Εξαργυρώθηκαν',
'cashout': 'Εξαργύρωση',
'cashout_at': 'ΕΙΣΠΡΑΞΗ ΣΕ',
'cashout_before': 'Εξαργυρώστε προτού εκραγεί ο Πύραυλος και τα χρήματα είναι δικά σας!',
'change_avatar': 'Αλλαγή Άβαταρ',
'change_username': 'Αλλαγή ονόματος χρήστη',
'characters_long': 'χαρακτήρες',
'chat': 'Συνομιλία',
'chat_room': 'Αίθουσα Συνομιλίας',
'checking_for_spam': 'Έλεγχος σπαμ σε εξέλιξη…',
'close': 'Κλείσιμο',
'coeff': 'ΣΥΝΤΕΛ.',
'collect_win': 'Συλλέξτε Κέρδος',
'combined_seed': 'Συνδυασμένο φύτρο',
'company': 'Εταιρεία',
'congratulations_you_have_won_free_bets': 'Συγχαρητήρια! Κερδίσατε Μπόνους Στοιχήματα! Μπορείτε να στοιχηματίσετε',
'convert_first_13_to_decimal': ' Μετατροπή των 13 πρώτων bytes σε δεκαδικό',
'd': 'ημ.',
'date': 'ΗΜΕΡΟΜΗΝΙΑ',
'date_and_time': 'Ημερομηνία και Ώρα',
'deny': 'Απόρριψη',
'detailed_game_rules': 'Αναλυτικοί Κανόνες Παιχνιδιού',
'dissconnection_policy': 'ΠΟΛΙΤΙΚΗ ΑΠΟΣΥΝΔΕΣΗΣ',
'easy_as_one_two_three': 'Εύκολο όπως ένα, δύο, τρία',
'enter_username': 'Πληκτρολογήστε το όνομα χρήστη που θέλετε',
'error_handling': 'ΔΙΑΧΕΙΡΙΣΗ ΛΑΘΩΝ',
'exit_full_screen': 'Έξοδος από τη λειτουργία πλήρους οθόνης',
'first': 'πρώτο',
'first_time_playing': 'Παίζετε «Rocketman» πρώτη φορά;',
'folow_the_three_easy_steps': 'Ακολουθήστε τα τρία εύκολα βήματα και απολαύστε το Rocketman! ',
'free_bets': 'ΔΩΡΕΑΝ ΣΤΟΙΧΗΜΑΤΑ',
'free_bets_desc': 'Κερδίσατε δωρεάν στοιχήματα! Μπορείτε να τα χρησιμοποιήσετε ανά πάσα στιγμή πατώντας το κουμπί έναρξης.',
'free_bets_header': 'Δωρεάν στοιχήματα',
'from': 'ΑΠΟ',
'fullscreen_mode': 'Για την καλύτερη εμπειρία χρήστη, το παιχνίδι θα ξεκινήσει σε λειτουργία πλήρους οθόνης.',
'function': 'Λειτουργία',
'game_functions': 'Λειτουργίες Παιχνιδιού',
'game_history': 'ΙΣΤΟΡΙΚΟ ΠΑΙΧΝΙΔΙΟΥ',
'game_r_1_paragraph': 'Ο παίκτης μπορεί να ενεργοποιήσει την επιλογή αυτόματου παιχνιδιού όπου ένα στοίχημα τοποθετείται αυτόματα για κάθε νέο γύρο χρησιμοποιώντας το ποσό από το προηγούμενο στοίχημα.',
'game_r_10_paragraph': 'Όταν απονεμηθούν οι πόντοι προσφοράς, για οποιοδήποτε ποντάρισμα τοποθετεί ο παίκτης, τα χρήματα θα αφαιρούνται αρχικά από τους πόντους προσφοράς μέχρι να τελειώσουν, και στη συνέχεια θα γίνεται ανάληψη χρημάτων από το πορτοφόλι του παίκτη.',
'game_r_11_paragraph': 'Δεν είναι δυνατό να εξαργυρώσετε τους πόντους προσφοράς, αλλά οποιοδήποτε κέρδος προκύπτει από αυτά τα στοιχήματα, πιστώνεται πλήρως στον παίκτη.',
'game_r_12_paragraph': 'Οι πόντοι προσφοράς έχουν περίοδο ισχύος 30 ημερών. Με το πέρας της, λήγουν και δεν είναι δυνατό να τους χρησιμοποιήσετε.',
'game_r_13_paragraph': 'Στον παίκτη μπορούν να απονεμηθούν τυχαία δωρεάν στοιχήματα. Ένα αναδυόμενο γραφικό θα εμφανιστεί στην οθόνη που θα ειδοποιεί τον παίκτη σχετικά με τα δωρεάν στοιχήματα, τον αριθμό των στοιχημάτων και το ποσό ανά στοίχημα.',
'game_r_14_paragraph': 'Όταν απονεμηθούν τα δωρεάν στοιχήματα, ο παίκτης μπορεί να επιλέξει πότε θέλει να ξεκινήσει τον γύρο δωρεάν στοιχημάτων. Όταν ξεκινήσει ο γύρος δωρεάν στοιχημάτων, ένα αυτόματο στοίχημα με το καθορισμένο ποντάρισμα τοποθετείται, και εξαρτάται από τον παίκ',
'game_r_15_paragraph': 'Τα δωρεάν στοιχήματα έχουν περίοδο ισχύος 30 ημερών. Με το πέρας της, λήγουν και δεν είναι δυνατό να τα χρησιμοποιήσετε.',
'game_r_16a_paragraph': 'Η συνολική θεωρητική επιστροφή στον παίκτη είναι ',
'game_r_17_paragraph': 'Τα ποσοστά ΘΕΠ παρακολουθούνται συνεχώς και εξακριβώνονται από ανεξάρτητα τρίτα μέρη.',
'game_r_18_paragraph': 'Εάν χαθεί η σύνδεση αφού τοποθετηθεί ένα στοίχημα, το στοίχημα δεν θα ακυρωθεί. Εάν ο πολλαπλασιαστής φτάσει 20.000x το ποντάρισμά σας θα εξαργυρωθεί αυτόματα και οι πόντοι θα πιστωθούν στον λογαριασμό σας. ',
'game_r_19_paragraph': 'Μπορείτε να συνομιλήσετε στο παιχνίδι με άλλους παίκτες. Πληκτρολογήστε το μήνυμά σας στο πεδίο συνομιλίας, ή κοινοποιήστε το κερδοφόρο ποντάρισμά σας σε άλλους παίκτες.',
'game_r_2_paragraph': 'Μπορείτε να εισαγάγετε έναν πολλαπλασιαστή αυτόματης εξαργύρωσης στο πεδίο «Αυτόματη εξαργύρωση», και όταν τον εισαγάγετε, το παιχνίδι θα εξαργυρώσει αυτόματα εάν ο πύραυλος φτάσει στον καθορισμένο πολλαπλασιαστή.',
'game_r_20_paragraph': 'Οι παίκτες που κάνουν κατάχρηση της Συνομιλίας μέσω κακομεταχείρισης άλλων παικτών ή χρησιμοποιώντας ανάρμοστη ή/και κακοποιητική γλώσσα θα χάσουν τα προνόμια χρήσης της Συνομιλίας.',
'game_r_21_paragraph': 'Το Rocketman έχει σχεδιαστεί με γνώμονα όμορφη μουσική υπόβαθρου και ηχητικά εφέ. Σε περίπτωση που θέλετε να απενεργοποιήσετε τη μουσική ή/και τα ηχητικά εφέ, μπορείτε να το κάνετε από το αναπτυσσόμενο μενού.',
'game_r_22_paragraph': 'Κάνοντας κλικ στο κουμπί «Τα στοιχήματά μου», ο παίκτης μπορεί να ελέγξει τα τελευταία στοιχήματά του. Για περισσότερες πληροφορίες για το ιστορικό παιχνιδιού (π.χ.',
'game_r_23_paragraph': 'Σε περίπτωση λάθους, οι γύροι ενδέχεται να μην ξεκινήσουν εγκαίρως. Σε αυτήν την περίπτωση, η ομάδα υποστήριξης θα προσπαθήσει να επιλύσει το πρόβλημα το συντομότερο δυνατόν για να συνεχιστούν οι κανονικοί γύροι παιχνιδιού. Σε περίπτωση προβλήματος με κάπ',
'game_r_24_paragraph': 'Ο λανθάνων χρόνος δικτύου είναι αναπόφευκτο κομμάτι της συνδεσιμότητας στο διαδίκτυο. Θεωρούμε έγκυρα όλα τα στοιχήματα και τις εισπράξεις μόνο όταν φτάσουν στον διακομιστή μας, ανεξάρτητα από τη διάρκεια του λανθάνοντος χρόνου δικτύου. ',
'game_r_3_paragraph': 'Ο παίκτης μπορεί να χρησιμοποιήσει πλήκτρα συντόμευσης για να τοποθετήσει γρήγορα ένα στοίχημα ή να κάνει εξαργύρωση στο παιχνίδι. Η χαρτογράφηση των κουμπιών είναι ως εξής',
'game_r_4_paragraph': 'Το 1% από κάθε στοίχημα αφιερώνεται σε ένα ταμείο προοδευτικού τζακπότ. Αυτό το ταμείο εξυπηρετεί το σύστημα προοδευτικού τζακπότ',
'game_r_5_paragraph': ' ‎• Jackpot – Υψηλότερο επίπεδο, το μεγαλύτερο έπαθλο, λιγότερο συχνό',
'game_r_6_paragraph': ' ‎• Rocketpot – Μεσαίο επίπεδο, μεσαίο έπαθλο',
'game_r_7_paragraph': ' ‎• Boosterpot – Χαμηλότερο επίπεδο, χαμηλό έπαθλο, περισσότερο συχνό ',
'game_r_8_paragraph': 'Όλα τα επίπεδα τζάκποτ απονέμονται με τη χρήση μηχανισμού «Mystery» ή «must-hit-by». Η αξία κάθε τζάκποτ «must-hit-by» καθορίζεται αμέσως αφού κερδηθεί το προηγούμενο τζάκποτ, από γεννήτρια τυχαίων αριθμών και αποθηκεύεται ως κρυπτογραφημένη αξία σε απομα',
'game_r_8a_paragraph': 'Σημείωση: Νικητής του Τζάκποτ μπορεί να είναι μόνο ένας παίκτης Rocketman που παίζει ενεργά στον γύρο όταν το τζάκποτ έχει ενεργοποιηθεί. Δεν υπάρχει πιθανότητα ότι εάν ο παίκτης έχει κερδίσει το τζάκποτ, θα πρέπει να το μοιραστεί με οποιονδήποτε άλλο παίκτη Rocketman.',
'game_r_9_paragraph': 'Στον παίκτη μπορούν να απονεμηθούν τυχαία πόντοι προσφοράς. Ένα αναδυόμενο γραφικό θα εμφανιστεί στην οθόνη που θα ειδοποιεί τον παίκτη σχετικά με τους πόντους προσφοράς που απονεμήθηκαν και το ποσό.',
'game_round': 'γύρο παιχνιδιού!',
'game_rules': 'Κανόνες Παιχνιδιού',
'game_rules_1_paragraph': 'Το παιχνίδι «Rocketman» είναι ένα τυχερό παιχνίδι διασκέδασης νέας γενιάς. Κερδίστε πολλές περισσότερες φορές μέσα σε δευτερόλεπτα! Το παιχνίδι «Rocketman» έχει δημιουργηθεί με βάση ένα',
'game_rules_10_paragraph': ' ‎• Το Αυτόματο Παιχνίδι ενεργοποιείται από την καρτέλα «Αυτόματο Παιχνίδι» στο Πλαίσιο Στοιχήματος, επιλέγοντας το πλαίσιο ελέγχου «Αυτόματο Στοίχημα». Αφού ενεργοποιηθεί, τα στοιχήματα θα τοποθετούνται αυτόματα, αλλά για την εξαργύρωση θα πρέπει να πατά',
'game_rules_11_paragraph': '‎• Η Αυτόματη Εξαργύρωση είναι διαθέσιμη από την καρτέλα «Αυτόματο Παιχνίδι» στο πλαίσιο στοιχήματος. Αφού ενεργοποιηθεί, το στοίχημά σας θα εξαργυρώνεται αυτόματα, όταν ο πύραυλος φτάνει στον συντελεστή.',
'game_rules_2_paragraph': '‎, το οποίο είναι αυτήν τη στιγμή η μοναδική, πραγματική εγγύηση ακεραιότητας στη βιομηχανία των τυχερών παιχνιδιών.',
'game_rules_3_paragraph': '‎• Ο πολλαπλασιαστής κέρδους ξεκινά x1 και αυξάνεται όλο και περισσότερο αφού απογειωθεί ο Τυχερός Πύραυλος.',
'game_rules_4_paragraph': ' ‎• Τα κέρδη σας είναι ο πολλαπλασιαστής με τον οποίο κάνατε Εξαργύρωση πολλαπλασιασμένα με το στοίχημά σας. ',
'game_rules_5_paragraph': '‎• Πριν από την έναρξη κάθε γύρου, το αποδεδειγμένα δίκαιο σύστημα για την παραγωγή τυχαίου αριθμού παράγει έναν συντελεστή στον οποίο ο Τυχερός Πύραυλος θα απομακρυνθεί πετώντας. Μπορείτε να ελέγξετε κατά πόσο αυτή η παραγωγή είναι δίκαιη κάνοντας κλικ σ',
'game_rules_6_paragraph': '‎• Επιλέξτε ποσό και πατήστε το κουμπί «Στοίχημα» για να τοποθετήσετε ένα στοίχημα. ',
'game_rules_7_paragraph': '‎• Μπορείτε να τοποθετήσετε ταυτόχρονα δύο στοιχήματα, προσθέτοντας ένα δεύτερο στο αντίστοιχο πλαίσιο. Για να προσθέσετε δεύτερο στοίχημα πατήστε στο εικονίδιο «συν», που βρίσκεται στην πάνω δεξιά γωνία του πλαισίου για το πρώτο στοίχημα.',
'game_rules_8_paragraph': ' ‎• Πατήστε στο κουμπί «Εξαργύρωση» για να εξαργυρώσετε τα κέρδη σας. Το κέρδος σας είναι το στοίχημα πολλαπλασιασμένο με τον συντελεστή εξαργύρωσης.',
'game_rules_9_paragraph': '‎• Αν δεν εξαργυρώσετε προτού ο πύραυλος απομακρυνθεί πετώντας, το στοίχημά σας θα χαθεί.',
'general_rules': 'ΓΕΝΙΚΟΙ ΚΑΝΟΝΕΣ',
'gifs': 'GIF',
'go_back': 'Επιστροφή',
'good_job_buddy': 'Μπράβο, συνεχίστε έτσι',
'h': 'ώ.',
'hashed_in_sha_512': 'Κατακερματισμός σε SHA512',
'hex': 'Δεκαεξαδικό',
'hey_username': 'Γεια σας',
'hide_hex_to_decimal': 'Απόκρυψη μετατροπής δεκαεξαδικού σε δεκαδικό',
'highscore': 'Υψηλή βαθμολογία',
'how_it_works': 'Πώς λειτουργεί;',
'how_to_check': 'Πώς να ελέγξετε;',
'how_to_play': 'Πώς να παίξετε',
'if_the_maximum_multiplier': '‎• Εάν ο πύραυλος φτάσει τον μέγιστο πολλαπλασιαστή 20.000, όλα τα ανοιχτά στοιχήματα εξαργυρώνονται αυτόματα στον μέγιστο πολλαπλασιαστή',
'increase': 'Αύξηση',
'input_bet_amount': 'Εισαγάγετε ποσό στοιχήματος και τοποθετήστε το στοίχημά σας πριν από την απογείωση.',
'insufficient_funds': 'Μη επαρκές υπόλοιπο',
'it_blew_up': 'Ανατινάχτηκε! Φοβερό…',
'jackpot': 'Τζάκποτ',
'jackpot_winners': 'Νικητές Jackpot',
'key': 'Κλειδί',
'load_more': 'ΦΟΡΤΩΣΗ ΠΕΡΙΣΣΟΤΕΡΩΝ',
'loading': 'Φόρτωση',
'loading_next_round': 'Φόρτωση επόμενου γύρου…',
'loss_gifs': 'ΑΠΩΛΕΙΑ',
'lost_connection': 'Απώλεια Σύνδεσης',
'make_a_bet': 'Τοποθετήστε Στοίχημα',
'malfunction_voids': 'Τυχόν δυσλειτουργία ακυρώνει όλες τις Πληρωμές και τα Παιχνίδια.',
'maximum_payout': 'Η μέγιστη πληρωμή είναι ',
'menu': 'Μενού',
'min_bet_is': 'Το ελαχιστο στοιχημα ειναι',
'month': 'Μήνας',
'more_details': 'Περισσότερες Λεπτομέρειες',
'more_information': 'Περισσότερες πληροφορίες',
'multiplier': 'Πολλαπλασιαστής',
'multiplier_starts': '‎• Ο πολλαπλασιαστής ξεκινά από το 1 και φτάνει μέχρι το 20.000',
'multipliers_larger': 'Πολλαπλασιαστές μεγαλύτεροι από x20.000 περιορίζονται στη μέγιστη αξία πολλαπλασιαστή',
'music_settings': 'Ρυθμίσεις Μουσικής',
'my_bet_history': 'Το Ιστορικό Στοιχημάτων μου',
'my_bets': 'Τα Στοιχήματά μου',
'my_bonysing_history': 'Το Ιστορικό μπόνους μου',
'network_latency': 'ΛΑΝΘΑΝΩΝ ΧΡΟΝΟΣ ΔΙΚΤΥΟΥ',
'network_latency_content': 'Ο λανθάνων χρόνος δικτύου είναι αναπόφευκτο κομμάτι της συνδεσιμότητας στο διαδίκτυο. Θεωρούμε έγκυρα όλα τα στοιχήματα και τις εισπράξεις μόνο όταν φτάσουν στον διακομιστή μας, ανεξάρτητα από τη διάρκεια του λανθάνοντος χρόνου δικτύου.',
'network_latency_title': 'ΛΑΝΘΑΝΩΝ ΧΡΟΝΟΣ ΔΙΚΤΥΟΥ',
'no_thanks': 'Όχι, ευχαριστώ!',
'not_right_now': 'Όχι τώρα',
'off': 'ανε',
'oh_snap': 'Πάει…',
'ok_close': 'Εντάξει, κλείσιμο!',
'ok_thanks': 'Εντάξει, ευχαριστώ!',
'on': 'ενεργό',
'on_loss': 'ΣΕ ΑΠΩΛΕΙΑ',
'on_win': 'ΣΕ ΚΕΡΔΟΣ',
'online': 'Online',
'per_bet': 'ανά στοίχημα',
'pick_your_avatar_img': 'Επιλέξτε Εικόνα Άβαταρ',
'place_bet_with_last': 'Τοποθετήστε ένα στοίχημα με το τελευταίο επιλεγμένο ποντάρισμα ',
'play_now': 'Παίξτε Τώρα',
'player_1_seed': 'Φύτρο Παίκτη 1',
'player_2_seed': 'Φύτρο Παίκτη 2',
'player_3_seed': 'Φύτρο Παίκτη 3',
'please_wait_active_bets': 'Περιμένετε να ολοκληρωθούν τα τρέχοντα στοιχήματα για να ενεργοποιηθούν τα δωρεάν στοιχήματα',
'points': 'ΠΟΝΤΟΙ',
'powered_by': 'υποστηρίζεται από τη',
'previous_hand': 'Προηγούμενο Χέρι',
'prize': 'ΕΠΑΘΛΟ',
'prizes': 'έπαθλα',
'progressive_jackpot': 'ΠΡΟΟΔΕΥΤΙΚΟ ΤΖΑΚΠΟΤ',
'promo_credit': 'Promo πίστωση',
'promo_credit_condradulations': 'Συγχαρητήρια! Κερδίσατε ένα αστρονομικά καλό ποσό πόντων Προσφοράς, που μπορείτε να χρησιμοποιήσετε στο παιχνίδι!',
'promo_credits': 'ΠΟΝΤΟΙ ΠΡΟΣΦΟΡΑΣ',
'promo_history': 'Ιστορικό Προσφορών',
'provably_fair': 'Αποδεδειγμένα Δίκαιο Σύστημα',
'provably_fair_1_paragraph': 'Το παιχνίδι «Rocketman» βασίζεται σε κρυπτογραφική τεχνολογία που ονομάζεται «Αποδεδειγμένα Δίκαιο Σύστημα» («Provable Fair»). Αυτή η τεχνολογία εγγυάται 100% δίκαιο αποτέλεσμα παιχνιδιού. Με αυτήν την τεχνολογία, είναι αδύνατη η παρεμβολή οποιουδήποτε τρ',
'provably_fair_2_paragraph': 'Το αποτέλεσμα κάθε γύρου (ο συντελεστής «Μακρινής Πτήσης» του παιχνιδιού) δεν παράγεται στους διακομιστές μας. Παράγεται με τη βοήθεια των παικτών του γύρου και είναι απόλυτα διαφανές. Με αυτόν τον τρόπο, είναι αδύνατο σε οποιονδήποτε να παραποιήσει το απ',
'provably_fair_3_paragraph': 'Το αποτέλεσμα του γύρου παράγεται από τέσσερις ανεξάρτητους συμμετέχοντες στον γύρο παιχνιδιού.',
'provably_fair_4_paragraph': 'Όταν ξεκινά ο γύρος του παιχνιδιού, το παιχνίδι συγχωνεύει το φύτρο διακομιστή με τρία φύτρα πελατών. Από τα συγχωνευμένα σύμβολα παράγεται ο κατακερματισμός SHA512 και από αυτόν τον κατακερματισμό, το αποτέλεσμα του παιχνιδιού.',
'provably_fair_5_paragraph': 'Μπορείτε να ελέγξετε κατά πόσο τα αποτελέσματα είναι δίκαια για κάθε γύρο στο ιστορικό παιχνιδιού, κάνοντας κλικ στο πράσινο εικονίδιο. Σε ανοιχτό παράθυρο, θα δείτε φύτρο διακομιστή, 3 ζεύγη από φύτρα παικτών, συνδυασμένο κατακερματισμό και το αποτέλεσμα',
'provably_fair_6_paragraph': 'Οι εκδόσεις κατακερματισμού για τα φύτρα διακομιστή των επόμενων γύρων είναι διαθέσιμες στο κοινό στο παράθυρο των ρυθμίσεων (Στο μενού χρήστη, επιλέξτε «Ρυθμίσεις Αποδεδειγμένα Δίκαιου Συστήματος» και στη συνέχεια «Επόμενο φύτρο διακομιστή SHA256»). Επιπ',
'provably_fair_7_paragraph': 'Η ορθότητα του κατακερματισμού μπορεί να ελεγχθεί σε οποιοδήποτε online εργαλείου υπολογισμού, για παράδειγμα',
'provably_fair_system': 'αποδεδειγμένα δίκαιο σύστημα',
'quick_explanation': 'Γρήγορη εξήγηση',
'randomly_generated_server_hash': 'Τυχαία δημιουργημένο HASH διακομιστή',
'rank': 'ΚΑΤΑΤΑΞΗ',
'raw_multiplier': 'ακατέργαστος πολλαπλασιαστής',
'raw_multiplier_to_edged': 'Ακατέργαστος Πολλαπλασιαστής σε Επεξεργασμένο Πολλαπλασιαστή με χρήση',
'remove_additional_bet': 'Αφαίρεση επιπλέον στοιχήματος',
'reset': 'Επαναφορά',
'return_to_player': 'ΕΠΙΣΤΡΟΦΗ ΣΤΟΝ ΠΑΙΚΤΗ',
'rocketman_is_pure_excitement': 'Το παιχνίδι «Rocketman» θα σας ενθουσιάσει! Ρισκάρετε και κερδίστε.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Νικητές Rocketpot',
'round': 'Γύρος',
'round_id': 'Αναγνωριστικό Γύρου',
'round_multiplier': 'Πολλαπλασιαστή γύρου',
'round_results': 'Αποτελέσματα γύρου',
'rounds': 'γύροι',
'rtp': 'ΘΕΠ',
'rules': 'κανόνες',
'rules_details': 'Κανόνες τουρνουά',
'save': 'Αποθήκευση',
'schedule': 'πρόγραμμα',
'second': 'δεύτερο',
'seconds_left': 'δευτ. που απομένουν',
'server_hash': 'ΚΑΤΑΚΕΡΜΑΤΙΣΜΟΣ διακομιστή',
'please_wait_current_bets': 'ΠΑΡΑΚΑΛΩ ΠΕΡΙΜΕΝΕΤΕ ΝΑ ΟΛΟΚΛΗΡΩΘΟΥΝ ΤΑ ΤΡΕΧΟΝΤΑ ΣΤΟΙΧΗΜΑΤΑ',
'min_autocashout_value_must_be': 'Η ΕΛΑΧΙΣΤΗ ΑΥΤΟΜΑΤΗ ΤΙΜΗ ΠΡΕΠΕΙ ΝΑ ΕΙΝΑΙ',
'min_bet_value_must_be': 'Η ΕΛΑΧΙΣΤΗ ΑΞΙΑ ΣΤΟΙΧΗΜΑΤΟΣ ΠΡΕΠΕΙ ΝΑ ΕΙΝΑΙ',
'server_seed': 'Φύτρο Διακομιστή',
'session_dissconnected': 'Αποσύνδεση Συνεδρίας',
'multisession_not_allowed': 'Δεν επιτρέπεται η πολλαπλή συνεδρία',
'set_auto_bet': 'ΟΡΙΣΜΟΣ ΑΥΤΟΜΑΤΟΥ ΣΤΟΙΧΗΜΑΤΟΣ',
'set_auto_cashout': 'ΟΡΙΣΜΟΣ ΑΥΤΟΜΑΤΗΣ ΕΞΑΡΓΥΡΩΣΗΣ',
'shourtcut_keys': 'ΠΛΗΚΤΡΑ ΣΥΝΤΟΜΕΥΣΗΣ',
'show_hex_to_deimal': 'Εμφάνιση μετατροπής δεκαεξαδικού σε δεκαδικό',
'sound': 'ΗΧΟΣ',
'sound_settigs': 'Ρυθμίσεις Ήχου',
'start_with_autoplay': 'Ξεκινήστε το αυτόματο παιχνίδι',
'stop_autoplay': 'ΔΙΑΚΟΠΗ ΑΥΤΟΜΑΤΟΥ ΠΑΙΧΝΙΔΙΟΥ',
'stop_on_loss': 'ΔΙΑΚΟΠΗ ΣΕ ΑΠΩΛΕΙΑ',
'stop_on_loss_tooltip': 'Χρησιμοποιήστε τη Διακοπή σε Απώλεια για να σταματήσετε το αυτόματο στοίχημα αφού χάσετε ένα συγκεκριμένο ποσό.<br/> Για παράδειγμα, αν ξεκινήσετε με 100 $ και ορίσετε Διακοπή σε Απώλεια 25 $.',
'stop_on_profit': 'ΔΙΑΚΟΠΗ ΣΕ ΚΕΡΔΟΣ',
'stop_on_profit_tooltip': 'Χρησιμοποιήστε τη Διακοπή σε Κέρδος για να σταματήσετε το αυτόματο στοίχημα αφού κερδίσετε ένα συγκεκριμένο ποσό.<br/> Για παράδειγμα, αν ξεκινήσετε με 100 $ και ορίσετε Διακοπή σε Κέρδος 25 $.',
'there_are_no_active_torunament': 'Δεν υπάρχουν ενεργά τουρνουά αυτή τη στιγμή.',
'third': 'τρίτο',
'this_game_is': 'Αυτό το παιχνίδι',
'this_tool_recalculates': 'Αυτό το εργαλείο υπολογίζει ξανά τον πολλαπλασιαστή από τον κατακερματισμό παιχνιδιού. Μπορείτε να αντιγράψετε/επικολλήσετε οποιονδήποτε κατακερματισμό παιχνιδιού για να εξακριβώσετε τον πολλαπλασιαστή.',
'time': 'ΩΡΑ',
'time_frame': 'Χρονικό Πλαίσιο',
'to': 'ΕΩΣ',
'toggle_animation': 'Εναλλαγή Εφέ Κίνησης',
'top_odds': 'Κορυφαίες Πιθανότητες',
'top_wins': 'Κορυφαία Κέρδη',
'total_bets': 'Συνολικά Στοιχήματα',
'tournament': 'Τουρνουά',
'tournament_information': 'Πληροφορίες τουρνουά',
'try_advanced_autoplay': 'Δοκιμάστε τη νέα προηγμένη λειτουργία αυτόματης αναπαραγωγής!',
'uncredited_wins': 'Κέρδη που δεν έχουν πιστωθεί',
'user': 'ΧΡΗΣΤΗΣ',
'user_and_points': 'ΧΡΗΣΤΗΣ ΚΑΙ ΠΟΝΤΟΙ',
'username': 'Όνομα χρήστη',
'username_must_be_at_least': 'Το όνομα χρήστη πρέπει να έχει τουλάχιστον X',
'verify': 'Εξακρίβωση',
'vip_popup_text': 'Μπορείτε να επιλέξετε ένα από τα ειδικά VIP avatar που διαθέτουμε αποκλειστικά για τα VIP μέλη μας!',
'vip_popup_title': 'Συγχαρητήρια, γίνατε VIP παίκτης',
'waiting_for_the_next_round': 'Αναμονή για τον επόμενο γύρο…',
'warnings_are_calculated': ' ‎• Τα κέρδη υπολογίζονται με πολλαπλασιασμό του πονταρίσματος με τον πολλαπλασιαστή τη στιγμή της Εξαργύρωσης',
'watch_take_off': 'Παρακολουθήστε καθώς απογειώνεται ο Πύραυλός σας και ο πολλαπλασιαστής κερδών σας αυξάνεται.',
'watch_the_rocket': 'Παρακολουθήστε τον Πύραυλο',
'we_have_noticed': 'Βλέπουμε ότι είναι η πρώτη φορά που παίζετε «Rocketman». Θέλετε να δείτε ένα σύντομο βίντεο που εξηγεί πώς να παίξετε;',
'week': 'Εβδομάδα',
'welcome_to_rocketman': 'Καλώς ήρθατε στο Rocketman, ένα νέο και συναρπαστικό παιχνίδι! Με το Rocketman έχετε την ευκαιρία να κερδίσετε έως και 20.000x το αρχικό ποντάρισμά σας.',
'what_is_provably_fair': 'Τι είναι το Αποδεδειγμένα Δίκαιο Σύστημα;',
'will_be_deposited': 'Θα κατατεθούν στο υπόλοιπο του παίκτη με την πρώτη κανονική νίκη',
'win': 'ΚΕΡΔΟΣ',
'win_amount': 'Ποσό Κέρδους',
'win_gifs': 'ΚΕΡΔΟΣ',
'winner': 'νικητής',
'wins': 'κέρδη',
'write_a_replay': 'Γράψτε μια απάντηση…',
'year': 'Έτος',
'yes': 'ΝΑΙ',
'yes_start_the_round': 'Ναι, να αρχίσει ο γύρος',
'you_have_cashed_out': 'Εξαργυρώσατε! Το κέρδος σας είναι ',
'you_have_won': 'ΚΕΡΔΙΣΑΤΕ',
'your_bet_is_lost': 'το στοίχημά σας θα χαθεί.',
'current': 'Ρεύμα',
'previous': 'Προηγούμενος',
'claim': 'Απαίτηση',
'this_feature_will_give': 'Αυτή η δυνατότητα θα δώσει επιλεγμένο ποσό σε τυχαίους χρήστες στη συνομιλία',
'amount_per_player': 'Ποσό ανά παίκτη',
'no_of_players': 'Αριθμός παικτών',
'meteor_shower': 'Βροχή μετεωριτών',
'claim_your_free_bet': 'Διεκδικήστε το δωρεάν στοίχημά σας',
'meteor_shower_total': 'Meteorschauer insgesamt',
'shower': 'ΒΡΟΧΗ',
'total': 'Σύνολο',
'meteor_shower_incoming': 'Έρχεται η βροχή μετεωριτών, δείτε τη συνομιλία!',
'insurance': 'ΑΣΦΑΛΙΣΗ',
'insurance_paragraph': 'Οι παίκτες μπορούν να αγοράσουν ασφάλεια για το στοίχημά τους κάνοντας κλικ στο πλαίσιο "Ασφάλεια Στοίχηματος" δίπλα στο κουμπί στοίχημα. Η ασφάλιση κοστίζει 10% του συνολικού ποσού του στοίχηματος και θα επιστρέψει το ποσό του στοίχηματος στον παίκτη εάν η ρουκέτα εκραγεί με πολλαπλασιαστή 1,00.',
'insure_your_bet': 'ΑΣΦΑΛΙΣΤΕ ΤΟ ΣΤΟΙΧΗΜΑ ΣΑΣ',
'bet_insurance_promo_text': 'Επιλέξτε "Ασφάλιση στοιχήματος" για να ασφαλίσετε το στοίχημά σας από συντριβή πυραύλων με πολλαπλασιαστή 1,00.',
'bet_insurance_promo_text_helper': 'Διαβάστε περισσότερα στην ενότητα "Κανόνες παιχνιδιού"...',
'game_explanation_1': 'Επιλέξτε τον πολλαπλασιαστή σας και εάν ο πολλαπλασιαστής του παιχνιδιού ξεπεράσει τον πολλαπλασιαστή που έχετε επιλέξει, η νίκη σας θα είναι',
'game_explanation_2': 'Ο πολλαπλασιαστής σας x Το στοίχημά σας',
'retail_max_mp_1': 'Κερδίστε μέχρι',
'retail_max_mp_2': 'το στοίχημά σου!',
'play_multibets': 'Παίζω',
'multibets': 'MULTIBETS',
'bonus_game': 'ΠΑΙΧΝΙΔΙ ΜΠΟΝΟΥΣ',
'all_wins_doubled': 'Όλες οι νίκες διπλασιάστηκαν!',
'multibets_paragraph_1': 'για να αυξήσεις τις πιθανότητες σου!',
'multibets_paragraph_2': 'Μπορείτε να συνδυάσετε στοιχήματα σε έως και δέκα γύρους σε ένα μόνο multibet και να κερδίσετε τεράστιες πληρωμές!',
'multibets_paragraph_3': 'Κάθε φορά που κερδίζεται ένας γύρος, το κερδισμένο μέρισμα από αυτόν τον γύρο στοιχηματίζεται στον επόμενο γύρο, μέχρι να κερδηθούν όλοι οι γύροι.',
'bonus_launch': 'Κερδίστε x2 σε όλα τα στοιχήματα στον γύρο «Bonus Launch»!',
'multiplier_history': 'Ιστορία πολλαπλασιαστή',
'will_start_soon': 'θα ξεκινήσει σύντομα',
'hurry_up_and_place_your_bets': 'Βιαστείτε και τοποθετήστε τα στοιχήματά σας!',
'active_bets': 'Ενεργά στοιχήματα',
'shop': 'ΚΑΤΑΣΤΗΜΑ',
'game_meteor_shower_paragraph': 'Το Meteor Shower απονέμει ένα δωρεάν στοίχημα προκαθορισμένης αξίας σε έναν προκαθορισμένο αριθμό παικτών. Μπορεί να ξεκινήσει είτε από τον διακομιστή είτε από έναν παίκτη. Μόλις ενεργοποιηθεί το Meteor Shower, εμφανίζεται ένα μήνυμα στο παιχνίδι, κατευθύνοντας τους χρήστες να κοιτάξουν τη συνομιλία όπου βρίσκεται το μήνυμα Meteor Shower. Οι παίκτες μπορούν να διεκδικήσουν το δωρεάν στοίχημα κάνοντας κλικ στο κουμπί «Αξίωση». Τα δωρεάν στοιχήματα απονέμονται σε παίκτες που κάνουν πρώτοι κλικ στο κουμπί "Αξίωση". Η χρήση αυτόματων κλικ ή οποιουδήποτε είδους λογισμικού αυτοματισμού για την εξομοίωση κλικ παικτών και τη διεκδίκηση βραβείων απαγορεύεται και μπορεί να οδηγήσει σε απαγόρευση αυτής της δυνατότητας.',
'claming_failed': 'Η αξίωση απέτυχε',
'player_on_blacklist': 'Ο παίκτης είναι στη μαύρη λίστα',
'you_can_claim_ticket': 'Μπορείτε να διεκδικήσετε εισιτήριο μόνο εάν είστε ενεργός παίκτης με κανονικά στοιχήματα',
'you_already_have_free_tickets': 'Έχετε ήδη δωρεάν εισιτήρια, ξοδέψτε τα πρώτα',
'please_play_the_game_in_portait_mode': 'Παρακαλώ παίξτε το παιχνίδι σε κατακόρυφη λειτουργία.',
};
langArr.es = {
'your_insurance_returns_you': 'Tu seguro te devuelve',
'bet_insurance': 'Seguro de apuesta',
'max_bet_is': 'La apuesta máxima es',
'already_active_bet_of': 'y ya hay apuesta activa de',
'1_from_game_history': '‎1. Desde el historial de juego',
'2_in_settings_window': '‎2. En la ventana de ajustes',
'3_online_calculator': '‎3. Calculadora en línea',
'active_tournament_1': 'El torneo activo ',
'active_tournament_2': 'aún no tiene apuestas.',
'add_another_bet': 'Añadir otra apuesta',
'advanced_autoplay': 'JUEGO AUTOMÁTICO AVANZADO',
'advanced_autoplay_for_bet': 'Juego automático avanzado para apuestas',
'alien_saucer_free_bets': 'Apuestas gratis Platillo Volante',
'all_bets': 'Todas las apuestas',
'all_in_your_hands': '‎¡Todo está en tus manos!',
'allow': 'Permitir',
'at': '',
'austronaut_promo_credit': 'Crédito promocional Astronauta',
'auto_play_and_autocash': 'AUTOJUGADA Y AUTOCOBRO ',
'autoplay': 'Juego automático',
'autoplay_and_cashout': '‎02. Auto Jugada y auto cobro.',
'back_to_home': 'Volver al inicio',
'balance': 'Saldo',
'bet': 'APOSTAR',
'bet_amount': 'IMPORTE DE LA APUESTA',
'bet_and_cashout': '‎01. Apuesta y Cobro',
'bet_bets_tab': 'APUESTA',
'bet_failed': 'Apuesta fallida',
'bet_statistic': 'Estadística de apuestas',
'bet_time': 'Hora de la apuesta',
'bet_wins': 'Ganancias de las apuestas',
'bets': 'Apuestas',
'biggest_odds': 'Mayores probabilidades',
'bonus_type': 'TIPO DE BONO',
'bonusing_history': 'Historial de bonos',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Ganadores del Boosterpot',
'but_remember_paragraph': 'Pero recuerda, si no logras hacer el Cobro antes de que explote el cohete,',
'calculate_raw_mp_to_decimal': 'Calcule el Multiplicador Bruto a partir del decimal y redondéelo a la baja al entero más cercano',
'cancel': 'Cancelar',
'cant_post_media_content': 'No se puede publicar contenido multimedia con frecuencia',
'cashed_out': 'Cobrado',
'cashout': 'Cobrar',
'cashout_at': 'COBRAR CON',
'cashout_before': 'Cobra antes de que explote el Cohete, ¡y el dinero es tuyo!',
'change_avatar': 'Cambiar Avatar',
'change_username': 'Cambiar el nombre de usuario',
'characters_long': 'caracteres',
'chat': 'Chat',
'chat_room': 'Sala de chat',
'checking_for_spam': 'Comprobando correo no deseado...',
'close': 'Cerrar',
'coeff': 'COEF.',
'collect_win': 'Cobrar el premio',
'combined_seed': 'Cifrado combinado',
'company': 'Empresa',
'congratulations_you_have_won_free_bets': '‎¡Enhorabuena! ¡Has ganado apuestas extra! Puedes apostar',
'convert_first_13_to_decimal': ' Convierta los primeros 13 bytes a decimales',
'd': 'd',
'date': 'FECHA',
'date_and_time': 'Fecha y Hora',
'deny': 'Rechazar',
'detailed_game_rules': 'Descripción de las Reglas de Juego',
'dissconnection_policy': 'POLÍTICA DE DESCONEXIÓN',
'easy_as_one_two_three': 'Tan fácil como uno, dos, tres',
'enter_username': '',
'error_handling': 'TRATAMIENTO DEL ERROR',
'exit_full_screen': 'Salir de pantalla completa',
'first': 'primero',
'first_time_playing': '¿Es la primera vez que juegas a "Rocketman"?',
'folow_the_three_easy_steps': 'Sigue los tres sencillos pasos y ¡disfruta de Rocketman! ',
'free_bets': 'APUESTAS GRATIS',
'free_bets_desc': '¡Has ganado apuestas gratis! Puedes disfrutarlas en cualquier momento pulsando el botón de inicio.',
'free_bets_header': 'Apuestas gratis',
'from': 'DE',
'fullscreen_mode': 'Para mejorar la experiencia del usuario, el juego comenzará en modo pantalla completa.',
'function': 'Función',
'game_functions': 'Funciones del juego',
'game_history': 'HISTORIAL DE JUEGO',
'game_r_1_paragraph': 'El jugador puede activar la opción de autojugada y la apuesta se hará de forma automática para cada nueva ronda usando la cantidad apostada en la apuesta previa.',
'game_r_10_paragraph': 'Una vez concedido el crédito promocional, por cada apuesta que el jugador haga, se deducirá el dinero primero del crédito promocional hasta que se agote, antes de continuar cargando el dinero a la billetera del jugador.',
'game_r_11_paragraph': 'El crédito promocional no se puede cobrar, pero todas las ganancias en dichas apuestas se pagarán al jugador en su totalidad.',
'game_r_12_paragraph': 'El crédito promocional tiene una validez de 30 días tras lo cual caduca y ya no se puede usar.',
'game_r_13_paragraph': 'A un jugador se le pueden otorgar apuestas gratis de forma aleatoria. Aparecerá un gráfico en la pantalla informando al jugador sobre la concesión de las apuestas gratis, el número de apuestas y el importe de cada una.',
'game_r_14_paragraph': 'Una vez concedidas las apuestas gratis, el jugador puede elegir cuando empezar la ronda de la apuesta gratis. Ya comenzada la ronda de la apuesta gratis, se hace una apuesta automática con la cantidad especificada y el jugador decide el cobro en cualquier momento. Si el jugador se desconecta durante las apuestas gratis, estas se mantendrán y se ajustarán a la "Política de Desconexión".',
'game_r_15_paragraph': 'Las apuestas gratis tienen una validez de 30 días tras lo cual caducan y ya no se pueden usar.',
'game_r_16a_paragraph': 'El retorno teórico general al jugador es ',
'game_r_17_paragraph': 'Los porcentajes del RTP se vigilan continuamente y son verificados por terceras partes.',
'game_r_18_paragraph': 'Si se pierde la conexión después de hacer una apuesta, la apuesta no se cancelará. Si el multiplicador alcanza x20000, su apuesta se autocobrará y el crédito irá a su cuenta.',
'game_r_19_paragraph': 'Puedes chatear durante el juego con los demás jugadores. Escribe tu mensaje en el espacio del chat o comparte tu apuesta ganadora con otros jugadores.',
'game_r_2_paragraph': 'Se puede introducir un multiplicador de autocobro en el apartado "Autocobro"y al hacerlo, el juego efectuará el cobro automáticamente si se alcanza el multiplicador elegido.',
'game_r_20_paragraph': 'Los jugadores que hagan un mal uso del chat o insulten a otros jugadores usando un lenguaje inapropiado o grosero, no podrán usar el Chat.',
'game_r_21_paragraph': 'Rocketman tiene un diseño con una agradable música de fondo y efectos de sonido. Si desea desactivar la música y/o los efectos de sonido puede hacerlo en el menú desplegable.',
'game_r_22_paragraph': 'El jugador puede consultar sus últimas apuestas haciendo clic en el botón "Mis Apuestas". Para más información sobre el historial de juego (p. ej. periodos más largos) el jugador puede precisar el acceso al historial del jugador dentro de la propia plataforma del juego.',
'game_r_23_paragraph': 'En caso de producirse un error, puede que las rondas no empiecen a tiempo. Si eso ocurre, nuestro equipo de apoyo tratará de resolver el problema lo antes posible y de reanudar las rondas normales de juego. Si el problema se produce en la propia ronda de juego, se cancelarán todas las apuestas y el importe apostado se devolverá a los jugadores.',
'game_r_24_paragraph': 'La latencia de red es una parte ineludible de la conectividad a Internet. Consideramos que todas las apuestas y cobros son válidos solo en el momento en que llegan a nuestro servidor, independientemente de la duración de la latencia de red. ',
'game_r_3_paragraph': 'El jugador puede emplear teclas de acceso directo para hacer una apuesta o un cobro durante el juego. La asignación de teclas es',
'game_r_4_paragraph': 'El 1% de cada apuesta se dedica a un fondo de jackpot progresivo. Este fondo sirve al sistema de jackpot progresivo.',
'game_r_5_paragraph': ' ‎• Jackpot - Nivel más alto, premio mayor, menos frecuente',
'game_r_6_paragraph': ' ‎• Rocketpot – Nivel medio, premio mediano',
'game_r_7_paragraph': ' ‎• Boosterpot – Nivel más bajo, premio bajo, más frecuente ',
'game_r_8_paragraph': 'Todos los niveles de bote se otorgan usando un sistema de "Mistery" o "hay que ganar antes". El valor de cada bote "hay que ganar antes" se determina inmediatamente después de que se gane el bote precedente mediante un generador de números aleatorios y es almacenado y encriptado en la base de datos a distancia. El valor se encripta y por lo tanto está oculto y es deconocido. El bote lo gana el primer jugador cuya apuesta haga que el bote exceda el valor misterioso. El ganador del bote se anuncia una vez que termina la ronda.',
'game_r_8a_paragraph': 'Nota: El ganador del premio mayor solo puede ser un jugador de Rocketman que juegue activamente en la ronda en la que se haya activado el premio mayor. No hay posibilidad de que si el jugador ha ganado el premio mayor, tenga que compartirlo con cualquier otro jugador de Rocketman.',
'game_r_9_paragraph': 'A un jugador se le puede otorgar crédito promocional de manera aleatoria. Aparecerá un gráfico en la pantalla informando al jugador sobre la concesión del crédito promocional y su importe.',
'game_round': 'una partida!',
'game_rules': 'Reglas del juego',
'game_rules_1_paragraph': 'Rocketman es un juego de entretenimiento de nueva generación. ¡Gana muchas veces más en segundos! Rocketman se basa en un',
'game_rules_10_paragraph': ' La Autojugada se activa desde la pestaña “Auto” en el Panel de Apuestas, seleccionando “Autoapuesta”. Una vez activada, las apuestas se hacen de forma automática, pero para cobrar, debes pulsar el botón “cobrar” en cada partida. Si deseas que tu apuesta ',
'game_rules_11_paragraph': 'El Autocobro está disponible desde la pestaña de “Auto” en el panel de apuestas. Tras la activación, tu apuesta será cobrada de manera automática cuando llegue al coeficiente elegido.',
'game_rules_2_paragraph': '‎, que es hoy en día la única garantía real de honestidad en la industria del juego.',
'game_rules_3_paragraph': 'El multiplicador de premio empieza en 1x y aumenta a medida que el Cohete de la Suerte despega.',
'game_rules_4_paragraph': ' Tus ganancias son el multiplicador con el que efectuaste el cobro multiplicado por tu apuesta. ',
'game_rules_5_paragraph': 'Antes de empezar cada partida, nuestro generador de números aleatorios provably fair genera el coeficiente al que el Cohete de la Suerte saldrá volando. Puedes comprobar la integridad de esta generación haciendo clic enfrente del resultado en la pestaña d',
'game_rules_6_paragraph': 'Selecciona la cantidad y pulsa el botón “Apostar” para hacer una apuesta. ',
'game_rules_7_paragraph': 'Puedes hacer dos apuestas simultáneamente, añadiendo un panel de segunda apuesta. Para añadir un panel de segunda apuesta, pulsa el icono de más, situado en la esquina superior derecha del primer panel.',
'game_rules_8_paragraph': ' Pulsa el botón “Cobrar” para cobrar tus premios. Tu premio es la apuesta multiplicada por el coeficiente de cobro.',
'game_rules_9_paragraph': 'Pierdes la apuesta si no retiras el dinero antes de que el avión salga volando.',
'general_rules': 'NORMAS GENERALES',
'gifs': 'GIFS',
'go_back': 'Volver',
'good_job_buddy': 'Bien hecho, sigue así',
'h': 'h',
'hashed_in_sha_512': 'Basados en el valor resumen SHA512',
'hex': 'Hex.',
'hey_username': 'Hola,',
'hide_hex_to_decimal': 'Ocultar la conversión hexadecimal a decimal',
'highscore': 'Puntuación más alta',
'how_it_works': '‎¿Cómo funciona?',
'how_to_check': '‎¿Cómo comprobarla?',
'how_to_play': 'Cómo jugar',
'if_the_maximum_multiplier': '‎• Si se alcanza el multiplicador máximo de 20000, todas las apuestas abiertas se cobrarán automáticamente con el máximo multiplicador.',
'increase': 'Aumentar',
'input_bet_amount': 'Introduce la cantidad y haz tu apuesta antes del despegue.',
'insufficient_funds': 'Fondos insuficientes',
'it_blew_up': '‎¡Ha estallado! Oh, en fin…',
'jackpot': 'Bote',
'jackpot_winners': 'Ganadores del Jackpot',
'key': 'Tecla',
'load_more': 'CARGAR MÁS',
'loading': 'Cargando',
'loading_next_round': 'Cargando siguiente ronda…',
'loss_gifs': 'PÉRDIDA',
'lost_connection': 'Conexión Perdida',
'make_a_bet': 'Haz una apuesta',
'malfunction_voids': 'Un fallo de funcionamiento invalida todos los Pagos y las Jugadas.',
'maximum_payout': 'El pago máximo es ',
'menu': 'Menú',
'min_bet_is': 'La apuesta mínima es de',
'month': 'Mes',
'more_details': 'Más Detalles',
'more_information': 'Más información',
'multiplier': 'Multiplicador',
'multiplier_starts': '‎• El multiplicador empieza en 1 y llega hasta 20000',
'multipliers_larger': 'Los multiplicadores superiores a x20 000 están limitados al valor máximo del multiplicador',
'music_settings': 'Ajustes de música',
'my_bet_history': 'Mi historial de apuestas',
'my_bets': 'Mis apuestas',
'my_bonysing_history': 'Mi historial de bonos',
'network_latency': 'LATENCIA DE RED',
'network_latency_content': 'La latencia de red es una parte ineludible de la conectividad a Internet. Consideramos que todas las apuestas y cobros son válidos solo en el momento en que llegan a nuestro servidor, independientemente de la duración de la latencia de red.',
'network_latency_title': 'LATENCIA DE RED',
'no_thanks': '¡No, gracias!',
'not_right_now': 'Ahora no',
'off': 'off',
'oh_snap': '‎¡Oh, vaya!',
'ok_close': '‎¡De acuerdo, cerrar!',
'ok_thanks': 'De acuerdo. ¡Gracias!',
'on': 'on',
'on_loss': 'POR PÉRDIDA',
'on_win': 'POR GANANCIA',
'online': 'En línea',
'per_bet': 'por apuesta',
'pick_your_avatar_img': 'Escoge tu imagen de avatar',
'place_bet_with_last': 'Apostar con la última apuesta seleccionada ',
'play_now': 'Jugar Ahora',
'player_1_seed': 'Cifrado del jugador 1',
'player_2_seed': 'Cifrado del jugador 2',
'player_3_seed': 'Cifrado del jugador 3',
'please_wait_active_bets': 'Para activar las apuestas gratis, espera a que se completen las apuestas actuales.',
'points': 'PUNTOS',
'powered_by': 'Desarrollado por',
'previous_hand': 'Ronda anterior',
'prize': 'PREMIO',
'prizes': 'premios',
'progressive_jackpot': 'BOTE PROGRESIVO',
'promo_credit': 'Crédito promocional',
'promo_credit_condradulations': '‎¡Enhorabuena! ¡Has ganado crédito promocional astronómicamente bueno que puedes usar en el juego!',
'promo_credits': 'CRÉDITOS PROMOCIONALES',
'promo_history': 'Historial de Promociones',
'provably_fair': 'Demostrablemente justo',
'provably_fair_1_paragraph': 'Rocketman se basa en una tecnología criptográfica denominada “Provably Fair”. Esta tecnología garantiza un 100% de honestidad del resultado del juego. Con esta tecnología, es imposible que terceras partes interfieran en el proceso de juego.',
'provably_fair_2_paragraph': 'El resultado de cada partida (el coeficiente “Salir volando” del juego) no se genera en nuestros servidores. Se genera con la ayuda de los jugadores de la partida y es totalmente transparente. De esta forma, es imposible que alguien manipule el resultado ',
'provably_fair_3_paragraph': 'El resultado de la partida se genera a partir de cuatro participantes de la partida independientes.',
'provably_fair_4_paragraph': 'Al empezar la partida, el juego fusiona el cifrado del servidor con los cifrados de tres clientes. De los símbolos fusionados se genera la función resumen (HASH) SHA512, y de esta función el resultado del juego.',
'provably_fair_5_paragraph': 'Puedes comprobar la honestidad de cada partida desde el historial de juego, haciendo clic en el icono verde. En la ventana abierta podrás ver el cifrado del servidor, tres pares de cifrados de los jugadores, el valor resumen combinado y el resultado del j',
'provably_fair_6_paragraph': 'El cifrado del servidor para las siguientes partidas basado en el valor resumen está disponible con carácter público en la ventana de ajustes (en el menú del usuario, selecciona “Ajustes de Provably Fair” y luego “Siguiente cifrado del servidor SHA256”). ',
'provably_fair_7_paragraph': 'La corrección del valor resumen se puede comprobar en cualquier calculadora en línea, por ejemplo',
'provably_fair_system': 'sistema de provably fair',
'quick_explanation': 'Explicación rápida',
'randomly_generated_server_hash': 'Servidor HASH generado aleatoriamente',
'rank': 'RANGO',
'raw_multiplier': 'multiplicador bruto',
'raw_multiplier_to_edged': 'Multiplicador Bruto a Multiplicador Redondeado, utilizando el',
'remove_additional_bet': 'Eliminar apuesta adicional',
'reset': 'Reiniciar',
'return_to_player': 'RETORNO AL JUGADOR',
'rocketman_is_pure_excitement': '‎¡Rocketman es pura emoción! Arriesga y gana.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Ganadores del Rocketpot',
'round': 'Ronda',
'round_id': 'ID de la partida',
'round_multiplier': 'Multiplicador de la Ronda',
'round_results': 'resultados de la partida',
'rounds': 'rondas',
'rtp': 'RTP',
'rules': 'reglas',
'rules_details': 'Reglas del torneo',
'save': 'Guardar',
'schedule': 'programa',
'second': 'segundo',
'seconds_left': 's restantes',
'server_hash': 'HASH del servidor',
'please_wait_current_bets': 'ESPERE A QUE SE COMPLETEN LAS APUESTAS ACTUALES',
'min_autocashout_value_must_be': 'EL VALOR MÍNIMO DE COBRO AUTOMÁTICO DEBE SER',
'min_bet_value_must_be': 'EL VALOR MÍNIMO DE LA APUESTA DEBE SER',
'server_seed': 'Cifrado del servidor',
'session_dissconnected': 'Desconexión de la sesión',
'multisession_not_allowed': 'No se permite multisesión',
'set_auto_bet': 'CONFIGURAR AUTOAPUESTA',
'set_auto_cashout': 'CONFIGURAR AUTOCOBRO',
'shourtcut_keys': 'TECLAS DE ACCESO DIRECTO',
'show_hex_to_deimal': 'Mostrar la conversión hexadecimal a decimal',
'sound': 'SONIDO',
'sound_settigs': 'Ajustes de sonido',
'start_with_autoplay': 'Iniciar el juego automático',
'stop_autoplay': 'DETENER EL JUEGO AUTOMÁTICO',
'stop_on_loss': 'DETENER POR PÉRDIDA',
'stop_on_loss_tooltip': 'Utiliza Detener por Pérdida para detener la apuesta automática tras perder una cantidad determinada.<br/> Por ejemplo, si comienzas con 100 $ y estableces Detener la Pérdida en 25 $',
'stop_on_profit': 'DETENER POR GANANCIA',
'stop_on_profit_tooltip': 'Utiliza Detener por Ganancia para detener la apuesta automática tras obtener una cantidad determinada.<br/> Por ejemplo, si comienzas con 100 $ y estableces Detener la Ganancia en 25 $',
'there_are_no_active_torunament': 'En este momento no hay torneos activos.',
'third': 'tercero',
'this_game_is': 'El juego es',
'this_tool_recalculates': 'Esta herramienta recalcula el multiplicador desde el hash del juego. Puede copiar/pegar cualquier hash para verificar el multiplicador.',
'time': 'HORA',
'time_frame': 'Interval de tiempo',
'to': 'A',
'toggle_animation': 'Botón de Animación',
'top_odds': 'Máximas Probabilidades',
'top_wins': 'Máximos Premios',
'total_bets': 'Apuestas totales',
'tournament': 'Torneo',
'tournament_information': 'Información del torneo',
'try_advanced_autoplay': '¡Pruebe la nueva función avanzada de reproducción automática!',
'uncredited_wins': 'Los premios no ingresados',
'user': 'USUARIO',
'user_and_points': 'USUARIO Y PUNTOS',
'username': 'Nombre de usuario',
'username_must_be_at_least': 'El nombre de usuario debe tener al menos',
'verify': 'Verificar',
'vip_popup_text': '‎¡Puede elegir uno de los avatares VIP especiales disponibles en exclusiva para nuestros miembros VIP!',
'vip_popup_title': 'Felicidades, ya es jugador VIP',
'waiting_for_the_next_round': 'Esperando a la siguiente ronda...',
'warnings_are_calculated': ' ‎• Las ganancias se calculan multiplicando la apuesta por el multiplicador en el momento de producirse el Cobro',
'watch_take_off': 'Mira cómo despega tu Cohete al tiempo que el multiplicador de tus ganancias se incrementa.',
'watch_the_rocket': 'Mira el Cohete',
'we_have_noticed': 'Hemos notado que es la primera vez que juegas a "Rocketman". ¿Te gustaría ver un breve video explicativo sobre cómo jugar?',
'week': 'Semana',
'welcome_to_rocketman': '‎¡Bienvenido a Rocketman - un juego nuevo y apasionante! Con Rocketman tienes la oportunidad de ganar hasta x20000 tu apuesta original.',
'what_is_provably_fair': '‎¿Qué es Provably fair?',
'will_be_deposited': 'Se depositarán en el saldo de los jugadores en el primer premio regular',
'win': 'PREMIO',
'win_amount': 'Cantidad del premio',
'win_gifs': 'PREMIO',
'winner': 'ganador',
'wins': 'premios',
'write_a_replay': 'Escribir una respuesta...',
'year': 'Año',
'yes': 'SÍ',
'yes_start_the_round': 'Sí, empezar la partida.',
'you_have_cashed_out': 'Has cobrado, tu premio es ',
'you_have_won': 'HAS GANADO',
'your_bet_is_lost': 'habrás perdido la apuesta.',
'current': 'Actual',
'previous': 'Anterior',
'claim': 'Afirmar',
'this_feature_will_give': 'Esta característica dará una cantidad seleccionada a usuarios aleatorios en el chat.',
'amount_per_player': 'Cantidad por jugador',
'no_of_players': 'Numero de jugadores',
'meteor_shower': 'Lluvia de meteoros',
'claim_your_free_bet': 'Reclama tu apuesta gratis',
'meteor_shower_total': 'Total de lluvia de meteoritos',
'shower': 'LLUVIA',
'total': 'Total',
'meteor_shower_incoming': '¡Se acerca la lluvia de meteoritos, mira el chat!',
'insurance': 'SEGURO',
'insurance_paragraph': 'Los jugadores pueden comprar un seguro para su apuesta haciendo clic en la casilla "Seguro de Apuesta" al lado del botón de apuesta. El seguro cuesta el 10% del monto total de la apuesta y reembolsará el monto apostado al jugador si el cohete explota con un multiplicador de 1,00.',
'insure_your_bet': 'ASEGURA TU APUESTA',
'bet_insurance_promo_text': 'Seleccione la opción "Seguro de apuesta" para asegurar su apuesta contra la caída de un cohete con un multiplicador de 1,00.',
'bet_insurance_promo_text_helper': 'Lea más en la sección "Reglas del juego"...',
'game_explanation_1': 'Elija su multiplicador y, si el multiplicador del juego excede el multiplicador elegido, su ganancia será',
'game_explanation_2': 'Tu multiplicador x tu apuesta',
'retail_max_mp_1': 'Ganar hasta',
'retail_max_mp_2': '¡tu apuesta!',
'play_multibets': 'Jugar',
'multibets': 'MULTIAPUESTAS',
'bonus_game': 'JUEGO DE BONIFICACIÓN',
'all_wins_doubled': '¡Todas las ganancias se duplican!',
'multibets_paragraph_1': 'para aumentar tus probabilidades!',
'multibets_paragraph_2': '¡Puedes combinar apuestas de hasta diez rondas en una sola apuesta múltiple y ganar pagos masivos!',
'multibets_paragraph_3': 'Cada vez que se gana una ronda, el dividendo ganador de esa ronda se apuesta en la siguiente ronda, hasta que todas las rondas sean ganadoras.',
'bonus_launch': '¡Gana x2 en todas las apuestas en la ronda "Bonus Launch"!',
'multiplier_history': 'Historia del multiplicador',
'will_start_soon': 'empezara pronto',
'hurry_up_and_place_your_bets': '¡Date prisa y haz tus apuestas!',
'active_bets': 'Apuestas activas',
'shop': 'COMERCIO',
'game_meteor_shower_paragraph': 'Meteor Shower otorga una apuesta gratuita de un valor predefinido a un número predefinido de jugadores. Puede ser iniciado por el servidor o por un jugador. Una vez que se activa Meteor Shower, aparece un mensaje en el juego que dirige a los usuarios a mirar el chat donde se encuentra el mensaje de Meteor Shower. Los jugadores pueden reclamar la apuesta gratuita haciendo clic en el botón "Reclamar". Las apuestas gratuitas se otorgan a los jugadores que primero hacen clic en el botón "Reclamar". El uso de autoclickers o cualquier tipo de software de automatización para emular los clics de los jugadores y reclamar premios está prohibido y puede resultar en la prohibición de esta función.',
'claming_failed': 'Reclamación fallida',
'player_on_blacklist': 'El jugador está en la lista negra',
'you_can_claim_ticket': 'Puedes reclamar el boleto solo si eres un jugador activo con apuestas regulares',
'you_already_have_free_tickets': 'Ya tienes entradas gratis, gástalas primero.',
'please_play_the_game_in_portait_mode': 'Por favor, juega el juego en modo vertical.',
};
langArr.fr = {
'your_insurance_returns_you': 'Votre assurance vous rembourse',
'bet_insurance': 'Assurance pari',
'max_bet_is': 'La mise maximale est',
'already_active_bet_of': 'et il y a déjà un pari actif de',
'1_from_game_history': '‎1. À partir de l\'historique du jeu',
'2_in_settings_window': '‎2. Dans la fenêtre des paramètres',
'3_online_calculator': '‎3. Calculateur en ligne',
'active_tournament_1': 'Le tournoi actif ',
'active_tournament_2': 'ne compte encore aucun pari.',
'add_another_bet': 'Ajouter un autre pari',
'advanced_autoplay': 'JEU AUTOMATIQUE AVANCÉ',
'advanced_autoplay_for_bet': 'Jeu automatique avancé pour le pari',
'alien_saucer_free_bets': 'Paris gratuits Vaisseau extraterrestre',
'all_bets': 'Tous les paris',
'all_in_your_hands': 'Votre destin est entre vos mains !',
'allow': 'Autoriser',
'at': 'de',
'austronaut_promo_credit': 'Crédit promo Astronaute',
'auto_play_and_autocash': 'JEU AUTOMATIQUE ET ENCAISSEMENT AUTOMATIQUE ',
'autoplay': 'Jeu automatique',
'autoplay_and_cashout': '‎02. Jeu et Encaissement automatiques',
'back_to_home': 'Retour à la page d\'accueil',
'balance': 'Solde',
'bet': 'PARIER',
'bet_amount': 'MONTANT DE LA MISE',
'bet_and_cashout': '‎01. Parier et Encaisser',
'bet_bets_tab': 'MISE',
'bet_failed': 'Échec du pari',
'bet_statistic': 'Statistiques de paris',
'bet_time': 'Heure du pari ',
'bet_wins': 'Gains des paris',
'bets': 'Paris',
'biggest_odds': 'Cotes les plus élevées',
'bonus_type': 'TYPE DE BONUS',
'bonusing_history': 'Historique des bonus',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Gagnants du Boosterpot',
'but_remember_paragraph': 'Notez bien que si vous n\'avez pas eu le temps d\'encaisser avant que la fusée n\'explose,',
'calculate_raw_mp_to_decimal': 'Calculer le multiplicateur brut à partir de la décimale, et l’arrondir au nombre entier le plus proche',
'cancel': 'Annuler',
'cant_post_media_content': 'Impossible de publier du contenu multimédia fréquemment',
'cashed_out': 'Encaissé ',
'cashout': 'Encaisser',
'cashout_at': 'ENCAISSEMENT À',
'cashout_before': 'Encaissez avant que la fusée n\'explose et l\'argent est à vous !',
'change_avatar': 'Changer d\'avatar',
'change_username': 'Changer le nom d’utilisateur',
'characters_long': 'X caractères',
'chat': 'Discussion',
'chat_room': 'Forum de discussion',
'checking_for_spam': 'Recherche de spam…',
'close': 'Fermer',
'coeff': 'COEFF.',
'collect_win': 'Collecter les gains',
'combined_seed': 'Combinaison aléatoire combinée ',
'company': 'Société',
'congratulations_you_have_won_free_bets': 'Félicitations ! Vous avez gagné des paris bonus ! Vous pouvez parier',
'convert_first_13_to_decimal': ' Convertir les 13 premiers octets en décimal',
'd': 'j',
'date': 'DATE',
'date_and_time': 'Date et heure',
'deny': 'Refuser',
'detailed_game_rules': 'Règles détaillées du jeu',
'dissconnection_policy': 'POLITIQUE DE DÉCONNEXION',
'easy_as_one_two_three': 'Aussi facile que de compter jusqu\'à trois',
'enter_username': 'Veuillez saisir le nom d’utilisateur souhaité',
'error_handling': 'GESTION DES ERREURS',
'exit_full_screen': 'Quitter le mode plein écran',
'first': 'premier',
'first_time_playing': 'C’est la première fois que vous jouez à « Rocketman » ?',
'folow_the_three_easy_steps': 'Suivez simplement ces trois étapes et amusez-vous sur Rocketman ! ',
'free_bets': 'PARIS GRATUITS',
'free_bets_desc': 'Vous avez gagné des paris gratuits ! Vous pouvez profiter des paris gratuits à tout moment en appuyant sur le bouton Démarrer.',
'free_bets_header': 'Paris gratuits',
'from': 'DU',
'fullscreen_mode': 'Afin de garantir la meilleure expérience utilisateur, le jeu démarre en mode plein écran.',
'function': 'Fonction',
'game_functions': 'Fonctions du jeu',
'game_history': 'HISTORIQUE DE JEU',
'game_r_1_paragraph': 'Le joueur peut activer l\'option de jeu automatique lorsqu\'un pari est automatiquement placé pour chaque nouvelle partie en utilisant le montant du pari précédent.',
'game_r_10_paragraph': 'Une fois que les crédits promo auront été attribués, à chaque pari placé par un joueur, l\'argent sera d\'abord déduit des crédits promo (jusqu\'à ce qu\'ils soient tous utilisés), puis le retrait d\'argent se poursuivra à partir du portefeuille du joueur',
'game_r_11_paragraph': 'Les crédits promo ne peuvent pas être encaissés, mais tous les gains issus de ces paris seront versés au joueur en totalité.',
'game_r_12_paragraph': 'Les crédits promo sont valables pendant 30 jours ; après cette période, ils expirent et ne peuvent plus être utilisés.',
'game_r_13_paragraph': 'Un joueur peut se voir attribuer des paris gratuits de manière aléatoire. Un graphique apparaîtra dans une fenêtre contextuelle pour informer le joueur des paris gratuits attribués, du nombre de paris et du montant de chaque pari.',
'game_r_14_paragraph': 'Une fois que les paris gratuits ont été attribués, le joueur peut choisir quand démarrer la partie tours gratuits. Une fois que la partie tours gratuits a démarré, un pari automatique avec la mise spécifiée est placé, et c\'est au joueur de décider à quel',
'game_r_15_paragraph': 'Les paris gratuits sont valables pendant 30 jours ; après cette période, ils expirent et ne peuvent plus être utilisés.',
'game_r_16a_paragraph': 'Le retour théorique global au joueur est ',
'game_r_17_paragraph': 'Les pourcentages du TRJ sont surveillés en permanence et vérifiés par des tiers indépendants.',
'game_r_18_paragraph': 'Si la connexion est perdue après qu\'un pari a été placé, le pari ne sera pas annulé. Si le multiplicateur atteint 20 000x, votre pari sera automatiquement encaissé et les crédits seront ajoutés à votre compte.',
'game_r_19_paragraph': 'Vous pouvez discuter avec les autres joueurs pendant le jeu. Saisissez votre message dans le champ de chat, ou partagez votre pari gagnant avec d\'autres joueurs.',
'game_r_2_paragraph': 'Un multiplicateur d\'encaissement automatique peut être saisi dans le champ « Encaissement automatique » ; une fois saisi, le jeu encaissera automatiquement si le multiplicateur défini est atteint.',
'game_r_20_paragraph': 'Les joueurs qui utilisent le chat de manière abusive à l\'encontre d\'autres joueurs, ou utilisent un langage inapproprié et/ou grossier se verront retirer leurs privilèges du chat.',
'game_r_21_paragraph': 'Le jeu Rocketman a été conçu avec une magnifique musique de fond et un accent mis sur les effets sonores. Si vous souhaitez désactiver la musique et/ou les effets sonores, vous pouvez le faire via le menu déroulant.',
'game_r_22_paragraph': 'Le joueur a la possibilité de passer en revue ses derniers paris en cliquant sur le bouton « Mes paris ». Pour consulter davantage d\'historique de jeu (p. ex. sur de plus longues périodes), le joueur devra accéder à son historique au sein même de la plat',
'game_r_23_paragraph': 'En cas d\'erreur, il est possible que les parties ne démarrent pas à l\'heure. Si tel est le cas, notre équipe d\'assistance tentera de résoudre le problème dès que possible afin de reprendre le cours normal des parties. En cas de problème avec une partie',
'game_r_24_paragraph': 'La latence de réseau est inévitable dans le cadre de la connectivité Internet. Nous considérons tous les paris et encaissements comme valables uniquement au moment où ils atteignent notre serveur, peu importe la durée de latence du réseau. ',
'game_r_3_paragraph': 'Le joueur peut utiliser des touches de raccourci pour placer rapidement un pari ou encaisser pendant le jeu. Le mappage des touches est le suivant ',
'game_r_4_paragraph': '1% de chaque mise est dédié à un fonds de jackpot progressif. Ce fonds sert au système de jackpot progressif',
'game_r_5_paragraph': ' ‎• Jackpot - le niveau le plus élevé, le plus gros prix, le moins fréquent',
'game_r_6_paragraph': ' ‎• Rocketpot - le niveau moyen, prix moyen',
'game_r_7_paragraph': ' ‎• Boosterpot - le niveau le plus bas, petit prix, le plus fréquent ',
'game_r_8_paragraph': 'Tous les niveaux de jackpot sont attribués en utilisant un mécanisme « Mystère » ou « Doit payer à ». La valeur de chaque jackpot « Doit payer à » est déterminée immédiatement après que le précédent jackpot a été gagné par un générateur de nombre aléatoir',
'game_r_8a_paragraph': 'Remarque: le gagnant du jackpot ne peut être qu\'un seul joueur Rocketman jouant activement au cours du tour lorsque le jackpot a été déclenché. Il n\'est pas possible que si le joueur a gagné le jackpot, il doive le partager avec un autre joueur de Rocketman.',
'game_r_9_paragraph': 'Un joueur peut se voir attribuer des crédits promo de manière aléatoire. Un graphique apparaîtra dans une fenêtre contextuelle pour informer le joueur des crédits promo attribués et de leur montant.',
'game_round': 'partie de jeu !',
'game_rules': 'Règles du jeu',
'game_rules_1_paragraph': 'Rocketman est un jeu de divertissement de nouvelle génération. Quelques secondes suffisent pour gagner gros ! Rocketman est basé sur un',
'game_rules_10_paragraph': ' ‎• Le jeu automatique est activé à partir de l\'onglet « Auto » du panneau de pari, en cochant la case « Pari auto ». Une fois l\'option activée, les paris seront placés automatiquement, mais pour l\'encaissement, vous devrez appuyer sur « Encaisser » lo',
'game_rules_11_paragraph': '‎• L\'encaissement automatique est disponible à partir de l\'onglet « Auto » du panneau de pari. Une fois l\'option activée, votre pari sera automatiquement encaissé lorsque le coefficient saisi sera atteint.',
'game_rules_2_paragraph': '‎, lequel est à l\'heure actuelle la seule garantie réelle d\'honnêteté dans le secteur des jeux d\'argent.',
'game_rules_3_paragraph': '‎• Le multiplicateur de gain démarre à x1 et augmente de plus en plus au fur et à mesure que la fusée de la chance décolle.',
'game_rules_4_paragraph': ' ‎• Vos gains correspondent au multiplicateur auquel vous avez encaissé, multiplié par votre pari. ',
'game_rules_5_paragraph': '‎• Avant le début de chaque partie, notre générateur de nombre aléatoire à l\'équité prouvée génère le coefficient auquel la fusée de la chance va décoller. Vous pouvez vérifier l\'honnêteté de ce processus de génération en cliquant en face du résultat da',
'game_rules_6_paragraph': '‎• Sélectionnez un montant et appuyez sur le bouton « Parier » pour placer un pari. ',
'game_rules_7_paragraph': '‎• Vous pouvez placer deux paris simultanément en ajoutant un deuxième panneau de pari. Pour ajouter un deuxième panneau de pari, appuyez sur l\'icône « + » située dans le coin en haut à droite du premier panneau de pari.',
'game_rules_8_paragraph': ' ‎• Appuyez sur le bouton « Encaisser » pour encaisser vos gains. Votre gain correspond au pari multiplié par le coefficient d\'encaissement.',
'game_rules_9_paragraph': '‎• Votre pari est perdu si vous n\'avez pas encaissé avant que la fusée n\'explose.',
'general_rules': 'RÈGLES GÉNÉRALES',
'gifs': 'GIFS',
'go_back': 'Retour',
'good_job_buddy': 'Bon travail l’ami(e), continuez comme ça',
'h': 'h',
'hashed_in_sha_512': 'Hachée en SHA512',
'hex': 'Hex',
'hey_username': 'Salut',
'hide_hex_to_decimal': 'Masquer la conversion hex à décimal',
'highscore': 'Score élevé',
'how_it_works': 'Comment ça marche ?',
'how_to_check': 'Comment vérifier ?',
'how_to_play': 'Comment jouer',
'if_the_maximum_multiplier': '‎• Si le multiplicateur maximum de 20 000 est atteint, tous les paris ouverts sont automatiquement encaissés au multiplicateur maximum',
'increase': 'Augmenter',
'input_bet_amount': 'Saisissez le montant du pari et placez-le avant le décollage.',
'insufficient_funds': 'Fonds insuffisants',
'it_blew_up': 'Elle a explosé ! Tant pis...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Gagnants du Jackpot',
'key': 'Touche',
'load_more': 'CHARGER PLUS',
'loading': 'Chargement',
'loading_next_round': 'Chargement de la prochaine partie...',
'loss_gifs': 'PERDU',
'lost_connection': 'Connexion perdue',
'make_a_bet': 'Placez un pari',
'malfunction_voids': 'Tout dysfonctionnement annule tous les paiements et toutes les parties.',
'maximum_payout': 'Le paiement maximum est ',
'menu': 'Menu',
'min_bet_is': 'La mise min est de',
'month': 'Mois',
'more_details': 'Plus de détails',
'more_information': 'Plus d\'informations ',
'multiplier': 'Multiplicateur',
'multiplier_starts': '‎• Le multiplicateur démarre à 1 et va jusqu\'à 20 000',
'multipliers_larger': 'Les multiplicateurs supérieurs à x20 000 sont limités à la valeur max du multiplicateur',
'music_settings': 'Paramètres de la musique',
'my_bet_history': 'Historique de mes paris',
'my_bets': 'Mes paris',
'my_bonysing_history': 'Historique de mes bonus',
'network_latency': 'LATENCE DU RÉSEAU',
'network_latency_content': 'La latence de réseau est inévitable dans le cadre de la connectivité Internet. Nous considérons tous les paris et encaissements comme valables uniquement au moment où ils atteignent notre serveur, peu importe la durée de latence du réseau.',
'network_latency_title': 'LATENCE DU RÉSEAU',
'no_thanks': 'Non merci !',
'not_right_now': 'Pas maintenant',
'off': 'désactivé',
'oh_snap': 'Oh non !',
'ok_close': 'OK, fermer !',
'ok_thanks': 'OK, merci !',
'on': 'activé',
'on_loss': 'SUR UNE PERTE',
'on_win': 'SUR UN GAIN',
'online': 'En ligne',
'per_bet': 'par pari',
'pick_your_avatar_img': 'Choisissez une image pour votre avatar',
'place_bet_with_last': 'Placer un pari avec la dernière mise sélectionnée ',
'play_now': 'Jouer maintenant',
'player_1_seed': 'Combinaison aléatoire joueur 1 ',
'player_2_seed': 'Combinaison aléatoire joueur 2 ',
'player_3_seed': 'Combinaison aléatoire joueur 3 ',
'please_wait_active_bets': 'Veuillez attendre que les paris actuels soient terminés pour activer les paris gratuits',
'points': 'POINTS',
'powered_by': 'distribué par',
'previous_hand': 'Main précédente',
'prize': 'PRIX',
'prizes': 'prix',
'progressive_jackpot': 'JACKPOT PROGRESSIF',
'promo_credit': 'Crédit promo',
'promo_credit_condradulations': 'Félicitations ! Vous avez gagné un crédit promo d\'un montant astronomique à utiliser dans le jeu !',
'promo_credits': 'CRÉDITS PROMO',
'promo_history': 'Historique des promotions',
'provably_fair': 'Équité prouvée',
'provably_fair_1_paragraph': 'Rocketman est basé sur une technologie cryptographique appelée « Équité prouvée ». Cette technologie garantit que le résultat du jeu est 100 % équitable. Grâce à cette technologie, il est impossible pour un tiers d\'interférer dans le processus de jeu.',
'provably_fair_2_paragraph': 'Le résultat de chaque partie (le coefficient « Envol » du jeu) n\'est pas généré sur nos serveurs. Il est généré avec l\'aide des joueurs de la partie et est complètement transparent. De cette manière, il est impossible pour quiconque de manipuler le résu',
'provably_fair_3_paragraph': 'Le résultat de la partie est généré à partir de quatre participants indépendants à la partie',
'provably_fair_4_paragraph': 'Lorsque la partie commence, le jeu fusionne la combinaison aléatoire du serveur avec les combinaisons aléatoires de trois clients. Un code de hachage SHA512 est généré à partir de ces symboles fusionnés, puis le résultat du jeu est généré à partir de ce c',
'provably_fair_5_paragraph': 'Vous pouvez vérifier l\'équité de chaque partie à partir de l\'historique du jeu en cliquant sur l\'icône verte. Une fenêtre s\'ouvre alors, dans laquelle vous verrez la combinaison aléatoire du serveur, 3 paires de combinaisons aléatoires des joueurs, le',
'provably_fair_6_paragraph': 'La version hachée de la combinaison aléatoire du serveur pour les prochaines parties est disponible publiquement dans la fenêtre des paramètres (dans le menu Utilisateur, paramètre « Équité prouvée », puis « Prochaine combinaison aléatoire du serveur SHA2',
'provably_fair_7_paragraph': 'Vous pouvez vérifier l\'exactitude du hachage dans n\'importe quel calculateur en ligne, par exemple ',
'provably_fair_system': 'système d\'équité prouvée',
'quick_explanation': 'Explication rapide ',
'randomly_generated_server_hash': 'CODE DE HACHAGE de serveur généré aléatoirement',
'rank': 'RANG',
'raw_multiplier': 'multiplicateur brut',
'raw_multiplier_to_edged': 'Du multiplicateur brut au multiplicateur arrondi, en utilisant',
'remove_additional_bet': 'Supprimer le pari supplémentaire',
'reset': 'Réinitialiser',
'return_to_player': 'TAUX DE RETOUR AU JOUEUR',
'rocketman_is_pure_excitement': 'Rocketman, c\'est du divertissement à l\'état pur ! Prenez des risques et gagnez.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Gagnants du Rocketpot',
'round': 'Partie',
'round_id': 'ID de la partie ',
'round_multiplier': 'Multiplicateur de la partie',
'round_results': 'Résultats de la partie ',
'rounds': 'parties',
'rtp': 'le TRJ',
'rules': 'règles',
'rules_details': 'Règles du tournoi ',
'save': 'Enregistrer',
'schedule': 'programme',
'second': 'deuxième',
'seconds_left': 's restantes',
'server_hash': 'CODE DE HACHAGE du serveur',
'please_wait_current_bets': 'VEUILLEZ ATTENDRE QUE LES PARIS EN COURS SONT TERMINÉS',
'min_autocashout_value_must_be': 'LA VALEUR MINIMALE D\'AUTOCASHOUT DOIT ÊTRE',
'min_bet_value_must_be': 'LA VALEUR MINIMALE DE LA MISE DOIT ÊTRE',
'server_seed': 'Combinaison aléatoire serveur ',
'session_dissconnected': 'Session déconnectée',
'multisession_not_allowed': 'La multisession n\'est pas autorisée',
'set_auto_bet': 'CONFIGURER PARI AUTOMATIQUE',
'set_auto_cashout': 'CONFIGURER ENCAISSEMENT AUTOMATIQUE',
'shourtcut_keys': 'TOUCHES DE RACCOURCI',
'show_hex_to_deimal': 'Afficher la conversion hex à décimal',
'sound': 'SON',
'sound_settigs': 'Paramètres du son',
'start_with_autoplay': 'Commencer avec le jeu automatique',
'stop_autoplay': 'ARRÊTER LE JEU AUTOMATIQUE',
'stop_on_loss': 'ARRÊTER SUR UNE PERTE',
'stop_on_loss_tooltip': 'Utilisez la fonction Arrêter sur une perte pour arrêter le jeu automatique lorsque vous avez perdu un montant défini.<br/> Par exemple, si vous démarrez avec 100 $ et définissez Arrêter sur une perte à 25 $',
'stop_on_profit': 'ARRÊTER SUR UN PROFIT',
'stop_on_profit_tooltip': 'Utilisez la fonction Arrêter sur un profit pour arrêter le jeu automatique lorsque vous avez reçu un montant défini.<br/> Par exemple, si vous démarrez avec 100 $ et définissez Arrêter sur un profit à 25 $',
'there_are_no_active_torunament': 'Il n’y a aucun tournoi actif à l’heure actuelle.',
'third': 'troisième',
'this_game_is': 'Ce jeu est',
'this_tool_recalculates': 'Cet outil recalcule le multiplicateur pour le code de hachage du jeu. Vous pouvez copier/coller tout code de hachage du jeu afin de vérifier le multiplicateur.',
'time': 'HEURE',
'time_frame': 'Période ',
'to': 'AU',
'toggle_animation': 'Changer l\'animation',
'top_odds': 'Meilleures cotes',
'top_wins': 'Meilleurs gains',
'total_bets': 'Total des paris',
'tournament': 'Tournoi',
'tournament_information': 'Informations sur le tournoi',
'try_advanced_autoplay': 'Essayez la nouvelle fonctionnalité avancée de lecture automatique !',
'uncredited_wins': 'Gains non crédités',
'user': 'UTILISATEUR',
'user_and_points': 'UTILISATEUR ET POINTS',
'username': 'Nom d’utilisateur',
'username_must_be_at_least': 'Le nom d’utilisateur doit comporter au moins',
'verify': 'Vérifier',
'vip_popup_text': 'Vous pouvez choisir l’un des avatars VIP spéciaux exclusivement réservés à nos membres VIP !',
'vip_popup_title': 'Félicitations, vous êtes maintenant un joueur VIP',
'waiting_for_the_next_round': 'En attente de la partie suivante...',
'warnings_are_calculated': ' ‎• Les gains sont calculés en multipliant la mise par le multiplicateur au moment de l\'encaissement',
'watch_take_off': 'Regardez votre fusée décoller et le multiplicateur de gains augmenter.',
'watch_the_rocket': 'Gardez un œil sur la fusée',
'we_have_noticed': 'Nous avons remarqué que c’est la première fois que vous jouez à « Rocketman ». Souhaitez-vous visionner une courte vidéo expliquant comment jouer ?',
'week': 'Semaine',
'welcome_to_rocketman': 'Bienvenue dans Rocketman, un nouveau jeu passionnant ! Avec Rocketman, vous aurez une chance de gagner jusqu\'à 20 000x votre mise initiale.',
'what_is_provably_fair': 'Qu\'est-ce que l\'équité prouvée ?',
'will_be_deposited': 'Seront déposés sur le solde du joueur lors du premier gain normal',
'win': 'GAIN',
'win_amount': 'Montant du gain ',
'win_gifs': 'GAIN',
'winner': 'gagnant',
'wins': 'gains',
'write_a_replay': 'Écrire une réponse...',
'year': 'Année',
'yes': 'OUI',
'yes_start_the_round': 'Oui, démarrer la partie',
'you_have_cashed_out': 'Vous avez encaissé, votre gain est de ',
'you_have_won': 'VOUS AVEZ GAGNÉ',
'your_bet_is_lost': 'votre pari sera perdu.',
'current': 'Actuel',
'previous': 'Précédent',
'claim': 'Réclamer',
'this_feature_will_give': 'Cette fonctionnalité donnera le montant sélectionné à des utilisateurs aléatoires dans le chat',
'amount_per_player': 'Montant par joueur',
'no_of_players': 'Nombre de joueurs',
'meteor_shower': 'Pluie de météorites',
'claim_your_free_bet': 'Réclamez votre pari gratuit',
'meteor_shower_total': 'Pluie de météores Total',
'shower': 'PLUIE',
'total': 'Total',
'meteor_shower_incoming': 'Pluie de météores entrante, regardez le chat !',
'insurance': 'ASSURANCE',
'insurance_paragraph': 'Les joueurs peuvent acheter une assurance pour leur mise en cochant la case "Assurance de mise" à côté du bouton de mise. L\'assurance coûte 10% du montant total de la mise et remboursera le montant misé au joueur si la fusée explose avec un multiplicateur de 1,00.',
'insure_your_bet': 'ASSUREZ VOTRE PARI',
'bet_insurance_promo_text': 'Sélectionnez l\'option « Assurance pari » pour assurer votre pari contre l\'écrasement d\'une fusée avec un multiplicateur de 1,00.',
'bet_insurance_promo_text_helper': 'En savoir plus dans la section "Règles du jeu"...',
'game_explanation_1': 'Choisissez votre multiplicateur, et si le multiplicateur de jeu dépasse le multiplicateur choisi, votre gain sera',
'game_explanation_2': 'Votre multiplicateur x votre mise',
'retail_max_mp_1': 'Gagnez jusqu\'à',
'retail_max_mp_2': 'votre pari !',
'play_multibets': 'Jouer',
'multibets': 'PARIS MULTIPLES',
'bonus_game': 'JEU BONUS',
'all_wins_doubled': 'Tous les gains sont doublés !',
'multibets_paragraph_1': 'pour augmenter vos chances !',
'multibets_paragraph_2': 'Vous pouvez combiner des paris sur jusqu\'à dix tours en un seul multipari et gagner des gains massifs !',
'multibets_paragraph_3': 'Chaque fois qu\'un tour est gagné, les dividendes gagnants de ce tour sont misés sur le tour suivant, jusqu\'à ce que tous les tours soient gagnants.',
'bonus_launch': 'Gagnez x2 sur tous les paris lors du tour « Bonus Launch » !',
'multiplier_history': 'Historique du multiplicateur',
'will_start_soon': 'va bientôt commencer',
'hurry_up_and_place_your_bets': 'Dépêchez-vous et placez vos paris !',
'active_bets': 'Paris actifs',
'shop': 'BOUTIQUE',
'game_meteor_shower_paragraph': 'Meteor Shower attribue un pari gratuit d\'une valeur prédéfinie à un nombre prédéfini de joueurs. Il peut être initié soit par le serveur, soit par un joueur. Une fois Meteor Shower activé, un message apparaît dans le jeu, invitant les utilisateurs à consulter le chat où se trouve le message Meteor Shower. Les joueurs peuvent réclamer le pari gratuit en cliquant sur le bouton « Réclamer ». Les paris gratuits sont attribués aux joueurs qui cliquent en premier sur le bouton « Réclamer ». L\'utilisation d\'autoclickers ou de tout type de logiciel d\'automatisation pour émuler les clics des joueurs et réclamer des prix est interdite et peut entraîner l\'interdiction de cette fonctionnalité.',
'claming_failed': 'La réclamation a échoué',
'player_on_blacklist': 'Le joueur est sur la liste noire',
'you_can_claim_ticket': 'Vous ne pouvez réclamer un ticket que si vous êtes un joueur actif avec des paris réguliers',
'you_already_have_free_tickets': 'Vous avez déjà des billets gratuits, veuillez d\'abord les dépenser',
'please_play_the_game_in_portait_mode': 'Veuillez jouer au jeu en mode portrait.',
};
langArr.he = {
'your_insurance_returns_you': 'הביטוח שלך מחזיר אותך',
'bet_insurance': 'ביטוח הימור',
'max_bet_is': 'הימור מקסימלי הוא',
'already_active_bet_of': 'וכבר יש הימור פעיל של',
'1_from_game_history': '‎1.‎ ‎מהיסטורית המשחק',
'2_in_settings_window': '‎2.‎ ‎בחלון ההגדרות',
'3_online_calculator': '‎3.‎ ‎מחשבון מקוון',
'active_tournament_1': 'טורניר פעיל ',
'active_tournament_2': 'אין עדיין הימורים.',
'add_another_bet': 'הוסף עוד הימור',
'advanced_autoplay': 'משחק אוטומטי מתקדם',
'advanced_autoplay_for_bet': 'משחק אוטומטי מתקדם להימור',
'alien_saucer_free_bets': 'הימורי הצלחת המעופפת חינם‎',
'all_bets': 'כל ההימורים',
'all_in_your_hands': 'הכל בידייך‎!',
'allow': 'אפשר',
'at': 'בשעה',
'austronaut_promo_credit': 'קרדיט מבצע אסטרונאוט',
'auto_play_and_autocash': 'משחק אוטומטי ומשיכת הימור אוטומטית ',
'autoplay': 'משחק אוטומטי',
'autoplay_and_cashout': '‎02.‎ ‎משחק אוטומטי ומשיכת רווחים אוטומטית',
'back_to_home': 'בחזרה לעמוד הבית',
'balance': 'יתרה',
'bet': 'המר',
'bet_amount': 'סכום הימור',
'bet_and_cashout': '‎01.‎ ‎הימור ומשיכת רווחים',
'bet_bets_tab': 'סכום הימור',
'bet_failed': 'ההימור נכשל',
'bet_statistic': 'סטטיסטיקת הימורים',
'bet_time': 'זמן הימור‎',
'bet_wins': 'זכיות מהימורים',
'bets': 'הימורים',
'biggest_odds': 'היחסים הכי גדולים',
'bonus_type': 'סוג בונוס',
'bonusing_history': 'היסטוריית בונוסים',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'הזוכים ב-Boosterpot',
'but_remember_paragraph': 'שים לב, אם לא הספקת למשוך את זכייתך לפני שהרקטה מתפוצצת,',
'calculate_raw_mp_to_decimal': 'אנו מחשבים את המכפיל הגולמי מהנקודה העשרונית ומעגלים אותו למספר השלם הקרוב ביותר',
'cancel': 'בטל',
'cant_post_media_content': 'לא יכול לפרסם מדיה באופן תדיר',
'cashed_out': 'רווח שנמשך‎',
'cashout': 'משיכת רווחים',
'cashout_at': 'תשלום ב',
'cashout_before': 'משוך את הזכיה שלך לפני שהרקטה מתפוצצת, והכסף יהיה שלך!',
'change_avatar': 'החלף אווטאר',
'change_username': 'החלף שם משתמש',
'characters_long': 'באורך x תווים לפחות',
'chat': 'צ\'ט',
'chat_room': 'חדר צ\'ט',
'checking_for_spam': 'מחפש ספאם...',
'close': 'סגור',
'coeff': 'מקדם',
'collect_win': 'קח את הזכיה',
'combined_seed': 'גרעין משולב‎',
'company': 'חברה',
'congratulations_you_have_won_free_bets': 'איחולינו! זכית בהימורי בונוס! אתה יכול להמר',
'convert_first_13_to_decimal': ' להמיר את 13 הבייטים הראשונים לנקודות עשרוניות',
'd': 'ימים',
'date': 'תאריך',
'date_and_time': 'תאריך ושעה',
'deny': 'מנע',
'detailed_game_rules': 'כללי משחק מפורטים',
'dissconnection_policy': 'מדיניות ניתוק',
'easy_as_one_two_three': 'קל כמו "אחת, שתיים, שלוש',
'enter_username': 'הזן בבקשה שם משתמש רצוי',
'error_handling': 'טיפול בתקלות',
'exit_full_screen': 'צא ממצב מסך מלא',
'first': 'ראשון',
'first_time_playing': 'משחק "Rocketman" פעם ראשונה?',
'folow_the_three_easy_steps': 'בצע את שלושת הצעדים הפשוטים ותהנה מ"Rocketman"! ',
'free_bets': 'הימורים חינם',
'free_bets_desc': 'זכית בהימורים חינם! אתה יכול לנצל את הימורים חינם בכל עת על ידי הקשה על הלחצן "התחל',
'free_bets_header': 'הימורים חינם',
'from': 'מ',
'fullscreen_mode': 'לשיפור חוויית המשתמש, המשחק יתחיל במצב מסך מלא.',
'function': 'פונקציה',
'game_functions': 'פעולות במשחק',
'game_history': 'היסטוריית משחק',
'game_r_1_paragraph': 'ניתן להפעיל את אפשרות המשחק האוטומטי, בה מבוצע הימור באופן אוטומטי על כל סיבוב חדש, עם סכום ההימור האחרון שבוצע.',
'game_r_10_paragraph': 'לאחר זיכוי האשראי, עבור כל הימור שהשחקן מבצע, הכסף ינוכה מאשראי הפרומו עד שזה יתאפס, ורק אז ימשיך לצאת כסף מארנקו של השחקן.',
'game_r_11_paragraph': 'לא ניתן למשוך את אשראי הפרומו, אבל כל זכיה בגינו מוענקת לשחקן במלואה.',
'game_r_12_paragraph': 'אשראי פרומו תקף ל-30 ימים, ולאחר מכן פג תקפו ולא ניתן להשתמש בו.',
'game_r_13_paragraph': 'שחקן עשוי לזכות באקראי בהימורים חינם.  חלון צץ יופיע על המסך ויעדכן את השחקן אודות ההימורים חינם, מספר ההימורים והסכום של כל אחד.',
'game_r_14_paragraph': 'לאחר שמוענקים הימורים חינם, השחקן רשאי לבחור מתי להתחיל סיבוב של הימור חינם.  כשמתחיל סיבוב הימור חינם, מבוצע הימור אוטומטי על הסכום המצוין, ובידי השחקן ניתנת ההחלטה מתי למשוך את ההימור.  אם השחקן מתנתק במהלך הימור חינם, הימורים חינם ימשיכו להתקיים, והם י',
'game_r_15_paragraph': 'הימורים חינם תקפים ל-30 ימים, ולאחר מכן פג תקפם ולא ניתן להשתמש בהם.',
'game_r_16a_paragraph': 'החזרה התיאורטית הכוללת לשחקן היא ',
'game_r_17_paragraph': 'אחוזי RTP מבוקרים באופן שוטף, ומאומתים על ידי צדדים שלישיים בלתי תלויים.',
'game_r_18_paragraph': 'אם הקשר ניתק לאחר ביצוע הימור, ההימור לא יבוטל.  אם המכפיל מגיע ל-x‏20.000 ההימור שלך ייפדה אוטומטית ואשראי יזוכה לחשבונך.',
'game_r_19_paragraph': 'אתה יכול לצ\'טט עם שחקנים אחרים בזמן המשחק.  הזן את ההודעה שלך בשדה בצ\'ט, או שתף א ת ההימור הזוכה שלך עם שחקנים אחרים.',
'game_r_2_paragraph': 'ניתן להזין ערך מכפיל למשיכת ההימור בשדה "משיכה אוטומטית", ואז המשחק ימשוך את ההימור שלך אוטומטית כשהמכפיל יגיע לערך שנקבע.',
'game_r_20_paragraph': 'שחקנים העושים שימוש בלתי ראוי בצ\'ט, תוך פגיעה בשחקנים אחרים, או שימוש בלשון מגונה או שפה לא הולמת, יאבדו את זכות השימוש בצ\'ט.',
'game_r_21_paragraph': 'Rocketman" מעוצב עם מוסיקת רקע נעימה ואפקטים קוליים.  אם ברצונך להפסיק את המוסיקה או את האפקטים הקוליים, ניתן לעשות זאת בתפריט הגלילה.',
'game_r_22_paragraph': 'על ידי הקשה על הלחצן "ההימורים שלי", השחקן יכול לראות את ההימורים האחרונים שלו.  כדי לראות יותר היסטוריית הימורים (למשל, פרקי זמן ארוכים יותר), השחקן יצטרך לגשת להיסטוריית השחקן בפלטפורמת ההימורים עצמה.',
'game_r_23_paragraph': 'במקרה של תקלה, ייתכן וסיבובים לא יתחילו בזמן.  אם זה קורה, צוות התמיכה שלנו ינסה לפתור את הבעיה הכי מהר שניתן, ולהתחיל מחדש  את סיבובי המשחק.  במקרה של תקלה בסיבוב המשחק עצמו, כל ההימורים יבוטלו וסכומי ההימורים יוחזרו לשחקנים.',
'game_r_24_paragraph': 'העיכוב הרשתי הוא חלק בלתי נמנע של החיבור לאינטרנט. אנו מחשיבים את כל ההימורים והתשלומים כתקפים רק מהרגע שהם מגיעים לשרת שלנו, ללא קשר למשך העיכוב הרשתי. ',
'game_r_3_paragraph': 'ניתן להשתמש במקשי קיצור דרך, כדי לבצע הימור מהיר או משיכת הימור מהירה במהלך המשחק.  המקשים ממופים כדלהלן',
'game_r_4_paragraph': '1% מכל הימור מוקדש לקרן ג\'קפוט פרוגרסיבי. קרן זו משמשת את מערכת הג\'קפוט הפרוגרסיבית',
'game_r_5_paragraph': ' Jackpot – הרמה הכי גבוהה, הפרס הכי גדול, הכי נדיר',
'game_r_6_paragraph': ' Rocketpot – רמה אמצעית, פרס בינוני',
'game_r_7_paragraph': ' Boosterpot – הרמה הכי נמוכה, פרס קטן, הכי תדיר ',
'game_r_8_paragraph': 'כל הג\'קפוטים מוענקים לפי מכניקת "מסתורין" או "צריך להגיע ל...".  הערך של כל אחד מ-"צריך להגיע ל..." נקבע על ידי מחולל מספרים אקראי מיידית לאחר הזכיה בג\'קפוט הקודם, ומאוחסן כערך מוצפן במסד נתונים מרוחק.  הערך מוצפן, ולכן נסתר ובלתי ידוע.  השחקן הראשון, ש',
'game_r_8a_paragraph': 'הערה: הזוכה בג\'קפוט יכול להיות רק שחקן רוקטמן בודד אחד שמשחק פעיל בסבב כאשר הקופה הופעל. אין אפשרות שאם השחקן זכה בקופה, יצטרך לחלוק אותו עם כל שחקן אחר של רוקטמן.',
'game_r_9_paragraph': 'שחקן עשוי לזכות באקראי באשראי פרומו.  חלון צץ יופיע על המסך ויעדכן את השחקן אודות האשראי והסכום בו הוא זכה.',
'game_round': 'סיבוב משחק‎!',
'game_rules': 'כללי המשחק',
'game_rules_1_paragraph': 'Rocketman הוא הימור-בידור מדור חדש‎. ‎זכה בהרבה יותר בתוך שניות!‎ Rocketman ‎בנוי על',
'game_rules_10_paragraph': ' ‎• ‎המשחק האוטומטי מופעל בלשונית‎ ‎ה‎"‎אוטומט"‎ ‎בפאנל ההימורים, על ידי סימון התיבה‎ ‎"הימור‎ ‎אוטומטי".‎ ‎לאחר ההפעלה, הימורים‎ ‎יבוצעו באופן אוטומטי, אבל כדי למשוך רווחים עליך להקיש ידנית על הלחצן‎ ‎"משיכת רווחים"‎ ‎בכל סיבוב.‎ ‎אם‎ ‎אתה רוצה שההימור י',
'game_rules_11_paragraph': '‎•‎‎משיכת רווחים אוטומטית זמינה בלשונית‎ ‎"אוטומטי"‎ ‎בפאנל ההימורים. לאחר הפעלתה, ההימור שלך יימשך אוטומטית כשהרקטה תגיע למקדם שהזנת‎.',
'game_rules_2_paragraph': ', שכרגע משמשת ככלי היחיד להבטחת כנות‎ ‎בתעשיית ההימורים‎.',
'game_rules_3_paragraph': '‎•‎‎מכפיל הזכייה מתחיל ב-x1 וגדל עוד ועוד‎ ‎כשהרקטה ממריאה‎.',
'game_rules_4_paragraph': ' ‎• ‎הזכייה שלך היא המכפיל ברגע שביצעת משיכת‎ ‎רווחים, כפול סכום ההימור שלך‎. ',
'game_rules_5_paragraph': '‎•‎‎לפני תחילת הסיבוב, מחולל המספרים‎ ‎האקראיים שלנו (ההוגן באופן יכיח) מייצר את המקדם שבו רקטת המזל תתפוצץ. אתה יכול לבדוק את אמינות של יצירת מספר זה‎ ‎על ידי הקשה על התוצאה הנגדית בלשונית‎ ‎"היסטוריה".',
'game_rules_6_paragraph': '‎• ‎בחר סכום והקש על הלחצן‎ ‎"המר"‎. ',
'game_rules_7_paragraph': '‎•‎אתה יכול לבצע שני הימורים בו-זמנית, על‎ ‎ידי הוספת פאנל הימורים שני‎. ‎כדי להוסיף‎ ‎את הפאנל השני, הקש על סמליל ה‎"‎פלוס",‎ ‎הממוקם בפינה העליונה של פאנל‎ ‎ההימורים הראשון‎.',
'game_rules_8_paragraph': ' ‎•‎.‎ ‎הקש על הלחצן‎ ‎"משיכת רווחים"‎ ‎כדי למשוך את הזכייה שלך.‎ ‎הזכייה שלך היא‎ ‎סכום ההימור כפול המקדם בעת משיכת הרווחים‎.',
'game_rules_9_paragraph': '‎•‎אם לא משכת את הרווחים לפני שהרקטה‎ ‎מתפוצצת, אתה מפסיד את סכום ההימור שלך‎.',
'general_rules': 'כללים כלליים',
'gifs': 'GIFS',
'go_back': 'חזור',
'good_job_buddy': 'עבודה יפה, חבר.  תמשיך ככה',
'h': 'שעות',
'hashed_in_sha_512': 'קוד ‎ SHA512',
'hex': 'הקס',
'hey_username': 'היי',
'hide_hex_to_decimal': 'הסתר המרת הקס לנקודה עשרונית',
'highscore': 'ניקוד גבוה',
'how_it_works': 'איך זה עובד‎?',
'how_to_check': 'איך לבדוק?',
'how_to_play': 'איך לשחק',
'if_the_maximum_multiplier': 'אם המכפיל מגיע למקסימום של 20.000, כל ההימורים הפתוחים משולמים אוטומטית לפי המכפיל המקסימלי',
'increase': 'הגדל',
'input_bet_amount': 'הזן סכום הימור ובצע את ההימור שלך לפני‎ ‎ההמראה‎.',
'insufficient_funds': 'אין מספיק כסף',
'it_blew_up': 'הרקטה התפוצצה! נו, מילא...',
'jackpot': 'ג\'קפוט',
'jackpot_winners': 'הזוכים ב-Jackpot',
'key': 'מקש',
'load_more': 'טען עוד',
'loading': 'טוען',
'loading_next_round': 'טוען את הסיבוב הבא',
'loss_gifs': 'הפסדת',
'lost_connection': 'אבד הקשר',
'make_a_bet': 'בצע הימור',
'malfunction_voids': 'תקלה מבטלת את כל התשלומים וההצגות.',
'maximum_payout': 'התשלום המקסימלי הוא',
'menu': 'תפריט',
'min_bet_is': 'ההימור‎ ‎המינימלי הוא',
'month': 'חודש',
'more_details': 'פרטים נוספים',
'more_information': 'מידע נוסף‎',
'multiplier': 'מכפיל',
'multiplier_starts': 'המכפיל מתחיל מ-1 ועולה עד 20.000',
'multipliers_larger': 'מכפילים הגדולים מ-20.000‏x לא יכולים לעלות על ערך המכפיל המקסימלי',
'music_settings': 'הגדרות מוסיקה',
'my_bet_history': 'היסטוריית ההימורים שלי',
'my_bets': 'ההימורים שלי',
'my_bonysing_history': 'היסטוריית הבונוסים שלי',
'network_latency': 'עיכוב רשתי',
'network_latency_content': 'העיכוב הרשתי הוא חלק בלתי נמנע של החיבור לאינטרנט. אנו מחשיבים את כל ההימורים והתשלומים כתקפים רק מהרגע שהם מגיעים לשרת שלנו, ללא קשר למשך העיכוב הרשתי.',
'network_latency_title': 'עיכוב רשתי',
'no_thanks': 'לא, תודה!',
'not_right_now': 'לא כרגע',
'off': 'מופסק',
'oh_snap': 'אוף, באסה!',
'ok_close': 'אוקיי, לסגור!',
'ok_thanks': 'אוקיי, תודה‎!',
'on': 'מופעל',
'on_loss': 'בהפסד',
'on_win': 'בניצחון',
'online': 'מקוון',
'per_bet': 'להימור',
'pick_your_avatar_img': 'בחר תמונת אווטאר',
'place_bet_with_last': 'בצע הימור עם סכום ההימור אחרון שנבחר ',
'play_now': 'שחק עכשיו',
'player_1_seed': 'גרעין שחקן 1‏‎',
'player_2_seed': 'גרעין שחקן 2‏‎',
'player_3_seed': 'גרעין שחקן 3‏‎',
'please_wait_active_bets': 'המתן, בבקשה, עד שההימורים הנוכחיים יושלמו, כדי להפעיל הימורים חינם',
'points': 'נקודות',
'powered_by': 'מונע על ידי',
'previous_hand': 'היד הקודמת',
'prize': 'פרס',
'prizes': 'פרסים',
'progressive_jackpot': 'ג\'קפוט מצטבר',
'promo_credit': 'זיכוי פרומו',
'promo_credit_condradulations': 'איחולינו‎ ‎זכית בקרדיט מבצע אסטרונומי, לשימוש במשחק‎!',
'promo_credits': 'אשראי פרומו',
'promo_history': 'היסטוריית פרומו',
'provably_fair': 'הוגן באופן יכיח',
'provably_fair_1_paragraph': 'המשחק‎ Rocketman ‎מבוסס על טכנולוגית הצפנה‎ ‎הנקראת‎ ‎"הוגן באופן יכיח"‎. ‎טכנולוגיה זו מבטיחה %100 הגינות של תוצאות המשחק. עם טכנולוגיה זו, צד שלישי לא יכול להתערב‎ ‎בתהליך‎.',
'provably_fair_2_paragraph': 'תוצאת כל משחק (המקדם של המשחק‎ ("Fly Away" ‎לא נוצרת בשרתים שלנו.‎ ‎היא‎ ‎נוצרת בעזרת השחקנים בסיבוב, והיא שקופה לחלוטין‎. ‎כך שאף אחד לא יכול להשפיע על תוצאת המשחק.‎ ‎בנוסף, כל אחד יכול לבדוק ולאשר את הגינות‎ ‎המשחק‎.',
'provably_fair_3_paragraph': 'תוצאת הסיבוב נוצרת מארבעה משתתפים בלתי‎ ‎תלוים בסיבוב',
'provably_fair_4_paragraph': 'כשהסיבוב מתחיל, המשחק משלב גרעין מהשרת‎ ‎עם שלושה גרעינים מלקוחות‎. ‎מארבעת‎ ‎הסמלים המשולבים נוצר קוד‎ SHA512, ‎ ‎ומקוד זה מחושבת תוצאת המשחק‎.',
'provably_fair_5_paragraph': 'אתה יכול לבדוק את הגינות כל סיבוב‎ ‎מהיסטוריית המשחק, על ידי הקשה על הלחצן הירוק‎. ‎בחלון שייפתח תראה את גרעין השרת, 3 גרעיני שחקנים, קוד‎ SHA512 ‎משולב‎ ‎ותוצאת הסיבוב‎.',
'provably_fair_6_paragraph': 'גרסה מקודדת של גרעין השרת לסיבובים הבאים‎ ‎מופיעה בפומבי בחלון ההגדרות (בתפריט המשתמש, בדוק "הגדרות הוגנות‎ ‎יכיחות"‎, ‎ואז‎ ‎"גרעין שרת‎ SHA256 ‎הבא")‎. ‎כאן אתה גם יכול לשנות את גרעין הלקוח שלך‎. ‎אם אתה רוצה להשתתף ביצירת תוצאת הסיבוב‎, ‎עליך לוודא שאת',
'provably_fair_7_paragraph': 'ניתן לבדוק את אמיתות הקוד בכל מחשבון‎ ‎מקוון, למשל‎',
'provably_fair_system': 'מערכת הוגנת באופן יכיח',
'quick_explanation': 'הסבר מהיר',
'randomly_generated_server_hash': 'שרת HASH מופק באופן אקראי',
'rank': 'דרגה',
'raw_multiplier': 'מכפיל גולמי',
'raw_multiplier_to_edged': 'מכפיל גולמי למכפיל קצה, באמצעות',
'remove_additional_bet': 'הסר הימור נוסף',
'reset': 'אתחל',
'return_to_player': 'תשואה לשחקן',
'rocketman_is_pure_excitement': 'Rocketman הוא ריגוש טהור! הסתכן ותזכה.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'הזוכים ב-Rocketpot',
'round': 'סיבוב ',
'round_id': 'זיהוי סיבוב‎',
'round_multiplier': 'מכפיל הסיבוב',
'round_results': 'תוצאת סיבוב‎',
'rounds': 'סיבובים',
'rtp': 'RTP',
'rules': 'חוקים',
'rules_details': 'כללי הטורניר',
'save': 'שמור',
'schedule': 'לוח זמנים',
'second': 'שני',
'seconds_left': 'עוד שנ',
'server_hash': 'קוד שרת',
'please_wait_current_bets': 'אנא המתן עד להשלמת ההימורים הנוכחיים',
'min_autocashout_value_must_be': 'ערך מזומן אוטומטי מינימלי חייב להיות',
'min_bet_value_must_be': 'ערך הימור מינימלי חייב להיות',
'server_seed': 'גרעין השרת‎',
'session_dissconnected': 'המפגש נותק',
'multisession_not_allowed': 'ריבוי הפעלות אסור',
'set_auto_bet': 'הגדר הימור אוטומטי',
'set_auto_cashout': 'הגדר משיכת רווחים אוטומטית',
'shourtcut_keys': 'מקשי קיצור דרך',
'show_hex_to_deimal': 'הצג המרת הקס לנקודה עשרונית',
'sound': 'קול',
'sound_settigs': 'הגדרות קול',
'start_with_autoplay': 'התחל במשחק אוטומטי',
'stop_autoplay': 'עצור משחק אוטומטי',
'stop_on_loss': 'עצור בהפסד',
'stop_on_loss_tooltip': 'השתמש ב"עצור בהפסד" כדי לעצור את ההימור האוטומטי לאחר שהפסדת סכום מסוים.<br/>לדוגמה, אם התחלת עם 100 דולר וקבעת "עצור בהפסד" ב-25 דולר',
'stop_on_profit': 'עצור ברווח',
'stop_on_profit_tooltip': 'השתמש ב"עצור ברווח" כדי לעצור את ההימור האוטומטי לאחר שהרווחת סכום מסוים.<br/>לדוגמה, אם התחלת עם 100 דולר וקבעת "עצור ברווח" ב-25 דולר',
'there_are_no_active_torunament': 'אין טורנירים פעילים כרגע.',
'third': 'שלישי',
'this_game_is': 'משחק זה',
'this_tool_recalculates': 'מכשיר זה מחשב מחדש את המכפיל מקוד המשחק.  אתה יכול להעתיק/להדביק קוד האש של כל משחק שהוא, כדי לאמת את המכפיל.',
'time': 'שעה',
'time_frame': 'מסגרת זמן‎',
'to': 'עד',
'toggle_animation': 'החלף אנימציה',
'top_odds': 'יחסים מובילים',
'top_wins': 'זכיות מובילות',
'total_bets': 'סך הכל הימורים',
'tournament': 'טורניר',
'tournament_information': 'מידע על טורניר',
'try_advanced_autoplay': 'נסה תכונת הפעלה אוטומטית מתקדמת חדשה!',
'uncredited_wins': 'זכיות שלא זוכו',
'user': 'משתמש',
'user_and_points': 'משתמש ונקודות',
'username': 'שם משתמש',
'username_must_be_at_least': 'שם משתמש חייב להיות',
'verify': 'אמת',
'vip_popup_text': 'אתם רשאים לבחור אחד מהאווטרים המיוחדים שזמינים בלעדית לשחקני ה-VIP שלנו!',
'vip_popup_title': 'מזל טוב על הפיכתכם לשחקן VIP',
'waiting_for_the_next_round': 'ממתין לסיבוב הבא',
'warnings_are_calculated': ' הזכיה מחושבת על ידי הכפלת סכום ההימור בערך המכפיל‎ ‎ברגע משיכת ההימור',
'watch_take_off': 'ראה איך הרקטה שלך ממריאה והמכפיל של זכייתך גדל.',
'watch_the_rocket': 'צפה ברקטה',
'we_have_noticed': '\'שמנו לב שזו הפעם הראשונה שאתה משחק "Rocketman". האם תרצה לראות וידאו קצר המסביר איך לשחק?',
'week': 'שבוע',
'welcome_to_rocketman': 'ברוך הבא ל"Rocketman" – משחק חדש ומרגש!  עם "Rocketman" יש לך סיכוי לזכות בפי 20.000 מסכום ההימור המקורי שלך.',
'what_is_provably_fair': 'מה זה‎ "‎הוגן באופן יכיח‎"?',
'will_be_deposited': 'יזוכו ליתרות-חשבונות השחקנים עם הזכייה הרגילה הראשונה',
'win': 'זכייה',
'win_amount': 'סכום זכייה‎',
'win_gifs': 'זכייה',
'winner': 'זוכה',
'wins': 'זכייה',
'write_a_replay': 'לַעֲנוֹת',
'year': 'שנה',
'yes': 'כן',
'yes_start_the_round': 'כן, התחל את הסיבוב',
'you_have_cashed_out': 'משכת את ההימור, הזכייה שלך היא ',
'you_have_won': 'זכית ב',
'your_bet_is_lost': 'ההימור שלך ייאבד‎.',
'current': 'נוֹכְחִי',
'previous': 'קודם',
'claim': 'תְבִיעָה',
'this_feature_will_give': 'תכונה זו תעניק כמות נבחרה למשתמשים אקראיים בצ\'אט',
'amount_per_player': 'כמות לכל שחקן',
'no_of_players': 'מספר שחקנים',
'meteor_shower': 'מטר מטאורים',
'claim_your_free_bet': 'תבע את ההימור החינמי שלך',
'meteor_shower_total': 'מטר מטאורים סך הכל',
'shower': 'גֶשֶׁם',
'total': 'סה"כ',
'meteor_shower_incoming': 'מטר מטאור נכנס, תסתכל בצ\'אט!',
'insurance': 'ביטוח',
'insurance_paragraph': 'ביטוח השחקנים יכולים לרכוש ביטוח להימור שלהם על ידי לחיצה על תיבת הסימון "ביטוח ההימור" ליד לחצן ההימור. הביטוח עולה 10% מסכום ההימור הכולל וישלם את סכום ההימור לשחקן במקרה שהרקטה תתפוצץ על רמת מכפיל.',
'insure_your_bet': 'בטח את ההימור שלך',
'bet_insurance_promo_text': 'בחר באפשרות "ביטוח הימור" כדי להבטיח את ההימור שלך מפני התרסקות רקטה במכפיל 1.00.',
'bet_insurance_promo_text_helper': 'קרא עוד בקטע "חוקי משחק"...',
'game_explanation_1': 'בחר את המכפיל שלך, ואם מכפיל המשחק עולה על המכפיל שבחרת, הניצחון שלך יהיה',
'game_explanation_2': 'המכפיל שלך x ההימור שלך',
'retail_max_mp_1': 'נצח עד',
'retail_max_mp_2': 'ההימור שלך!',
'play_multibets': 'לְשַׂחֵק',
'multibets': 'MULTIBETS',
'bonus_game': 'משחק בונוס',
'all_wins_doubled': 'כל הניצחונות הוכפלו!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'זכה x2 בכל ההימורים בסבב "השקת בונוס"!',
'multiplier_history': 'היסטוריית מכפיל',
'will_start_soon': 'יתחיל בקרוב',
'hurry_up_and_place_your_bets': 'מהרו והצבו את ההימורים שלכם!',
'active_bets': 'הימורים פעילים',
'shop': 'לִקְנוֹת',
'game_meteor_shower_paragraph': 'Meteor Shower מעניק הימור חינם אחד בערך מוגדר מראש למספר מוגדר מראש של שחקנים. זה יכול להיות יזום על ידי השרת או על ידי שחקן. לאחר הפעלת Meteor Shower, מופיעה הודעה במשחק, המכוונת את המשתמשים להסתכל בצ\'אט שבו ממוקמת הודעת Meteor Shower. שחקנים יכולים לתבוע את ההימור בחינם על ידי לחיצה על כפתור "תבע". הימורים בחינם מוענקים לשחקנים שלוחצים תחילה על כפתור "תבע". שימוש בקליקים אוטומטיים או כל סוג של תוכנת אוטומציה כדי לחקות קליקים של שחקנים ולתבוע פרסים אסור ועלול לגרום לאיסור על תכונה זו.',
'claming_failed': 'התביעה נכשלה',
'player_on_blacklist': 'השחקן נמצא ברשימה השחורה',
'you_can_claim_ticket': 'אתה יכול לתבוע כרטיס רק אם אתה שחקן פעיל עם הימורים רגילים',
'you_already_have_free_tickets': 'כבר יש לך כרטיסים בחינם, בבקשה תוציא אותם קודם',
'please_play_the_game_in_portait_mode': 'אנא שחקו את המשחק במצב לאורך.',
};
langArr.hr = {
'your_insurance_returns_you': 'Vaše osiguranje vam vraća',
'bet_insurance': 'Osiguranje klađenja',
'max_bet_is': 'Maksimalna oklada je',
'already_active_bet_of': 'i već postoji aktivna oklada na',
'1_from_game_history': '‎1. Iz povijesti igre',
'2_in_settings_window': '‎2. U prozoru postavki',
'3_online_calculator': '‎3. Online kalkulator',
'active_tournament_1': 'Aktivni turnir ',
'active_tournament_2': 'još nema oklada.',
'add_another_bet': 'Dodaj drugu okladu',
'advanced_autoplay': 'NAPREDNA AUTOMATSKA IGRA',
'advanced_autoplay_for_bet': 'Napredna automatska igra za okladu',
'alien_saucer_free_bets': 'Besplatne oklade letećeg tanjura',
'all_bets': 'Sve oklade',
'all_in_your_hands': 'Sve je u vašim rukama!',
'allow': 'Dopusti',
'at': 'na',
'austronaut_promo_credit': 'Astronautski promo kredit',
'auto_play_and_autocash': 'AUTOMATSKA IGRA I AUTOMATSKA ISPLATA ',
'autoplay': 'Automatska igra',
'autoplay_and_cashout': '‎02. Automatska igra i automatsko podizanje sredstava',
'back_to_home': 'Povratak na početak',
'balance': 'Stanje',
'bet': 'OKLADA',
'bet_amount': 'IZNOS OKLADE',
'bet_and_cashout': '‎01. Oklada i isplata',
'bet_bets_tab': 'ULOG',
'bet_failed': 'Oklada nije uspjela',
'bet_statistic': 'Statistika oklade',
'bet_time': 'Vrijeme oklade',
'bet_wins': 'Dobici u okladama',
'bets': 'Oklade',
'biggest_odds': 'Najveći koeficijenti',
'bonus_type': 'TIP BONUSA',
'bonusing_history': 'Arhiva bonusa',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Dobitnici Boosterpot',
'but_remember_paragraph': 'Ako niste imali vremena zatražiti isplatu prije eksplozije rakete, ',
'calculate_raw_mp_to_decimal': 'Izračunaj neobrađeni umnožitelj iz decimalnog broja te ga zaokruži na najbliži cijeli broj',
'cancel': 'Odustani',
'cant_post_media_content': 'Česta objava medijskog sadržaja nije moguća',
'cashed_out': 'Podignuto',
'cashout': 'Isplata',
'cashout_at': 'ISPLATA NA',
'cashout_before': 'Zatražite isplatu prije eksplozije rakete i novac je vaš!',
'change_avatar': 'Promijeni avatar',
'change_username': 'Promjena korisničkog imena',
'characters_long': 'znakova',
'chat': 'Čavrljanje',
'chat_room': 'Soba za čavrljanje',
'checking_for_spam': 'Provjera neželjene pošte...',
'close': 'Zatvori',
'coeff': 'KOEF.',
'collect_win': 'Uzmi dobitak',
'combined_seed': 'Kombinirana oznkaka',
'company': 'Tvrtka',
'congratulations_you_have_won_free_bets': 'Čestitamo! Osvojili ste bonus oklade! Možete odigrati okladu u vrijednosti',
'convert_first_13_to_decimal': ' Pretvori prvih 13 bajta u decimalni broj',
'd': 'd',
'date': 'DATUM',
'date_and_time': 'Datum i vrijeme',
'deny': 'Odbij',
'detailed_game_rules': 'Detaljna pravila igre',
'dissconnection_policy': 'PRAVILA PREKIDANJA VEZE',
'easy_as_one_two_three': 'Tako je jednostavno',
'enter_username': 'Unesite željeno korisničko ime',
'error_handling': 'RJEŠAVANJE POGREŠAKA',
'exit_full_screen': 'Izađi iz prikaza na cijelom zaslonu',
'first': 'prvi',
'first_time_playing': 'Igrate li „Rocketman“ po prvi puta?',
'folow_the_three_easy_steps': 'Slijedite tri jednostavna koraka i uživajte u Rocketmanu! ',
'free_bets': 'BESPLATNE OKLADE',
'free_bets_desc': 'Osvojili ste besplatne oklade! U bilo kom trenutku možete iskoristiti besplatne oklade pritiskom na tipku start.',
'free_bets_header': 'Besplatne oklade',
'from': 'OD',
'fullscreen_mode': 'Radi boljeg korisničkog iskustva igra će započeti na cijelom zaslonu.',
'function': 'Funkcija',
'game_functions': 'Funkcije igre',
'game_history': 'POVIJEST IGRE',
'game_r_1_paragraph': 'Igrač može aktivirati funkciju automatske igre gdje se oklada automatski igra za svaku novu rundu s iznosom iz prethodne oklade.',
'game_r_10_paragraph': 'Nakon što promo bodovi budu dodijeljeni, za bilo koju okladu koju igrač odigra novac će prvo biti oduzet od promo bodova dok ne bude iskorišten, a tek onda će novac biti podignut iz novčanika igrača.',
'game_r_11_paragraph': 'Promo bodove nije moguće podići, no svaki dobitak osvojen okladama uz pomoć promo bodovima u potpunosti se dodjeljuju igraču.',
'game_r_12_paragraph': 'Promo bodovi vrijede 30 dana, nakon čega ističu te ih nije moguće koristiti.',
'game_r_13_paragraph': 'Igrač može nasumično dobiti besplatne oklade. Skočni prozor s grafikom pojavit će se na zaslonu te obavijestiti igrača o tome da su mu dodijeljene besplatne oklade te iznos po okladi.',
'game_r_14_paragraph': 'Nakon što besplatne oklade budu dodijeljene, igrač može odabrati kad će započeti rundu besplatnih oklada. Nakon što je runda besplatnih oklada započela, igra se automatska oklada s određenim ulogom, a igrač može podići sredstva u bilo kom trenutku. Ako se',
'game_r_15_paragraph': 'Besplatne oklade vrijede 30 dana, nakon čega ističu te ih nije moguće koristiti.',
'game_r_16a_paragraph': 'Ukupni teoretski povrat igraču je ',
'game_r_17_paragraph': 'Postoci povrata igraču stalno se nadziru te ih provjeravaju neovisne treće strane.',
'game_r_18_paragraph': 'Ako nakon odigravanja oklade bude prekinuta veza, oklada neće biti poništena. Ako umnožitelj dođe do 20.000x, vaša će oklada biti automatski isplaćena, a bodovi biti prebačeni na vaš račun.',
'game_r_19_paragraph': 'Možete čavrljati tijekom igre s drugim igračima. Unesite poruku u polje za čavrljanje ili podijelite svoju dobitnu okladu s drugim igračima.',
'game_r_2_paragraph': 'Multiplikator automatske isplate može se unijeti u polje „Automatska isplata“. Kad se unese, automatski će se isplatiti osvojeni iznos ako bude dosegnut postavljeni umnožitelj.',
'game_r_20_paragraph': 'Igračima koji zloupotrebljavaju čavrljanje zlostavljajući druge igrače ili koristeći nepristojne i/ili uvredljive izraze bit će uskraćena mogućnost čavrljanja.',
'game_r_21_paragraph': 'Rocketman je dizajniran s prelijepom pozadinskom glazbom i zvučnim efektima. Želite li isključiti glazbu i zvučne efekte, to možete učiniti kroz padajući izbornik.',
'game_r_22_paragraph': 'Klikom na tipku „Moje oklade“ igrač može pregledati svoje aktualne oklade. Za daljnju povijest igre (npr. duže vremenske periode) igrač će možda trebati pristupiti povijesti igrača unutar same platforme.',
'game_r_23_paragraph': 'U slučaju pogreške, runde mogu kasniti. Ako se to dogodi, naša služba za korisnike pokušat će riješiti problem što je prije moguće i nastaviti s redovnim rundama igre. U slučaju problema sa samom rundom igre, sve će oklade biti poništene, a iznos uloga vr',
'game_r_24_paragraph': 'Latencija mreže je neizbježan dio povezivanja na internet. Sve oklade i isplate smatramo važećima tek kad dođu do našeg servera, neovisno o trajanju latencije mreže. ',
'game_r_3_paragraph': 'Igrač može koristiti tipke za prečac kako bi brzo odigrao okladu ili podigao sredstva u igri. Mapiranja tipki su sljedeća',
'game_r_4_paragraph': '1% svakog uloga ide u fond za progresivni jackpot. Taj fond služi za sustav progresivnog jackpota',
'game_r_5_paragraph': ' ‎• Jackpot – Najviša razina, najveća nagrada, najrjeđe pada',
'game_r_6_paragraph': ' ‎• Rocketpot – Srednja razina, srednja nagrada',
'game_r_7_paragraph': ' ‎• Boosterpot – Najniža razina, najmanja nagrada, najčešće pada ',
'game_r_8_paragraph': 'Sve razine džekpota dodjeljuju se mehanikom „Misterij“ ili „mora-ga-dobiti“. Vrijednost svakog džekpota „mora-ga-dobtii“ određuje se nasumičnim generatorom broja odmah nakon što prethodni džekpot bude osvojen. Vrijednost je šifrirana, skrivena i nije pozn',
'game_r_8a_paragraph': 'Napomena: Dobitnik jackpota može biti samo jedan Rocketman igrač koji aktivno igra u rundi kada je jackpot aktiviran. Ne postoji mogućnost da bi igrač, ako je osvojio jackpot, morao to podijeliti s bilo kojim drugim igračem Rocketmana.',
'game_r_9_paragraph': 'Igrač može nasumično dobiti promo bodove. Skočni prozor s grafikom pojavit će se na zaslonu te obavijestiti igrača o tome da su mu dodijeljeni promo bodovi i njihov iznos.',
'game_round': 'dodatne runde igre!',
'game_rules': 'Pravila igre',
'game_rules_1_paragraph': 'Rocketman je kladioničarska zabava nove generacije. Za nekoliko sekundi osvojite višestruko veću nagradu! Rocketman je izgrađen na',
'game_rules_10_paragraph': ' ‎• Automatska igra pokreće se iz kartice „Auto“ u bloku za klađenje, označavanjem polja „Automatska oklada“. Nakon aktivacije oklada će se automatski odigrati, no za podizanje sredstava trebate pritisnuti na tipku „Isplata“ u svakoj rundi. Ako želite da ',
'game_rules_11_paragraph': '‎• Automatska isplata dostupna je iz kartice „Auto“ u bloku za klađenje. Nakon aktivacije, vaša će oklada biti automatski isplaćena kad dođe do upisanog koeficijenta.',
'game_rules_2_paragraph': '‎, koji je trenutačno jedino jamstvo poštenja u industriji klađenja.',
'game_rules_3_paragraph': '‎• Umnožitelj dobitka kreće na 1x i sve više raste dok Sretna raketa uzlijeće.',
'game_rules_4_paragraph': ' ‎• Vaš dobitak odgovara iznosu umnožitelja u trenutku kad ste izvršili podizanje sredstava pomnoženog s vašim iznosom oklade. ',
'game_rules_5_paragraph': '‎• Prije početka svake runde, naš sustav dokazano poštenog nasumičnog generiranja brojeva generira koeficijent na kojemu će raketa uzletjeti. Je li ovo generiranje bilo pošteno možete provjeriti klikom preko puta rezultata u kartici Povijest.',
'game_rules_6_paragraph': '‎• Odaberite iznos i pritisnite tipku „Oklada“ kako biste se kladili. ',
'game_rules_7_paragraph': '‎• Možete istovremeno igrati dvije oklade tako što ćete dodati drugi blok za klađenje. Kako biste to učinili, pritisnite na ikonicu plus, koja se nalazi u gornjem desnom kutu prvog bloka.',
'game_rules_8_paragraph': ' ‎• Za podizanje sredstava pritisnite tipku „Isplata“. Vaš dobitak odgovara iznosu oklade pomnoženom s koeficijentom isplate.',
'game_rules_9_paragraph': '‎• Ako niste podigli sredstva prije nego što raketa eksplodira, gubite okladu.',
'general_rules': 'OPĆA PRAVILA',
'gifs': 'GIFOVI',
'go_back': 'Vrati se',
'good_job_buddy': 'Bravo majstore, samo tako nastavi',
'h': 'h',
'hashed_in_sha_512': 'Kodirano u SHA512',
'hex': 'Heksadecimalno',
'hey_username': 'Hej',
'hide_hex_to_decimal': 'Sakrij heksadecimalno pretvaranje u decimalno',
'highscore': 'Najbolji rezultat',
'how_it_works': 'Kako to funkcionira?',
'how_to_check': 'Kako provjeriti?',
'how_to_play': 'Kako igrati',
'if_the_maximum_multiplier': '‎• Ako je dosegnut maksimalni kapacitet od 20.000, sve otvorene oklade automatski se isplaćuju uz maksimalni umnožitelj',
'increase': 'Povećaj',
'input_bet_amount': 'Unesite iznos oklade i odigrajte okladu prije uzlijetanja.',
'insufficient_funds': 'Nedovoljno sredstava',
'it_blew_up': 'Eksplodirala je! Šteta…',
'jackpot': 'Džekpot',
'jackpot_winners': 'Dobitnici Jackpot',
'key': 'Tipka',
'load_more': 'UČITAJ JOŠ',
'loading': 'Učitavanje',
'loading_next_round': 'Učitavanje sljedeće runde...',
'loss_gifs': 'GUBITAK',
'lost_connection': 'Prekinuta veza',
'make_a_bet': 'Odigrajte okladu',
'malfunction_voids': 'Kvar poništava sve isplate i igre.',
'maximum_payout': 'Maksimalna isplata je ',
'menu': 'Izbornik',
'min_bet_is': 'Minimalna oklada je',
'month': 'Mjesec',
'more_details': 'Više detalja',
'more_information': 'Više informacija',
'multiplier': 'Umnožitelj',
'multiplier_starts': '‎• Umnožitelj kreće s 1 i penje se do 20.000',
'multipliers_larger': 'Umnožitelji veći od x20000 ograničavaju se na maksimalnu vrijednost umnožitelja',
'music_settings': 'Postavke glazbe',
'my_bet_history': 'Moja povijest oklada',
'my_bets': 'Moje oklade',
'my_bonysing_history': 'Moja arhiva bonusa',
'network_latency': 'LATENCIJA MREŽE',
'network_latency_content': 'Latencija mreže je neizbježan dio povezivanja na internet. Sve oklade i isplate smatramo važećima tek kad dođu do našeg servera, neovisno o trajanju latencije mreže.',
'network_latency_title': 'LATENCIJA MREŽE',
'no_thanks': 'Ne, hvala!',
'not_right_now': 'Ne odmah',
'off': 'isklj',
'oh_snap': 'O, ne!',
'ok_close': 'OK, zatvori!',
'ok_thanks': 'OK, hvala!',
'on': 'uklj',
'on_loss': 'NA GUBITAK',
'on_win': 'NA DOBITAK',
'online': 'Online',
'per_bet': 'po okladi',
'pick_your_avatar_img': 'Odaberi svoj avatar',
'place_bet_with_last': 'Odigraj okladu s posljednjim odabranim ulogom ',
'play_now': 'Igraj odmah',
'player_1_seed': 'Oznaka igrača 1',
'player_2_seed': 'Oznaka igrača 2',
'player_3_seed': 'Oznaka igrača 3',
'please_wait_active_bets': 'Kako biste aktivirali besplatne oklade, pričekajte završetak trenutnih oklada',
'points': 'BODOVI',
'powered_by': 'vam donosi',
'previous_hand': 'Prethodno dijeljenje',
'prize': 'NAGRADA',
'prizes': 'nagrade',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'promo_credit': 'Promo kredit',
'promo_credit_condradulations': 'Čestitamo! Osvojili ste astronomski promo kredit koji možete iskoristiti u ovoj igri!',
'promo_credits': 'PROMO BODOVI',
'promo_history': 'Povijest promocije',
'provably_fair': 'Dokazano pošteno',
'provably_fair_1_paragraph': 'Rocketman se zasniva na tehnologiji šifriranja po imenu \'Dokazano pošteno\'. Ova tehnologija jamči stopostotno pošten rezultat igre. Uz ovu tehnologiju nemoguće je da se treća strana umiješa u proces igre.',
'provably_fair_2_paragraph': 'Rezultat svake runde (koeficijent \'Uzlijetanja\') ne generira se na našim serverima, već uz pomoć igrača u igri te je potpuno transparentan. Na ovaj način nitko ne može manipulirati ishodom igre. Također, bilo tko može provjeriti i potvrditi poštenje igr',
'provably_fair_3_paragraph': 'Rezultat runde generiraju četiri neovisna sudionika runde',
'provably_fair_4_paragraph': 'Kad runda završi, igra spaja oznaku (seed) servera s tri klijentske oznake. Iz spojenih simbola generira se hash kod SHA512, a iz njega – rezultat igre.',
'provably_fair_5_paragraph': 'Klikom na zelenu ikonu u povijesti igre možete provjeriti je li ona bila poštena. U otvorenom prozoru vidjet ćete oznaku servera, tri para oznaka igrača, kombinirani hash kod i rezultat runde.',
'provably_fair_6_paragraph': 'Kodirana verzija oznaka servera sljedećih rundi javno je dostupna u prozoru postavki (u korisničkom izborniku provjerite „Postavke dokazano poštenog“, a zatim „Sljedeća oznaka servera SHA256“). Svoju oznaku klijenta možete provjeriti i ovdje. Želite li su',
'provably_fair_7_paragraph': 'U svakom online kalkulatoru možete provjeriti je li hash kod ispravan. Primjerice',
'provably_fair_system': 'sustavu dokazanog poštenja',
'quick_explanation': 'Kratko objašnjenje',
'randomly_generated_server_hash': 'Nasumično generirani HASH server',
'rank': 'POREDAK',
'raw_multiplier': 'neobrađeni umnožitelj',
'raw_multiplier_to_edged': 'Neobrađeni umnožitelj do rubnog umnožitelja, koristeći',
'remove_additional_bet': 'Ukloni dodatnu okladu',
'reset': 'Vrati',
'return_to_player': 'POVRAT IGRAČU',
'rocketman_is_pure_excitement': 'Rocketman je čisto uzbuđenje! Riskirajte i ostvarite dobitak.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Dobitnici Rocketpot',
'round': 'Runda',
'round_id': 'ID runde',
'round_multiplier': 'Umnožitelj runde',
'round_results': 'rezultati runde',
'rounds': 'runde',
'rtp': 'RTP',
'rules': 'pravila',
'rules_details': 'Pravila turnira',
'save': 'Spremi',
'schedule': 'raspored',
'second': 'drugi',
'seconds_left': 'preostalo sek',
'server_hash': 'Hash servera',
'please_wait_current_bets': 'MOLIMO PRIČEKAJTE DA SE TRENUTNE KLADNJE ZAVRŠE',
'min_autocashout_value_must_be': 'MINIMALNA VRIJEDNOST AUTOCASHOUTA MORA BITI',
'min_bet_value_must_be': 'MINIMALNA VRIJEDNOST ULOGA MORA BITI',
'server_seed': 'Oznaka servera',
'session_dissconnected': 'Sesija je prekinuta',
'multisession_not_allowed': 'Multisesija nije dopuštena',
'set_auto_bet': 'POSTAVI AUTOMATSKU OKLADU',
'set_auto_cashout': 'POSTAVI AUTOMATSKU ISPLATU',
'shourtcut_keys': 'TIPKE ZA PREČAC',
'show_hex_to_deimal': 'Prikaži heksadecimalno pretvaranje u decimalno',
'sound': 'ZVUK',
'sound_settigs': 'Postavke zvuka',
'start_with_autoplay': 'Pokreni automatsku igru',
'stop_autoplay': 'ZAUSTAVI AUTOMATSKU IGRU',
'stop_on_loss': 'KRAJ UZ GUBITAK',
'stop_on_loss_tooltip': 'Iskoristi funkciju Kraj uz gubitak kako bi se zaustavila automatska oklada nakon određenog iznosa gubitka.<br/> Primjerice, ako počnete s 100$ i postavite Kraj uz gubitak na 25$',
'stop_on_profit': 'KRAJ UZ DOBITAK',
'stop_on_profit_tooltip': 'Iskoristi funkciju Kraj uz dobitak kako bi se zaustavila automatska oklada nakon određenog iznosa zarade.<br/> Primjerice, ako počnete s 100$ i postavite Kraj uz dobitak na 25$',
'there_are_no_active_torunament': 'Trenutačno nema aktivnih turnira.',
'third': 'treći',
'this_game_is': 'Ovu igru',
'this_tool_recalculates': 'Ovaj alat ponovo izračunava umnožitelj iz hash algoritma igre. Za provjeru umnožitelja možete kopirati/zalijepiti bilo koji hash algoritam igre.',
'time': 'VRIJEME',
'time_frame': 'Vremenski okvir',
'to': 'DO',
'toggle_animation': 'Mijenjaj animaciju',
'top_odds': 'Najbolji koeficijenti',
'top_wins': 'Najbolji dobici',
'total_bets': 'Ukupno oklada',
'tournament': 'Turnir',
'tournament_information': 'Informacije o turniru',
'try_advanced_autoplay': 'Isrpobajte novu opciju automatskog igranja',
'uncredited_wins': 'Nedodijeljeni dobici',
'user': 'KORISNIK',
'user_and_points': 'KORISNIK & BODOVI',
'username': 'Korisničko ime',
'username_must_be_at_least': 'Korisničko ime mora imati najmanje',
'verify': 'Provjeri',
'vip_popup_text': 'Možete odabrati jedan od posebnih VIP avatara dostupnih isključivo našim VIP članovima!',
'vip_popup_title': 'Čestitamo vam što ste postali VIP igrač',
'waiting_for_the_next_round': 'Čekanje na sljedeću rundu...',
'warnings_are_calculated': ' ‎• Dobici se izračunavaju množenjem uloga oklade s umnožiteljem u trenutku isplate',
'watch_take_off': 'Gledajte kako vaša raketa polijeće, a vaš umnožitelj dobitaka raste.',
'watch_the_rocket': 'Gledajte raketu',
'we_have_noticed': 'Primijetili smo da vam je ovo prvi puta da igrate „Rocketman“. Biste li htjeli vidjeti kratki video koji objašnjava kako igrati igru?',
'week': 'Tjedan',
'welcome_to_rocketman': 'Dobro došli u Rocketman – novu i uzbudljivu igru! Uz igru Rocketman imate mogućnost osvajanja do 20.000x prvobitnog uloga.',
'what_is_provably_fair': 'Što znači Dokazano pošteno?',
'will_be_deposited': 'S prvim redovnim dobitkom bit će prebačeno na stanje igrača',
'win': 'DOBITAK',
'win_amount': 'Iznos dobitka',
'win_gifs': 'DOBITAK',
'winner': 'dobitnik',
'wins': 'dobitak',
'write_a_replay': 'Odgovorīti…',
'year': 'Godina',
'yes': 'DA',
'yes_start_the_round': 'Da, započni rundu',
'you_have_cashed_out': 'Podigli ste novac, vaš dobitak iznosi ',
'you_have_won': 'OSVOJILI STE',
'your_bet_is_lost': 'gubite okladu.',
'current': 'Trenutni',
'previous': 'Prethodni',
'claim': 'Zahtjev',
'this_feature_will_give': 'Ova značajka će dati odabrani iznos nasumičnim korisnicima u chatu',
'amount_per_player': 'Iznos po igraču',
'no_of_players': 'Broj igrača',
'meteor_shower': 'Kiša meteora',
'claim_your_free_bet': 'Preuzmite svoju besplatnu okladu',
'meteor_shower_total': 'Kiša meteora Ukupno',
'shower': 'KIŠA',
'total': 'Ukupno',
'meteor_shower_incoming': 'Dolazi kiša meteora, pogledajte chat!',
'insurance': 'OSIGURANJE',
'insurance_paragraph': 'Igrači mogu kupiti osiguranje za svoju opkladu klikom na polje "Osiguranje oklade" pored dugmeta za opkladu. Osiguranje košta 10% ukupne vrijednosti oklade i vratit će iznos oklade igraču ako raketa eksplodira sa množiteljem 1.00.',
'insure_your_bet': 'OSIGURAJTE SVOJ KLAD',
'bet_insurance_promo_text': 'Odaberite opciju "Osiguranje oklade" kako biste osigurali svoju okladu od pada rakete s množiteljem 1,00.',
'bet_insurance_promo_text_helper': 'Pročitajte više u rubrici "Pravila igre"...',
'game_explanation_1': 'Odaberite množitelj, a ako množitelj igre premaši odabrani množitelj, dobit ćete',
'game_explanation_2': 'Vaš množitelj x Vaša oklada',
'retail_max_mp_1': 'Osvojiti do',
'retail_max_mp_2': 'Vaša oklada!',
'play_multibets': 'Igraj',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS IGRA',
'all_wins_doubled': 'Svi dobici udvostručeni!',
'multibets_paragraph_1': 'da povećate svoje izglede!',
'multibets_paragraph_2': 'Možete kombinirati oklade na do deset rundi u jednu multibet i osvojiti masivne isplate!',
'multibets_paragraph_3': 'Svaki put kada se runda dobije, dobivena dividenda iz te runde ulaže se u sljedeću rundu, sve dok sve runde ne budu dobitne.',
'bonus_launch': 'Dobijte x2 na svim okladama u rundi „Pokretanje bonusa“!',
'multiplier_history': 'Povijest množitelja',
'will_start_soon': 'uskoro će početi',
'hurry_up_and_place_your_bets': 'Požurite i stavite svoje oklade!',
'active_bets': 'Aktivne oklade',
'shop': 'DUĆAN',
'game_meteor_shower_paragraph': 'Meteor Shower dodjeljuje jednu besplatnu okladu unaprijed definirane vrijednosti unaprijed definiranom broju igrača. Može ga pokrenuti poslužitelj ili igrač. Nakon što se kiša meteora aktivira, u igri pada poruka koja upućuje korisnike da pogledaju chat gdje se nalazi poruka kiše meteora. Igrači mogu zatražiti besplatnu okladu klikom na gumb "Zatraži". Besplatne oklade dodjeljuju se igračima koji prvi kliknu na gumb "Zatraži". Korištenje autoklikera ili bilo koje vrste softvera za automatizaciju za oponašanje klikova igrača i traženje nagrada je zabranjeno i može dovesti do zabrane ove značajke.',
'claming_failed': 'Preuzimanje nije uspjelo',
'player_on_blacklist': 'Igrač je na crnoj listi',
'you_can_claim_ticket': 'Listić možete zatražiti samo ako ste aktivni igrač s redovnim okladama',
'you_already_have_free_tickets': 'Već imate besplatne listiće, prvo ih potrošite',
'please_play_the_game_in_portait_mode': 'Molimo igrajte igru u portretnom načinu.',
};
langArr.hu = {
'your_insurance_returns_you': 'A biztosítás visszaadja',
'bet_insurance': 'Fogadás Biztosítás',
'max_bet_is': 'Maximum tét',
'already_active_bet_of': 'és már van aktív fogadás',
'1_from_game_history': '‎1. A játékelőzményekből',
'2_in_settings_window': '‎2. A Beállítások ablakban',
'3_online_calculator': '‎3. Online számológép',
'active_tournament_1': 'Aktív torna',
'active_tournament_2': '‎– még nincsenek tétek.',
'add_another_bet': 'Új fogadás hozzáadása',
'advanced_autoplay': 'SPECIÁLIS AUTOMATIKUS JÁTÉK',
'advanced_autoplay_for_bet': 'Speciális automatikus játék adott téthez',
'alien_saucer_free_bets': 'Repülőcsészealjas ingyenes fogadások',
'all_bets': 'Minden fogadás',
'all_in_your_hands': 'Minden a te kezedben van!',
'allow': 'Engedélyezés',
'at': '‎@',
'austronaut_promo_credit': 'Asztronauta promóciós kredit',
'auto_play_and_autocash': 'AUTOMATIKUS JÁTÉK ÉS AUTOMATIKUS KIFIZETÉS ',
'autoplay': 'Automatikus játék',
'autoplay_and_cashout': '‎02. Automatikus játék és automatikus kifizetés',
'back_to_home': 'Vissza a kezdőoldalra',
'balance': 'Egyenleg',
'bet': 'FOGADÁS',
'bet_amount': 'TÉTÖSSZEG',
'bet_and_cashout': '‎01. Fogadás és kifizetés',
'bet_bets_tab': 'TÉT',
'bet_failed': 'Sikertelen fogadás',
'bet_statistic': 'Fogadási statisztika',
'bet_time': 'Fogadás ideje',
'bet_wins': 'Nyeremények',
'bets': 'Fogadások',
'biggest_odds': 'A legnagyobb szorzó',
'bonus_type': 'BÓNUSZTÍPUS',
'bonusing_history': 'Bónuszelőzmények',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'A Boosterpot nyertesei',
'but_remember_paragraph': 'Figyelem, ha nincs időd a kifizetésre, mielőtt a rakéta felrobban, ',
'calculate_raw_mp_to_decimal': 'A nyers szorzó átalakítása decimálisból és lefelé kerekítés a legközelebbi egész számra',
'cancel': 'Elvetés',
'cant_post_media_content': 'Nincs lehetőség médiatartalmak gyakori posztolására',
'cashed_out': 'Kifizetve',
'cashout': 'Kifizetés',
'cashout_at': 'KIFIZETÉS',
'cashout_before': 'Válaszd a kifizetést, mielőtt a rakéta felrobban, és tiéd a pénz!',
'change_avatar': 'Avatár megváltoztatása',
'change_username': 'Felhasználónév módosítása',
'characters_long': 'karakter hosszúnak kell lennie',
'chat': 'Chat',
'chat_room': 'Chatszoba',
'checking_for_spam': 'Spam ellenőrzése…',
'close': 'Bezárás',
'coeff': 'EGYÜTTH.',
'collect_win': 'Nyeremény begyűjtése',
'combined_seed': 'Kombinált mag',
'company': 'Vállalat',
'congratulations_you_have_won_free_bets': 'Gratulálunk! Bónuszfogadásokat nyertél! Megfogadható',
'convert_first_13_to_decimal': ' Az első 13 byte átalakítása decimálissá',
'd': 'n',
'date': 'DÁTUM',
'date_and_time': 'Dátum és idő',
'deny': 'Elutasítás',
'detailed_game_rules': 'Részletes játékszabályok',
'dissconnection_policy': 'LEKAPCSOLÓDÁSI IRÁNYELVEK',
'easy_as_one_two_three': 'Könnyű, mint az egyszeregy',
'enter_username': 'Kérjük, írd be a kívánt felhasználónevet',
'error_handling': 'HIBAKEZELÉS',
'exit_full_screen': 'Kilépés a teljes képernyős módból',
'first': 'első',
'first_time_playing': 'Először játszol a „Rocketman” játékkal?',
'folow_the_three_easy_steps': 'Kövesd a három egyszerű lépést, és élvezd a Rocketmant! ',
'free_bets': 'INGYENES FOGADÁSOK',
'free_bets_desc': 'Ingyenes fogadásokat nyertél! Bármikor felhasználhatod őket a start gomb megnyomásával.',
'free_bets_header': 'Ingyenes fogadások',
'from': 'KEZDÉS',
'fullscreen_mode': 'A jobb felhasználói élmény érdekében a játék teljes képernyős módban indul el.',
'function': 'Funkció',
'game_functions': 'Játékfunkciók',
'game_history': 'JÁTÉKELŐZMÉNYEK',
'game_r_1_paragraph': 'A játékosok aktiválhatják az automatikus játék opciót, amelyben minden új körben automatikusan megjátszásra kerül egy fogadás az előző fogadás tétösszegével.',
'game_r_10_paragraph': 'A promóciós kreditek megszerzése után a pénz minden megjátszott fogadásnál először a promóciós kreditből kerül levonásra, amíg az el nem fogy, ezt követően ismét a játékos pénztárcájából vonjuk le a pénzt.',
'game_r_11_paragraph': 'A promóciós kreditet nem lehet kifizetni, de a vele szerzett összes nyeremény teljes egészében jóváíródik a játékos számláján.',
'game_r_12_paragraph': 'A promóciós kreditek 30 napos érvényességi idővel rendelkeznek, ezt követően lejárnak, és nem használhatók fel.',
'game_r_13_paragraph': 'A játékosok véletlenszerűen ingyenes fogadásokat nyerhetnek. Egy felugró grafika jelenik meg a képernyőn, amely értesíti a játékost a megszerzett ingyenes fogadásokról, a fogadások számáról és a fogadásonkénti tétösszegről.',
'game_r_14_paragraph': 'Az ingyenes fogadások megnyerése után a játékos kiválaszthatja, hogy mikor kezdi el az ingyenes fogadási kört. Miután az ingyenes fogadási kör elkezdődött, a megadott téttel egy automatikus fogadásra kerül sor, és a játékoson múlik, hogy melyik pillanatba',
'game_r_15_paragraph': 'Az ingyenes fogadások érvényessége 30 nap, ezt követően lejárnak, és nem használhatók fel.',
'game_r_16a_paragraph': 'Az általános elméleti játékos-visszatérítés ',
'game_r_17_paragraph': 'Az RTP-százalékokat független harmadik felek folyamatosan figyelik és ellenőrzik.',
'game_r_18_paragraph': 'Ha egy fogadás megjátszását követően megszakad a kapcsolat, a fogadás nem válik érvénytelenné. Ha a szorzó eléri a 20 000x-es értéket, a fogadás automatikusan kifizetésre kerül, és a kreditek megjelennek a számládon.',
'game_r_19_paragraph': 'A játékban más játékosokkal is cseveghetsz. Írd be az üzenetedet a chatmezőbe, vagy oszd meg a nyertes fogadásodat másokkal. ',
'game_r_2_paragraph': 'Egy automatikus kifizetési szorzó is megadható az „Automatikus kifizetés” mezőben, és ha ezt beírod, a játék automatikusan elindítja a kifizetést, ha eléri a beállított szorzót.',
'game_r_20_paragraph': 'Azok a játékosok, akik nem megfelelően használják a chat funkciót, sértegetnek másokat, vagy nem megfelelő stílusban és/vagy káromkodva csevegnek, elveszítik a lehetőséget a szolgáltatás használatára. ',
'game_r_21_paragraph': 'A Rocketman készítésénél gondot fordítottunk a gyönyörű háttérzenére és a hanghatásokra. Ha szeretnéd letiltani a zenét és/vagy a hanghatásokat, a legördülő menüben megteheted.',
'game_r_22_paragraph': 'A „Saját fogadásaim” gombra kattintva a játékos megnézheti a legutóbbi fogadásait. A hosszabb időszakra visszanyúló játékelőzményekhez a játékosoknak a játékplatformon belül kell hozzáférniük a játékelőzményekhez.',
'game_r_23_paragraph': 'Hiba esetén előfordulhat, hogy a játékkörök nem indulnak el időben. Ebben az esetben ügyfélszolgálatunk amint lehet, megpróbálja megoldani a problémát, és folytatódhatnak a normál játékkörök. Ha magukkal a játékkörökkel adódik probléma, minden fogadás tör',
'game_r_24_paragraph': 'A hálózati késleltetés elkerülhetetlen része az internetkapcsolatnak. A fogadásokat és a kifizetéseket csak akkor tekintjük érvényesnek, amikor elérik a szerverünket, függetlenül a hálózati késleltetés időtartamától. ',
'game_r_3_paragraph': 'A játékosok gyorsbillentyűket is használhatnak a játékban a fogadások gyors megjátszásához vagy a kifizetéshez. A billentyűzetkiosztás a következő',
'game_r_4_paragraph': 'Minden tét 1%-a egy progresszív jackpot alapba kerül. Ez az alap szolgálja a progresszív jackpot rendszert',
'game_r_5_paragraph': ' ‎• Jackpot – A legmagasabb szint, a legnagyobb nyeremény, a legritkább előfordulás',
'game_r_6_paragraph': ' ‎• Rocketpot – Közepes szint, közepes nyeremény',
'game_r_7_paragraph': ' ‎• Boosterpot – A legalacsonyabb szint, alacsony nyeremény, a leggyakoribb előfordulás ',
'game_r_8_paragraph': 'Minden jackpotszintet a „rejtélyes” vagy „elérendő” mechanizmus segítségével lehet megszerezni. Minden „elérendő” jackpot értékét közvetlenül az előző jackpot megnyerését követően határozza meg egy véletlenszám-generátor, majd az értéke titkosítva tárolód',
'game_r_8a_paragraph': 'Megjegyzés: A jackpot nyertese csak egyetlen Rocketman-játékos lehet, aki aktívan játszik a körben, amikor a jackpot aktiválásra került. Nincs lehetőség arra, hogy ha a játékos megnyerte a jackpotot, meg kell osztania azt bármely más Rocketman játékossal.',
'game_r_9_paragraph': 'A játékosok véletlenszerűen promóciós krediteket kaphatnak. Egy felugró grafika jelenik meg a képernyőn, amely értesíti a játékost a megszerzett promóciós kreditről és annak értékéről.',
'game_round': 'játékkörben!',
'game_rules': 'Játékszabályok',
'game_rules_1_paragraph': 'A Rocketman az új generáció szórakoztató szerencsejátéka. Nyerj sokkal többször másodpercek alatt! A Rocketman',
'game_rules_10_paragraph': ' ‎• Az automatikus játékot a Fogadási panel „Auto” fülén aktiválhatod, ha bejelölöd az „Automatikus fogadás” jelölőnégyzetet. Aktiválás után a játék automatikusan megteszi a téteket, de a kifizetéshez minden körben meg kell nyomnod a „kifizetés” gombot. H',
'game_rules_11_paragraph': '‎• Az automatikus kifizetés a fogadási panel „Auto” füléről kezdeményezhető. Az aktiválás után a fogadás kifizetése automatikus lesz, amikor eléred a megadott együtthatót.',
'game_rules_2_paragraph': 'amely jelenleg az egyedüli garancia a tisztességes játékra a szerencsejáték-iparban.',
'game_rules_3_paragraph': '‎• A nyereményszorzó 1x-es értéktől indul, és egyre növekszik, miután a szerencserakéta felszáll.',
'game_rules_4_paragraph': ' ‎• A nyereményed a kifizetés kérésének pillanatában érvényes szorzó és a téted szorzata. ',
'game_rules_5_paragraph': '‎• A bizonyíthatóan tisztességes véletlenszám-generátorunk minden kör elején létrehozza azt az együtthatót, amelynél a szerencserakéta elrepül. A generálás tisztességességét úgy ellenőrizheted, hogy az Előzmények fülön az eredménnyel szembeni részre katti',
'game_rules_6_paragraph': '‎• Válaszd ki az összeget, majd nyomd meg a „Fogadás” gombot a fogadáshoz. ',
'game_rules_7_paragraph': '‎• Egyszerre két fogadást köthetsz, ha hozzáadsz egy második fogadási panelt is. A második fogadási panel hozzáadásához nyomd meg a pluszjel ikont, amely az első fogadási panel jobb felső sarkában látható.',
'game_rules_8_paragraph': ' ‎• Nyomd meg a „Kifizetés” gombot a nyereményed felvételéhez. A nyereményed a tét és a kifizetési együttható szorzója.',
'game_rules_9_paragraph': '‎• A fogadásod veszít, ha nem kéred a kifizetést, mielőtt a rakéta elrepül.',
'general_rules': 'ÁLTALÁNOS SZABÁLYOK',
'gifs': 'GIFEK',
'go_back': 'Visszalépés',
'good_job_buddy': 'Jól nyomod, csak így tovább',
'h': 'ó',
'hashed_in_sha_512': 'SHA512 hash függvény',
'hex': 'Hexadecimális',
'hey_username': 'Üdv',
'hide_hex_to_decimal': 'A hexadecimális-decimális átváltás elrejtése',
'highscore': 'Magas pontszám',
'how_it_works': 'Hogyan működik?',
'how_to_check': 'Hogyan lehet ellenőrizni?',
'how_to_play': 'A játék menete',
'if_the_maximum_multiplier': '‎• Ha eléred a maximális, 20 000-es szorzót, minden nyitott fogadás automatikusan kifizetésre kerül a maximális szorzóval',
'increase': 'Növelés',
'input_bet_amount': 'Add meg a fogadási összeget, és helyezd el a téted a felszállás előtt.',
'insufficient_funds': 'Nincs elegendő pénzed',
'it_blew_up': 'Ez felrobbant!',
'jackpot': 'Jackpot',
'jackpot_winners': 'A Jackpot nyertesei',
'key': 'Billentyű',
'load_more': 'TOVÁBBIAK BETÖLTÉSE',
'loading': 'Betöltés',
'loading_next_round': 'A következő kör betöltése…',
'loss_gifs': 'VESZTETT',
'lost_connection': 'Megszakadt a kapcsolat',
'make_a_bet': 'Fogadás',
'malfunction_voids': 'A hibás működés minden kifizetést és játékot érvénytelenít.',
'maximum_payout': 'A maximális kifizetés ',
'menu': 'Menü',
'min_bet_is': 'A minimális tét',
'month': 'Hónap',
'more_details': 'Részletek',
'more_information': 'További információk',
'multiplier': 'Szorzó',
'multiplier_starts': '‎• A szorzó kezdőértéke 1, és egészen 20 000-ig növekedhet',
'multipliers_larger': 'A 20 000x-esnél nagyobb szorzók a maximális szorzóértéket kapják meg',
'music_settings': 'Zenebeállítások',
'my_bet_history': 'Fogadási előzményeim',
'my_bets': 'Fogadásaim',
'my_bonysing_history': 'Korábbi bónuszaim',
'network_latency': 'HÁLÓZATI KÉSLELTETÉS',
'network_latency_content': 'A hálózati késleltetés elkerülhetetlen része az internetkapcsolatnak. A fogadásokat és a kifizetéseket csak akkor tekintjük érvényesnek, amikor elérik a szerverünket, függetlenül a hálózati késleltetés időtartamától.',
'network_latency_title': 'HÁLÓZATI KÉSLELTETÉS',
'no_thanks': 'Kösz, nem!',
'not_right_now': 'Most nem',
'off': 'ki',
'oh_snap': 'Ó, jaj!',
'ok_close': 'Rendben, bezárom!',
'ok_thanks': 'Rendben, köszi!',
'on': 'be',
'on_loss': 'VESZTÉS ESETÉN',
'on_win': 'NYERÉS ESETÉN',
'online': 'Online',
'per_bet': 'fogadásonként',
'pick_your_avatar_img': 'Válaszd ki az avatárképedet',
'place_bet_with_last': 'Fogadás a legutóbb kiválasztott téttel ',
'play_now': 'Játssz most',
'player_1_seed': 'Az 1. játékos magja',
'player_2_seed': 'A 2. játékos magja',
'player_3_seed': 'A 3. játékos magja',
'please_wait_active_bets': 'Az ingyenes fogadások aktiválásához kérjük, várd meg, hogy az aktuális fogadások eldőljenek',
'points': 'PONT',
'powered_by': 'üzemeltetője',
'previous_hand': 'Előző kéz',
'prize': 'NYEREMÉNY',
'prizes': 'nyeremények',
'progressive_jackpot': 'PROGRESSZÍV JACKPOT',
'promo_credit': 'Promóciós kredit',
'promo_credit_condradulations': 'Gratulálunk! Nyertél egy asztronómikusan jó promóciós kreditet, amelyet felhasználhatsz a játékban!',
'promo_credits': 'PROMÓCIÓS KREDITEK',
'promo_history': 'Promóciós előzmények',
'provably_fair': 'Bizonyíthatóan fair',
'provably_fair_1_paragraph': 'A Rocketman olyan kriptografikus technológián alapul, amelynek neve „Bizonyíthatóan tisztességes”. Ez a technológia garantálja a 100%-osan korrekt játékeredményt. Ezzel a technológiával lehetetlenné válik, hogy bárki beleavatkozzon a játékmenetbe.',
'provably_fair_2_paragraph': 'Az egyes körök eredménye (a játék „elrepülési” együtthatója) nem a saját szervereinken jön létre, hanem a körben résztvevő játékosok segítségével generálódik, és teljes mértékben átlátható. Így lehetetlenné válik, hogy bárki manipulálja a játék kimenetelé',
'provably_fair_3_paragraph': 'A kör eredményeit a kör négy egymástól független összetevőjéből számoljuk ki',
'provably_fair_4_paragraph': 'Amikor a kör megkezdődik, a játék összevonja a szervermagot és a három ügyfélmagot. Ezekből az összevont szimbólumokból generálódik az SHA512 hash függvény, és ebből jön létre a játék eredménye.',
'provably_fair_5_paragraph': 'Minden kör korrekt kimenetelét a játékelőzmények között, a zöld ikonra kattintva ellenőrizheted. A megnyitott ablakban látható a szervermag, 3 pár játékosmag, a kombinált hash függvény és a kör eredménye.',
'provably_fair_6_paragraph': 'A következő körök szervermagjának hash függvénnyé alakított verziója bárki számára hozzáférhető a Beállítások ablakban (a felhasználói menüben, a „Bizonyíthatóan tisztességes beállítások”, majd a „Következő szervermag SHA256” részben). Itt megváltoztathat',
'provably_fair_7_paragraph': 'A hash funkció helyességét bármelyik online számológéppel le lehet ellenőrizni, például',
'provably_fair_system': 'bizonyíthatóan tisztességes rendszerre épül,',
'quick_explanation': 'Gyors magyarázat',
'randomly_generated_server_hash': 'Véletlenszerűen generált szerver HASH',
'rank': 'HELYEZÉS',
'raw_multiplier': 'nyers szorzó',
'raw_multiplier_to_edged': 'Nyers szorzóból kerekített szorzó, a következő felhasználásával',
'remove_additional_bet': 'További fogadás eltávolítása',
'reset': 'Visszaállítás',
'return_to_player': 'JÁTÉKOS-VISSZATÉRÍTÉS',
'rocketman_is_pure_excitement': 'A Rocketman színtiszta izgalom! Kockáztass és nyerj!',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'A Rocketpot nyertesei',
'round': 'Kör',
'round_id': 'Kör azonosítója',
'round_multiplier': 'A kör szorzója',
'round_results': 'a kör eredményei',
'rounds': 'kör',
'rtp': 'RTP',
'rules': 'szabályok',
'rules_details': 'A torna szabályai',
'save': 'Mentés',
'schedule': 'időpont',
'second': 'második',
'seconds_left': 'mp maradt',
'server_hash': 'Szerver HASH',
'please_wait_current_bets': 'KÉRJÜK, VÁRJON MEG A JELENLEGI FOGADÁSOK BEFEJEZÉSÉT',
'min_autocashout_value_must_be': 'MIN. AUTOMATIKUS KISZÁMÍTÁSI ÉRTÉK KELL LENNI',
'min_bet_value_must_be': 'MIN. TÉTÉRTÉK KELL LENNI',
'server_seed': 'A szervermag',
'session_dissconnected': 'A munkamenet kapcsolata megszakadt',
'multisession_not_allowed': 'A többmenet nem engedélyezett',
'set_auto_bet': 'AUTOMATIKUS FOGADÁS BEÁLLÍTÁSA',
'set_auto_cashout': 'AUTOMATIKUS KIFIZETÉS BEÁLLÍTÁSA',
'shourtcut_keys': 'GYORSBILLENTYŰK',
'show_hex_to_deimal': 'A hexadecimális-decimális átváltás megjelenítése',
'sound': 'HANG',
'sound_settigs': 'Hangbeállítások',
'start_with_autoplay': 'Automatikus játék indítása',
'stop_autoplay': 'AUTOMATIKUS JÁTÉK LEÁLLÍTÁSA',
'stop_on_loss': 'LEÁLLÍTÁS ADOTT VESZTESÉGNÉL',
'stop_on_loss_tooltip': 'Használd a Leállítás adott veszteségnél lehetőséget az automatikus játék leállítására, ha elérted a megadott összeget.<br/> Például, ha 100 dollárral indulsz, beállíthatod, hogy 25 dolláros veszteségnél leálljon az automatikus játék.',
'stop_on_profit': 'LEÁLLÍTÁS ADOTT NYERESÉGNÉL',
'stop_on_profit_tooltip': 'Használd a Leállítás adott nyereségnél lehetőséget az automatikus játék leállítására, ha elérted a megadott összeget.<br/> Például, ha 100 dollárral indulsz, beállíthatod, hogy 25 dolláros nyereségnél leálljon az automatikus játék.',
'there_are_no_active_torunament': 'Jelenleg nincsenek aktív tornák.',
'third': 'harmadik',
'this_game_is': 'Ennek a játéknak az',
'this_tool_recalculates': 'Ez az eszköz újraszámolja a szorzót a játék hash-kódjából. Bármely játék hash-kódját bemásolhatod, hogy ellenőrizd a szorzót.',
'time': 'IDŐ',
'time_frame': 'Időkeret',
'to': 'BEFEJEZÉS',
'toggle_animation': 'Animáció be- és kikapcsolása',
'top_odds': 'Top szorzók',
'top_wins': 'Top nyeremények',
'total_bets': 'Összes fogadás',
'tournament': 'Torna',
'tournament_information': 'A torna adatai',
'try_advanced_autoplay': 'Próbálja ki az új fejlett automatikus lejátszási funkciót!',
'uncredited_wins': 'Jóvá nem írt nyeremények',
'user': 'JÁTÉKOS',
'user_and_points': 'FELHASZNÁLÓ + PONTOK',
'username': 'Felhasználónév',
'username_must_be_at_least': 'A felhasználónévnek legalább',
'verify': 'Ellenőrzés',
'vip_popup_text': 'Választhatsz a kizárólag a VIP-játékosoknak járó, különleges VIP-avatárok közül!',
'vip_popup_title': 'Gratulálunk, VIP-játékos lettél',
'waiting_for_the_next_round': 'Várakozás a következő körre…',
'warnings_are_calculated': ' ‎• A nyeremények kiszámolásakor a fogadás tétjét megszorozzuk a kifizetési esemény pillanatában érvényes szorzóval',
'watch_take_off': 'Figyeld, ahogy a rakéta felszáll, és a nyereményszorzód növekszik.',
'watch_the_rocket': 'Figyeld a rakétát',
'we_have_noticed': 'Úgy látjuk, először játszol a „Rocketman” játékkal. Szeretnél megnézni egy gyors videót, amely bemutatja a játék menetét?',
'week': 'Hét',
'welcome_to_rocketman': 'Üdv a Rocketman játékban – egy újszerű, izgalmas játék vár rád! A Rocketmanben esélyed van megnyerni az eredeti téted akár 20 000x-esét.',
'what_is_provably_fair': 'Mit jelent a bizonyíthatóan tisztességes?',
'will_be_deposited': 'Az első normál nyereménynél kerül jóváírásra a játékosok egyenlegén',
'win': 'NYEREMÉNY',
'win_amount': 'Nyeremény',
'win_gifs': 'NYEREMÉNY',
'winner': 'nyertes',
'wins': 'nyeremény',
'write_a_replay': 'Válasz írása…',
'year': 'Év',
'yes': 'IGEN',
'yes_start_the_round': 'Igen, kezdjük a kört',
'you_have_cashed_out': 'Kifizetést kértél, a nyereményed ',
'you_have_won': 'NYERTÉL',
'your_bet_is_lost': 'a fogadásod veszít.',
'current': 'Jelenlegi',
'previous': 'Előző',
'claim': 'Követelés',
'this_feature_will_give': 'Ez a funkció kiválasztott összeget ad a véletlenszerű felhasználóknak a chatben',
'amount_per_player': 'Játékosonkénti összeg',
'no_of_players': 'A játékosok száma',
'meteor_shower': 'Meteor zápor',
'claim_your_free_bet': 'Igényelje ingyenes fogadását',
'meteor_shower_total': 'Meteorzápor Összesen',
'shower': 'ESŐ',
'total': 'Teljes',
'meteor_shower_incoming': 'Meteorzápor érkezik, nézd meg a chat-et!',
'insurance': 'BIZTOSÍTÁS',
'insurance_paragraph': 'A játékosok megvásárolhatják a fogadásuk biztosítását a "Fogadásbiztosítás" jelölőnégyzet kiválasztásával a fogadás gomb mellett. A biztosítás költsége a fogadás teljes összegének 10%-a, és visszatéríti a fogadott összeget a játékosnak, ha a rakéta 1.00-es szorzóval felrobban.',
'insure_your_bet': 'BIZTOSÍTSA A TÉTÉT',
'bet_insurance_promo_text': 'Válassza a „Fogadásbiztosítás” opciót, hogy biztosítsa fogadását a rakéta lezuhanásától 1,00 szorzónál.',
'bet_insurance_promo_text_helper': 'Bővebben a "Játékszabályok" részben...',
'game_explanation_1': 'Válaszd ki a szorzót, és ha a játék szorzója meghaladja a választott szorzót, a nyereményed lesz',
'game_explanation_2': 'Az Ön szorzója x a tétje',
'retail_max_mp_1': 'Nyerj akár',
'retail_max_mp_2': 'a fogadásod!',
'play_multibets': 'Játék',
'multibets': 'MULTIBETS',
'bonus_game': 'BÓNUSZ JÁTÉK',
'all_wins_doubled': 'Minden győzelem megduplázódott!',
'multibets_paragraph_1': 'hogy növelje az esélyeit!',
'multibets_paragraph_2': 'A fogadásokat akár tíz körben is kombinálhatja egyetlen multitétté, és hatalmas nyereményeket nyerhet!',
'multibets_paragraph_3': 'Minden alkalommal, amikor egy kört megnyernek, az adott körből nyert osztalékot a következő körben fogadják meg, amíg minden kör nyer.',
'bonus_launch': 'Nyerj 2-szer minden fogadást a „Bónuszindítás” körben!',
'multiplier_history': 'Szorzótörténet',
'will_start_soon': 'hamarosan indul',
'hurry_up_and_place_your_bets': 'Siess és tedd meg a fogadásod!',
'active_bets': 'Aktív fogadások',
'shop': 'ÜZLET',
'game_meteor_shower_paragraph': 'A Meteor Shower egy előre meghatározott értékű ingyenes fogadást ad előre meghatározott számú játékosnak. Kezdeményezheti akár a szerver, akár egy játékos. A Meteor Shower aktiválása után egy üzenet jelenik meg a játékban, és arra utasítja a felhasználókat, hogy nézzék meg a csevegést, ahol a Meteor Shower üzenet található. A játékosok az „Igénylés” gombra kattintva igényelhetik az ingyenes fogadást. Az ingyenes fogadásokat azok a játékosok kapják, akik először kattintanak a „Claim” gombra. Tilos az autoclickerek vagy bármilyen automatizálási szoftver használata a játékosok kattintásainak emulálására és a nyeremények átvételére, és ennek a funkciónak a tiltását vonhatja maga után.',
'claming_failed': 'Az igénylés nem sikerült',
'player_on_blacklist': 'A játékos a feketelistán van',
'you_can_claim_ticket': 'Csak akkor igényelhet jegyet, ha aktív játékos, és rendszeresen fogad',
'you_already_have_free_tickets': 'Már van ingyenes jegyed, kérjük, először költsd el',
'please_play_the_game_in_portait_mode': 'Kérjük, játsszon a játékot álló módban.',
};
langArr.it = {
'your_insurance_returns_you': 'La tua assicurazione ti restituisce',
'bet_insurance': 'Assicurazione sulle scommesse',
'max_bet_is': 'La scommessa massima è',
'already_active_bet_of': 'e c\'è già una scommessa attiva di',
'1_from_game_history': '‎1. Dalla cronologia di gioco',
'2_in_settings_window': '‎2. Dalla finestra delle impostazioni',
'3_online_calculator': '‎3. Con un calcolatore online',
'active_tournament_1': 'Torneo attivo ',
'active_tournament_2': 'non ha puntata al momento.',
'add_another_bet': 'Aggiungi un’altra puntata',
'advanced_autoplay': 'IMPOSTAZIONI AVANZATE DEL GIOCO AUTOMATICO',
'advanced_autoplay_for_bet': 'Impostazioni avanzate del gioco automatico - Puntata',
'alien_saucer_free_bets': 'Puntate gratis del disco volante',
'all_bets': 'Tutte le puntate',
'all_in_your_hands': 'il destino è nelle tue mani!',
'allow': 'Consenti',
'at': 'da',
'austronaut_promo_credit': 'Credito promozionale dell’astronauta',
'auto_play_and_autocash': 'COME GIOCARE E RISCUOTERE AUTOMATICAMENTE ',
'autoplay': 'Gioco automatico',
'autoplay_and_cashout': '‎02. Puntate e riscossioni automatiche',
'back_to_home': 'Torna alla home',
'balance': 'Saldo',
'bet': 'PUNTA',
'bet_amount': 'IMPORTO DELLA PUNTATA',
'bet_and_cashout': '‎01. Puntate e riscossioni',
'bet_bets_tab': 'PUNTATA',
'bet_failed': 'Puntata non riuscita',
'bet_statistic': 'Statistiche delle puntate',
'bet_time': 'Orario puntata',
'bet_wins': 'Puntate vincenti',
'bets': 'Puntate',
'biggest_odds': 'Quote più alte',
'bonus_type': 'TIPO DI BONUS',
'bonusing_history': 'Cronologia bonus',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Vincitori del Boosterpot',
'but_remember_paragraph': 'Ricorda',
'calculate_raw_mp_to_decimal': 'Calcola il moltiplicatore di base dai decimali e lo approssima per difetto al numero intero più vicino',
'cancel': 'Annulla',
'cant_post_media_content': 'Non puoi pubblicare contenuti multimediali frequentemente.',
'cashed_out': 'Riscosso',
'cashout': 'Riscuoti',
'cashout_at': 'RISCUOTI A',
'cashout_before': 'Riscuoti prima che il razzo esploda e il denaro sarà tuo!',
'change_avatar': 'Cambia avatar',
'change_username': 'Cambia il nome utente',
'characters_long': 'caratteri',
'chat': 'Chat',
'chat_room': 'Chat',
'checking_for_spam': 'Verifica della posta indesiderata...',
'close': 'Chiudi',
'coeff': 'COEFF.',
'collect_win': 'Riscuoti la vincita',
'combined_seed': 'Seed combinato',
'company': 'Azienda',
'congratulations_you_have_won_free_bets': 'Congratulazioni! Hai vinto le puntate bonus! Puoi scommettere',
'convert_first_13_to_decimal': ' Conversione dei primi 13 byte in decimali',
'd': 'g',
'date': 'DATA',
'date_and_time': 'Data e ora',
'deny': 'Rifiuta',
'detailed_game_rules': 'Regole di gioco dettagliate',
'dissconnection_policy': 'POLITICA SULLE DISCONNESSIONI',
'easy_as_one_two_three': 'Semplice come contare fino a tre',
'enter_username': 'Inserisci il nome utente desiderato',
'error_handling': 'RISOLUZIONE DEI PROBLEMI',
'exit_full_screen': 'Esci dalla modalità a schermo intero',
'first': 'prima',
'first_time_playing': 'Stai giocando a "Rocketman” per la prima volta?',
'folow_the_three_easy_steps': 'Segui i tre semplici passi e divertiti con Rocketman! ',
'free_bets': 'PUNTATE GRATIS',
'free_bets_desc': 'Hai vinto delle puntate gratis! Puoi utilizzare le puntate gratis in qualsiasi momento premendo il pulsante di inizio.',
'free_bets_header': 'Puntate gratis',
'from': 'DA',
'fullscreen_mode': 'La partita avrà inizio a schermo intero per offrire un’esperienza d’uso migliore.',
'function': 'Funzione',
'game_functions': 'Funzioni del gioco',
'game_history': 'CRONOLOGIA',
'game_r_1_paragraph': 'È possibile attivare la funzione del gioco che consente di piazzare automaticamente una puntata in ogni nuovo round, utilizzando un importo pari a quello della puntata precedente.',
'game_r_10_paragraph': 'Una volta ricevuti i crediti promozionali, il denaro necessario a piazzare qualsiasi puntata del giocatore non sarà prelevato direttamente dal suo saldo reale ma dal saldo dei suoi crediti promozionali, fino al loro esaurimento.',
'game_r_11_paragraph': 'I crediti promozionali non possono essere prelevati ma le eventuali vincite ottenute con le loro puntate vengono interamente accreditate sul saldo del giocatore.',
'game_r_12_paragraph': 'I crediti promozionali sono validi per un periodo di 30 giorni, dopo il quale scadono e non possono più essere utilizzati.',
'game_r_13_paragraph': 'Delle puntate gratis potrebbero essere assegnate casualmente a un giocatore che verrà informato della vincita da una notifica sullo schermo, in cui saranno indicati il numero di puntate gratis e l’importo per puntata.',
'game_r_14_paragraph': 'Una volta ricevute le puntate gratis, il giocatore può decidere quando avviare il round corrispondente. All’inizio di questo round viene piazzata automaticamente una puntata dell’importo indicato ed è il giocatore a decidere il momento in cui riscuotere. ',
'game_r_15_paragraph': 'Le puntate gratis sono valide per un periodo di 30 giorni, dopo il quale scadono e non possono più essere utilizzate.',
'game_r_16a_paragraph': 'Il ritorno teorico complessivo per il giocatore è ',
'game_r_17_paragraph': 'Le percentuali di RTP vengono costantemente monitorate e verificate da terze parti indipendenti.',
'game_r_18_paragraph': 'Se la connessione viene interrotta dopo avere piazzato una puntata, quest’ultima non viene annullata; se il moltiplicatore raggiunge il valore di 20.000x, la puntata viene riscossa automaticamente e i crediti vengono versati sul conto.',
'game_r_19_paragraph': 'Durante la partita, i giocatori possono chattare tra di loro. Basta inserire un messaggio nel campo della chat oppure condividere una puntata vincente con gli altri giocatori.',
'game_r_2_paragraph': 'Il giocatore può inoltre inserire il valore di un moltiplicatore nel campo della riscossione automatica, in modo che il gioco riscuota il premio automaticamente se viene raggiunto il moltiplicatore del valore impostato.',
'game_r_20_paragraph': 'Gli utenti che abusano della chat molestando gli altri giocatori oppure utilizzando un linguaggio inappropriato e/o indecente non potranno più accedere a questa funzione.',
'game_r_21_paragraph': 'La musica di sottofondo e gli effetti sonori di Rocketman sono stati ideati per offrire un’esperienza d’uso ottima. Se si desidera disattivarli, basta accedere alle relative impostazioni disponibili nel menu a scomparsa.',
'game_r_22_paragraph': 'Le ultime puntate del giocatore possono essere visualizzate facendo clic sul pulsante “Le mie puntate”. Per consultare la cronologia delle partite relativa a un periodo più esteso basta accedere alla sezione corrispondente della piattaforma di gioco.',
'game_r_23_paragraph': 'Se si verifica un errore, i round di gioco potrebbero non avere inizio al momento prestabilito. In questo caso, i nostri agenti di supporto proveranno a risolvere il problema nel minor tempo possibile per riprendere i round. Nel caso in cui il problema fo',
'game_r_24_paragraph': 'La latenza della rete è un aspetto inevitabile della connessione a Internet. Consideriamo valide le puntate e le riscossioni esclusivamente nel momento in cui raggiungono il nostro server, a prescindere dalla durata del periodo di latenza della rete. ',
'game_r_3_paragraph': 'È possibile utilizzare i seguenti comandi per piazzare una puntata o riscuotere più rapidamente durante la partita',
'game_r_4_paragraph': 'L\'1% di ogni puntata è dedicato a un fondo jackpot progressivo. Questo fondo serve al sistema di jackpot progressivo',
'game_r_5_paragraph': ' ‎• Jackpot – livello massimo, premio più alto e minor frequenza;',
'game_r_6_paragraph': ' ‎• Rocketpot – livello e premio medi;',
'game_r_7_paragraph': ' ‎• Boosterpot – livello minimo, premio basso e maggior frequenza. ',
'game_r_8_paragraph': 'Tutti i livelli del montepremi vengono assegnati utilizzando un sistema di premiazione “misterioso” e basato su limiti. Subito dopo la vincita del montepremi precedente, un generatore di numeri casuali determina l’importo limite entro il quale deve essere',
'game_r_8a_paragraph': 'Nota: il vincitore del jackpot può essere un solo giocatore Rocketman che gioca attivamente nel round quando il jackpot è stato attivato. Non vi è alcuna possibilità che, se il giocatore ha vinto il jackpot, debba condividerlo con qualsiasi altro giocatore di Rocketman.',
'game_r_9_paragraph': 'Dei crediti promozionali potrebbero essere assegnati casualmente a un giocatore, che verrà informato della vincita e del suo importo da una notifica sullo schermo.',
'game_round': 'round!',
'game_rules': 'Regole di gioco',
'game_rules_1_paragraph': 'Rocketman offre la possibilità di scommettere in un modo totalmente innovativo. Potresti aggiudicarti diverse vincite in pochi secondi! Rocketman è sfrutta il',
'game_rules_10_paragraph': ' ‎• Il gioco automatico può essere attivato dalla sezione “Auto” del pannello della puntata, spuntando la casella “Puntata automatica”. Una volta attivata questa opzione, le puntate saranno piazzate automaticamente; per prelevare la vincita dovrai comunqu',
'game_rules_11_paragraph': '‎• La riscossione automatica è disponibile nella sezione “Auto” del pannello della puntata. Una volta attivata questa opzione, la tua puntata sarà automaticamente riscossa quando verrà raggiunto il coefficiente impostato.',
'game_rules_2_paragraph': 'che, al momento, costituisce l’unica garanzia effettiva di trasparenza nell’industria del gioco d’azzardo.',
'game_rules_3_paragraph': '‎• Il moltiplicatore di vincita parte da 1x e continua ad aumentare dal momento del lancio del razzo fortunato.',
'game_rules_4_paragraph': ' ‎• L’importo della tua vincita è pari al valore della tua puntata, al quale viene applicato il moltiplicatore con cui hai effettuato la riscossione. ',
'game_rules_5_paragraph': '‎• Prima dell’inizio di ogni round, il nostro generatore di numeri casuali basato su tecnologia Provably Fair crea il coefficiente, raggiunto il quale, il razzo fortunato esplode. Puoi verificare la trasparenza di questo processo facendo clic sull’opzione',
'game_rules_6_paragraph': '‎• Seleziona l’importo e premi il pulsante “Punta” per effettuare una puntata. ',
'game_rules_7_paragraph': '‎• È possibile piazzare due puntate contemporaneamente aggiungendo il pannello della seconda puntata. Per farlo basta premere il segno più posto nell’angolo in alto a destra del pannello della prima puntata.',
'game_rules_8_paragraph': ' ‎• Premi il pulsante “Riscuoti” per prelevare la tua vincita. La tua vincita è pari all’importo della puntata moltiplicato per il coefficiente di riscossione.',
'game_rules_9_paragraph': '‎• Se non riuscirai a riscuotere prima che il razzo esploda, perderai la tua puntata.',
'general_rules': 'REGOLAMENTO GENERALE',
'gifs': 'GIF',
'go_back': 'Indietro',
'good_job_buddy': 'Ottimo lavoro, continua così!',
'h': 'o',
'hashed_in_sha_512': 'Convertito in SHA512',
'hex': 'Esadecimali',
'hey_username': 'Ciao,',
'hide_hex_to_decimal': 'Nascondi gli esadecimali nella conversione in decimali',
'highscore': 'Punteggio alto',
'how_it_works': 'Come funziona?',
'how_to_check': 'Come può essere verificato?',
'how_to_play': 'Come si gioca',
'if_the_maximum_multiplier': '‎• Se il moltiplicatore raggiunge il suo valore massimo, pari a 20.000, questo viene applicato a tutte le puntate attive, che vengono automaticamente riscosse.',
'increase': 'Aumenta',
'input_bet_amount': 'Inserisci l’importo della puntata e confermala prima del lancio.',
'insufficient_funds': 'Fondi insufficienti',
'it_blew_up': 'È esploso! Che peccato...',
'jackpot': 'Montepremi',
'jackpot_winners': 'Vincitori del Jackpot',
'key': 'Tasto',
'load_more': 'MOSTRA ALTRO',
'loading': 'Caricamento',
'loading_next_round': 'Caricamento del prossimo round...',
'loss_gifs': 'PERDITA',
'lost_connection': 'Connessione interrotta',
'make_a_bet': 'Effettua una puntata',
'malfunction_voids': 'I malfunzionamenti annullano tutte le puntate e le vincite.',
'maximum_payout': 'Il pagamento massimo è ',
'menu': 'Menu',
'min_bet_is': 'La puntata minima è',
'month': 'Un mese',
'more_details': 'Maggiori informazioni',
'more_information': 'Spiegazione dettagliata',
'multiplier': 'Moltiplicatore',
'multiplier_starts': '‎• Il moltiplicatore ha un valore iniziale pari a 1 e può aumentare fino a 20.000;',
'multipliers_larger': 'I moltiplicatori superiori a x20.000 vengono riportati al valore massimo consentito',
'music_settings': 'Impostazioni musica',
'my_bet_history': 'Cronologia delle puntate',
'my_bets': 'Le mie puntate',
'my_bonysing_history': 'La mia cronologia bonus',
'network_latency': 'LATENZA DELLA RETE',
'network_latency_content': 'La latenza della rete è un aspetto inevitabile della connessione a Internet. Consideriamo valide le puntate e le riscossioni esclusivamente nel momento in cui raggiungono il nostro server, a prescindere dalla durata del periodo di latenza della rete.',
'network_latency_title': 'LATENZA DELLA RETE',
'no_thanks': 'No, grazie!',
'not_right_now': 'Non adesso',
'off': 'off',
'oh_snap': 'Incredibile!',
'ok_close': 'Ok! Chiudi',
'ok_thanks': 'Ok, grazie!',
'on': 'on',
'on_loss': 'IN CASO DI PERDITA',
'on_win': 'IN CASO DI VINCITA',
'online': 'Online',
'per_bet': 'per puntata',
'pick_your_avatar_img': 'Scegli l’immagine per il tuo avatar',
'place_bet_with_last': 'Piazza una puntata utilizzando l’ultimo importo selezionato ',
'play_now': 'Gioca subito',
'player_1_seed': 'Seed del giocatore 1',
'player_2_seed': 'Seed del giocatore 2',
'player_3_seed': 'Seed del giocatore 3',
'please_wait_active_bets': 'Ti preghiamo di attendere che le puntate attuali vengano completate per attivare le puntate gratis',
'points': 'PUNTI',
'powered_by': 'prodotto con la tecnologia di',
'previous_hand': 'Mano precedente',
'prize': 'PREMIO',
'prizes': 'premi',
'progressive_jackpot': 'MONTEPREMI PROGRESSIVO',
'promo_credit': 'Credito promozionale',
'promo_credit_condradulations': 'Congratulazioni! Hai vinto un credito promozionale stellare da utilizzare nel gioco!',
'promo_credits': 'CREDITI PROMOZIONALI',
'promo_history': 'Cronologia delle promozioni',
'provably_fair': 'Dimostrabilmente giusto',
'provably_fair_1_paragraph': 'Rocketman è stato ideato sulla base della tecnologia crittografica “Provably Fair”, la quale garantisce che il risultato della partita sia equo al 100%. In questo modo viene impedito a eventuali terze parti di interferire lo svolgimento del gioco.',
'provably_fair_2_paragraph': 'Il risultato di ogni round di gioco (ovvero il coefficiente “Fly away” della partita) non è generato dai nostri server ma con l’aiuto dei giocatori partecipanti, in modo del tutto trasparente. Grazie a questo sistema, è impossibile manipolare i risultati ',
'provably_fair_3_paragraph': 'Il risultato del round è generato da quattro giocatori indipendenti che partecipano alla partita.',
'provably_fair_4_paragraph': 'All’inizio del round, il gioco unisce il seed del server con tre seed del client. Con questo processo viene generato l’hash SHA512, da cui si ottiene il risultato della partita.',
'provably_fair_5_paragraph': 'Puoi verificare l’equità di ogni round di gioco facendo clic sull’icona verde. Nella finestra aperta saranno mostrati il seed del server, 3 coppie di seed dei giocatori, l’hash combinato e il risultato del round.',
'provably_fair_6_paragraph': 'La versione codificata in hash del seed del server per i prossimi round di gioco è disponibile nella finestra delle impostazioni (accedi al menu di navigazione > Provably Fair > Prossimo seed del server SHA256). Puoi inoltre modificare il tuo seed del cli',
'provably_fair_7_paragraph': 'La correttezza dell’hash può essere verificata con qualsiasi calcolatore online, tra cui',
'provably_fair_system': 'sistema Provably Fair',
'quick_explanation': 'Spiegazione sintetica',
'randomly_generated_server_hash': 'HASH del server generato casualmente',
'rank': 'POSIZIONE',
'raw_multiplier': 'moltiplicatore di base',
'raw_multiplier_to_edged': 'Dal valore di base a quello definitivo del moltiplicatore, utilizzando',
'remove_additional_bet': 'Rimuovi la puntata aggiuntiva',
'reset': 'Valore predefinito',
'return_to_player': 'RITORNO AL GIOCATORE',
'rocketman_is_pure_excitement': 'Rocketman è adrenalina pura! Tenta la sorte e punta alla vittoria',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Vincitori del Rocketpot',
'round': 'Round',
'round_id': 'Id round',
'round_multiplier': 'Moltiplicatore del round',
'round_results': 'Risultato del round',
'rounds': 'turni di gioco',
'rtp': 'RTP',
'rules': 'regole',
'rules_details': 'Regolamento del torneo',
'save': 'Salva',
'schedule': 'Programma',
'second': 'seconda',
'seconds_left': 'sec. rimanenti',
'server_hash': 'HASH del server',
'please_wait_current_bets': 'SI PREGA DI ATTENDERE IL COMPLETAMENTO DELLE SCOMMESSE CORRENTI',
'min_autocashout_value_must_be': 'IL VALORE MINIMO DI AUTOCASHOUT DEVE ESSERE',
'min_bet_value_must_be': 'IL VALORE MINIMO DELLA PUNTATA DEVE ESSERE',
'server_seed': 'Seed del server',
'session_dissconnected': 'Disconnessione dalla sessione',
'multisession_not_allowed': 'La multisessione non è consentita',
'set_auto_bet': 'IMPOSTA LA PUNTATA AUTOMATICA',
'set_auto_cashout': 'IMPOSTA LA RISCOSSIONE AUTOMATICA',
'shourtcut_keys': 'COMANDI RAPIDI',
'show_hex_to_deimal': 'Mostra gli esadecimali nella conversione in decimali',
'sound': 'AUDIO',
'sound_settigs': 'Impostazioni suoni',
'start_with_autoplay': 'Attiva il gioco automatico',
'stop_autoplay': 'INTERROMPI IL GIOCO AUTOMATICO',
'stop_on_loss': 'INTERROMPI CON UNA PERDITA NETTA DI',
'stop_on_loss_tooltip': 'Usa l’opzione “Interrompi con una perdita netta di',
'stop_on_profit': 'INTERROMPI CON UNA VINCITA NETTA DI',
'stop_on_profit_tooltip': 'Usa l’opzione “Interrompi con una vincita netta di',
'there_are_no_active_torunament': 'Nessun torneo attivo al momento.',
'third': 'terza',
'this_game_is': 'Questo gioco è stato',
'this_tool_recalculates': 'Questa funzione consente di ricalcolare il moltiplicatore a partire dall’hash della partita. Basta inserire l’hash di qualsiasi partita per verificarne il moltiplicatore.',
'time': 'ORA',
'time_frame': 'Periodo',
'to': 'A',
'toggle_animation': 'Attiva l’animazione',
'top_odds': 'Quote migliori',
'top_wins': 'Vincite migliori',
'total_bets': 'Puntate totali',
'tournament': 'Torneo',
'tournament_information': 'Informazioni sul torneo',
'try_advanced_autoplay': 'Prova la nuova funzionalità di riproduzione automatica avanzata!',
'uncredited_wins': 'Vincite non accreditate',
'user': 'UTENTE',
'user_and_points': 'UTENTE E PUNTI',
'username': 'Nome utente',
'username_must_be_at_least': 'Il nome utente deve contenere almeno',
'verify': 'Verifica',
'vip_popup_text': 'Puoi scegliere uno degli avatar speciali disponibili in esclusiva per i nostri membri VIP!',
'vip_popup_title': 'Congratulazioni! Adesso sei un giocatore VIP',
'waiting_for_the_next_round': 'In attesa del prossimo round...',
'warnings_are_calculated': ' ‎• Per calcolare le vincite è necessario applicare alla puntata il moltiplicatore ottenuto al momento della riscossione;',
'watch_take_off': 'Assisti al lancio del tuo razzo e guarda aumentare il moltiplicatore delle vincite.',
'watch_the_rocket': 'Segui il razzo',
'we_have_noticed': '‎\'Abbiamo notato che stai giocando a "Rocketman” per la prima volta. Ti piacerebbe vedere un breve video che ne spiega il funzionamento?’',
'week': 'Una settimana',
'welcome_to_rocketman': 'Ti diamo il benvenuto su Rocketman, un gioco divertente e dal funzionamento innovativo! Rocketman offre la possibilità di vincere fino a 20.000x la puntata iniziale.',
'what_is_provably_fair': 'Cos’è Provably Fair?',
'will_be_deposited': 'Saranno accreditate sul saldo dei giocatori con la prima vincita regolare',
'win': 'VINCITA',
'win_amount': 'Importo vincita',
'win_gifs': 'VINCITA',
'winner': 'vincitore',
'wins': 'vince',
'write_a_replay': 'Rispondi',
'year': 'Un anno',
'yes': 'SÌ',
'yes_start_the_round': 'Sì, avvia il round',
'you_have_cashed_out': 'Hai riscosso la tua vincita che è pari a ',
'you_have_won': 'HAI VINTO',
'your_bet_is_lost': 'perderai la tua puntata.',
'current': 'Attuale',
'previous': 'Precedente',
'claim': 'Reclamo',
'this_feature_will_give': 'Questa funzione darà l\'importo selezionato a utenti casuali in chat',
'amount_per_player': 'Importo per giocatore',
'no_of_players': 'Numero di giocatori',
'meteor_shower': 'Pioggia di meteoriti',
'claim_your_free_bet': 'Richiedi la tua scommessa gratuita',
'meteor_shower_total': 'Totale dello sciame meteorico',
'shower': 'PIOVERE',
'total': 'Totale',
'meteor_shower_incoming': 'Pioggia di meteoriti in arrivo, guarda la chat!',
'insurance': 'ASSICURAZIONE',
'insurance_paragraph': 'I giocatori possono acquistare un\'assicurazione per la loro scommessa cliccando sulla casella "Assicurazione Scommessa" accanto al pulsante di scommessa. L\'assicurazione costa il 10% dell\'importo totale scommesso e restituirà l\'importo scommesso al giocatore se il razzo esploderà con un moltiplicatore di 1,00.',
'insure_your_bet': 'ASSICURA LA TUA SCOMMESSA',
'bet_insurance_promo_text': 'Seleziona l\'opzione "Assicurazione scommessa" per assicurare la tua scommessa dallo schianto del razzo con moltiplicatore 1,00.',
'bet_insurance_promo_text_helper': 'Maggiori informazioni nella sezione "Regole del gioco"...',
'game_explanation_1': 'Scegli il tuo moltiplicatore e, se il moltiplicatore del gioco supera il moltiplicatore scelto, la tua vincita sarà',
'game_explanation_2': 'Il tuo moltiplicatore x la tua scommessa',
'retail_max_mp_1': 'Vinci fino a',
'retail_max_mp_2': 'la tua scommessa!',
'play_multibets': 'Giocare',
'multibets': 'SCOMMESSE MULTIPLE',
'bonus_game': 'GIOCO BONUS',
'all_wins_doubled': 'Tutte le vincite raddoppiate!',
'multibets_paragraph_1': 'per aumentare le tue probabilità!',
'multibets_paragraph_2': 'Puoi combinare le scommesse su un massimo di dieci round in un\'unica scommessa multipla e vincere enormi vincite!',
'multibets_paragraph_3': 'Ogni volta che viene vinto un round, il dividendo vincente di quel round viene scommesso sul round successivo, finché tutti i round non risultano vincenti.',
'bonus_launch': 'Vinci x2 su tutte le scommesse nel round "Lancio bonus"!',
'multiplier_history': 'Storia del moltiplicatore',
'will_start_soon': 'inizierà presto',
'hurry_up_and_place_your_bets': 'Affrettati e piazza le tue scommesse!',
'active_bets': 'Scommesse attive',
'shop': 'NEGOZIO',
'game_meteor_shower_paragraph': 'Pioggia di meteoriti assegna una scommessa gratuita di un valore predefinito a un numero predefinito di giocatori. Può essere avviato dal server o da un giocatore. Una volta attivato Meteor Shower, viene visualizzato un messaggio nel gioco, che invita gli utenti a guardare la chat in cui si trova il messaggio Meteor Shower. I giocatori possono richiedere la scommessa gratuita facendo clic sul pulsante “Richiedi”. Le scommesse gratuite vengono assegnate ai giocatori che fanno prima clic sul pulsante "Richiedi". È vietato utilizzare clic automatici o qualsiasi tipo di software di automazione per emulare i clic dei giocatori e richiedere premi e potrebbe comportare il divieto di questa funzionalità.',
'claming_failed': 'Rivendicazione fallita',
'player_on_blacklist': 'Il giocatore è nella lista nera',
'you_can_claim_ticket': 'Puoi richiedere il biglietto solo se sei un giocatore attivo con scommesse regolari',
'you_already_have_free_tickets': 'Hai già biglietti gratuiti, spendili prima',
'please_play_the_game_in_portait_mode': 'Si prega di giocare al gioco in modalità verticale.',
};
langArr.mk = {
'your_insurance_returns_you': 'Вашето осигурување ве враќа',
'bet_insurance': 'Осигурување за обложување',
'max_bet_is': 'Максималниот облог е',
'already_active_bet_of': 'и веќе постои активен облог на',
'1_from_game_history': '‎1. Од историјата на играта',
'2_in_settings_window': '‎2. Во прозорчето за поставки',
'3_online_calculator': '‎3. Онлајн дигитрон',
'active_tournament_1': 'Активниот турнир ',
'active_tournament_2': 'сѐ уште нема облози.',
'add_another_bet': 'Додадете друг облог',
'advanced_autoplay': 'НАПРЕДНО ИГРАЊЕ',
'advanced_autoplay_for_bet': 'Напредно Автоматско играње при облог',
'alien_saucer_free_bets': 'Бесплатни облози со вонземјанско летало',
'all_bets': 'Сите облози',
'all_in_your_hands': 'Сѐ е во ваши раце!',
'allow': 'Дозволи',
'at': 'по',
'austronaut_promo_credit': 'Астронаут промотивен кредит',
'auto_play_and_autocash': 'АВТОМАТСКО ИГРАЊЕ И АВТОМАТСКА ИСПЛАТА ',
'autoplay': 'Автоматско играње',
'autoplay_and_cashout': '‎02. Автоматско играње и автоматска исплата',
'back_to_home': 'Назад на главната страница',
'balance': 'Биланс',
'bet': 'ОБЛОГ',
'bet_amount': 'ИЗНОС НА БОНУС',
'bet_and_cashout': '‎01. Облог и исплата',
'bet_bets_tab': 'ВЛОГ',
'bet_failed': 'Неуспешен облог',
'bet_statistic': 'Статистика на облози',
'bet_time': 'Време на облогот',
'bet_wins': 'Добитни облози',
'bets': 'Облози',
'biggest_odds': 'Најголеми коефициенти',
'bonus_type': 'ТИП НА БОНУСИ',
'bonusing_history': 'Историја на бонуси',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Добитници на Boosterpot',
'but_remember_paragraph': 'Внимавајте, ако немавте време да направите Исплата пред да експлодира ракетата,',
'calculate_raw_mp_to_decimal': 'Пресметајте го бруто множителот со децимална вредност, па заокружете го до најблискиот цел број',
'cancel': 'Откажи',
'cant_post_media_content': 'Не можете често да постирате медиумски содржини',
'cashed_out': 'Исплатено',
'cashout': 'Исплата',
'cashout_at': 'ИСПЛАТА НА',
'cashout_before': 'Побарајте исплата пред Ракетата да експлодира и парите се ваши!',
'change_avatar': 'Сменете аватар',
'change_username': 'Промена на корисничко име',
'characters_long': 'карактери',
'chat': 'Чет',
'chat_room': 'Чет соба',
'checking_for_spam': 'Проверка за спам...',
'close': 'Затвори',
'coeff': 'КОЕФ.',
'collect_win': 'Земете ја добивката',
'combined_seed': 'Комбиниран сид',
'company': 'Компанија',
'congratulations_you_have_won_free_bets': 'Честитки! Освоивте Бонус облози! Можете да обложите',
'convert_first_13_to_decimal': ' Конвертирајте ги првите 13 бајтови во децимала',
'd': 'д',
'date': 'ДАТУМ',
'date_and_time': 'Датум и време',
'deny': 'Одбиј',
'detailed_game_rules': 'Детални правила на играта',
'dissconnection_policy': 'ПОЛИТИКА ЗА ИСКЛУЧУВАЊЕ',
'easy_as_one_two_three': 'Лесно како еден, два, три',
'enter_username': 'Ве молиме, внесете го саканото корисничко име',
'error_handling': 'ГРЕШКА ПРИ РАКУВАЊЕ',
'exit_full_screen': 'Излезете од цел екран',
'first': 'прва',
'first_time_playing': 'Првпат играте "Rocketman"?',
'folow_the_three_easy_steps': 'Следете ги овие три лесни чекори и уживајте во Rocketman! ',
'free_bets': 'БЕСПЛАТНИ ОБЛОЗИ',
'free_bets_desc': 'Освоивте бесплатни облози! Можете да ги искористите бесплатните облози во било ко време со стискање на копчето за старт.',
'free_bets_header': 'Бесплатни облози',
'from': 'ОД',
'fullscreen_mode': 'За подобро играчко искуство, играта ќе почне во режим на цел екран.',
'function': 'Функција',
'game_functions': 'Функции на играта',
'game_history': 'ИСТОРИЈА НА ИГРИ',
'game_r_1_paragraph': 'Играчот може да ја активира опцијата за автоматска исплата кога автоматски се уплаќа облог за секоја нова рунда користејќи го износот на влогот од претходниот облог.',
'game_r_10_paragraph': 'Откако се доделени промо кредитите, за било кој облог уплатен од играчот, парите ќе бидат повлечени од промо кредитите се додека не се искористат, пред да се продолжи со повлекување средства од новчаникот на играчот.',
'game_r_11_paragraph': 'Не може да се направи исплата на промо кредитите, но секоја добивка од облози со нив целосно се префрла на сметката на играчот.',
'game_r_12_paragraph': 'Промо кредитите имаат рок на важење од 30 дена, после што истекуваат и не можат да се искористат.',
'game_r_13_paragraph': 'На играч може по случаен избор да му се доделат бесплатни облози. Скокнувачка графика ќе се појави на екранот да го извести играчот за доделените бесплатнипромо кредити и нивниот износ.',
'game_r_14_paragraph': 'Откако ќе му бидат доделени бесплатни облози, играчот може да избере кога да ја започне рундата за бесплатен облог. Откако таа рунда ќе започне, се уплаќа автоматски облог со утврден влог, а зависи од играчот во кој момент ќе направи исплата. Ако играчот ',
'game_r_15_paragraph': 'Бесплатните облози имаат рок на важење од 30 дена, после што истекуваат и не можат да се искористат.',
'game_r_16a_paragraph': 'Вкупната теоретска исплата за играчот е ',
'game_r_17_paragraph': 'Процентите на RTP постојано се следат и потврдуваат од независни трети страни.',
'game_r_18_paragraph': 'Ако се прекине врската откако е уплатен облог, облогот нема да биде поништен. Ако множител достигне х20.000, вашиот облог ќе биде автоматски исплатен и кредитите ќе бидат впишани на вашата сметка.',
'game_r_19_paragraph': 'Можете да разговарате со другите играчи во играта. Внесете ја пораката во полето за чет, или споделете го добитниот облог со другите играчи.',
'game_r_2_paragraph': 'Множител за автоматска исплата може да се внесе во полето “Автоматска исплата“, а откако ќе се внесе, играта автоматски ќе направи исплата ако е достигнат поставениот множител.',
'game_r_20_paragraph': 'На играчите кои го злоупотребуваат четот со тоа што малтретираат други играчи, или кои користат несоодветен и/или погрден јазик ќе им бидат исклучени привилегиите за Чет.',
'game_r_21_paragraph': 'Rocketman е дизајниран да има прекрасна музика во позадина и убави звучни ефекти. Ако сакате да ја исклучите музиката и/или звучните ефекти, тоа можете да го направите во паѓачкото мени.',
'game_r_22_paragraph': 'Со кликање на копчето “Мои облози“, играчот може да ги прегледа своите последни облози. За да ја види историјата на играње (т.е. подолги периоди), играчот може да треба да пристапи до историјата на играчот во рамките на самата играчка платформа.',
'game_r_23_paragraph': 'Во случај на грешка, рундите може да не започнат на време. Во тој случај, нашата служба за поддршка ќе се обиде да го репи проблемот во најкус можен рок за да продолжат регуларните рунди на играта. Во случај да се јави проблем со самата рунда во играта, с',
'game_r_24_paragraph': 'Латентноста на мрежата е неизбежен дел од интернет конекцијата. Ние ги сметаме за валидни сите облози и исплати само во моментот кога ќе стигнат на нашиот сервер, без разлика на времетраењето на мрежната латентност. ',
'game_r_3_paragraph': 'Играчот може да користи копчиња за брз пристап за брзо уплаќање на облог или исплата во играта. Мапата на копчињата е следната',
'game_r_4_paragraph': '1% од секој облог се посветува на фонд за прогресивен џекпот. Овој фонд служи за прогресивниот џекпот систем',
'game_r_5_paragraph': ' ‎• Jackpot – Највисокото ниво, најголемата награда, најретко',
'game_r_6_paragraph': ' ‎• Rocketpot – Средно ниво, средна награда',
'game_r_7_paragraph': ' ‎• Boosterpot – Најниското ниво, основна награда, најчесто ',
'game_r_8_paragraph': 'Сите џекпот нивоа се доделуваат користејќи ги механизмите на “мистериозни’ или ‘мора-да-падне-до“ џекпоти. Вредноста на секој “мора-да-падне-до“ џекпот се утврдува веднаш откако претходниот џекпот е освоен од генератор на случајни броеви и зачуван како ен',
'game_r_8a_paragraph': 'Забелешка: Добитник на џекпот може да биде само еден играч на Rocketman кој активно игра во кругот кога џекпотот е активиран. Не постои можност доколку играчот го освоил џекпотот, ќе мора да го сподели со кој било друг играч на Rocketman.',
'game_r_9_paragraph': 'На играч може по случаен избор да му се доделат промо кредити. Скокнувачка графика ќе се појави на екранот да го извести играчот за доделените промо кредити и нивниот износ.',
'game_round': 'рунда на играта!',
'game_rules': 'Правила на играта',
'game_rules_1_paragraph': 'Рокетмен е игра на среќа за новата генерација. Ве делат неколку секунди од големи добивки! Рокетмен е изграден на',
'game_rules_10_paragraph': ' ‎• Автоматското играње се активира од табот “Авто’ во Панелот за облог, со штиклирање на квадратчето “Автоматски облог“. Откако ќе биде активирано, облозите автоматски ќе се уплаќаат, но ако сакате исплата, треба да стиснете на копчето “Исплата“ во секој',
'game_rules_11_paragraph': '‎• Автоматската исплата е достапна на табот “Авто“ на панелот за облогот. Откако ќе се активира, вашиот облог ќе биде автоматски исплатен кога ќе се постигне внесениот коефициент.',
'game_rules_2_paragraph': '‎, кој моментално е единствената вистинска гаранција за чесност во индустријата на игри на среќа.',
'game_rules_3_paragraph': '‎• Добитниот множител започнува со 1x и се зголемува сѐ повеќе како што Среќната ракета полетува.',
'game_rules_4_paragraph': ' ‎• Вашата добивка е множителот кога сте побарале Исплата помножен со вашиот облог. ',
'game_rules_5_paragraph': '‎• Пред почетокот на секоја рунда, нашиот докажливо фер генератор на случајни броеви го генерира коефициентот со кој ќе одлета Среќната ракета. Можете да ја проверите чесноста на генерирањето со кликање наспроти резултатот во табот за Историја.',
'game_rules_6_paragraph': '‎• Изберете износ и стиснете на копчето “Облог“ за да се обложите. ',
'game_rules_7_paragraph': '‎• Можете истовремено да уплатите два облози, со додавање на панел за втор облог. За да додадете панел за втор облог стиснете на иконата со плус, која се наоѓа во горниот десен агол на панелот за првиот облог.',
'game_rules_8_paragraph': ' ‎• Стиснете на копчето “Исплата“ за да побарате исплата на вашата добивка. Вашата добивка е облогот помножен со коефициентот за исплата.',
'game_rules_9_paragraph': '‎• Вашиот облог е губитен, ако не сте побарале исплата пред ракетата да експлодира.',
'general_rules': 'ОПШТИ ПРАВИЛА',
'gifs': 'GIFS',
'go_back': 'Назад',
'good_job_buddy': 'Добра работа, само продолжи така',
'h': 'ч',
'hashed_in_sha_512': 'Хаш во SHA512',
'hex': 'Хекс',
'hey_username': 'Здраво',
'hide_hex_to_decimal': 'Скријте ја конверзијата од хекс до децимална вредност',
'highscore': 'Висок резултат',
'how_it_works': 'Како функционира?',
'how_to_check': 'Како да проверите?',
'how_to_play': 'Како се игра',
'if_the_maximum_multiplier': '‎• Ако се достигне максималниот множител од 20.000, автоматски се врши исплата на сите активни облози со максималниот множител',
'increase': 'Зголеми',
'input_bet_amount': 'Внесете го износот на облогот и уплатете облог пред полетувањето.',
'insufficient_funds': 'Недоволно средства',
'it_blew_up': 'Експлодира! Што да се прави...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Добитници на Jackpot',
'key': 'Копче',
'load_more': 'ВЧИТАЈ ПОВЕЌЕ',
'loading': 'Се вчитува',
'loading_next_round': 'Се вчитува следната рунда…',
'loss_gifs': 'ПОРАЗ',
'lost_connection': 'Изгубена врска',
'make_a_bet': 'Уплатете облог',
'malfunction_voids': 'Заради грешка сите Исплати и Игри.',
'maximum_payout': 'Максималната исплата е ',
'menu': 'Мени',
'min_bet_is': 'Мин облог е',
'month': 'Месец',
'more_details': 'Повеќе детали',
'more_information': 'Повеќе информации',
'multiplier': 'Множител',
'multiplier_starts': '‎• Множителот почнува со 1 и се зголемува до 20.000',
'multipliers_larger': 'Множителите поголеми од х20.000 се ограничени со максималната вредност на множител',
'music_settings': 'Поставки за музика',
'my_bet_history': 'Историја на моите облози',
'my_bets': 'Мои облози',
'my_bonysing_history': 'Мојата историја на бонуси',
'network_latency': 'ЛАТЕНТОСТ НА МРЕЖАТА',
'network_latency_content': 'Латентноста на мрежата е неизбежен дел од интернет конекцијата. Ние ги сметаме за валидни сите облози и исплати само во моментот кога ќе стигнат на нашиот сервер, без разлика на времетраењето на мрежната латентност.',
'network_latency_title': 'ЛАТЕНТОСТ НА МРЕЖАТА',
'no_thanks': 'Не, благодарам!',
'not_right_now': 'Не сега',
'off': 'искл.',
'oh_snap': 'Ох, не!',
'ok_close': 'Ок, затвори!',
'ok_thanks': 'Ок, благодарам!',
'on': 'вкл.',
'on_loss': 'ПРИ ЗАГУБА',
'on_win': 'ПРИ ДОБИВКА',
'online': 'Онлајн',
'per_bet': 'по облог',
'pick_your_avatar_img': 'Изберете слика на аватарот',
'place_bet_with_last': 'Уплатете облог со последно избраниот облог ',
'play_now': 'Играјте сега',
'player_1_seed': 'Сид на Играч 1',
'player_2_seed': 'Сид на Играч 2',
'player_3_seed': 'Сид на Играч 3',
'please_wait_active_bets': 'Ве молиме, почекајте тековните облози да завршат за да ги активирате бесплатните облози',
'points': 'ПОЕНИ',
'powered_by': 'овозможена од',
'previous_hand': 'Претходна рака',
'prize': 'НАГРАДА',
'prizes': 'награди',
'progressive_jackpot': 'ПРОГРЕСИВЕН ЏЕКПОТ',
'promo_credit': 'Промотивен кредит',
'promo_credit_condradulations': 'Честитки! Освоивте астрономски добар промотивен кредит што може да го искористите во играта!',
'promo_credits': 'ПРОМО КРЕДИТИ',
'promo_history': 'Историја на промоции',
'provably_fair': 'Докажливо фер',
'provably_fair_1_paragraph': 'Рокетмен е заснован на криптографска технологија наречена ‘Докажливо фер’. Оваа технологија гарантира 100% фер резултат на играта. Со оваа технологија е невозможно некоја трета страна да се вмеша во процесот на играта.',
'provably_fair_2_paragraph': 'Резултатот од секоја рунда (коефициентот за “Одлетување“ во играта) не се генерира на нашите сервери. Тој се генерира со помош на играчите во рундата и е целосно транспарентен. На овој начин, невозможно е било кој да манипулира со исходот на играта. Исто ',
'provably_fair_3_paragraph': 'Резултатот на рундата е генериран од четирите независни учесници во рундата',
'provably_fair_4_paragraph': 'Кога почнува рундата, играта ги спојува сидовите на серверот со сидовите на трите клиенти. Од споените симболи се генерира хашот SHA512, а од овој хаш – резултатот на играта.',
'provably_fair_5_paragraph': 'Можете да проверите колку е фер секоја рунда во историјата на играта, со кликање на зелената икона. Во отвореното прозорче, ќе го видите сидот на серверот, 3 пара на сидови на играчите, комбинираниот хаш и резултатот на рундата.',
'provably_fair_6_paragraph': 'Хаш верзијата на сидот на серверот за следните рунди е јавно достапна во прозорчето за поставки (во корисничкото мени, проверете ги “Поставки за Докажливо фер“, а потоа “Следен сид на сервер SHA256“). Можете, исто така, да го промените вашиот сид на клиен',
'provably_fair_7_paragraph': 'Точноста на хашот може да се провери со било кој онлајн дигитрон, на пример',
'provably_fair_system': 'докажливо фер систем',
'quick_explanation': 'Брзо објаснување',
'randomly_generated_server_hash': 'Случајно генериран ХАШ на серверот',
'rank': 'ПЛАСМАН',
'raw_multiplier': 'бруто множител',
'raw_multiplier_to_edged': 'Од бруто множител до заокружен множител, со помош на',
'remove_additional_bet': 'Отстранете го дополнителниот облог',
'reset': 'Ресетирај',
'return_to_player': 'ВРАТЕТЕ СЕ НА ИГРАЧОТ',
'rocketman_is_pure_excitement': 'Рокетмен е чиста возбуда! Ризикувајте и освојувајте.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Добитници на Rocketpot',
'round': 'Рунда',
'round_id': 'ID на рундата',
'round_multiplier': 'Множител за рундата',
'round_results': 'резултати на рундите',
'rounds': 'рунди',
'rtp': 'RTP',
'rules': 'правила',
'rules_details': 'Правила за турнирот',
'save': 'Зачувај',
'schedule': 'распоред',
'second': 'втора',
'seconds_left': 'с останати',
'server_hash': 'ХАШ на серверот',
'please_wait_current_bets': 'ВЕ МОЛИМЕ ПОЧЕКАЈТЕ ДА СЕ ЗАПОЛНУВААТ ТЕКОВНИТЕ ОБЛОЗИ  ',
'min_autocashout_value_must_be': 'МОРА ДА БИДЕ МИНИНАТА ВРЕДНОСТ НА АВТОМАТСКИ ПОВРШУВАЊЕ',
'min_bet_value_must_be': 'МОРА ДА БИДЕ МИНИНАТА ВРЕДНОСТ НА ОБЛОЖУВАЧИТЕ',
'server_seed': 'Сид на серверот',
'session_dissconnected': 'Сесијата е прекината',
'multisession_not_allowed': 'Мултисесиите не се дозволени',
'set_auto_bet': 'ПОСТАВЕТЕ АВТО. ОБЛОГ',
'set_auto_cashout': 'ПОСТАВЕТЕ АВТО. ИСПЛАТА',
'shourtcut_keys': 'КОПЧИЊА ЗА БРЗ ПРИСТАП',
'show_hex_to_deimal': 'Покажете ја конврзијата од хекс до децимална вредност',
'sound': 'ЗВУК',
'sound_settigs': 'Поставки за звук',
'start_with_autoplay': 'Почни со автоматско играње',
'stop_autoplay': 'ЗАПРИ АВТО. ИГРАЊЕ',
'stop_on_loss': 'ЗАПРИ ПРИ ЗАГУБА',
'stop_on_loss_tooltip': 'Користете го Запри при загуба за да го запрете автоматското обложување откако ќе изгубите одреден износ.<br/>На пример, ако почнете со 100$ и го поставите Запри при загуба на 25$',
'stop_on_profit': 'ЗАПРИ ПРИ ПРОФИТ',
'stop_on_profit_tooltip': 'Користете го Запри при профит за да го запрете автоматското обложување откако ќе имате профит во одреден износ.<br/>На пример, ако почнете со 100$ и го поставите Запри при профит на 25$',
'there_are_no_active_torunament': 'Нема активни турнири во моментов.',
'third': 'трета',
'this_game_is': 'Оваа игра е',
'this_tool_recalculates': 'Оваа алатка повторно го пресметува множителот од хаш алгоритамот на играта. Можете да пресликате внатре било кој хаш алгоритам на игра за да го потврдите множителот.',
'time': 'ВРЕМЕ',
'time_frame': 'Временска рамка',
'to': 'ДО',
'toggle_animation': 'Смени анимација',
'top_odds': 'Топ коефициенти',
'top_wins': 'Топ добитни облози',
'total_bets': 'Вкупно облози',
'tournament': 'Турнир',
'tournament_information': 'Информации за турнирот',
'try_advanced_autoplay': 'Пробајте ја новата напредна функција за автоматско репродукција!',
'uncredited_wins': 'Невпишаните победи',
'user': 'КОРИСНИК',
'user_and_points': 'КОРИСНИК И ПОЕНИ',
'username': 'Корисничко име',
'username_must_be_at_least': 'Корисничкото име мора да има барем',
'verify': 'Потврди',
'vip_popup_text': 'Можете да изберете еден од VIP аватарите кои се достапни ексклузивно само за нашите VIP членови!',
'vip_popup_title': 'Честитки што станавте VIP играч',
'waiting_for_the_next_round': 'Се чека следната рунда...',
'warnings_are_calculated': ' ‎• Добивката се пресметува со множење на влогот со множителот во моментот на Исплата',
'watch_take_off': 'Гледајте како Ракетата полетува и множителот на добивката се зголемува.',
'watch_the_rocket': 'Гледајте ја ракетата',
'we_have_noticed': '‎‘Забележавме дека за првпат играте "Rocketman". Дали би сакале да видите кусо видео за тоа како се игра?`',
'week': 'Недела',
'welcome_to_rocketman': 'Добредојдовте во Rocketman – нова возбудлива игра! Со Rocketman имате шанса да заработите до х20,000 од вашиот почетен влог.',
'what_is_provably_fair': 'Што е Докажливо фер?',
'will_be_deposited': 'Ќе бидат впишани на билансот на играчот со првата регуларна победа',
'win': 'ПОБЕДА',
'win_amount': 'Освоен износ',
'win_gifs': 'ПОБЕДА',
'winner': 'Победник',
'wins': 'Победи',
'write_a_replay': 'Напишете одговор',
'year': 'Година',
'yes': 'ДА',
'yes_start_the_round': 'Да, почнете ја рундата',
'you_have_cashed_out': 'Вие сте исплатени, вашата добивка е',
'you_have_won': 'ОСВОИВТЕ',
'your_bet_is_lost': 'вашиот облог е губитен.',
'current': 'Актуелни',
'previous': 'Претходни',
'claim': 'Побарување',
'this_feature_will_give': 'Оваа функција ќе им даде избрана сума на случајни корисници во разговорот',
'amount_per_player': 'Износ по играч',
'no_of_players': 'Број на играчи',
'meteor_shower': 'Метеорски дожд',
'claim_your_free_bet': 'Побарајте го вашиот бесплатен облог',
'meteor_shower_total': 'Вкупно метеорски дожд',
'shower': 'ДОЖД',
'total': 'Вкупно',
'meteor_shower_incoming': 'Доаѓа метеорски дожд, погледнете на разговор!',
'insurance': 'ОСИГУРУВАЊЕ',
'insurance_paragraph': 'Играчите можат да закупат осигурување за нивната обложување со кликање на полето "Осигурување на облог" до копчето за обложување. ОсигуруИграчите можат да закупат осигурување за нивната обложување со кликање на полето "Осигурување на облог" до копчето за обложување. Осигурувањето чини 10% од вкупниот износ на облогот и ќе врати износ на облогот на играчот ако ракетата експлодира со множител 1,00.',
'insure_your_bet': 'ОСИГУРИТЕ ГО ВАШИОТ Влог',
'bet_insurance_promo_text': 'Изберете ја опцијата „Осигурување на облози“ за да го осигурате вашиот облог од паѓање на ракета на множител 1,00.',
'bet_insurance_promo_text_helper': 'Прочитајте повеќе во делот „Правила на играта“...',
'game_explanation_1': 'Изберете го вашиот множител и ако множителот на играта го надмине избраниот множител, вашата победа ќе биде',
'game_explanation_2': 'Вашиот мултипликатор x вашиот облог',
'retail_max_mp_1': 'Победи до',
'retail_max_mp_2': 'вашиот облог!',
'play_multibets': 'Играј',
'multibets': 'МУЛТИБЕТИ',
'bonus_game': 'БОНУС ИГРА',
'all_wins_doubled': 'Сите победи се удвоени!',
'multibets_paragraph_1': 'да ги зголемите вашите шанси!',
'multibets_paragraph_2': 'Може да комбинирате облози до десет рунди во еден мултиоблог и да добивате огромни исплати!',
'multibets_paragraph_3': 'Секој пат кога се добива едно коло, добитната дивиденда од таа рунда се обложува во следното коло, додека не се добијат сите рунди.',
'bonus_launch': 'Победи x2 на сите облози во рундата „Bonus Launch“!',
'multiplier_history': 'Историја на мултипликатор',
'will_start_soon': 'ќе започне наскоро',
'hurry_up_and_place_your_bets': 'Побрзајте и ставете ги вашите облози!',
'active_bets': 'Активни облози',
'shop': 'ПРОДАВНИЦА',
'game_meteor_shower_paragraph': 'Метеорскиот дожд доделува еден бесплатен облог со однапред дефинирана вредност на однапред дефиниран број играчи. Може да се иницира или од серверот или од играчот. Штом ќе се активира Метеорскиот дожд, пораката се појавува во играта, насочувајќи ги корисниците да погледнат во разговорот каде што се наоѓа пораката од Метеорскиот дожд. Играчите можат да го побараат бесплатниот облог со кликнување на копчето „Побарување“. Бесплатните облози се доделуваат на играчи кои прво ќе кликнат на копчето „Побарување“. Користењето автоматски кликери или каков било софтвер за автоматизација за емулирање на кликнувања на играчи и барање награди е забрането и може да резултира со забрана на оваа функција.',
'claming_failed': 'Приговорот не успеа',
'player_on_blacklist': 'Играчот е на црната листа',
'you_can_claim_ticket': 'Може да барате тикет само ако сте активен играч со редовни облози',
'you_already_have_free_tickets': 'Веќе имате бесплатни билети, прво потрошете ги',
'please_play_the_game_in_portait_mode': 'Ве молиме играјте ја играта во портрет режим.',
};
langArr.pl = {
'your_insurance_returns_you': 'Twoje ubezpieczenie zwraca Ci pieniądze',
'bet_insurance': 'Ubezpieczenie zakładów',
'max_bet_is': 'Maksymalny zakład jest',
'already_active_bet_of': 'i istnieje już aktywny zakład',
'1_from_game_history': '‎1. W historii gry',
'2_in_settings_window': '‎2. W oknie ustawień',
'3_online_calculator': '‎3. Kalkulator online',
'active_tournament_1': 'Trwający turniej ',
'active_tournament_2': 'nie ma jeszcze zakładów.',
'add_another_bet': 'Dodaj kolejny zakład',
'advanced_autoplay': 'ZAAWANSOWANA AUTOGRA',
'advanced_autoplay_for_bet': 'Zaawansowana autogra, zakład nr',
'alien_saucer_free_bets': 'Darmowe zakłady kosmicznych spodków',
'all_bets': 'Wszystkie zakłady',
'all_in_your_hands': 'Wszystko w Twoich rękach!',
'allow': 'Zezwól',
'at': 'za',
'austronaut_promo_credit': 'Promocyjne kredyty kosmonauty',
'auto_play_and_autocash': 'AUTOGRA I AUTOWYPŁATA ',
'autoplay': 'Autogra',
'autoplay_and_cashout': '‎02. Automatyczna gra i wypłata',
'back_to_home': 'Wróć na start',
'balance': 'Saldo',
'bet': 'POSTAW',
'bet_amount': 'KWOTA ZAKŁADU',
'bet_and_cashout': '‎01. Stawianie zakładu i wypłata',
'bet_bets_tab': 'STAWKA',
'bet_failed': 'Zakład nieudany',
'bet_statistic': 'Statystyki zakładu',
'bet_time': 'Czas zakładu',
'bet_wins': 'Wygrane z zakładów',
'bets': 'Zakłady',
'biggest_odds': 'Największe kursy',
'bonus_type': 'RODZAJ BONUSU',
'bonusing_history': 'Historia bonusów',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Zdobywcy Boosterpota',
'but_remember_paragraph': 'Pamiętaj jednak, że jeśli nie zdążysz wypłacić wygranej przed eksplozją,',
'calculate_raw_mp_to_decimal': 'Oblicz surowy mnożnik z systemu dziesiętnego i zaokrąglij w dół do najbliższej liczby całkowitej',
'cancel': 'Anuluj',
'cant_post_media_content': 'Nie można zbyt często publikować treści multimedialnych',
'cashed_out': 'Wypłacono',
'cashout': 'Wypłata',
'cashout_at': 'WYPŁATA PRZY KWOCIE',
'cashout_before': 'Wypłać wygraną, zanim rakieta eksploduje, a pieniądze będą Twoje!',
'change_avatar': 'Zmień awatar',
'change_username': 'Zmień nazwę użytkownika',
'characters_long': 'znaków długości',
'chat': 'Czat',
'chat_room': 'Pokój czatu',
'checking_for_spam': 'Sprawdzanie pod kątem spamu…',
'close': 'Zamknij',
'coeff': 'PARAMETR',
'collect_win': 'Odbierz wygraną',
'combined_seed': 'Połączony seed',
'company': 'Firma',
'congratulations_you_have_won_free_bets': 'Gratulacje! Wygrywasz bonusowe zakłady! Możesz postawić',
'convert_first_13_to_decimal': ' Przekonwertuj pierwsze 13 bajtów na system dziesiętny',
'd': 'd.',
'date': 'DATA',
'date_and_time': 'Data i godzina',
'deny': 'Odrzuć',
'detailed_game_rules': 'Szczegółowe zasady gry',
'dissconnection_policy': 'ZASADY W PRZYPADKU UTRATY POŁĄCZENIA',
'easy_as_one_two_three': 'Trzy proste kroki',
'enter_username': 'Wprowadź pożądaną nazwę użytkownika',
'error_handling': 'POSTĘPOWANIE W RAZIE BŁĘDÓW',
'exit_full_screen': 'Wyjdź z trybu pełnoekranowego',
'first': 'pierwsza',
'first_time_playing': 'Po raz pierwszy grasz w grę „Rocketman”?',
'folow_the_three_easy_steps': 'Wystarczy wykonać te trzy proste kroki. Życzymy miłej zabawy z Rocketman! ',
'free_bets': 'DARMOWE ZAKŁADY',
'free_bets_desc': 'Otrzymujesz darmowe zakłady! Darmowe zakłady możesz użyć w dowolnym momencie, naciskając przycisk startu.',
'free_bets_header': 'Darmowe zakłady',
'from': 'OD',
'fullscreen_mode': 'W celu zapewnienia wyższego komfortu rozgrywki gra zostanie uruchomiona w trybie pełnoekranowym.',
'function': 'Funkcja',
'game_functions': 'Zasady gry',
'game_history': 'HISTORIA GRY',
'game_r_1_paragraph': 'Gracz może aktywować opcję autogry. Wówczas w każdej nowej rundzie zakłady stawiane są automatycznie według stawki z poprzedniego zakładu.',
'game_r_10_paragraph': 'Podczas stawiania następnych zakładów w pierwszej kolejności zostaną wykorzystane środki z przyznanych kredytów promocyjnych, a gdy ich zabraknie, zostaną pobrane środki z portfela gracza.',
'game_r_11_paragraph': 'Środków z promocyjnych kredytów nie można wypłacić, ale wygrane z zakładów postawionych przy ich użyciu trafiają w całości na konto gracza.',
'game_r_12_paragraph': 'Promocyjne kredyty mają 30-dniowy okres ważności, po upływie którego nie mogą być wykorzystane.',
'game_r_13_paragraph': 'Gracz może losowo otrzymać darmowe zakłady. Na ekranie pojawi się wtedy wyskakujące okienko z informacją o przyznanej liczbie i kwocie darmowych zakładów.',
'game_r_14_paragraph': 'Gracz może wybrać, kiedy rozpocząć rundę darmowych zakładów. Po jej rozpoczęciu zostanie postawiony automatyczny zakład z określoną stawką, a gracz może w dowolnej chwili wypłacić pieniądze. Nawet jeśli podczas darmowych zakładów gracz się rozłączy, zosta',
'game_r_15_paragraph': 'Darmowe zakłady mają 30-dniowy okres ważności, po upływie którego nie mogą być wykorzystane.',
'game_r_16a_paragraph': 'Całkowity teoretyczny zwrot dla gracza wynosi ',
'game_r_17_paragraph': 'Wartość RTP jest stale monitorowana i weryfikowana przez niezależne strony trzecie.',
'game_r_18_paragraph': 'Jeśli po postawieniu zakładu utracisz połączenie z grą, zakład nie zostanie anulowany. Natomiast jeśli mnożnik osiągnie 20 000x, wygrana zostanie automatycznie wypłacona, a kredyty dodane do Twojego konta.',
'game_r_19_paragraph': 'W grze możesz rozmawiać na czacie z innymi graczami. Wpisz wiadomość w oknie czatu lub pochwal się innym graczom swoją wygraną.',
'game_r_2_paragraph': 'W polu „Autowypłata” można wprowadzić mnożnik automatycznej wypłaty. Wówczas w przypadku osiągnięcia ustawionego mnożnika gra będzie automatycznie wypłacać wygraną.',
'game_r_20_paragraph': 'Gracze, którzy używają czatu w niestosowny sposób (np. źle odnoszą się do innych graczy lub używają nieprzyzwoitego lub wulgarnego języka), zostaną zablokowani na czacie.',
'game_r_21_paragraph': 'Rocketman ma piękną oprawę muzyczną i efekty dźwiękowe. Jeśli chcesz je wyłączyć, możesz to zrobić w rozwijanym menu.',
'game_r_22_paragraph': 'Aby sprawdzić swoje ostatnie zakłady, kliknij przycisk „Moje zakłady”. Natomiast jeśli chcesz sprawdzić dłuższą historię gry, możesz potrzebować dostępu do historii gracza na platformie danej gry.',
'game_r_23_paragraph': 'W razie wystąpienia błędu rozpoczęcie rundy może się opóźnić. W takim przypadku nasz zespół obsługi klienta postara się niezwłocznie usunąć awarię i wznowić regularne rundy. W razie problemów podczas rozgrywania rundy wszystkie zakłady zostaną anulowane, ',
'game_r_24_paragraph': 'Opóźnienie sieciowe jest nieuniknione podczas korzystania z Internetu. Wszystkie zakłady i wypłaty uznajemy za obowiązujące w momencie zarejestrowania ich na naszych serwerach, niezależnie od opóźnienia sieciowego. ',
'game_r_3_paragraph': 'Aby szybko postawić zakład lub wypłacić pieniądze w grze, gracz może użyć skrótów klawiszowych. Poniżej możesz sprawdzić mapowanie klawiszy',
'game_r_4_paragraph': '1% z każdej stawki przeznaczane jest na fundusz progresywnego jackpota. Ten fundusz obsługuje system progresywnego jackpota',
'game_r_5_paragraph': ' ‎• Jackpot – najwyższy poziom, najwyższa nagroda, najrzadziej występuje',
'game_r_6_paragraph': ' ‎• Rocketpot – średni poziom, średnia nagroda',
'game_r_7_paragraph': ' ‎• Boosterpot – najniższy poziom, najniższa nagroda, najczęściej występuje ',
'game_r_8_paragraph': 'Wszystkie jackpoty, niezależnie od poziomu, przyznawane są w oparciu o mechanikę „Mystery”, zwaną również tajemniczym lub gwarantowanym jackpotem. Po zdobyciu jackpota generator liczb losowych określa wartość tajemniczego jackpota, która jest przechowywan',
'game_r_8a_paragraph': 'Uwaga: Zwycięzcą jackpota może być tylko jeden gracz Rocketman aktywnie grający w rundzie, w której jackpot został uruchomiony. Nie ma możliwości, że jeśli gracz wygra jackpot, będzie musiał podzielić się nim z jakimkolwiek innym graczem Rocketman.',
'game_r_9_paragraph': 'Gracz może losowo otrzymać promocyjne kredyty. Na ekranie pojawi się wtedy wyskakujące okienko z informacją o ilości przyznanych kredytów.',
'game_round': 'rundę gry!',
'game_rules': 'Zasady gry',
'game_rules_1_paragraph': 'Rocketman należy do nowej generacji gier hazardowych. W ciągu kilku sekund możesz zdobyć wielkie wygrane! Rocketman oparty jest na systemie',
'game_rules_10_paragraph': ' ‎• Automatyczną grę można aktywować na karcie „Auto” w panelu zakładu, zaznaczając pole wyboru „Autozakład”. Po aktywacji tej opcji zakłady będą stawiane automatycznie. Aby je wypłacić, w każdej rundzie musisz jednak nacisnąć przycisk „Wypłata”. Jeśli ch',
'game_rules_11_paragraph': '‎• Automatyczną wypłatę można aktywować na karcie „Auto” w panelu zakładu. Po aktywacji tej opcji zakład zostanie automatycznie wypłacony po osiągnięciu parametru.',
'game_rules_2_paragraph': '‎, który jako jedyny daje obecnie prawdziwą gwarancję uczciwości w rozgrywkach hazardowych.',
'game_rules_3_paragraph': '‎• Mnożnik wygranej zaczyna się od 1x i rośnie w trakcie lotu Szczęśliwej Rakiety.',
'game_rules_4_paragraph': ' ‎• Twoje wygrane stanowią równowartość stawki pomnożonej o parametr, przy którym dokonano wypłaty. ',
'game_rules_5_paragraph': '‎• Przed rozpoczęciem każdej rundy nasz generator liczb losowych oparty na mechanizmie Provably Fair generuje parametr, przy którym Szczęśliwa Rakieta wybuchnie. Jeśli chcesz zweryfikować uczciwość tego procesu, kliknij pole po przeciwnej stronie wyniku w',
'game_rules_6_paragraph': '‎• Wybierz kwotę i naciśnij przycisk „Postaw”, aby postawić zakład. ',
'game_rules_7_paragraph': '‎• Możesz postawić dwa zakłady jednocześnie, dodając drugi panel zakładu. Aby dodać drugi panel, naciśnij znak plusa, który znajduje się w prawym górnym rogu pierwszego panelu zakładu.',
'game_rules_8_paragraph': ' ‎• Naciśnij przycisk „Wypłata”, aby wypłacić wygraną. Twoja wygrana stanowi równowartość stawki pomnożonej przez parametr wypłaty.',
'game_rules_9_paragraph': '‎• Jeśli nie wypłacisz wygranej przed wybuchem rakiety, przegrywasz zakład.',
'general_rules': 'ZASADY OGÓLNE',
'gifs': 'GIF-y',
'go_back': 'Wróć',
'good_job_buddy': 'Dobra robota! Tak trzymaj',
'h': 'h',
'hashed_in_sha_512': 'Zhashowane w SHA512',
'hex': 'System szesnastkowy',
'hey_username': 'Witaj',
'hide_hex_to_decimal': 'Ukryj konwersję z systemu szesnastkowego na dziesiętny',
'highscore': 'Wysoki wynik',
'how_it_works': 'Jak to działa?',
'how_to_check': 'Jak sprawdzić?',
'how_to_play': 'Jak grać',
'if_the_maximum_multiplier': '‎• W przypadku osiągnięcia maksymalnego mnożnika 20 000 wszystkie otwarte zakłady zostaną automatycznie wypłacone z maksymalnym mnożnikiem.',
'increase': 'Zwiększ',
'input_bet_amount': 'Określ stawkę i postaw zakład przed startem rakiety.',
'insufficient_funds': 'Niewystarczające środki',
'it_blew_up': 'Rakieta eksplodowała! No cóż...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Zdobywcy Jackpota',
'key': 'Klucz',
'load_more': 'ZAŁADUJ WIĘCEJ',
'loading': 'Ładowanie',
'loading_next_round': 'Ładowanie kolejnej rundy...',
'loss_gifs': 'STRATA',
'lost_connection': 'Utracone połączenie',
'make_a_bet': 'Postaw zakład',
'malfunction_voids': 'Błąd unieważnia wszystkie wypłaty i gry.',
'maximum_payout': 'Maksymalna wypłata wynosi ',
'menu': 'Menu',
'min_bet_is': 'Min. Zakład wynosi',
'month': 'Miesiąc',
'more_details': 'Więcej szczegółów',
'more_information': 'Więcej informacji',
'multiplier': 'Mnożnik',
'multiplier_starts': '‎• Początkowa wartość mnożnika wynosi 1 i rośnie aż do 20 000.',
'multipliers_larger': 'Maksymalna wartość mnożnika jest ograniczona do x20 000',
'music_settings': 'Ustawienia muzyki',
'my_bet_history': 'Historia moich zakładów',
'my_bets': 'Moje zakłady',
'my_bonysing_history': 'Historia moich bonusów',
'network_latency': 'OPÓŹNIENIE SIECIOWE',
'network_latency_content': 'Opóźnienie sieciowe jest nieuniknione podczas korzystania z Internetu. Wszystkie zakłady i wypłaty uznajemy za obowiązujące w momencie zarejestrowania ich na naszych serwerach, niezależnie od opóźnienia sieciowego.',
'network_latency_title': 'OPÓŹNIENIE SIECIOWE',
'no_thanks': 'Nie, dziękuję!',
'not_right_now': 'Nie teraz',
'off': 'wył.',
'oh_snap': 'O nie!',
'ok_close': 'OK. Zamknij!',
'ok_thanks': 'OK, dzięki!',
'on': 'wł.',
'on_loss': 'PO PRZEGRANIU',
'on_win': 'PO WYGRANIU',
'online': 'Online',
'per_bet': 'na zakład',
'pick_your_avatar_img': 'Wybierz obraz awatara',
'place_bet_with_last': 'Postaw zakład wg ostatniej stawki ',
'play_now': 'Graj teraz',
'player_1_seed': 'Seed gracza 1',
'player_2_seed': 'Seed gracza 2',
'player_3_seed': 'Seed gracza 3',
'please_wait_active_bets': 'Aby aktywować darmowe zakłady, zaczekaj na rozliczenie bieżących zakładów',
'points': 'PUNKTY',
'powered_by': 'Rozgrywana w technologii',
'previous_hand': 'Poprzednia runda',
'prize': 'NAGRODA',
'prizes': 'nagrody',
'progressive_jackpot': 'PROGRESYWNY JACKPOT',
'promo_credit': 'Kredyt promocyjny',
'promo_credit_condradulations': 'Gratulacje! Wygrywasz kosmicznie dobre promocyjne kredyty, których możesz użyć w grze!',
'promo_credits': 'PROMOCYJNE KREDYTY',
'promo_history': 'Historia promocji',
'provably_fair': 'Prawdopodobnie uczciwe',
'provably_fair_1_paragraph': 'Gra Rocketman wykorzystuje technologię kryptograficzną pod nazwą „Provably Fair”. Mechanizm ten gwarantuje 100-procentową uczciwość rezultatów gry, a także uniemożliwia jakąkolwiek ingerencję z zewnątrz.',
'provably_fair_2_paragraph': 'Wynik każdej rundy (parametr „fly away” gry) nie jest generowany na naszych serwerach, lecz z pomocą graczy biorących udział w danej rundzie, co zapewnia pełną przejrzystość. Dzięki temu manipulowanie wynikiem gry jest niemożliwe, a każdy może sprawdzić u',
'provably_fair_3_paragraph': 'Wynik rundy generowany jest przez czterech niezależnych uczestników rundy.',
'provably_fair_4_paragraph': 'Na początku rundy gra łączy seed serwera z trzema seedami klienta. Z połączonych symboli generowany jest hash SHA512, a z niego – wynik gry.',
'provably_fair_5_paragraph': 'Uczciwość każdej rundy możesz zweryfikować w historii gry, klikając zieloną ikonę. W otwartym oknie zobaczysz seedy serwera, 3 pary seedów graczy, połączony hash oraz wynik rundy.',
'provably_fair_6_paragraph': 'Zhashowana wersja seeda serwera następnej rundy jest dostępna publicznie w oknie ustawień (w menu użytkownika, zaznacz „Ustawienia Provably Fair”, a potem „Następny seed serwera SHA256”). Możesz tu również zmienić swój seed klienta. Jeśli chcesz wziąć udz',
'provably_fair_7_paragraph': 'Poprawność hashowania można sprawdzić w dowolnym kalkulatorze online, takim jak',
'provably_fair_system': 'Provably Fair',
'quick_explanation': 'Szybkie wyjaśnienie',
'randomly_generated_server_hash': 'Losowo generowany skrót HASH serwera',
'rank': 'RANKING',
'raw_multiplier': 'surowy mnożnik',
'raw_multiplier_to_edged': 'Surowy mnożnik do skrajnego mnożnika, korzystając z',
'remove_additional_bet': 'Usuń dodatkowy zakład',
'reset': 'Reset',
'return_to_player': 'ZWROT DLA GRACZA',
'rocketman_is_pure_excitement': 'Rocketman dostarcza wielką dawkę emocji! Zaryzykuj i wygraj.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Zdobywcy Rocketpota',
'round': 'Runda',
'round_id': 'ID rundy',
'round_multiplier': 'Mnożnik rundy',
'round_results': 'wyniki rundy',
'rounds': 'rundy',
'rtp': 'RTP',
'rules': 'zasady',
'rules_details': 'Zasady turnieju',
'save': 'Zapisz',
'schedule': 'harmonogram',
'second': 'druga',
'seconds_left': 'sek. do wysłania',
'server_hash': 'HASH serwera',
'please_wait_current_bets': 'PROSZĘ CZEKAĆ NA ZAKOŃCZENIE AKTUALNYCH ZAKŁADÓW',
'min_autocashout_value_must_be': 'MIN WARTOŚĆ AUTOCASHOUT MUSI BYĆ',
'min_bet_value_must_be': 'MIN WARTOŚĆ ZAKŁADU MUSI BYĆ',
'server_seed': 'Seed serwera',
'session_dissconnected': 'Sesja rozłączona',
'multisession_not_allowed': 'Wielosesyjność nie jest dozwolona',
'set_auto_bet': 'USTAW AUTOZAKŁAD',
'set_auto_cashout': 'USTAW AUTOWYPŁATĘ',
'shourtcut_keys': 'SKRÓTY KLAWISZOWE',
'show_hex_to_deimal': 'Pokaż konwersję z systemu szesnastkowego na dziesiętny',
'sound': 'DŹWIĘK',
'sound_settigs': 'Ustawienia dźwięku',
'start_with_autoplay': 'Rozpocznij autogrę',
'stop_autoplay': 'ZATRZYMAJ AUTOGRĘ',
'stop_on_loss': 'ZAKOŃCZ PO OSIĄGNIĘCIU STRATY',
'stop_on_loss_tooltip': 'Użyj funkcji Zakończ po osiągnięciu straty, aby przerwać autogrę po przegraniu określonej wartości.<br/> Na przykład, jeśli rozpoczynasz grę z saldem 100 USD, a ustawisz Zakończ po osiągnięciu straty 25 USD',
'stop_on_profit': 'ZAKOŃCZ PO OSIĄGNIĘCIU ZYSKU',
'stop_on_profit_tooltip': 'Użyj funkcji Zakończ po osiągnięciu zysku, aby przerwać autogrę po wygraniu określonej wartości.<br/> Na przykład, jeśli rozpoczynasz grę z saldem 100 USD, a ustawisz Zakończ po osiągnięciu zysku 25 USD',
'there_are_no_active_torunament': 'W tej chwili nie jest rozgrywany żaden turniej.',
'third': 'trzecia',
'this_game_is': 'Ta gra jest',
'this_tool_recalculates': 'Narzędzie ponownie oblicza mnożnik na podstawie skrótu „hash” gry. Aby zweryfikować mnożnik, skopiuj i wklej hash dowolnej gry.',
'time': 'GODZINA',
'time_frame': 'Okres',
'to': 'DO',
'toggle_animation': 'Przełącz animację',
'top_odds': 'Najwyższe kursy',
'top_wins': 'Najwyższe wygrane',
'total_bets': 'Suma zakładów',
'tournament': 'Turniej',
'tournament_information': 'Informacje o turnieju',
'try_advanced_autoplay': 'Wypróbuj nową zaawansowaną funkcję autoodtwarzania!',
'uncredited_wins': 'Niedoliczone wygrane',
'user': 'UŻYTKOWNIK',
'user_and_points': 'UŻYTKOWNIK I PUNKTY',
'username': 'Nazwa użytkownika',
'username_must_be_at_least': 'Nazwa użytkownika musi mieć co najmniej',
'verify': 'Zweryfikuj',
'vip_popup_text': 'Możesz wybrać jeden z dostępnych wyjątkowych awatarów dla VIP-ów!',
'vip_popup_title': 'Gratulujemy uzyskania statusu gracza VIP',
'waiting_for_the_next_round': 'Oczekiwanie na kolejną rundę...',
'warnings_are_calculated': ' ‎• Wygrane są obliczane poprzez pomnożenie stawki zakładu przez mnożnik obowiązujący w chwili wypłaty.',
'watch_take_off': 'Obserwuj lot rakiety, podczas którego rośnie mnożnik wygranej.',
'watch_the_rocket': 'Obserwuj lot',
'we_have_noticed': 'Zauważyliśmy, że po raz pierwszy grasz w grę „Rocketman”. Czy chcesz zobaczyć krótki filmik wyjaśniający zasady gry?',
'week': 'Tydzień',
'welcome_to_rocketman': 'Witamy w nowatorskiej i ekscytującej grze Rocketman! W Rocketman możesz wygrać stawkę całkowitą pomnożoną nawet 20 000x!',
'what_is_provably_fair': 'Na czym polega technologia Provably Fair?',
'will_be_deposited': 'Zostaną dodane do salda gracza po pierwszej zwykłej wygranej',
'win': 'WYGRANA',
'win_amount': 'Kwota wygranej',
'win_gifs': 'WYGRANA',
'winner': 'zwycięzca',
'wins': 'wygrane',
'write_a_replay': 'Wpisz odpowiedź...',
'year': 'Rok',
'yes': 'TAK',
'yes_start_the_round': 'Tak, rozpocznij rundę',
'you_have_cashed_out': 'Udało ci się wypłacić. Wygrywasz ',
'you_have_won': 'TWOJA WYGRANA TO',
'your_bet_is_lost': 'stracisz kwotę stawki.',
'current': 'Aktualny',
'previous': 'Poprzedni',
'claim': 'Prawo',
'this_feature_will_give': 'Ta funkcja przekaże wybraną kwotę losowym użytkownikom na czacie',
'amount_per_player': 'Kwota na gracza',
'no_of_players': 'Liczba graczy',
'meteor_shower': 'Deszcz meteorytów',
'claim_your_free_bet': 'Odbierz swój darmowy zakład',
'meteor_shower_total': 'Razem deszcz meteorów',
'shower': 'DESZCZ',
'total': 'Całkowity',
'meteor_shower_incoming': 'Nadchodzi deszcz meteorytów, spójrz na czat!',
'insurance': 'UBEZPIECZENIE',
'insurance_paragraph': 'Gracze mogą zakupić ubezpieczenie na swoją zakładkę, klikając pole "Ubezpieczenie zakładu" obok przycisku zakładu. Ubezpieczenie kosztuje 10% całkowitej kwoty zakładu i zwróci kwotę zakładu graczowi, jeśli rakieta eksploduje przy mnożniku 1,00.',
'insure_your_bet': 'UBEZPIECZ SWÓJ ZAKŁAD',
'bet_insurance_promo_text': 'Wybierz opcję „Ubezpieczenie zakładu”, aby ubezpieczyć swój zakład od katastrofy rakietowej przy mnożniku 1,00.',
'bet_insurance_promo_text_helper': 'Przeczytaj więcej w dziale „Zasady gry”...',
'game_explanation_1': 'Wybierz mnożnik, a jeśli mnożnik gry przekroczy wybrany mnożnik, Twoja wygrana zostanie przyznana',
'game_explanation_2': 'Twój mnożnik x Twój zakład',
'retail_max_mp_1': 'Wygraj do',
'retail_max_mp_2': 'twój zakład!',
'play_multibets': 'Grać',
'multibets': 'MULTIBETY',
'bonus_game': 'GRA BONUSOWA',
'all_wins_doubled': 'Wszystkie wygrane podwojone!',
'multibets_paragraph_1': 'aby zwiększyć swoje szanse!',
'multibets_paragraph_2': 'Możesz łączyć zakłady z maksymalnie dziesięciu rund w jeden zakład wielokrotny i wygrywać ogromne wygrane!',
'multibets_paragraph_3': 'Za każdym razem, gdy wygrywana jest runda, wygrana z tej rundy jest obstawiana w następnej rundzie, aż do momentu, gdy wszystkie rundy zakończą się wygraną.',
'bonus_launch': 'Wygraj x2 na wszystkie zakłady w rundzie „Bonusowe uruchomienie”!',
'multiplier_history': 'Historia mnożnika',
'will_start_soon': 'zacznie się wkrótce',
'hurry_up_and_place_your_bets': 'Pospiesz się i obstawiaj zakłady!',
'active_bets': 'Aktywne zakłady',
'shop': 'SKLEP',
'game_meteor_shower_paragraph': 'Deszcz Meteorów przyznaje jeden darmowy zakład o określonej wartości określonej liczbie graczy. Może zostać zainicjowane przez serwer lub przez gracza. Po aktywowaniu Deszczu Meteorów w grze pojawia się komunikat polecający użytkownikom sprawdzenie czatu, na którym znajduje się wiadomość o Deszczu Meteorów. Gracze mogą odebrać darmowy zakład, klikając przycisk „Odbierz”. Darmowe zakłady przyznawane są graczom, którzy jako pierwsi klikną przycisk „Odbierz”. Używanie programów do automatycznego klikania lub jakiegokolwiek oprogramowania do automatyzacji w celu emulowania kliknięć graczy i odbierania nagród jest zabronione i może skutkować zablokowaniem tej funkcji.',
'claming_failed': 'Zgłoszenie nie powiodło się',
'player_on_blacklist': 'Gracz znajduje się na czarnej liście',
'you_can_claim_ticket': 'Możesz ubiegać się o bilet tylko wtedy, gdy jesteś aktywnym graczem i regularnie obstawiasz zakłady',
'you_already_have_free_tickets': 'Masz już darmowe bilety, wydaj je w pierwszej kolejności',
'please_play_the_game_in_portait_mode': 'Proszę grać w grę w trybie portretowym.',
};
langArr.pt = {
'your_insurance_returns_you': 'Seu seguro te devolve',
'bet_insurance': 'Seguro de apostas',
'max_bet_is': 'A aposta máxima é',
'already_active_bet_of': 'e já existe aposta ativa de',
'1_from_game_history': '‎1. Através do historial do jogo',
'2_in_settings_window': '‎2. Na janela de configurações',
'3_online_calculator': '‎3. Calculadora online',
'active_tournament_1': 'O torneio ativo ',
'active_tournament_2': 'ainda não tem apostas.',
'add_another_bet': 'Adicionar outra aposta',
'advanced_autoplay': 'JOGO AUTOMÁTICO AVANÇADO',
'advanced_autoplay_for_bet': 'Jogo Automático Avançado para a Aposta',
'alien_saucer_free_bets': 'Apostas Grátis Disco Voador',
'all_bets': 'Todas as Apostas',
'all_in_your_hands': 'Está tudo nas suas mãos!',
'allow': 'Permitir',
'at': 'por',
'austronaut_promo_credit': 'Crédito Promocional Astronauta',
'auto_play_and_autocash': 'JOGO AUTOMÁTICO E CASHOUT AUTOMÁTICO ',
'autoplay': 'Jogo automático',
'autoplay_and_cashout': '‎02. Jogo Automático e Cashout Automático',
'back_to_home': 'Voltar ao início',
'balance': 'Saldo',
'bet': 'APOSTA',
'bet_amount': 'VALOR DA APOSTA',
'bet_and_cashout': '‎01. Aposta & Cashout',
'bet_bets_tab': 'VALOR DA APOSTA',
'bet_failed': 'A Aposta Falhou',
'bet_statistic': 'Estatísticas de Apostas',
'bet_time': 'Momento da Aposta',
'bet_wins': 'Ganhos das Apostas',
'bets': 'Apostas',
'biggest_odds': 'Odds Mais Altas',
'bonus_type': 'TIPO DE BÓNUS',
'bonusing_history': 'Historial de Bónus',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Vencedores do Boosterpot',
'but_remember_paragraph': 'Tenha em atenção, se não tiver tempo de fazer cashout antes de o foguetão explodir,',
'calculate_raw_mp_to_decimal': 'Calcular o Multiplicador em Bruto a partir do decimal e arredondá-lo para o número inteiro mais próximo',
'cancel': 'Cancelar',
'cant_post_media_content': 'Não pode publicar conteúdos multimédia frequentemente',
'cashed_out': 'Cashout Realizado',
'cashout': 'Cashout',
'cashout_at': 'CASHOUT COM',
'cashout_before': 'Faça cashout antes de o Foguetão explodir e o dinheiro é seu!',
'change_avatar': 'Alterar Avatar',
'change_username': 'Alterar o Nome de Utilizador',
'characters_long': 'caracteres',
'chat': 'Chat',
'chat_room': 'Sala de Chat',
'checking_for_spam': 'A verificar se há spam...',
'close': 'Fechar',
'coeff': 'COEF.',
'collect_win': 'Recolher os Ganhos',
'combined_seed': 'Seed combinada',
'company': 'Empresa',
'congratulations_you_have_won_free_bets': 'Parabéns! Ganhou Apostas de Bónus! Pode apostar',
'convert_first_13_to_decimal': ' Converter os primeiros 13 bytes para decimal',
'd': 'd',
'date': 'DATA',
'date_and_time': 'Data e Hora',
'deny': 'Recusar',
'detailed_game_rules': 'Regras do Jogo Detalhadas',
'dissconnection_policy': 'POLÍTICA DE DESCONEXÃO',
'easy_as_one_two_three': 'Tão fácil como contar até três',
'enter_username': 'Por favor, introduza o nome de utilizador desejado',
'error_handling': 'GESTÃO DE ERROS',
'exit_full_screen': 'Sair do modo ecrã completo',
'first': 'primeiro',
'first_time_playing': 'É a primeira vez que joga "Rocketman"?',
'folow_the_three_easy_steps': 'Siga estes três simples passos e desfrute do Rocketman! ',
'free_bets': 'APOSTAS GRÁTIS',
'free_bets_desc': 'Ganhou apostas grátis! Pode tirar partido das apostas grátis em qualquer momento premindo o botão iniciar.',
'free_bets_header': 'Apostas Grátis',
'from': 'DE',
'fullscreen_mode': 'Para uma melhor experiência de utilizador, o jogo iniciará no modo ecrã completo.',
'function': 'Função',
'game_functions': 'Funções do Jogo',
'game_history': 'HISTORIAL DE JOGO',
'game_r_1_paragraph': 'Um jogador pode ativar a opção de jogo automático, na qual é realizada automaticamente uma aposta a cada nova ronda utilizando o valor de aposta anterior.',
'game_r_10_paragraph': 'Uma vez atribuídos os créditos promocionais, por qualquer aposta realizada pelo jogador, o dinheiro será em primeiro lugar deduzido dos créditos promocionais até que estes se esgotem, antes de continuar a ser retirado dinheiro da carteira do jogador.',
'game_r_11_paragraph': 'Não pode ser feito cashout dos créditos promocionais, mas quaisquer ganhos provenientes dessas apostas serão creditados na íntegra ao jogador.',
'game_r_12_paragraph': 'Os créditos promocionais têm um período de validade de 30 dias, após o qual expiram e não podem ser utilizados.',
'game_r_13_paragraph': 'Podem ser atribuídas apostas grátis aleatoriamente a um jogador. Aparecerá um popup gráfico no ecrã a notificar o jogador acerca das apostas grátis atribuídas, número de apostas e montante por aposta.',
'game_r_14_paragraph': 'Uma vez atribuídas as apostas grátis, um jogador pode escolher quando iniciar a ronda de apostas grátis. Uma vez iniciada a ronda de apostas grátis, é realizada uma aposta automática com o valor de aposta especificado, cabendo ao jogador fazer cashout a q',
'game_r_15_paragraph': 'As apostas grátis têm um período de validade de 30 dias, após o qual expiram e não podem ser utilizadas.',
'game_r_16a_paragraph': 'O retorno teórico geral ao jogador é ',
'game_r_17_paragraph': 'As percentagens de RTP são monitorizadas de forma contínua e verificadas por terceiros independentes.',
'game_r_18_paragraph': 'Se a conexão se perder após a realização da aposta, esta não será cancelada. Se o multiplicador atingir 20 000x, será feito cashout automático da sua aposta e os créditos serão aplicados na sua conta.',
'game_r_19_paragraph': 'Pode utilizar o chat para interagir com outros jogadores. Introduza a sua mensagem no campo do chat, ou partilhe a sua aposta vencedora com os outros jogadores.',
'game_r_2_paragraph': 'Pode ser introduzido um multiplicador de cashout automático no campo “Cashout Automático” e, quando este é introduzido, o jogo irá fazer cashout automaticamente ao ser atingido o multiplicador estabelecido.',
'game_r_20_paragraph': 'Serão desabilitados os privilégios de acesso ao chat aos jogadores que fizerem uma má utilização deste, abusando de outros jogadores ou utilizando linguagem imprópria e/ou grosseira.',
'game_r_21_paragraph': 'O Rocketman foi concebido com uma música de fundo e efeitos sonoros agradáveis. Caso pretenda desativar a música e/ou os efeitos sonoros, poderá fazê-lo através do menu suspenso.',
'game_r_22_paragraph': 'Ao clicar no botão “As Minhas Apostas”, o jogador pode rever as suas apostas mais recentes. Para um historial de jogo mais completo (p. ex. períodos mais longos), um jogador poderá ter de aceder ao historial do jogador dentro da própria plataforma de jogo',
'game_r_23_paragraph': 'Caso venha a ocorrer um erro, as rondas podem não se iniciar atempadamente. Nesse caso, a nossa equipa de apoio tentará resolver o problema logo que possível e retomar as habituais rondas de jogo. No caso de ocorrer um problema com uma ronda do jogo em si',
'game_r_24_paragraph': 'A latência de rede é parte inevitável da ligação à Internet. Consideramos todas as apostas e cashouts válidos apenas quando chegam ao nosso servidor, independentemente da duração da latência de rede. ',
'game_r_3_paragraph': 'Um jogador pode utilizar teclas de atalho para realizar uma aposta rapidamente ou fazer cashout no jogo. O mapeamento das teclas é o seguinte',
'game_r_4_paragraph': '1% de cada aposta é destinado a um fundo de jackpot progressivo. Este fundo serve ao sistema de jackpot progressivo',
'game_r_5_paragraph': ' ‎• Jackpot – Nível mais alto, prémio maior, menos frequente',
'game_r_6_paragraph': ' ‎• Rocketpot – Nível médio, prémio médio',
'game_r_7_paragraph': ' ‎• Boosterpot – Nível mais baixo, prémio menor, mais frequente ',
'game_r_8_paragraph': 'Todos os níveis de jackpot são atribuídos utilizando a mecânica “Mistério”, ou “tem de ser ganho até”. O valor de todos os jackpots “tem de ser ganho até” é determinado imediatamente após o jackpot anterior ter sido ganho, através de um gerador de números',
'game_r_8a_paragraph': 'Nota: O vencedor do jackpot pode ser apenas um jogador do Rocketman jogando ativamente na rodada quando o jackpot foi acionado. Não há possibilidade de que, se o jogador ganhar o jackpot, tenha que compartilhá-lo com qualquer outro jogador do Rocketman.',
'game_r_9_paragraph': 'Podem ser atribuídos créditos promocionais aleatoriamente a um jogador. Aparecerá um popup gráfico no ecrã que notificará o jogador acerca dos créditos promocionais atribuídos e o seu valor.',
'game_round': 'ronda de jogo!',
'game_rules': 'Regras do Jogo',
'game_rules_1_paragraph': 'O Rocketman é uma atividade lúdica de fortuna e azar de nova geração. Ganhe muito mais em apenas segundos! O Rockteman foi criado sob o',
'game_rules_10_paragraph': ' O Jogo Automático é ativado no separador “Automático” no Painel de Aposta, selecionando o caixa “Aposta Automática”. Depois de ativado, as apostas serão feitas de forma automática, mas para fazer cashout, deverá pressionar o botão “cashout” em cada ronda',
'game_rules_11_paragraph': 'O Cashout Automático está disponível no separador “Automático” no painel de aposta. Depois de ser ativado, será feito cashout automático da sua aposta, após esta ter atingido o coeficiente introduzido.',
'game_rules_2_paragraph': '‎, o qual é atualmente o único que oferece garantia efetiva de honestidade na indústria dos jogos de fortuna e azar.',
'game_rules_3_paragraph': 'O multiplicador de ganhos inicia-se em 1x e aumenta cada vez mais à medida que o Foguete da Sorte descola.',
'game_rules_4_paragraph': ' Os seus ganhos correspondem ao multiplicador com o qual fez cashout multiplicado pela sua aposta. ',
'game_rules_5_paragraph': 'Antes do início de cada ronda, o nosso gerador de números aleatórios ‘provably fair’ gera o coeficiente a partir do qual o Foguete da Sorte irá explodir. Pode verificar a honestidade desta geração clicando no lado oposto ao do resultado no separador Histo',
'game_rules_6_paragraph': 'Selecione o valor e pressione o botão “Apostar” para fazer uma aposta. ',
'game_rules_7_paragraph': 'Pode fazer duas apostas em simultâneo, adicionando um segundo painel de aposta. Para adicionar um segundo painel de aposta, pressione o ícone “mais” que se encontra no canto superior direito do primeiro painel de aposta.',
'game_rules_8_paragraph': ' Pressione o botão “Cashout” para fazer cashout dos seus ganhos. O seu ganho corresponde à aposta multiplicada pelo coeficiente de cashout.',
'game_rules_9_paragraph': 'Perderá a sua aposta, se não fizer cashout antes de o foguete explodir.',
'general_rules': 'REGRAS GERAIS',
'gifs': 'GIFS',
'go_back': 'Voltar Atrás',
'good_job_buddy': 'Muito bem amigo, continua',
'h': 'h',
'hashed_in_sha_512': 'Com hash em SHA512',
'hex': 'Hex',
'hey_username': 'Olá',
'hide_hex_to_decimal': 'Ocultar a conversão de hexadecimal para decimal',
'highscore': 'Pontuação máxima',
'how_it_works': 'Como funciona?',
'how_to_check': 'Como verificar?',
'how_to_play': 'Como jogar',
'if_the_maximum_multiplier': '‎• Se for atingido o multiplicador máximo de 20 000, será feito o cashout automático de todas as apostas em aberto com o multiplicador máximo',
'increase': 'Aumentar',
'input_bet_amount': 'Introduza o valor da aposta e faça a sua aposta antes da descolagem.',
'insufficient_funds': 'Fundos Insuficientes',
'it_blew_up': 'Explodiu! Que pena...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Vencedores do Jackpot',
'key': 'Tecla',
'load_more': 'CARREGAR MAIS',
'loading': 'A carregar',
'loading_next_round': 'A carregar a próxima ronda...',
'loss_gifs': 'PERDEU',
'lost_connection': 'A Conexão Perdeu-se',
'make_a_bet': 'Faça uma Aposta',
'malfunction_voids': 'O mau funcionamento anula todos os Pagamentos e Jogadas.',
'maximum_payout': 'O pagamento máximo é ',
'menu': 'Menu',
'min_bet_is': 'Aposta mín. É',
'month': 'Mês',
'more_details': 'Mais Detalhes',
'more_information': 'Mais informação',
'multiplier': 'Multiplicador',
'multiplier_starts': '‎• O multiplicador inicia-se em 1 e pode ascender a 20 000',
'multipliers_larger': 'Multiplicadores superiores a x20 000 estão limitados a um valor multiplicador máximo',
'music_settings': 'Configurações de Música',
'my_bet_history': 'O Meu Historial de Apostas',
'my_bets': 'As Minhas Apostas',
'my_bonysing_history': 'O Meu Historial de Bónus',
'network_latency': 'LATÊNCIA DE REDE',
'network_latency_content': 'A latência de rede é parte inevitável da ligação à Internet. Consideramos todas as apostas e cashouts válidos apenas no momento em que chegam ao nosso servidor, independentemente da duração da latência de rede.',
'network_latency_title': 'LATÊNCIA DE REDE',
'no_thanks': 'Não, obrigado!',
'not_right_now': 'Agora não',
'off': 'off',
'oh_snap': 'Oh Não!',
'ok_close': 'Ok, fechar!',
'ok_thanks': 'Ok, Obrigado!',
'on': 'on',
'on_loss': 'AO PERDER',
'on_win': 'AO GANHAR',
'online': 'Online',
'per_bet': 'por aposta',
'pick_your_avatar_img': 'Escolha a imagem do seu avatar',
'place_bet_with_last': 'Realizar uma aposta com o último valor de aposta selecionado ',
'play_now': 'Jogar Agora',
'player_1_seed': 'Seed do jogador 1',
'player_2_seed': 'Seed do jogador 2',
'player_3_seed': 'Seed do jogador 3',
'please_wait_active_bets': 'Por favor, aguarde que as apostas em curso se concluam para ativar as apostas grátis',
'points': 'PONTOS',
'powered_by': 'Distribuído por',
'previous_hand': 'Mão Anterior',
'prize': 'PRÉMIO',
'prizes': 'prémios',
'progressive_jackpot': 'JACKPOT PROGRESSIVO',
'promo_credit': 'Crédito Promocional',
'promo_credit_condradulations': 'Parabéns! Ganhou um Crédito Promocional astronómico que pode usar no jogo!',
'promo_credits': 'CRÉDITOS PROMOCIONAIS',
'promo_history': 'Historial de Promoções',
'provably_fair': '‎‘Provably Fair’',
'provably_fair_1_paragraph': 'O Rocketman é baseado na tecnologia criptográfica intitulada \'Provably Fair\'. Esta tecnologia garante que o resultado do jogo é 100% justo. Com esta tecnologia, é impossível que quaisquer terceiros interfiram com o processo do jogo.',
'provably_fair_2_paragraph': 'O resultado de cada ronda (Coeficiente do voo de um jogo) não é gerado nos nossos servidores. É gerado com a ajuda dos jogadores da ronda e é absolutamente transparente. Desta forma, é impossível alguém manipular o resultado de um jogo. Além disso, qualqu',
'provably_fair_3_paragraph': 'Os resultados da ronda são gerados por quatro participantes independentes na ronda',
'provably_fair_4_paragraph': 'Uma vez iniciada a ronda, o jogo combina a seed do servidor com as seeds de três clientes. Dos símbolos combinados é gerado o hash SHA512, do qual se obtém o resultado do jogo.',
'provably_fair_5_paragraph': 'Pode verificar que cada ronda do jogo foi justa através do historial do jogo, ao clicar no ícone verde. Na janela que abre, poderá ver a seed do servidor, 3 pares de seeds dos jogadores, o hash combinado e o resultado da ronda.',
'provably_fair_6_paragraph': 'A versão com hash das seeds do servidor das rondas seguintes está disponível de forma pública, na janela de configurações (no menu do utilizador, verifique “Configurações do ‘Provably Fair’” e de seguida “Próxima seed do sevidor SHA256”). Aqui também pode',
'provably_fair_7_paragraph': 'A exatidão do hash pode ser verificada em qualquer calculadora online, por exemplo',
'provably_fair_system': 'sistema ‘provably fair’',
'quick_explanation': 'Explicação rápida',
'randomly_generated_server_hash': 'HASH do servidor gerada aleatoriamente',
'rank': 'CLASSIFICAÇÃO',
'raw_multiplier': 'multiplicador em bruto',
'raw_multiplier_to_edged': 'Multiplicador em Bruto para Multiplicador Limite, utilizando',
'remove_additional_bet': 'Remover aposta adicional',
'reset': 'Repor',
'return_to_player': 'RETORNO AO JOGADOR',
'rocketman_is_pure_excitement': 'O Rocketman é diversão pura! Arrisque e ganhe.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Vencedores do Rocketpot',
'round': 'Ronda',
'round_id': 'Id da Ronda',
'round_multiplier': 'Multiplicador da Ronda',
'round_results': 'resultados da ronda',
'rounds': 'rondas',
'rtp': 'RTP',
'rules': 'regras',
'rules_details': 'Regras do torneio',
'save': 'Gravar',
'schedule': 'calendário',
'second': 'segundo',
'seconds_left': 's restantes',
'server_hash': 'HASH do Servidor',
'please_wait_current_bets': 'AGUARDE QUE AS APOSTAS ATUAIS CONCLUAM',
'min_autocashout_value_must_be': 'O VALOR MÍNIMO DE AUTOCASHOUT DEVE SER',
'min_bet_value_must_be': 'O VALOR MÍNIMO DA APOSTA DEVE SER',
'server_seed': 'Seed do Servidor',
'session_dissconnected': 'Sessão Desconetada',
'multisession_not_allowed': 'Multisessão não é permitida',
'set_auto_bet': 'DEFINIR APOSTA AUTOMÁTICA',
'set_auto_cashout': 'DEFINIR CASHOUT AUTOMÁTICO',
'shourtcut_keys': 'TECLAS DE ATALHO',
'show_hex_to_deimal': 'Mostrar a conversão de hexadecimal para decimal',
'sound': 'SOM',
'sound_settigs': 'Configurações de Som',
'start_with_autoplay': 'Começar com o jogo automático',
'stop_autoplay': 'PARAR O JOGO AUTOMÁTICO',
'stop_on_loss': 'PARAR AO PERDER',
'stop_on_loss_tooltip': 'Utilize a opção Parar ao Perder para interromper a aposta automática após perder um determinado valor.<br/> Por exemplo, se começar com 100$ e fixar o valor relativo à opção Parar ao Perder em 25$.',
'stop_on_profit': 'PARAR AO LUCRAR',
'stop_on_profit_tooltip': 'Utilize a opção Parar ao Lucrar para interromper a aposta automática após ganhar um determinado valor.<br/> Por exemplo, se começar com 100$ e fixar o valor relativo à opção Parar ao Lucrar em 25$.',
'there_are_no_active_torunament': 'Neste momento, não existem torneios ativos.',
'third': 'terceiro',
'this_game_is': 'Este jogo é',
'this_tool_recalculates': 'Esta ferramenta recalcula o multiplicador a partir do hash do jogo. Pode copiar/colar qualquer hash do jogo para verificar o multiplicador.',
'time': 'HORA',
'time_frame': 'Intervalo de Tempo',
'to': 'PARA',
'toggle_animation': 'Animação dos Botões Deslizantes',
'top_odds': 'Odds Principais',
'top_wins': 'Ganhos Principais',
'total_bets': 'Total de Apostas',
'tournament': 'Torneio',
'tournament_information': 'Informação do torneio',
'try_advanced_autoplay': 'Experimente o novo recurso avançado de reprodução automática!',
'uncredited_wins': 'Ganhos não creditados',
'user': 'UTILIZADOR',
'user_and_points': 'UTILIZADOR E PONTOS',
'username': 'Nome de utilizador',
'username_must_be_at_least': 'O nome de utilizador tem de ter, pelo menos,',
'verify': 'Verificar',
'vip_popup_text': 'Pode escolher um dos avatares VIP especiais disponíveis exclusivamente para os nossos membros VIP!',
'vip_popup_title': 'Parabéns por se tornar um jogador VIP',
'waiting_for_the_next_round': 'À espera da próxima ronda…',
'warnings_are_calculated': ' ‎• Os ganhos são calculados através da multiplicação do valor de aposta pelo multiplicador existente no momento em que é feito o cashout',
'watch_take_off': 'Veja o seu Foguetão a descolar e o seu multiplicador de ganhos a aumentar.',
'watch_the_rocket': 'Observe o Foguete.',
'we_have_noticed': 'Reparámos que é a primeira vez que joga "Rocketman". Gostaria de ver um vídeo rápido que explica como se joga?',
'week': 'Semana',
'welcome_to_rocketman': 'Bem-vindo ao Rocketman – um novo e emocionante jogo! Com o Rocketman, tem a oportunidade de ganhar até 20 000x o seu valor de aposta original.',
'what_is_provably_fair': 'O que é o ‘Provably Fair’?',
'will_be_deposited': 'Será depositado no saldo dos jogadores com o primeiro ganho regular',
'win': 'GANHO',
'win_amount': 'Montante Ganho',
'win_gifs': 'GANHO',
'winner': 'vencedor',
'wins': 'ganhos',
'write_a_replay': 'Escreva uma resposta...',
'year': 'Ano',
'yes': 'SIM',
'yes_start_the_round': 'Sim, iniciar a ronda',
'you_have_cashed_out': 'Fez cashout, os seus ganhos são de ',
'you_have_won': 'GANHOU',
'your_bet_is_lost': 'perderá a sua aposta.',
'current': 'Atual',
'previous': 'Anterior',
'claim': 'Alegar',
'this_feature_will_give': 'Este recurso dará uma quantia selecionada para usuários aleatórios no chat',
'amount_per_player': 'Quantidade por jogador',
'no_of_players': 'Número de jogadores',
'meteor_shower': 'Chuva de meteoros',
'claim_your_free_bet': 'Reivindique sua aposta grátis',
'meteor_shower_total': 'Chuva de Meteoros Total',
'shower': 'CHUVA',
'total': 'Total',
'meteor_shower_incoming': 'Chuva de meteoros chegando, veja o chat!',
'insurance': 'SEGURO',
'insurance_paragraph': 'Os jogadores podem comprar um seguro para sua aposta clicando na caixa "Seguro da Aposta" ao lado do botão de aposta. O seguro custa 10% do valor total da aposta e reembolsará o valor apostado ao jogador caso o foguete exploda com um multiplicador de 1,00.',
'insure_your_bet': 'SEGURE SUA APOSTA',
'bet_insurance_promo_text': 'Selecione a opção "Seguro de Aposta" para garantir que sua aposta não caia com um multiplicador de 1,00.',
'bet_insurance_promo_text_helper': 'Leia mais na seção "Regras do Jogo"...',
'game_explanation_1': 'Escolha o seu multiplicador e, se o multiplicador do jogo exceder o multiplicador escolhido, sua vitória será',
'game_explanation_2': 'Seu Multiplicador x Sua Aposta',
'retail_max_mp_1': 'Ganhe até',
'retail_max_mp_2': 'sua aposta!',
'play_multibets': 'Jogar',
'multibets': 'MULTIAPOSTAS',
'bonus_game': 'JOGO DE BÓNUS',
'all_wins_doubled': 'Todas as vitórias duplicaram!',
'multibets_paragraph_1': 'para aumentar suas chances!',
'multibets_paragraph_2': 'Você pode combinar apostas em até dez rodadas em uma única aposta múltipla e ganhar pagamentos enormes!',
'multibets_paragraph_3': 'Cada vez que uma rodada é ganha, o dividendo vencedor dessa rodada é apostado na próxima rodada, até que todas as rodadas sejam vencedoras.',
'bonus_launch': 'Ganhe x2 em todas as apostas na rodada “Bonus Launch”!',
'multiplier_history': 'Histórico do Multiplicador',
'will_start_soon': 'começará em breve',
'hurry_up_and_place_your_bets': 'Apresse-se e faça suas apostas!',
'active_bets': 'Apostas Ativas',
'shop': 'COMPRAR',
'game_meteor_shower_paragraph': 'Meteor Shower concede uma aposta grátis de valor predefinido para um número predefinido de jogadores. Pode ser iniciado pelo servidor ou por um jogador. Assim que a Chuva de Meteoros for ativada, uma mensagem será exibida no jogo, direcionando os usuários a olharem para o chat onde a mensagem da Chuva de Meteoros está localizada. Os jogadores podem reivindicar a aposta grátis clicando no botão “Reivindicar”. As apostas grátis são concedidas aos jogadores que clicam primeiro no botão “Reivindicar”. O uso de autoclickers ou qualquer tipo de software de automação para emular cliques de jogadores e reivindicar prêmios é proibido e pode resultar no banimento deste recurso.',
'claming_failed': 'Falha na reivindicação',
'player_on_blacklist': 'O jogador está na lista negra',
'you_can_claim_ticket': 'Você pode reivindicar o tíquete apenas se for um jogador ativo com apostas regulares',
'you_already_have_free_tickets': 'Você já tem ingressos grátis, gaste-os primeiro',
'please_play_the_game_in_portait_mode': 'Por favor, jogue o jogo no modo retrato.',
};
langArr.br = {
'your_insurance_returns_you': 'Seu seguro te devolve',
'bet_insurance': 'Seguro de apostas',
'max_bet_is': 'A aposta máxima é',
'already_active_bet_of': 'e já existe aposta ativa de',
'1_from_game_history': '‎1. Através do historial do jogo',
'2_in_settings_window': '‎2. Na janela de configurações',
'3_online_calculator': '‎3. Calculadora online',
'active_tournament_1': 'O torneio ativo ',
'active_tournament_2': 'ainda não tem apostas.',
'add_another_bet': 'Adicionar outra aposta',
'advanced_autoplay': 'JOGO AUTOMÁTICO AVANÇADO',
'advanced_autoplay_for_bet': 'Jogo Automático Avançado para a Aposta',
'alien_saucer_free_bets': 'Apostas Grátis Disco Voador',
'all_bets': 'Todas as Apostas',
'all_in_your_hands': 'Está tudo nas suas mãos!',
'allow': 'Permitir',
'at': 'por',
'austronaut_promo_credit': 'Crédito Promocional Astronauta',
'auto_play_and_autocash': 'JOGO AUTOMÁTICO E CASHOUT AUTOMÁTICO ',
'autoplay': 'Jogo automático',
'autoplay_and_cashout': '‎02. Jogo Automático e Cashout Automático',
'back_to_home': 'Voltar ao início',
'balance': 'Saldo',
'bet': 'APOSTA',
'bet_amount': 'VALOR DA APOSTA',
'bet_and_cashout': '‎01. Aposta & Cashout',
'bet_bets_tab': 'VALOR DA APOSTA',
'bet_failed': 'A Aposta Falhou',
'bet_statistic': 'Estatísticas de Apostas',
'bet_time': 'Momento da Aposta',
'bet_wins': 'Ganhos das Apostas',
'bets': 'Apostas',
'biggest_odds': 'Odds Mais Altas',
'bonus_type': 'TIPO DE BÓNUS',
'bonusing_history': 'Historial de Bónus',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Vencedores do Boosterpot',
'but_remember_paragraph': 'Tenha em atenção, se não tiver tempo de fazer cashout antes de o foguetão explodir,',
'calculate_raw_mp_to_decimal': 'Calcular o Multiplicador em Bruto a partir do decimal e arredondá-lo para o número inteiro mais próximo',
'cancel': 'Cancelar',
'cant_post_media_content': 'Não pode publicar conteúdos multimédia frequentemente',
'cashed_out': 'Cashout Realizado',
'cashout': 'Cashout',
'cashout_at': 'CASHOUT COM',
'cashout_before': 'Faça cashout antes de o Foguetão explodir e o dinheiro é seu!',
'change_avatar': 'Alterar Avatar',
'change_username': 'Alterar o Nome de Utilizador',
'characters_long': 'caracteres',
'chat': 'Chat',
'chat_room': 'Sala de Chat',
'checking_for_spam': 'A verificar se há spam...',
'close': 'Fechar',
'coeff': 'COEF.',
'collect_win': 'Recolher os Ganhos',
'combined_seed': 'Seed combinada',
'company': 'Empresa',
'congratulations_you_have_won_free_bets': 'Parabéns! Ganhou Apostas de Bónus! Pode apostar',
'convert_first_13_to_decimal': ' Converter os primeiros 13 bytes para decimal',
'd': 'd',
'date': 'DATA',
'date_and_time': 'Data e Hora',
'deny': 'Recusar',
'detailed_game_rules': 'Regras do Jogo Detalhadas',
'dissconnection_policy': 'POLÍTICA DE DESCONEXÃO',
'easy_as_one_two_three': 'Tão fácil como contar até três',
'enter_username': 'Por favor, introduza o nome de utilizador desejado',
'error_handling': 'GESTÃO DE ERROS',
'exit_full_screen': 'Sair do modo ecrã completo',
'first': 'primeiro',
'first_time_playing': 'É a primeira vez que joga "Rocketman"?',
'folow_the_three_easy_steps': 'Siga estes três simples passos e desfrute do Rocketman! ',
'free_bets': 'APOSTAS GRÁTIS',
'free_bets_desc': 'Ganhou apostas grátis! Pode tirar partido das apostas grátis em qualquer momento premindo o botão iniciar.',
'free_bets_header': 'Apostas Grátis',
'from': 'DE',
'fullscreen_mode': 'Para uma melhor experiência de utilizador, o jogo iniciará no modo ecrã completo.',
'function': 'Função',
'game_functions': 'Funções do Jogo',
'game_history': 'HISTORIAL DE JOGO',
'game_r_1_paragraph': 'Um jogador pode ativar a opção de jogo automático, na qual é realizada automaticamente uma aposta a cada nova ronda utilizando o valor de aposta anterior.',
'game_r_10_paragraph': 'Uma vez atribuídos os créditos promocionais, por qualquer aposta realizada pelo jogador, o dinheiro será em primeiro lugar deduzido dos créditos promocionais até que estes se esgotem, antes de continuar a ser retirado dinheiro da carteira do jogador.',
'game_r_11_paragraph': 'Não pode ser feito cashout dos créditos promocionais, mas quaisquer ganhos provenientes dessas apostas serão creditados na íntegra ao jogador.',
'game_r_12_paragraph': 'Os créditos promocionais têm um período de validade de 30 dias, após o qual expiram e não podem ser utilizados.',
'game_r_13_paragraph': 'Podem ser atribuídas apostas grátis aleatoriamente a um jogador. Aparecerá um popup gráfico no ecrã a notificar o jogador acerca das apostas grátis atribuídas, número de apostas e montante por aposta.',
'game_r_14_paragraph': 'Uma vez atribuídas as apostas grátis, um jogador pode escolher quando iniciar a ronda de apostas grátis. Uma vez iniciada a ronda de apostas grátis, é realizada uma aposta automática com o valor de aposta especificado, cabendo ao jogador fazer cashout a q',
'game_r_15_paragraph': 'As apostas grátis têm um período de validade de 30 dias, após o qual expiram e não podem ser utilizadas.',
'game_r_16a_paragraph': 'O retorno teórico geral ao jogador é ',
'game_r_17_paragraph': 'As percentagens de RTP são monitorizadas de forma contínua e verificadas por terceiros independentes.',
'game_r_18_paragraph': 'Se a conexão se perder após a realização da aposta, esta não será cancelada. Se o multiplicador atingir 20 000x, será feito cashout automático da sua aposta e os créditos serão aplicados na sua conta.',
'game_r_19_paragraph': 'Pode utilizar o chat para interagir com outros jogadores. Introduza a sua mensagem no campo do chat, ou partilhe a sua aposta vencedora com os outros jogadores.',
'game_r_2_paragraph': 'Pode ser introduzido um multiplicador de cashout automático no campo “Cashout Automático” e, quando este é introduzido, o jogo irá fazer cashout automaticamente ao ser atingido o multiplicador estabelecido.',
'game_r_20_paragraph': 'Serão desabilitados os privilégios de acesso ao chat aos jogadores que fizerem uma má utilização deste, abusando de outros jogadores ou utilizando linguagem imprópria e/ou grosseira.',
'game_r_21_paragraph': 'O Rocketman foi concebido com uma música de fundo e efeitos sonoros agradáveis. Caso pretenda desativar a música e/ou os efeitos sonoros, poderá fazê-lo através do menu suspenso.',
'game_r_22_paragraph': 'Ao clicar no botão “As Minhas Apostas”, o jogador pode rever as suas apostas mais recentes. Para um historial de jogo mais completo (p. ex. períodos mais longos), um jogador poderá ter de aceder ao historial do jogador dentro da própria plataforma de jogo',
'game_r_23_paragraph': 'Caso venha a ocorrer um erro, as rondas podem não se iniciar atempadamente. Nesse caso, a nossa equipa de apoio tentará resolver o problema logo que possível e retomar as habituais rondas de jogo. No caso de ocorrer um problema com uma ronda do jogo em si',
'game_r_24_paragraph': 'A latência de rede é parte inevitável da ligação à Internet. Consideramos todas as apostas e cashouts válidos apenas quando chegam ao nosso servidor, independentemente da duração da latência de rede. ',
'game_r_3_paragraph': 'Um jogador pode utilizar teclas de atalho para realizar uma aposta rapidamente ou fazer cashout no jogo. O mapeamento das teclas é o seguinte',
'game_r_4_paragraph': '1% de cada aposta é destinado a um fundo de jackpot progressivo. Este fundo serve ao sistema de jackpot progressivo',
'game_r_5_paragraph': ' ‎• Jackpot – Nível mais alto, prémio maior, menos frequente',
'game_r_6_paragraph': ' ‎• Rocketpot – Nível médio, prémio médio',
'game_r_7_paragraph': ' ‎• Boosterpot – Nível mais baixo, prémio menor, mais frequente ',
'game_r_8_paragraph': 'Todos os níveis de jackpot são atribuídos utilizando a mecânica “Mistério”, ou “tem de ser ganho até”. O valor de todos os jackpots “tem de ser ganho até” é determinado imediatamente após o jackpot anterior ter sido ganho, através de um gerador de números',
'game_r_8a_paragraph': 'Nota: O vencedor do jackpot pode ser apenas um jogador do Rocketman jogando ativamente na rodada quando o jackpot foi acionado. Não há possibilidade de que, se o jogador ganhar o jackpot, tenha que compartilhá-lo com qualquer outro jogador do Rocketman.',
'game_r_9_paragraph': 'Podem ser atribuídos créditos promocionais aleatoriamente a um jogador. Aparecerá um popup gráfico no ecrã que notificará o jogador acerca dos créditos promocionais atribuídos e o seu valor.',
'game_round': 'ronda de jogo!',
'game_rules': 'Regras do Jogo',
'game_rules_1_paragraph': 'O Rocketman é uma atividade lúdica de fortuna e azar de nova geração. Ganhe muito mais em apenas segundos! O Rockteman foi criado sob o',
'game_rules_10_paragraph': ' O Jogo Automático é ativado no separador “Automático” no Painel de Aposta, selecionando o caixa “Aposta Automática”. Depois de ativado, as apostas serão feitas de forma automática, mas para fazer cashout, deverá pressionar o botão “cashout” em cada ronda',
'game_rules_11_paragraph': 'O Cashout Automático está disponível no separador “Automático” no painel de aposta. Depois de ser ativado, será feito cashout automático da sua aposta, após esta ter atingido o coeficiente introduzido.',
'game_rules_2_paragraph': '‎, o qual é atualmente o único que oferece garantia efetiva de honestidade na indústria dos jogos de fortuna e azar.',
'game_rules_3_paragraph': 'O multiplicador de ganhos inicia-se em 1x e aumenta cada vez mais à medida que o Foguete da Sorte descola.',
'game_rules_4_paragraph': ' Os seus ganhos correspondem ao multiplicador com o qual fez cashout multiplicado pela sua aposta. ',
'game_rules_5_paragraph': 'Antes do início de cada ronda, o nosso gerador de números aleatórios ‘provably fair’ gera o coeficiente a partir do qual o Foguete da Sorte irá explodir. Pode verificar a honestidade desta geração clicando no lado oposto ao do resultado no separador Histo',
'game_rules_6_paragraph': 'Selecione o valor e pressione o botão “Apostar” para fazer uma aposta. ',
'game_rules_7_paragraph': 'Pode fazer duas apostas em simultâneo, adicionando um segundo painel de aposta. Para adicionar um segundo painel de aposta, pressione o ícone “mais” que se encontra no canto superior direito do primeiro painel de aposta.',
'game_rules_8_paragraph': ' Pressione o botão “Cashout” para fazer cashout dos seus ganhos. O seu ganho corresponde à aposta multiplicada pelo coeficiente de cashout.',
'game_rules_9_paragraph': 'Perderá a sua aposta, se não fizer cashout antes de o foguete explodir.',
'general_rules': 'REGRAS GERAIS',
'gifs': 'GIFS',
'go_back': 'Voltar Atrás',
'good_job_buddy': 'Muito bem amigo, continua',
'h': 'h',
'hashed_in_sha_512': 'Com hash em SHA512',
'hex': 'Hex',
'hey_username': 'Olá',
'hide_hex_to_decimal': 'Ocultar a conversão de hexadecimal para decimal',
'highscore': 'rom',
'how_it_works': 'Como funciona?',
'how_to_check': 'Como verificar?',
'how_to_play': 'Como jogar',
'if_the_maximum_multiplier': '‎• Se for atingido o multiplicador máximo de 20 000, será feito o cashout automático de todas as apostas em aberto com o multiplicador máximo',
'increase': 'Aumentar',
'input_bet_amount': 'Introduza o valor da aposta e faça a sua aposta antes da descolagem.',
'insufficient_funds': 'Fundos Insuficientes',
'it_blew_up': 'Explodiu! Que pena...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Vencedores do Jackpot',
'key': 'Tecla',
'load_more': 'CARREGAR MAIS',
'loading': 'A carregar',
'loading_next_round': 'A carregar a próxima ronda...',
'loss_gifs': 'PERDEU',
'lost_connection': 'A Conexão Perdeu-se',
'make_a_bet': 'Faça uma Aposta',
'malfunction_voids': 'O mau funcionamento anula todos os Pagamentos e Jogadas.',
'maximum_payout': 'O pagamento máximo é ',
'menu': 'Menu',
'min_bet_is': 'Aposta mín. É',
'month': 'Mês',
'more_details': 'Mais Detalhes',
'more_information': 'Mais informação',
'multiplier': 'Multiplicador',
'multiplier_starts': '‎• O multiplicador inicia-se em 1 e pode ascender a 20 000',
'multipliers_larger': 'Multiplicadores superiores a x20 000 estão limitados a um valor multiplicador máximo',
'music_settings': 'Configurações de Música',
'my_bet_history': 'O Meu Historial de Apostas',
'my_bets': 'As Minhas Apostas',
'my_bonysing_history': 'O Meu Historial de Bónus',
'network_latency': 'LATÊNCIA DE REDE',
'network_latency_content': 'A latência de rede é parte inevitável da ligação à Internet. Consideramos todas as apostas e cashouts válidos apenas no momento em que chegam ao nosso servidor, independentemente da duração da latência de rede.',
'network_latency_title': 'LATÊNCIA DE REDE',
'no_thanks': 'Não, obrigado!',
'not_right_now': 'Agora não',
'off': 'off',
'oh_snap': 'Oh Não!',
'ok_close': 'Ok, fechar!',
'ok_thanks': 'Ok, Obrigado!',
'on': 'on',
'on_loss': 'AO PERDER',
'on_win': 'AO GANHAR',
'online': 'Online',
'per_bet': 'por aposta',
'pick_your_avatar_img': 'Escolha a imagem do seu avatar',
'place_bet_with_last': 'Realizar uma aposta com o último valor de aposta selecionado ',
'play_now': 'Jogar Agora',
'player_1_seed': 'Seed do jogador 1',
'player_2_seed': 'Seed do jogador 2',
'player_3_seed': 'Seed do jogador 3',
'please_wait_active_bets': 'Por favor, aguarde que as apostas em curso se concluam para ativar as apostas grátis',
'points': 'PONTOS',
'powered_by': 'Distribuído por',
'previous_hand': 'Mão Anterior',
'prize': 'PRÉMIO',
'prizes': 'prémios',
'progressive_jackpot': 'JACKPOT PROGRESSIVO',
'promo_credit': 'Crédito Promocional',
'promo_credit_condradulations': 'Parabéns! Ganhou um Crédito Promocional astronómico que pode usar no jogo!',
'promo_credits': 'CRÉDITOS PROMOCIONAIS',
'promo_history': 'Historial de Promoções',
'provably_fair': '‎‘Provably Fair’',
'provably_fair_1_paragraph': 'O Rocketman é baseado na tecnologia criptográfica intitulada \'Provably Fair\'. Esta tecnologia garante que o resultado do jogo é 100% justo. Com esta tecnologia, é impossível que quaisquer terceiros interfiram com o processo do jogo.',
'provably_fair_2_paragraph': 'O resultado de cada ronda (Coeficiente do voo de um jogo) não é gerado nos nossos servidores. É gerado com a ajuda dos jogadores da ronda e é absolutamente transparente. Desta forma, é impossível alguém manipular o resultado de um jogo. Além disso, qualqu',
'provably_fair_3_paragraph': 'Os resultados da ronda são gerados por quatro participantes independentes na ronda',
'provably_fair_4_paragraph': 'Uma vez iniciada a ronda, o jogo combina a seed do servidor com as seeds de três clientes. Dos símbolos combinados é gerado o hash SHA512, do qual se obtém o resultado do jogo.',
'provably_fair_5_paragraph': 'Pode verificar que cada ronda do jogo foi justa através do historial do jogo, ao clicar no ícone verde. Na janela que abre, poderá ver a seed do servidor, 3 pares de seeds dos jogadores, o hash combinado e o resultado da ronda.',
'provably_fair_6_paragraph': 'A versão com hash das seeds do servidor das rondas seguintes está disponível de forma pública, na janela de configurações (no menu do utilizador, verifique “Configurações do ‘Provably Fair’” e de seguida “Próxima seed do sevidor SHA256”). Aqui também pode',
'provably_fair_7_paragraph': 'A exatidão do hash pode ser verificada em qualquer calculadora online, por exemplo',
'provably_fair_system': 'sistema ‘provably fair’',
'quick_explanation': 'Explicação rápida',
'randomly_generated_server_hash': 'HASH do servidor gerada aleatoriamente',
'rank': 'CLASSIFICAÇÃO',
'raw_multiplier': 'multiplicador em bruto',
'raw_multiplier_to_edged': 'Multiplicador em Bruto para Multiplicador Limite, utilizando',
'remove_additional_bet': 'Remover aposta adicional',
'reset': 'Repor',
'return_to_player': 'RETORNO AO JOGADOR',
'rocketman_is_pure_excitement': 'O Rocketman é diversão pura! Arrisque e ganhe.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Vencedores do Rocketpot',
'round': 'Ronda',
'round_id': 'Id da Ronda',
'round_multiplier': 'Multiplicador da Ronda',
'round_results': 'resultados da ronda',
'rounds': 'rondas',
'rtp': 'RTP',
'rules': 'regras',
'rules_details': 'Regras do torneio',
'save': 'Gravar',
'schedule': 'calendário',
'second': 'segundo',
'seconds_left': 's restantes',
'server_hash': 'HASH do Servidor',
'please_wait_current_bets': 'AGUARDE QUE AS APOSTAS ATUAIS CONCLUAM',
'min_autocashout_value_must_be': 'O VALOR MÍNIMO DE AUTOCASHOUT DEVE SER',
'min_bet_value_must_be': 'O VALOR MÍNIMO DA APOSTA DEVE SER',
'server_seed': 'Seed do Servidor',
'session_dissconnected': 'Sessão Desconetada',
'multisession_not_allowed': 'Multisessão não é permitida',
'set_auto_bet': 'DEFINIR APOSTA AUTOMÁTICA',
'set_auto_cashout': 'DEFINIR CASHOUT AUTOMÁTICO',
'shourtcut_keys': 'TECLAS DE ATALHO',
'show_hex_to_deimal': 'Mostrar a conversão de hexadecimal para decimal',
'sound': 'SOM',
'sound_settigs': 'Configurações de Som',
'start_with_autoplay': 'Começar com o jogo automático',
'stop_autoplay': 'PARAR O JOGO AUTOMÁTICO',
'stop_on_loss': 'PARAR AO PERDER',
'stop_on_loss_tooltip': 'Utilize a opção Parar ao Perder para interromper a aposta automática após perder um determinado valor.<br/> Por exemplo, se começar com 100$ e fixar o valor relativo à opção Parar ao Perder em 25$.',
'stop_on_profit': 'PARAR AO LUCRAR',
'stop_on_profit_tooltip': 'Utilize a opção Parar ao Lucrar para interromper a aposta automática após ganhar um determinado valor.<br/> Por exemplo, se começar com 100$ e fixar o valor relativo à opção Parar ao Lucrar em 25$.',
'there_are_no_active_torunament': 'Neste momento, não existem torneios ativos.',
'third': 'terceiro',
'this_game_is': 'Este jogo é',
'this_tool_recalculates': 'Esta ferramenta recalcula o multiplicador a partir do hash do jogo. Pode copiar/colar qualquer hash do jogo para verificar o multiplicador.',
'time': 'HORA',
'time_frame': 'Intervalo de Tempo',
'to': 'PARA',
'toggle_animation': 'Animação dos Botões Deslizantes',
'top_odds': 'Odds Principais',
'top_wins': 'Ganhos Principais',
'total_bets': 'Total de Apostas',
'tournament': 'Torneio',
'tournament_information': 'Informação do torneio',
'try_advanced_autoplay': 'Experimente o novo recurso avançado de reprodução automática!',
'uncredited_wins': 'Ganhos não creditados',
'user': 'UTILIZADOR',
'user_and_points': 'UTILIZADOR E PONTOS',
'username': 'Nome de utilizador',
'username_must_be_at_least': 'O nome de utilizador tem de ter, pelo menos,',
'verify': 'Verificar',
'vip_popup_text': 'Pode escolher um dos avatares VIP especiais disponíveis exclusivamente para os nossos membros VIP!',
'vip_popup_title': 'Parabéns por se tornar um jogador VIP',
'waiting_for_the_next_round': 'À espera da próxima ronda…',
'warnings_are_calculated': ' ‎• Os ganhos são calculados através da multiplicação do valor de aposta pelo multiplicador existente no momento em que é feito o cashout',
'watch_take_off': 'Veja o seu Foguetão a descolar e o seu multiplicador de ganhos a aumentar.',
'watch_the_rocket': 'Observe o Foguete.',
'we_have_noticed': 'Reparámos que é a primeira vez que joga "Rocketman". Gostaria de ver um vídeo rápido que explica como se joga?',
'week': 'Semana',
'welcome_to_rocketman': 'Bem-vindo ao Rocketman – um novo e emocionante jogo! Com o Rocketman, tem a oportunidade de ganhar até 20 000x o seu valor de aposta original.',
'what_is_provably_fair': 'O que é o ‘Provably Fair’?',
'will_be_deposited': 'Será depositado no saldo dos jogadores com o primeiro ganho regular',
'win': 'GANHO',
'win_amount': 'Montante Ganho',
'win_gifs': 'GANHO',
'winner': 'vencedor',
'wins': 'ganhos',
'write_a_replay': 'Escreva uma resposta...',
'year': 'Ano',
'yes': 'SIM',
'yes_start_the_round': 'Sim, iniciar a ronda',
'you_have_cashed_out': 'Fez cashout, os seus ganhos são de ',
'you_have_won': 'GANHOU',
'your_bet_is_lost': 'perderá a sua aposta.',
'current': 'Atual',
'previous': 'Anterior',
'claim': 'Alegar',
'this_feature_will_give': 'Este recurso dará uma quantia selecionada para usuários aleatórios no chat',
'amount_per_player': 'Quantidade por jogador',
'no_of_players': 'Número de jogadores',
'meteor_shower': 'Chuva de meteoros',
'claim_your_free_bet': 'Reivindique sua aposta grátis',
'meteor_shower_total': 'Chuva de Meteoros Total',
'shower': 'CHUVA',
'total': 'Total',
'meteor_shower_incoming': 'Chuva de meteoros chegando, veja o chat!',
'insurance': 'SEGURO',
'insurance_paragraph': 'Os jogadores podem comprar um seguro para sua aposta clicando na caixa "Seguro da Aposta" ao lado do botão de aposta. O seguro custa 10% do valor total da aposta e reembolsará o valor apostado ao jogador caso o foguete exploda com um multiplicador de 1,00.',
'insure_your_bet': 'SEGURE SUA APOSTA',
'bet_insurance_promo_text': 'Selecione a opção "Seguro de Aposta" para garantir que sua aposta não caia com um multiplicador de 1,00.',
'bet_insurance_promo_text_helper': 'Leia mais na seção "Regras do Jogo"...',
'game_explanation_1': 'Escolha o seu multiplicador e, se o multiplicador do jogo exceder o multiplicador escolhido, sua vitória será',
'game_explanation_2': 'Seu Multiplicador x Sua Aposta',
'retail_max_mp_1': 'Ganhe até',
'retail_max_mp_2': 'sua aposta!',
'play_multibets': 'Jogar',
'multibets': 'MULTIAPOSTAS',
'bonus_game': 'JOGO BÔNUS',
'all_wins_doubled': 'Todos os ganhos são dobrados!',
'multibets_paragraph_1': 'para aumentar suas chances!',
'multibets_paragraph_2': 'Você pode combinar apostas em até dez rodadas em uma única aposta múltipla e ganhar pagamentos enormes!',
'multibets_paragraph_3': 'Cada vez que uma rodada é ganha, o dividendo vencedor dessa rodada é apostado na próxima rodada, até que todas as rodadas sejam vencedoras.',
'bonus_launch': 'Ganhe x2 em todas as apostas na rodada “Bonus Launch”!',
'multiplier_history': 'Histórico do Multiplicador',
'will_start_soon': 'começará em breve',
'hurry_up_and_place_your_bets': 'Apresse-se e faça suas apostas!',
'active_bets': 'Apostas Ativas',
'shop': 'COMPRAR',
'game_meteor_shower_paragraph': 'Meteor Shower concede uma aposta grátis de valor predefinido para um número predefinido de jogadores. Pode ser iniciado pelo servidor ou por um jogador. Assim que a Chuva de Meteoros for ativada, uma mensagem será exibida no jogo, direcionando os usuários a olharem para o chat onde a mensagem da Chuva de Meteoros está localizada. Os jogadores podem reivindicar a aposta grátis clicando no botão “Reivindicar”. As apostas grátis são concedidas aos jogadores que clicam primeiro no botão “Reivindicar”. O uso de autoclickers ou qualquer tipo de software de automação para emular cliques de jogadores e reivindicar prêmios é proibido e pode resultar no banimento deste recurso.',
'claming_failed': 'Falha na reivindicação',
'player_on_blacklist': 'O jogador está na lista negra',
'you_can_claim_ticket': 'Você pode reivindicar o tíquete apenas se for um jogador ativo com apostas regulares',
'you_already_have_free_tickets': 'Você já tem ingressos grátis, gaste-os primeiro',
'please_play_the_game_in_portait_mode': 'Por favor, jogue o jogo no modo retrato.',
};
langArr.ro = {
'your_insurance_returns_you': 'Asigurarea ta te returnează',
'bet_insurance': 'Asigurare de pariuri',
'max_bet_is': 'Pariul maxim este',
'already_active_bet_of': 'și există deja un pariu activ de',
'1_from_game_history': '‎1. Din istoricul jocului',
'2_in_settings_window': '‎2. În fereastra setărilor',
'3_online_calculator': '‎3. Calculatorul online',
'active_tournament_1': 'Turneul activ ',
'active_tournament_2': 'nu are încă pariuri.',
'add_another_bet': 'Adăugați alt pariu',
'advanced_autoplay': 'JOC AUTOMAT AVANSAT',
'advanced_autoplay_for_bet': 'Joc automat avansat pentru pariu',
'alien_saucer_free_bets': 'Pariuri Gratuite Farfurie Zburătoare',
'all_bets': 'Toate pariurile',
'all_in_your_hands': 'Totul stă în mâinile tale!',
'allow': 'Permitere',
'at': 'la',
'austronaut_promo_credit': 'Credit Promoțional Astronaut',
'auto_play_and_autocash': 'JOC AUTOMAT ȘI ÎNCASARE AUTOMATĂ',
'autoplay': 'Joc automat',
'autoplay_and_cashout': '‎02. Joc automat și încasare automată',
'back_to_home': 'Înapoi la acasă',
'balance': 'Sold',
'bet': 'PARIU',
'bet_amount': 'VALOARE PARIU',
'bet_and_cashout': '‎01. Pariu și Încasare',
'bet_bets_tab': 'MIZĂ',
'bet_failed': 'Pariul a Eșuat',
'bet_statistic': 'Statistici pariu',
'bet_time': 'Oră pariu',
'bet_wins': 'Câștiguri din pariu',
'bets': 'Pariuri',
'biggest_odds': 'Cele mai mari cote',
'bonus_type': 'TIP BONUS',
'bonusing_history': 'Istoric Bonus',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Câștigători Boosterpot',
'but_remember_paragraph': 'Rețineți, dacă nu ați avut timp să faceți o Încasare înainte de explozia rachetei,',
'calculate_raw_mp_to_decimal': 'Calculați multiplicatorul brut din zecimale și rotunjiți-l la cel mai apropiat număr întreg.',
'cancel': 'Anulare',
'cant_post_media_content': 'Nu poate posta frecvent conținut media',
'cashed_out': 'Încasat',
'cashout': 'Încasare',
'cashout_at': 'ÎNCASARE LA',
'cashout_before': 'Încasați fondurile înainte de explozia Rachetei și păstrați toți banii!',
'change_avatar': 'Schimbare Avatar',
'change_username': 'Schimbare nume utilizator',
'characters_long': 'caractere',
'chat': 'Chat',
'chat_room': 'Cameră de chat',
'checking_for_spam': 'Se verifică Spam...',
'close': 'Închidere',
'coeff': 'COEF.',
'collect_win': 'Colectați Câștigul',
'combined_seed': 'Seed combinat',
'company': 'Companie',
'congratulations_you_have_won_free_bets': 'Felicitări! Ați câștigat Pariuri Bonus! Puteți paria',
'convert_first_13_to_decimal': ' Conversia primilor 13 bytes în zecimal',
'd': 'z',
'date': 'DATĂ',
'date_and_time': 'Dată și Oră',
'deny': 'Refuzare',
'detailed_game_rules': 'Reguli detaliate ale jocului',
'dissconnection_policy': 'POLITICA DE DECONECTARE',
'easy_as_one_two_three': 'Simplu ca un, doi, trei',
'enter_username': 'Introduceți numele dorit',
'error_handling': 'GESTIONAREA ERORILOR',
'exit_full_screen': 'Părăsire mod ecran complet',
'first': 'primul',
'first_time_playing': 'Jucați „Rocketman” pentru prima oară?',
'folow_the_three_easy_steps': 'Urmați acești trei pași simpli și bucurați-vă de Rocketman! ',
'free_bets': 'PARIURI GRATUITE',
'free_bets_desc': 'Ați câștigat pariuri gratuite! Puteți profita de pariurile gratuite oricând, apăsând butonul de start.',
'free_bets_header': 'Pariuri gratuite',
'from': 'DE LA',
'fullscreen_mode': 'Pentru o mai bună experiență, jocul se lansează în modul ecran complet.',
'function': 'Funcție',
'game_functions': 'Funcțiile jocului',
'game_history': 'ISTORICUL JOCULUI',
'game_r_1_paragraph': 'Jucătorul poate activa opțiunea de joc automat, prin care se plasează automat un pariu pentru fiecare nouă rundă, folosind suma pariată la pariul anterior.',
'game_r_10_paragraph': 'După acordarea creditelor promoționale, pentru orice pariu plasat de jucător, banii vor fi mai întâi scăzuți din creditele promoționale până la epuizarea lor, înainte de a continua retragerea banilor din portofelul jucătorului.',
'game_r_11_paragraph': 'Creditele promoționale nu pot fi încasate, dar orice câștig obținut din astfel de pariuri este creditat jucătorului în întregime.',
'game_r_12_paragraph': 'Creditele promoționale au o perioadă de valabilitate de 30 de zile, după care expiră și nu mai pot fi folosite.',
'game_r_13_paragraph': 'Un jucător poate fi premiat aleatoriu cu pariuri gratuite. Pe ecran va apărea o fereastră pop-up care îl anunță pe jucător despre pariurile gratuite acordate, numărul de pariuri și valoarea per pariu.',
'game_r_14_paragraph': 'După acordarea pariurilor gratuite, jucătorul poate alege când să înceapă runda de pariuri gratuite. Odată începută runda de pariuri gratuite, se plasează un pariu automat cu miza specificată, iar jucătorul este cel care trebuie să încaseze banii în orice',
'game_r_15_paragraph': 'Pariurile gratuite au o perioadă de valabilitate de 30 de zile, după care expiră și nu mai pot fi folosite.',
'game_r_16a_paragraph': 'Randamentul teoretic general pentru jucător este ',
'game_r_17_paragraph': 'Procentele RTP sunt monitorizate și verificate în permanență de către entități terțe independente.',
'game_r_18_paragraph': 'În cazul în care se pierde conexiunea după plasarea unui pariu, pariul nu va fi anulat. Dacă multiplicatorul ajunge la 20.000x, pariul dvs. va fi încasat automat, iar creditele vor fi aplicate în contul dvs.',
'game_r_19_paragraph': 'Puteți discuta în joc cu alți jucători. Introduceți mesajul dvs. în câmpul de chat sau partajați pariul dvs. câștigător cu alți jucători.',
'game_r_2_paragraph': 'În câmpul „Încasare automată” poate fi introdus un multiplicator de încasare automată, după care jocul va încasa automat banii când se atinge multiplicatorul stabilit.',
'game_r_20_paragraph': 'Jucătorilor care folosesc în mod inadecvat chat-ul, abuzând de alți jucători sau folosind un limbaj nepotrivit și/sau vulgar, li se vor dezactiva privilegiile de chat.',
'game_r_21_paragraph': 'Rocketman a fost creat cu o coloană sonoră și efecte sonore superbe. Dacă doriți să dezactivați muzica și/sau efectele sonore, puteți face acest lucru prin intermediul meniului derulant.',
'game_r_22_paragraph': 'Făcând clic pe butonul „Pariurile mele”, jucătorul poate consulta ultimele sale pariuri. Pentru un istoric de joc mai amplu (de exemplu, perioade mai lungi), jucătorul va trebui să acceseze istoricul jucătorului în cadrul platformei de joc.',
'game_r_23_paragraph': 'În cazul unei erori, este posibil ca rundele să nu înceapă la timp. În acest caz, echipa noastră de asistență va încerca să rezolve problema cât mai curând posibil și să reia rundele de joc obișnuite. În cazul unei probleme cu o rundă de joc în sine, toat',
'game_r_24_paragraph': 'Latența rețelei este un aspect inevitabil al conexiunii la internet. Considerăm valide toate pariurile și încasările doar la momentul în care ajung pe serverul nostru, indiferent de perioada de latență a rețelei. ',
'game_r_3_paragraph': 'Jucătorul poate folosi tastele rapide pentru a plasa rapid un pariu sau pentru a încasa banii în joc. Configurația tastelor este următoarea',
'game_r_4_paragraph': '1% din fiecare pariu este dedicat unui fond de jackpot progresiv. Acest fond servește sistemului de jackpot progresiv',
'game_r_5_paragraph': ' ‎• Jackpot',
'game_r_6_paragraph': ' ‎• Rocketpot',
'game_r_7_paragraph': ' ‎• Boosterpot',
'game_r_8_paragraph': 'Toate nivelurile de jackpot se acordă folosind o mecanică „Mister” sau „trebuie să plătească după”. Valoarea fiecărui jackpot „trebuie să plătească după” este determinată de un generator de numere aleatorii imediat după ce jackpotul precedent este câștiga',
'game_r_8a_paragraph': 'Notă: Câștigătorul jackpot-ului poate fi doar un singur jucător Rocketman care joacă activ în runda când jackpot-ul a fost declanșat. Nu există nicio posibilitate ca, dacă jucătorul a câștigat jackpot-ul, ar trebui să îl împartă cu orice alt jucător Rocketman.',
'game_r_9_paragraph': 'Un jucător poate fi premiat la întâmplare cu credite promoționale. Pe ecran va apărea o fereastră pop-up care îl notifică pe jucător cu privire la creditele promoționale acordate și la valoarea acestora.',
'game_round': 'rundă de joc!',
'game_rules': 'Regulile jocului',
'game_rules_1_paragraph': 'Rocketman este un joc de noroc de divertisment din noua generație. Câștigați mult mai mult în câteva secunde! Rocketman este construit pe baza unui',
'game_rules_10_paragraph': ' ‎• Jocul automat se activează din fila „Auto” în Panoul de pariu, bifând caseta de selecție „Pariu automat”. După activare, pariul se va plasa automat, dar pentru încasare va trebui să apăsați butonul „încasare” la fiecare rundă. Dacă vreți ca pariul să ',
'game_rules_11_paragraph': '‎• Încasarea automată este disponibilă din fila „Auto” a panoului de pariu. După activare, pariul dvs. va fi încasat automat atunci când atinge coeficientul introdus.',
'game_rules_2_paragraph': 'care este momentan singura garanție reală a onestității în industria jocurilor de noroc.',
'game_rules_3_paragraph': '‎• Multiplicatorul de câștig începe de la 1x și crește din ce în ce mai mult pe măsură ce Racheta Norocoasă decolează.',
'game_rules_4_paragraph': ' ‎• Câștigurile dvs. reprezintă multiplicatorul la care ați făcut o Încasare, înmulțit cu pariul dvs. ',
'game_rules_5_paragraph': '‎• Înainte de începutul fiecărei runde, generatorul nostru de numere aleatorii dovedit echitabil, generează coeficientul la care va decola Racheta Norocoasă. Puteți verifica onestitatea acestei generări făcând clic în partea opusă rezultatului din fila Is',
'game_rules_6_paragraph': '‎• Selectați valoarea și apăsați butonul „Pariu” pentru a face un pariu. ',
'game_rules_7_paragraph': '‎• Puteți face două pariuri simultan, adăugând un al doilea panou de pariu. Pentru a adăuga un al doilea panou de pariu apăsați pictograma „plus”, în colțul din dreapta sus al primului panou de pariu.',
'game_rules_8_paragraph': ' ‎• Apăsați butonul „Încasare” pentru a vă încasa câștigurile. Câștigul dvs. este pariul înmulțit cu coeficientul încasării.',
'game_rules_9_paragraph': '‎• Pierdeți pariul dacă nu ați făcut o încasare înainte să zboare racheta.',
'general_rules': 'REGULI GENERALE',
'gifs': 'GIFS',
'go_back': 'Înapoi',
'good_job_buddy': 'Bravo amice, ține-o tot așa!',
'h': 'h',
'hashed_in_sha_512': 'Hash în SHA512',
'hex': 'Hex',
'hey_username': 'Salutare',
'hide_hex_to_decimal': 'Ascunde conversia hexadecimală la zecimală',
'highscore': 'Cel mai mare scor',
'how_it_works': 'Cum funcționează?',
'how_to_check': 'Cum se poate verifica?',
'how_to_play': 'Cum se joacă',
'if_the_maximum_multiplier': '‎• Dacă se atinge multiplicatorul maxim de 20.000, toate pariurile deschise sunt automat încasate cu valoarea maximă a multiplicatorului',
'increase': 'Creștere',
'input_bet_amount': 'Introduceți valoarea pariului și plasați pariul înainte de lansare.',
'insufficient_funds': 'Fonduri insuficiente',
'it_blew_up': 'A sărit în aer! Asta e...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Câștigători Jackpot',
'key': 'Cheie',
'load_more': 'ÎNCARCĂ MAI MULTE',
'loading': 'Se încarcă',
'loading_next_round': 'Se încarcă următoarea rundă...',
'loss_gifs': 'PIERDERE',
'lost_connection': 'Conexiune Pierdută',
'make_a_bet': 'Faceți un pariu',
'malfunction_voids': 'În caz de defecțiune, se anulează toate plățile și jocurile.',
'maximum_payout': 'Plata maximă este ',
'menu': 'Meniu',
'min_bet_is': 'Pariul min e',
'month': 'Lună',
'more_details': 'Detalii suplimentare',
'more_information': 'Informații suplimentare',
'multiplier': 'Multiplicator',
'multiplier_starts': '‎• Multiplicatorul începe de la 1 și urcă până la 20.000',
'multipliers_larger': 'Multiplicatorii mai mari de x20.000 sunt limitați la valoarea maximă a multiplicatorului.',
'music_settings': 'Setări muzică',
'my_bet_history': 'Istoricul pariurilor mele',
'my_bets': 'Pariurile mele',
'my_bonysing_history': 'Istoricul meu Bonus',
'network_latency': 'LATENȚA REȚELEI',
'network_latency_content': 'Latența rețelei este un aspect inevitabil al conexiunii la internet. Considerăm valide toate pariurile și încasările doar la momentul în care ajung pe serverul nostru, indiferent de perioada de latență a rețelei.',
'network_latency_title': 'LATENȚA REȚELEI',
'no_thanks': 'Nu, mulțumesc!',
'not_right_now': 'Nu acum',
'off': 'oprit',
'oh_snap': 'Oh, nu!',
'ok_close': 'Okay, Închidere!',
'ok_thanks': 'Perfect, mulțumesc!',
'on': 'pornit',
'on_loss': 'LA PIERDERE',
'on_win': 'LA CÂȘTIG',
'online': 'Online',
'per_bet': 'per pariu',
'pick_your_avatar_img': 'Alegeți-vă avatarul',
'place_bet_with_last': 'Plasați un pariu cu ultima miză selectată ',
'play_now': 'Jucați acum',
'player_1_seed': 'Seed Jucător 1',
'player_2_seed': 'Seed Jucător 2',
'player_3_seed': 'Seed Jucător 3',
'please_wait_active_bets': 'Așteptați finalizarea pariurilor curente pentru activarea pariurilor gratuite',
'points': 'PUNCTE',
'powered_by': 'Oferit de',
'previous_hand': 'Mâna anterioară',
'prize': 'PREMIU',
'prizes': 'premii',
'progressive_jackpot': 'JACKPOT PROGRESIV',
'promo_credit': 'Credit promoțional',
'promo_credit_condradulations': 'Felicitări! Ați câștigat un credit Promoțional astronomic, pe care îl puteți folosi în joc!',
'promo_credits': 'CREDITE PROMOȚIONALE',
'promo_history': 'Istoric Promo',
'provably_fair': 'Corectitudine dovedită',
'provably_fair_1_paragraph': 'Rocketman se bazează pe tehnologia criptografică, numită „Corectitudine dovedită”. Această tehnologie garantează 100 % corectitudine a rezultatului jocului. Cu această tehnologie, niciun terț nu poate interfera cu procesul de joc.',
'provably_fair_2_paragraph': 'Rezultatul fiecărei runde (coeficientul „Fly Away” al jocului) nu este generat pe serverele noastre. Acesta este generat cu ajutorul jucătorilor din rundă și este complet transparent. Astfel, nimeni nu poate manipula rezultatul jocului. De asemenea, orici',
'provably_fair_3_paragraph': 'Rezultatul rundei se generează din patru participanți independenți ai rundei',
'provably_fair_4_paragraph': 'Când începe runda, jocul îmbină seed-ul serverului cu trei seed-uri client. Din simbolurile combinate se generează un hash SHA512 și, din acest hash, rezultatul jocului.',
'provably_fair_5_paragraph': 'Puteți controla corectitudinea fiecărei runde în istoricul jocului, făcând clic pe pictograma verde. În fereastra care se deschide, veți vedea seed-ul serverului, 3 perechi de seed-uri ale jucătorilor, hash-ul combinat și rezultatul rundei.',
'provably_fair_6_paragraph': 'Versiunea hash a seed-ului serverului rundelor următoare este disponibilă public în fereastra setărilor (în meniul utilizatorului, bifați „Setări Corectitudine dovedită”, și apoi „Următorul seed de server SHA256”). De asemenea, vă puteți schimba aici seed',
'provably_fair_7_paragraph': 'Corectitudinea hash-ului poate fi verificată în orice calculator online, de exemplu',
'provably_fair_system': 'sistem cu corectitudine dovedită',
'quick_explanation': 'Scurtă explicație',
'randomly_generated_server_hash': 'HASH server generat aleatoriu',
'rank': 'CLASAMENT',
'raw_multiplier': 'multiplicator brut',
'raw_multiplier_to_edged': 'Multiplicator brut la multiplicator rotunjit, folosind',
'remove_additional_bet': 'Eliminare pariu suplimentar',
'reset': 'Resetare',
'return_to_player': 'CÂȘTIGUL JUCĂTORULUI',
'rocketman_is_pure_excitement': 'Rocketman este bucurie pură! Riscați și câștigați.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Câștigători Rocketpot',
'round': 'Rundă',
'round_id': 'ID rundă',
'round_multiplier': 'Multiplicator Rundă',
'round_results': 'rezultatele rundei',
'rounds': 'runde',
'rtp': 'RTP',
'rules': 'reguli',
'rules_details': 'Regulile turneului',
'save': 'Salvare',
'schedule': 'orar',
'second': 'al doilea',
'seconds_left': 's. rămase',
'server_hash': 'HASH Server',
'please_wait_current_bets': 'VĂ RUGĂM SĂ ASTEPTĂ PENTRU FINALIZAREA PARIURILOR ACTUALE',
'min_autocashout_value_must_be': 'VALOAREA MINĂ A RECOMANDĂRII AUTOMATICE TREBUIE SĂ FIE',
'min_bet_value_must_be': 'VALOAREA PARIULUI MIN TREBUIE SĂ FIE',
'server_seed': 'Seed server',
'session_dissconnected': 'Sesiune Deconectată',
'multisession_not_allowed': 'Nu este permisă mai multe sesiuni',
'set_auto_bet': 'SETAȚI PARIU AUTOMAT',
'set_auto_cashout': 'SETAȚI ÎNCASARE AUTOMATĂ',
'shourtcut_keys': 'TASTE RAPIDE',
'show_hex_to_deimal': 'Arată conversia hexadecimală la zecimală',
'sound': 'SUNET',
'sound_settigs': 'Setări audio',
'start_with_autoplay': 'Start cu joc automat',
'stop_autoplay': 'STOP JOC AUTOMAT',
'stop_on_loss': 'STOP LA PIERDERE',
'stop_on_loss_tooltip': 'Folosiți Stop la pierdere pentru a opri pariul automat după pierderea unei anumite sume.<br/> De exemplu, dacă începeți cu 100 USD și setați Stop la pierdere de 25 USD',
'stop_on_profit': 'STOP LA PROFIT',
'stop_on_profit_tooltip': 'Folosiți Stop la profit pentru a opri pariul automat după o anumită valoare a profitului.<br/> De exemplu, dacă începeți cu 100 USD și setați Stop la profit de 25 USD',
'there_are_no_active_torunament': 'Nu există turnee active momentan.',
'third': 'al treilea',
'this_game_is': 'Jocul este',
'this_tool_recalculates': 'Acest instrument recalculează multiplicatorul din hash-ul jocului. Puteți copia/lipi orice hash din joc pentru a verifica multiplicatorul.',
'time': 'ORĂ',
'time_frame': 'Interval de timp',
'to': 'LA',
'toggle_animation': 'Comutare animație',
'top_odds': 'Cote de top',
'top_wins': 'Câștiguri de top',
'total_bets': 'Pariuri totale',
'tournament': 'Turneu',
'tournament_information': 'Informații turneu',
'try_advanced_autoplay': 'Încercați noua funcție avansată de redare automată!',
'uncredited_wins': 'Câștiguri necreditate',
'user': 'UTILIZATOR',
'user_and_points': 'UTILIZATOR ȘI PUNCTE',
'username': 'Nume utilizator',
'username_must_be_at_least': 'Numele de utilizator trebuie să aibă minim',
'verify': 'Verificare',
'vip_popup_text': 'Puteți alege unul dintre avatarele speciale VIP disponibile exclusiv pentru membrii VIP!',
'vip_popup_title': 'Felicitări, ați devenit jucător VIP',
'waiting_for_the_next_round': 'Se așteaptă runda următoare...',
'warnings_are_calculated': ' ‎• Câștigurile se calculează înmulțind miza pariului cu multiplicatorul la momentul Încasării',
'watch_take_off': 'Priviți cum decolează Racheta și cum crește multiplicatorul câștigurilor.',
'watch_the_rocket': 'Urmăriți Racheta',
'we_have_noticed': 'Am observat că e prima oară când jucați „Rocketman”. Vreți să vedeți un scurt videoclip cu instrucțiuni de joc?',
'week': 'Săptămână',
'welcome_to_rocketman': 'Bun venit la Rocketman, un joc nou și palpitant! Rocketman vă dă șansa de a câștiga până la 20.000x miza inițială.',
'what_is_provably_fair': 'Ce înseamnă Corectitudine dovedită?',
'will_be_deposited': 'Vor fi depuse în soldurile jucătorilor cu primul câștig obișnuit',
'win': 'CÂȘTIG',
'win_amount': 'Valoare câștig',
'win_gifs': 'CÂȘTIG',
'winner': 'câștigător',
'wins': 'câștiguri',
'write_a_replay': 'Scrieți un răspuns...',
'year': 'An',
'yes': 'DA',
'yes_start_the_round': 'Da, începe runda',
'you_have_cashed_out': 'Ați încasat, câștigul dvs. este ',
'you_have_won': 'AȚI CÂȘTIGAT',
'your_bet_is_lost': 'pariul dvs. este pierdut.',
'current': 'Actual',
'previous': 'Anterior',
'claim': 'Revendicare',
'this_feature_will_give': 'Această funcție va oferi suma selectată utilizatorilor aleatori în chat',
'amount_per_player': 'Suma pe jucător',
'no_of_players': 'Numărul de jucători',
'meteor_shower': 'Ploaia de meteoriți',
'claim_your_free_bet': 'Revendicați pariul dvs. gratuit',
'meteor_shower_total': 'Ploaia de meteori Total',
'shower': 'PLOAIE',
'total': 'Total',
'meteor_shower_incoming': 'Sosește ploaia de meteoriți, uită-te la chat!',
'insurance': 'ASIGURARE',
'insurance_paragraph': 'Jucătorii pot achiziționa o asigurare pentru pariurile lor făcând clic pe caseta "Asigurare Pariu" lângă butonul de pariere. Asigurarea costă 10% din suma totală a pariului și va restitui suma pariată jucătorului în cazul în care racheta explodează cu un multiplicator de 1,00.',
'insure_your_bet': 'ASIGURAȚI-VĂ PARIUL',
'bet_insurance_promo_text': 'Selectați opțiunea „Asigurare pariu” pentru a vă asigura pariul împotriva prăbușirii rachetei la multiplicatorul de 1,00.',
'bet_insurance_promo_text_helper': 'Citiți mai multe în secțiunea „Reguli de joc”...',
'game_explanation_1': 'Alege-ți multiplicatorul, iar dacă multiplicatorul jocului depășește multiplicatorul ales, câștigul tău va fi',
'game_explanation_2': 'Multiplicatorul tău x pariul tău',
'retail_max_mp_1': 'Câștigă până la',
'retail_max_mp_2': 'pariul tau!',
'play_multibets': 'Joaca',
'multibets': 'MULTIBETURI',
'bonus_game': 'JOC BONUS',
'all_wins_doubled': 'Toate victoriile s-au dublat!',
'multibets_paragraph_1': 'pentru a-ți crește șansele!',
'multibets_paragraph_2': 'Puteți combina pariuri pe până la zece runde într-un singur multibet și puteți câștiga plăți masive!',
'multibets_paragraph_3': 'De fiecare dată când este câștigată o rundă, dividendul câștigător din acea rundă este pariat în runda următoare, până când toate rundele sunt câștigătoare.',
'bonus_launch': 'Câștigă x2 la toate pariurile din runda „Lansare bonus”!',
'multiplier_history': 'Istoricul multiplicatorului',
'will_start_soon': 'va începe în curând',
'hurry_up_and_place_your_bets': 'Grăbește-te și pariază!',
'active_bets': 'Pariuri active',
'shop': 'MAGAZIN',
'game_meteor_shower_paragraph': 'Meteor Shower acordă un pariu gratuit cu o valoare predefinită unui număr predefinit de jucători. Poate fi inițiat fie de către server, fie de către un jucător. Odată ce este activat Meteor Shower, un mesaj apare în joc, direcționând utilizatorii să se uite la chat-ul în care se află mesajul Meteor Shower. Jucătorii pot revendica pariul gratuit făcând clic pe butonul „Revendicare”. Pariurile gratuite sunt acordate jucătorilor care fac mai întâi clic pe butonul „Revendicare”. Utilizarea autoclickerelor sau a oricărui fel de software de automatizare pentru a emula clicurile jucătorilor și a revendica premii este interzisă și poate duce la interzicerea acestei funcții.',
'claming_failed': 'Revendicarea a eșuat',
'player_on_blacklist': 'Jucătorul este pe lista neagră',
'you_can_claim_ticket': 'Puteți revendica biletul numai dacă sunteți jucător activ cu pariuri obișnuite',
'you_already_have_free_tickets': 'Aveți deja bilete gratuite, vă rugăm să le cheltuiți mai întâi',
'please_play_the_game_in_portait_mode': 'Vă rugăm să jucați jocul în modul portret.',
};
langArr.ru = {
'your_insurance_returns_you': 'Ваша страховка возвращает вас',
'bet_insurance': 'Страхование ставок',
'max_bet_is': 'Максимальная ставка',
'already_active_bet_of': 'и уже есть активная ставка на',
'1_from_game_history': '‎1. В истории игр',
'2_in_settings_window': '‎2. В окне настроек',
'3_online_calculator': '‎3. Онлайн-калькулятор',
'active_tournament_1': 'В активном турнире ',
'active_tournament_2': 'ставок пока нет.',
'add_another_bet': 'Добавить дополнительную ставку',
'advanced_autoplay': 'ДОПОЛНИТЕЛЬНЫЕ НАСТРОЙКИ АВТОИГРЫ',
'advanced_autoplay_for_bet': 'Дополнительные настройки автоигры для ставки',
'alien_saucer_free_bets': 'Бесплатные ставки с Тарелкой пришельцев',
'all_bets': 'Все ставки',
'all_in_your_hands': 'Все в ваших руках!',
'allow': 'Разрешить',
'at': 'по',
'austronaut_promo_credit': 'Промо-кредит Астронавта',
'auto_play_and_autocash': 'АВТОИГРА И АВТОКЕШАУТ ',
'autoplay': 'Автоигра',
'autoplay_and_cashout': '‎02. Автоматическая игра и автоматический кешаут',
'back_to_home': 'Вернуться на главную',
'balance': 'Баланс',
'bet': 'СТАВКА',
'bet_amount': 'СУММА СТАВКИ',
'bet_and_cashout': '‎01. Ставка и кешаут',
'bet_bets_tab': 'СТАВКА',
'bet_failed': 'Ставка проиграна',
'bet_statistic': 'Статистика ставок',
'bet_time': 'Время ставки',
'bet_wins': 'Ставка выиграла',
'bets': 'Ставки',
'biggest_odds': 'Самые высокие коэффициенты',
'bonus_type': 'ВИД БОНУСА',
'bonusing_history': 'История бонусов',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Получатели Boosterpot',
'but_remember_paragraph': 'Обратите внимание! Если вы не успели нажать «Кешаут» до взрыва ракеты,',
'calculate_raw_mp_to_decimal': 'Расчет исходного множителя из десятичной дроби с округлением в меньшую сторону до ближайшего целого числа',
'cancel': 'Отмена',
'cant_post_media_content': 'Нельзя публиковать медиаконтент слишком часто.',
'cashed_out': 'Сумма кешаута',
'cashout': 'Забрать деньги',
'cashout_at': 'КЕШАУТ ПРИ',
'cashout_before': 'Нажмите кнопку «Кешаут» прежде, чем Ракета взорвется, и деньги ваши!',
'change_avatar': 'Сменить аватар',
'change_username': 'Изменить имя пользователя',
'characters_long': 'символов',
'chat': 'Чат',
'chat_room': 'Чат',
'checking_for_spam': 'Идет проверка на спам.',
'close': 'Закрыть',
'coeff': 'КОЭФФ.',
'collect_win': 'Забрать выигрыш',
'combined_seed': 'Комбинированное начальное число',
'company': 'Компания',
'congratulations_you_have_won_free_bets': 'Поздравляем! Вы выиграли Бонусные ставки! Вы можете поставить',
'convert_first_13_to_decimal': ' Преобразование первых 13 байтов в десятичный код',
'd': 'д.',
'date': 'ДАТА',
'date_and_time': 'Дата и время',
'deny': 'Отказаться',
'detailed_game_rules': 'Подробные правила игры',
'dissconnection_policy': 'ПОЛИТИКА РАСЧЕТОВ ПРИ ПОТЕРЕ СОЕДИНЕНИЯ',
'easy_as_one_two_three': 'Легко, как дважды два',
'enter_username': 'Введите выбранное имя пользователя',
'error_handling': 'ДЕЙСТВИЯ В СЛУЧАЕ ОШИБОК',
'exit_full_screen': 'Выйти из полноэкранного режима',
'first': 'первое место',
'first_time_playing': 'Впервые играете в Rocketman?',
'folow_the_three_easy_steps': 'Выполните три простых шага и наслаждайтесь игрой в Rocketman! ',
'free_bets': 'БЕСПЛАТНЫЕ СТАВКИ',
'free_bets_desc': 'Вы выиграли бесплатные ставки! Вы можете воспользоваться бесплатными ставками в любое время, нажав кнопку запуска.',
'free_bets_header': 'Бесплатные ставки',
'from': 'С',
'fullscreen_mode': 'Для удобства пользователя игра запустится в полноэкранном режиме.',
'function': 'Функция',
'game_functions': 'Игровые функции',
'game_history': 'ИСТОРИЯ ИГРЫ',
'game_r_1_paragraph': 'Игрок может активировать опцию автоматической игры, при которой для каждого нового раунда ставка размещается автоматически, при этом используется сумма предыдущей ставки.',
'game_r_10_paragraph': 'После присуждения промокредитов для любой сделанной игроком ставки деньги сначала будут вычитаться из суммы промокредитов до тех пор, пока они не будут израсходованы, после чего деньги снова начнут списываться из кошелька игрока.',
'game_r_11_paragraph': 'Промокредиты не могут быть обналичены, но любой выигрыш по сделанным с их использованием ставкам полностью зачисляется на счет игрока.',
'game_r_12_paragraph': 'Промокредиты действуют в течение 30 дней, по истечении которых они исчезают и не могут быть использованы.',
'game_r_13_paragraph': 'Игрок может в случайном порядке получить бесплатные ставки (фрибеты). На экране появится всплывающее изображение, уведомляющее игрока о присужденных бесплатных ставках, количестве ставок и сумме каждой ставки.',
'game_r_14_paragraph': 'После присуждения бесплатных ставок игрок может выбрать, когда начинать раунд бесплатных ставок. После начала раунда бесплатных ставок делается автоматическая ставка с указанной суммой, и игрок может забрать деньги в любой момент. Если соединение с компью',
'game_r_15_paragraph': 'Бесплатные ставки действуют в течение 30 дней, по истечении которых они исчезают и не могут быть использованы.',
'game_r_16a_paragraph': 'Общая теоретическая отдача игроку составляет ',
'game_r_17_paragraph': 'Процент RTP постоянно отслеживается и проверяется независимыми третьими сторонами.',
'game_r_18_paragraph': 'Если соединение потеряно после того, как ставка сделана, ставка не отменяется. Если множитель достигнет 20 000x, ваша ставка будет автоматически оплачена и кредиты будут зачислены на ваш счет.',
'game_r_19_paragraph': 'Вы можете общаться в игре с другими игроками. Введите свое сообщение в поле чата или поделитесь выигрышной ставкой с другими игроками.',
'game_r_2_paragraph': 'В поле «Автокешаут» можно ввести множитель автоматического кешаута; в этом случае при достижении указанного множителя выигрыш будет выплачиваться автоматически.',
'game_r_20_paragraph': 'У игроков, которые злоупотребляют чатом, оскорбляя других игроков или используя ненормативную и (или) нецензурную лексику, права доступа к чату будут отключены.',
'game_r_21_paragraph': 'В игре Rocketman используются приятная фоновая музыка и звуковые эффекты. Если вы хотите отключить музыку и (или) звуковые эффекты, вы можете сделать это в выпадающем меню.',
'game_r_22_paragraph': 'Нажав кнопку «Мои ставки», игрок может просмотреть свои последние ставки. Чтобы просмотреть предыдущую историю игр (например, за более ранние периоды), игрок должен получить доступ к своей истории на самой игровой платформе.',
'game_r_23_paragraph': 'В случае технической ошибки раунды могут не начаться вовремя. В этом случае наша служба поддержки постарается решить проблему как можно скорее и возобновить выполнение раундов игры в обычном режиме. В случае возникновения проблем с самим игровым раундом в',
'game_r_24_paragraph': 'Задержки передачи данных через сеть неизбежны при использовании интернет-соединений. Прием ставок и вывод средств считаются сделанными только в момент поступления соответствующих запросов на сервер, независимо от того, когда игрок инициировал такие запрос',
'game_r_3_paragraph': 'Игрок может использовать сочетания клавиш, чтобы быстро делать ставки или забирать деньги в игре. Ключевые сочетания',
'game_r_4_paragraph': '1% от каждой ставки направляется в фонд прогрессивного джекпота. Этот фонд используется в системе прогрессивного джекпота.',
'game_r_5_paragraph': ' ‎• Jackpot — самый высокий уровень, самый большой приз, присуждается реже всего;',
'game_r_6_paragraph': ' ‎• Rocketpot — средний уровень, средний приз;',
'game_r_7_paragraph': ' ‎• Boosterpot — самый низкий уровень, низкий приз, присуждается чаще всего. ',
'game_r_8_paragraph': 'Джекпоты всех уровней присуждаются с использованием алгоритма Mystery или must-hit-by. Величина каждого must-hit-by-джекпота определяется генератором случайных чисел сразу после выигрыша предыдущего джекпота и сохраняется в виде зашифрованного значения в ',
'game_r_8a_paragraph': 'Примечание. Победителем джекпота может стать только один игрок Rocketman, активно игравший в раунде, когда был активирован джекпот. Нет никакой возможности, что, если игрок выиграет джекпот, ему придется делить его с любым другим игроком Rocketman.',
'game_r_9_paragraph': 'Игрок может случайным образом получить промокредиты. На экране появится всплывающее изображение, уведомляющее игрока о присужденных промокредитах и их сумме.',
'game_round': 'игровой раунд!',
'game_rules': 'Правила игры',
'game_rules_1_paragraph': 'Rocketman — это азартное развлечение нового поколения. Выигрывайте во много раз больше за секунды! Rocketman построен на',
'game_rules_10_paragraph': ' ‎• Автоматическая игра активируется на вкладке «Авто» в Панели ставок путем установки флажка «Автоставка». После активации ставки будут размещаться автоматически, но для вывода средств в каждом раунде необходимо нажимать кнопку «Кешаут». Если вы хотите, ',
'game_rules_11_paragraph': '‎• Автокешаут доступен на вкладке «Авто» на панели ставок. После активации ваша ставка будет автоматически выводиться при достижении заданного коэффициента.',
'game_rules_2_paragraph': '‎, которая на данный момент — единственная реальная гарантия честности в индустрии азартных игр.',
'game_rules_3_paragraph': '‎• Множитель выигрыша начинается с 1х и растет по мере взлета Ракеты удачи.',
'game_rules_4_paragraph': ' ‎• Ваш выигрыш равен произведению множителя на момент кешаута и вашей ставки. ',
'game_rules_5_paragraph': '‎• Перед началом каждого раунда наш доказуемо честный генератор случайных чисел генерирует коэффициент, при котором Ракета Удачи взорвется. Проверить честность этого процесса можно, щелкнув напротив результата во вкладке «История».',
'game_rules_6_paragraph': '‎• Выберите сумму и нажмите кнопку «Ставка», чтобы сделать ставку. ',
'game_rules_7_paragraph': '‎• Вы можете сделать две ставки одновременно, добавив вторую панель ставок. Чтобы добавить вторую панель ставок, нажмите значок плюса в правом верхнем углу первой панели ставок.',
'game_rules_8_paragraph': ' ‎• Нажмите кнопку «Кешаут», чтобы забрать свой выигрыш. Ваш выигрыш равен произведению ставки и коэффициента кешаута.',
'game_rules_9_paragraph': '‎• Вы проиграете свою ставку, если не заберете деньги до взрыва ракеты.',
'general_rules': 'ОБЩИЕ ПРАВИЛА',
'gifs': 'ГИФКИ',
'go_back': 'Назад',
'good_job_buddy': 'Отлично, приятель! Продолжай в том же духе!',
'h': 'ч.',
'hashed_in_sha_512': 'Хешированные в SHA512',
'hex': 'Шестнадцатеричный код',
'hey_username': 'Привет,',
'hide_hex_to_decimal': 'Скрыть преобразование шестнадцатеричного кода в десятичный',
'highscore': 'Рекорд',
'how_it_works': 'Как это работает?',
'how_to_check': 'Как проверить?',
'how_to_play': 'Как играть',
'if_the_maximum_multiplier': '‎• При достижении максимального множителя 20 000 все открытые ставки автоматически оплачиваются с применением максимального множителя.',
'increase': 'Увеличить',
'input_bet_amount': 'Укажите сумму и сделайте ставку до взлета.',
'insufficient_funds': 'Недостаточно средств',
'it_blew_up': 'Взорвалась! Как жалко...',
'jackpot': 'Джекпот',
'jackpot_winners': 'Получатели Jackpot',
'key': 'Клавиша',
'load_more': 'ЗАГРУЗИТЬ ЕЩЕ',
'loading': 'Загрузка',
'loading_next_round': 'Загрузка следующего раунда...',
'loss_gifs': 'ПРОИГРЫШ',
'lost_connection': 'Соединение прервано',
'make_a_bet': 'Сделайте ставку',
'malfunction_voids': 'В случае сбоя все выплаты и результаты аннулируются.',
'maximum_payout': 'Максимальная выплата составляет ',
'menu': 'Меню',
'min_bet_is': 'Мин. Ставка — ',
'month': 'Месяц',
'more_details': 'Подробнее',
'more_information': 'Дополнительная информация',
'multiplier': 'Множитель',
'multiplier_starts': '‎• Множитель начинается с 1 и растет до 20 000.',
'multipliers_larger': 'Максимальное значение множителя не может превышать x20 000',
'music_settings': 'Настройки музыки',
'my_bet_history': 'История моих ставок',
'my_bets': 'Мои ставки',
'my_bonysing_history': 'Моя история бонусов',
'network_latency': 'СЕТЕВАЯ ЗАДЕРЖКА',
'network_latency_content': 'Задержки при передаче данных в интернет сети являются неизбежной частью функционирования Интернета. Действия такие как «прием платежей» и «вывод средств» действительны только в момент поступления этих запросов на сервер, независимо от того, когда игрок ин',
'network_latency_title': 'ЗАДЕРЖКА ИНТЕРНЕТ СЕТИ',
'no_thanks': 'Спасибо, нет!',
'not_right_now': 'Не сейчас',
'off': 'Выкл.',
'oh_snap': 'Вот, блин!',
'ok_close': 'Понятно. Закрыть!',
'ok_thanks': 'Понятно, спасибо!',
'on': 'Вкл.',
'on_loss': 'ПРИ ПРОИГРЫШЕ',
'on_win': 'ПРИ ВЫИГРЫШЕ',
'online': 'Онлайн',
'per_bet': 'на ставку',
'pick_your_avatar_img': 'Выберите свой аватар',
'place_bet_with_last': 'Сделать ставку в размере последней выбранной суммы ',
'play_now': 'Играть сейчас',
'player_1_seed': 'Начальное число 1-го игрока',
'player_2_seed': 'Начальное число 2-го игрока',
'player_3_seed': 'Начальное число 3-го игрока',
'please_wait_active_bets': 'Дождитесь окончания розыгрыша текущих ставок, чтобы активировать бесплатные ставки.',
'points': 'ОЧКИ',
'powered_by': 'на платформе',
'previous_hand': 'Предыдущий раунд',
'prize': 'ПРИЗ',
'prizes': 'призы',
'progressive_jackpot': 'ПРОГРЕССИВНЫЙ ДЖЕКПОТ',
'promo_credit': 'Промокредит',
'promo_credit_condradulations': 'Поздравляем! Вы выиграли Промо-кредит на астрономическую сумму, который можно использовать в игре!',
'promo_credits': 'ПРОМОКРЕДИТЫ',
'promo_history': 'История промоакций',
'provably_fair': 'Доказуемая честность',
'provably_fair_1_paragraph': 'Игра Rocketman основана на криптографической технологии под названием Provably Fair (доказуемая честность). Эта технология гарантирует 100 % честность игрового результата. Благодаря этой технологии никакое третье лицо не может вмешаться в игровой процесс.',
'provably_fair_2_paragraph': 'Результат каждого раунда (игровой коэффициент «взлета») не генерируется на наших серверах. Он генерируется с помощью игроков раунда и полностью прозрачен. Таким образом, никто не сможет манипулировать результатами игры. Также любой желающий может проверит',
'provably_fair_3_paragraph': 'Результат раунда формируется четырьмя независимыми участниками раунда.',
'provably_fair_4_paragraph': 'Когда начинается раунд, игра объединяет начальное число сервера с начальным числом трех клиентов. Из объединенных символов генерируется хеш SHA512, а из этого хеша — результат игры.',
'provably_fair_5_paragraph': 'Вы можете проверить честность каждого раунда в истории игр, нажав на зеленый значок. В открывшемся окне вы увидите начальное число сервера, 3 пары начальных чисел игроков, комбинированный хеш и результат раунда.',
'provably_fair_6_paragraph': 'Хешированная версия начального числа сервера для следующего раунда доступна в окне настроек (в меню пользователя зайдите в раздел «Настройки доказуемой честности», а затем выберите «Следующее начальное число сервера SHA256»). Здесь вы также можете изменит',
'provably_fair_7_paragraph': 'Правильность хеша можно проверить в любом онлайн-калькуляторе, например',
'provably_fair_system': 'системе доказуемой честности',
'quick_explanation': 'Краткое объяснение',
'randomly_generated_server_hash': 'Случайно сгенерированный HASH сервера',
'rank': 'МЕСТО',
'raw_multiplier': 'исходный множитель',
'raw_multiplier_to_edged': 'Преобразование исходного множителя в ограниченный с использованием процента выплат игрокам в размере',
'remove_additional_bet': 'Удалить дополнительную ставку',
'reset': 'Сбросить',
'return_to_player': 'ПРОЦЕНТ ВЫПЛАТ ИГРОКУ',
'rocketman_is_pure_excitement': 'Rocketman — это сплошной азарт! Рискуйте и выигрывайте.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Получатели Rocketpot',
'round': 'Раунд',
'round_id': '‎№ раунда',
'round_multiplier': 'Множитель раунда',
'round_results': 'результаты раунда',
'rounds': 'раунды',
'rtp': 'RTP',
'rules': 'правила',
'rules_details': 'Правила турнира',
'save': 'Сохранить',
'schedule': 'продолжительность',
'second': 'второе место',
'seconds_left': 'сек. осталось',
'server_hash': 'Хеш сервера',
'please_wait_current_bets': 'ПОЖАЛУЙСТА, ПОДОЖДИТЕ ЗАВЕРШЕНИЯ ТЕКУЩИХ СТАВОК',
'min_autocashout_value_must_be': 'МИН. ЗНАЧЕНИЕ АВТОКАШАУТА ДОЛЖНО БЫТЬ БЫТЬ',
'min_bet_value_must_be': 'МИН. ЗНАЧЕНИЕ СТАВКИ ДОЛЖНО БЫТЬ БЫТЬ',
'server_seed': 'Начальное число сервера',
'session_dissconnected': 'Сеанс отключен',
'multisession_not_allowed': 'Мультисессия не допускается',
'set_auto_bet': 'УСТАНОВИТЬ АВТОСТАВКУ',
'set_auto_cashout': 'УСТАНОВИТЬ АВТОКЕШАУТ',
'shourtcut_keys': 'СОЧЕТАНИЯ КЛАВИШ',
'show_hex_to_deimal': 'Показать преобразование шестнадцатеричного кода в десятичный',
'sound': 'ЗВУК',
'sound_settigs': 'Настройки звука',
'start_with_autoplay': 'Начать автоигру',
'stop_autoplay': 'ОСТАНОВИТЬ АВТОИГРУ',
'stop_on_loss': 'ОСТАНОВИТЬ ПРИ ПРОИГРЫШЕ',
'stop_on_loss_tooltip': 'Используйте функцию «Остановить при проигрыше», чтобы прекратить автоматическое размещение ставок после проигрыша определенной суммы.<br/> Например, вы начинаете игру со 100 долларами и устанавливаете значение параметра «Остановить при проигрыше на 25 долларов',
'stop_on_profit': 'ОСТАНОВИТЬ ПРИ ВЫИГРЫШЕ',
'stop_on_profit_tooltip': 'Используйте функцию «Остановить при выигрыше», чтобы прекратить автоматическое размещение ставок после выигрыша определенной суммы.<br/> Например, вы начинаете игру со 100 долларами и устанавливаете значение параметра «Остановить при проигрыше на 25 долларов',
'there_are_no_active_torunament': 'На данный момент активных турниров нет.',
'third': 'третье место',
'this_game_is': 'Это игра',
'this_tool_recalculates': 'Этот инструмент пересчитывает множитель на основе игрового хеша. Вы можете скопировать и вставить игровой хеш, чтобы проверить множитель.',
'time': 'ВРЕМЯ',
'time_frame': 'Период времени',
'to': 'ПО',
'toggle_animation': 'Переключить анимацию',
'top_odds': 'Лучшие коэффициенты',
'top_wins': 'Лучшие победы',
'total_bets': 'Всего ставок',
'tournament': 'Турнир',
'tournament_information': 'Информация о турнире',
'try_advanced_autoplay': 'Попробуйте новую расширенную функцию автозапуска!',
'uncredited_wins': 'Незасчитанные выигрыши',
'user': 'ПОЛЬЗОВАТЕЛЬ',
'user_and_points': 'ИГРОК И ОЧКИ',
'username': 'Имя пользователя',
'username_must_be_at_least': 'Имя пользователя должно содержать не менее',
'verify': 'Проверить',
'vip_popup_text': 'Вы можете выбрать одну из VIP-аватарок, доступных только нашим VIP-членам!',
'vip_popup_title': 'Поздравляем! Вы стали VIP-игроком!',
'waiting_for_the_next_round': 'Ожидание следующего раунда...',
'warnings_are_calculated': ' ‎• Выигрыши рассчитываются путем умножения сделанной ставки на множитель, указанный в момент Кешаута.',
'watch_take_off': 'Следите, как ваша Ракета взлетает и множитель вашего выигрыша увеличивается.',
'watch_the_rocket': 'Следите за полетом',
'we_have_noticed': '‎«Мы заметили, что вы впервые играете в Rocketman. Хотите посмотреть короткое видео, объясняющее правила игры?»',
'week': 'Неделя',
'welcome_to_rocketman': 'Добро пожаловать в новую увлекательную игру Rocketman! В Rocketman вы можете выиграть до 20 000х вашей первоначальной ставки.',
'what_is_provably_fair': 'Что такое «доказуемая честность»?',
'will_be_deposited': 'Будут зачислены на баланс игрока при первом обычном выигрыше',
'win': 'ВЫИГРЫШ',
'win_amount': 'Сумма выигрыша',
'win_gifs': 'ВЫИГРЫШ',
'winner': 'победитель',
'wins': 'выигрыши',
'write_a_replay': 'Ответить...',
'year': 'Год',
'yes': 'ДА',
'yes_start_the_round': 'Да, начать раунд',
'you_have_cashed_out': 'Вы забрали деньги, ваш выигрыш составил ',
'you_have_won': 'ВЫ ВЫИГРАЛИ',
'your_bet_is_lost': 'вы потеряете вашу ставку.',
'current': 'Текущий',
'previous': 'Предыдущий',
'claim': 'Требовать',
'this_feature_will_give': 'Эта функция предоставит выбранную сумму случайным пользователям в чате.',
'amount_per_player': 'Сумма на игрока',
'no_of_players': 'Количество игроков',
'meteor_shower': 'Метеоритный дождь',
'claim_your_free_bet': 'Получите бесплатную ставку',
'meteor_shower_total': 'Метеоритный дождь Всего',
'shower': 'ДОЖДЬ',
'total': 'Общий',
'meteor_shower_incoming': 'Приближается метеоритный дождь, загляните в чат!',
'insurance': 'СТРАХОВАНИЕ',
'insurance_paragraph': 'Игроки могут приобрести страховку для своей ставки, нажав на флажок "Страхование ставки" рядом с кнопкой ставки. Страховка стоит 10% от общей суммы ставки и вернет сумму ставки игроку, если ракета взорвется с коэффициентом 1,00.',
'insure_your_bet': 'ЗАСТРАХОВАЙТЕ СВОЮ СТАВКУ',
'bet_insurance_promo_text': 'Выберите опцию «Страхование ставки», чтобы застраховать свою ставку от падения ракеты с множителем 1,00.',
'bet_insurance_promo_text_helper': 'Подробнее читайте в разделе «Правила игры»...',
'game_explanation_1': 'Выберите свой множитель, и если игровой множитель превысит выбранный вами множитель, ваш выигрыш составит',
'game_explanation_2': 'Ваш множитель x ваша ставка',
'retail_max_mp_1': 'Выиграйте до',
'retail_max_mp_2': 'ваша ставка!',
'play_multibets': 'Играть',
'multibets': 'МУЛЬТИСТАВКИ',
'bonus_game': 'БОНУСНАЯ ИГРА',
'all_wins_doubled': 'Все выигрыши удваиваются!',
'multibets_paragraph_1': 'чтобы увеличить свои шансы!',
'multibets_paragraph_2': 'Вы можете объединить ставки на десять раундов в одну экспресс-ставку и выиграть огромные выплаты!',
'multibets_paragraph_3': 'Каждый раз, когда раунд выигран, дивиденды от выигрыша в этом раунде делаются на следующий раунд, пока все раунды не будут выигрышными.',
'bonus_launch': 'Выиграйте х2 по всем ставкам в раунде «Запуск бонусов»!',
'multiplier_history': 'История множителя',
'will_start_soon': 'скоро начнется',
'hurry_up_and_place_your_bets': 'Спешите делать ставки!',
'active_bets': 'Активные ставки',
'shop': 'МАГАЗИН',
'game_meteor_shower_paragraph': 'Метеоритный дождь дает одну бесплатную ставку заранее определенного значения заранее определенному количеству игроков. Он может быть инициирован как сервером, так и игроком. После активации Метеоритного дождя в игре появляется сообщение, предлагающее пользователям просмотреть чат, где находится сообщение Метеоритного дождя. Игроки могут претендовать на бесплатную ставку, нажав кнопку «Получить». Бесплатные ставки предоставляются игрокам, которые первыми нажимают кнопку «Получить». Использование автокликеров или любого другого программного обеспечения для автоматизации для имитации кликов игроков и получения призов запрещено и может привести к блокировке этой функции.',
'claming_failed': 'Не удалось подать заявку',
'player_on_blacklist': 'Игрок в черном списке',
'you_can_claim_ticket': 'Вы можете претендовать на билет только в том случае, если вы активный игрок и делаете обычные ставки.',
'you_already_have_free_tickets': 'У вас уже есть бесплатные билеты, пожалуйста, потратьте их сначала',
'please_play_the_game_in_portait_mode': 'Пожалуйста, играйте в игру в портретном режиме.',
};
langArr.sq = {
'your_insurance_returns_you': 'Sigurimi juaj ju kthen',
'bet_insurance': 'Sigurimi i basteve',
'max_bet_is': 'Basti maksimal është',
'already_active_bet_of': 'dhe tashmë ka një bast aktiv të',
'1_from_game_history': '‎1. Nga historiku i lojës',
'2_in_settings_window': '‎2. Në dritaren e cilësimeve',
'3_online_calculator': '‎3. Llogaritësi online',
'active_tournament_1': 'Turneu aktiv ',
'active_tournament_2': 'nuk ka ende baste.',
'add_another_bet': 'Shto një bast tjetër',
'advanced_autoplay': 'AUTOLOJË E AVANCUAR',
'advanced_autoplay_for_bet': 'Autolojë e avancuar për Bastin',
'alien_saucer_free_bets': 'Baste falas Alien Saucer',
'all_bets': 'Të gjitha bastet',
'all_in_your_hands': 'Gjithçka varet nga ju!',
'allow': 'Lejo',
'at': 'Në',
'austronaut_promo_credit': 'Kredia Promo Astronaut',
'auto_play_and_autocash': 'LOJA AUTOMATIKE DHE ARKËTIMI AUTOMATIK ',
'autoplay': 'Autolojë',
'autoplay_and_cashout': '‎02. Loja automatike & Arkëtimi automatik',
'back_to_home': 'Mbrapa te faqja kryesore',
'balance': 'Balanca',
'bet': 'VENDOS BAST',
'bet_amount': 'SHUMA E BASTIT',
'bet_and_cashout': '‎01. Bastet & Arkëtimi',
'bet_bets_tab': 'SHUMA E BASTIT',
'bet_failed': 'Basti dështoi',
'bet_statistic': 'Statistika e basteve',
'bet_time': 'Koha e bastit',
'bet_wins': 'Bastet e fituara',
'bets': 'Baste',
'biggest_odds': 'Koeficienti më i lartë',
'bonus_type': 'LLOJI I BONUSIT',
'bonusing_history': 'Historiku i bonuseve',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Fituesit e Boosterpot',
'but_remember_paragraph': 'Kujdes, nëse nuk arrini të arkëtoni fitimin para se të shpërthejë raketa, ',
'calculate_raw_mp_to_decimal': 'Llogaritni shumëzuesin fillestar nga decimalja dhe rrumbullakoni atë në numrin më të afërt të plotë',
'cancel': 'Anulo',
'cant_post_media_content': 'Nuk mund të postoni shpesh përmbajtje mediatike',
'cashed_out': 'Arkëtimi',
'cashout': 'ARKËTIMI',
'cashout_at': 'ARKËTO NË',
'cashout_before': 'Arkëtoni fitimin para se të shpërthejë Raketa dhe paratë janë tuajat!',
'change_avatar': 'Ndrysho Avatarin',
'change_username': 'Ndrysho fjalëkalimin',
'characters_long': 'karaktere',
'chat': 'Chat',
'chat_room': 'Chat Room',
'checking_for_spam': 'Po kontrollon për spam...',
'close': 'Mbylle',
'coeff': 'KOEF.',
'collect_win': 'Mblidh fitimin',
'combined_seed': 'Vargu i kombinuar',
'company': 'Kompania',
'congratulations_you_have_won_free_bets': 'Urime! Ju keni fituar Baste bonus! Mund të vendosni',
'convert_first_13_to_decimal': ' Konverto 13 bajtët e parë në decimal',
'd': 'd',
'date': 'DATA',
'date_and_time': 'Data dhe koha',
'deny': 'Refuzo',
'detailed_game_rules': 'Rregullat e detajuara të lojës',
'dissconnection_policy': 'POLITIKA E SHKËPUTJES NGA LIDHJA',
'easy_as_one_two_three': 'E thjeshtë, si bukë me djathë',
'enter_username': 'Futni emrin e përdoruesit që dëshironi',
'error_handling': 'GABIM GJATË TRAJTIMIT',
'exit_full_screen': 'Dil nga modaliteti i ekranit të plotë',
'first': 'i pari',
'first_time_playing': 'Hera e parë që luani "Rocketman"?',
'folow_the_three_easy_steps': 'Ndiqni tri hapat e thjeshtë dhe shijoni lojën! ',
'free_bets': 'BASTE FALAS',
'free_bets_desc': 'Ju fituat baste falas! Mund të përfitoni nga bastet falas në çdo kohë duke shtypur butonin e nisjes.',
'free_bets_header': 'Baste falas',
'from': 'NGA',
'fullscreen_mode': 'Për një përvojë më të mirë gjatë lojës, loja do të fillojë në modalitetin e ekranit të plotë.',
'function': 'Funksioni',
'game_functions': 'Funksionet e lojës',
'game_history': 'HISTORIKU I LOJËS',
'game_r_1_paragraph': 'Një lojtar mund të aktivizojë opsionin e lojës automatike, ku për çdo raund të ri automatikisht vendoset një bast duke përdorur shumën e bastit nga basti i mëparshëm.',
'game_r_10_paragraph': 'Pasi të jepen kreditë promo, për çdo bast që vendos një lojtar, paratë së pari do të zbriten nga kreditë promo derisa të mbarojnë ato, para se të vazhdojnë të tërhiqen para nga portofoli i lojtarit.',
'game_r_11_paragraph': 'Kreditë nuk mund të arkëtohen, por çdo fitim nga bastet e tilla i kreditohet lojtarit plotësisht.',
'game_r_12_paragraph': 'Kreditë promo kanë një periudhë vlefshmërie prej 30 ditësh. Pas kësaj periudhe ato skadojnë dhe nuk mund të përdoren.',
'game_r_13_paragraph': 'Një lojtari mund t\'i jepen rastësisht baste falas. Në ekran do të shfaqet një grafik informativ duke njoftuar lojtarin për bastet e dhëna falas dhe vlerën e secilit prej tyre.',
'game_r_14_paragraph': 'Pasi të jepen bastet falas, lojtari mund të zgjedhë kur të fillojë raundin e basteve falas. Pasi të fillojë raundi i basteve falas, vendoset një bast automatik me një shumë të specifikuar basti dhe i takon lojtarit të arkëtojë atë në çdo moment të caktuar',
'game_r_15_paragraph': 'Bastet falas kanë një periudhë vlefshmërie prej 30 ditësh. Pas kësaj periudhe ato skadojnë dhe nuk mund të përdoren.',
'game_r_16a_paragraph': 'Kthimi teorik i përgjithshëm te lojtari është ',
'game_r_17_paragraph': 'Përqindjet e RTP-së monitorohen dhe verifikohen vazhdimisht nga palë të treta të pavarura.',
'game_r_18_paragraph': 'Nëse lidhja shkëputet pasi të vendoset një bast, basti nuk do të anulohet. Nëse shumëzuesi arrin 20.000x basti juaj do të arkëtohet automatikisht dhe kreditet do të transferohen në llogarinë tuaj.',
'game_r_19_paragraph': 'Në lojë, ju mund të bisedoni me lojtarë të tjerë. Shkruani mesazhin tuaj në fushën e chat-it, ose shpërndani bastin tuaj fitues me lojtarët e tjerë.',
'game_r_2_paragraph': 'Një shumëzues për arkëtim automatik mund të futet në fushën "Arkëtimi automatik" dhe kur futet ky shumëzues, loja automatikisht do të arkëtojë kur të arrihet shumëzuesi i caktuar.',
'game_r_20_paragraph': 'Lojtarët që keqpërdorin Chat-in duke abuzuar me lojtarët e tjerë, ose duke përdorur gjuhë të papërshtatshme dhe/ose të ndyrë, atyre do t\'ju çaktivizohen privilegjet e Chat-it.',
'game_r_21_paragraph': 'Rocketman është zhvilluar duke pasur parasysh një muzikë të bukur në sfond dhe efekte zanore. Nëse dëshironi të çaktivizoni muzikën dhe/ose efektet zanore, mund ta bëni përmes menysë rënëse.',
'game_r_22_paragraph': 'Duke klikuar butonin "Bastet e mia", lojtari mund të rishikojë bastet e tij të fundit. Për historikun më të hershëm të lojës (p.sh. periudha më të gjata), lojtari do të duhet të hyjë në historikun e lojtarit brenda vetë platformës së lojës.',
'game_r_23_paragraph': 'Në rast të një gabimi, raundet mund të mos fillojnë në kohë. Nëse ndodh kjo, ekipi ynë për mbështetje do të përpiqet të zgjidhë çështjen sa më shpejt të jetë e mundur dhe të fillojë sërish me raundet e rregullta të lojës. Në rast të një problemi me vetë r',
'game_r_24_paragraph': 'Vonesa e rrjetit është pjesë e pashmangshme e lidhjes në internet. Të gjitha bastet dhe pagesat i konsiderojmë të vlefshme vetëm në momentin kur ato arrijnë në serverin tonë, pavarësisht nga kohëzgjatja e vonesës së rrjetit. ',
'game_r_3_paragraph': 'Lojtari mund të përdorë tastierën e shkurtoreve për të vendosur shpejt një bast ose për të arkëtuar paratë në lojë. Përshkrimi i tastierave është si në vijim',
'game_r_4_paragraph': '1% e çdo basti dedikohet një fondi jackpot progresiv. Ky fond shërben për sistemin e jackpot-it progresiv',
'game_r_5_paragraph': ' ‎• Jackpot - Niveli më i lartë, çmimi më i madh, më pak i frekuentuar',
'game_r_6_paragraph': ' ‎• Rocketpot – Niveli i mesëm, çmimi i mesëm',
'game_r_7_paragraph': ' ‎• Boosterpot – Niveli më i ulët, çmimi i ulët, më i frekuentuari ',
'game_r_8_paragraph': 'Të gjitha nivelet e jackpot-it jepen duke përdorur një mekanikë "Misterioze" ose "must-hit-by". Vlera e çdo jackpot-i "must-hit-by" përcaktohet menjëherë pasi të fitohet jackpot-i paraprak nga një gjenerues i numrave të rastit dhe ruhet si një vlerë e kod',
'game_r_8a_paragraph': 'Shënim: Fituesi i xhekpotit mund të jetë vetëm një lojtar i vetëm Rocketman që luan aktivisht në raund kur jackpoti është aktivizuar. Nuk ka asnjë mundësi që nëse lojtari ka fituar jackpot-in, do të duhet ta ndajë atë me ndonjë lojtar tjetër të Rocketman.',
'game_r_9_paragraph': 'Një lojtari mund t\'i jepen rastësisht kredi promo. Në ekran do të shfaqet një grafik informativ duke njoftuar lojtarin për kreditë promo të dhëna dhe vlerën e tyre.',
'game_round': 'raundi i lojës!',
'game_rules': 'Rregullat e lojës',
'game_rules_1_paragraph': 'Rocketman është një lloj argëtimi lojërash fati i gjeneratës së re. Fitoni shumë herë më shumë në sekonda! Rocketman është ndërtuar në bazë të',
'game_rules_10_paragraph': ' ‎• Loja automatike aktivizohet nga skeda "Auto" në Panelin e basteve, duke aktivizuar kutinë e kontrollit "Bast automatik". Pasi të aktivizohet, bastet do të vendosen automatikisht, por për të arkëtuar, duhet të shtypni butonin "arkëto" në çdo raund. Nës',
'game_rules_11_paragraph': '‎• Arkëtimi automatik është i disponueshëm nga skeda "Auto" në panelin e basteve. Pasi të aktivizohet, basti juaj do të arkëtohet automatikisht, kur arrin koeficientin që keni caktuar.',
'game_rules_2_paragraph': '‎, i cili aktualisht është garancia e vetme e vërtetë e ndershmërisë në industrinë e lojërave të fatit.',
'game_rules_3_paragraph': '‎• Shumëzuesi i fitimit fillon me 1x dhe rritet gjithnjë e më shumë ndërsa avioni Lucky Rocket niset.',
'game_rules_4_paragraph': ' ‎• Fitimi juaj është shumëzuesi në të cilin keni bërë Arkëtimin, shumëzuar me shumën e bastit tuaj. ',
'game_rules_5_paragraph': '‎• Para fillimit të çdo raundi, gjeneratori ynë i ndershëm i numrave rastësorë gjeneron koeficientin, se sa larg do të fluturojë Lucky Rocket. Ndershmërinë e këtij gjeneratori mund ta kontrolloni duke klikuar në skedën Historiku.',
'game_rules_6_paragraph': '‎• Për të vendosur një bast, zgjidhni shumën dhe shtypni butonin "Vendos bast". ',
'game_rules_7_paragraph': '‎• Mund të vendosni dy baste njëkohësisht, duke shtuar panelin e dytë të basteve. Për të shtuar panelin e dytë të bastit shtypni ikonën plus, e vendosur në këndin e sipërm të djathtë të panelit të parë të basteve.',
'game_rules_8_paragraph': ' ‎• Shtypni butonin "Arkëto" për të arkëtuar fitimet tuaja. Fitimi juaj është basti i shumëzuar me koeficientin e arkëtimit.',
'game_rules_9_paragraph': '‎• Basti juaj humbet, nëse nuk keni arkëtuar para se raketa të fluturojë larg.',
'general_rules': 'RREGULLAT E PËRGJITHSHME',
'gifs': 'GIFS',
'go_back': 'Mbrapa',
'good_job_buddy': 'Punë e mirë shoku, mos ndalo',
'h': 'o',
'hashed_in_sha_512': 'Sheshuar në SHA512',
'hex': 'Hex',
'hey_username': 'Çkemi',
'hide_hex_to_decimal': 'Fshih konvertimin hex në decimal',
'highscore': 'Rezultati i lartë',
'how_it_works': 'Si funksionon?',
'how_to_check': 'Si të kontrollohet?',
'how_to_play': 'Si të luani',
'if_the_maximum_multiplier': '‎• Nëse arrihet shumëzuesi maksimal prej 20.000, të gjitha bastet e hapura arkëtohen automatikisht me shumëzuesin maksimal',
'increase': 'Rrit',
'input_bet_amount': 'Fut shumën e bastit dhe vendos bastin tuaj para nisjes.',
'insufficient_funds': 'Fonde të pamjaftueshme',
'it_blew_up': 'Shpërtheu! Epo mirë...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Fituesit e Jackpot',
'key': 'Tastiera',
'load_more': 'HAP MË SHUMË',
'loading': 'Po ngarkohet',
'loading_next_round': 'Po ngarkohet raundi i radhës...',
'loss_gifs': 'HUMBJE',
'lost_connection': 'Lidhja u shkëput',
'make_a_bet': 'Vendos një bast',
'malfunction_voids': 'Mosfunksionimi anulon të gjitha Pagesat dhe Lojërat.',
'maximum_payout': 'Pagesa maksimale është ',
'menu': 'Meny',
'min_bet_is': 'Basti min. Është',
'month': 'Muaj',
'more_details': 'Më shumë informacion',
'more_information': 'Më shumë informacion',
'multiplier': 'Shumëzuesi',
'multiplier_starts': '‎• Shumëzuesi fillon nga 1 dhe arrin deri në 20.000',
'multipliers_larger': 'Shumëzuesit më të mëdhenj se x20.000 janë të kufizuara në vlerën maksimale të shumëzuesit',
'music_settings': 'Cilësimet e muzikës',
'my_bet_history': 'Historiku i basteve të mia',
'my_bets': 'Bastet e mia',
'my_bonysing_history': 'Historiku i bonuseve të mia',
'network_latency': 'VONESA NË RRJET',
'network_latency_content': 'Vonesat në rrjeti janë pjesë e pashmangshme e lidhjes me internetin. Ne i konsiderojmë të vlefshme të gjitha bastet dhe arkëtimet vetëm në momentin kur ato arrijnë në serverin tonë, pavarësisht nga kohëzgjatja e vonesës.',
'network_latency_title': 'VONESA NË RRJET',
'no_thanks': 'Jo, faleminderit!',
'not_right_now': 'Jo tani',
'off': 'off',
'oh_snap': 'Dreqi e marrtë!',
'ok_close': 'Në rregull, Mbyll!',
'ok_thanks': 'Në rregull, faleminderit!',
'on': 'on',
'on_loss': 'NË HUMBJE',
'on_win': 'NË FITORE',
'online': 'Online',
'per_bet': 'për bast',
'pick_your_avatar_img': 'Zgjidh foton e avatarit',
'place_bet_with_last': 'Vendosni një bast me shumën e fundit që keni zgjedhur ',
'play_now': 'Luaj tani',
'player_1_seed': 'Vargu i Lojtarit 1',
'player_2_seed': 'Vargu i Lojtarit 2',
'player_3_seed': 'Vargu i Lojtarit 3',
'please_wait_active_bets': 'Ju lutemi prisni që të përfundojnë bastet aktuale në mënyrë që të aktivizoni bastet falas',
'points': 'PIKËT',
'powered_by': 'Mundësuar nga',
'previous_hand': 'Basti i mëparshëm',
'prize': 'ÇMIMI',
'prizes': 'çmimet',
'progressive_jackpot': 'JACKPOT PROGRESIV',
'promo_credit': 'Kredi Promo',
'promo_credit_condradulations': 'Urime! Ju keni fituar një kredi astronomike Promo që mund ta përdorni në lojë!',
'promo_credits': 'KREDI PROMO',
'promo_history': 'Historiku Promo',
'provably_fair': 'Lojë e ndershme',
'provably_fair_1_paragraph': 'Rocketman bazohet në teknologjinë kriptografike të quajtur \'Lojë e ndershme\'. Kjo teknologji garanton 100% rezultat të ndershëm të lojës. Me këtë teknologji, është e pamundur që një palë e tretë të ndërhyjë në procesin e lojës.',
'provably_fair_2_paragraph': 'Rezultati i secilit raund (koeficienti i lojës \'Fluturo larg\') nuk gjenerohet në serverët tanë. Ai gjenerohet me ndihmën e lojtarëve në raund dhe është plotësisht transparent. Në këtë mënyrë, është e pamundur që dikush të manipulojë verdiktin e lojës. G',
'provably_fair_3_paragraph': 'Rezultati i raundit gjenerohet nga katër pjesëmarrës të pavarur të raundit',
'provably_fair_4_paragraph': 'Kur fillon raundi, loja kombinon vargun e serverit me tre vargje të klientëve. Nga ky kombinim gjenerohet algoritmi hash SHA512, dhe nga ky algoritëm hash - rezultati i lojës.',
'provably_fair_5_paragraph': 'Ndershmërinë e çdo raundi mund ta kontrolloni nga historiku i lojës, duke klikuar në ikonën e gjelbër. Në dritaren që do të hapet, do të shihni vargun e serverit, 3 çifte vargjesh të klientëve, algoritmin e kombinuar hash dhe rezultatin e raundit.',
'provably_fair_6_paragraph': 'Versioni hash i raundeve të ardhshme të vargut të serverit është i disponueshëm publikisht në dritaren e cilësimeve (Në menynë e përdoruesit, kontrolloni "Cilësimet Lojë e ndershme" dhe më pas në "Vargu i radhës së serverit SHA256"). Këtu, gjithashtu mund',
'provably_fair_7_paragraph': 'Korrektësia e algoritmit hash mund të kontrollohet në çdo llogaritës online, si për shembull',
'provably_fair_system': 'sistemit Lojë e ndershme',
'quick_explanation': 'Shpjegim i shkurtër',
'randomly_generated_server_hash': 'HASH-i i serverit i gjeneruar rastësisht',
'rank': 'RENDITJA',
'raw_multiplier': 'shumëzuesi fillestar',
'raw_multiplier_to_edged': 'Nga shumëzuesi fillestar arrijmë te shumëzuesi përfundimtar duke përdorur',
'remove_additional_bet': 'Hiq bastin shtesë',
'reset': 'Rivendos',
'return_to_player': 'KTHIMI TE LOJTARI',
'rocketman_is_pure_excitement': 'Rocketman është drithërimë e pastër! Rreziko dhe fito.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Fituesit e Rocketpot',
'round': 'Raundi',
'round_id': 'Id-ja e raundit',
'round_multiplier': 'Shumëzuesi i raundit',
'round_results': 'rezultatet e raundit',
'rounds': 'raunde',
'rtp': 'RTP',
'rules': 'rregullat',
'rules_details': 'Rregullat e turneut',
'save': 'Ruaj',
'schedule': 'orari',
'second': 'i dyti',
'seconds_left': 's. të mbetura',
'server_hash': 'HASH-i i serverit',
'please_wait_current_bets': 'JU LUTEM PRIT TË PLOTËSOHEN BASTET AKTUALE',
'min_autocashout_value_must_be': 'VLERA MIN AUTOKATARISE DUHET TË JETË',
'min_bet_value_must_be': 'VLERA MINI I BATIT DUHET TË JETË',
'server_seed': 'Vargu i serverit',
'session_dissconnected': 'Sesioni u shkëput',
'multisession_not_allowed': 'Multisesionet nuk lejohen',
'set_auto_bet': 'VENDOS BASTIN AUTOMAIK',
'set_auto_cashout': 'VENDOS ARKËTIMIN AUTOMATIK',
'shourtcut_keys': 'TASTIERAT E SHKURTOREVE',
'show_hex_to_deimal': 'Shfaq konvertimin hex në decimal',
'sound': 'EFEKTE ZANORE ',
'sound_settigs': 'Cilësimet e zërit',
'start_with_autoplay': 'Fillo me autolojë',
'stop_autoplay': 'NDAL AUTOLOJËN',
'stop_on_loss': 'NDAL NË HUMBJE',
'stop_on_loss_tooltip': 'Përdorni Ndal në Humbje për të ndaluar bastet automatike pasi të keni humbur një shumë të caktuar.<br/> Për shembull, nëse filloni me 100$ dhe vendosni Ndal në Humbje në 25$',
'stop_on_profit': 'NDAL NË FITIM',
'stop_on_profit_tooltip': 'Përdorni Ndal në Fitim për të ndaluar bastet automatike pasi të keni fituar një shumë të caktuar.<br/> Për shembull, nëse filloni me 100$ dhe vendosni Ndal në Fitim në 25$',
'there_are_no_active_torunament': 'Nuk ka turne aktivë për momentin.',
'third': 'i treti',
'this_game_is': 'Loja është',
'this_tool_recalculates': 'Ky mjet rillogarit shumëzuesin nga hash-i i lojës. Ju mund të kopjoni/ngjisni çdo hash të lojës për të verifikuar shumëzuesin.',
'time': 'KOHA',
'time_frame': 'Koha',
'to': 'DERI',
'toggle_animation': 'Ndrysho animacionin',
'top_odds': 'Koeficientët më të lartë',
'top_wins': 'Fitimet më të larta',
'total_bets': 'Bastet totale',
'tournament': 'Turneu',
'tournament_information': 'Info rreth turneut',
'try_advanced_autoplay': 'Provoni funksionin e ri të avancuar të luajtjes automatike!',
'uncredited_wins': 'Fitore të pakredituara',
'user': 'PËRDORUESI',
'user_and_points': 'PËRDORUESI & PIKËT',
'username': 'Emri i përdoruesit',
'username_must_be_at_least': 'Emri i përdoruesit duhet të ketë të paktën',
'verify': 'Verifiko',
'vip_popup_text': 'Ju mund të zgjidhni një nga avatarët e veçantë VIP në dispozicion, ekskluzivisht për anëtarët tanë VIP!',
'vip_popup_title': 'Urime! Tani jeni një lojtar VIP',
'waiting_for_the_next_round': 'Në pritje të raundit të radhës...',
'warnings_are_calculated': ' ‎• Fitimet llogariten duke shumëzuar shumën e bastit me shumëzuesin në momentin e Arkëtimit',
'watch_take_off': 'Shihni se si Raketa juaj ngrihet, ndërsa shumëzuesi i fitimeve tuaja rritet.',
'watch_the_rocket': 'Shiko raketën',
'we_have_noticed': '‎“Kemi vënë re se është hera e parë që luani “Rocketman””. Dëshironi të shihni një video të shpejtë që shpjegon se si ta luani ?`',
'week': 'Javë',
'welcome_to_rocketman': 'Mirë se vini në Rocketman - një lojë e re dhe emocionuese! Me Rocketman ju keni mundësinë të fitoni deri në 20.000 herë se shuma e bastit tuaj fillestar.',
'what_is_provably_fair': 'Çfarë do të thotë Lojë e ndershme',
'will_be_deposited': 'Do të depozitohet në balancën e lojtarëve me fitoren e parë të rregullt',
'win': 'FITIMI',
'win_amount': 'Shuma e fituar',
'win_gifs': 'FITIMI',
'winner': 'fitues',
'wins': 'fitore',
'write_a_replay': 'Shkruaj një përgjigje...',
'year': 'Vit',
'yes': 'PO',
'yes_start_the_round': 'Po, fillo raundin',
'you_have_cashed_out': 'Ju keni arkëtuar, fitimi juaj është ',
'you_have_won': 'JU KENI FITUAR',
'your_bet_is_lost': 'basti juaj humbet.',
'current': 'Aktuale',
'previous': 'E mëparshme',
'claim': 'Kerkese',
'this_feature_will_give': 'Kjo veçori do t\'u japë shumën e zgjedhur përdoruesve të rastësishëm në chat',
'amount_per_player': 'Shuma për lojtar',
'no_of_players': 'Numri i lojtarëve',
'meteor_shower': 'Shi meteoresh',
'claim_your_free_bet': 'Kërkoni bastin tuaj falas',
'meteor_shower_total': 'Totali i shiut meteorik',
'shower': 'SHI',
'total': 'Total',
'meteor_shower_incoming': 'Po vjen Dushi i Meteorëve, shikoni në chat!',
'insurance': 'SIGURIM',
'insurance_paragraph': 'Lojtarët mund të blejnë sigurim për bastin e tyre duke klikuar në kutinë "Sigurimi i Basteve" pranë butonit të basteve. Sigurimi kushton 10% të shumës totale të bastit dhe do të kthejë shumën e bastit te lojtari nëse raketat shpërthen me një shumëzues 1.00.',
'insure_your_bet': 'SIGURONI BASTIN TUAJ',
'bet_insurance_promo_text': 'Zgjidhni opsionin "Sigurimi i basteve" për të siguruar bastin tuaj nga rrëzimi i raketës në shumëzuesin 1.00.',
'bet_insurance_promo_text_helper': 'Lexoni më shumë në seksionin "Rregullat e lojës"...',
'game_explanation_1': 'Zgjidhni shumëzuesin tuaj dhe nëse shumëzuesi i lojës tejkalon shumëzuesin tuaj të zgjedhur, fitorja juaj do të jetë',
'game_explanation_2': 'Shumëzuesi juaj x Basti juaj',
'retail_max_mp_1': 'Fito deri në',
'retail_max_mp_2': 'basti juaj!',
'play_multibets': 'Luaj',
'multibets': 'MULTIBETE',
'bonus_game': 'LOJË BONUS',
'all_wins_doubled': 'Të gjitha fitoret u dyfishuan!',
'multibets_paragraph_1': 'për të rritur shanset tuaja!',
'multibets_paragraph_2': 'Ju mund të kombinoni baste deri në dhjetë raunde në një multibet të vetëm dhe të fitoni pagesa masive!',
'multibets_paragraph_3': 'Sa herë që fitohet një raund, dividenti fitues nga ai raund vihet në raundin tjetër, derisa të fitojnë të gjitha raundet.',
'bonus_launch': 'Fito x2 në të gjitha bastet në raundin "Launch Bonus"!',
'multiplier_history': 'Historia e shumëzuesit',
'will_start_soon': 'do të fillojë së shpejti',
'hurry_up_and_place_your_bets': 'Nxitoni dhe vendosni bastet tuaja!',
'active_bets': 'Baste aktive',
'shop': 'DYQANI',
'game_meteor_shower_paragraph': 'Meteor Shower jep një bast falas me një vlerë të paracaktuar për një numër të paracaktuar lojtarësh. Mund të inicohet ose nga serveri ose nga një lojtar. Pasi të aktivizohet Meteor Shower, një mesazh del në lojë, duke i drejtuar përdoruesit të shikojnë bisedën ku ndodhet mesazhi i Meteor Shower. Lojtarët mund të kërkojnë bast falas duke klikuar në butonin "Kërkesë". Bastet falas u jepen lojtarëve që klikojnë së pari në butonin "Kërkesë". Përdorimi i klikuesve automatikë ose çdo lloj softueri automatizimi për të imituar klikimet e lojtarëve dhe për të kërkuar çmime është i ndaluar dhe mund të rezultojë në një ndalim nga kjo veçori.',
'claming_failed': 'Pretendimi dështoi',
'player_on_blacklist': 'Lojtari është në listën e zezë',
'you_can_claim_ticket': 'Ju mund të kërkoni biletë vetëm nëse jeni lojtar aktiv me baste të rregullta',
'you_already_have_free_tickets': 'Ju tashmë keni bileta falas, ju lutemi shpenzoni ato fillimisht',
'please_play_the_game_in_portait_mode': 'Ju lutemi luani lojën në modalitetin portret.',
};
langArr.sv = {
'your_insurance_returns_you': 'Din försäkring ger dig tillbaka',
'bet_insurance': 'Bet försäkring',
'max_bet_is': 'Max insats är',
'already_active_bet_of': 'och det finns redan aktiv satsning på',
'1_from_game_history': '‎1. Från spelhistoriken',
'2_in_settings_window': '‎2. I fönstret Inställningar',
'3_online_calculator': '‎2. Online kalkylator',
'active_tournament_1': 'Den aktiva turneringen ',
'active_tournament_2': 'har inga spel ännu.',
'add_another_bet': 'Lägg ett nytt bet',
'advanced_autoplay': 'AVANCERAT AUTOSPEL',
'advanced_autoplay_for_bet': 'Avancerat autospel för insats',
'alien_saucer_free_bets': 'Alien Saucer free bets',
'all_bets': 'Alla insatser',
'all_in_your_hands': 'Allt i dina händer!',
'allow': 'Tillåt',
'at': 'på',
'austronaut_promo_credit': 'Astronaut Kampanjkredit',
'auto_play_and_autocash': 'AUTOSPEL OCH AUTOCASHOUT ',
'autoplay': 'Autospel',
'autoplay_and_cashout': '‎02. Auto play och auto cashout',
'back_to_home': 'Tillbaka Hem',
'balance': 'Saldo',
'bet': 'SATSA',
'bet_amount': 'INSATSBELOPP',
'bet_and_cashout': '‎01. Bet & Cashout',
'bet_bets_tab': 'INSATS',
'bet_failed': 'Spel misslyckades',
'bet_statistic': 'Statistik för satsningar',
'bet_time': 'Satsningstid',
'bet_wins': 'Bettingvinster',
'bets': 'Bets',
'biggest_odds': 'Högsta odds',
'bonus_type': 'BONUSTYP',
'bonusing_history': 'Bonushistorik',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot-vinnare',
'but_remember_paragraph': 'Men kom ihåg',
'calculate_raw_mp_to_decimal': 'Beräkna råmultiplikator från decimal och avrunda ner till närmaste heltal',
'cancel': 'Avbryt',
'cant_post_media_content': 'Kan inte publicera medieinnehåll frekvent',
'cashed_out': 'Cashade ut',
'cashout': 'Cashout',
'cashout_at': 'CASHOUT VID',
'cashout_before': 'Begär cashout innan raketen exploderar och pengarna är dina!',
'change_avatar': 'Byt Avatar',
'change_username': 'Byt användarnamn',
'characters_long': 'tecken långt',
'chat': 'Chatta',
'chat_room': 'Chattrum',
'checking_for_spam': 'Kontrollerar för spam…',
'close': 'Stäng',
'coeff': 'KOEFF.',
'collect_win': 'Samla vinst',
'combined_seed': 'Kombinerad seed',
'company': 'Företag',
'congratulations_you_have_won_free_bets': 'Grattis! Du har vunnit Bonussatsningar! Du kan satsa',
'convert_first_13_to_decimal': ' Konverterar första 13 bytes till decimal',
'd': 'd',
'date': 'DATUM',
'date_and_time': 'Datum och tid',
'deny': 'Avvisa',
'detailed_game_rules': 'Detaljerade spelregler',
'dissconnection_policy': 'FRÅNKOPPLINGSPOLICY',
'easy_as_one_two_three': 'Lätt som en plätt',
'enter_username': 'Vänligen ange önskat användarnamn',
'error_handling': 'FELHANTERING',
'exit_full_screen': 'Avsluta fullskärmsläge',
'first': 'första',
'first_time_playing': 'Första gången du spelar "Rocketman"?',
'folow_the_three_easy_steps': 'Följ de tre enkla stegen och njut av Rocketman! ',
'free_bets': 'GRATISSPEL',
'free_bets_desc': 'Du vann gratisspel! Du kan dra nytta av gratisspel när som helst genom att trycka på startknappen.',
'free_bets_header': 'Gratisspel',
'from': 'FRÅN',
'fullscreen_mode': 'För bättre användarupplevelse kommer spelet att starta i fullskärmsläge.',
'function': 'Funktion',
'game_functions': 'Spelfunktioner',
'game_history': 'SPELHISTORIK',
'game_r_1_paragraph': 'En spelare kan aktivera autospelalternativet där en insats automatiskt placeras för varje ny omgång som använder insatsbeloppet från det föregående spelet.',
'game_r_10_paragraph': 'När kampanjkrediterna tilldelats för alla insatser som en spelare placerar, kommer pengarna först att dras från kampanjkrediter tills de har använts upp innan de fortsätter att ta pengar från spelarens plånbok.',
'game_r_11_paragraph': 'Kampanjkrediter kan inte tas ut, men alla vinster på sådana spel krediteras till fullo till spelaren.',
'game_r_12_paragraph': 'Kampanjkrediter har en 30 dagars giltighetsperiod varpå de går ut och inte kan användas.',
'game_r_13_paragraph': 'En spelare kan slumpmässigt tilldelas gratisspel. En popup-grafik kommer att visas på skärmen och meddela spelaren om tilldelade gratisspel, antalet spel och belopp per insats.',
'game_r_14_paragraph': 'När gratisspelen tilldelas kan en spelare välja när den vill starta gratisspelomgången. När gratisspelomgången är startad, placeras ett automatiskt spel med den specificerade insatsen, och det är upp till spelaren att ta ut pengar när som helst. Om spelar',
'game_r_15_paragraph': 'Gratisspel har en 30 dagars giltighetsperiod varpå de går ut och inte kan användas.',
'game_r_16a_paragraph': 'Den övergripande teoretiska återgången till spelaren är ',
'game_r_17_paragraph': 'RTP-procentenheter övervakas kontinuerligt och verifieras av oberoende tredjeparter.',
'game_r_18_paragraph': 'Om anslutningen förloras efter att en insats är placerad kommer spelet inte att avbrytas. Om multiplikatorn når 20 000x din insats kommer ditt spel automatiskt att betalas ut och krediter kommer att tillämpas till ditt konto.',
'game_r_19_paragraph': 'Du kan chatta med andra spelare i spelet. Skriv ditt meddelande i chattfältet eller dela dina vinnande spel med andra spelare.',
'game_r_2_paragraph': 'En autocashout-multiplikator kan anges i fältet ”Autocashout”, och när den anges kommer spelet automatiskt att ta ut pengar om den inställda multiplikatorn nås.',
'game_r_20_paragraph': 'Spelare som missbrukar chatten genom att smäda andra spelare, eller genom olämplig och/eller felaktigt språkbruk kommer att få sina chattprivilegier avstängda.',
'game_r_21_paragraph': 'Rocketman är designat med vacker bakgrundsmusik och ljudeffekter i åtanke. Skulle du vilja inaktivera musik och/eller ljudeffekter kan du göra det i rullgardinsmenyn.',
'game_r_22_paragraph': 'Genom att klicka på knappen ”Mina spel” kan en spelare granska sina senaste spel. För ytterligare spelhistorik (t.ex. längre perioder), kan en spelare behöva åtkomst till spelarhistoriken inom själva spelplattformen.',
'game_r_23_paragraph': 'I händelse av ett fel kanske omgången inte startar i tid. Om så är fallet kommer vårt support-team att försöka lösa problemet så snart som möjligt och återuppta vanliga spelomgångar. I händelse av ett problem med omgången, kommer alla spel att avbrytas oc',
'game_r_24_paragraph': 'Nätverkslatens är en oundviklig del av internetuppkoppling. Vi anser att alla satsningar och cashouts är giltiga vid den tidpunkt de når vår server, oavsett nätverkets latensvaraktighet. ',
'game_r_3_paragraph': 'En spelare kan använda kortkommandon för att snabbt placera ett spel eller ta ut pengar i spelet. De viktigaste kartläggningarna är följande ',
'game_r_4_paragraph': '1% av varje insats går till en progressiv jackpottfond. Denna fond tjänar det progressiva jackpott-systemet',
'game_r_5_paragraph': ' ‎• Jackpot – Högsta nivå, största pris, minst frekvent',
'game_r_6_paragraph': ' ‎• Rocketpot – Medium nivå, medium pris',
'game_r_7_paragraph': ' ‎• Boosterpot – Lägst nivå, lågt pris, mest frekvent ',
'game_r_8_paragraph': 'Alla jackpot-nivåer tilldelas med hjälp av en ”Mystery” eller ”must-hit-by”-mekanik. Värdet på varje ”must-hit-by”-jackpot avgörs av en, direkt efter att föregående jackpot har vunnits, slumpmässig nummergenerator och lagras som ett krypterat värde i fjär',
'game_r_8a_paragraph': 'Notera: Jackpottvinnare kan endast vara en enda Rocketman-spelare som aktivt spelar i omgången när jackpotten har utlösts. Det finns ingen möjlighet att om spelaren har vunnit jackpotten, skulle behöva dela den med någon annan Rocketman-spelare.',
'game_r_9_paragraph': 'En spelare kan slumpmässigt tilldelas kampanjkrediter. En popup-grafik kommer visas på skärmen och meddela spelaren om tilldelade kampanjkrediter och deras belopp.',
'game_round': 'spelomgång!',
'game_rules': 'Spelregler',
'game_rules_1_paragraph': 'Rocketman är spelunderhållning för en ny generation. Vinn flera gånger mer på några sekunder! Rocketman är byggt på ett',
'game_rules_10_paragraph': ' ‎• Auto Play aktiveras genom att kryssa i rutan för ”Auto” i betpanelen. När den är aktiverad läggs satsningarna automatiskt. Men för cashout ska du trycka på knappen ”cashout” i varje spelomgång. Vill du ha automatisk cashout, använd alternativet ”auto ',
'game_rules_11_paragraph': '‎• Auto Cashout är tillgänglig i fliken ”Auto” på betpanelen. När den är aktiverad får du automatisk cashout på din satsning när den når angiven koefficient.',
'game_rules_2_paragraph': 'som just nu är den enda sanna garantin för ärlighet i spelbranschen.',
'game_rules_3_paragraph': '‎• Vinstmultiplikatorn startar på 1x och växer mer och mer när raketen lyfter.',
'game_rules_4_paragraph': ' ‎• Dina vinster är den multiplikator där du begärde cashout, multiplicerad med din insats. ',
'game_rules_5_paragraph': '‎• Före varje spelomgång genererar vår bevisligen rättvisa slumpgenerator den koefficient till vilket vårt Lucky Plane flyger. Du kan kolla ärligheten i detta genom att klicka på det motsatta resultatet i fliken Historik.',
'game_rules_6_paragraph': '‎• Välj summa och tryck på ”Satsa” för att lägga ett bet. ',
'game_rules_7_paragraph': '‎• Du kan lägga två bets samtidigt genom att lägga till en andra betpanel. För att göra det, tryck på plusikonen som finns högst upp i högra hörnet av den första betpanelen.',
'game_rules_8_paragraph': ' ‎• Tryck på ”Cashout”-knappen för att kvittera ut sina vinster. Din vinst multipliceras med cashout-koeffficienten.',
'game_rules_9_paragraph': '‎• Du förlorar ditt bet om du inte begär cashout innan planet flyger iväg.',
'general_rules': 'ALLMÄNNA REGLER',
'gifs': 'GIFS',
'go_back': 'Gå tillbaka',
'good_job_buddy': 'Bra jobbat kompis, fortsätt så',
'h': 't',
'hashed_in_sha_512': 'Hashad i SHA512',
'hex': 'Hex',
'hey_username': 'Hej',
'hide_hex_to_decimal': 'Dölj hex till decimalkonvertering',
'highscore': 'Rekord',
'how_it_works': 'Hur det fungerar?',
'how_to_check': 'Hur kontrollerar man?',
'how_to_play': 'Så spelar du',
'if_the_maximum_multiplier': '‎• Om den maximala multiplikatorn på 20 000 har uppnåtts kommer alla öppna spel automatiskt att betalas ut vid den maximala multiplikatorn',
'increase': 'Öka',
'input_bet_amount': 'Ange insatsens storlek och gör din insats innan avfärd.',
'insufficient_funds': 'Otillräckliga tillgångar',
'it_blew_up': 'Den exploderade! Nåväl…',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpot-vinnare',
'key': 'Tangent',
'load_more': 'LADDA FLER',
'loading': 'Laddar',
'loading_next_round': 'Laddar nästa omgång…',
'loss_gifs': 'FÖRLUST',
'lost_connection': 'Förlorad anslutning',
'make_a_bet': 'Gör din insats',
'malfunction_voids': 'Tekniska fel ogiltigförklarar alla betalningar och spel.',
'maximum_payout': 'Maximal utbetalning är ',
'menu': 'Meny',
'min_bet_is': 'Minsta insats är',
'month': 'Månad',
'more_details': 'Fler detaljer',
'more_information': 'Mer information',
'multiplier': 'Multiplikator',
'multiplier_starts': '‎• Multiplikatorn startar på 1 och går upp till 20 000',
'multipliers_larger': 'Multiplikatorer större än x20 000 är begränsade till maximalt multiplikatorvärde',
'music_settings': 'Musikinställningar',
'my_bet_history': 'Min satsningshistorik',
'my_bets': 'Mina insatser',
'my_bonysing_history': 'Min bonushistorik',
'network_latency': 'NÄTVERKSLATENS',
'network_latency_content': 'Nätverkslatens är en oundviklig del av internetuppkoppling. Vi anser att alla satsningar och cashouts är giltiga vid den tidpunkt de når vår server, oavsett nätverkets latensvaraktighet.',
'network_latency_title': 'NÄTVERKSLATENS',
'no_thanks': 'Nej tack!',
'not_right_now': 'Inte just nu',
'off': 'av',
'oh_snap': 'Oj då!',
'ok_close': 'Okej, nära!',
'ok_thanks': 'Okej, tack!',
'on': 'på',
'on_loss': 'PÅ FÖRLUST',
'on_win': 'PÅ VINST',
'online': 'Online',
'per_bet': 'per spel',
'pick_your_avatar_img': 'Välj en bild till din avatar',
'place_bet_with_last': 'Placera ett spel med senast valda insats ',
'play_now': 'Spela NU',
'player_1_seed': 'Spelare 1 seed',
'player_2_seed': 'Spelare 2 seed',
'player_3_seed': 'Spelare 3 seed',
'please_wait_active_bets': 'Vänligen vänta på att aktuella spel slutförs för att aktivera gratisspel',
'points': 'POÄNG',
'powered_by': 'drivs av',
'previous_hand': 'Föregående hand',
'prize': 'PRIS',
'prizes': 'priser',
'progressive_jackpot': 'PROGRESSIV JACKPOT',
'promo_credit': 'Kampanjkredit',
'promo_credit_condradulations': 'Grattis! Du har vunnit en astronomiskt bra Kampanjkredit som du kan använda i spelet!',
'promo_credits': 'KAMPANJKREDITER',
'promo_history': 'Kampanjhistorik',
'provably_fair': 'Bevisligen rättvist',
'provably_fair_1_paragraph': 'Rocketman\' baseras på en kryptografisk teknik som kallas ”Provably Fair”. Tekniken garanterar 100% opartiskt spelresultat. Med denna reknik är det omöjligt för tredje part att störa spelprocessen.',
'provably_fair_2_paragraph': 'Resultatet av varje spelomgång (Spelets ”Fly away”-koefficient) genereras inte på våra servrar. De genereras med hjälp av ”round players” och är helt transparenta. På så vis är det omöjligt för någon att manipulera resultatet. Dessutom kan vem som helst k',
'provably_fair_3_paragraph': 'Resultatet i spelomgången genereras av fyra beroende deltagare i spelomgången.',
'provably_fair_4_paragraph': 'När spelomgången startar sammanfogar spelet serverseeden med tre klientseeds. Från de sammanslagna symbolerna genereras en SHA512 hash, och från denna hash – spelresultat.',
'provably_fair_5_paragraph': 'Du kan kolla opartiskheten för varje spelomgång i spelhistoriken genom att klicka på den gröna ikonen. I det öppnade fönstret ser du serverseed, 3 par klientseeds, kombinerad hash och spelomgångens resultat.',
'provably_fair_6_paragraph': 'En hashad version av nästa spelomgångs server seed är tillgängligt offentligt i fönstret för inställningar (Se ”Provavly Fair Settings” i menyn, och sedan ”Next server seed SHA 256”). Här kan du också ändra din klientseed. Om du vill delta i genereringen ',
'provably_fair_7_paragraph': 'Korrektheten hos hashen kan kontrolleras i en online kalkylator, till exempel',
'provably_fair_system': 'provably fair system',
'quick_explanation': 'Snabb förklaring',
'randomly_generated_server_hash': 'Slumpmässigt genererad server-HASH',
'rank': 'RANKING',
'raw_multiplier': 'råmultiplikator',
'raw_multiplier_to_edged': 'Råmultiplikator till kantmultiplikator med användning av',
'remove_additional_bet': 'Ta bort extra bet',
'reset': 'Återställ',
'return_to_player': 'AVKASTNING TILL SPELARE',
'rocketman_is_pure_excitement': 'Rocketman är ren spänning! Ta riken och vinn.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot-vinnare',
'round': 'Omgång',
'round_id': 'Spelomgångens id',
'round_multiplier': 'Omgångsmultiplikator',
'round_results': 'Spelomgångens resultat',
'rounds': 'omgångar',
'rtp': 'RTP',
'rules': 'regler',
'rules_details': 'Turneringsregler',
'save': 'Spara',
'schedule': 'schema',
'second': 'andra',
'seconds_left': 's kvar',
'server_hash': 'Server-HASH',
'please_wait_current_bets': 'VÄNLIGEN VÄNTA PÅ PÅ AKTUELLA SATSER FÖR ATT SLUTFÖRAS',
'min_autocashout_value_must_be': 'MIN AUTOCASHOUT VÄRDE MÅSTE VARA',
'min_bet_value_must_be': 'MIN INSATSVÄRDE MÅSTE VARA',
'server_seed': 'Server seed',
'session_dissconnected': 'Session frånkopplad',
'multisession_not_allowed': 'Multisession är inte tillåten',
'set_auto_bet': 'STÄLL IN AUTO-BET',
'set_auto_cashout': 'STÄLL IN AUTO-CASHOUT',
'shourtcut_keys': 'TANGENTER FÖR KORTKOMMANDON',
'show_hex_to_deimal': 'Visa hex till decimalkonvertering',
'sound': 'LJUD',
'sound_settigs': 'Ljudinställningar',
'start_with_autoplay': 'Starta med autospel',
'stop_autoplay': 'STOPPA AUTOSPEL',
'stop_on_loss': 'STOPPA PÅ FÖRLUST',
'stop_on_loss_tooltip': 'Använd Stoppa på förlust för att stoppa autospel efter att ett specifikt belopp har förlorats.<br/> Till exempel, om du börjar med 100 $ och du ställer in Stoppa på förlust till 25 $',
'stop_on_profit': 'STOPPA PÅ VINST',
'stop_on_profit_tooltip': 'Använd Stoppa på vinst för att stoppa autospel efter att ett specifikt belopp har vunnits.<br/> Till exempel, om du börjar med 100 $ och du ställer in Stoppa på vinst till 25 $',
'there_are_no_active_torunament': 'Det finns inga aktiva turneringar just nu.',
'third': 'tredje',
'this_game_is': 'Det här spelet',
'this_tool_recalculates': 'Detta verktyg räknar om multiplikatorn från spelets hash. Du kan kopiera/klistra in eventuellt spel-hash för att verifiera multiplikatorn.',
'time': 'TID',
'time_frame': 'Tidsram',
'to': 'TILL',
'toggle_animation': 'Växla animation mellan på och av',
'top_odds': 'Bästa odds',
'top_wins': 'Högsta vinster',
'total_bets': 'Totalt insatser',
'tournament': 'Turnering',
'tournament_information': 'Turneringsinformation',
'try_advanced_autoplay': 'Prova den nya avancerade autoplay-funktionen!',
'uncredited_wins': 'Icke-krediterade vinster',
'user': 'ANVÄNDARE',
'user_and_points': 'ANVÄNDARE & POÄNG',
'username': 'Användarnamn',
'username_must_be_at_least': 'Användarnamn måste vara minst',
'verify': 'Verifiera',
'vip_popup_text': 'Du kan välja en av de speciella VIP-avatarerna som finns exklusivt tillgängliga för våra VIP-medlemmar!',
'vip_popup_title': 'Grattis till att du blivit en VIP-spelare',
'waiting_for_the_next_round': 'Väntar på nästa runda...',
'warnings_are_calculated': ' ‎• Vinster beräknas genom att multiplicera spelinsatsen med multiplikatorn vid utbetalningshändelsen',
'watch_take_off': 'Se hur raketen lyfter och dina vinster ökar.',
'watch_the_rocket': 'Följ raketen',
'we_have_noticed': '‎`Vi har noterat att det är första gången du spelar "Rocketman". Skulle du vilja sen en snabb video som förklarar hur man spelar spelet?`',
'week': 'Vecka',
'welcome_to_rocketman': 'Välkommen till Rocketman – ett nytt och spännande spel! Med Rocketman har du chans att vinna upp till 20 000x din ursprungliga insats.',
'what_is_provably_fair': 'Vad är ”Provably Fair”?',
'will_be_deposited': 'Kommer att sättas in på spelares saldo med första vanliga vinst',
'win': 'VINST',
'win_amount': 'Vinstsumma',
'win_gifs': 'VINST',
'winner': 'Vinnare',
'wins': 'Vinner',
'write_a_replay': 'Skriv ett svar…',
'year': 'År',
'yes': 'JA',
'yes_start_the_round': 'Ja, starta spelomgången',
'you_have_cashed_out': 'Du har begärt cashout. Din vinst är',
'you_have_won': 'DU HAR VUNNIT',
'your_bet_is_lost': 'Har du förlorat ditt bet.',
'current': 'Nuvarande',
'previous': 'Tidigare',
'claim': 'Krav',
'this_feature_will_give': 'Den här funktionen kommer att ge vald mängd till slumpmässiga användare i chatten',
'amount_per_player': 'Belopp per spelare',
'no_of_players': 'Antal spelare',
'meteor_shower': 'Meteorregn',
'claim_your_free_bet': 'Lunasta ilmainen vetosi',
'meteor_shower_total': 'Meteorisadetta yhteensä',
'shower': 'SADE',
'total': 'Kaikki yhteensä',
'meteor_shower_incoming': 'Meteorisadetta tulossa, katso chatti!',
'insurance': 'FÖRSÄKRING',
'insurance_paragraph': 'Spelare kan köpa försäkring för sin satsning genom att klicka på rutan "Försäkring för insats" bredvid insatsknappen. Försäkringen kostar 10% av den totala satsningsbeloppet och kommer att betala tillbaka satsningsbeloppet till spelaren om raketen exploderar med en multiplikator på 1,00.',
'insure_your_bet': 'VAKUUTTA VETOSI',
'bet_insurance_promo_text': 'Valitse "Bet Insurance" -vaihtoehto varmistaaksesi vetosi raketin törmäyksestä kertoimella 1,00.',
'bet_insurance_promo_text_helper': 'Lue lisää "Pelisäännöt" -osiosta...',
'game_explanation_1': 'Valitse kertoimesi, ja jos pelin kerroin ylittää valitsemasi kertoimen, voittosi on',
'game_explanation_2': 'Kerroin x panoksesi',
'retail_max_mp_1': 'Voita jopa',
'retail_max_mp_2': 'vetosi!',
'play_multibets': 'Spela',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPEL',
'all_wins_doubled': 'Alla vinster fördubblades!',
'multibets_paragraph_1': 'för att öka dina odds!',
'multibets_paragraph_2': 'Du kan kombinera satsningar på upp till tio omgångar till en enda multibet och vinna enorma utbetalningar!',
'multibets_paragraph_3': 'Varje gång en omgång vinner, satsas vinnande utdelning från den omgången i nästa omgång, tills alla omgångar vinner.',
'bonus_launch': 'Voita x2 kaikista "Bonus Launch" -kierroksen panoksista!',
'multiplier_history': 'Kerroinhistoria',
'will_start_soon': 'alkaa pian',
'hurry_up_and_place_your_bets': 'Pidä kiirettä ja aseta vetosi!',
'active_bets': 'Aktiiviset vedot',
'shop': 'MYYMÄLÄ',
'game_meteor_shower_paragraph': 'Meteor Shower palkitsee yhden ennalta määrätyn arvon ilmaisen vedon ennalta määritetylle määrälle pelaajia. Sen voi käynnistää joko palvelin tai pelaaja. Kun Meteor Shower on aktivoitu, peliin ilmestyy viesti, joka ohjaa käyttäjiä katsomaan chattia, jossa Meteor Shower -viesti sijaitsee. Pelaajat voivat lunastaa ilmaisen vedon napsauttamalla Lunasta-painiketta. Ilmaiset vedot myönnetään pelaajille, jotka napsauttavat ensin "Claim"-painiketta. Autoclickereiden tai minkä tahansa automaatioohjelmiston käyttäminen pelaajien napsautusten jäljittelemiseen ja palkintojen lunastamiseen on kiellettyä ja voi johtaa tämän ominaisuuden käyttökieltoon.',
'claming_failed': 'Vaatimus epäonnistui',
'player_on_blacklist': 'Pelaaja on mustalla listalla',
'you_can_claim_ticket': 'Voit lunastaa lipun vain, jos olet aktiivinen pelaaja tavallisilla vedoilla',
'you_already_have_free_tickets': 'Sinulla on jo ilmaisia ​​lippuja, käytä ne ensin',
'please_play_the_game_in_portait_mode': 'Vänligen spela spelet i porträttläge.',
};
langArr.tr = {
'your_insurance_returns_you': 'Sigortanız sizi geri getirir',
'bet_insurance': 'Bahis Sigortası',
'max_bet_is': 'Maksimum bahis:',
'already_active_bet_of': 've halihazırda aktif bir bahis var',
'1_from_game_history': '‎1. Oyun geçmişinden',
'2_in_settings_window': '‎2. Ayarlar penceresinden',
'3_online_calculator': '‎3. Online hesap makinesi',
'active_tournament_1': 'Aktif turnuva ',
'active_tournament_2': 'henüz bahis içermiyor.',
'add_another_bet': 'Başka bahis ekle',
'advanced_autoplay': 'GELİŞMİŞ OTOMATİK OYNAMA',
'advanced_autoplay_for_bet': 'Bahis İçin Gelişmiş Otomatik Oynama',
'alien_saucer_free_bets': 'Alien Saucer Ücretsiz Bahisleri',
'all_bets': 'Tüm Bahisler',
'all_in_your_hands': 'Hepsi sizin elinizde!',
'allow': 'İzin ver',
'at': '',
'austronaut_promo_credit': 'Astronot Promosyon Kredisi',
'auto_play_and_autocash': 'OTOMATİK OYNAMA VE OTOMATİK PARA ÇEKME ',
'autoplay': 'Otomatik oyna',
'autoplay_and_cashout': '‎02. Otomatik Bahis ve otomatik para çekme',
'back_to_home': 'Ana sayfaya geri dön',
'balance': 'Bakiye',
'bet': 'BAHİS YAP',
'bet_amount': 'BAHİS MİKTARI',
'bet_and_cashout': '‎01. Bahis ve Para Çekme',
'bet_bets_tab': 'BAHİS TUTARI',
'bet_failed': 'Bahis Başarısız',
'bet_statistic': 'Bahis İstatistikleri',
'bet_time': 'Bahis Zamanı',
'bet_wins': 'Bahis Kazançları',
'bets': 'Bahisler',
'biggest_odds': 'En Büyük Oranlar',
'bonus_type': 'BONUS TÜRÜ',
'bonusing_history': 'Bonus Geçmişi',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot kazananları',
'but_remember_paragraph': 'Ancak, Şanslı Roket patlamadan önce Para Çekmek için zamanınız olmadıysa,',
'calculate_raw_mp_to_decimal': 'Ham Çarpanı ondalıktan hesaplayın ve en yakın tamsayı',
'cancel': 'İptal Et',
'cant_post_media_content': 'Sık medya içeriği yayınlanamaz',
'cashed_out': 'Çekilen tutar',
'cashout': 'Para Çek',
'cashout_at': 'ÖDEME AL',
'cashout_before': 'Roket patlamadan önce paranızı çekin ve para sizin olsun!',
'change_avatar': 'Avatar Değiştir',
'change_username': 'Kullanıcı adını değiştir',
'characters_long': 'karakter uzunluğunda olmalıdır',
'chat': 'Sohbet',
'chat_room': 'Sohbet Odası',
'checking_for_spam': 'Spam kontrol ediliyor...',
'close': 'Kapat',
'coeff': 'KATSAYI',
'collect_win': 'Kazancı Al',
'combined_seed': 'Birleştirilmiş seed',
'company': 'Şirket',
'congratulations_you_have_won_free_bets': 'Tebrikler! Bonus Bahisler kazandınız! Bahis yapabilirsiniz',
'convert_first_13_to_decimal': ' İlk 13 baytı ondalık sayıya dönüştür',
'd': 'g',
'date': 'TARİH',
'date_and_time': 'Tarih ve saat',
'deny': 'Reddet',
'detailed_game_rules': 'Detaylı Oyun Kuralları',
'dissconnection_policy': 'BAĞLANTI KESİLMESİ POLİTİKASI',
'easy_as_one_two_three': 'Çok basit',
'enter_username': 'Lütfen istediğiniz kullanıcı adını girin',
'error_handling': 'HATA YÖNETİMİ',
'exit_full_screen': 'Tam ekran modundan çık',
'first': 'birinci',
'first_time_playing': '‎"Rocketman" oyununu ilk kez mi oynuyorsunuz?',
'folow_the_three_easy_steps': 'Üç kolay adımı takip edin ve Rocketman oyununun tadını çıkarın! ',
'free_bets': 'ÜCRETSİZ BAHİSLER',
'free_bets_desc': 'Bedava bahisler kazandınız! Başlat düğmesine basarak istediğiniz zaman ücretsiz bahislerden yararlanabilirsiniz.',
'free_bets_header': 'Ücretsiz Bahisler',
'from': 'Başlangıç',
'fullscreen_mode': 'Daha iyi bir kullanıcı deneyimi sağlamak için oyun tam ekran modunda başlayacaktır.',
'function': 'İşlev',
'game_functions': 'Oyun Fonksiyonları',
'game_history': 'OYUN GEÇMİŞİ',
'game_r_1_paragraph': 'Bir oyuncu, her yeni raunt için otomatik olarak önceki bahsin bahis miktarı kullanılarak bir bahsin yapıldığı otomatik oynama seçeneğini etkinleştirebilir.',
'game_r_10_paragraph': 'Bir oyuncunun yaptığı herhangi bir bahis için ödemesi gereken para, promosyon kredileri verildikten sonra bu kredi tükenene kadar oyuncunun kendi hesap bakiyesinden değil promosyon kredilerinden düşülür.',
'game_r_11_paragraph': 'Promosyon kredileri paraya çevrilemez, ancak bu tür bahislerdeki kazançların tamamı oyuncunun bakiyesine eklenir.',
'game_r_12_paragraph': 'Promosyon kredilerinin geçerlilik süresi 30 gündür ve bu süre dolduktan sonra kullanılamazlar.',
'game_r_13_paragraph': 'Bir oyuncuya rastgele bir şekilde ücretsiz bahisler verilebilir. Ekranda oyuncuya ücretsiz bahis verildiğini bildiren, bahis sayısını ve bahis başına bahis miktarı içeren bir açılır grafik belirir.',
'game_r_14_paragraph': 'Ücretsiz bahisler verildiğinde oyuncu, ücretsiz bahis turuna ne zaman başlayacağını kendisi seçebilir. Ücretsiz bahis turu başladığında belirtilen bahis miktarıyla otomatik bir bahis yapılır ve herhangi bir anda para çekme işlemi oyuncunun takdirine bağlı',
'game_r_15_paragraph': 'Ücretsiz bahislerin geçerlilik süresi 30 gündür ve bu süre dolduktan sonra kullanılamazlar.',
'game_r_16a_paragraph': 'Oyuncuya genel teorik geri dönüş oranı ',
'game_r_17_paragraph': 'RTP yüzdeleri, bağımsız üçüncü taraflarca sürekli olarak izlenmekte ve doğrulanmaktadır.',
'game_r_18_paragraph': 'Bir bahis yapıldıktan sonra bağlantı kesilirse bahis iptal edilmez. Çarpan 20.000x kata ulaşırsa, bahsiniz otomatik olarak paraya çevrilir ve hesabınıza yatırılır.',
'game_r_19_paragraph': 'Oyunda diğer oyuncularla sohbet edebilirsiniz. Mesajınızı sohbet alanına yazın veya kazanan bahsinizi diğer oyuncularla paylaşın.',
'game_r_2_paragraph': '‎“Otomatik Para Çekme” alanına bir Otomatik Para Çekme çarpanı girilebilir. Girilen çarpana ulaşıldığında oyun otomatik olarak para çekecektir.',
'game_r_20_paragraph': 'Sohbeti diğer oyuncuları suistimal ederek veya uygunsuz ve/veya kötü bir dil kullanarak kötüye kullanan oyuncuların Sohbet ayrıcalıkları devre dışı bırakılır.',
'game_r_21_paragraph': 'Rocketman, güzel fon müziği ve ses efektleri düşünülerek tasarlanmıştır. Müziği ve/veya ses efektlerini devre dışı bırakmak isterseniz bunu açılır menüden yapabilirsiniz.',
'game_r_22_paragraph': 'Oyuncular "Bahislerim" butonuna tıklayarak en son bahislerini gözden geçirebilirler. Daha fazla oyun geçmişi için (örneğin daha geniş bir dönem), bir oyuncunun oyun platformunun kendi içindeki oyuncu geçmişine erişmesi gerekebilir.',
'game_r_23_paragraph': 'Hata oluşması durumunda rauntlar zamanında başlamayabilir. Bu durumda destek ekibimiz sorunu en kısa sürede çözmeye çalışacak ve normal oyun rauntlarına devam edilecektir. Oyun raundunun kendisinde bir sorun olması durumunda tüm bahisler iptal edilir ve b',
'game_r_24_paragraph': 'Ağ gecikmesi, internet bağlantısının kaçınılmaz bir parçasıdır. Ağ gecikme süresi ne olursa olsun tüm bahisleri ve para çekme işlemlerini yalnızca sunucumuza ulaştıkları anda geçerli olarak kabul ederiz. ',
'game_r_3_paragraph': 'Bir oyuncu, oyunda hızlı bir şekilde bahis yapmak veya para çekmek için kısayol tuşlarını kullanabilir. Tuş eşlemeleri aşağıdaki gibidir',
'game_r_4_paragraph': 'av varje insats går till en progressiv jackpottfond. Denna fond tjänar det progressiva jackpott-systemet',
'game_r_5_paragraph': ' ‎• Jackpot – En yüksek seviye, en büyük ödül, en az sıklıkta',
'game_r_6_paragraph': ' ‎• Rocketpot – Orta seviye, orta seviye ödül',
'game_r_7_paragraph': ' ‎• Boosterpot – En düşük seviye, düşük ödül, en sık ',
'game_r_8_paragraph': 'Tüm jackpot seviyeleri, bir "Gizem" veya "öncesinde almalı" mekaniği kullanılarak verilir. Her "öncesinde almalı" jackpot değeri, bir önceki jackpot kazanıldıktan hemen sonra bir rastgele sayı üreteci tarafından belirlenir ve uzak bir veri tabanında şifre',
'game_r_8a_paragraph': 'Not: İkramiyeyi kazanan, ikramiye tetiklendiğinde turda aktif olarak oynayan tek bir Rocketman oyuncusu olabilir. Oyuncunun ikramiyeyi kazanması durumunda bunu başka bir Rocketman oyuncusuyla paylaşmak zorunda kalması ihtimali yoktur.',
'game_r_9_paragraph': 'Bir oyuncuya rastgele bir şekilde promosyon kredisi verilebilir. Ekranda oyuncuya verilen promosyon kredilerini ve miktarını bildiren bir açılır grafik belirir.',
'game_round': 'oyun raundu!',
'game_rules': 'Oyun Kuralları',
'game_rules_1_paragraph': 'Rocketman yeni neslin bahis oyun eğlencesidir. Saniyeler içinde birçok kat kazanın! Rocketman,',
'game_rules_10_paragraph': ' ‎• Otomatik Bahis, Bahis Panelindeki "Otomatik" sekmesinden "Otomatik Bahis” onay kutusu işaretlenerek etkinleştirilir. Etkinleştirildikten sonra bahisler otomatik olarak yapılır. Fakat para çekmek için her rauntta "Para Çek” butonuna tıklamanız gerekir.',
'game_rules_11_paragraph': '‎• Otomatik Para Çekme, bahis panelinde yer alan "Otomatik" sekmesinde mevcuttur. Etkinleştirildikten sonra, girilen katsayıya ulaştığında, bahsiniz otomatik olarak çekilir.',
'game_rules_2_paragraph': 'üzerine inşa edilmiştir ve bu sistem şu anda bahis sektöründe adilliğin tek gerçek garantisidir.',
'game_rules_3_paragraph': '‎• Kazanç çarpanı 1x kat olarak başlar ve Roket uçuşa geçtiği andan itibaren giderek büyür.',
'game_rules_4_paragraph': ' ‎• Kazançlarınız, bahis tutarınızın para çekme anındaki çarpan ile çarpımına eşittir. ',
'game_rules_5_paragraph': '‎• Her raundun başlamasından önce, kanıtlanabilir adilliğe sahip rastgele sayı üretecimiz, roketin patlayacağı katsayıyı üretir. Bu neslin adilliğini, Geçmiş sekmesinde sonucun karşısına tıklayarak kontrol edebilirsiniz.',
'game_rules_6_paragraph': '‎• Bahis yapmak için tutarı seçin ve "Bahis Yap" butonuna tıklayın. ',
'game_rules_7_paragraph': '‎• İkinci bahis panelini ekleyerek aynı anda iki bahis yapabilirsiniz. İkinci bahis panelini eklemek için ilk bahis panelinin sağ üst köşesinde bulunan artıya tıklayın.',
'game_rules_8_paragraph': ' ‎• Kazançlarınızı çekmek için "Para Çek" butonuna tıklayın. Kazancınız, bahis tutarınızın para çekme anındaki çarpan ile çarpımına eşittir.',
'game_rules_9_paragraph': '‎• Roket patlamadan önce paranızı çekmezseniz, bahsiniz kaybeder.',
'general_rules': 'GENEL KURALLAR',
'gifs': 'GIF’LER',
'go_back': 'Geri Git',
'good_job_buddy': 'Tebrikler dostum, aynen devam',
'h': 's',
'hashed_in_sha_512': 'SHA512 Hash',
'hex': 'Hex',
'hey_username': 'Merhaba',
'hide_hex_to_decimal': 'Hex’ten ondalığa dönüştürmeyi gizle',
'highscore': 'Yüksek skor',
'how_it_works': 'Nasıl çalışır?',
'how_to_check': 'Nasıl kontrol edilir?',
'how_to_play': 'Nasıl oynanır',
'if_the_maximum_multiplier': '‎• Maksimum çarpan değeri olan 20.000’e ulaşılırsa tüm açık bahisler otomatik olarak maksimum çarpan üzerinden ödenir',
'increase': 'Arttır',
'input_bet_amount': 'Bahis tutarını girin ve kalkıştan önce bahsinizi yapın.',
'insufficient_funds': 'Yetersiz bakiye',
'it_blew_up': 'Patladı! Neyse...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpot kazananları',
'key': 'Tuş',
'load_more': 'DAHA FAZLA YÜKLE',
'loading': 'Yükleniyor',
'loading_next_round': 'Bir sonraki raunt yükleniyor…',
'loss_gifs': 'KAYIP',
'lost_connection': 'Bağlantı kaybedildi',
'make_a_bet': 'Bahis Yap',
'malfunction_voids': 'Arıza, tüm Ödemeleri ve Oyunları geçersiz kılar.',
'maximum_payout': 'Maksimum ödeme: ',
'menu': 'Menü',
'min_bet_is': 'Mi̇n bahi̇s',
'month': 'Ay',
'more_details': 'Daha Fazla Bilgi',
'more_information': 'Daha fazla bilgi',
'multiplier': 'Çarpan',
'multiplier_starts': '‎• Çarpan 1\'den başlar ve 20.000’e kadar çıkar',
'multipliers_larger': '‎20.000x kattan büyük çarpanlar, maksimum çarpan değerinde sınırlandırılır',
'music_settings': 'Müzik Ayarları',
'my_bet_history': 'Bahis Geçmişim',
'my_bets': 'Bahislerim',
'my_bonysing_history': 'Bonus Geçmişim',
'network_latency': 'AĞ GECİKMESİ',
'network_latency_content': 'Ağ gecikmesi, internet bağlantısının kaçınılmaz bir parçasıdır. Ağ gecikme süresi ne olursa olsun, tüm bahisleri ve para çekme işlemlerini yalnızca sunucumuza ulaştıkları anda geçerli olarak kabul ederiz.',
'network_latency_title': 'AĞ GECİKMESİ',
'no_thanks': 'Hayır, teşekkürler!',
'not_right_now': 'Şimdi olmaz',
'off': 'kapalı',
'oh_snap': 'Hay Allah!',
'ok_close': 'Tamam, Kapat!',
'ok_thanks': 'Tamam, Teşekkürler!',
'on': 'açık',
'on_loss': 'KAYBEDİNCE',
'on_win': 'KAZANINCA',
'online': 'Online',
'per_bet': 'bahis başına',
'pick_your_avatar_img': 'Avatar resminizi seçin',
'place_bet_with_last': 'Son seçilen bahis miktarıyla bahis yapın ',
'play_now': 'Şimdi Oyna',
'player_1_seed': '‎1. Oyuncu seed’i',
'player_2_seed': '‎2. Oyuncu seed’i',
'player_3_seed': '‎3. Oyuncu seed’i',
'please_wait_active_bets': 'Ücretsiz bahisleri etkinleştirmek için lütfen mevcut bahislerin tamamlanmasını bekleyin.',
'points': 'PUANLAR',
'powered_by': 'Destekleyen',
'previous_hand': 'Önceki El',
'prize': 'ÖDÜL',
'prizes': 'ödüller',
'progressive_jackpot': 'ARTAN JACKPOT',
'promo_credit': 'Promosyon Kredisi',
'promo_credit_condradulations': 'Tebrikler! Oyunda kullanabileceğiniz astronomik düzeyde iyi bir Promosyon kredisi kazandınız!',
'promo_credits': 'PROMOSYON KREDİLERİ',
'promo_history': 'Promosyon Geçmişi',
'provably_fair': 'Kanıtlanabilir Adillik',
'provably_fair_1_paragraph': 'Rocketman, ‘Kanıtlanabilir Adillik\' adı verilen şifreleme teknolojisine dayanmaktadır. Bu teknoloji, oyun sonucunun %100 adilliğini garanti eder. Bu teknoloji ile herhangi bir üçüncü tarafın oyun sürecine müdahale etmesi imkansızdır.',
'provably_fair_2_paragraph': 'Rauntların sonucu (Oyunun \'Uçuş\' katsayısı) sunucularımızda oluşturulmaz. Raunt oyuncularının yardımıyla oluşturulur ve tamamen şeffaftır. Bu şekilde, herhangi birinin oyun sonucunu manipüle etmesi imkansızdır. Ayrıca, oyunun adilliğini herkes kontrol v',
'provably_fair_3_paragraph': 'Raundun sonucu, Raundun dört bağımsız katılımcısından elde edilir',
'provably_fair_4_paragraph': 'Raunt başladığında oyun, sunucu seed’ini üç seed’iyle birleştirir. Birleştirilmiş sembollerden SHA512 hash, bu hash ile de oyun sonucu üretilir.',
'provably_fair_5_paragraph': 'Oyun geçmişinde yeşil simgeye tıklayarak her raundun adilliğini kontrol edebilirsiniz. Açılan pencerede sunucu seed’ini, 3 çift oyuncu seed’ini, birleşik hash’i ve raunt sonucunu görebilirsiniz.',
'provably_fair_6_paragraph': 'Sonraki raundun sunucu seed’inin hash versiyonu, ayarlar penceresinde herkese açık olarak mevcuttur (Kullanıcı menüsünde, "Kanıtlanabilir Adillik Ayarları" ve ardından "Sonraki sunucu seed’i SHA256"sekmelerini kontrol edin). Ayrıca müşteri seed’inizi bura',
'provably_fair_7_paragraph': 'Hash\'in doğruluğu herhangi bir online hesap makinesinde kontrol edilebilir, örneğin',
'provably_fair_system': 'kanıtlanabilir adillik sistemi',
'quick_explanation': 'Hızlı açıklama',
'randomly_generated_server_hash': 'Rastgele oluşturulmuş sunucu HASH',
'rank': 'SIRALAMA',
'raw_multiplier': 'ham çarpanına yuvarlayın',
'raw_multiplier_to_edged': 'Ham Çarpan\'dan Uçlu Çarpan\'a,',
'remove_additional_bet': 'Ekstra bahsi sil',
'reset': 'Sıfırla',
'return_to_player': 'OYUNCUYA GERİ DÖNÜŞ',
'rocketman_is_pure_excitement': 'Rocketman heyecan doludur! Riske girin ve kazanın.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot kazananları',
'round': 'Raunt',
'round_id': 'Raunt ID',
'round_multiplier': 'Raunt Çarpanı',
'round_results': 'raunt sonuçları',
'rounds': 'rauntlar',
'rtp': 'RTP kullanarak',
'rules': 'kurallar',
'rules_details': 'Turnuva Kuralları',
'save': 'Kaydet',
'schedule': 'program',
'second': 'ikinci',
'seconds_left': 's kaldı',
'server_hash': 'Sunucu HASH',
'please_wait_current_bets': 'LÜTFEN GÜNCEL BAHİSLERİN TAMAMLANMASINI BEKLEYİN',
'min_autocashout_value_must_be': 'MİN OTOMATİK ÇIKIŞ DEĞERİ OLMALIDIR',
'min_bet_value_must_be': 'MİN BAHİS DEĞERİ OLMALIDIR',
'server_seed': 'Sunucu Seed’i',
'session_dissconnected': 'Oturum Bağlantısı Kesildi',
'multisession_not_allowed': 'Çoklu oturumlara izin verilmiyor',
'set_auto_bet': 'OTOMATİK BAHİS AYARLA',
'set_auto_cashout': 'OTOMATİK PARA ÇEKME AYARLA',
'shourtcut_keys': 'KISAYOL TUŞLARI',
'show_hex_to_deimal': 'Hex’ten ondalığa dönüştürmeyi göster',
'sound': 'SES',
'sound_settigs': 'Ses Ayarları',
'start_with_autoplay': 'Otomatik oynatma ile başla',
'stop_autoplay': 'OTOMATİK OYNAMAYI İPTAL ET',
'stop_on_loss': 'KAYBEDİNCE DURDUR',
'stop_on_loss_tooltip': 'Belirli bir miktarı kaybettikten sonra otomatik bahsi durdurmak için Kaybedince Durdur özelliğini kullanın.<br/> Örneğin, 100$ ile başlarsanız ve Kaybedince Durdur özelliğini 25$ olarak ayarlarsanız',
'stop_on_profit': 'KÂR EDİNCE DURDUR',
'stop_on_profit_tooltip': 'Belirli bir miktarda kâr elde ettikten sonra otomatik bahsi durdurmak için Kâr Edince Durdur özelliğini kullanın.<br/> Örneğin, 100$ ile başlarsanız ve Kâr Edince Durdur özelliğini 25$ olarak ayarlarsanız',
'there_are_no_active_torunament': 'Şu anda aktif turnuva yok.',
'third': 'üçüncü',
'this_game_is': 'Bu oyunu',
'this_tool_recalculates': 'Bu araç, çarpanı oyun hash değeri üzerinden yeniden hesaplar. Çarpanı doğrulamak için herhangi bir oyun hash değerini kopyala/yapıştır yapabilirsiniz.',
'time': 'ZAMAN',
'time_frame': 'Zaman Aralığı',
'to': 'Bitiş',
'toggle_animation': 'Geçiş Animasyonu',
'top_odds': 'En İyi Oranlar',
'top_wins': 'En İyi Kazançlar',
'total_bets': 'Toplam Bahisler',
'tournament': 'Turnuva',
'tournament_information': 'Turnuva bilgileri',
'try_advanced_autoplay': 'Yeni gelişmiş otomatik oynatma özelliğini deneyin!',
'uncredited_wins': 'Hesaba geçmeyen kazançlar',
'user': 'KULLANICI',
'user_and_points': 'KULLANICI & PUANLAR',
'username': 'Kullanıcı adı',
'username_must_be_at_least': 'Kullanıcı adı en az',
'verify': 'Doğrula',
'vip_popup_text': 'Sadece VIP üyelerimize özel VIP avatarlarından birini seçebilirsiniz!',
'vip_popup_title': 'VIP oyuncu olduğunuz için tebrik ederiz',
'waiting_for_the_next_round': 'Bir sonraki raunt bekleniyor...',
'warnings_are_calculated': ' ‎• Kazançlar, para çekme işlemi sırasında ekranda gösterilen bahis çarpanı ile bahis tutarınız çarpılarak hesaplanır',
'watch_take_off': 'Şanslı Roketinizin uçuşunu ve kazançlarınızın artışını izleyin.',
'watch_the_rocket': 'Roketi İzle',
'we_have_noticed': '‎‘”Rocketman” oyununu ilk kez oynadığınızı fark ettik. Nasıl oynanacağını açıklayan kısa bir video görmek ister misiniz?`',
'week': 'Hafta',
'welcome_to_rocketman': 'Yeni ve heyecan verici bir oyun olan Rocketman\'a hoş geldiniz! Rocketman ile orijinal bahis tutarınızın 20.000x katına kadar kazanma şansınız var.',
'what_is_provably_fair': 'Kanıtlanabilir adillik nedir?',
'will_be_deposited': 'İlk normal kazançla birlikte oyuncuların bakiyesine yatırılacak',
'win': 'KAZANÇ',
'win_amount': 'Kazanç Tutarı',
'win_gifs': 'KAZANÇ',
'winner': 'kazanan',
'wins': 'kazançlar',
'write_a_replay': 'Cevap yaz…',
'year': 'Yıl',
'yes': 'EVET',
'yes_start_the_round': 'Evet, raunda başla',
'you_have_cashed_out': 'Para çektiniz, kazancınız',
'you_have_won': 'KAZANDINIZ',
'your_bet_is_lost': 'bahsiniz kaybeder.',
'current': 'Akım',
'previous': 'Öncesi',
'claim': 'İddia',
'this_feature_will_give': 'Bu özellik seçilen miktarı sohbetteki rastgele kullanıcılara verecektir',
'amount_per_player': 'Oyuncu Başına Tutar',
'no_of_players': 'Oyuncu sayısı',
'meteor_shower': 'Meteor yağmuru',
'claim_your_free_bet': 'Ücretsiz Bahisinizi Talep Edin',
'meteor_shower_total': 'Meteor Yağmuru Toplamı',
'shower': 'YAĞMUR',
'total': 'Toplam',
'meteor_shower_incoming': 'Meteor Yağmuru geliyor, sohbete bakın!',
'insurance': 'SİGORTA',
'insurance_paragraph': 'Oyuncular, bahis düğmesinin yanındaki "Bahis Sigortası" onay kutusuna tıklayarak bahisleri için sigorta satın alabilirler. Sigorta, toplam bahis tutarının %10\'u tutarında ve roket 1.00 katlayıcıda patlarsa bahis tutarını oyuncuya geri ödeyecektir.',
'insure_your_bet': 'BAHİSİNİZİ SİGORTA EDİN',
'bet_insurance_promo_text': 'Bahsinizin 1,00 çarpanıyla roket çarpmasına karşı sigortalanması için "Bahis Sigortası" seçeneğini seçin.',
'bet_insurance_promo_text_helper': 'Daha fazlasını "Oyun Kuralları" bölümünde okuyun...',
'game_explanation_1': 'Çarpanınızı seçin; oyun çarpanı seçtiğiniz çarpanı aşarsa kazancınız',
'game_explanation_2': 'Çarpanınız x Bahsiniz',
'retail_max_mp_1': 'Kazanmak',
'retail_max_mp_2': 'senin bahsin!',
'play_multibets': 'Oynamak',
'multibets': 'MULTİBAHİSLER',
'bonus_game': 'BONUS OYUN',
'all_wins_doubled': 'Tüm kazançlar ikiye katlandı!',
'multibets_paragraph_1': 'şansınızı artırmak için!',
'multibets_paragraph_2': 'On tura kadar olan bahisleri tek bir çoklu bahiste birleştirebilir ve büyük kazançlar kazanabilirsiniz!',
'multibets_paragraph_3': 'Her tur kazanıldığında, o turdan elde edilen kazanç, tüm turlar kazanana kadar bir sonraki turda bahis olarak oynanır.',
'bonus_launch': 'Bonus Lansmanı" turundaki tüm bahislerde x2 kazanın!',
'multiplier_history': 'Çarpan Geçmişi',
'will_start_soon': 'yakında başlayacak',
'hurry_up_and_place_your_bets': 'Acele edin ve bahislerinizi yapın!',
'active_bets': 'Aktif Bahisler',
'shop': 'MAĞAZA',
'game_meteor_shower_paragraph': 'Meteor Yağmuru, önceden tanımlanmış sayıda oyuncuya önceden tanımlanmış bir değerde bir ücretsiz bahis verir. Sunucu veya oyuncu tarafından başlatılabilir. Meteor Yağmuru etkinleştirildiğinde oyuna bir mesaj düşerek kullanıcıları Meteor Yağmuru mesajının bulunduğu sohbete bakmaya yönlendirir. Oyuncular “Talep Et” butonuna tıklayarak ücretsiz bahsi talep edebilirler. Ücretsiz bahisler, ilk önce “Talep Et” butonuna tıklayan oyunculara verilir. Oyuncu tıklamalarını taklit etmek ve ödülleri almak için otomatik tıklama programlarının veya herhangi bir tür otomasyon yazılımının kullanılması yasaktır ve bu özelliğin yasaklanmasıyla sonuçlanabilir.',
'claming_failed': 'Hak talebinde bulunulamadı',
'player_on_blacklist': 'Oyuncu kara listede',
'you_can_claim_ticket': 'Yalnızca düzenli bahis yapan aktif bir oyuncuysanız bilet talep edebilirsiniz',
'you_already_have_free_tickets': 'Zaten ücretsiz biletleriniz var, lütfen önce harcayın',
'please_play_the_game_in_portait_mode': 'Lütfen oyunu dikey modda oynayın.',
};
langArr.uk = {
'your_insurance_returns_you': 'Ваша страховка повертає вас',
'bet_insurance': 'Страхування ставок',
'max_bet_is': 'Максимальна ставка',
'already_active_bet_of': 'і вже є активна ставка',
'1_from_game_history': '‎1. У «Історії гри».',
'2_in_settings_window': '‎2. У вікні «Налаштування»',
'3_online_calculator': '‎3. Онлайн-калькулятор',
'active_tournament_1': 'На активний турнір ',
'active_tournament_2': 'ставки поки що відсутні.',
'add_another_bet': 'Додати іншу ставку',
'advanced_autoplay': 'РОЗШИРЕНА АВТОГРА',
'advanced_autoplay_for_bet': 'Розширена автогра для ставки',
'alien_saucer_free_bets': 'Вільні ставки «Тарілка прибульця»',
'all_bets': 'Усі ставки',
'all_in_your_hands': 'Усе у ваших руках!',
'allow': 'Дозволити',
'at': 'на',
'austronaut_promo_credit': 'Промо-кредит «Астронавт»',
'auto_play_and_autocash': 'АВТОГРА ТА АВТОВИВЕДЕННЯ ',
'autoplay': 'Автогра',
'autoplay_and_cashout': '‎02. Автогра та автовиведення',
'back_to_home': 'Повернутися на головну',
'balance': 'Баланс',
'bet': 'СТАВКА',
'bet_amount': 'РОЗМІР СТАВКИ',
'bet_and_cashout': '‎01. Ставки та виведення коштів',
'bet_bets_tab': 'СТАВКА',
'bet_failed': 'Ставку не прийнято',
'bet_statistic': 'Статистика ставок',
'bet_time': 'Ставку зроблено о',
'bet_wins': 'Виграші ставок',
'bets': 'Ставки',
'biggest_odds': 'Найвищі коефіцієнти',
'bonus_type': 'ТИП БОНУСУ',
'bonusing_history': 'Історія бонусів',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Щасливчики, які зірвали Boosterpot',
'but_remember_paragraph': 'Але пам’ятайте',
'calculate_raw_mp_to_decimal': 'Обчислити приблизний множник з десяткових, округлити до цілого',
'cancel': 'Скасувати',
'cant_post_media_content': 'Неможливо часто розміщувати медіа-контент',
'cashed_out': 'Виведено',
'cashout': 'Виведення',
'cashout_at': 'КЕШАУТ ЗА',
'cashout_before': 'Заберіть гроші до того, як ракета вибухне, щоб залишитися з виграшом!',
'change_avatar': 'Змінити аватар',
'change_username': 'Змінити ім’я користувача',
'characters_long': 'символів',
'chat': 'Чат',
'chat_room': 'Кімната для чату',
'checking_for_spam': 'Перевіряємо на спам...',
'close': 'Закрити',
'coeff': 'КОЕФ.',
'collect_win': 'Заберіть виграш',
'combined_seed': 'Об’єднане зерно',
'company': 'Компанія',
'congratulations_you_have_won_free_bets': 'Вітання! Ви виграли Бонусну Ставку! Ви можете зробити ставку',
'convert_first_13_to_decimal': ' Перетворити перші 13 байт у десяткові',
'd': 'д',
'date': 'ДАТА',
'date_and_time': 'Дата й час',
'deny': 'Відхилити',
'detailed_game_rules': 'Докладні правила гри',
'dissconnection_policy': 'ПОЛІТИКА РОЗ’ЄДНАННЯ',
'easy_as_one_two_three': 'Просто, як раз, два, три!',
'enter_username': 'Будь ласка, введіть бажане ім’я користувача',
'error_handling': 'УСУНЕННЯ ПОМИЛКИ',
'exit_full_screen': 'Вийти з повноекранного режиму',
'first': 'перше',
'first_time_playing': 'Граєте у "Rocketman" уперше?',
'folow_the_three_easy_steps': 'Зробіть три прості кроки та насолоджуйтеся грою Rocketman! ',
'free_bets': 'БЕЗКОШТОВНІ СТАВКИ',
'free_bets_desc': 'Ви виграли безкоштовні ставки! Скористатися безкоштовними ставами можна будь-коли, натиснувши кнопку пуску.',
'free_bets_header': 'Безкоштовні ставки',
'from': 'З',
'fullscreen_mode': 'Для зручності користувача гра почнеться в повноекранному режимі.',
'function': 'Функція',
'game_functions': 'Функції гри',
'game_history': 'ІСТОРІЯ ГРИ',
'game_r_1_paragraph': 'Гравець може активувати режим автогри, коли ставка на кожен новий раунд розміщується автоматично у розмірі попередньої ставки.',
'game_r_10_paragraph': 'Після надання промокредиту кошти на будь-яку ставку гравця списуються спершу за рахунок промокредиту, доки він не закінчиться, після чого кошти знову списуватимуться з гаманця гравця.',
'game_r_11_paragraph': 'Промокредит неможливо вивести, але всі виграші за такими ставками виплачуються гравцеві в повному обсязі.',
'game_r_12_paragraph': 'Промокредити дійсні протягом 30 днів, після чого їх буде анульовано і скористуватися ними буде неможливо.',
'game_r_13_paragraph': 'Гравцю випадковим чином можуть бути надані безкоштовні ставки. На екрані спливає зображення, що повідомляє про надання гравцеві безкоштовних ставок, їхньої кількості та розміру ставки.',
'game_r_14_paragraph': 'Після надання безкоштовних ставок гравець може сам вирішувати, коли починати раунд із безкоштовними ставками. З початком раунду з безкоштовними ставками ставки певного розміру розміщуються автоматично, а гравець може вивести кошти в будь-який момент. Якщо',
'game_r_15_paragraph': 'Безкоштовні ставки дійсні протягом 30 днів, після чого їх буде анульовано і скористуватися ними буде неможливо.',
'game_r_16a_paragraph': 'Загальний теоретичний відсоток повернення гравцеві становить ',
'game_r_17_paragraph': 'Відсоток виплати гравцям постійно контролюють і перевіряють незалежні треті сторони.',
'game_r_18_paragraph': 'Якщо після розміщення ставки зв’язок переривається, ця ставка не скасовується. Коли буде досягнуто значення множника 20 000x, ставка виплачується автоматично і кредити зараховуються на баланс.',
'game_r_19_paragraph': 'Під час гри можна спілкуватися з іншими гравцями. Пишіть повідомлення в полі чату або повідомляйте іншим гравцям , що ваша ставка зіграла.',
'game_r_2_paragraph': 'Якщо множник автовиведення ввести у поле «Автовиведення», щоразу коли досягатиметься встановлений множник, кошти виводитимуться автоматично.',
'game_r_20_paragraph': 'Гравцям, які зловживатимуть чатом, ображатимуть інших, використовуючи лайливу чи нецензурну лексику, , чат буде вимкнено.',
'game_r_21_paragraph': 'У грі Rocketman використовується чудова фонова музика та звукові ефекти. Якщо хочете вимкнути музику та/або звукові ефекти,ви можете це зробити, розгорнувши меню.',
'game_r_22_paragraph': 'Свої останні ставки гравець може переглянути, натиснувши кнопку «Мої ставки». Щоб переглянути історію гри за триваліший період, потрібно увійти в розділ з історією гравця на самій ігровій платформі.',
'game_r_23_paragraph': 'У разі помилки раунд може не розпочатися вчасно. Якщо так станеться, наша служба підтримки зробить усе можливе для якнайшвидшого вирішення проблеми й відновлення звичайних раундів гри. У разі виникнення проблеми з самим раундом гри, усі ставки ставки буде',
'game_r_24_paragraph': 'Затримка в мережі є невід’ємною частиною інтернет-підключення. Незалежно від тривалості затримки в мережі, ми зараховуємо всі ставки й кешаути лише тоді, коли відповідна інформація з’являється на нашому сервері. ',
'game_r_3_paragraph': 'Для швидкого розміщення ставки або виведення коштів скористайтеся клавішами прискорення гри. Розкладка клавіш',
'game_r_4_paragraph': '1% від кожної ставки виділяється у фонд прогресивного джекпоту. Цей фонд обслуговує систему прогресивного джекпоту',
'game_r_5_paragraph': ' ‎• Jackpot – найвищий рівень, найбільший виграш, трапляється найрідше',
'game_r_6_paragraph': ' ‎• Rocketpot – середній рівень, середній виграш',
'game_r_7_paragraph': ' ‎• Boosterpot – найнижчий рівень, найменший виграш, трапляється найчастіше ',
'game_r_8_paragraph': 'Усі рівні джекпоту визначаються алгоритмами гри "Таємниця" або "must-hit-by" (джекпот обов’язково випадає, перш ніж досягне певної встановленої суми). Розмір кожного "must-hit-by" джекпоту визначається генератором випадкових чисел одразу ж після виграшу п',
'game_r_8a_paragraph': 'Примітка. Переможцем джекпоту може стати лише один гравець Rocketman, який активно грає в раунді, коли джекпот був активований. Немає можливості, що якщо гравець виграв джекпот, йому доведеться поділитися ним з будь-яким іншим гравцем Rocketman.',
'game_r_9_paragraph': 'Гравцю випадковим чином можуть бути надані промокредити. На екрані спливає зображення, що повідомляє про надання гравцеві промокредиту та його розміру.',
'game_round': 'раунд гри!',
'game_rules': 'Правила гри',
'game_rules_1_paragraph': 'Rocketman — це азартна гра нового покоління. За секунди вигравай набагато більше! В основі гри Rocketman лежить',
'game_rules_10_paragraph': ' ‎• Автоматична гра активується натисканням на «Авто» у вкладці «Панель ставки» та встановленням позначки в полі «Автоставка». Після активації ставки будуть розміщуватися автоматично, але для виведення коштів у кожному раунді вам доведеться натискати кноп',
'game_rules_11_paragraph': '‎• Автоматичне виведення коштів доступне у вкладці «Авто» на панелі ставки. Після активації виведення ваших виграшів за ставками відбуватиметься автоматично, коли буде досягнуто введений коефіцієнт.',
'game_rules_2_paragraph': '‎, що на сьогоднішній день є єдиною дійсною гарантією чесності в індустрії азартних ігор.',
'game_rules_3_paragraph': '‎• Множники виграшів починаються з 1x і зростають, поки злітає Щаслива ракета.',
'game_rules_4_paragraph': ' ‎• Ваш виграш залежить від множника, на який множиться ваша ставка на момент виведення коштів. ',
'game_rules_5_paragraph': '‎• Перед початком кожного раунду генератор випадкових чисел, чесність якого забезпечує технологія «Provably Fair», генерує коефіцієнт, за якого улітає Щаслива ракета. Ви можете перевірити чесність генерованих даних, натиснувши навпроти результату у заклад',
'game_rules_6_paragraph': '‎• Для розміщення ставки виберіть суму та натисніть кнопку «Ставка». ',
'game_rules_7_paragraph': '‎• Ви можете розмістити дві ставки одночасно, додавши панель другої ставки. Щоб додати панель другої ставки, натисніть на іконку зі знаком «плюс», розташовану у правому верхньому кутку панелі першої ставки.',
'game_rules_8_paragraph': ' ‎• Натисніть «Виведення коштів», щоб забрати виграш. Ваш виграш — це сума вашої ставки, помножена на виграшний коефіцієнт.',
'game_rules_9_paragraph': '‎• Якщо ви не встигнете забрати гроші до того, як вибухне ракета, ви втратите свою ставку.',
'general_rules': 'ЗАГАЛЬНІ ПРАВИЛА',
'gifs': 'ГІФКИ',
'go_back': 'Повернутися',
'good_job_buddy': 'Чудово, друже, так тримати!',
'h': 'г',
'hashed_in_sha_512': 'Хешовані в SHA512',
'hex': 'Шістнадцятковий',
'hey_username': 'Агов,',
'hide_hex_to_decimal': 'Перетворення шістнадцяткових у десяткові',
'highscore': 'High Score',
'how_it_works': 'Як це працює?',
'how_to_check': 'Як перевірити?',
'how_to_play': 'Як грати',
'if_the_maximum_multiplier': '‎• Коли буде досягнуто максимальний множник 20 000, усі відкриті ставки автоматично сплачуються за максимальним множником',
'increase': 'Збільшити',
'input_bet_amount': 'Укажіть розмір ставки і зробіть ставку перед стартом.',
'insufficient_funds': 'Бракує коштів',
'it_blew_up': 'Вона вибухнула! Яка прикрість...',
'jackpot': 'Джекпот',
'jackpot_winners': 'Щасливчики, які зірвали Jackpot',
'key': 'Клавіша',
'load_more': 'ЗАВАНТАЖИТИ БІЛЬШЕ',
'loading': 'Завантаження',
'loading_next_round': 'Завантаження наступного раунду....',
'loss_gifs': 'ПРОГРАШ',
'lost_connection': 'Сталося роз’єднання',
'make_a_bet': 'Зробити ставку',
'malfunction_voids': 'Збій анулює всі виплати й ігри.',
'maximum_payout': 'Максимальна виплата становить ',
'menu': 'Меню',
'min_bet_is': 'Мін. Ставка',
'month': 'Місяць',
'more_details': 'Докладно',
'more_information': 'Більше інформації',
'multiplier': 'Множник',
'multiplier_starts': '‎• Множник починається від 1 і збільшується до 20 000',
'multipliers_larger': 'Множники більші за x20 000 обмежуються максимальною величиною множника',
'music_settings': 'Налаштування музики',
'my_bet_history': 'Історія моїх ставок',
'my_bets': 'Мої ставки',
'my_bonysing_history': 'Моя історія бонусів',
'network_latency': 'ЗАТРИМКА В МЕРЕЖІ',
'network_latency_content': 'Затримка в мережі є невід’ємною частиною інтернет-підключення. Незалежно від тривалості затримки в мережі, ми зараховуємо всі ставки й кешаути лише тоді, коли відповідна інформація з’являється на нашому сервері.',
'network_latency_title': 'ЗАТРИМКА В МЕРЕЖІ',
'no_thanks': 'Ні, дякую!',
'not_right_now': 'Не зараз',
'off': 'вимк',
'oh_snap': 'О, ні!',
'ok_close': 'Добре, вибрати!',
'ok_thanks': 'Добре, дякую!',
'on': 'увімк',
'on_loss': 'У РАЗІ ПРОГРАШУ',
'on_win': 'У РАЗІ ВИГРАШУ',
'online': 'Онлайн',
'per_bet': 'на ставку',
'pick_your_avatar_img': 'Виберіть зображення на свій аватар',
'place_bet_with_last': 'Поставити суму з минулого разу ',
'play_now': 'Грати зараз',
'player_1_seed': 'Зерно Гравця 1',
'player_2_seed': 'Зерно Гравця 2',
'player_3_seed': 'Зерно Гравця 3',
'please_wait_active_bets': 'Перш ніж активувати безкоштовні ставки, дочекайтеся завершення поточних ставок',
'points': 'БАЛИ',
'powered_by': 'за технологією',
'previous_hand': 'Попередній раунд',
'prize': 'ПРИЗ',
'prizes': 'призи',
'progressive_jackpot': 'ПРОГРЕСИВНИЙ ДЖЕКПОТ',
'promo_credit': 'Промо кредит',
'promo_credit_condradulations': 'Вітання! Ви виграли астрономічний промо-кредит і можете скористатися ним у грі!',
'promo_credits': 'ПРОМОКРЕДИТИ',
'promo_history': 'Історія акцій',
'provably_fair': 'Доведено справедливо',
'provably_fair_1_paragraph': 'Гру «Rocketman» створено на базі криптографічної технології «Provably Fair». Ця технологія на 100% гарантує чесний результат гри. Завдяки цій технології стороння особа за жодних обставин не може втрутитися в ігровий процес.',
'provably_fair_2_paragraph': 'Результат кожного раунду (коефіцієнт, що змінюється під час зльоту ракети) не генерується на наших серверах. Він генерується за допомогою гравців раунду та є повністю прозорим. Таким чином, ніхто не може маніпулювати результатами гри. Крім того, кожен мож',
'provably_fair_3_paragraph': 'Результат раунду генерується чотирма незалежними учасниками раунду',
'provably_fair_4_paragraph': 'З початком раунду гра поєднує зерно серверу з клієнтськими зернами. З об’єднаних символів генерується хеш SHA512, а з цього хешу — результат гри.',
'provably_fair_5_paragraph': 'Чесність кожного раунду перевіряється в історії гри натисканням на зелену іконку. У віконці, що відкриється, ви можете переглянути зерно сервера, 3 пари зерен гравців, об’єднаний хеш і результат раунду.',
'provably_fair_6_paragraph': 'Хешовані версії наступних раундів доступні для загального перегляду в розділі «Налаштування» (у меню користувача виберіть «Налаштування Provably Fair», потім виберіть «Наступне зерно сервера SHA256»). Також тут ви можете змінити ваше клієнтське зерно. Якщ',
'provably_fair_7_paragraph': 'Коректність хешування можна перевірити за допомогою будь-якого онлайн-калькулятора, наприклад',
'provably_fair_system': 'система «Provably Fair»',
'quick_explanation': 'Коротке пояснення',
'randomly_generated_server_hash': 'Довільно сгенерований ХЕШ сервера',
'rank': 'РАНГ',
'raw_multiplier': 'приблизний множник',
'raw_multiplier_to_edged': 'Приблизний множник у граничний множник з використанням',
'remove_additional_bet': 'Прибрати іншу ставку',
'reset': 'Скинути',
'return_to_player': 'ВІДСОТОК ВИПЛАТИ ГРАВЦЯМ',
'rocketman_is_pure_excitement': 'Rocketman — це чистий азарт! Хто не ризикує, той не п’є шампанського!',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Щасливчики, які зірвали Rocketpot',
'round': 'Раунд',
'round_id': 'Id раунду',
'round_multiplier': 'Множник раунду',
'round_results': 'результат раунду',
'rounds': 'раунди',
'rtp': 'RTP',
'rules': 'правила',
'rules_details': 'Правила турнірів',
'save': 'Зберегти',
'schedule': 'розклад',
'second': 'друге',
'seconds_left': 'с залишилося',
'server_hash': 'ХЕШ сервера',
'please_wait_current_bets': 'БУДЬ ЛАСКА, ДОЧЕКАЙТЕСЯ ЗАВЕРШЕННЯ ПОТОЧНИХ СТАВОК',
'min_autocashout_value_must_be': 'ПОВИННО БУТИ МІНІМАЛЬНЕ ЗНАЧЕННЯ АВТОКАШУТУ',
'min_bet_value_must_be': 'МІНІМАЛЬНА СТАВКА ПОВИННА БУТИ',
'server_seed': 'Зерно сервера',
'session_dissconnected': 'Роз’єднання під час сеансу',
'multisession_not_allowed': 'Мультисеанс не допускається',
'set_auto_bet': 'НАЛАШТУВАТИ АВТОСТАВКУ',
'set_auto_cashout': 'НАЛАШТУВАТИ АВТОВИВЕДЕННЯ',
'shourtcut_keys': 'КЛАВІШІ ШВИДКОГО ВВОДУ',
'show_hex_to_deimal': 'Показати перетворення шістнадцяткових у десяткові',
'sound': 'ЗВУК',
'sound_settigs': 'Налаштування звуку',
'start_with_autoplay': 'Почати з автогри',
'stop_autoplay': 'ЗУПИНИТИ АВТОГРУ',
'stop_on_loss': 'ПРИ ПРОГРАШУ',
'stop_on_loss_tooltip': 'Скористайтеся функцією «Зупинитися в разі програшу» для припинення автоматичного розміщення ставок після програшу визначеної суми.<br/> Наприклад, ви почали гру зі $100 та встановили «Зупинитися», коли у вас залишається $25.',
'stop_on_profit': 'ЗУПИНИТИСЯ В РАЗІ ВИГРАШУ',
'stop_on_profit_tooltip': 'Скористайтеся функцією «Зупинитися в разі виграшу» для припинення автоматичного розміщення ставок після отримання визначеної суми виграшу.<br/> Наприклад, ви почали гру зі $100 та встановили «Зупинитися», коли ваш виграш сягнув $25.',
'there_are_no_active_torunament': 'Активні турніри наразі відсутні.',
'third': 'третє',
'this_game_is': 'Цю гру розроблено',
'this_tool_recalculates': 'Цей інструмент переобчислює множник за хеш-кодом гри. Ви можете скопіювати і вставити хеш-код будь-якої гри й перевірити множник.',
'time': 'ЧАС',
'time_frame': 'Проміжок часу',
'to': 'ПО',
'toggle_animation': 'Увімкнути анімацію',
'top_odds': 'Топ коефіцієнти',
'top_wins': 'Топ виграші',
'total_bets': 'Загальні ставки',
'tournament': 'Турнір',
'tournament_information': 'Інформація про турнір',
'try_advanced_autoplay': 'Спробуйте нову розширену функцію автовідтворення!',
'uncredited_wins': 'Незараховані виграші',
'user': 'КОРИСТУВАЧ',
'user_and_points': 'ГРАВЕЦЬ ТА БАЛИ',
'username': 'Ім’я користувача',
'username_must_be_at_least': 'Ім’я користувача має містити принаймні',
'verify': 'Перевірити',
'vip_popup_text': 'Ви можете вибрати один зі спеціальних VIP аватарів, що доступні виключно для наших VIP учасників!',
'vip_popup_title': 'Вітаємо! Ви отримали статус VIP гравця',
'waiting_for_the_next_round': 'Чекаємо на наступний раунд...',
'warnings_are_calculated': ' ‎• Виграші розраховуються множенням розміру ставки на множник на момент Виведення коштів',
'watch_take_off': 'Спостерігайте за тим, як Щаслива ракета відривається від землі, а разом із нею злітають і ваші виграші.',
'watch_the_rocket': 'Спостерігайте за ракетою',
'we_have_noticed': 'Ми помітили, що це ваша перша гра у "Rocketman". Не бажаєте переглянути коротке відео з поясненнями щодо гри?',
'week': 'Тиждень',
'welcome_to_rocketman': 'Ласкаво просимо до нової та захопливої гри Rocketman! У цій грі ви маєте можливість виграти до 20 000разів більше від вашої початкової ставки.',
'what_is_provably_fair': 'Що означає «Provably fair»?',
'will_be_deposited': 'Буде зараховано на баланс гравця з першим звичайним виграшом',
'win': 'ВИГРАШ',
'win_amount': 'Сума виграшу',
'win_gifs': 'ВИГРАШ',
'winner': 'переможець',
'wins': 'виграші',
'write_a_replay': 'Написати відповідь....',
'year': 'Рік',
'yes': 'ТАК',
'yes_start_the_round': 'Так, почати раунд',
'you_have_cashed_out': 'Ваші кошти виведено, ваш виграш становить ',
'you_have_won': 'ВИ ВИГРАЛИ',
'your_bet_is_lost': 'ваша ставка згорить!',
'current': 'поточний',
'previous': 'Попередній',
'claim': 'Претензія',
'this_feature_will_give': 'Ця функція надасть обрану суму випадковим користувачам у чаті',
'amount_per_player': 'Сума на гравця',
'no_of_players': 'Кількість гравців',
'meteor_shower': 'Метеоритний дощ',
'claim_your_free_bet': 'Отримайте безкоштовну ставку',
'meteor_shower_total': 'Метеоритний дощ Всього',
'shower': 'ДОЩ',
'total': 'Всього',
'meteor_shower_incoming': 'Наближається метеоритний дощ, подивіться на чат!',
'insurance': 'СТРАХУВАННЯ',
'insurance_paragraph': 'Гравці можуть придбати страховку для своєї ставки, натиснувши на прапорець "Страхування ставки" поруч з кнопкою ставки. Страховка коштує 10% від загальної суми ставки і поверне суму ставки гравцеві, якщо ракета вибухне з множником 1,00.',
'insure_your_bet': 'ЗАСТРАХУЙТЕ СВОЮ СТАВКУ',
'bet_insurance_promo_text': 'Виберіть опцію «Страхування ставок», щоб застрахувати свою ставку від падіння ракети з множником 1,00.',
'bet_insurance_promo_text_helper': 'Детальніше в розділі "Правила гри"...',
'game_explanation_1': 'Виберіть множник, і якщо множник гри перевищить вибраний множник, ваш виграш буде',
'game_explanation_2': 'Ваш множник x Ваша ставка',
'retail_max_mp_1': 'Виграти до',
'retail_max_mp_2': 'ваша ставка!',
'play_multibets': 'Toneelstuk',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'om uw kansen te vergroten!',
'multibets_paragraph_2': 'Je kunt weddenschappen op maximaal tien ronden combineren tot één enkele multibet en enorme uitbetalingen winnen!',
'multibets_paragraph_3': 'Elke keer dat een ronde wordt gewonnen, wordt het winnende dividend uit die ronde ingezet in de volgende ronde, totdat alle rondes winnend zijn.',
'bonus_launch': 'Виграйте 2 рази на всіх ставках у раунді «Bonus Launch»!',
'multiplier_history': 'Історія множників',
'will_start_soon': 'незабаром розпочнеться',
'hurry_up_and_place_your_bets': 'Поспішайте робити ставки!',
'active_bets': 'Активні ставки',
'shop': 'МАГАЗИН',
'game_meteor_shower_paragraph': 'Meteor Shower надає одну безкоштовну ставку заздалегідь визначеної кількості гравців. Він може бути ініційований як сервером, так і гравцем. Після активації «Метеоритного дощу» в грі з’являється повідомлення, яке пропонує користувачам переглянути чат, де міститься повідомлення «Метеоритний дощ». Гравці можуть отримати безкоштовну ставку, натиснувши кнопку «Вимагати». Безкоштовні ставки присуджуються гравцям, які першими натискають кнопку «Вимагати». Використання автоматичних клікерів або будь-якого програмного забезпечення для автоматизації для імітації клацань гравців і отримання призів заборонено та може призвести до заборони цієї функції.',
'claming_failed': 'Не вдалося подати претензію',
'player_on_blacklist': 'Гравець в чорному списку',
'you_can_claim_ticket': 'Ви можете отримати квиток, лише якщо ви активний гравець із регулярними ставками',
'you_already_have_free_tickets': 'У вас уже є безкоштовні квитки, будь ласка, спочатку витратите їх',
'please_play_the_game_in_portait_mode': 'Будь ласка, грайте у гру в портретному режимі.',
};
langArr.sr = {
'your_insurance_returns_you': 'Vaše osiguranje vam vraća',
'bet_insurance': 'Osiguranje Opklade',
'max_bet_is': 'Maksimalna opklada je',
'already_active_bet_of': 'i već postoji aktivna opklada',
'1_from_game_history': '1. Iz istorije igre',
'2_in_settings_window': '2. U prozoru menija igre',
'3_online_calculator': '3. Online kalkulator',
'active_tournament_1': 'Turnir u toku',
'active_tournament_2': 'nema još uvek uloga.',
'add_another_bet': 'Dodaj još jednu opkladu',
'advanced_autoplay': 'NAPREDNI AUTOPLAY',
'advanced_autoplay_for_bet': 'Napredni Autoplay',
'alien_saucer_free_bets': 'Besplatne Opklade Letećeg Tanjira',
'all_bets': 'Sve Opklade',
'all_in_your_hands': 'Sve je u vašim rukama!',
'allow': 'Dozvoli',
'at': 'za',
'austronaut_promo_credit': 'Promotivni kredit za astronaute',
'auto_play_and_autocash': 'AUTO KLAĐENJE I AUTO ISPLATA',
'autoplay': 'Autoplay',
'autoplay_and_cashout': '02. Automatska opklada i automatska isplata',
'back_to_home': 'Nazad na poč. stranicu',
'balance': 'Balans',
'bet': 'UPLATI TIKET',
'bet_amount': 'ULOG',
'bet_and_cashout': '01. Opklada i Isplata',
'bet_bets_tab': 'ULOG',
'bet_failed': 'Bet Failed',
'bet_statistic': 'Statistika Klađenja',
'bet_time': 'Vreme',
'bet_wins': 'Dob. Opklade',
'bets': 'Opklade',
'biggest_odds': 'Najveće kvote',
'bonus_type': 'TIP BONUSA',
'bonusing_history': 'Istorija Bonusa',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot dobitnici',
'but_remember_paragraph': 'Zapamtite, ako niste imali vremena da izvršite isplatu pre nego što raketa eksplodira,',
'calculate_raw_mp_to_decimal': 'Računamo neobrađeni množilac od decimale i zaokružujemo ga na najbliži ceo broj',
'cancel': 'Odustani',
'cant_post_media_content': 'Can\'t post media content frequently',
'cashed_out': 'Isplaćeno',
'cashout': 'Isplati',
'cashout_at': 'ISPLATA NA',
'cashout_before': 'Isplati pre nego što raketa eksplodira i novac je vaš!',
'change_avatar': 'Promeni Avatar',
'change_username': 'Promeni nadimak',
'characters_long': 'karaktera dug',
'chat': 'Ćaskanje',
'chat_room': 'Pričaonica',
'checking_for_spam': 'Checking for spam...',
'close': 'Zatvori',
'coeff': 'KOEF.',
'collect_win': 'Unovči Dobitak',
'combined_seed': 'Kombinovani seed',
'company': 'Kompanija',
'congratulations_you_have_won_free_bets': 'Čestitamo! Osvojili ste bonus oklade! Možete se kladiti',
'convert_first_13_to_decimal': 'Pretvaramo prvih 13 bajtova u decimalne',
'd': 'd',
'date': 'DATUM',
'date_and_time': 'Datum i Vreme',
'deny': 'Odbij',
'detailed_game_rules': 'Detaljna pravila igre',
'dissconnection_policy': 'POLITIKA POVEZIVANJA',
'easy_as_one_two_three': 'Lako kao jedan, dva, tri',
'enter_username': 'Unesite željeni nadimak',
'error_handling': 'U SLUČAJU GREŠKE',
'exit_full_screen': 'Izađi iz režima punog ekrana',
'first': 'prva',
'first_time_playing': 'Prvi put igrate "Rocketman"?',
'folow_the_three_easy_steps': 'Pratite tri laka koraka i uživajte u Rocketmanu!',
'free_bets': 'FREE BETS',
'free_bets_desc': 'Dobili ste besplatne opklade! Besplatne opklade možete iskoristiti u bilo kom trentku pritiskom na dugme start.',
'free_bets_header': 'Besplatne Opklade',
'from': 'OD',
'fullscreen_mode': 'Za bolje korisničko iskustvo igra će se pokrenuti u režimu celog ekrana.',
'function': 'Funkcija',
'game_functions': 'Funkcije igre',
'game_history': 'ISTORIJA IGRE',
'game_r_1_paragraph': 'Igrač može aktivirati opciju automatske igre gde se opklada automatski postavlja za svaki novi krug koristeći iznos opklade iz prethodne opklade.',
'game_r_10_paragraph': 'Nakon dodjeljivanja promo kredita, za bilo koju opkladu koju igrač položi, novac će se prvo oduzeti od promo kredita do njegove potrošnje, prije nego što nastavite sa podizanjem novca iz novčanika igrača.',
'game_r_11_paragraph': 'Promo krediti se ne mogu unovčiti, ali svaki dobitak na takvim opkladama pripisuje se igraču u celosti.',
'game_r_12_paragraph': 'Promo krediti imaju rok važenja 30 dana nakon čega ističu i ne mogu se koristiti.',
'game_r_13_paragraph': 'Igrač može biti nasumično nagrađen besplatnim opkladama. Na ekranu će se pojaviti skočna slika koja obaveštava igrača o dodeljenim besplatnim opkladama, broju opklada i iznosu po opkladi.',
'game_r_14_paragraph': 'Nakon što se dodele besplatne opklade, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku. Ako se igrač prekine tokom besplatnih opklada, besplatne opklade će se i dalje dešavati i oni će slediti „Politiku odvajanja“.',
'game_r_15_paragraph': 'Besplatne opklade imaju rok važenja 30 dana nakon čega ističu i ne mogu se koristiti.',
'game_r_16a_paragraph': 'Ukupni teoretski povraćaj igraču je',
'game_r_17_paragraph': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'game_r_18_paragraph': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako multiplikator dostigne 20.000k, vaša opklada će biti automatski isplaćena i krediti će biti primenjeni na vaš račun.',
'game_r_19_paragraph': 'Možete da ćaskate u igri sa drugim igračima. Unesite svoju poruku u polje za ćaskanje ili podelite svoju dobitnu opkladu sa drugim igračima.',
'game_r_2_paragraph': 'Multiplikator automatskog isplaćivanja može se uneti u polje „Autocashout“, a kada se unese, igra će se automatski isplatiti ako se dostigne postavljeni multiplikator.',
'game_r_20_paragraph': 'Igračima koji zloupotrebljavaju ćaskanje zloupotrebom drugih igrača ili korišćenjem neprikladnog i/ili lažnog jezika onemogućiće im se privilegije ćaskanja.',
'game_r_21_paragraph': 'Rocketman je dizajniran sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da onemogućite muziku i/ili zvučne efekte, to možete učiniti putem padajućeg menija.',
'game_r_22_paragraph': 'Klikom na dugme „Moje opklade“ igrač može pregledati svoje najnovije opklade. Za dalju istoriju igranja (npr. Duže periode), igrač će možda morati da pristupi istoriji igrača unutar same platforme za igre.',
'game_r_23_paragraph': 'U slučaju greške runde možda neće početi na vreme. U tom slučaju naš tim za podršku će pokušati rešiti problem što je pre moguće i nastaviti sa redovnim rundama igre. U slučaju problema sa samim krugom igre, sve opklade će biti poništene, a iznos uloga uložen igračima.',
'game_r_24_paragraph': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani. ',
'game_r_3_paragraph': 'Igrač može da koristi prečice za brzo klađenje ili isplatu u igri. Ključna mapiranja su sledeća',
'game_r_4_paragraph': '1% od svakog uloga ide u fond za progresivni džekpot. Taj fond služi za sistem progresivnog džekpota',
'game_r_5_paragraph': ' • Džekpot - najviši nivo, najveća nagrada, najmanje učestala',
'game_r_6_paragraph': ' • Rocketpot - Srednji nivo, srednja nagrada',
'game_r_7_paragraph': ' • Boosterpot - Najniži nivo, niska nagrada, najčešći',
'game_r_8_paragraph': 'Svi nivoi džekpota se dodeljuju pomoću mehanike "Misteri" ili "must-hit-bi". Vrednost svakog džekpota "mora se udariti" određuje se odmah nakon što prethodni džekpot osvoji generator slučajnih brojeva i uskladišti kao šifrovana vrednost u udaljenoj bazi podataka. Vrednost je šifrovana, pa je stoga skrivena i nije poznata. Džekpot dobija prvi igrač čiji ulog uzrokuje da džekpot pređe misterioznu vrednost. Dobitnik džekpota se objavljuje po završetku runde.',
'game_r_8a_paragraph': 'Napomena: Dobitnik džekpota može biti samo jedan igrač Rocketmana koji aktivno igra u rundi kada je džekpot aktiviran. Ne postoji mogućnost da bi igrač, ako je osvojio džekpot, morao da ga podeli sa bilo kojim drugim igračem Rocketmana.',
'game_r_9_paragraph': 'Igrač može biti nasumično nagrađen promo kreditima. Na ekranu će se pojaviti skočna slika koja obaveštava igrača o dodeljenim promo kreditima i njihovom iznosu.',
'game_round': 'rundi!',
'game_rules': 'Pravila Igre',
'game_rules_1_paragraph': 'Rocketman je kockarska zabava nove generacije. Osvojite mnogo puta više u sekundi! Rocketmanis izgrađen na',
'game_rules_10_paragraph': ' • Automatska opklada se aktivira na kartici „Auto“ na tabli za klađenje, potvrđivanjem polja za potvrdu „Auto Uplata Tiketa“. Nakon aktiviranja, opklade će se postavljati automatski, ali za isplate treba da pritisnete dugme „isplata“ u svakoj rundi. Ako',
'game_rules_11_paragraph': '• Automatska isplata je dostupna na kartici „Auto“ na tabli. Nakon aktiviranja, vaša oklada će biti automatski unovčena kada dostigne uneti koeficijent.',
'game_rules_2_paragraph': ', što je trenutno jedina stvarna garancija poštenja u industriji kockanja.',
'game_rules_3_paragraph': '• Multiplikator pobeda počinje sa 1k i raste sve više i više kako Srećna Letelica poleće.',
'game_rules_4_paragraph': ' • Vaš dobitak je multiplikator pri kojem ste izvršili isplatu pomnoženi sa vašom opkladom. ',
'game_rules_5_paragraph': '• Pre početka svakog kruga, naš dokazno fer generator slučajnih brojeva generiše koeficijent pri kojem će Srećna Raketa odleteti. Iskrenost ove generacije možete proveriti klikom na suprotni rezultat na kartici Istorija.',
'game_rules_6_paragraph': '• Izaberite iznos i pritisnite dugme „UPLATI TIKET“ da biste se kladili. ',
'game_rules_7_paragraph': '• Možete istovremeno da napravite dve opklade dodavanjem drugog panela za uloge. Da biste dodali drugi panel za opkladu, pritisnite ikonu plus, koja se nalazi u gornjem desnom uglu prvog okna za klađenje.',
'game_rules_8_paragraph': ' • Pritisnite dugme „Isplata“ da biste unovčili svoj dobitak. Vaš dobitak se množi sa koeficijentom isplate.',
'game_rules_9_paragraph': '• Vaša opklada je izgubljena ako niste unovčili pre nego što raketa odleti.',
'general_rules': 'OPŠTA PRAVILA',
'gifs': 'GIFOVI',
'go_back': 'Vrati Se Nazad',
'good_job_buddy': 'Bravo majstore, samo tako nastavi',
'h': 's',
'hashed_in_sha_512': 'Heširano u SHA512',
'hex': 'Hex',
'hey_username': 'Hej',
'hide_hex_to_decimal': 'Sakrij heksadecimalne konverzije u decimalne',
'highscore': 'High Score',
'how_it_works': 'Kako to radi ?',
'how_to_check': 'Kako proveriti ?',
'how_to_play': 'Kako igrati',
'if_the_maximum_multiplier': '• Ako je dostignut maksimalni multiplikator od 20.000, sve otvorene opklade se automatski unovčuju na maksimalnom množitelju',
'increase': 'Uvećaj',
'input_bet_amount': 'Unesite iznos opklade i stavite opkladu pre poletanja.',
'insufficient_funds': 'Nedovoljno sredstava',
'it_blew_up': 'Idemo dalje 🚀',
'jackpot': 'Džekpot',
'jackpot_winners': 'Jackpot dobitnici',
'key': 'Taster',
'load_more': 'UČITAJ JOŠ',
'loading': 'Učitavanje',
'loading_next_round': 'Učitavanje sledeće runde....',
'loss_gifs': 'IZGUBITI',
'lost_connection': 'Lost Connection',
'make_a_bet': 'Opkladi se',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'maximum_payout': 'Maksimalna isplata je ',
'menu': 'Meni',
'min_bet_is': 'Min bet is',
'month': 'Mesec',
'more_details': 'Detaljnije',
'more_information': 'Više informacija',
'multiplier': 'Množilac',
'multiplier_starts': '• Množilac počinje od 1 i ide do 20.000',
'multipliers_larger': 'Množioci veži od x20.000 se limitiraju na maksimalnu vrednost množioca',
'music_settings': 'Pozadinska Muzika',
'my_bet_history': 'Moja istorija opklada',
'my_bets': 'Moje Opklade',
'my_bonysing_history': 'Moja Istorija Bonusa',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_content': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'network_latency_title': 'KAŠNJENJA U MREŽI',
'no_thanks': 'Ne, hvala!',
'not_right_now': 'Ne sada',
'off': 'isklj.',
'oh_snap': 'Gotova runda.',
'ok_close': 'Okay, Zatvori!',
'ok_thanks': 'U redu, Hvala!',
'on': 'uklj.',
'on_loss': 'NA GUBITAK',
'on_win': 'NA DOBITAK',
'online': 'Na Mreži',
'per_bet': 'po opkladi',
'pick_your_avatar_img': 'Izaberi avatar',
'place_bet_with_last': 'Kupi tiket sa poslednjim odabranim ulogom',
'play_now': 'Igraj',
'player_1_seed': 'Igrač 1 seed',
'player_2_seed': 'Igrač 2 seed',
'player_3_seed': 'Igrač 3 seed',
'please_wait_active_bets': 'Molimo sacekajte da se zavrse trenutni betovi kako bi ste aktivirali besplatne opklade',
'points': 'POENI',
'powered_by': 'Pokreće',
'previous_hand': 'Prethodna Runda',
'prize': 'NAGRADA',
'prizes': 'nagrade',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'promo_credit': 'Promo Kredit',
'promo_credit_condradulations': 'Čestitamo! Osvojili ste astronomski dobar Promo kredit koji možete koristiti u igri!',
'promo_credits': 'PROMO KREDITI',
'promo_history': 'Promo History',
'provably_fair': 'Dokazano pošteno',
'provably_fair_1_paragraph': '„Rocketman“ je zasnovan na kriptografskoj tehnologiji nazvanoj „Provably Fair“. Ova tehnologija garantuje 100% pravičnost rezultata igre. Pomoću ove tehnologije nije moguće da se bilo koja treća strana umeša u proces igre.',
'provably_fair_2_paragraph': 'Rezultat svake runde (koeficijent „Odleti“ igre) se ne generiše na našim serverima. Generiše se uz pomoć igrača po rundi i potpuno je transparentan. Na ovaj način je nemoguće da bilo ko manipuliše izlazom igre. Takođe, svako može da proveri i potvrdi pr',
'provably_fair_3_paragraph': 'Rezultat runde generiše se od četiri nezavisna učesnika runde',
'provably_fair_4_paragraph': 'Kada runda počne, igra spaja seme servera sa tri semena klijenta. Iz spojenih simbola generiše se SHA512 heš, a iz ovog heša - rezultat igre.',
'provably_fair_5_paragraph': 'Možete proveriti pravičnost svakog kruga iz istorije igara, klikom na zelenu ikonu. U otvorenom prozoru videćete seme servera, 3 para igrača semena, kombinovani heš i okrugli rezultat.',
'provably_fair_6_paragraph': 'Heširana verzija sledećeg kruga semena servera je javno dostupna u prozoru podešavanja (u korisničkom meniju izberite „Provably Fair Settings“, a zatim „Nekt server seed SHA256“). Ovde takođe možete promeniti seme klijenta. Ako želite da učestvujete u ge',
'provably_fair_7_paragraph': 'Ispravnost heširanja može se proveriti u bilo kom mrežnom kalkulatoru, na primer',
'provably_fair_system': 'provably fair sistemu',
'quick_explanation': 'Brzo objašnjenje',
'randomly_generated_server_hash': 'Nasumično generisani HASH servera',
'rank': 'RANK',
'raw_multiplier': 'neobrađeni množilac',
'raw_multiplier_to_edged': 'Neobrađeni množilac do ivičnog množitelja, koristeći',
'remove_additional_bet': 'Ukloni dodatnu opkladu',
'reset': 'Reset',
'return_to_player': 'POVRAĆAJ IGRAČU',
'rocketman_is_pure_excitement': 'Rocketman je čisto uzbuđenje! Rizikujte i pobedite.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot dobitnici',
'round': 'Igra',
'round_id': 'Broj runde',
'round_multiplier': 'Množilac Runde',
'round_results': 'rezultati runde',
'rounds': 'igara',
'rtp': 'RTP',
'rules': 'pravila',
'rules_details': 'Pravila turnira',
'save': 'Sačuvaj',
'schedule': 'raspored',
'second': 'druga',
'seconds_left': 's left',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'MOLIM VAS SAČEKAJTE DA SE AKTUELNE OKLADE ZAVRŠE',
'min_autocashout_value_must_be': 'MINIMALNA AUTOCASHOUT VREDNOST MORA BITI',
'min_bet_value_must_be': 'MINIMALNA VREDNOST OPKLADE MORA BITI',
'server_seed': 'Server Seed',
'session_dissconnected': 'Session Disconnected',
'multisession_not_allowed': 'Multisesija nije dozvoljena',
'set_auto_bet': 'AUTO UPLATA TIKETA',
'set_auto_cashout': 'IZNOS AUTO ISPLATE (Koef.)',
'shourtcut_keys': 'PREČICE NA TASTATURI',
'show_hex_to_deimal': 'Prikaži heksadecimalnu konverziju u decimale',
'sound': 'ZVUK',
'sound_settigs': 'Podešavanja zvuka',
'start_with_autoplay': 'Pokreni autoplay',
'stop_autoplay': 'STOPIRAJ AUTOPLAY',
'stop_on_loss': 'STOP NA GUBITAK',
'stop_on_loss_tooltip': 'STOP NA GUBITAK će stopirati autoplay nakon što se kredit igrača umanji za ovaj iznos.<br/>Na primer, ukoliko počnete sa 1000 RSD i stavite STOP NA GUBITAK na 300 RSD<br/>ukoliko se vaš kredit umanji više od 300 RSD, autoplay će se zaustaviti.',
'stop_on_profit': 'STOP NA PROFIT',
'stop_on_profit_tooltip': 'STOP NA PROFIT će stopirati autoplay nakon što se kredit igrača uveća za ovaj iznos.<br/>Na primer, ukoliko počnete sa 100 RSD i stavite STOP NA PROFIT na 500 RSD<br/>ukoliko se vaš kredit uveća na više od 500 RSD, autoplay će se zaustaviti.',
'there_are_no_active_torunament': 'Nema aktivnih turnira u ovom momentu',
'third': 'treca',
'this_game_is': 'Ova igra je',
'this_tool_recalculates': 'Ovaj alat ponovo izračunava množilac iz heš algoritma igre. Možete kopirati/nalepiti bilo koji algoritam heš igre da biste proverili množilac.',
'time': 'VREME',
'time_frame': 'Za Period',
'to': 'DO',
'toggle_animation': 'Uklj/Isklj Animaciju',
'top_odds': 'Najveći Koef.',
'top_wins': 'Najveći Dobici',
'total_bets': 'Ukupno Opklada',
'tournament': 'Turnir',
'tournament_information': 'Informacije o turniru',
'try_advanced_autoplay': 'Probajte novu opciju automatskog igranja!',
'uncredited_wins': 'Nedodeljen kredit',
'user': 'KORISNIK',
'user_and_points': 'IGRAČ & POENI',
'username': 'Nadimak',
'username_must_be_at_least': 'Nadimak mora biti bar',
'verify': 'Provera',
'vip_popup_text': 'Sada možete odabrati neki od VIP avatara koji su dostupni isključivo VIP igračima.',
'vip_popup_title': 'Čestitamo, postali ste VIP igrač!',
'waiting_for_the_next_round': 'Čeka se sledeća runda...',
'warnings_are_calculated': ' • Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na Isplati dugme',
'watch_take_off': 'Gledajte kako vaša Srećna Raketa poleće i vaši dobici se povećavaju.',
'watch_the_rocket': 'Pratite Raketu',
'we_have_noticed': '`Primetili smo da ste prvi put otvorili igru "Rocketman". Da li želite da Vam pokažemo kratak video i objasnimo kako se igra "Rocketman"?`',
'week': 'Nedelja',
'welcome_to_rocketman': 'Dobrodošli u Rocketman - novu i uzbudljivu igru! Sa Rocketman -om imate šansu da osvojite do 20.000k vaš originalni ulog.',
'what_is_provably_fair': 'Šta je "Provably Fair" ?',
'will_be_deposited': 'Biće kreditirano na balans igrača sa prvim regularnim dobitkom',
'win': 'DOBITAK',
'win_amount': 'Dobitak',
'win_gifs': 'POBEDITI',
'winner': 'dobitnik',
'wins': 'je dobio',
'write_a_replay': 'Odgovori ...',
'year': 'Godina',
'yes': 'DA',
'yes_start_the_round': 'Da, započni rundu',
'you_have_cashed_out': 'Isplatili ste, vaš dobitak je ',
'you_have_won': 'DOBILI STE',
'your_bet_is_lost': 'vaša opklada je izgubljena.',
'current': 'Trenutni',
'previous': 'Prethodni',
'claim': 'Prihvati',
'this_feature_will_give': 'Ova funkcija će dati odabrani iznos nasumičnim korisnicima u ćaskanju',
'amount_per_player': 'Iznos po igraču',
'no_of_players': 'Broj igrača',
'meteor_shower': 'Meteorska kiša',
'claim_your_free_bet': 'Preuzmite svoju besplatnu opkladu',
'meteor_shower_total': 'Kiša meteora Total',
'shower': 'KIŠA',
'total': 'Ukupno',
'meteor_shower_incoming': 'Kiša meteora stiže, pogledajte chat!',
'insurance': 'OSIGURANJE',
'insurance_paragraph': 'Igrači mogu da kupe osiguranje za svoju opkladu klikom na polje za potvrdu „Osiguranje klađenja“ pored dugmeta za opkladu. Osiguranje košta 10% od ukupnog iznosa opklade i vratiće iznos opklade igraču ako raketa eksplodira sa množiocem od 1,00.',
'insure_your_bet': 'Osigurajte svoju opkladu',
'bet_insurance_promo_text': 'Izaberite opciju „Osiguranje opklade“ da biste osigurali svoju opkladu od pada rakete sa množinom od 1,00.',
'bet_insurance_promo_text_helper': 'Pročitajte više u odeljku "Pravila igre"...',
'game_explanation_1': 'Izaberite svoj množilac, i ako množilac igre premaši Vaš izabrani, Vaš dobitak će biti',
'game_explanation_2': 'Vaš množilac X Vaša opklada',
'retail_max_mp_1': 'Osvojite do',
'retail_max_mp_2': 'tvoje opklade!',
'play_multibets': 'Igraj',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS IGRA',
'all_wins_doubled': 'Svi dobici su udvostručeni!',
'multibets_paragraph_1': 'da povećate svoje šanse!',
'multibets_paragraph_2': 'Možete kombinovati opklade na do deset rundi u jednu multibet i osvojiti ogromne isplate!',
'multibets_paragraph_3': 'Svaki put kada se dobije runda, dobitna dividenda iz te runde se kladi u sledećoj rundi, sve dok sve runde ne budu pobedničke.',
'bonus_launch': 'Osvojite X2 na svim opkladama u rundi „Bonus uzletanje“!',
'multiplier_history': 'Istorija multiplikatora',
'will_start_soon': 'uskoro će početi',
'hurry_up_and_place_your_bets': 'Požurite i uložite svoje opklade!',
'active_bets': 'Aktivne opklade',
'shop': 'POSLOVNICA',
'game_meteor_shower_paragraph': 'Meteorska Kiša dodeljuje jednu besplatnu opkladu unapred definisane vrednosti unapred definisanom broju igrača. Meteorska Kiša može biti pokrenuta od strane servera ili od strane igrača. Kada se Meteorska Kiša aktivira, poruka se pojavljuje u igri, usmeravajući korisnike da pogledaju chat gde se nalazi poruka o Meteorskoj Kiši. Igrači mogu preuzeti besplatnu opkladu klikom na dugme „Preuzmi“. Besplatne opklade se dodeljuju igračima koji prvi kliknu na dugme „Preuzmi“. Upotreba automatskih klikera ili bilo kog softvera za automatizaciju koji emulira klikove igrača radi preuzimanja nagrada je zabranjena i može rezultirati zabranom korišćenja ove funkcije.',
'claming_failed': 'Preuzimanje nije uspelo',
'player_on_blacklist': 'Igrač je na crnoj listi',
'you_can_claim_ticket': 'Možete preuzeti tiket samo ako ste aktivni igrač sa redovnim opkladama',
'you_already_have_free_tickets': 'Već imate besplatne tikete, prvo ih potrošite',
'please_play_the_game_in_portait_mode': 'Molim vas igrajte u portret modu.',
};
langArr.rs = {
'your_insurance_returns_you': 'Vaše osiguranje vam vraća',
'bet_insurance': 'Osiguranje Opklade',
'max_bet_is': 'Maksimalna opklada je',
'already_active_bet_of': 'i već postoji aktivna opklada',
'1_from_game_history': '1. Iz istorije igre',
'2_in_settings_window': '2. U prozoru menija igre',
'3_online_calculator': '3. Online kalkulator',
'active_tournament_1': 'Turnir u toku',
'active_tournament_2': 'nema još uvek uloga.',
'add_another_bet': 'Dodaj još jednu opkladu',
'advanced_autoplay': 'NAPREDNI AUTOPLAY',
'advanced_autoplay_for_bet': 'Napredni Autoplay',
'alien_saucer_free_bets': 'Besplatne Opklade Letećeg Tanjira',
'all_bets': 'Sve Opklade',
'all_in_your_hands': 'Sve je u vašim rukama!',
'allow': 'Dozvoli',
'at': 'za',
'austronaut_promo_credit': 'Promotivni kredit za astronaute',
'auto_play_and_autocash': 'AUTO KLAĐENJE I AUTO ISPLATA',
'autoplay': 'Autoplay',
'autoplay_and_cashout': '02. Automatska opklada i automatska isplata',
'back_to_home': 'Nazad na poč. stranicu',
'balance': 'Balans',
'bet': 'UPLATI TIKET',
'bet_amount': 'ULOG',
'bet_and_cashout': '01. Opklada i Isplata',
'bet_bets_tab': 'ULOG',
'bet_failed': 'Bet Failed',
'bet_statistic': 'Statistika Klađenja',
'bet_time': 'Vreme',
'bet_wins': 'Dob. Opklade',
'bets': 'Opklade',
'biggest_odds': 'Najveće kvote',
'bonus_type': 'TIP BONUSA',
'bonusing_history': 'Istorija Bonusa',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot dobitnici',
'but_remember_paragraph': 'Zapamtite, ako niste imali vremena da izvršite isplatu pre nego što raketa eksplodira,',
'calculate_raw_mp_to_decimal': 'Računamo neobrađeni množilac od decimale i zaokružujemo ga na najbliži ceo broj',
'cancel': 'Odustani',
'cant_post_media_content': 'Can\'t post media content frequently',
'cashed_out': 'Isplaćeno',
'cashout': 'Isplati',
'cashout_at': 'ISPLATA NA',
'cashout_before': 'Isplati pre nego što raketa eksplodira i novac je vaš!',
'change_avatar': 'Promeni Avatar',
'change_username': 'Promeni nadimak',
'characters_long': 'karaktera dug',
'chat': 'Ćaskanje',
'chat_room': 'Pričaonica',
'checking_for_spam': 'Checking for spam...',
'close': 'Zatvori',
'coeff': 'KOEF.',
'collect_win': 'Unovči Dobitak',
'combined_seed': 'Kombinovani seed',
'company': 'Kompanija',
'congratulations_you_have_won_free_bets': 'Čestitamo! Osvojili ste bonus oklade! Možete se kladiti',
'convert_first_13_to_decimal': 'Pretvaramo prvih 13 bajtova u decimalne',
'd': 'd',
'date': 'DATUM',
'date_and_time': 'Datum i Vreme',
'deny': 'Odbij',
'detailed_game_rules': 'Detaljna pravila igre',
'dissconnection_policy': 'POLITIKA POVEZIVANJA',
'easy_as_one_two_three': 'Lako kao jedan, dva, tri',
'enter_username': 'Unesite željeni nadimak',
'error_handling': 'U SLUČAJU GREŠKE',
'exit_full_screen': 'Izađi iz režima punog ekrana',
'first': 'prva',
'first_time_playing': 'Prvi put igrate "Rocketman"?',
'folow_the_three_easy_steps': 'Pratite tri laka koraka i uživajte u Rocketmanu!',
'free_bets': 'FREE BETS',
'free_bets_desc': 'Dobili ste besplatne opklade! Besplatne opklade možete iskoristiti u bilo kom trentku pritiskom na dugme start.',
'free_bets_header': 'Besplatne Opklade',
'from': 'OD',
'fullscreen_mode': 'Za bolje korisničko iskustvo igra će se pokrenuti u režimu celog ekrana.',
'function': 'Funkcija',
'game_functions': 'Funkcije igre',
'game_history': 'ISTORIJA IGRE',
'game_r_1_paragraph': 'Igrač može aktivirati opciju automatske igre gde se opklada automatski postavlja za svaki novi krug koristeći iznos opklade iz prethodne opklade.',
'game_r_10_paragraph': 'Nakon dodjeljivanja promo kredita, za bilo koju opkladu koju igrač položi, novac će se prvo oduzeti od promo kredita do njegove potrošnje, prije nego što nastavite sa podizanjem novca iz novčanika igrača.',
'game_r_11_paragraph': 'Promo krediti se ne mogu unovčiti, ali svaki dobitak na takvim opkladama pripisuje se igraču u celosti.',
'game_r_12_paragraph': 'Promo krediti imaju rok važenja 30 dana nakon čega ističu i ne mogu se koristiti.',
'game_r_13_paragraph': 'Igrač može biti nasumično nagrađen besplatnim opkladama. Na ekranu će se pojaviti skočna slika koja obaveštava igrača o dodeljenim besplatnim opkladama, broju opklada i iznosu po opkladi.',
'game_r_14_paragraph': 'Nakon što se dodele besplatne opklade, igrač može izabrati kada počinje besplatnu rundu opklade. Nakon što započne runda besplatnih opklada, postavlja se automatska opklada sa navedenim ulogom, a na igraču je da isplati u svakom trenutku. Ako se igrač prekine tokom besplatnih opklada, besplatne opklade će se i dalje dešavati i oni će slediti „Politiku odvajanja“.',
'game_r_15_paragraph': 'Besplatne opklade imaju rok važenja 30 dana nakon čega ističu i ne mogu se koristiti.',
'game_r_16a_paragraph': 'Ukupni teoretski povraćaj igraču je',
'game_r_17_paragraph': 'Procente RTP -a stalno prate i verifikuju nezavisne treće strane.',
'game_r_18_paragraph': 'Ako se veza prekine nakon postavljanja opklade, opklada se neće poništiti. Ako multiplikator dostigne 20.000k, vaša opklada će biti automatski isplaćena i krediti će biti primenjeni na vaš račun.',
'game_r_19_paragraph': 'Možete da ćaskate u igri sa drugim igračima. Unesite svoju poruku u polje za ćaskanje ili podelite svoju dobitnu opkladu sa drugim igračima.',
'game_r_2_paragraph': 'Multiplikator automatskog isplaćivanja može se uneti u polje „Autocashout“, a kada se unese, igra će se automatski isplatiti ako se dostigne postavljeni multiplikator.',
'game_r_20_paragraph': 'Igračima koji zloupotrebljavaju ćaskanje zloupotrebom drugih igrača ili korišćenjem neprikladnog i/ili lažnog jezika onemogućiće im se privilegije ćaskanja.',
'game_r_21_paragraph': 'Rocketman je dizajniran sa prelepom muzikom u pozadini i zvučnim efektima. Ako želite da onemogućite muziku i/ili zvučne efekte, to možete učiniti putem padajućeg menija.',
'game_r_22_paragraph': 'Klikom na dugme „Moje opklade“ igrač može pregledati svoje najnovije opklade. Za dalju istoriju igranja (npr. Duže periode), igrač će možda morati da pristupi istoriji igrača unutar same platforme za igre.',
'game_r_23_paragraph': 'U slučaju greške runde možda neće početi na vreme. U tom slučaju naš tim za podršku će pokušati rešiti problem što je pre moguće i nastaviti sa redovnim rundama igre. U slučaju problema sa samim krugom igre, sve opklade će biti poništene, a iznos uloga uložen igračima.',
'game_r_24_paragraph': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani. ',
'game_r_3_paragraph': 'Igrač može da koristi prečice za brzo klađenje ili isplatu u igri. Ključna mapiranja su sledeća',
'game_r_4_paragraph': '1% od svakog uloga ide u fond za progresivni džekpot. Taj fond služi za sistem progresivnog džekpota',
'game_r_5_paragraph': ' • Džekpot - najviši nivo, najveća nagrada, najmanje učestala',
'game_r_6_paragraph': ' • Rocketpot - Srednji nivo, srednja nagrada',
'game_r_7_paragraph': ' • Boosterpot - Najniži nivo, niska nagrada, najčešći',
'game_r_8_paragraph': 'Svi nivoi džekpota se dodeljuju pomoću mehanike "Misteri" ili "must-hit-bi". Vrednost svakog džekpota "mora se udariti" određuje se odmah nakon što prethodni džekpot osvoji generator slučajnih brojeva i uskladišti kao šifrovana vrednost u udaljenoj bazi podataka. Vrednost je šifrovana, pa je stoga skrivena i nije poznata. Džekpot dobija prvi igrač čiji ulog uzrokuje da džekpot pređe misterioznu vrednost. Dobitnik džekpota se objavljuje po završetku runde.',
'game_r_8a_paragraph': 'Napomena: Dobitnik džekpota može biti samo jedan igrač Rocketmana koji aktivno igra u rundi kada je džekpot aktiviran. Ne postoji mogućnost da bi igrač, ako je osvojio džekpot, morao da ga podeli sa bilo kojim drugim igračem Rocketmana.',
'game_r_9_paragraph': 'Igrač može biti nasumično nagrađen promo kreditima. Na ekranu će se pojaviti skočna slika koja obaveštava igrača o dodeljenim promo kreditima i njihovom iznosu.',
'game_round': 'rundi!',
'game_rules': 'Pravila Igre',
'game_rules_1_paragraph': 'Rocketman je kockarska zabava nove generacije. Osvojite mnogo puta više u sekundi! Rocketmanis izgrađen na',
'game_rules_10_paragraph': ' • Automatska opklada se aktivira na kartici „Auto“ na tabli za klađenje, potvrđivanjem polja za potvrdu „Auto Uplata Tiketa“. Nakon aktiviranja, opklade će se postavljati automatski, ali za isplate treba da pritisnete dugme „isplata“ u svakoj rundi. Ako',
'game_rules_11_paragraph': '• Automatska isplata je dostupna na kartici „Auto“ na tabli. Nakon aktiviranja, vaša oklada će biti automatski unovčena kada dostigne uneti koeficijent.',
'game_rules_2_paragraph': ', što je trenutno jedina stvarna garancija poštenja u industriji kockanja.',
'game_rules_3_paragraph': '• Multiplikator pobeda počinje sa 1k i raste sve više i više kako Srećna Letelica poleće.',
'game_rules_4_paragraph': ' • Vaš dobitak je multiplikator pri kojem ste izvršili isplatu pomnoženi sa vašom opkladom. ',
'game_rules_5_paragraph': '• Pre početka svakog kruga, naš dokazno fer generator slučajnih brojeva generiše koeficijent pri kojem će Srećna Raketa odleteti. Iskrenost ove generacije možete proveriti klikom na suprotni rezultat na kartici Istorija.',
'game_rules_6_paragraph': '• Izaberite iznos i pritisnite dugme „UPLATI TIKET“ da biste se kladili. ',
'game_rules_7_paragraph': '• Možete istovremeno da napravite dve opklade dodavanjem drugog panela za uloge. Da biste dodali drugi panel za opkladu, pritisnite ikonu plus, koja se nalazi u gornjem desnom uglu prvog okna za klađenje.',
'game_rules_8_paragraph': ' • Pritisnite dugme „Isplata“ da biste unovčili svoj dobitak. Vaš dobitak se množi sa koeficijentom isplate.',
'game_rules_9_paragraph': '• Vaša opklada je izgubljena ako niste unovčili pre nego što raketa odleti.',
'general_rules': 'OPŠTA PRAVILA',
'gifs': 'GIFOVI',
'go_back': 'Vrati Se Nazad',
'good_job_buddy': 'Bravo majstore, samo tako nastavi',
'h': 's',
'hashed_in_sha_512': 'Heširano u SHA512',
'hex': 'Hex',
'hey_username': 'Hej',
'hide_hex_to_decimal': 'Sakrij heksadecimalne konverzije u decimalne',
'highscore': 'High Score',
'how_it_works': 'Kako to radi ?',
'how_to_check': 'Kako proveriti ?',
'how_to_play': 'Kako igrati',
'if_the_maximum_multiplier': '• Ako je dostignut maksimalni multiplikator od 20.000, sve otvorene opklade se automatski unovčuju na maksimalnom množitelju',
'increase': 'Uvećaj',
'input_bet_amount': 'Unesite iznos opklade i stavite opkladu pre poletanja.',
'insufficient_funds': 'Nedovoljno sredstava',
'it_blew_up': 'Idemo dalje 🚀',
'jackpot': 'Džekpot',
'jackpot_winners': 'Jackpot dobitnici',
'key': 'Taster',
'load_more': 'UČITAJ JOŠ',
'loading': 'Učitavanje',
'loading_next_round': 'Učitavanje sledeće runde....',
'loss_gifs': 'IZGUBITI',
'lost_connection': 'Lost Connection',
'make_a_bet': 'Opkladi se',
'malfunction_voids': 'Neispravnost u radu poništava sve isplate i igre.',
'maximum_payout': 'Maksimalna isplata je ',
'menu': 'Meni',
'min_bet_is': 'Min bet is',
'month': 'Mesec',
'more_details': 'Detaljnije',
'more_information': 'Više informacija',
'multiplier': 'Množilac',
'multiplier_starts': '• Množilac počinje od 1 i ide do 20.000',
'multipliers_larger': 'Množioci veći od x20.000 se limitiraju na maksimalnu vrednost množioca',
'music_settings': 'Pozadinska Muzika',
'my_bet_history': 'Moja istorija opklada',
'my_bets': 'Moje Opklade',
'my_bonysing_history': 'Moja Istorija Bonusa',
'network_latency': 'KAŠNJENJA U MREŽI',
'network_latency_content': 'Kašnjenja prilikom prenosa podataka u mreži su sastavni deo funkcionisanja interneta. Akcije Uplate i Isplate su validne onoga trenutka kada ti zahtevi stignu na server, bez obzira na to kada je igrač inicirao akciju na svojoj strani.',
'network_latency_title': 'KAŠNJENJA U MREŽI',
'no_thanks': 'Ne, hvala!',
'not_right_now': 'Ne sada',
'off': 'isklj.',
'oh_snap': 'Gotova runda.',
'ok_close': 'Okay, Zatvori!',
'ok_thanks': 'U redu, Hvala!',
'on': 'uklj.',
'on_loss': 'NA GUBITAK',
'on_win': 'NA DOBITAK',
'online': 'Na Mreži',
'per_bet': 'po opkladi',
'pick_your_avatar_img': 'Izaberi avatar',
'place_bet_with_last': 'Kupi tiket sa poslednjim odabranim ulogom',
'play_now': 'Igraj',
'player_1_seed': 'Igrač 1 seed',
'player_2_seed': 'Igrač 2 seed',
'player_3_seed': 'Igrač 3 seed',
'please_wait_active_bets': 'Molimo sacekajte da se zavrse trenutni betovi kako bi ste aktivirali besplatne opklade',
'points': 'POENI',
'powered_by': 'Pokreće',
'previous_hand': 'Prethodna Runda',
'prize': 'NAGRADA',
'prizes': 'nagrade',
'progressive_jackpot': 'PROGRESIVNI DŽEKPOT',
'promo_credit': 'Promo Kredit',
'promo_credit_condradulations': 'Čestitamo! Osvojili ste astronomski dobar Promo kredit koji možete koristiti u igri!',
'promo_credits': 'PROMO KREDITI',
'promo_history': 'Promo History',
'provably_fair': 'Dokazano pošteno',
'provably_fair_1_paragraph': '„Rocketman“ je zasnovan na kriptografskoj tehnologiji nazvanoj „Provably Fair“. Ova tehnologija garantuje 100% pravičnost rezultata igre. Pomoću ove tehnologije nije moguće da se bilo koja treća strana umeša u proces igre.',
'provably_fair_2_paragraph': 'Rezultat svake runde (koeficijent „Odleti“ igre) se ne generiše na našim serverima. Generiše se uz pomoć igrača po rundi i potpuno je transparentan. Na ovaj način je nemoguće da bilo ko manipuliše izlazom igre. Takođe, svako može da proveri i potvrdi pr',
'provably_fair_3_paragraph': 'Rezultat runde generiše se od četiri nezavisna učesnika runde',
'provably_fair_4_paragraph': 'Kada runda počne, igra spaja seme servera sa tri semena klijenta. Iz spojenih simbola generiše se SHA512 heš, a iz ovog heša - rezultat igre.',
'provably_fair_5_paragraph': 'Možete proveriti pravičnost svakog kruga iz istorije igara, klikom na zelenu ikonu. U otvorenom prozoru videćete seme servera, 3 para igrača semena, kombinovani heš i okrugli rezultat.',
'provably_fair_6_paragraph': 'Heširana verzija sledećeg kruga semena servera je javno dostupna u prozoru podešavanja (u korisničkom meniju izberite „Provably Fair Settings“, a zatim „Nekt server seed SHA256“). Ovde takođe možete promeniti seme klijenta. Ako želite da učestvujete u ge',
'provably_fair_7_paragraph': 'Ispravnost heširanja može se proveriti u bilo kom mrežnom kalkulatoru, na primer',
'provably_fair_system': 'provably fair sistemu',
'quick_explanation': 'Brzo objašnjenje',
'randomly_generated_server_hash': 'Nasumično generisani HASH servera',
'rank': 'RANK',
'raw_multiplier': 'neobrađeni množilac',
'raw_multiplier_to_edged': 'Neobrađeni množilac do ivičnog množitelja, koristeći',
'remove_additional_bet': 'Ukloni dodatnu opkladu',
'reset': 'Reset',
'return_to_player': 'POVRAĆAJ IGRAČU',
'rocketman_is_pure_excitement': 'Rocketman je čisto uzbuđenje! Rizikujte i pobedite.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot dobitnici',
'round': 'Igra',
'round_id': 'Broj runde',
'round_multiplier': 'Množilac Runde',
'round_results': 'rezultati runde',
'rounds': 'igara',
'rtp': 'RTP',
'rules': 'pravila',
'rules_details': 'Pravila turnira',
'save': 'Sačuvaj',
'schedule': 'raspored',
'second': 'druga',
'seconds_left': 's left',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'MOLIM VAS SAČEKAJTE DA SE AKTUELNE OKLADE ZAVRŠE',
'min_autocashout_value_must_be': 'MINIMALNA AUTOCASHOUT VREDNOST MORA BITI',
'min_bet_value_must_be': 'MINIMALNA VREDNOST OPKLADE MORA BITI',
'server_seed': 'Server Seed',
'session_dissconnected': 'Session Disconnected',
'multisession_not_allowed': 'Multisesija nije dozvoljena',
'set_auto_bet': 'AUTO UPLATA TIKETA',
'set_auto_cashout': 'IZNOS AUTO ISPLATE (Koef.)',
'shourtcut_keys': 'PREČICE NA TASTATURI',
'show_hex_to_deimal': 'Prikaži heksadecimalnu konverziju u decimale',
'sound': 'ZVUK',
'sound_settigs': 'Podešavanja zvuka',
'start_with_autoplay': 'Pokreni autoplay',
'stop_autoplay': 'STOPIRAJ AUTOPLAY',
'stop_on_loss': 'STOP NA GUBITAK',
'stop_on_loss_tooltip': 'STOP NA GUBITAK će stopirati autoplay nakon što se kredit igrača umanji za ovaj iznos.<br/>Na primer, ukoliko počnete sa 1000 RSD i stavite STOP NA GUBITAK na 300 RSD<br/>ukoliko se vaš kredit umanji više od 300 RSD, autoplay će se zaustaviti.',
'stop_on_profit': 'STOP NA PROFIT',
'stop_on_profit_tooltip': 'STOP NA PROFIT će stopirati autoplay nakon što se kredit igrača uveća za ovaj iznos.<br/>Na primer, ukoliko počnete sa 100 RSD i stavite STOP NA PROFIT na 500 RSD<br/>ukoliko se vaš kredit uveća na više od 500 RSD, autoplay će se zaustaviti.',
'there_are_no_active_torunament': 'Nema aktivnih turnira u ovom momentu',
'third': 'treca',
'this_game_is': 'Ova igra je',
'this_tool_recalculates': 'Ovaj alat ponovo izračunava množilac iz heš algoritma igre. Možete kopirati/nalepiti bilo koji algoritam heš igre da biste proverili množilac.',
'time': 'VREME',
'time_frame': 'Za Period',
'to': 'DO',
'toggle_animation': 'Uklj/Isklj Animaciju',
'top_odds': 'Najveći Koef.',
'top_wins': 'Najveći Dobici',
'total_bets': 'Ukupno Opklada',
'tournament': 'Turnir',
'tournament_information': 'Informacije o turniru',
'try_advanced_autoplay': 'Probajte novu opciju automatskog igranja!',
'uncredited_wins': 'Nedodeljen kredit',
'user': 'KORISNIK',
'user_and_points': 'IGRAČ & POENI',
'username': 'Nadimak',
'username_must_be_at_least': 'Nadimak mora biti bar',
'verify': 'Provera',
'vip_popup_text': 'Sada možete odabrati neki od VIP avatara koji su dostupni isključivo VIP igračima.',
'vip_popup_title': 'Čestitamo, postali ste VIP igrač!',
'waiting_for_the_next_round': 'Čeka se sledeća runda...',
'warnings_are_calculated': ' • Dobitak se izračunava množenjem opklade sa množiteljem u trenutku klika na Isplati dugme',
'watch_take_off': 'Gledajte kako vaša Srećna Raketa poleće i vaši dobici se povećavaju.',
'watch_the_rocket': 'Pratite Raketu',
'we_have_noticed': '`Primetili smo da ste prvi put otvorili igru "Rocketman". Da li želite da Vam pokažemo kratak video i objasnimo kako se igra "Rocketman"?`',
'week': 'Nedelja',
'welcome_to_rocketman': 'Dobrodošli u Rocketman - novu i uzbudljivu igru! Sa Rocketman -om imate šansu da osvojite do 20.000k vaš originalni ulog.',
'what_is_provably_fair': 'Šta je "Provably Fair" ?',
'will_be_deposited': 'Biće kreditirano na balans igrača sa prvim regularnim dobitkom',
'win': 'DOBITAK',
'win_amount': 'Dobitak',
'win_gifs': 'POBEDITI',
'winner': 'dobitnik',
'wins': 'je dobio',
'write_a_replay': 'Odgovori ...',
'year': 'Godina',
'yes': 'DA',
'yes_start_the_round': 'Da, započni rundu',
'you_have_cashed_out': 'Isplatili ste, vaš dobitak je ',
'you_have_won': 'DOBILI STE',
'your_bet_is_lost': 'vaša opklada je izgubljena.',
'current': 'Trenutni',
'previous': 'Prethodni',
'claim': 'Prihvati',
'this_feature_will_give': 'Ova funkcija će dati odabrani iznos nasumičnim korisnicima u ćaskanju',
'amount_per_player': 'Iznos po igraču',
'no_of_players': 'Broj igrača',
'meteor_shower': 'Meteorska kiša',
'claim_your_free_bet': 'Preuzmite svoju besplatnu opkladu',
'meteor_shower_total': 'Kiša meteora Total',
'shower': 'KIŠA',
'total': 'Ukupno',
'meteor_shower_incoming': 'Kiša meteora stiže, pogledajte chat!',
'insurance': 'OSIGURANJE',
'insurance_paragraph': 'Igrači mogu da kupe osiguranje za svoju opkladu klikom na polje za potvrdu „Osiguranje klađenja“ pored dugmeta za opkladu. Osiguranje košta 10% od ukupnog iznosa opklade i vratiće iznos opklade igraču ako raketa eksplodira sa množiocem od 1,00.',
'insure_your_bet': 'Osigurajte svoju opkladu',
'bet_insurance_promo_text': 'Izaberite opciju „Osiguranje opklade“ da biste osigurali svoju opkladu od pada rakete sa množinom od 1,00.',
'bet_insurance_promo_text_helper': 'Pročitajte više u odeljku "Pravila igre"...',
'game_explanation_1': 'Izaberite svoj množilac, i ako množilac igre premaši Vaš izabrani, Vaš dobitak će biti',
'game_explanation_2': 'Vaš množilac X Vaša opklada',
'retail_max_mp_1': 'Osvojite do',
'retail_max_mp_2': 'tvoje opklade!',
'play_multibets': 'Igraj',
'multibets': 'MULTIBET',
'bonus_game': 'BONUS IGRA',
'all_wins_doubled': 'Svi dobici su udvostručeni!',
'multibets_paragraph_1': 'da povećate svoje šanse!',
'multibets_paragraph_2': 'Možete kombinovati opklade na do deset rundi u jednu multibet i osvojiti ogromne isplate!',
'multibets_paragraph_3': 'Svaki put kada se dobije runda, dobitna dividenda iz te runde se kladi u sledećoj rundi, sve dok sve runde ne budu pobedničke.',
'bonus_launch': 'Osvojite X2 na svim opkladama u rundi „Bonus uzletanje“!',
'multiplier_history': 'Istorija multiplikatora',
'will_start_soon': 'uskoro će početi',
'hurry_up_and_place_your_bets': 'Požurite i uložite svoje opklade!',
'active_bets': 'Aktivne opklade',
'shop': 'POSLOVNICA',
'game_meteor_shower_paragraph': 'Meteorska Kiša dodeljuje jednu besplatnu opkladu unapred definisane vrednosti unapred definisanom broju igrača. Meteorska Kiša može biti pokrenuta od strane servera ili od strane igrača. Kada se Meteorska Kiša aktivira, poruka se pojavljuje u igri, usmeravajući korisnike da pogledaju chat gde se nalazi poruka o Meteorskoj Kiši. Igrači mogu preuzeti besplatnu opkladu klikom na dugme „Preuzmi“. Besplatne opklade se dodeljuju igračima koji prvi kliknu na dugme „Preuzmi“. Upotreba automatskih klikera ili bilo kog softvera za automatizaciju koji emulira klikove igrača radi preuzimanja nagrada je zabranjena i može rezultirati zabranom korišćenja ove funkcije.',
'claming_failed': 'Preuzimanje nije uspelo',
'player_on_blacklist': 'Igrač je na crnoj listi',
'you_can_claim_ticket': 'Možete preuzeti tiket samo ako ste aktivni igrač sa redovnim opkladama',
'you_already_have_free_tickets': 'Već imate besplatne tikete, prvo ih potrošite',
'please_play_the_game_in_portait_mode': 'Molim vas igrajte u portret modu.',
};
langArr.zh = {
'your_insurance_returns_you': '您的保险会回报您',
'bet_insurance': '投注保险',
'max_bet_is': '最大賭注是',
'already_active_bet_of': '並且已經有活躍的賭注',
'1_from_game_history': '‎1. 游戏历史记录',
'2_in_settings_window': '‎2. 设置窗口',
'3_online_calculator': '‎3. 在线计算器',
'active_tournament_1': '活跃的锦标赛 ',
'active_tournament_2': '尚无投注。',
'add_another_bet': '添加另一个投注',
'advanced_autoplay': '高级自动游戏',
'advanced_autoplay_for_bet': '投注高级自动游戏',
'alien_saucer_free_bets': '外星飞船免费投注',
'all_bets': '所有投注',
'all_in_your_hands': '尽在您的掌握！',
'allow': '允许',
'at': '投注',
'austronaut_promo_credit': '宇航员促销积分',
'auto_play_and_autocash': '自动游戏和自动兑现 ',
'autoplay': '自动游戏',
'autoplay_and_cashout': '‎02. 自动游戏和自动兑现',
'back_to_home': '返回主页',
'balance': '余额',
'bet': '投注',
'bet_amount': '投注额',
'bet_and_cashout': '‎01. 投注和兑现',
'bet_bets_tab': '投注额',
'bet_failed': '投注失败',
'bet_statistic': '投注统计数据',
'bet_time': '投注时间：',
'bet_wins': '投注赢利',
'bets': '投注',
'biggest_odds': '最大赔率',
'bonus_type': '奖金类型',
'bonusing_history': '奖金历史',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot得主',
'but_remember_paragraph': '请记住，如果您没有在幸运火箭爆炸前兑现，',
'calculate_raw_mp_to_decimal': '计算十进制的原始翻倍器，四舍五入至最接近的整数',
'cancel': '取消',
'cant_post_media_content': '不可频繁发布媒体信息',
'cashed_out': '已兑现：',
'cashout': '兑现',
'cashout_at': '兑现',
'cashout_before': '在火箭爆炸前兑现，钱就是你的！',
'change_avatar': '更改头像',
'change_username': '更改用户名',
'characters_long': '字符长度',
'chat': '聊天',
'chat_room': '聊天室',
'checking_for_spam': '检查是否为诈骗……',
'close': '关闭',
'coeff': '系数',
'collect_win': '收集赢利',
'combined_seed': '组合种子：',
'company': '公司',
'congratulations_you_have_won_free_bets': '恭喜！您赢得了奖金投注！您可以',
'convert_first_13_to_decimal': ' 将前13个字节变为十进制',
'd': '天',
'date': '日期',
'date_and_time': '日期和时间',
'deny': '拒绝',
'detailed_game_rules': '详细游戏规则',
'dissconnection_policy': '断开连接政策',
'easy_as_one_two_three': '易如反掌',
'enter_username': '请输入想要的用户名',
'error_handling': '错误处理',
'exit_full_screen': '退出全屏模式',
'first': '第一',
'first_time_playing': '第一次玩“Rocketman”？',
'folow_the_three_easy_steps': '只需简简单单的三个步骤，就能享受Rocketman！ ',
'free_bets': '免费投注',
'free_bets_desc': '您已赢得免费投注！按下开始按钮后可随时使用免费投注。',
'free_bets_header': '免费投注',
'from': '从',
'fullscreen_mode': '为提供更优质的用户体验，游戏将以全屏模式启动。',
'function': '功能',
'game_functions': '游戏功能',
'game_history': '游戏历史',
'game_r_1_paragraph': '玩家可激活自动游戏选项，每次新回合都会自动投注，投注额与之前的投注额相同。',
'game_r_10_paragraph': '获得促销积分奖励后，玩家进行的任意投注时，会先扣除促销积分中的金额，直至用完促销积分，然后才会从玩家账户中扣除投注额。',
'game_r_11_paragraph': '促销积分不能兑现，但任何此类投注的赢利都会全额计入玩家账户。',
'game_r_12_paragraph': '促销积分有效期为30天，过期后将无法使用。',
'game_r_13_paragraph': '玩家可随机获得免费投注奖励。屏幕上会出现弹窗提醒玩家获得的免费投注奖励、投注数量以及投注额。',
'game_r_14_paragraph': '获得免费投注奖励后，玩家可自行选择何时开始免费投注回合。免费投注回合开始后，会进行带有特定投注额的自动投注，玩家可自行决定在任意时间兑现。如果玩家在免费投注期间断开连接，则免费投注仍会进行并遵循“断开连接”政策。',
'game_r_15_paragraph': '免费投注有效期为30天，过期后将无法使用。',
'game_r_16a_paragraph': '总体理论玩家回报率为 ',
'game_r_17_paragraph': 'RTP百分比由独立第三方持续监控和验证。',
'game_r_18_paragraph': '即使投注后连接断开，投注也不会取消。如果翻倍器达到20.000倍，则您的投注将自动兑现，积分将计入您的账户。',
'game_r_19_paragraph': '您可以在游戏中与其他玩家聊天。在聊天字段中输入您的消息，或与其他玩家分享您的赢利投注。',
'game_r_2_paragraph': '在“自动兑现”中输入自动兑现翻倍器，输入后，游戏会在到达所设翻倍器时自动兑现。',
'game_r_20_paragraph': '辱骂其他玩家或使用不当和/或粗言秽语滥用聊天的玩家将被禁用聊天权限。',
'game_r_21_paragraph': 'Rocketman的设计包含优美的背景音乐和音效。如果您希望禁用音乐和/或声音效果，您可以通过下拉菜单进行操作。',
'game_r_22_paragraph': '通过点击“我的投注”按钮，玩家可以查看近期投注。如需查询更多游戏历史（如更长时间段的游戏历史）玩家可能需要访问游戏平台自带的玩家历史。',
'game_r_23_paragraph': '如出现错误，回合可能无法按时开始。如果此类情况发生，我们的支持团队将尝试尽快解决问题，并恢复常规游戏回合。如果游戏回合本身出现问题，所有投注将被取消，投注额将退还至玩家。',
'game_r_24_paragraph': '网络连接时不可避免会出现网络延迟的情况。无论是否出现延迟，所有的投注和兑现都仅在连接至服务器时有效。 ',
'game_r_3_paragraph': '玩家可在游戏中使用快捷键快速投注或兑现。快捷键对应功能如下',
'game_r_4_paragraph': '每次下注的1%用于累积奖池基金。该基金用于累积奖池系统',
'game_r_5_paragraph': ' ‎• Jackpot – 最高级别、最高奖金、频率最低',
'game_r_6_paragraph': ' ‎• Rocketpot – 中等级别，中等奖金',
'game_r_7_paragraph': ' ‎• Boosterpot – 最低级别、较低奖金、频率最高 ',
'game_r_8_paragraph': '所有头奖级别都使用“神秘”或“必胜”玩法。每次赢得头奖后，随机数字生成器都会立即决定下一个“必胜”头奖的金额，并以加密形式存储在远程数据库中。该值为加密值，因此也属于保密的隐藏值。首位投注额使得头奖奖池超过该神秘值的玩家将赢得头奖。头奖得主将在该回合结束后宣布。',
'game_r_8a_paragraph': '注意：當累積獎金被觸發時，累積獎金得獎者只能是一位在該輪中積極參與遊戲的 Rocketman 玩家。如果玩家贏得了頭獎，就不可能與任何其他 Rocketman 玩家分享。',
'game_r_9_paragraph': '玩家可随机获得促销积分奖励。屏幕上会出现弹窗提醒玩家获得的促销积分奖励及其数额。',
'game_round': '游戏回合！',
'game_rules': '游戏规则',
'game_rules_1_paragraph': 'Rocketman是新一代的博彩游戏！几秒钟内就可以获胜好几次！Rocketman基于',
'game_rules_10_paragraph': ' ‎• 通过勾选“自动投注”复选框，可从投注面板的“自动”选项卡激活自动游戏。激活后，投注将自动进行，但要兑现，您应该在每一轮中按“兑现”按钮。如果您希望投注自动兑现，请使用“自动兑现”选项。',
'game_rules_11_paragraph': '‎• 自动兑现可从投注面板的“自动”选项卡中获得。激活后，当达到输入的系数时，您的投注将自动兑现。',
'game_rules_2_paragraph': '‎，这是目前博彩行业中唯一真正能够确保诚实可信度的游戏系统。',
'game_rules_3_paragraph': '‎• 赢利翻倍器从1x开始，随着幸运火箭的起飞不断增加。',
'game_rules_4_paragraph': ' ‎• 您的赢利等于兑现时的翻倍器乘以您的投注。 ',
'game_rules_5_paragraph': '‎• 在每一轮开始前，“可验证公平游戏”的随机数生成器会生成幸运火箭飞离时的系数。您可以通过单击“历史记录”选项卡结果检查生成系数的可信性。',
'game_rules_6_paragraph': '‎• 选择金额，按下“投注”按钮进行投注。 ',
'game_rules_7_paragraph': '‎• 通过添加第二个投注面板，您可以同时进行两个投注。要添加第二个投注面板，请按下位于第一个投注面板右上角的“+”图标。',
'game_rules_8_paragraph': ' ‎• 按“兑现”按钮兑现您的赢利。您的赢利等于投注乘以兑现系数。',
'game_rules_9_paragraph': '‎• 如果您没有在火箭爆炸前兑现，您的投注就输了。',
'general_rules': '通用规则',
'gifs': 'GIFS',
'go_back': '返回',
'good_job_buddy': '好样的，继续加油',
'h': '时',
'hashed_in_sha_512': 'SHA512哈希',
'hex': '十六进制',
'hey_username': '您好',
'hide_hex_to_decimal': '隐藏十六进制至十进制转化',
'highscore': '高分',
'how_it_works': '如何运作？',
'how_to_check': '如何查看？',
'how_to_play': '玩法',
'if_the_maximum_multiplier': '‎• 如果达到20.000的最大翻倍器，则所有公开投注将自动按最大翻倍器兑现',
'increase': '增加',
'input_bet_amount': '起飞前输入投注金额并投注。',
'insufficient_funds': '资金不足',
'it_blew_up': '爆炸了！',
'jackpot': '头奖',
'jackpot_winners': 'Jackpot得主',
'key': '快捷键',
'load_more': '加载更多',
'loading': '加载中',
'loading_next_round': '正在加载下一回合……',
'loss_gifs': '输',
'lost_connection': '失去连接',
'make_a_bet': '投注',
'malfunction_voids': '故障造成所有支付和游戏无效。',
'maximum_payout': '最高支付额为',
'menu': '菜单',
'min_bet_is': '最小投注为',
'month': '月',
'more_details': '更多详情',
'more_information': '更多信息：',
'multiplier': '翻倍器',
'multiplier_starts': '‎• 翻倍器从1开始，最高可到达20.000',
'multipliers_larger': '大于x20.000的翻倍器将受限于最大翻倍器的值',
'music_settings': '音乐设置',
'my_bet_history': '我的投注历史',
'my_bets': '我的投注',
'my_bonysing_history': '我的奖金历史',
'network_latency': '网络延迟',
'network_latency_content': '网络连接时不可避免会出现网络延迟的情况。无论是否出现延迟，我们认为所有的投注和兑现都仅在连接至服务器时有效。',
'network_latency_title': '网络延迟',
'no_thanks': '不了谢谢！',
'not_right_now': '暂不开始',
'off': '关',
'oh_snap': '啊呀！',
'ok_close': '好的，关闭！',
'ok_thanks': '好的谢谢！',
'on': '开',
'on_loss': '输掉后',
'on_win': '获胜后',
'online': '在线',
'per_bet': '每次投注',
'pick_your_avatar_img': '选择您的头像',
'place_bet_with_last': '使用最后所选的投注额投注 ',
'play_now': '立即开始',
'player_1_seed': '玩家1种子：',
'player_2_seed': '玩家2种子：',
'player_3_seed': '玩家3种子：',
'please_wait_active_bets': '请等待当前投注完成，然后激活免费投注',
'points': '积分',
'powered_by': '支持方为',
'previous_hand': '过往投注',
'prize': '奖励',
'prizes': '奖励',
'progressive_jackpot': '渐进式头奖',
'promo_credit': '促销积分',
'promo_credit_condradulations': '恭喜！您赢得了可以在游戏中使用的丰厚促销积分！',
'promo_credits': '促销积分',
'promo_history': '促销历史',
'provably_fair': '可验证公平游戏',
'provably_fair_1_paragraph': 'Rocketman采用“可验证公平游戏”的加密技术，保证了游戏结果100%公平。有了这项技术，任何第三方都不可能干预游戏过程。',
'provably_fair_2_paragraph': '每轮的结果（游戏中的“飞离”系数）不会在我们的服务器上生成。结果是在每回合玩家的帮助下生成，且完全透明。通过这种方式，任何人都不可能操纵游戏结果。此外，任何人都可以检查和确认游戏公平性',
'provably_fair_3_paragraph': '回合结果由回合的四名独立参与者生成',
'provably_fair_4_paragraph': '当回合开始时，游戏将服务器种子与三个客户端种子合并。从合并的符号生成SHA512哈希，并从该哈希中生成游戏结果。',
'provably_fair_5_paragraph': '您可以通过单击绿色图标从游戏历史记录中查看每轮游戏是否公平。在打开的窗口中，您将看到服务器种子、3对玩家的种子、组合哈希和回合结果。',
'provably_fair_6_paragraph': '下一轮服务器种子的哈希版本可在设置窗口中公开获得（在用户菜单中，选中“可验证公平游戏设置”，然后选中“下一个服务器种子SHA256”）。您还可以在此处更改您的客户种子。如果您想参与回合结果的生成，请确保您是该回合投注中的前3名玩家之一。',
'provably_fair_7_paragraph': '可在任何在线计算器中检查哈希的正确性，例如：',
'provably_fair_system': '可验证公平游戏系统打造',
'quick_explanation': '简短解释：',
'randomly_generated_server_hash': '随机生成服务器哈希',
'rank': '排名',
'raw_multiplier': '原始翻倍器',
'raw_multiplier_to_edged': '原始翻倍器至优化翻倍器，使用',
'remove_additional_bet': '移除额外投注',
'reset': '重置',
'return_to_player': '玩家回报率',
'rocketman_is_pure_excitement': 'Rocketman带来单纯的刺激体验！冒个险就能赢钱。',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot得主',
'round': '回合',
'round_id': '回合ID：',
'round_multiplier': '回合翻倍器',
'round_results': '回合结果：',
'rounds': '回合',
'rtp': 'RTP',
'rules': '规则',
'rules_details': '锦标赛规则：',
'save': '保存',
'schedule': '安排',
'second': '第二',
'seconds_left': '秒剩余',
'server_hash': '服务器哈希',
'please_wait_current_bets': '请等待当前投注完成',
'min_autocashout_value_must_be': '最小自动兑现价值必须为',
'min_bet_value_must_be': '最小下注值必须为',
'server_seed': '服务器种子：',
'session_dissconnected': '会话断开',
'multisession_not_allowed': '不允许多会话',
'set_auto_bet': '设置自动投注',
'set_auto_cashout': '设置自动兑现',
'shourtcut_keys': '快捷键',
'show_hex_to_deimal': '显示十六进制至十进制转化',
'sound': '声音',
'sound_settigs': '声音设置',
'start_with_autoplay': '开始自动游戏',
'stop_autoplay': '停止自动游戏',
'stop_on_loss': '输掉后停止',
'stop_on_loss_tooltip': '使用“输掉后停止”功能可以在输掉特定的金额后停止自动投注。<br/>例如，如果您开始时有$100，您可以设置在输掉$25时停止',
'stop_on_profit': '盈利后停止',
'stop_on_profit_tooltip': '使用“盈利后停止”功能可以在获得特定的金额后停止自动投注。<br/>例如，如果您开始时有$100，您可以设置在盈利$25时停止',
'there_are_no_active_torunament': '当前无活跃的锦标赛。',
'third': '第三',
'this_game_is': '该游戏的',
'this_tool_recalculates': '该工具会根据游戏哈希重新计算翻倍器。您可以复制/粘贴任何游戏哈希用于验证翻倍器。',
'time': '时间',
'time_frame': '时间：',
'to': '至',
'toggle_animation': '切换动画',
'top_odds': '最高赔率',
'top_wins': '最高赢利',
'total_bets': '总投注',
'tournament': '锦标赛',
'tournament_information': '锦标赛信息',
'try_advanced_autoplay': '嘗試新的進階自動播放功能！',
'uncredited_wins': '未记入的赢利',
'user': '用户',
'user_and_points': '用户和积分',
'username': '用户名',
'username_must_be_at_least': '用户名至少',
'verify': '验证',
'vip_popup_text': '您可以选择独特的VIP头像之一，仅面向VIP会员！',
'vip_popup_title': '恭喜您成为VIP玩家',
'waiting_for_the_next_round': '等待下一回合……',
'warnings_are_calculated': ' ‎• 赢利的计算方法是将投注额乘以兑现时的翻倍器',
'watch_take_off': '观看幸运火箭起飞，您的赢利会也随之增加。',
'watch_the_rocket': '观看火箭',
'we_have_noticed': '`我们注意到这是您第一次玩“Rocketman”。您是否想要快速查看玩法视频？`',
'week': '周',
'welcome_to_rocketman': '欢迎来到Rocketman——这是一款非常新颖刺激的游戏！通过Rocketman，您有机会赢取最高20.000x的初始投注额。',
'what_is_provably_fair': '什么是可验证公平游戏？',
'will_be_deposited': '将和第一次常规赢利一起存入玩家余额',
'win': '赢',
'win_amount': '赢利额：',
'win_gifs': '赢',
'winner': '获胜者',
'wins': '赢利',
'write_a_replay': '回复……',
'year': '年',
'yes': '是',
'yes_start_the_round': '是的，开始回合',
'you_have_cashed_out': '您已兑现，您的赢利为 ',
'you_have_won': '您已赢得',
'your_bet_is_lost': '您的投注就输了。',
'current': '目前的',
'previous': '以前的',
'claim': '宣称',
'this_feature_will_give': '此功能将在聊天中向随机用户提供选定的金额',
'amount_per_player': '每个玩家的金额',
'no_of_players': '玩家人数',
'meteor_shower': '流星雨',
'claim_your_free_bet': '領取您的免費投注',
'meteor_shower_total': '流星雨總計',
'shower': '雨',
'total': '全部的',
'meteor_shower_incoming': '流星雨来袭，看聊天！',
'insurance': '保险',
'insurance_paragraph': '玩家可以通过单击赌注按钮旁边的"赌注保险"复选框来购买赌注保险。保险费用为总赌注金额的10%，如果火箭在1.00倍数上爆炸，将返还赌注金额给玩家。',
'insure_your_bet': '确保您的赌注',
'bet_insurance_promo_text': '选择“投注保险”选项，以 1.00 乘数确保您的投注免受火箭坠毁的影响。',
'bet_insurance_promo_text_helper': '阅读“游戏规则”部分了解更多内容...',
'game_explanation_1': '选择您的乘数，如果游戏乘数超过您选择的乘数，您的奖金将是',
'game_explanation_2': '您的乘数 x 您的赌注',
'retail_max_mp_1': '赢得最多',
'retail_max_mp_2': '你的赌注！',
'play_multibets': '玩',
'multibets': '多重投注',
'bonus_game': '獎勵遊戲',
'all_wins_doubled': '所有勝利都翻倍！',
'multibets_paragraph_1': '來增加你的勝算！',
'multibets_paragraph_2': '您可以將最多十輪的投注合併為一個多重投注，並贏得巨額獎金！',
'multibets_paragraph_3': '每贏一輪，該輪的贏利紅利將用於下一輪的投注，直到所有輪都獲勝。',
'bonus_launch': '在“奖金启动”轮中的所有投注中赢得 x2！',
'multiplier_history': '乘数历史',
'will_start_soon': '即将开始',
'hurry_up_and_place_your_bets': '赶快下注吧！',
'active_bets': '活跃投注',
'shop': '店铺',
'game_meteor_shower_paragraph': '《流星雨》向预定数量的玩家奖励一次预定价值的免费投注。它可以由服务器或玩家发起。一旦流星雨被激活，游戏中就会掉落一条消息，引导用户查看流星雨消息所在的聊天室。玩家可以通过点击“领取”按钮来领取免费投注。首先点击“领取”按钮的玩家将获得免费投注。禁止使用自动点击器或任何类型的自动化软件来模拟玩家点击并领取奖品，并可能导致禁止使用此功能。',
'claming_failed': '领取失败',
'player_on_blacklist': '该玩家已被列入黑名单',
'you_can_claim_ticket': '仅当您是定期投注的活跃玩家时，您才可以领取彩票',
'you_already_have_free_tickets': '您已有免费门票，请先使用',
'please_play_the_game_in_portait_mode': '请在纵向模式下玩游戏。',
};
langArr.hi = {
'your_insurance_returns_you': 'आपका बीमा आपको लौटाता है',
'bet_insurance': 'शर्त बीमा',
'max_bet_is': 'अधिकतम शर्त है',
'already_active_bet_of': 'और वहाँ पहले से ही सक्रिय दांव है',
'1_from_game_history': '‎1. गेम हिस्ट्री से',
'2_in_settings_window': '‎2. सेटिंग्स विंडो में',
'3_online_calculator': '‎3. ऑनलाइन कैलकुलेटर',
'active_tournament_1': 'एक्टिव टूर्नामेंट ',
'active_tournament_2': 'में अभी तक कोई बेट नहीं है।',
'add_another_bet': 'दूसरी बेट जोड़ें',
'advanced_autoplay': 'एडवांस्ड ऑटोप्ले',
'advanced_autoplay_for_bet': 'बेट के लिए एडवांस्ड ऑटोप्ले',
'alien_saucer_free_bets': 'Alien Saucer मुफ्त बेट्स',
'all_bets': 'सभी बेट्स',
'all_in_your_hands': 'सब आपके हाथ में है!',
'allow': 'अनुमति दें',
'at': 'पर',
'austronaut_promo_credit': 'बहुत बड़ा प्रोमो क्रेडिट',
'auto_play_and_autocash': 'ऑटोप्ले और ऑटो कैशआउट ',
'autoplay': 'ऑटोप्ले',
'autoplay_and_cashout': '‎02. ऑटो प्ले और ऑटो कैशआउट',
'back_to_home': 'होम पर जाएं',
'balance': 'बैलेंस',
'bet': 'बेट',
'bet_amount': 'बेट राशि',
'bet_and_cashout': '‎01. बेट और कैशआउट',
'bet_bets_tab': 'दांव',
'bet_failed': 'बेट विफल रहा',
'bet_statistic': 'बेट के आंकड़े',
'bet_time': 'बेट का समय',
'bet_wins': 'बेट में जीत',
'bets': 'बेट्स',
'biggest_odds': 'सबसे बड़ा ऑड्स',
'bonus_type': 'बोनस प्रकार',
'bonusing_history': 'बोनस हिस्ट्री',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot के विजेता',
'but_remember_paragraph': 'लेकिन याद रखें, यदि लकी रॉकेट के विस्फोट होने से पहले यदि आपके पास कैश आउट के लिए समय नहीं बचा,',
'calculate_raw_mp_to_decimal': 'दशमलव से रॉ मल्टीप्लायर की गणना करें और सबसे नजदीकी पूर्णांक के करीब रखें',
'cancel': 'रद्द करें',
'cant_post_media_content': 'मीडिया कंटेंट को लगातार पोस्ट नहीं कर सकते',
'cashed_out': 'कैशआउट किया गया',
'cashout': 'कैशआउट',
'cashout_at': 'पर कैशआउट',
'cashout_before': 'रॉकेट के विस्फोट होने से पहले कैशआउट करें और पैसे आपके होंगे!',
'change_avatar': 'अवतार बदलें',
'change_username': 'यूजरनेम बदलें',
'characters_long': 'करैक्टर्स लम्बा होना चाहिए',
'chat': 'चैट',
'chat_room': 'चैट रूम',
'checking_for_spam': 'स्पैम की जांच की जा रही है...',
'close': 'बंद करें',
'coeff': 'गुणक',
'collect_win': 'जीत कलेक्﻿ट करें',
'combined_seed': 'संयोजित सीड',
'company': 'कंपनी',
'congratulations_you_have_won_free_bets': 'बधाई हो! आपने बोनस बेट्स जीत लिए हैं! आप',
'convert_first_13_to_decimal': ' पहले 13 बाइट्स को दशमलव में बदलें',
'd': 'दिन',
'date': 'तिथि',
'date_and_time': 'तिथि और समय',
'deny': 'अस्वीकार करें',
'detailed_game_rules': 'गेम के नियम का विस्﻿तृत वर्णन',
'dissconnection_policy': 'डिस्﻿कनेक्﻿शन नीति',
'easy_as_one_two_three': 'एक, दो, तीन गिनने जितना आसान',
'enter_username': 'कृपया अपना पसंदीदा यूजरनेम दर्ज करें',
'error_handling': 'त्रुटि प्रबंधन',
'exit_full_screen': 'फुल-स्क्रीन मोड से बाहर निकलें',
'first': 'पहला',
'first_time_playing': 'पहली बार "Rocketman" खेल रहे हैं?',
'folow_the_three_easy_steps': 'तीन आसान स्टेप्स को फॉलो करें और Rocketman का आनंद लें! ',
'free_bets': 'मुफ्त बेट्स',
'free_bets_desc': 'आपने जीता है मुफ्त बेट्स! आप स्टार्ट बटन को कभी भी दबाकर मुफ्त बेट्स का लाभ ले सकते हैं।',
'free_bets_header': 'मुफ्त बेट्स',
'from': 'से',
'fullscreen_mode': 'यूजर के बेहतर अनुभव के लिए गेम फुलस्क्रीन मोड में शुरू होगा।',
'function': 'फंक्शन',
'game_functions': 'गेम के फंक्﻿शंस',
'game_history': 'गेम हिस्ट्री',
'game_r_1_paragraph': 'कोई भी प्लेयर ऑटोप्ले विकल्प को एक्टिवेट कर सकता है, जहां पिछले बेट से बेट राशि का इस्तेमाल कर प्रत्येक नए राउंड के लिए बेट ऑटोमैटिकली लगाई जाती है।',
'game_r_10_paragraph': 'एक बार प्रोमो क्रेडिट दिए जाने के बाद, प्﻿लेयर द्वारा लगाए गए किसी भी बेट के लिए, प्﻿लेयर्स के वॉलेट से पैसे निकालना जारी रखने से पहले, पैसे पहले प्रोमो क्रेडिट से काटे जाएंगे, जब तक कि इसका उपयोग नहीं हो जाता।',
'game_r_11_paragraph': 'प्रोमो क्रेडिट को कैशआउट नहीं किया जा सकता है, लेकिन ऐसे किसी भी बेट्स पर मिली जीत की पूरी राशि प्लेयर को क्रेडिट की जाती है।',
'game_r_12_paragraph': 'प्रोमो क्रेडिट की 30 दिनों की वैद्यता अवधि होती है, जिसके बाद वे समाप्त हो जाते हैं और इस्तेमाल नहीं किए जा सकते हैं।',
'game_r_13_paragraph': 'किसी प्लेयर को रैंडमली मुफ्त बेट्स प्रदान किए जा सकते हैं। प्लेयर को प्रदान किए गए मुफ्त बेट्स, बेट्स की संख्या, और प्रति बेट राशि के बारे में स्क्रीन पर सूचित करता एक पॉप-अप प्रदर्शित होगा।',
'game_r_14_paragraph': 'मुफ्त बेट्स प्रदान किए जाने के बाद प्लेयर मुफ्त बेट राउंड को कब शुरू करना है, को चुन सकता है। मुफ्त बेट राउंड शुरू होने के बाद, किसी विशिष्ट दांव की राशि के साथ ऑटोमैटिक बेट लगाई जाएगी, और यह प्लेयर पर निर्भर करता है कि वह किस क्षण में कैशआउट करना चाहता ह',
'game_r_15_paragraph': 'मुफ्त बेट्स की 30 दिनों की वैधता अवधि होती है और उसके बाद वे समाप्त हो जाते हैं और इस्तेमाल नहीं किए जा सकते हैं।',
'game_r_16a_paragraph': 'कुल सैद्धांतिक खिलाड़ी रिटर्न है ',
'game_r_17_paragraph': 'RTP प्रतिशत की लगातार निगरानी की जाती है और निष्पक्ष थर्ड पार्टी से सत्यापित की जाती है।',
'game_r_18_paragraph': 'यदि बेट लगाने के बाद कनेक्शन टूट जाता है, तो बेट को रद्द माना जाएगा। यदि मल्टीप्लायर आपकी बेट का 20.000x पहुंच जाता है, तो आपकी बेट ऑटो कैशआउट हो जाएगी और क्रेडिट आपके अकाउंट में होगा।',
'game_r_19_paragraph': 'आप गेम में अन्य प्लेयर के साथ चैट कर सकते हैं। चैट क्षेत्र में अपने संदेश दर्ज करें, या अपनी जीतने वाली बेट को अन्य प्लेयर्स के साथ साझा करें।',
'game_r_2_paragraph': 'ऑटो कैशआउट मल्टीप्लायर को "ऑटो कैशआउट" क्षेत्र में दर्ज किया जा सकता है और जब दर्ज किया जाता है, तो निर्धारित मल्टीप्लायर के पहुंचने की स्थिति में, गेम ऑटोमैटिकली कैशआउट हो जाएगा।',
'game_r_20_paragraph': 'जो प्﻿लेयर अन्य प्﻿लेयर्स को अपशब्﻿द कहते हैं, या अनुचित और/या अभद्र भाषा का उपयोग करके चैट का दुरुपयोग करते हैं, उनके चैट के विशेषाधिकार को डिसेबल कर दिया जाएगा।',
'game_r_21_paragraph': 'Rocketman को अच्छे बैकग्राउंड म्यूजिक और साउंड इफ़ेक्ट को ध्यान में रखते हुए डिजाइन किया गया है। यदि आप म्यूजिक और/या साउंड इफेक्ट को डिसेबल करना चाहते हैं, तो आप ड्रॉप डाउन मेनू के माध्यम से ऐसा कर सकते हैं।',
'game_r_22_paragraph': '‎"माइ बेट" बटन पर क्लिक कर, प्लेयर अपनी नवीनतम बेट की समीक्षा कर सकता है। आगे की प्ले हिस्ट्री (जैसे, लंबी अवधि) के लिए, प्लेयर को स्वयं से गेमिंग प्﻿लेटफॉर्म के भीतर प्लेयर हिस्ट्री को एक्सेस की आवश्यकता हो सकती है।',
'game_r_23_paragraph': 'त्रुटि होने की स्थिति में, राउंड समय से शुरू नहीं हो सकता है। यदि ऐसा होता है, तो हमारी सहायता समूह यथाशीघ्र समस्﻿या के समाधान करने की कोशिश करेगा, और सामान्य गेम राउंड के साथ पुनः शुरू होगा। यदि स्वयं गेम राउंड में कोई समस्या होती है, तो सभी बेट्स को रद्',
'game_r_24_paragraph': 'नेटवर्क विलंब इंटरनेट कनेक्टिविटी को नहीं टाला जाने वाला हिस्﻿सा है। हम सभी बेट्स और कैशआउट को केवल उस समय लगा हुआ मानते हैं, जब वे नेटवर्क विलंब की अवधि के बिना हमारे सर्वर पर पहुंचता है। ',
'game_r_3_paragraph': 'प्लेयर शॉर्टकट कुंजी का इस्तेमाल गेम में तेजी से बेट लगाने या कैशआउट करने के लिए कर सकते हैं। कुंजी की मैपिंग इस प्रकार है',
'game_r_4_paragraph': 'हर दांव का 1% प्रगतिशील जैकपॉट फंड के लिए समर्पित है। यह फंड प्रगतिशील जैकपॉट सिस्टम के लिए कार्य करता है',
'game_r_5_paragraph': ' ‎• जैकपॉट – उच्चतम लेवल, सबसे बड़ा पुरस्कार, कम बार मिलता है',
'game_r_6_paragraph': ' ‎• Rocketpot – मध्यम लेवल, मध्यम पुरस्कार',
'game_r_7_paragraph': ' ‎• Boosterpot – निम्नतम लेवल, छोटा पुरस्कार, अक्﻿सर मिलता है ',
'game_r_8_paragraph': 'सभी जैकपॉट लेवल को "मिस्ट्री" या "मस्ट-हिट-बाय" मैकेनिक का इस्तेमाल कर प्रदान किया जाता है। प्रत्येक "मस्ट-हिट-बाय" जैकपॉट का मूल्य पिछले जैकपॉट को एक रैंडम नंबर जेनरेटर द्वारा जीते जाने के तुरंत बाद निर्धारित किया जाता है और रिमोट में एन्क्रिप्टेड मूल्य ',
'game_r_8a_paragraph': 'ध्यान दें: जैकपॉट विजेता केवल एक रॉकेटमैन खिलाड़ी हो सकता है जो जैकपॉट ट्रिगर होने पर राउंड में सक्रिय रूप से खेल रहा हो। ऐसी कोई संभावना नहीं है कि यदि खिलाड़ी ने जैकपॉट जीत लिया है, तो उसे इसे किसी अन्य रॉकेटमैन खिलाड़ी के साथ साझा करना होगा।',
'game_r_9_paragraph': 'किसी प्लेयर को रैंडम तरीके से प्रोमो क्रेडिट दिया जा सकता है। प्रदान किए गए प्रोमो क्रेडिट और इसकी राशि के बारे में प्लेयर को सूचित करने के लिए स्क्रीन पर एक पॉप-अप ग्राफ़िक प्रदर्शित होगा।',
'game_round': 'गेम राउंड पर बेट लगा सकते हैं!',
'game_rules': 'गेम के नियम',
'game_rules_1_paragraph': 'Rocketman नए ज़माने की मनोरंजक गैंबलिंग है। सेकंड्स में कई बार जीतें! Rocketman',
'game_rules_10_paragraph': ' ‎• ऑटो प्ले को बेट पैनल में "ऑटो" टैब से "ऑटो बेट" चेकबॉक्स पर चिन्हित कर एक्टिव किया जाता है। एक्टिव होने के बाद, बेट्स ऑटोमैटिकली लगा दिए जाएंगे, लेकिन कैशआउट के लिए, आपको प्रत्येक राउंड में "कैशआउट" बटन दबाना होगा। यदि आप बेट को ऑटोमैटिकली कैशआउट करना',
'game_rules_11_paragraph': '‎• ऑटो कैशआउट बेट पैनल पर "ऑटो" टैब पर उपलब्ध है। एक्टिवेट होने के बाद दर्ज गुणक तक पहुंचने पर, आपकी बेट ऑटोमैटिकली कैशआउट हो जाएगी।',
'game_rules_2_paragraph': '‎, जो कि मौजूदा समय में गैंबलिंग उद्योग में ईमानदारी की एकमात्र वास्﻿तविक गारंटी है।',
'game_rules_3_paragraph': '‎ • जीत का मल्टीप्लायर 1x से शुरू होता है और लकी प्लेन के उड़ान भरते ही अधिक से अधिक बढ़ना शुरू हो जाता है।',
'game_rules_4_paragraph': ' ‎• आपकी जीत की राशि वह गुणक है जिस पर आपने अपनी बेट से गुणा कर कैशआउट किया है। ',
'game_rules_5_paragraph': '‎• प्रत्येक राउंड की शुरुआत से पहले, हमारे प्रोवाब्ली फेयर रैंडम नंबर जेनरेटर गुणक को जेनरेट करता है, जिस पर लकी प्लेन उड़न भरेगा। आप इस जेनेरेशन की ईमानदारी को हिस्ट्री टैब में परिणाम के विपरीत पर क्लिक कर जांच सकते हैं।',
'game_rules_6_paragraph': '‎• बेट लगाने के लिए राशि चुनें और "बेट" बटन दबाएं। ',
'game_rules_7_paragraph': '‎• दूसरा बेट पैनल जोड़कर आप एक साथ दो दांव लगा सकते हैं। दूसरा बेट पैनल जोड़ने के लिए पहले बेट पैनल के ऊपरी दाएं कोने पर स्थित प्लस आइकन दबाएं।',
'game_rules_8_paragraph': ' ‎• अपनी जीत की राशि को निकालने के लिए "कैशआउट" बटन दबाएं। आपकी जीत वाली बेट को कैशआउट गुणक से गुना किया जाता है।',
'game_rules_9_paragraph': '‎• आपकी बेट हार जाती है, यदि आप प्लेन के उड़ान भरने से पहले कैश आउट नहीं करते हैं।',
'general_rules': 'सामान्य नियम',
'gifs': 'GIFS',
'go_back': 'वापस जाएं',
'good_job_buddy': 'बहुत खूब दोस्त, इसे जारी रखें',
'h': 'घंटे',
'hashed_in_sha_512': 'SHA512 में हैश किया गया',
'hex': 'हेक्साडेसिमल',
'hey_username': 'हैलो',
'hide_hex_to_decimal': 'दशमलव परिवर्तन तक हेक्﻿साडेसिमल को छुपाएं',
'highscore': 'उच्च स्कोर',
'how_it_works': 'यह कैसे काम करता है?',
'how_to_check': 'कैसे जांच करें?',
'how_to_play': 'कैसे खेलें',
'if_the_maximum_multiplier': '‎• यदि मल्﻿टीप्﻿लायर 20.000 अधिकतम पर पहुंच जाता है, तो सभी ओपन बेट्स को अधिकतम मल्टीप्लायर पर कैशआउट किया जाएगा।',
'increase': 'बढ़ाएं',
'input_bet_amount': 'बेट राशि दर्ज करें और टेक ऑफ करने से पहले अपनी बेट लगाएं।',
'insufficient_funds': 'अपर्याप्त धनराशि',
'it_blew_up': 'इसने होश उड़ा दिए! वाह शानदार...',
'jackpot': 'जैकपॉट',
'jackpot_winners': 'जैकपॉट के विजेता',
'key': 'कुंजी',
'load_more': 'अधिक लोड करें',
'loading': 'लोड हो रहा है',
'loading_next_round': 'अगला राउंड लोड हो रहा है....',
'loss_gifs': 'हार',
'lost_connection': 'कनेक्शन टूट गया',
'make_a_bet': 'बेट लगाएं',
'malfunction_voids': 'खराबी के कारण सभी भुगतान और खेल रद्द हो जाते हैं।',
'maximum_payout': 'अधिकतम भुगतान है',
'menu': 'मेनू',
'min_bet_is': 'न्यूनतम बेट है',
'month': 'माह',
'more_details': 'अधिक विवरण',
'more_information': 'अधिक जानकारी',
'multiplier': 'मल्टीप्लायर',
'multiplier_starts': '‎• मल्टीप्लायर 1 से शुरू होता है और 20.000 तक जाता है',
'multipliers_larger': 'x20.000 से अधिक के मल्टीप्लायर्स को अधिकतम मल्टीप्लायर वैल्यू पर सीमित किया जाता है',
'music_settings': 'म्यूजिक सेटिंग्स',
'my_bet_history': 'माइ बेट हिस्ट्री',
'my_bets': 'माइ बेट्स',
'my_bonysing_history': 'माइ बोनसिंग हिस्ट्री',
'network_latency': 'नेटवर्क विलंब',
'network_latency_content': 'नेटवर्क विलंब इंटरनेट कनेक्टिविटी को नहीं टाला जाने वाला हिस्﻿सा है। हम सभी बेट्स और कैशआउट को केवल उस समय लगा हुआ मानते हैं, जब वे नेटवर्क विलंब की अवधि के बिना हमारे सर्वर पर पहुंचता है।',
'network_latency_title': 'नेटवर्क विलंब',
'no_thanks': 'नहीं, धन्यवाद!',
'not_right_now': 'अभी नहीं',
'off': 'ऑफ करें',
'oh_snap': 'ओह स्﻿नैप!',
'ok_close': 'ठीक है, बंद करें!',
'ok_thanks': 'ठीक है, धन्यवाद!',
'on': 'ऑन करें',
'on_loss': 'हार पर',
'on_win': 'जीत पर',
'online': 'ऑनलाइन',
'per_bet': 'प्रति बेट',
'pick_your_avatar_img': 'अपना अवतार इमेज चुनें',
'place_bet_with_last': 'अंतिम चयनित दांव वाला कोई बेट लगाएं ',
'play_now': 'अभी खेलें',
'player_1_seed': 'प्लेयर 1 सीड',
'player_2_seed': 'प्लेयर 2 सीड',
'player_3_seed': 'प्लेयर 3 सीड',
'please_wait_active_bets': 'मुफ्त बेट्स को एक्टिवेट करने के लिए कृपया मौजूदा बेट्स के पूरा होने का इंतजार करें',
'points': 'प्वाइंट्स',
'powered_by': 'द्वारा संचालित है',
'previous_hand': 'पिछला हैंड',
'prize': 'पुरस्कार',
'prizes': 'पुरस्कार',
'progressive_jackpot': 'प्रोग्रेसिव जैकपॉट',
'promo_credit': 'प्रोमो क्रेडिट',
'promo_credit_condradulations': 'बधाई हो! आपने अप्रत्﻿याशित रूप से अच्छा प्रोमो क्रेडिट जीता है, जिसे आप गेम में इस्तेमाल कर सकते हैं!',
'promo_credits': 'प्रोमो क्रेडिट',
'promo_history': 'प्रोमो हिस्ट्री',
'provably_fair': 'प्रोवाब्ली फेयर',
'provably_fair_1_paragraph': '‎\'Rocketman\' क्रिप्टोग्राफिक तकनीक पर आधारित है, जिसे \'प्रोवाब्ली फेयर \' कहा जाता है। यह तकनीक गेम के परिणाम में 100% निष्पक्षता की गारंटी देता है। इस तकनीक के साथ, गेम प्रक्रिया में किसी भी थर्ड पार्टी का हस्तक्षेप नामुमकिन है।',
'provably_fair_2_paragraph': 'प्रत्येक राउंड का परिणाम (गेम\'का \'फ़्लाइ अवे\' गुणक) हमारे सर्वर पर जेनरेट नहीं होता है। इसे राउंड के प्लेयर्स की सहायता से जेनरेट किया जाता है और पूरी तरह से पारदर्शी होता है। इस तरीके से, किसी के लिए भी गेम के परिणाम से छेड़छाड़ करना नामुमकिन होता है। सा',
'provably_fair_3_paragraph': 'राउंड का परिणाम राउंड के चार निष्पक्ष प्रतिभागियों से जेनरेट किया जाता है',
'provably_fair_4_paragraph': 'जब राउंड शुरू होता है, तो गेम ग्राहक सीड्स के साथ सर्वर सीड्स को मिलाता है। मिलाए गए सिंबल्स से SHA512 हैश जेनरेट किया जाता है और इस हैश से - गेम के परिणाम तय होते हैं।',
'provably_fair_5_paragraph': 'आप गेम की निष्पक्षता को, ग्रीन आइकॉन पर क्लिक कर, गेम हिस्ट्री से जांच सकते हैं। खुलने वाली विंडो में, आप सर्वर सीड, प्लेयर्स सीड के 3 पेयर, संयोजित हैश और गेम परिणाम को पाएंगे।',
'provably_fair_6_paragraph': 'अगले राउंड के सर्वर सीड का हैशड संस्करण सेटिंग्स विंडो (यूजर मेनू में, "प्रोवाब्ली फेयर सेटिंग्स" और इसके बाद "अगले सर्वर सीड SHA256" की जांच करें) में सार्वजनिक रूप से उपलब्ध है। आप अपने ग्राहक सीड को यहां भी बदल सकते हैं। यदि आप राउंड रिजल्ट जनरेशन में ',
'provably_fair_7_paragraph': 'हैश की सत्यता की जांच किसी भी ऑनलाइन कैलकुलेटर से की जा सकती है, उदाहरण के लिए',
'provably_fair_system': 'प्रोवाब्ली फेयर सिस्टम से बना है',
'quick_explanation': 'त्वरित व्याख्या',
'randomly_generated_server_hash': 'रैंडमली जेनरेट किया गया सर्वर हैश',
'rank': 'रैंक',
'raw_multiplier': 'रॉ मल्टीप्लायर',
'raw_multiplier_to_edged': 'रॉ मल्टीप्लायर से एज्ड मल्टीप्लायर का उपयोग कर,',
'remove_additional_bet': 'अतिरिक्त बेट हटाएं',
'reset': 'रीसेट',
'return_to_player': 'प्लेयर का रिटर्न',
'rocketman_is_pure_excitement': 'Rocketman विशद्ध रूप से रोमांचक है! जोखिम और जीत।',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot के विजेता',
'round': 'राउंड',
'round_id': 'राउंड ID',
'round_multiplier': 'राउंड मल्टीप्लायर',
'round_results': 'राउंड के नतीजे',
'rounds': 'राउंड्स',
'rtp': 'RTP',
'rules': 'नियम',
'rules_details': 'टूर्नामेंट के नियम',
'save': 'सेव करें',
'schedule': 'शेड्यूल',
'second': 'दूसरा',
'seconds_left': 'सेकंड्स बचा',
'server_hash': 'सर्वर हैश',
'please_wait_current_bets': 'कृपया वर्तमान दांव पूरा होने तक प्रतीक्षा करें',
'min_autocashout_value_must_be': 'न्यूनतम ऑटोकैशआउट मान होना चाहिए',
'min_bet_value_must_be': 'न्यूनतम शर्त मूल्य अवश्य होना चाहिए',
'server_seed': 'सर्वर सीड',
'session_dissconnected': 'सेशन डिस्﻿कनेक्﻿ट हो गया',
'multisession_not_allowed': 'मल्टीसेशन की अनुमति नहीं है',
'set_auto_bet': 'ऑटो बेट सेट करें',
'set_auto_cashout': 'ऑटो कैश आउट सेट करें',
'shourtcut_keys': 'शॉर्टकट कुंजी',
'show_hex_to_deimal': 'दशमलव परिवर्तन तक हेक्﻿साडेसिमल को दिखाएं',
'sound': 'साउंड',
'sound_settigs': 'साउंड सेटिंग्स',
'start_with_autoplay': 'ऑटोप्ले के साथ शुरू करें',
'stop_autoplay': 'ऑटोप्ले रोकें',
'stop_on_loss': 'हार पर रुकें',
'stop_on_loss_tooltip': 'निर्दिष्﻿ट राशि हार जाने के बाद ऑटोप्ले रोकने के लिए हार पर रुकें का इस्तेमाल करें।<br/> उदाहरण के लिए, यदि आप $100 के साथ शुरू करते हैं और आप $25 पर हार पर रुकें सेट करते हैं',
'stop_on_profit': 'लाभ पर रुकें',
'stop_on_profit_tooltip': 'निर्दिष्﻿ट राशि का लाभ कमाने के बाद ऑटोप्ले रोकने के लिए लाभ पर रुकें का इस्तेमाल करें।<br/> उदाहरण के लिए, यदि आप $100 के साथ शुरू करते हैं और आप $25 पर लाभ पर रुकें सेट करते हैं',
'there_are_no_active_torunament': 'इस समय कोई एक्टिव टूर्नामेंट नहीं है।',
'third': 'तीसरा',
'this_game_is': 'यह गेम',
'this_tool_recalculates': 'यह टूल गेम हैश से गुणक की पुनः गणना करता है। मल्टीप्लायर की गणना करने के लिए आप किसी भी गेम हैश को कॉपी/पेस्ट कर सकते हैं।',
'time': 'समय',
'time_frame': 'निर्धारित समय-सीमा',
'to': 'तक',
'toggle_animation': 'एनिमेशन टॉगल करें',
'top_odds': 'शीर्ष ऑड्स',
'top_wins': 'शीर्ष जीत',
'total_bets': 'कुल बेट्स',
'tournament': 'टूर्नामेंट',
'tournament_information': 'टूर्नामेंट की जानकारी',
'try_advanced_autoplay': 'नई उन्नत ऑटोप्ले सुविधा आज़माएँ!',
'uncredited_wins': 'जीत, जिसे क्रेडिट नहीं किया गया है',
'user': 'यूजर',
'user_and_points': 'यूज़र और प्वाइंट्स',
'username': 'यूज़रनेम',
'username_must_be_at_least': 'यूज़रनेम न्यूनतम',
'verify': 'पुष्टि करना',
'vip_popup_text': 'आप हमारे VIP सदस्यों के लिए विशेष रूप से उपलब्ध विशेष VIP अवतार में से एक चुन सकते हैं!',
'vip_popup_title': 'VIP प्लेयर बनने की बधाई हो',
'waiting_for_the_next_round': 'अगले राउंड का इंतज़ार कर रहे हैं...',
'warnings_are_calculated': ' ‎• जीत की गणना कैशआउट इवेंट के समय गुणक द्वारा बेट के दांव को गुणा कर की जाती है',
'watch_take_off': 'अपने लकी रॉकेट को उड़ान भरते हुए और अपनी जीत की राशि बढ़ते हुए देखें।',
'watch_the_rocket': 'रॉकेट को देखें',
'we_have_noticed': '‎हमने नोटिस किया है कि आप पहली बार "Rocketman" खेल रहे हैं। क्या आप इसे कैसे खेलते हैं, की जानकारी देने वाला छोटा-सा वीडियो देखना चाहेंगे?',
'week': 'सप्ताह',
'welcome_to_rocketman': 'Rocketman में स्वागत है - एक अद्भुत और शानदार गेम! Rocketman के साथ आपके पास अपनी मूल दांव का 20.000x तक का जीतने का मौका होता है।',
'what_is_provably_fair': 'प्रोवाब्ली फेयर क्या है?',
'will_be_deposited': 'को पहले नियमित जीत के साथ प्लेयर के बैलेंस में जमा किया जाएगा',
'win': 'जीत',
'win_amount': 'जीत की राशि',
'win_gifs': 'जीत',
'winner': 'विजेता',
'wins': 'जीत',
'write_a_replay': 'जवाब लिखें...',
'year': 'वर्ष',
'yes': 'हां',
'yes_start_the_round': 'हां, राउंड शुरू करें',
'you_have_cashed_out': 'आपने कैश आउट किया है, आपकी जीत की राशि है ',
'you_have_won': 'आपने जीता है',
'your_bet_is_lost': 'तो आपकी बेट हार जाएगी।',
'current': 'मौजूदा',
'previous': 'पहले का',
'claim': 'दावा',
'this_feature_will_give': 'यह फीचर रैंडम यूजर्स को चैट में चयनित राशि देगा',
'amount_per_player': 'प्रति खिलाड़ी राशि',
'no_of_players': 'खिलाड़ियों की संख्या',
'meteor_shower': 'उल्का बौछार',
'claim_your_free_bet': 'अपनी निःशुल्क शर्त का दावा करें',
'meteor_shower_total': 'उल्का बौछार कुल',
'shower': 'बारिश',
'total': 'कुल',
'meteor_shower_incoming': 'उल्का बौछार आ रही है, चैट देखें!',
'insurance': 'बीमा',
'insurance_paragraph': 'खिलाड़ी अपनी बीट के लिए "बीट इंश्योरेंस" चेकबॉक्स पर क्लिक करके अपनी बीट के लिए बीमा खरीद सकते हैं। बीमा की कीमत कुल बीट राशि का 10% है और अगर रॉकेट 1.00 गुणांक पर फटती है, तो खिलाड़ी को बीट राशि वापस मिलेगी।',
'insure_your_bet': 'अपनी शर्त का बीमा करें',
'bet_insurance_promo_text': '1.00 गुणक पर रॉकेट क्रैश होने से अपनी शर्त का बीमा करने के लिए "बेट इंश्योरेंस" विकल्प चुनें।',
'bet_insurance_promo_text_helper': 'गेम नियम" अनुभाग में और पढ़ें...',
'game_explanation_1': 'अपना गुणक चुनें, और यदि खेल गुणक आपके चुने हुए गुणक से अधिक हो जाता है, तो आपकी जीत होगी',
'game_explanation_2': 'आपका गुणक x आपका दांव',
'retail_max_mp_1': 'तक जीतें',
'retail_max_mp_2': 'आपका दांव!',
'play_multibets': 'खेल',
'multibets': 'मल्टीबेट्स',
'bonus_game': 'बोनस खेल',
'all_wins_doubled': 'सभी जीतें दोगुनी हो गईं!',
'multibets_paragraph_1': 'अपनी संभावनाएँ बढ़ाने के लिए!',
'multibets_paragraph_2': 'आप दस राउंड तक के दांवों को एक मल्टीबेट में जोड़ सकते हैं और भारी भुगतान जीत सकते हैं!',
'multibets_paragraph_3': 'हर बार जब एक राउंड जीता जाता है, तो उस राउंड से प्राप्त लाभांश को अगले राउंड पर दांव पर लगाया जाता है, जब तक कि सभी राउंड जीत नहीं जाते।',
'bonus_launch': '„बोनस लॉन्च” राउंड में सभी दांवों पर x2 जीतें!',
'multiplier_history': 'गुणक इतिहास',
'will_start_soon': 'जल्द ही शुरू होगा',
'hurry_up_and_place_your_bets': 'जल्दी करें और अपना दांव लगाएं!',
'active_bets': 'सक्रिय दांव',
'shop': 'दुकान',
'game_meteor_shower_paragraph': 'उल्का बौछार खिलाड़ियों की पूर्वनिर्धारित संख्या को पूर्वनिर्धारित मूल्य का एक मुफ्त दांव प्रदान करता है। इसे या तो सर्वर द्वारा या किसी प्लेयर द्वारा शुरू किया जा सकता है। एक बार जब उल्का बौछार सक्रिय हो जाती है, तो गेम में एक संदेश आता है, जो उपयोगकर्ताओं को उस चैट को देखने के लिए निर्देशित करता है जहां उल्का बौछार संदेश स्थित है। खिलाड़ी "दावा करें" बटन पर क्लिक करके मुफ्त दांव का दावा कर सकते हैं। जो खिलाड़ी पहले "दावा करें" बटन पर क्लिक करते हैं, उन्हें मुफ़्त दांव दिए जाते हैं। खिलाड़ी क्लिक का अनुकरण करने और पुरस्कारों का दावा करने के लिए ऑटोक्लिकर्स या किसी भी प्रकार के ऑटोमेशन सॉफ़्टवेयर का उपयोग करना निषिद्ध है और इसके परिणामस्वरूप इस सुविधा पर प्रतिबंध लग सकता है।',
'claming_failed': 'दावा करना विफल रहा',
'player_on_blacklist': 'खिलाड़ी काली सूची में है',
'you_can_claim_ticket': 'आप टिकट का दावा केवल तभी कर सकते हैं जब आप नियमित दांव लगाने वाले सक्रिय खिलाड़ी हों',
'you_already_have_free_tickets': 'आपके पास पहले से ही मुफ़्त टिकट हैं, कृपया पहले इसे खर्च करें',
'please_play_the_game_in_portait_mode': 'कृपया खेल को पोर्ट्रेट मोड में खेलें।',
};
langArr.cs = {
'your_insurance_returns_you': 'Vaše pojištění vám vrátí',
'bet_insurance': 'Pojištění sázek',
'max_bet_is': 'Maximální sázka je',
'already_active_bet_of': 'a již existuje aktivní sázka',
'1_from_game_history': '‎1. Z historie hry',
'2_in_settings_window': '‎2. V okně nastavení',
'3_online_calculator': '‎3. Online kalkulačka',
'active_tournament_1': 'Aktivní turnaj ',
'active_tournament_2': 'zatím nemá žádné sázky.',
'add_another_bet': 'Přidat další sázku',
'advanced_autoplay': 'POKROČILÁ AUTOMATICKÁ HRA',
'advanced_autoplay_for_bet': 'Pokročilá automatická hra pro sázky',
'alien_saucer_free_bets': 'Sázky zdarma na létající talíř Alien Saucer',
'all_bets': 'Všechny sázky',
'all_in_your_hands': 'Vše je ve vašich rukou!',
'allow': 'Povolit',
'at': 'Na',
'austronaut_promo_credit': 'Propagační kredit Astronaut',
'auto_play_and_autocash': 'AUTOMATICKÁ HRA A AUTOMATICKÁ VÝPLATA ',
'autoplay': 'Automatická hra',
'autoplay_and_cashout': '‎02. Automatická hra a automatické vyplacení',
'back_to_home': 'Zpět na úvodní stránku',
'balance': 'Zůstatek',
'bet': 'SÁZKA',
'bet_amount': 'VÝŠE SÁZKY',
'bet_and_cashout': '‎01. Sázka a výplata peněz',
'bet_bets_tab': 'PODAT SÁZKU',
'bet_failed': 'Selhání sázky',
'bet_statistic': 'Statistika sázek',
'bet_time': 'Čas sázky',
'bet_wins': 'Vítězné sázky',
'bets': 'Sázky',
'biggest_odds': 'Největší kurzy',
'bonus_type': 'TYP BONUSU',
'bonusing_history': 'Historie bonusů',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Výherci Boosterpotu',
'but_remember_paragraph': 'Nezapomeňte však, že pokud jste nestihli provést Výplatu před výbuchem rakety Lucky Rocket,',
'calculate_raw_mp_to_decimal': 'Výpočet hrubého násobitele z desetinného čísla a zaokrouhlení na nejbližší celé číslo dolů',
'cancel': 'Zrušit',
'cant_post_media_content': 'Nemůžete často zveřejňovat mediální obsah',
'cashed_out': 'Vyplaceno',
'cashout': 'Výplata peněz',
'cashout_at': 'VÝPLATA PŘI',
'cashout_before': 'Vyplaťte peníze dříve, než raketa exploduje, a peníze jsou vaše!',
'change_avatar': 'Změnit avatar',
'change_username': 'Změnit uživatelské jméno',
'characters_long': 'znaků dlouhé',
'chat': 'Chat',
'chat_room': 'Chatovací místnost',
'checking_for_spam': 'Kontrolujeme spam...',
'close': 'Zavřít',
'coeff': 'KOEFICIENT',
'collect_win': 'Vybrat výhru',
'combined_seed': 'Kombinovaný seed',
'company': 'Společnost',
'congratulations_you_have_won_free_bets': 'Gratulujeme! Vyhráli jste bonusové sázky! Můžete si vsadit',
'convert_first_13_to_decimal': ' Převeďte prvních 13 bajtů na desítkovou soustavu',
'd': 'd',
'date': 'DATUM',
'date_and_time': 'Datum a čas',
'deny': 'Zakázat',
'detailed_game_rules': 'Podrobná pravidla hry',
'dissconnection_policy': 'PRAVIDLA PRO ODPOJENÍ',
'easy_as_one_two_three': 'Snadné jako facka',
'enter_username': 'Zadejte prosím požadované uživatelské jméno',
'error_handling': 'ZACHÁZENÍ S CHYBAMI',
'exit_full_screen': 'Ukončení celoobrazovkového režimu',
'first': 'První',
'first_time_playing': 'Hrajete hru "Rocketman" poprvé?',
'folow_the_three_easy_steps': 'Postupujte podle tří jednoduchých kroků a užijte si hru Rocketman! ',
'free_bets': 'SÁZKY ZDARMA',
'free_bets_desc': 'Vyhráli jste sázky zdarma! Sázky zdarma můžete kdykoli využít stisknutím tlačítka Start.',
'free_bets_header': 'Sázky zdarma',
'from': 'OD',
'fullscreen_mode': 'Pro lepší uživatelský zážitek se hra spustí v celoobrazovkovém režimu.',
'function': 'Funkce',
'game_functions': 'Funkce hry',
'game_history': 'HISTORIE HRY',
'game_r_1_paragraph': 'Hráč si může aktivovat možnost automatické hry, při níž se pro každé nové kolo automaticky uzavře sázka s použitím sázkové částky z předchozí sázky.',
'game_r_10_paragraph': 'Po udělení promo kreditů se za každou sázku, kterou hráč podá, nejprve odečtou prostředky z promo kreditů, dokud se nevyčerpají, a teprve poté se pokračuje ve vybírání peněz z peněženky hráče.',
'game_r_11_paragraph': 'Promo kredity nelze proplatit, ale případné výhry z takových sázek se hráči připíší v plné výši.',
'game_r_12_paragraph': 'Promo kredity mají 30denní platnost, po které vyprší a nelze je použít.',
'game_r_13_paragraph': 'Hráči mohou být sázky zdarma přiděleny náhodně. Na obrazovce se objeví vyskakovací grafika, která hráče informuje o udělených bezplatných sázkách, počtu sázek a částce za sázku.',
'game_r_14_paragraph': 'Po udělení bezplatných sázek si hráč může zvolit, kdy zahájí kolo bezplatných sázek. Po spuštění kola bezplatných sázek se automaticky uzavře sázka s určeným vkladem a je na hráči, zda si v daném okamžiku vybere peníze. Pokud se hráč odpojí během sázek zd',
'game_r_15_paragraph': 'Sázky zdarma mají 30denní platnost, po které vyprší a nelze je použít.',
'game_r_16a_paragraph': 'Celkový teoretický návrat hráči je ',
'game_r_17_paragraph': 'Procenta RTP jsou průběžně sledována a ověřována nezávislými třetími stranami.',
'game_r_18_paragraph': 'Pokud dojde ke ztrátě spojení po uzavření sázky, sázka se nezruší. Pokud násobitel dosáhne 20 000x, bude vaše sázka automaticky vyplacena a na váš účet budou připsány kredity.',
'game_r_19_paragraph': 'Ve hře můžete chatovat s ostatními hráči. Do chatovacího pole zadejte svůj vzkaz nebo se s ostatními hráči podělte o svou výherní sázku.',
'game_r_2_paragraph': 'Do pole "Automatická výplata" lze zadat násobitel automatické výplaty a po jeho zadání se hra automaticky vyplatí, pokud je dosaženo nastaveného násobitele.',
'game_r_20_paragraph': 'Hráčům, kteří zneužívají chat tím, že urážejí ostatní hráče nebo používají nevhodné a/nebo sprosté výrazy, budou práva k chatu zrušena.',
'game_r_21_paragraph': 'Hra Rocketman obsahuje krásnou hudbu na pozadí a zvukové efekty. Pokud si přejete hudbu a/nebo zvukové efekty vypnout, můžete tak učinit prostřednictvím rozevírací nabídky.',
'game_r_22_paragraph': 'Kliknutím na tlačítko "Moje sázky" si hráč může prohlédnout své poslední sázky. Pro další historii hry (např. delší období) může hráč potřebovat přístup k historii hráčů v samotné herní platformě.',
'game_r_23_paragraph': 'V případě chyby nemusí být kola zahájena včas. V takovém případě se náš tým podpory pokusí problém co nejdříve vyřešit a pokračovat v pravidelných herních kolech. V případě problému se samotným herním kolem budou všechny sázky zrušeny a hráčům bude vrácen',
'game_r_24_paragraph': 'Zpoždění sítě je nevyhnutelnou součástí internetového připojení. Všechny sázky a cashouty považujeme za platné až v okamžiku, kdy dorazí na náš server, bez ohledu na délku síťové latence. ',
'game_r_3_paragraph': 'Hráč může ve hře používat klávesové zkratky pro rychlé uzavření sázky nebo vyplacení peněz. Mapování kláves je následující',
'game_r_4_paragraph': '1 % z každé sázky jde do fondu progresivního jackpotu. Tento fond slouží systému progresivního jackpotu',
'game_r_5_paragraph': ' ‎• Jackpot - nejvyšší úroveň, největší výhra, nejméně častá výhra',
'game_r_6_paragraph': ' ‎• Rocketpot - střední úroveň, střední výhra',
'game_r_7_paragraph': ' ‎• Boosterpot - nejnižší úroveň, nízká výhra, nejčastější výhra ',
'game_r_8_paragraph': 'Všechny úrovně jackpotu jsou udělovány pomocí mechaniky "Mystery" nebo "must-hit-by". Hodnota každého jackpotu "must-hit-by" je určena ihned po výhře předchozího jackpotu generátorem náhodných čísel a uložena jako zašifrovaná hodnota ve vzdáleném počítači',
'game_r_8a_paragraph': 'Poznámka: Vítězem jackpotu může být pouze jeden hráč Rocketman, který aktivně hraje v kole, když byl spuštěn jackpot. Neexistuje žádná možnost, že pokud hráč vyhrál jackpot, musel by jej sdílet s jakýmkoli jiným hráčem Rocketman.',
'game_r_9_paragraph': 'Hráč může být náhodně odměněn promo kredity. Na obrazovce se objeví vyskakovací grafika, která hráče informuje o udělených promo kreditech a jejich výši.',
'game_round': 'herních kol!',
'game_rules': 'Pravidla hry',
'game_rules_1_paragraph': 'Rocketman je hazardní zábava nové generace. Vyhrajte mnohonásobně více během několika sekund! Rocketmanis je postaven na',
'game_rules_10_paragraph': ' ‎• Automatická hra se aktivuje na kartě "Auto" v panelu sázek zaškrtnutím políčka "Auto Bet". Po aktivaci se sázky podávají automaticky, ale pro vyplacení peněz byste měli v každém kole stisknout tlačítko "výplata". Pokud chcete, aby se sázka automaticky',
'game_rules_11_paragraph': '‎• Automatické vyplacení je k dispozici na kartě "Auto" na panelu sázek. Po aktivaci bude vaše sázka automaticky vyplacena, když dosáhne zadaného koeficientu.',
'game_rules_2_paragraph': '‎, který je v současné době jedinou skutečnou zárukou poctivosti v hazardním průmyslu.',
'game_rules_3_paragraph': '‎• Násobitel výhry začíná na 1× a s tím, jak Letadlo štěstí vzlétá, stále více roste.',
'game_rules_4_paragraph': ' ‎• Vaše výhry jsou násobkem koeficientu, kterým jste provedli Výplatu, a vaší sázky. ',
'game_rules_5_paragraph': '‎• Před začátkem každého kola náš prokazatelně spravedlivý generátor náhodných čísel vygeneruje koeficient, při kterém odletí Letadlo štěstí. Férovost tohoto generování si můžete ověřit kliknutím na protější výsledek na kartě Historie.',
'game_rules_6_paragraph': '‎• Vyberte částku a stisknutím tlačítka "Vsadit" proveďte sázku. ',
'game_rules_7_paragraph': '‎• Můžete uzavřít dvě sázky současně, a to přidáním druhého panelu sázek. Druhý sázkový panel přidáte stisknutím ikony plus, která se nachází v pravém horním rohu prvního sázkového panelu.',
'game_rules_8_paragraph': ' ‎• Stisknutím tlačítka "Výplata" vyplatíte své výhry. Vaše výhra je sázka vynásobená koeficientem pro výplatu.',
'game_rules_9_paragraph': '‎• Pokud jste sázku nevyplatili dříve, než letadlo odletělo, vaše sázka propadá.',
'general_rules': 'OBECNÁ PRAVIDLA',
'gifs': 'GIFS',
'go_back': 'Vrať se zpět',
'good_job_buddy': 'Dobrá práce, kamaráde, jen tak dál',
'h': 'h',
'hashed_in_sha_512': 'Hash v SHA512',
'hex': 'Hex',
'hey_username': 'Ahoj',
'hide_hex_to_decimal': 'Skrýt převod hexadecimálního čísla na desítkové',
'highscore': 'High Score',
'how_it_works': 'Jak to funguje?',
'how_to_check': 'Jak zkontrolovat?',
'how_to_play': 'Jak hrát',
'if_the_maximum_multiplier': '‎• Pokud dosáhnete maximálního násobitele 20 000, všechny otevřené sázky se automaticky vyplatí s maximálním násobitelem',
'increase': 'Zvýšit',
'input_bet_amount': 'Zadejte výši sázky a uzavřete sázku před startem.',
'insufficient_funds': 'Nedostatek finančních prostředků',
'it_blew_up': 'Vybuchlo to! No jo...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Výherci jackpotu',
'key': 'Klíč',
'load_more': 'VLOŽIT VÍCE',
'loading': 'Načítání',
'loading_next_round': 'Načítání dalšího kola....',
'loss_gifs': 'PROHRA',
'lost_connection': 'Ztracené spojení',
'make_a_bet': 'Vsaďte si',
'malfunction_voids': 'Porucha zruší platnost všech výplat a her.',
'maximum_payout': 'Maximální výplata je ',
'menu': 'Menu',
'min_bet_is': 'Minimální sázka činí',
'month': 'Měsíc',
'more_details': 'Další podrobnosti',
'more_information': 'Více informací',
'multiplier': 'Násobitel',
'multiplier_starts': '‎• Násobitel začíná na 1 a dosahuje až 20 000',
'multipliers_larger': 'Násobitele větší než x20 000 jsou omezeny na maximální hodnotu násobitele',
'music_settings': 'Nastavení hudby',
'my_bet_history': 'Historie mých sázek',
'my_bets': 'Moje sázky',
'my_bonysing_history': 'Moje historie bonusů',
'network_latency': 'ZPOŽDĚNÍ SÍTĚ',
'network_latency_content': 'Zpoždění sítě je nevyhnutelnou součástí internetového připojení. Všechny sázky a cashouty považujeme za platné až v okamžiku, kdy dorazí na náš server, bez ohledu na délku síťové latence.',
'network_latency_title': 'ZPOŽDĚNÍ SÍTĚ',
'no_thanks': 'Ne, děkujeme!',
'not_right_now': 'Teď ne',
'off': 'Vypnuto',
'oh_snap': 'Oh Snap!',
'ok_close': 'Dobře, zavíráme!',
'ok_thanks': 'Dobře, děkujeme!',
'on': 'Zapnuto',
'on_loss': 'PŘI ZTRÁTĚ',
'on_win': 'PŘI VÝHŘE',
'online': 'Online',
'per_bet': 'na sázku',
'pick_your_avatar_img': 'Vyberte si svůj obrázek avataru',
'place_bet_with_last': 'Uzavření sázky s posledním vybraným vkladem ',
'play_now': 'Hrajte nyní',
'player_1_seed': 'Hráč 1 seed',
'player_2_seed': 'Hráč 2 seed',
'player_3_seed': 'Hráč 3 seed',
'please_wait_active_bets': 'Pro aktivaci sázek zdarma počkejte na dokončení aktuálních sázek.',
'points': 'BODY',
'powered_by': 'Powered by',
'previous_hand': 'Předchozí rozdání',
'prize': 'CENA',
'prizes': 'Ceny',
'progressive_jackpot': 'PROGRESIVNÍ JACKPOT',
'promo_credit': 'Propagační kredit',
'promo_credit_condradulations': 'Gratulujeme! Vyhráli jste astronomický promo kredit, který můžete využít ve hře!',
'promo_credits': 'PROMO KREDITY',
'promo_history': 'Historie promo akcí',
'provably_fair': 'Provably Fair (Prokazatelně spravedlivé)',
'provably_fair_1_paragraph': '‎Hra \'Rocketman\' je založen na kryptografické technologii nazvané \'Provably Fair\'. Tato technologie zaručuje 100% spravedlnost výsledků hry. Díky této technologii je nemožné, aby do průběhu hry zasahovala jakákoli třetí strana.',
'provably_fair_2_paragraph': 'Výsledek každého kola (koeficient hry \'Fly away\') není generován na našich serverech. Je generován s pomocí hráčů kola a je zcela transparentní. Tímto způsobem není možné, aby kdokoli manipuloval s výstupem hry. Kdokoli jej také může zkontrolovat a potv',
'provably_fair_3_paragraph': 'Výsledek kola je generován ze čtyř nezávislých účastníků kola',
'provably_fair_4_paragraph': 'Při zahájení kola hra sloučí serverový seed se třemi klientskými semeny. Ze sloučených symbolů se vygeneruje hash SHA512, a z tohoto hashe výsledek hry.',
'provably_fair_5_paragraph': 'Férovost každého kola můžete zkontrolovat z historie hry kliknutím na zelenou ikonu. V otevřeném okně uvidíte nasazení serveru, nasazení 3 dvojic hráčů, kombinovaný hash a výsledek kola.',
'provably_fair_6_paragraph': 'Hashovaná verze seedu serveru pro další kola je veřejně dostupná v okně nastavení (v uživatelské nabídce zaškrtněte políčko "Prokazatelně férové nastavení" a poté " Následující seed serveru SHA256"). Zde můžete také změnit klientský seed. Pokud se chcete ',
'provably_fair_7_paragraph': 'Správnost hashe lze zkontrolovat v libovolné online kalkulačce, např.',
'provably_fair_system': 'prokazatelně spravedlivém systému',
'quick_explanation': 'Stručné vysvětlení',
'randomly_generated_server_hash': 'Náhodně vygenerovaný server HASH',
'rank': 'POŘADÍ',
'raw_multiplier': 'hrubý násobitel',
'raw_multiplier_to_edged': 'Hrubý násobitel na upravený násobitel pomocí',
'remove_additional_bet': 'Odebrat další sázku',
'reset': 'Obnovit',
'return_to_player': 'NÁVRATNOST HRÁČI',
'rocketman_is_pure_excitement': 'Rocketman je čisté vzrušení! Riskujte a vyhrajte.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Výherci Rocketpotu',
'round': 'Kolo',
'round_id': 'Id kola',
'round_multiplier': 'Násobitel kola',
'round_results': 'výsledky kola',
'rounds': 'kola',
'rtp': 'RTP',
'rules': 'pravidla',
'rules_details': 'Pravidla turnaje',
'save': 'Uložit',
'schedule': 'Rozpis turnajů',
'second': 'Druhý',
'seconds_left': 's zbývá',
'server_hash': 'HASH serveru',
'please_wait_current_bets': 'ČEKEJTE PROSÍM NA DOKONČENÍ AKTUÁLNÍCH SÁZEK',
'min_autocashout_value_must_be': 'MUSÍ BÝT MINIMÁLNÍ HODNOTA AUTOCASHOUT',
'min_bet_value_must_be': 'MINIMÁLNÍ HODNOTA SÁZKY MUSÍ BÝT',
'server_seed': 'Server Seed',
'session_dissconnected': 'Odpojená relace',
'multisession_not_allowed': 'Multisession není povolena',
'set_auto_bet': 'NASTAVIT AUTOMATICKOU SÁZKU',
'set_auto_cashout': 'NASTAVIT AUTOMATICKOU VÝPLATU',
'shourtcut_keys': 'KLÁVESOVÉ ZKRATKY',
'show_hex_to_deimal': 'Zobrazit převod hexadecimálního čísla na desítkové',
'sound': 'ZVUK',
'sound_settigs': 'Nastavení zvuku',
'start_with_autoplay': 'Začněte s automatickou hrou',
'stop_autoplay': 'ZASTAVIT AUTOMATICKOU HRU',
'stop_on_loss': 'ZASTAVIT NA ZTRÁTĚ',
'stop_on_loss_tooltip': 'Pomocí funkce Stop on Loss (Zastavit při ztrátě) zastavíte automatickou sázku po ztrátě určité částky.<br/> Například pokud začnete se 100 dolary a nastavíte Stop on Loss na 25 dolarů',
'stop_on_profit': 'ZASTAVIT PŘI ZISKU',
'stop_on_profit_tooltip': 'Pomocí funkce Stop on Profit (Zastavit při zisku) zastavíte automatickou sázku po dosažení zisku v určité výši.<br/> Například pokud začnete se 100 dolary a nastavíte Stop on Profit na 25 dolarů',
'there_are_no_active_torunament': 'V tuto chvíli nejsou žádné aktivní turnaje.',
'third': 'třetí',
'this_game_is': 'Tato hra je',
'this_tool_recalculates': 'Tento nástroj přepočítá násobitel z herního hashe. Pro ověření násobitele můžete zkopírovat/vložit jakýkoli herní hash.',
'time': 'ČAS',
'time_frame': 'Časový rámec',
'to': 'DO',
'toggle_animation': 'Přepnout animace',
'top_odds': 'Nejlepší kurzy',
'top_wins': 'Nejvyšší výhry',
'total_bets': 'Celkový počet sázek',
'tournament': 'Turnaj',
'tournament_information': 'Informace o turnaji',
'try_advanced_autoplay': 'Vyzkoušejte novou pokročilou funkci automatického přehrávání!',
'uncredited_wins': 'Nepřipsané výhry',
'user': 'UŽIVATELSKÝ',
'user_and_points': 'UŽIVATEL A BODY',
'username': 'Uživatelské jméno',
'username_must_be_at_least': 'Uživatelské jméno musí být alespoň',
'verify': 'Ověřit',
'vip_popup_text': 'Můžete si vybrat jeden ze speciálních VIP avatarů, které jsou k dispozici výhradně pro naše VIP členy!',
'vip_popup_title': 'Gratulujeme, že jste se stali VIP hráčem',
'waiting_for_the_next_round': 'Čekáme na další kolo...',
'warnings_are_calculated': ' ‎• Výhry se vypočítají vynásobením sázkového vkladu násobitelem v okamžiku události Cashout',
'watch_take_off': 'Sledujte, jak vaše raketa štěstí startuje a jak se vaše výhra zvyšuje.',
'watch_the_rocket': 'Sledujte raketu',
'we_have_noticed': '‎`Všimli jsme si, že hrajete hru "Rocketman" poprvé. Chtěli byste se podívat na krátké video, které vám vysvětlí, jak se hraje?`',
'week': 'Týden',
'welcome_to_rocketman': 'Vítejte ve hře Rocketman - neotřelé a vzrušující hře! S hrou Rocketman máte šanci vyhrát až 20 000násobek původní sázky.',
'what_is_provably_fair': 'Co je Provably Fair?',
'will_be_deposited': 'Budou připsány na zůstatek hráče s první regulérní výhrou.',
'win': 'VÝHRA',
'win_amount': 'Výše výhry',
'win_gifs': 'VÝHRA',
'winner': 'Vítěz',
'wins': 'Vyhrává',
'write_a_replay': 'Napište odpověď...',
'year': 'Rok',
'yes': 'ANO',
'yes_start_the_round': 'Ano, zahajte kolo',
'you_have_cashed_out': 'Vyplatili jste peníze, vaše výhra činí ',
'you_have_won': 'VYHRÁLI JSTE',
'your_bet_is_lost': 'vaše sázka je prohraná.',
'current': 'Aktuální',
'previous': 'Předchozí',
'claim': 'Nárok',
'this_feature_will_give': 'Tato funkce poskytne vybranou částku náhodným uživatelům v chatu',
'amount_per_player': 'Částka na hráče',
'no_of_players': 'Počet hráčů',
'meteor_shower': 'Meteorický roj',
'claim_your_free_bet': 'Získejte svou bezplatnou sázku',
'meteor_shower_total': 'Meteorický roj celkem',
'shower': 'DÉŠŤ',
'total': 'Celkový',
'meteor_shower_incoming': 'Přichází meteorický roj, podívejte se na chat!',
'insurance': 'POJIŠTĚNÍ',
'insurance_paragraph': 'Hráči si mohou ke své sázce zakoupit pojištění kliknutím na zaškrtávací políčko „Pojištění sázek“ vedle tlačítka sázky. Pojištění stojí 10 % z celkové částky vsazené částky a vrátí hráči částku vsazené v případě, že raketa exploduje při násobku 1,00.',
'insure_your_bet': 'POJIŠTĚTE SI SÁZKU',
'bet_insurance_promo_text': 'Vyberte možnost "Pojištění sázek", chcete-li pojistit svou sázku před raketovým pádem při násobku 1,00.',
'bet_insurance_promo_text_helper': 'Více v sekci "Pravidla hry"...',
'game_explanation_1': 'Vyberte si multiplikátor, a pokud herní multiplikátor překročí vámi zvolený multiplikátor, vaše výhra bude',
'game_explanation_2': 'Váš multiplikátor x vaše sázka',
'retail_max_mp_1': 'Vyhrajte až',
'retail_max_mp_2': 'vaše sázka!',
'play_multibets': 'Hrát si',
'multibets': 'MULTIBETY',
'bonus_game': 'BONUSOVÁ HRA',
'all_wins_doubled': 'Všechny výhry se zdvojnásobily!',
'multibets_paragraph_1': 'zvýšit své šance!',
'multibets_paragraph_2': 'Můžete kombinovat sázky až na deset kol do jediné multibety a vyhrát masivní výplaty!',
'multibets_paragraph_3': 'Pokaždé, když je kolo vyhráno, vítězná dividenda z tohoto kola se vsadí na další kolo, dokud všechna kola nevyhrají.',
'bonus_launch': 'Vyhrajte 2x na všechny sázky v kole „Bonus Launch“!',
'multiplier_history': 'Historie multiplikátoru',
'will_start_soon': 'začne brzy',
'hurry_up_and_place_your_bets': 'Pospěšte si a sázejte!',
'active_bets': 'Aktivní sázky',
'shop': 'PRODEJNA',
'game_meteor_shower_paragraph': 'Meteor Shower uděluje jednu volnou sázku předem definované hodnoty předem definovanému počtu hráčů. Může být spuštěn buď serverem nebo hráčem. Jakmile je Meteorický roj aktivován, ve hře se objeví zpráva, která uživatele nasměruje, aby se podívali na chat, kde se zpráva Meteorického roje nachází. Hráči si mohou nárokovat sázku zdarma kliknutím na tlačítko „Nárokovat“. Sázky zdarma získávají hráči, kteří jako první kliknou na tlačítko „Nárokovat“. Používání automatických kliknutí nebo jakéhokoli automatizačního softwaru k napodobování kliknutí hráčů a získávání cen je zakázáno a může mít za následek zákaz této funkce.',
'claming_failed': 'Nárokování se nezdařilo',
'player_on_blacklist': 'Hráč je na černé listině',
'you_can_claim_ticket': 'Tiket můžete uplatnit pouze v případě, že jste aktivním hráčem s běžnými sázkami',
'you_already_have_free_tickets': 'Vstupenky zdarma již máte, nejdříve je prosím utraťte',
'please_play_the_game_in_portait_mode': 'Hrajte prosím hru v režimu na výšku.',
};
langArr.id = {
'your_insurance_returns_you': 'Asuransi Anda mengembalikan Anda',
'bet_insurance': 'Asuransi Taruhan',
'max_bet_is': 'Taruhan maksimalnya adalah',
'already_active_bet_of': 'dan sudah ada taruhan aktif',
'1_from_game_history': '‎1. Dari riwayat permainan',
'2_in_settings_window': '‎2. Di jendela pengaturan',
'3_online_calculator': '‎3. Kalkulator online',
'active_tournament_1': 'Turnamen aktif ',
'active_tournament_2': 'belum memiliki taruhan.',
'add_another_bet': 'Tambahkan taruhan lain',
'advanced_autoplay': 'PERMAINAN OTOMATIS LANJUTAN',
'advanced_autoplay_for_bet': 'Permainan Otomatis Lanjutan Untuk Taruhan',
'alien_saucer_free_bets': 'Taruhan Gratis Pesawat Alien',
'all_bets': 'Semua Taruhan',
'all_in_your_hands': 'Semuanya ada di tangan Anda!',
'allow': 'Izinkan',
'at': 'di',
'austronaut_promo_credit': 'Kredit Promo Astronaut',
'auto_play_and_autocash': 'PERMAINAN OTOMATIS DAN PENARIKAN OTOMATIS ',
'autoplay': 'Permainan otomatis',
'autoplay_and_cashout': '‎02. Permainan Otomatis & penarikan otomatis',
'back_to_home': 'Kembali ke beranda',
'balance': 'Saldo',
'bet': 'TARUHAN',
'bet_amount': 'JUMLAH TARUHAN',
'bet_and_cashout': '‎01. Taruhan & Penarikan',
'bet_bets_tab': 'JUMLAH TARUHAN',
'bet_failed': 'Taruhan Gagal',
'bet_statistic': 'Statistik Taruhan',
'bet_time': 'Waktu Taruhan',
'bet_wins': 'Kemenangan Taruhan',
'bets': 'Taruhan',
'biggest_odds': 'Odd Terbesar',
'bonus_type': 'JENIS BONUS',
'bonusing_history': 'Riwayat Bonus',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Pemenang Boosterpot',
'but_remember_paragraph': 'Tapi ingat, jika Anda tidak sempat menarik uang sebelum Roket Keberuntungan meledak,',
'calculate_raw_mp_to_decimal': 'Hitung Pengganda Mentah dari desimal, lalu bulatkan ke bawah ke bilangan bulat terdekat',
'cancel': 'Batalkan',
'cant_post_media_content': 'Konten media tidak bisa sering dipublikasikan',
'cashed_out': 'Dibayarkan',
'cashout': 'Pembayaran',
'cashout_at': 'PENARIKAN SEBESAR',
'cashout_before': 'Tarik uang sebelum roket meledak, dan uang tersebut akan menjadi milik Anda!',
'change_avatar': 'Ubah Avatar',
'change_username': 'Ubah Nama Pengguna',
'characters_long': 'karakter',
'chat': 'Obrolan',
'chat_room': 'Ruang Obrolan',
'checking_for_spam': 'Memeriksa spam…',
'close': 'Tutup',
'coeff': 'KOEF.',
'collect_win': 'Kumpulkan Kemenangan',
'combined_seed': 'Seed gabungan',
'company': 'Perusahaan',
'congratulations_you_have_won_free_bets': 'Selamat! Anda memenangkan Taruhan Bonus! Anda bisa bertaruh',
'convert_first_13_to_decimal': ' Ubah 13 bit pertama ke desimal',
'd': 'h',
'date': 'TANGGAL',
'date_and_time': 'Tanggal dan Waktu',
'deny': 'Tolak',
'detailed_game_rules': 'Aturan Permainan Terperinci',
'dissconnection_policy': 'KEBIJAKAN KONEKSI TERPUTUS',
'easy_as_one_two_three': 'Tiga langkah mudah',
'enter_username': 'Harap masukkan nama pengguna yang diinginkan.',
'error_handling': 'KESALAHAN PENANGANAN',
'exit_full_screen': 'Keluar dari mode layar penuh',
'first': 'pertama',
'first_time_playing': 'Pertama kali memainkan "Rocketman"?',
'folow_the_three_easy_steps': 'Ikuti tiga langkah mudah, dan nikmati Rocketman! ',
'free_bets': 'TARUHAN GRATIS',
'free_bets_desc': 'Anda memnangkan taruhan gratis! Anda bisa memanfaatkan taruhan gratis kapan saja dengan menekan tombol mulai.',
'free_bets_header': 'Taruhan Gratis',
'from': 'DARI',
'fullscreen_mode': 'Untuk pengalaman pengguna yang lebih baik, permainan akan dimulai dalam mode layar penuh.',
'function': 'Fungsi',
'game_functions': 'Fungsi Permainan',
'game_history': 'RIWAYAT PERMAINAN',
'game_r_1_paragraph': 'Pemain dapat mengaktifkan opsi permainan otomatis di mana taruhan dipasang secara otomatis untuk setiap ronde baru dengan menggunakan jumlah taruhan dari taruhan sebelumnya.',
'game_r_10_paragraph': 'Begitu kredit promo diberikan, untuk setiap taruhan yang dipasang oleh pemain, uang tersebut pertama-tama akan dikurangi dari kredit promo hingga semuanya digunakan, sebelum melanjutkan dengan menarik dana dari dompet pemain.',
'game_r_11_paragraph': 'Kredit promo tidak bisa ditarik tunai, tapi semua kemenangan dari taruhan ini akan dikreditkan kepada pemain sepenuhnya.',
'game_r_12_paragraph': 'Kredit promo memiliki masa berlaku 30 hari, yang mana setelahnya kredit akan kedaluwarsa dan tidak dapat digunakan.',
'game_r_13_paragraph': 'Pemain dapat secara acak menerima taruhan gratis. Sebuah grafik popup akan muncul di layar untuk memberitahukan kepada pemain mengenai taruhan gratis yang dihadiahkan, jumlah taruhan, dan nillai per taruhan.',
'game_r_14_paragraph': 'Begitu taruhan gratis diberikan, pemain dapat memilih kapan memulai ronde taruhan gratis. Setelah ronde taruhan gratis dimulai, sebuah taruhan otomatis dengan jumlah yang ditetapkan akan dipasang, dan pemain dapat memutuskan untuk menarik uang kapan saja.',
'game_r_15_paragraph': 'Taruhan gratis memiliki masa berlaku 30 hari, yang mana setelahnya taruhan akan kedaluwarsa dan tidak dapat digunakan.',
'game_r_16a_paragraph': 'Pengembalian teoretis keseluruhan kepada pemain adalah ',
'game_r_17_paragraph': 'Persentase RTP dipantau secara terus-menerus dan diverifikasi oleh pihak ketiga yang independen.',
'game_r_18_paragraph': 'Jika koneksi terputus setelah taruhan dipasang, taruhan tidak akan dibatalkan. Jika pengganda mencapai 20.000x, taruhan Anda akan ditarik secara otomatis, dan kedit akan diterapkan ke akun Anda.',
'game_r_19_paragraph': 'Anda bisa mengobrol dalam permainan dengan pemain lain. Masukkan pesan Anda di bidang obrolan, atau bagikan taruhan kemenangan Anda dengan pemain lain.',
'game_r_2_paragraph': 'Pengganda penarikan otomatis dapat dimasukkan di bidang "Penarikan otomatis", dan saat ini dimasukkan, permainan akan menarik uang secara otomatis saat nilai pengganda yang ditetapkan tercapai.',
'game_r_20_paragraph': 'Jika pemain menyalahgunakan Obrolan dengan melecehkan pemain lain, atau dengan menggunakan kata-kata tidak sopan dan/atau kasar, hak istimewanya atas Obrolan akan dinonaktifkan.',
'game_r_21_paragraph': 'Rocketman didesain dengan musik latar dan efek suara yang indah. Jika Anda ingin menonaktifkan musik dan/atau efek suara, Anda bisa melakukannya melalui menu tarik turun.',
'game_r_22_paragraph': 'Dengan mengeklik tombol "Taruhan Saya", pemain dapat melihat kembali taruhan-taruhan terakhirnya. Untuk Riwayat permainan lebih lengkap (yaitu periode lebih Panjang), pemain mungkin harus mengakses riwayat pemain dalam platform permainan itu sendiri.',
'game_r_23_paragraph': 'Jika terjadi kesalahan, ronde permainan mungkin tidak dimulai tepat waktu. Jika ini terjadi, tim dukungan kami akan mencoba memecahkan masalahnya sesegera mungkin, dan ronde permainan biasa akan dilanjutkan. Jika terjadi masalah dengan ronde permainan itu',
'game_r_24_paragraph': 'Latensi jaringan adalah bagian yang tidak terhindarkan dari koneksi internet. Kami menganggap semua taruhan dan penarikan dana valid hanya pada saat permintaan ini mencapai server kami, terlepas dari durasi latensi jaringan. ',
'game_r_3_paragraph': 'Pemain dapat menggunakan tombol pintas untuk memasang taruhan atau menarik uang dengan cepat dalam permainan. Pemetaan tombol adalah sebagai berikut ',
'game_r_4_paragraph': '1% dari setiap taruhan didedikasikan untuk dana jackpot progresif. Dana ini digunakan untuk sistem jackpot progresif',
'game_r_5_paragraph': ' ‎• Jackpot – Level tertinggi, hadiah terbesar, paling jarang',
'game_r_6_paragraph': ' ‎• Rocketpot – Level medium, hadiah medium',
'game_r_7_paragraph': ' ‎• Boosterpot – Level teredah, hadiah kecil, paling sering ',
'game_r_8_paragraph': 'Semua level jackpot diberikan dengan mekanisme "Misteri" atau "harus didapatkan sebelum". Nilai setiap jackpot yang "harus didapatkan sebelum" akan ditetapkan segera setelah jackpot yang terdahulu dimenangkan dengan generator angka acak, dan disimpan seba',
'game_r_8a_paragraph': 'Catatan: Pemenang jackpot hanya dapat berupa satu pemain Rocketman yang aktif bermain dalam putaran tersebut ketika jackpot telah dipicu. Tidak ada kemungkinan jika pemain telah memenangkan jackpot, harus membaginya dengan pemain Rocketman lainnya.',
'game_r_9_paragraph': 'Pemain dapat secara acak menerima kredit promo. Sebuah grafik popup akan muncul di layar untuk memberitahukan kepada pemain mengenai kredit promo yang dihadiahkan dan jumlahnya.',
'game_round': 'ronde permainan!',
'game_rules': 'Aturan Permainan',
'game_rules_1_paragraph': 'Rocketman adalah hiburan perjudian generasi baru. Menang besar hanya dalam hitungan detik! Rocketman dibangun dengan',
'game_rules_10_paragraph': ' ‎• Permainan Otomatis diaktifkan dari tab "Auto" di Panel Taruhan, dengan mencentang kotak "Taruhan Otomatis". Setelah diaktifkan, taruhan akan dipasang secara otomatis, tapi untuk menarik dana, Anda harus menekan tombol “penarikan” di setiap ronde. Jika',
'game_rules_11_paragraph': '‎• Penarikan Otomatis tersedia dari tab “Auto” di panel taruhan. Setelah diaktifkan, taruhan Anda akan ditarik secara otomatis saat taruhan tersebut mencapai koefisien yang ditetapkan.',
'game_rules_2_paragraph': '‎, yang saat ini merupakan satu-satunya jaminan kejujuran sesungguhnya di industri perjudian.',
'game_rules_3_paragraph': '‎• Pengganda kemenangan awal adalah 1x, dan bertumbuh lagi dan lagi saat Roket Keberuntungan lepas landas.',
'game_rules_4_paragraph': ' ‎• Kemenangan Anda adalah nilai pengganda saat Anda melakukan Penarikan dana yang dikalikan dengan taruhan Anda. ',
'game_rules_5_paragraph': '‎• Sebelum setiap ronde dimulai, generator angka acak kami yang terbukti adil akan menghasilkan koefisien di mana Roket Keberuntungan akan terbang. Anda bisa memeriksa kejujuran proses ini dengan mengeklik di seberang hasil di tab Riwayat.',
'game_rules_6_paragraph': '‎• Pilih jumlah taruhan, dan tekan tombol “Taruhan” untuk memasang taruhan. ',
'game_rules_7_paragraph': '‎• Anda bisa memasang dua taruhan secara bersamaan, dengan menambahkan panel taruhan kedua. Untuk menambahkan panel taruhan kedua, tekan ikon plus, yang terletak di pojok kanan atas dari panel taruhan pertama.',
'game_rules_8_paragraph': ' ‎• Tekan tombol “Penarikan” untuk menarik kemenangan Anda. Kemenangan Anda adalah nilai taruhan yang dikalikan dengan koefisien penarikan.',
'game_rules_9_paragraph': '‎• Taruhan Anda kalah jika Anda tidak menarik dana sebelum roket terbang menjauh.',
'general_rules': 'ATURAN UMUM',
'gifs': 'GIF',
'go_back': 'Kembali',
'good_job_buddy': 'Kerja bagus, lanjutkan',
'h': 'j',
'hashed_in_sha_512': 'Hash SHA512',
'hex': 'Heks',
'hey_username': 'Hei',
'hide_hex_to_decimal': 'Sembunyikan konversi heks ke desimal',
'highscore': 'High Score',
'how_it_works': 'Bagaimana cara kerjanya ?',
'how_to_check': 'Bagaimana memeriksanya?',
'how_to_play': 'Cara bermain',
'if_the_maximum_multiplier': '‎• Jika pengganda maksimum sebesar 20.000 tercapai, semua taruhan terbuka akan ditarik secara otomatis pada pengganda maksimum',
'increase': 'Tingkatkan',
'input_bet_amount': 'Masukkan jumlah taruhan, dan pasang taruhan Anda sebelum lepas landas.',
'insufficient_funds': 'Dana tidak mencukupi',
'it_blew_up': 'Yah… roketnya meledak!',
'jackpot': 'Jackpot',
'jackpot_winners': 'Pemenang Jackpot',
'key': 'Tombol',
'load_more': 'MUAT LEBIH BANYAK',
'loading': 'Memuat',
'loading_next_round': 'Memuat ronde berikutnya…',
'loss_gifs': 'KEKALAHAN',
'lost_connection': 'Koneksi Hilang',
'make_a_bet': 'Buat Taruhan',
'malfunction_voids': 'Malafungsi membatalkan semua Pembayaran dan Permainan.',
'maximum_payout': 'Pembayaran maksimum adalah ',
'menu': 'Menu',
'min_bet_is': 'Taruhan min adalah',
'month': 'Bulan',
'more_details': 'Detail Selengkapnya',
'more_information': 'Informasi selengkapnya',
'multiplier': 'Pengganda',
'multiplier_starts': '‎• Pengganda dimulai sebesar 1, dan naik hingga 20.000',
'multipliers_larger': 'Pengganda yang lebih tinggi dari x20.000 dibatasi pada nilai pengganda maks',
'music_settings': 'Pengaturan Musik',
'my_bet_history': 'Riwayat Taruhan',
'my_bets': 'Taruhan Saya',
'my_bonysing_history': 'Riwayat Bonus Saya',
'network_latency': 'LATENSI JARINGAN',
'network_latency_content': 'Latensi jaringan adalah bagian yang tidak terhindarkan dari koneksi internet. Kami menganggap semua taruhan dan penarikan dana valid hanya pada saat permintaan ini mencapai server kami, terlepas dari durasi latensi jaringan.',
'network_latency_title': 'LATENSI JARINGAN',
'no_thanks': 'Tidak, terima kasih!',
'not_right_now': 'Nanti dulu',
'off': 'mati',
'oh_snap': 'Astaga!',
'ok_close': 'Oke, Tutup!',
'ok_thanks': 'Oke, Terima kasih!',
'on': 'menyala',
'on_loss': 'PADA KEKALAHAN',
'on_win': 'PADA KEMENANGAN',
'online': 'Online',
'per_bet': 'per taruhan',
'pick_your_avatar_img': 'Pilih gambar avatar Anda',
'place_bet_with_last': 'Pasang taruhan dengan nilai taruhan yang dipilih terakhir ',
'play_now': 'Main Sekarang',
'player_1_seed': 'Seed Pemain 1',
'player_2_seed': 'Seed Pemain 2',
'player_3_seed': 'Seed Pemain 3',
'please_wait_active_bets': 'Harap tunggu hingga taruhan saat ini selesai untuk mengaktifkan taruhan gratis',
'points': 'POIN',
'powered_by': 'Dipersembahkan oleh',
'previous_hand': 'Tangan Sebelumnya',
'prize': 'HADIAH',
'prizes': 'hadiah',
'progressive_jackpot': 'JACKPOT PROGRESIF',
'promo_credit': 'Kredit Promo',
'promo_credit_condradulations': 'Selamat! Anda telah memenangkan kredit Promo yang sangat hebat yang bisa Anda gunakan dalam permainan!',
'promo_credits': 'KREDIT PROMO',
'promo_history': 'Riwayat Promo',
'provably_fair': 'Terbukti Adil',
'provably_fair_1_paragraph': '‎\'Rocketman\' berdasar pada teknologi kriptografik yang disebut dengan \'Terbukti Adil\'. Teknologi ini menjamin 100% keadilan hasil permainan. Dengan teknologi ini, tidak memungkinkan bagi pihak ketiga untuk ikut campur dalam proses permainan.',
'provably_fair_2_paragraph': 'Hasil setiap ronde (Koefisien \'Terbang Menjauh\' di Permainan) tidak dihasilkan di server kami. Ini dihasilkan dengan bantuan para pemain di ronde, dan bersifat sepenuhnya transparan. Dengan demikian, tidak memungkinkan bagi siapa pun untuk memanipulasi ',
'provably_fair_3_paragraph': 'Hasil ronde dihasilkan dari empat peserta independen di ronde tersebut',
'provably_fair_4_paragraph': 'Saat ronde dimulai, permainan menggabungkan seed server dengan tiga seed klien. Dari simbol-simbol yang digabungkan, hash SHA512 dihasilkan, dan dari hash ini – hasil permainan.',
'provably_fair_5_paragraph': 'Anda bisa memeriksa keadilan setiap ronde dari riwayat permainan, dengan mengeklik ikon hijau. Di jendela yang terbuka, Anda akan melihat seed server, 3 pasang seed pemain, hash gabungan, dan hasil ronde.',
'provably_fair_6_paragraph': 'Versi hash dari seed server ronde berikutnya tersedia secara umum di jendela pengaturan (Di menu pengguna, periksa "Pengaturan Terbukti Adil", lalu “Seed server SHA256 berikutnya"). Anda juga bisa mengubah seed klien Anda di sini. Jika Anda ingin berparti',
'provably_fair_7_paragraph': 'Akurasi hash dapat diperiksa di kalkulator online apa saja, misalnya',
'provably_fair_system': 'sistem yang terbukti adil',
'quick_explanation': 'Penjelasan cepat',
'randomly_generated_server_hash': 'HASH server yang dihasilkan secara acak',
'rank': 'PERINGKAT',
'raw_multiplier': 'pengganda mentah',
'raw_multiplier_to_edged': 'Pengganda Mentah ke Pengganda Diproses, menggunakan',
'remove_additional_bet': 'Hapus taruhan tambahan',
'reset': 'Atur ulang',
'return_to_player': 'KEUNTUNGAN UNTUK PEMAIN',
'rocketman_is_pure_excitement': 'Rocketman adalah kesenangan murni! Ambil risiko dan menangkan.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Pemenang Rocketpot',
'round': 'Ronde',
'round_id': 'Id Ronde',
'round_multiplier': 'Pengganda Ronde',
'round_results': 'hasil ronde',
'rounds': 'ronde',
'rtp': 'RTP',
'rules': 'aturan',
'rules_details': 'Aturan turnamen',
'save': 'Simpan',
'schedule': 'jadwal',
'second': 'kedua',
'seconds_left': 'detik tersisa',
'server_hash': 'HASH Server',
'please_wait_current_bets': 'HARAP TUNGGU SAMPAI TARUHAN SAAT INI SAMPAI SELESAI',
'min_autocashout_value_must_be': 'NILAI AUTOCASHOUT MIN HARUS',
'min_bet_value_must_be': 'NILAI TARUHAN MIN HARUS',
'server_seed': 'Seed Server',
'session_dissconnected': 'Sesi Terputus',
'multisession_not_allowed': 'Multisesi tidak diizinkan',
'set_auto_bet': 'ATUR TARUHAN OTOMATIS',
'set_auto_cashout': 'ATUR PENARIKAN OTOMATIS',
'shourtcut_keys': 'TOMBOL PINTAS',
'show_hex_to_deimal': 'Tunjukkan konversi heks ke deismal',
'sound': 'SUARA',
'sound_settigs': 'Pengaturan Suara',
'start_with_autoplay': 'Mulai dengan permainan otomatis',
'stop_autoplay': 'HENTIKAN PERMAINAN OTOMATIS',
'stop_on_loss': 'HENTIKAN PADA KEKALAHAN',
'stop_on_loss_tooltip': 'Gunakan Hentikan pada Kekalahan untuk menghentikan taruhan otomatis setelah mengalami kekalahan sebesar jumlah yang ditetapkan.<br/> Misalnya, jika Anda mulai dengan $100 dan Anda mengatur Hentikan pada Kekalahan sebesar $25',
'stop_on_profit': 'HENTIKAN PADA PROFIT',
'stop_on_profit_tooltip': 'Gunakan Hentikan pada Profit untuk menghentikan taruhan otomatis setelah mendapatkan profit sebesar jumlah yang ditetapkan.<br/> Misalnya, jika Anda mulai dengan $100 dan Anda mengatur Hentikan pada Profit sebesar $25',
'there_are_no_active_torunament': 'Tidak ada turnamen aktif pada saat ini.',
'third': 'ketiga',
'this_game_is': 'Permainan ini',
'this_tool_recalculates': 'Alat ini menghitung ulang pengganda dari hash permainan. Anda bisa menyalin/menempelkan hash permainan untuk memverifikasi pengganda.',
'time': 'WAKTU',
'time_frame': 'Kerangka Waktu',
'to': 'HINGGA',
'toggle_animation': 'Toggle Animasi',
'top_odds': 'Odd Teratas',
'top_wins': 'Kemenangan Teratas',
'total_bets': 'Total Taruhan',
'tournament': 'Turnamen',
'tournament_information': 'Informasi turnamen',
'try_advanced_autoplay': 'Coba fitur putar otomatis lanjutan yang baru!',
'uncredited_wins': 'Kemenangan yang tidak dikreditkan',
'user': 'PENGGUNA',
'user_and_points': 'PENGGUNA & POIN',
'username': 'Nama pengguna',
'username_must_be_at_least': 'Panjang nama pengguna harus setidaknya',
'verify': 'Verifikasi',
'vip_popup_text': 'Anda bisa memilih salah satu avatar VIP istimewa yang tersedia khusus untuk anggota VIP kami!',
'vip_popup_title': 'Selamat, Anda menjadi pemain VIP',
'waiting_for_the_next_round': 'Menunggu ronde berikutnya…',
'warnings_are_calculated': ' ‎• Kemenangan dihitung dengan mengalikan nilai taruhan dengan pengganda pada saat Penarikan uang dilakukan',
'watch_take_off': 'Lihat Roket Keberuntungan Anda lepas landas, dan kemenangan Anda meningkat.',
'watch_the_rocket': 'Amati Roket',
'we_have_noticed': '‎`Kami melihat ini kali pertama Anda memainkan "Rocketman". Apakah Anda ingin menonton video singkat yang menjelaskan cara memainkannya? `',
'week': 'Pekan',
'welcome_to_rocketman': 'Selamat datang di Rocketman – permainan baru dan menarik! Bersama Rocketman, Anda berkesempatan untuk memenangkan hingga 20.000x jumlah taruhan awal Anda.',
'what_is_provably_fair': 'Apa itu Terbukti adil?',
'will_be_deposited': 'Akan disetorkan ke saldo pemain dengan kemenangan biasa pertama',
'win': 'KEMENANGAN',
'win_amount': 'Jumlah Kemenangan',
'win_gifs': 'KEMENANGAN',
'winner': 'pemenang',
'wins': 'kemenangan',
'write_a_replay': 'Tulis balasan …',
'year': 'Tahun',
'yes': 'YA',
'yes_start_the_round': 'Ya, mulai ronde',
'you_have_cashed_out': 'Anda telah menarik pembayaran, kemenangan Anda adalah ',
'you_have_won': 'ANDA TELAH MEMENANGKAN',
'your_bet_is_lost': 'taruhan Anda kalah.',
'current': 'Saat ini',
'previous': 'Sebelumnya',
'claim': 'Mengeklaim',
'this_feature_will_give': 'Fitur ini akan memberikan jumlah yang dipilih kepada pengguna acak dalam obrolan',
'amount_per_player': 'Jumlah Per Pemain',
'no_of_players': 'Jumlah pemain',
'meteor_shower': 'Hujan meteor',
'claim_your_free_bet': 'Klaim Taruhan Gratis Anda',
'meteor_shower_total': 'Jumlah Hujan Meteor',
'shower': 'HUJAN',
'total': 'Total',
'meteor_shower_incoming': 'Hujan Meteor masuk, lihat chat!',
'insurance': 'PERTANGGUNGAN',
'insurance_paragraph': 'Pemain dapat membeli asuransi untuk taruhan mereka dengan mengklik kotak centang "Asuransi Taruhan" di samping tombol taruhan. Biaya asuransi sebesar 10% dari total jumlah taruhan dan akan membayar kembali jumlah taruhan kepada pemain jika roket meledak dengan pengganda 1,00.',
'insure_your_bet': 'ASURANSIKAN TARUHAN ANDA',
'bet_insurance_promo_text': 'Pilih opsi "Asuransi Taruhan" untuk memastikan taruhan Anda tidak jatuh pada pengganda 1,00.',
'bet_insurance_promo_text_helper': 'Baca lebih lanjut di bagian "Peraturan Game"...',
'game_explanation_1': 'Pilih pengganda Anda, dan jika pengganda permainan melebihi pengganda yang Anda pilih, kemenangan Anda akan tercapai',
'game_explanation_2': 'Pengganda Anda x Taruhan Anda',
'retail_max_mp_1': 'Menangkan hingga',
'retail_max_mp_2': 'taruhanmu!',
'play_multibets': 'Bermain',
'multibets': 'MULTIBET',
'bonus_game': 'BONUS PERMAINAN',
'all_wins_doubled': 'Semua kemenangan berlipat ganda!',
'multibets_paragraph_1': 'untuk meningkatkan peluang Anda!',
'multibets_paragraph_2': 'Anda dapat menggabungkan taruhan hingga sepuluh putaran menjadi satu multibet dan memenangkan pembayaran besar-besaran!',
'multibets_paragraph_3': 'Setiap kali suatu putaran dimenangkan, dividen kemenangan dari putaran tersebut dipertaruhkan pada putaran berikutnya, hingga semua putaran menang.',
'bonus_launch': 'Menangkan x2 pada semua taruhan di babak "Peluncuran Bonus"!',
'multiplier_history': 'Sejarah Pengganda',
'will_start_soon': 'akan segera dimulai',
'hurry_up_and_place_your_bets': 'Cepat dan pasang taruhanmu!',
'active_bets': 'Taruhan Aktif',
'shop': 'TOKO',
'game_meteor_shower_paragraph': 'Meteor Shower memberikan satu taruhan gratis dengan nilai yang telah ditentukan kepada sejumlah pemain yang telah ditentukan. Itu dapat dimulai baik oleh server atau oleh pemain. Setelah Meteor Shower diaktifkan, sebuah pesan muncul di dalam game, mengarahkan pengguna untuk melihat chat tempat pesan Meteor Shower berada. Pemain dapat mengklaim taruhan gratis dengan mengklik tombol “Klaim”. Taruhan gratis diberikan kepada pemain yang mengklik tombol “Klaim” terlebih dahulu. Dilarang menggunakan autoclicker atau perangkat lunak otomasi apa pun untuk meniru klik pemain dan mengklaim hadiah dan dapat mengakibatkan pelarangan fitur ini.',
'claming_failed': 'Klaim gagal',
'player_on_blacklist': 'Pemain ada dalam daftar hitam',
'you_can_claim_ticket': 'Anda dapat mengklaim tiket hanya jika Anda adalah pemain aktif dengan taruhan reguler',
'you_already_have_free_tickets': 'Anda sudah mempunyai tiket gratis, silahkan dibelanjakan terlebih dahulu',
'please_play_the_game_in_portait_mode': 'Silakan mainkan game dalam mode potret.',
};
langArr.th = {
'your_insurance_returns_you': 'ประกันของคุณส่งคืนคุณ',
'bet_insurance': 'เดิมพันประกันภัย',
'max_bet_is': 'เดิมพันสูงสุดคือ',
'already_active_bet_of': 'และมีการเดิมพันที่ใช้งานอยู่แล้ว',
'1_from_game_history': '‎1. จากประวัติเกม',
'2_in_settings_window': '‎2. ในหน้าต่างการตั้งค่า',
'3_online_calculator': '‎3. เครื่องคำนวณออนไลน์',
'active_tournament_1': 'ทัวร์นาเมนต์ที่กำลังจัด ',
'active_tournament_2': 'ยังไม่มีเดิมพัน',
'add_another_bet': 'เพิ่มเดิมพันอีกรายการ',
'advanced_autoplay': 'เล่นอัตโนมัติขั้นสูง',
'advanced_autoplay_for_bet': 'การเล่นอัตโนมัติขั้นสูงสำหรับเดิมพัน',
'alien_saucer_free_bets': 'เดิมพันฟรีจานบินเอเลี่ยน',
'all_bets': 'เดิมพันทั้งหมด',
'all_in_your_hands': 'ไม่ว่าจะปังหรือจะพัง คุณคือผู้กำหนด!',
'allow': 'อนุญาต',
'at': 'ที่มูลค่า',
'austronaut_promo_credit': 'เครดิตโปรโมชั่นนักบินอวกาศ',
'auto_play_and_autocash': 'เล่นอัตโนมัติและถอนเงินอัตโนมัติ ',
'autoplay': 'เล่นอัตโนมัติ',
'autoplay_and_cashout': '‎02. เล่นอัตโนมัติและถอนเงินอัตโนมัติ',
'back_to_home': 'กลับไปที่หน้าหลัก',
'balance': 'ยอดคงเหลือ',
'bet': 'เดิมพัน',
'bet_amount': 'จำนวนเงินเดิมพัน',
'bet_and_cashout': '‎01. เดิมพันและถอนเงิน',
'bet_bets_tab': 'เงินเดิมพัน',
'bet_failed': 'เดิมพันล้มเหลว',
'bet_statistic': 'สถิติเดิมพัน',
'bet_time': 'เวลาที่เดิมพัน',
'bet_wins': 'เงินรางวัลจากเดิมพัน',
'bets': 'เดิมพัน',
'biggest_odds': 'อัตราต่อรองมากสุด',
'bonus_type': 'ประเภทโบนัส',
'bonusing_history': 'ประวัติการใช้โบนัส',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'ผู้ชนะ Boosterpot',
'but_remember_paragraph': 'แต่อย่าลืมว่าหากถอนเงินออกมาไม่ทันก่อนจรวดนำโชคระเบิด',
'calculate_raw_mp_to_decimal': 'คำนวณตัวคูณดิบจากเลขทศนิยม แล้วจึงปัดเศษลงให้ได้จำนวนเต็มที่มีค่าใกล้เคียงที่สุด',
'cancel': 'ยกเลิก',
'cant_post_media_content': 'ไม่สามารถโพสต์เนื้อหาสื่อบ่อย ๆ ได้',
'cashed_out': 'ถอนเงินแล้ว',
'cashout': 'ถอนเงิน',
'cashout_at': 'ถอนเงินที่',
'cashout_before': 'รับเงินรางวัลไปได้เลยเมื่อคุณถอนเงินก่อนที่จรวดจะระเบิด!',
'change_avatar': 'เปลี่ยนอวาตาร์',
'change_username': 'เปลี่ยนชื่อผู้ใช้',
'characters_long': 'อักขระ',
'chat': 'แชท',
'chat_room': 'ห้องแชท',
'checking_for_spam': 'กำลังตรวจหาสแปม...',
'close': 'ปิด',
'coeff': 'อัตราต่อรอง',
'collect_win': 'รับเงินรางวัล',
'combined_seed': 'ซีดที่ผสมกัน',
'company': 'บริษัท',
'congratulations_you_have_won_free_bets': 'ยินดีด้วย! คุณได้รับเดิมพันโบนัส! คุณสามารถเดิมพัน',
'convert_first_13_to_decimal': ' แปลง 13 ไบต์แรกเป็นเลขทศนิยม',
'd': 'วัน',
'date': 'วันที่',
'date_and_time': 'วันที่และเวลา',
'deny': 'ปฏิเสธ',
'detailed_game_rules': 'กฎกติกาของเกมโดยละเอียด',
'dissconnection_policy': 'นโยบายการขาดการเชื่อมต่อ',
'easy_as_one_two_three': 'ง่ายเหมือนนับหนึ่งสองสาม',
'enter_username': 'โปรดป้อนชื่อผู้ใช้ที่ต้องการ',
'error_handling': 'การจัดการข้อผิดพลาด',
'exit_full_screen': 'ออกจากโหมดเต็มหน้าจอ',
'first': 'ที่หนึ่ง',
'first_time_playing': 'ไม่เคยเล่น "Rocketman" มาก่อนใช่ไหม?',
'folow_the_three_easy_steps': 'ทำตามสามขั้นตอนง่าย ๆ ต่อไปนี้และสนุกกับ Rocketman ได้เลย! ',
'free_bets': 'เดิมพันฟรี',
'free_bets_desc': 'คุณได้รับเดิมพันฟรี! คุณจะใช้เดิมพันฟรีที่ได้รับเมื่อไรก็ได้โดยการกดที่ปุ่มเริ่มต้น',
'free_bets_header': 'เดิมพันฟรี',
'from': 'จาก',
'fullscreen_mode': 'ระบบจะเริ่มเกมในโหมดเต็มหน้าจอเพื่อให้ผู้ใช้ได้รับประสบการณ์ที่น่าพอใจยิ่งขึ้น',
'function': 'ฟังก์ชัน',
'game_functions': 'ฟังก์ชันในเกม',
'game_history': 'ประวัติเกม',
'game_r_1_paragraph': 'ผู้เล่นสามารถเปิดใช้ตัวเลือกการเล่นอัตโนมัติเพื่อให้ระบบวางเดิมพันตามจำนวนเงินเดิมพันก่อนหน้าที่ได้ลงเอาไว้โดยอัตโนมัติเมื่อขึ้นรอบใหม่',
'game_r_10_paragraph': 'ทันทีที่ผู้เล่นได้รับเครดิตโปรโมชั่น ระบบจะหักเงินออกจากเครดิตโปรโมชั่นทุกครั้งที่ผู้เล่นวางเดิมพันจนกว่าเครดิตที่มีจะหมดไป จากนั้นระบบจึงจะกลับไปหักเงินออกจากกระเป๋าเงินของผู้เล่นต่ออีกครั้ง',
'game_r_11_paragraph': 'เราไม่อนุญาตให้ถอนเงินจากเครดิตโปรโมชั่น แต่ผู้เล่นจะรับเงินรางวัลใด ๆ ก็ตามจากเดิมพันที่ลงโดยใช้เครดิตโปรโมชั่นได้เต็มจำนวน',
'game_r_12_paragraph': 'เครดิตโปรโมชั่นมีระยะเวลาการใช้งาน 30 วัน หลังจากนั้นจะหมดอายุและไม่สามารถใช้งานได้',
'game_r_13_paragraph': 'ระบบอาจสุ่มมอบเดิมพันฟรีให้กับผู้เล่น โดยภาพป๊อปอัปกราฟิกจะปรากฏขึ้นบนหน้าจอเพื่อแจ้งให้ผู้เล่นรายดังกล่าวทราบถึงเดิมพันฟรีที่ได้รับ จำนวนรอบที่วางเดิมพันได้ และจำนวนเงินต่อเดิมพัน',
'game_r_14_paragraph': 'ทันทีที่ได้รับเดิมพันฟรี ผู้เล่นสามารถเลือกว่าจะเริ่มรอบเดิมพันฟรีเมื่อไร และหลังจากเริ่มรอบเดิมพันฟรีเรียบร้อยแล้ว ระบบจะวางเดิมพันด้วยจำนวนเงินเดิมพันตามที่ระบุเอาไว้โดยอัตโนมัติ ส่วนผู้เล่นจะเป็นฝ่ายตัดสินใจว่าจะถอนเงินในจังหวะเวลาไหน หากผู้เล่นขาดการเ',
'game_r_15_paragraph': 'เดิมพันฟรีมีระยะเวลาการใช้งาน 30 วัน หลังจากนั้นจะหมดอายุและไม่สามารถใช้งานได้',
'game_r_16a_paragraph': 'ผลตอบแทนทางทฤษฎีโดยรวมสำหรับผู้เล่นคือ ',
'game_r_17_paragraph': 'เปอร์เซ็นต์ของค่า RTP ได้รับการเฝ้าติดตามและตรวจสอบยืนยันจากบุคคลที่สามที่เป็นอิสระอย่างต่อเนื่อง',
'game_r_18_paragraph': 'หากสูญเสียการเชื่อมต่อหลังจากวางเดิมพันไปแล้ว ระบบจะไม่ยกเลิกเดิมพันดังกล่าว หากตัวคูณเพิ่มไปจนถึง 20,000 เท่า ระบบจะถอนเงินจากเดิมพันของคุณโดยอัตโนมัติและโอนเครดิตเข้าไปยังบัญชีของคุณ',
'game_r_19_paragraph': 'คุณสามารถแชทกับผู้เล่นคนอื่น ๆ ในเกมได้ เพียงป้อนข้อความลงในช่องแชทหรือแชร์ผลเดิมพันที่คุณชนะกับผู้เล่นคนอื่น',
'game_r_2_paragraph': 'ผู้เล่นสามารถป้อนค่าตัวคูณสำหรับการถอนเงินอัตโนมัติในช่อง "ถอนเงินอัตโนมัติ" หลังจากป้อนค่าดังกล่าวแล้ว เกมจะถอนเงินให้ผู้เล่นโดยอัตโนมัติในกรณีที่ตัวคูณถึงค่าที่กำหนด',
'game_r_20_paragraph': 'ระบบจะปิดสิทธิ์การใช้แชทของผู้เล่นที่ใช้งานแชทในทางที่ผิด ไม่ว่าจะโดยการสบประมาทผู้เล่นคนอื่น ๆ หรือโดยใช้ภาษาที่ไม่เหมาะสมและ/หรือหยาบคาย',
'game_r_21_paragraph': 'Rocketman ผ่านกระบวนการออกแบบมาโดยคำนึงถึงดนตรีพื้นหลังและซาวด์เอฟเฟ็กต์ที่งดงาม หากมีความประสงค์ที่จะปิดเสียงดนตรีและ/หรือซาวด์เอฟเฟ็กต์ คุณสามารถเข้าไปปิดได้ที่เมนูดรอปดาวน์',
'game_r_22_paragraph': 'ผู้เล่นสามารถตรวจสอบเดิมพันรายการล่าสุดของตนได้โดยคลิกที่ปุ่ม “เดิมพันของฉัน” แต่หากต้องการดูประวัติการเล่นเพิ่มเติม (เช่น ในช่วงเวลาที่ยาวนานขึ้น) ผู้เล่นอาจจำเป็นต้องเข้าไปที่ประวัติผู้เล่นภายในตัวแพลตฟอร์มการเล่นเกม',
'game_r_23_paragraph': 'ในกรณีที่เกิดข้อผิดพลาด รอบเกมอาจไม่เริ่มขึ้นตามเวลาที่กำหนด เมื่อเกิดกรณีเช่นนี้ ทีมงานฝ่ายสนับสนุนของเราจะพยายามแก้ไขปัญหาโดยเร็วที่สุดเท่าที่จะเป็นไปได้เพื่อให้รอบเกมกลับมาเป็นปกติ ส่วนในกรณีที่เกิดปัญหากับตัวรอบเกมเอง ระบบจะยกเลิกเดิมพันทั้งหมดและคืนเ',
'game_r_24_paragraph': 'ความล่าช้าของเครือข่ายเป็นปัญหาการใช้งานอินเทอร์เน็ตที่หลีกเลี่ยงไม่ได้ เราถือว่าเดิมพันและการถอนเงินทุกรายการจะมีผลก็ต่อเมื่อเซิร์ฟเวอร์ของเราได้รับข้อมูลดังกล่าว ไม่ว่าความล่าช้าของเครือข่ายจะทำให้รับส่งข้อมูลได้ช้าเท่าใดก็ตาม ',
'game_r_3_paragraph': 'ผู้เล่นสามารถใช้ปุ่มลัดเพื่อให้วางเดิมพันหรือถอนเงินภายในเกมได้อย่างรวดเร็ว โดยแผนผังปุ่มต่าง ๆ มีดังต่อไปนี้',
'game_r_4_paragraph': '1% ของการเดิมพันแต่ละครั้งจะถูกจัดสรรให้กับกองทุนแจ็คพอตแบบโปรเกรสซีฟ กองทุนนี้รองรับระบบแจ็คพอตแบบโปรเกรสซีฟ',
'game_r_5_paragraph': ' ‎• Jackpot - ระดับสูงสุด รางวัลใหญ่สุด พบได้ยากที่สุด',
'game_r_6_paragraph': ' ‎• Rocketpot - ระดับกลาง รางวัลปานกลาง',
'game_r_7_paragraph': ' ‎• Boosterpot - ระดับต่ำสุด รางวัลเล็ก พบได้บ่อยที่สุด ',
'game_r_8_paragraph': 'ระบบจะมอบแจ็กพอตในทุกระดับผ่านกลไก "ปริศนา" หรือ "ต้องแตกก่อนถึง" โดยจะกำหนดค่าของแจ็กพอตประเภท "ต้องแตกก่อนถึง" ทุกแจ็กพอตทันทีที่เครื่องมือสร้างเลขสุ่มได้แจกแจ็กพอตก่อนหน้าไปเรียบร้อยแล้ว จากนั้นจึงจัดเก็บค่าดังกล่าวไว้แบบเข้ารหัสในฐานข้อมูลระยะไกล การเ',
'game_r_8a_paragraph': 'หมายเหตุ: ผู้ชนะแจ็คพอตสามารถเป็นผู้เล่น Rocketman เพียงคนเดียวที่เล่นอยู่ในรอบนั้นเมื่อแจ็คพอตเริ่มทำงาน ไม่มีความเป็นไปได้ที่หากผู้เล่นถูกรางวัลแจ็กพอต จะต้องแบ่งปันให้กับผู้เล่น Rocketman คนอื่นๆ',
'game_r_9_paragraph': 'ระบบอาจสุ่มมอบเครดิตโปรโมชั่นให้กับผู้เล่น โดยภาพป๊อปอัปกราฟิกจะปรากฏขึ้นบนหน้าจอเพื่อแจ้งให้ผู้เล่นรายดังกล่าวทราบถึงเครดิตโปรโมชั่นที่ได้รับและจำนวนเครดิตที่ได้รับ',
'game_round': 'รอบเกม!',
'game_rules': 'กฎกติกาของเกม',
'game_rules_1_paragraph': 'Rocketman พร้อมมอบความบันเทิงของการพนันยุคใหม่ ให้คุณคว้าเงินรางวัลแบบจุใจได้ในไม่กี่วินาที! Rocketman พัฒนาขึ้นมาโดยใช้',
'game_rules_10_paragraph': ' ‎• เปิดใช้ฟังก์ชันเล่นอัตโนมัติได้โดยทำเครื่องหมายในช่อง “เดิมพันอัตโนมัติ” ที่แท็บ “อัตโนมัติ” ในแผงเดิมพัน ระบบจะวางเดิมพันโดยอัตโนมัติหลังจากเปิดใช้ฟังก์ชันนี้ แต่หากต้องการถอนเงิน คุณควรกดปุ่ม "ถอนเงิน" ในแต่ละรอบ ส่วนในกรณีที่คุณต้องการถอนเงินจากเดิ',
'game_rules_11_paragraph': '‎• ใช้ฟังก์ชันถอนเงินอัตโนมัติได้ที่แท็บ “อัตโนมัติ” ในแผงเดิมพัน หลังจากเปิดใช้ฟังก์ชันนี้ ระบบจะถอนเงินจากเดิมพันให้คุณโดยอัตโนมัติเมื่อถึงอัตราต่อรองที่ป้อนเอาไว้',
'game_rules_2_paragraph': 'ซึ่งเป็นวิธีรับประกันความเที่ยงตรงอย่างแท้จริงเพียงหนึ่งเดียวของวงการพนันในปัจจุบัน',
'game_rules_3_paragraph': '‎• ตัวคูณเงินรางวัลจะเริ่มต้นจาก 1 เท่าและเพิ่มสูงขึ้นไปเรื่อย ๆ ตามเส้นทางของจรวดนำโชค',
'game_rules_4_paragraph': ' ‎• ระบบจะคิดเงินรางวัลโดยนำตัวคูณที่ระบุ ณ เวลาที่คุณถอนเงินมาคูณกับเดิมพันของคุณ ',
'game_rules_5_paragraph': '‎• ก่อนที่เกมจะเริ่มขึ้นในแต่ละรอบ เครื่องมือสร้างเลขสุ่มที่ยุติธรรมแบบพิสูจน์ได้ของเราจะสร้างอัตราต่อรองขึ้นมา ระบบจะใช้ค่าดังกล่าวในการกำหนดว่าจรวดจะระเบิดเมื่อใด คุณสามารถตรวจสอบความเที่ยงตรงของอัตราต่อรองที่สร้างขึ้นมาได้โดยคลิกที่ฝั่งตรงข้ามผลลัพธ์ใน',
'game_rules_6_paragraph': '‎• เลือกจำนวนเงินและกดปุ่ม “เดิมพัน” เพื่อวางเดิมพัน ',
'game_rules_7_paragraph': '‎• คุณสามารถวางเดิมพันสองรายการในเวลาเดียวกันได้โดยการเพิ่มแผงเดิมพันที่สอง หากคุณต้องการเพิ่มแผงเดิมพันแผงที่สอง ให้กดไอคอนเครื่องหมายบวกที่อยู่บริเวณมุมขวาบนของแผงเดิมพันแผงแรก',
'game_rules_8_paragraph': ' ‎• กดปุ่ม “ถอนเงิน” เพื่อถอนเงินรางวัลของคุณ ระบบจะคิดเงินรางวัลโดยนำเดิมพันของคุณไปคูณด้วยอัตราต่อรองของการถอนเงิน',
'game_rules_9_paragraph': '‎• หากไม่ได้ถอนเงินก่อนจรวดระเบิด ระบบจะถือว่าคุณแพ้เดิมพัน',
'general_rules': 'กฎกติกาทั่วไป',
'gifs': 'GIF',
'go_back': 'กลับไป',
'good_job_buddy': 'เยี่ยมไปเลยพวก ลุยต่อเลย',
'h': 'ชม.',
'hashed_in_sha_512': 'แฮชในรูปแบบ SHA512',
'hex': 'เลขฐานสิบหก',
'hey_username': 'ไง',
'hide_hex_to_decimal': 'ซ่อนการแปลงเลขฐานสิบหกป็นเลขทศนิยม',
'highscore': 'คะแนนสูง',
'how_it_works': 'วิธีการทำงาน',
'how_to_check': 'วิธีการตรวจสอบ',
'how_to_play': 'วิธีการเล่น',
'if_the_maximum_multiplier': '‎• หากตัวคูณชนเพดานสูงสุดที่ 20,000 ระบบจะถอนเงินสำหรับเดิมพันทุกรายการที่ยังไม่ได้ถอนโดยคิดเงินรางวัลตามตัวคูณสูงสุด',
'increase': 'เพิ่ม',
'input_bet_amount': 'ป้อนจำนวนเงินเดิมพันและวางเดิมพันของคุณก่อนที่จรวดจะออกเดินทาง',
'insufficient_funds': 'เงินไม่พอ',
'it_blew_up': 'ระเบิดจนได้! โถ่...',
'jackpot': 'แจ็กพอต',
'jackpot_winners': 'ผู้ชนะ Jackpot',
'key': 'ปุ่ม',
'load_more': 'โหลดเพิ่ม',
'loading': 'กำลังโหลด',
'loading_next_round': 'กำลังโหลดรอบถัดไป...',
'loss_gifs': 'แพ้',
'lost_connection': 'การเชื่อมต่อขาดหาย',
'make_a_bet': 'วางเดิมพัน',
'malfunction_voids': 'ความผิดปกติจะทำให้การจ่ายและการเล่นทั้งหมดเป็นโมฆะ',
'maximum_payout': 'การจ่ายเงินสูงสุดคือ ',
'menu': 'เมนู',
'min_bet_is': 'เดิมพันขั้นต่ำคือ',
'month': 'เดือน',
'more_details': 'รายละเอียดเพิ่มเติม',
'more_information': 'ข้อมูลเพิ่มเติม',
'multiplier': 'ตัวคูณ',
'multiplier_starts': '‎• ตัวคูณจะเริ่มจาก 1 ไปจนสุดที่ 20,000',
'multipliers_larger': 'ตัวคูณที่มีค่ามากกว่า 20,000 เท่าจะมีเพดานจำกัดอยู่ที่ค่าตัวคูณสูงสุด',
'music_settings': 'การตั้งค่าดนตรี',
'my_bet_history': 'ประวัติเดิมพันของฉัน',
'my_bets': 'เดิมพันของฉัน',
'my_bonysing_history': 'ประวัติการใช้โบนัสของฉัน',
'network_latency': 'ความล่าช้าของเครือข่าย',
'network_latency_content': 'ความล่าช้าของเครือข่ายเป็นปัญหาการใช้งานอินเทอร์เน็ตที่หลีกเลี่ยงไม่ได้ เราถือว่าเดิมพันและการถอนเงินทุกรายการจะมีผลก็ต่อเมื่อเซิร์ฟเวอร์ของเราได้รับข้อมูลดังกล่าว ไม่ว่าความล่าช้าของเครือข่ายจะทำให้รับส่งข้อมูลได้ช้าเท่าใดก็ตาม',
'network_latency_title': 'ความล่าช้าของเครือข่าย',
'no_thanks': 'ไม่ดีกว่า ขอบคุณ!',
'not_right_now': 'ยังไม่ใช่ตอนนี้',
'off': 'ปิด',
'oh_snap': 'แย่แล้ว!',
'ok_close': 'เข้าใจแล้ว ปิดเลย!',
'ok_thanks': 'เข้าใจแล้ว ขอบคุณ!',
'on': 'เปิด',
'on_loss': 'เมื่อแพ้',
'on_win': 'เมื่อชนะ',
'online': 'ออนไลน์',
'per_bet': 'ต่อเดิมพัน',
'pick_your_avatar_img': 'เลือกรูปอวาตาร์ของคุณ',
'place_bet_with_last': 'วางเดิมพันด้วยเงินเดิมพันล่าสุดที่เลือก ',
'play_now': 'เล่นเลย',
'player_1_seed': 'ซีดของผู้เล่น 1',
'player_2_seed': 'ซีดของผู้เล่น 2',
'player_3_seed': 'ซีดของผู้เล่น 3',
'please_wait_active_bets': 'โปรดรอให้รายการเดิมพันปัจจุบันทราบผลเสร็จสิ้นก่อนจะใช้เดิมพันฟรี',
'points': 'แต้ม',
'powered_by': 'ขับเคลื่อนโดย',
'previous_hand': 'มือก่อนหน้า',
'prize': 'รางวัล',
'prizes': 'รางวัล',
'progressive_jackpot': 'แจ็กพอตสะสมยอด',
'promo_credit': 'เครดิตโปรโมชั่น',
'promo_credit_condradulations': 'ยินดีด้วย! คุณได้รับเครดิตโปรโมชั่นที่เรียกได้ว่าเด็ดหลุดโลก นำไปใช้ภายในเกมได้เลย!',
'promo_credits': 'เครดิตโปรโมชั่น',
'promo_history': 'ประวัติโปรโมชั่น',
'provably_fair': 'ยุติธรรมแบบพิสูจน์ได้',
'provably_fair_1_paragraph': '‎\'Rocketman\' เป็นเกมที่ใช้เทคโนโลยีการเข้ารหัสลับซึ่งเรียกว่า \'ยุติธรรมแบบพิสูจน์ได้\' เทคโนโลยีนี้ช่วยรับประกันว่าผลลัพธ์ของเกมจะมีความยุติธรรม 100% การใช้เทคโนโลยีนี้จะป้องกันไม่ให้บุคคลที่สามใด ๆ เข้ามาแทรกแซงระบบของเกมได้',
'provably_fair_2_paragraph': 'ระบบไม่ได้สร้างผลลัพธ์ของแต่ละรอบ (อัตราต่อรองที่ \'คุมไม่ได้\' ของเกม) ขึ้นมาบนเซิร์ฟเวอร์ของเราเอง แต่ใช้ผู้เล่นในรอบนั้น ๆ เป็นปัจจัยประกอบการสร้างผลลัพธ์และมีความโปร่งใสอย่างครบถ้วน ดังนั้นไม่ว่าใครก็ควบคุมผลลัพธ์ของเกมไม่ได้ นอกจากนี้ใครก็ตรวจสอบและย',
'provably_fair_3_paragraph': 'ระบบสร้างผลลัพธ์ของรอบโดยอาศัยผู้เข้าร่วมสี่รายที่เป็นอิสระจากกันในรอบนั้น ๆ',
'provably_fair_4_paragraph': 'เมื่อเริ่มต้นรอบ ระบบจะนำซีดของเซิร์ฟเวอร์มาผสานกับซีดของไคลเอนต์สามรายการ จากนั้นจะสร้างแฮช SHA512 ขึ้นมาจากสัญลักษณ์ที่ผสานรวมกัน แล้วจึงใช้แฮชดังกล่าวสร้างผลลัพธ์ของเกม',
'provably_fair_5_paragraph': 'คุณสามารถตรวจสอบความยุติธรรมของแต่ละรอบได้โดยการคลิกไอคอนสีเขียวเพื่อเข้าไปที่ประวัติเกม ระบบจะระบุซีดของเซิร์ฟเวอร์ ซีดของผู้เล่นจำนวน 3 คู่ แฮชที่ผสมกัน และผลลัพธ์ของรอบในหน้าต่างที่เปิดขึ้นมา',
'provably_fair_6_paragraph': 'เราเปิดให้ทุกคนสามารถเข้าไปดูซีดของเซิร์ฟเวอร์สำหรับรอบถัด ๆ ไปในเวอร์ชันที่แฮชแล้วได้ผ่านหน้าต่างการตั้งค่า (ให้ดูที่ "การตั้งค่ายุติธรรมแบบพิสูจน์ได้" ในเมนูผู้ใช้ จากนั้นไปที่ "SHA256 ซีดของเซิร์ฟเวอร์ถัดไป") คุณยังเปลี่ยนซีดไคลเอนต์ของตัวเองได้ที่นี่อ',
'provably_fair_7_paragraph': 'ตรวจสอบความถูกต้องของแฮชผ่านเครื่องคำนวณออนไลน์ใด ๆ ก็ได้ เช่น',
'provably_fair_system': 'ระบบความยุติธรรมแบบพิสูจน์ได้',
'quick_explanation': 'คำอธิบายฉบับย่อ',
'randomly_generated_server_hash': 'แฮชของเซิร์ฟเวอร์ที่สุ่มสร้างขึ้น',
'rank': 'อันดับ',
'raw_multiplier': 'ตัวคูณดิบ',
'raw_multiplier_to_edged': 'ตัวคูณดิบเป็นตัวคูณเอ็ดจ์โดยใช้ค่า',
'remove_additional_bet': 'ลบเดิมพันเพิ่มเติม',
'reset': 'รีเซ็ต',
'return_to_player': 'ผลตอบแทนของผู้เล่น',
'rocketman_is_pure_excitement': 'Rocketman จะพาคุณไปสัมผัสความระทึกแบบจัดเต็ม! เสี่ยงโชคลุ้นรับเงินได้เลย',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'ผู้ชนะ Rocketpot',
'round': 'รอบ',
'round_id': 'ID รอบ',
'round_multiplier': 'ตัวคูณของรอบ',
'round_results': 'ผลลัพธ์ของรอบ',
'rounds': 'รอบ',
'rtp': 'RTP',
'rules': 'กฎกติกา',
'rules_details': 'กติกาของทัวร์นาเมนต์',
'save': 'บันทึก',
'schedule': 'กำหนดเวลา',
'second': 'ที่สอง',
'seconds_left': 'วินาที',
'server_hash': 'แฮชของเซิร์ฟเวอร์',
'please_wait_current_bets': 'โปรดรอให้การเดิมพันปัจจุบันเสร็จสิ้น',
'min_autocashout_value_must_be': 'มูลค่าเงินสดอัตโนมัติขั้นต่ำต้องเป็น',
'min_bet_value_must_be': 'มูลค่าเดิมพันขั้นต่ำจะต้องเป็น',
'server_seed': 'ซีดของเซิร์ฟเวอร์',
'session_dissconnected': 'เซสชันขาดการเชื่อมต่อ',
'multisession_not_allowed': 'ไม่อนุญาตให้ใช้มัลติเซสชั่น',
'set_auto_bet': 'ตั้งเดิมพันอัตโนมัติ',
'set_auto_cashout': 'ตั้งถอนเงินอัตโนมัติ',
'shourtcut_keys': 'ปุ่มลัด',
'show_hex_to_deimal': 'แสดงการแปลงเลขฐานสิบหกป็นเลขทศนิยม',
'sound': 'เสียง',
'sound_settigs': 'การตั้งค่าเสียง',
'start_with_autoplay': 'เริ่มพร้อมการเล่นอัตโนมัติ',
'stop_autoplay': 'หยุดเล่นอัตโนมัติ',
'stop_on_loss': 'หยุดเมื่อเสีย',
'stop_on_loss_tooltip': 'ใช้ "หยุดเมื่อเสีย" เพื่อหยุดการเดิมพันอัตโนมัติหลังเสียเงินครบจำนวนที่ระบุ<br/>ตัวอย่างเช่น หากคุณเริ่มเล่นโดยมีเงิน $100 และตั้งหยุดเมื่อเสียไว้ที่ $25',
'stop_on_profit': 'หยุดเมื่อได้',
'stop_on_profit_tooltip': 'ใช้ "หยุดเมื่อได้" เพื่อหยุดการเดิมพันอัตโนมัติหลังได้เงินครบจำนวนที่ระบุ<br/>ตัวอย่างเช่น หากคุณเริ่มเล่นโดยมีเงิน $100 และตั้งหยุดเมื่อได้ไว้ที่ $25',
'there_are_no_active_torunament': 'ไม่มีทัวร์นาเมนต์ที่กำลังจัดในขณะนี้',
'third': 'ที่สาม',
'this_game_is': 'เกมนี้',
'this_tool_recalculates': 'เครื่องมือนี้จะคำนวณตัวคูณจากแฮชของเกมอีกครั้ง คุณสามารถคัดลอก/วางแฮชใด ๆ ของเกมก็ได้เพื่อตรวจสอบตัวคูณ',
'time': 'เวลา',
'time_frame': 'กรอบเวลา',
'to': 'จนถึง',
'toggle_animation': 'เปิด/ปิดภาพเคลื่อนไหว',
'top_odds': 'อัตราต่อรองสูงสุด',
'top_wins': 'เงินรางวัลสูงสุด',
'total_bets': 'เดิมพันรวม',
'tournament': 'ทัวร์นาเมนต์',
'tournament_information': 'ข้อมูลทัวร์นาเมนต์',
'try_advanced_autoplay': 'ลองใช้ฟีเจอร์เล่นอัตโนมัติขั้นสูงใหม่!',
'uncredited_wins': 'เงินรางวัลที่ยังไม่ได้รับ',
'user': 'ผู้ใช้',
'user_and_points': 'ผู้ใช้และแต้ม',
'username': 'ชื่อผู้ใช้',
'username_must_be_at_least': 'ชื่อผู้ใช้ต้องมีความยาวอย่างน้อย',
'verify': 'ตรวจสอบ',
'vip_popup_text': 'คุณสามารถเลือกใช้อวาตาร์ VIP พิเศษที่มีให้เฉพาะสมาชิก VIP ของเราเท่านั้นได้เลย!',
'vip_popup_title': 'ยินดีด้วยกับการเป็นผู้เล่น VIP',
'waiting_for_the_next_round': 'กำลังรอรอบถัดไป...',
'warnings_are_calculated': ' ‎• ระบบจะคำนวณเงินรางวัลโดยนำจำนวนเงินเดิมพันของเดิมพันมาคูณด้วยตัวคูณที่ระบุ ณ เวลาที่ผู้เล่นถอนเงิน',
'watch_take_off': 'ดูจรวดนำโชคออกเดินทางไปพร้อม ๆ กับที่เงินรางวัลของคุณเพิ่มขึ้น',
'watch_the_rocket': 'ดูจรวดพุ่งทะยาน',
'we_have_noticed': '‎`เราสังเกตได้ว่าคุณไม่เคยเล่น "Rocketman" มาก่อน คุณต้องการรับชมวิดีโออธิบายวิธีการเล่นคร่าว ๆ หรือไม่`',
'week': 'สัปดาห์',
'welcome_to_rocketman': 'ขอต้อนรับสู่ Rocketman เกมรูปแบบใหม่ที่จะพาคุณไปสัมผัสความระทึก! คุณมีโอกาสคว้าเงินรางวัลสูงสุด 20,000 เท่าของเงินเดิมพันตั้งต้นเมื่อเล่น Rocketman',
'what_is_provably_fair': 'ยุติธรรมแบบพิสูจน์ได้คืออะไร',
'will_be_deposited': 'ระบบจะโอนไปยังยอดคงเหลือของผู้เล่นหลังชนะเดิมพันรอบปกติเป็นครั้งแรก',
'win': 'เงินรางวัล',
'win_amount': 'จำนวนเงินรางวัล',
'win_gifs': 'ชนะ',
'winner': 'ผู้ชนะ',
'wins': 'เงินรางวัล',
'write_a_replay': 'พิมพ์ข้อความ...',
'year': 'ปี',
'yes': 'ใช่',
'yes_start_the_round': 'ใช่ เริ่มรอบเลย',
'you_have_cashed_out': 'คุณได้ถอนเงินแล้ว เงินรางวัลของคุณคือ ',
'you_have_won': 'คุณชนะ',
'your_bet_is_lost': 'ระบบจะถือว่าคุณแพ้เดิมพัน',
'current': 'ปัจจุบัน',
'previous': 'ก่อนหน้า',
'claim': 'เรียกร้อง',
'this_feature_will_give': 'คุณสมบัตินี้จะให้จำนวนเงินที่เลือกแก่ผู้ใช้แบบสุ่มในการแชท',
'amount_per_player': 'จำนวนเงินต่อผู้เล่น',
'no_of_players': 'จำนวนผู้เล่น',
'meteor_shower': 'ฝนดาวตก',
'claim_your_free_bet': 'รับสิทธิ์เดิมพันฟรีของคุณ',
'meteor_shower_total': 'รวมฝนดาวตก',
'shower': 'ฝน',
'total': 'ทั้งหมด',
'meteor_shower_incoming': 'ฝนดาวตกกำลังมา ดูแชทสิ!',
'insurance': 'ประกันภัย',
'insurance_paragraph': 'ผู้เล่นสามารถซื้อประกันสำหรับการเดิมพันได้โดยคลิกที่ช่องทำเครื่องหมาย "ประกันเดิมพัน" ข้างปุ่มเดิมพัน ค่าประกัน 10% ของจำนวนเงินเดิมพันทั้งหมด และจะคืนเงินเดิมพันให้กับผู้เล่นหากจรวดระเบิดด้วยตัวคูณ 1.00',
'insure_your_bet': 'ประกันการเดิมพันของคุณ',
'bet_insurance_promo_text': 'เลือกตัวเลือก "ประกันการเดิมพัน" เพื่อประกันการเดิมพันของคุณจากการชนจรวดที่ตัวคูณ 1.00',
'bet_insurance_promo_text_helper': 'อ่านเพิ่มเติมในส่วน "กฎของเกม"...',
'game_explanation_1': 'เลือกตัวคูณของคุณ และหากตัวคูณของเกมเกินกว่าตัวคูณที่คุณเลือก คุณจะชนะ',
'game_explanation_2': 'ตัวคูณของคุณ x เดิมพันของคุณ',
'retail_max_mp_1': 'ชนะได้ถึง',
'retail_max_mp_2': 'เดิมพันของคุณ!',
'play_multibets': 'เล่น',
'multibets': 'มัลติเบต',
'bonus_game': 'เกมโบนัส',
'all_wins_doubled': 'ชัยชนะทั้งหมดเป็นสองเท่า!',
'multibets_paragraph_1': 'เพื่อเพิ่มโอกาสของคุณ!',
'multibets_paragraph_2': 'คุณสามารถรวมการเดิมพันได้มากถึงสิบรอบเป็นเดิมพันเดียวและชนะการจ่ายเงินก้อนโต!',
'multibets_paragraph_3': 'ในแต่ละรอบที่ชนะ การชนะเงินปันผลจากรอบนั้นจะถูกเดิมพันในรอบถัดไป จนกว่าทุกรอบจะชนะ',
'bonus_launch': 'ชนะ x2 จากการเดิมพันทั้งหมดในรอบ "เปิดตัวโบนัส"!',
'multiplier_history': 'ประวัติตัวคูณ',
'will_start_soon': 'จะเริ่มเร็ว ๆ นี้',
'hurry_up_and_place_your_bets': 'รีบวางเดิมพันของคุณ!',
'active_bets': 'เดิมพันที่ใช้งานอยู่',
'shop': 'ร้านค้า',
'game_meteor_shower_paragraph': 'ฝนดาวตกจะมอบการเดิมพันฟรีหนึ่งรายการตามมูลค่าที่กำหนดไว้ล่วงหน้าให้กับผู้เล่นตามจำนวนที่กำหนดไว้ล่วงหน้า สามารถเริ่มต้นได้โดยเซิร์ฟเวอร์หรือโดยผู้เล่น เมื่อเปิดใช้งานฝนดาวตก ข้อความจะปรากฏขึ้นในเกม โดยแจ้งให้ผู้ใช้ดูแชทที่มีข้อความฝนดาวตกอยู่ ผู้เล่นสามารถรับสิทธิ์เดิมพันฟรีได้โดยคลิกที่ปุ่ม "รับสิทธิ์" จะมีการมอบเดิมพันฟรีให้กับผู้เล่นที่คลิกที่ปุ่ม "รับสิทธิ์" ก่อน ห้ามใช้ autoclickers หรือซอฟต์แวร์อัตโนมัติใดๆ เพื่อจำลองการคลิกของผู้เล่นและรับรางวัล และอาจส่งผลให้ถูกแบนจากฟีเจอร์นี้',
'claming_failed': 'การอ้างสิทธิ์ล้มเหลว',
'player_on_blacklist': 'ผู้เล่นอยู่ในบัญชีดำ',
'you_can_claim_ticket': 'คุณสามารถรับตั๋วได้เฉพาะในกรณีที่คุณเป็นผู้เล่นที่มีการเดิมพันปกติ',
'you_already_have_free_tickets': 'คุณมีตั๋วฟรีอยู่แล้ว โปรดใช้จ่ายก่อน',
'please_play_the_game_in_portait_mode': 'กรุณาเล่นเกมในโหมดแนวตั้ง',
};
langArr.tl = {
'your_insurance_returns_you': 'உங்கள் காப்பீடு உங்களுக்குத் திரும்பும்',
'bet_insurance': 'பந்தயம் காப்பீடு',
'max_bet_is': 'Max bet ay',
'already_active_bet_of': 'at may active bet na ng',
'1_from_game_history': '1. Mula sa history ng laro',
'2_in_settings_window': '2. Sa settings window',
'3_online_calculator': '3. Online calculator',
'active_tournament_1': 'Aktibong tournament ',
'active_tournament_2': 'ay wala pang pusta.',
'add_another_bet': 'Magdagdag ng isa pang pusta',
'advanced_autoplay': 'ADVANCED AUTOPLAY',
'advanced_autoplay_for_bet': 'Advanced Autoplay Para Sa Pusta',
'alien_saucer_free_bets': 'Alien Saucer Libreng Pusta',
'all_bets': 'Lahat ng Pusta',
'all_in_your_hands': 'Lahat ay nasa kamay mo!',
'allow': 'Payagan',
'at': 'sa',
'austronaut_promo_credit': 'Astronaut Promo Credit',
'auto_play_and_autocash': 'AUTOPLAY AT AUTOCASHOUT ',
'autoplay': 'Autoplay',
'autoplay_and_cashout': '02. Auto Play & auto cashout',
'back_to_home': 'Bumalik sa home',
'balance': 'Balanse',
'bet': 'PUSTA',
'bet_amount': 'HALAGA NG PUSTA',
'bet_and_cashout': '01. Pumusta & Cashout',
'bet_bets_tab': 'TAYA',
'bet_failed': 'Nabigo ang Pusta',
'bet_statistic': 'Bet Statistic',
'bet_time': 'Oras ng Pusta',
'bet_wins': 'Mga Panalong Pusta',
'bets': 'Mga Pusta',
'biggest_odds': 'Pinakamalaking Odds',
'bonus_type': 'URI NG BONUS',
'bonusing_history': 'Bonusing History',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Mga nanalo sa Boosterpot',
'but_remember_paragraph': 'Pero tandaan mo, kung wala kang panahon para mag-Cashout bago sumabog ang Maswerteng Rocket,',
'calculate_raw_mp_to_decimal': 'Kalkulahin ang Raw Multiplier mula sa decimal, at i-round down sa pinakamalapit na integer',
'cancel': 'Kanselahin',
'cant_post_media_content': 'Hindi pwedeng mag-post ng media content nang madalas',
'cashed_out': 'Na-cash out',
'cashout': 'Cashout',
'cashout_at': 'CASHOUT SA',
'cashout_before': 'Mag-cashout bago sumabog ang rocket at mapapasaiyo ang pera!',
'change_avatar': 'Magpalit ng Avatar',
'change_username': 'Magpalit ng username',
'characters_long': 'character ang haba',
'chat': 'Chat',
'chat_room': 'Chat Room',
'checking_for_spam': 'Tumitingin ng spam...',
'close': 'Isara',
'coeff': 'COEFF.',
'collect_win': 'Kolektahin ang Panalo',
'combined_seed': 'Pinagsamang seed',
'company': 'Kumpanya',
'congratulations_you_have_won_free_bets': 'Binabati kita! Nanalo ka ng Mga Bonus na Pusta! Maaari kang pumusta',
'convert_first_13_to_decimal': ' I-convert ang unang 13 bytes patungong decimal',
'd': 'a',
'date': 'PETSA',
'date_and_time': 'Petsa at Oras',
'deny': 'Tanggihan',
'detailed_game_rules': 'Detalyadong Panuntunan ng Laro',
'dissconnection_policy': 'PATAKARAN SA DISKONEKSYON',
'easy_as_one_two_three': 'Kasing dali ng isa, dalawa, tatlo',
'enter_username': 'Pakilagay ang ninanais na username',
'error_handling': 'PANGANGASIWA NG ERROR',
'exit_full_screen': 'Umalis sa full-screen mode',
'first': 'una',
'first_time_playing': 'Unang beses na naglalaro ng "Rocketman"?',
'folow_the_three_easy_steps': 'Sundin ang tatlong madadaling hakbang, at i-enjoy ang Rocketman! ',
'free_bets': 'MGA LIBRENG PUSTA',
'free_bets_desc': 'Nanalo ka ng mga libreng pusta! Pwede mong sulitin ang mga libreng pusta anumang oras sa pamamagitan ng pagpindot sa magsimula button.',
'free_bets_header': 'Mga Libreng Pusta',
'from': 'MULA',
'fullscreen_mode': 'Para sa mas magandang karanasan ng user, ang laro ay magsisimula sa fullscreen mode.',
'function': 'Function',
'game_functions': 'Mga Function ng Laro',
'game_history': 'KASAYSAYAN NG LARO',
'game_r_1_paragraph': 'Maaaring i-activate ng player ang autoplay na opsyon kung saan ang pusta ay awtomatikong nilalagay para sa bawat bagong round gamit ang halaga ng pusta mula sa naunang pusta.',
'game_r_10_paragraph': 'Sa oras na mabigay ang mga promo credit, para sa kahit anong pusta na nilalagay ng player, ang pera ay ikakaltas muna mula sa mga promo credit hanggang magamit ang lahat, bago magpatuloy sa pagwi-withdraw ng pera mula sa wallet ng player.',
'game_r_11_paragraph': 'Hindi maaaring i-cash out ang mga promo credit, ngunit anumang panalo sa mga nasabing pusta ay nake-credit nang buo sa player.',
'game_r_12_paragraph': 'Ang mga promo credit ay may 30 araw na panahon ng validity, at pagkatapos nito ay nage-expire ang mga ito at hindi na magagamit.',
'game_r_13_paragraph': 'Ang player ay maaaring mabigyan ng mga libreng pusta nang random. Isang popup graphic ang lilitaw sa screen na nag-aabiso sa player tungkol sa binigay na libreng pusta, bilang ng pusta at halaga kada pusta.',
'game_r_14_paragraph': 'Sa oras na mabigay ang mga libreng pusta, maaaring piliin ng player kung kailan sisimulan ang round ng libreng pusta. Sa oras na magsimula ang round ng mga libreng pusta, isang automatic bet na may nakatakdang taya ang inilalagay, at player na ang magka-c',
'game_r_15_paragraph': 'Ang mga libreng pusta ay may 30 araw na panahon ng validity, at pagkatapos nito ay nage-expire ang mga ito at hindi na magagamit.',
'game_r_16a_paragraph': 'Ang pangkalahatang teoretikal na balik sa manlalaro ay ',
'game_r_17_paragraph': 'Ang mga porsyento ng RTP ay patuloy na sinusubaybayan at bineberipika ng mga independenteng thrid party.',
'game_r_18_paragraph': 'Kung nawala ang koneksyon pagkatapos mailagay ang pusta, hindi makakansela ang pusta. Kung marating ng multiplier ang 20.000x, ang pusta mo ay mao-auto cash out at ang mga credit ay malalapat sa iyong account.',
'game_r_19_paragraph': 'Pwede kang makipag-chat sa ibang mga player sa laro. Ilagay ang iyong mensahe sa chat field, o ibahagi ang iyong panalong pusta sa ibang mga player.',
'game_r_2_paragraph': 'Isang autocashout multiplier ang maaaring ilagay sa "Autocashout" field, at kapag nilagay, ang laro ay awtomatikong maka-cash out kapag naabot ang set multiplier.',
'game_r_20_paragraph': 'Madi-disable ang mga pribilehiyo sa Chat ng mga player na gagamit ng chat sa maling paraan sa pamamagitan ng pang-aabuso sa ibang mga player, o paggamit ng hindi angkop o bastos na pananalita.',
'game_r_21_paragraph': 'Ang Rocketman ay dinisenyo na may magandang background music at mga sound effect. Kung nais mong i-disable ang musika at/o mga sound effect, maaari mong gawin gamit ang drop down menu.',
'game_r_22_paragraph': 'Sa pag-click sa button na "Mga Bet Ko", maaaring pag-aralan ng player ang kanyang pinakahuling mga pusta. Para sa mas matagal pang kasaysayan ng paglalaro (hal. mas matagal na panahon), kakailanganin ng player na i-access ang kasaysayan ng player sa loob ',
'game_r_23_paragraph': 'Kung sakaling may error, maaaring hindi magsimula sa oras ang mga round. Kung iyon ang kaso, susubukang lutasin ng aming support team ang isyu sa lalong madaling panahon, at magpapatuloy sa mga regular na round ng laro. Kung sakaling may isyu sa round ng ',
'game_r_24_paragraph': 'Ang network latency ay hindi maiiwasang bahagi ng koneksyon sa internet. Itinuturing namin ang lahat ng pusta at cashout na valid lamang sa panahong marating ng mga ito ang aming server, kahit ano pa ang durasyon ng network latency. ',
'game_r_3_paragraph': 'Maaaring gumamit ang player ng mga shortcut key para mabilis na maglagay ng pusta o mag-cashout sa laro. Ang mga key mapping ay gaya ng sumusunod',
'game_r_4_paragraph': '1% ng bawat taya ay nakalaan sa isang pondo para sa progresibong jackpot. Ang pondong ito ay nagsisilbi para sa sistemang progresibong jackpot',
'game_r_5_paragraph': ' ⦁ Jackpot – Pinakamataas na antas, pinakamalaking premyo, pinakamadalang',
'game_r_6_paragraph': ' ⦁ Rocketpot – Katamtamang antas, katamtamang premyo',
'game_r_7_paragraph': ' ⦁ Boosterpot – Pinakamababang antas, maliit na premyo, pinakamadalas ',
'game_r_8_paragraph': 'Lahat ng level ng jackpot ay binibigay gamit ang mechanics na "Mystery" o "must-hit-by". Ang value ng bawat "must-hit-by" jackpot ay agad na natutukoy matapos na ang naunang jackpot ay napanalunan sa pamamagitan ng random number generator at tinatago bila',
'game_r_8a_paragraph': 'Tandaan: Ang nanalo ng Jackpot ay maaaring isang solong manlalaro ng Rocketman na aktibong naglalaro sa round kapag na-trigger ang jackpot. Walang posibilidad na kung nanalo ang manlalaro ng jackpot, kailangang ibahagi ito sa sinumang manlalaro ng Rocketman.',
'game_r_9_paragraph': 'Ang player ay maaaring mabigyan ng mga promo credit nang random. Isang popup graphic ang lilitaw sa screen na nag-aabiso sa player tungkol sa binigay na promo credit at halaga nito.',
'game_round': 'mga round ng laro!',
'game_rules': 'Mga Panuntunan ng Laro',
'game_rules_1_paragraph': 'Ang Rocketman ay isang bagong henerasyon na libangang pagsusugal. Manalo nang maraming beses sa loob ng ilang segundo! Ang Rocketman ay binuo sa',
'game_rules_10_paragraph': ' • Ang Auto Play ay ina-activate mula sa "Auto" tab sa Panel ng Pusta, sa pamamagitan ng pagmarka sa "Auto Bet" checkbox. Pagkatapos ma-activate, ang mga pusta ay ilalagay nang awtomatiko, pero para sa cash out, kailangan mong pindutin ang "cashout" butto',
'game_rules_11_paragraph': '• Ang Auto Cashout ay available mula sa "Auto" tab sa panel ng pusta. Pagkatapos ma-activate, ang pusta mo ay awtomatikong maka-cash out, kapag nakarating sa coefficient.',
'game_rules_2_paragraph': ', na sa kasalukuyan ay tanging tunay na garantiya ng katapatan sa industriya ng pagsusugal.',
'game_rules_3_paragraph': '• Ang win multiplier ay nagsisimula sa 1x at lumalaki nang lumalaki habang nagte-take off ang Maswerteng Rocket.',
'game_rules_4_paragraph': ' • Ang mga panalo mo ang multiplier kung saan gumawa ka ng Cashout na na-multiply sa iyong pusta. ',
'game_rules_5_paragraph': '• Bago magsimula ang bawat round, ang aming provably fair random number generator ay lilikha ng coefficient kung saan lilipad ang Maswerteng Rocket. Maaari mong tingnan ang katapatan ng paglikhang ito sa pamamagitan ng pag-click sa katapat ng resulta sa H',
'game_rules_6_paragraph': '• Piliin ang halaga at pindutin ang "Pumusta" button para pumusta. ',
'game_rules_7_paragraph': '• Maaari kang gumawa ng dalawang pusta nang sabay, sa pamamagitan ng pagdaragdag ng ikalawang panel ng pusta. Para magdagdag ng ikalawang panel ng pusta, pindutin ang plus icon, na nakikita sa itaas na kanang sulok ng unang panel ng pusta.',
'game_rules_8_paragraph': ' • Pindutin ang "Cashout" button para i-cash out ang iyong panalo. Ang iyong panalo ay pusta na na-multiply ng cashout coefficient.',
'game_rules_9_paragraph': '• Natalo ang pusta mo, hindi ka nag-cash out bago lumipad papalayo ang Rocket.',
'general_rules': 'PANGKALAHATANG PANUNTUNAN',
'gifs': 'MGA GIF',
'go_back': 'Bumalik',
'good_job_buddy': 'Ayos yan, ituloy mo lang',
'h': 'o',
'hashed_in_sha_512': 'Hashed in SHA512',
'hex': 'Hex',
'hey_username': 'Hey',
'hide_hex_to_decimal': 'Itago ang hex patungong decimal conversion',
'highscore': 'High Score',
'how_it_works': 'Paano ito gumagana?',
'how_to_check': 'Paano tingnan?',
'how_to_play': 'Paano maglaro',
'if_the_maximum_multiplier': '• Kung ang maximum multiplier na 20.000 ay naabot, lahat ng bukas na pusta ay awtomatikong naka-cash out sa maximum multiplier',
'increase': 'Taasan',
'input_bet_amount': 'Ilagay ang halaga ng pusta at ilagay ang iyong pusta bago ang takeoff.',
'insufficient_funds': 'Hindi sapat na pondo',
'it_blew_up': 'Sumabog! Haay naku...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Mga nanalo sa jackpot',
'key': 'Key',
'load_more': 'MAG-LOAD NG HIGIT PA',
'loading': 'Naglo-load',
'loading_next_round': 'Nilo-load ang susunod na round...',
'loss_gifs': 'TALO',
'lost_connection': 'Nawalang Koneksyon',
'make_a_bet': 'Pumusta',
'malfunction_voids': 'Bino-void ng malfunction ang lahat ng Pagbabayad at Paglalaro.',
'maximum_payout': 'Ang maximum na payout ay ',
'menu': 'Menu',
'min_bet_is': 'Ang min na pusta ay',
'month': 'Buwan',
'more_details': 'Marami Pang Detalye',
'more_information': 'Higit pang impormasyon',
'multiplier': 'Multiplier',
'multiplier_starts': '• Nagsisimula ang multiplier sa 1 at umaakyat hanggang 20.000',
'multipliers_larger': 'Ang mga multiplier na mas malaki kaysa x20.000 ay naka-cap sa max multiplier value',
'music_settings': 'Settings ng Musika',
'my_bet_history': 'Bet History Ko',
'my_bets': 'Mga Pusta Ko',
'my_bonysing_history': 'Bonusing History Ko',
'network_latency': 'NETWORK LATENCY',
'network_latency_content': 'Ang network latency ay hindi maiiwasang bahagi ng koneksyon sa internet. Itinuturing namin ang lahat ng pusta at cashout na valid lamang sa panahong marating ng mga ito ang aming server, kahit ano pa ang durasyon ng network latency.',
'network_latency_title': 'NETWORK LATENCY',
'no_thanks': 'Hindi, salamat!',
'not_right_now': 'Huwag ngayon',
'off': 'off',
'oh_snap': 'Ay Naku!',
'ok_close': 'Okay, Isara!',
'ok_thanks': 'Okay, Salamat!',
'on': 'on',
'on_loss': 'SA TALO',
'on_win': 'SA PANALO',
'online': 'Online',
'per_bet': 'kada pusta',
'pick_your_avatar_img': 'Piliin ang imahe ng avatar mo',
'place_bet_with_last': 'Maglagay ng pusta na may huling piniling taya ',
'play_now': 'Maglaro Ngayon',
'player_1_seed': 'Player 1 seed',
'player_2_seed': 'Player 2 seed',
'player_3_seed': 'Player 3 seed',
'please_wait_active_bets': 'Mangyaring maghintay na makumpleto ang mga kasalukuyang pusta upang ma-activate ang mga libreng pusta',
'points': 'PUNTOS',
'powered_by': 'ay Pinapagana ng',
'previous_hand': 'Nakaraang Hand',
'prize': 'PREMYO',
'prizes': 'mga premyo',
'progressive_jackpot': 'PROGRESIBONG JACKPOT',
'promo_credit': 'Promo Credit',
'promo_credit_condradulations': 'Binabati kita! Nanalo ka ng napakalaking Promo credit na magagamit mo sa laro!',
'promo_credits': 'MGA PROMO CREDIT',
'promo_history': 'Promo History',
'provably_fair': 'Provably Fair',
'provably_fair_1_paragraph': 'Ang \'Rocketman\' ay batay sa cryptographic technology na tinatawag na \'Provably Fair\'. Ginagarantiya ng teknolohiyang ito ang 100% pagiging patas ng resulta ng laro. Sa teknolohiyang ito, imposible para sa sinumang third party ang makialam sa proseso n',
'provably_fair_2_paragraph': 'Ang resulta ng bawat round (Game\'s \'Fly away\' coefficient) ay hindi nililikha sa aming mga server. Ito ay nililika sa tulong ng mga round player at ganap na malinis. Sa ganitong paraan, imposible para sa sino man ang magmanipula ng output ng laro. Maaa',
'provably_fair_3_paragraph': 'Ang resulta ng round ay nililikha mula sa apat na independent na kalahok ng round',
'provably_fair_4_paragraph': 'Kapag nagsimula ang round, pinagsasama ng laro ang server seed at tatlong client seed. Mula sa mga pinagsamang symbol ay nalilikha ang SHA512 hash, at mula sa hash na ito - resulta ng laro.',
'provably_fair_5_paragraph': 'Makikita mo ang pagiging patas ng bawat round mula sa history ng laro, sa pag-click sa green icon. Sa nabuksang window, makikita mo ang server seed, 3 pares ng player seed, pinagsamang hash at resulta ng round.',
'provably_fair_6_paragraph': 'Ang hashed version ng server seed ng mga susunod na round ay available sa publiko sa settings window (Sa user menu, tingnan ang "Provably Fair Settings" at pagkatapos ay "Susunod na server seed SHA 256"). Maaari mo ring baguhin ang iyong client seed dito.',
'provably_fair_7_paragraph': 'Ang pagiging tama ng hash ay masusuri sa kahit anong online calculator, halimbawa',
'provably_fair_system': 'sistemang provably fair',
'quick_explanation': 'Mabilis na pagpapaliwanag',
'randomly_generated_server_hash': 'Server HASH na nalikha nang random',
'rank': 'RANK',
'raw_multiplier': 'raw multiplier',
'raw_multiplier_to_edged': 'Raw Multiplier patungong Edged Multiplier, gamit ang',
'remove_additional_bet': 'Alisin ang karagdagang pusta',
'reset': 'I-reset',
'return_to_player': 'RETURN SA PLAYER',
'rocketman_is_pure_excitement': 'Ang Rocketman ay purong kasiyahan! Makipagsapalaran at manalo.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Mga nanalo sa Rocketpot',
'round': 'Round',
'round_id': 'Round Id',
'round_multiplier': 'Round Multiplier',
'round_results': 'resulta ng round',
'rounds': 'mga round',
'rtp': 'RTP',
'rules': 'mga panuntunan',
'rules_details': 'Mga panuntunan ng tournament',
'save': 'I-save',
'schedule': 'schedule',
'second': 'ikalawa',
'seconds_left': 's natitira',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'PAKIHINTAY NA KUMPLETO ANG MGA KASALUKUYANG pustahan',
'min_autocashout_value_must_be': 'DAPAT NA ANG MIN AUTOCASHOUT VALUE',
'min_bet_value_must_be': 'DAPAT NA ANG MIN BET VALUE',
'server_seed': 'Server Seed',
'session_dissconnected': 'Nadiskonekta ang Session',
'multisession_not_allowed': 'Hindi pinapayagan ang multisession',
'set_auto_bet': 'ITAKDA ANG AUTO BET',
'set_auto_cashout': 'ITAKDA ANG AUTO CASHOUT',
'shourtcut_keys': 'MGA SHORTCUT KEY',
'show_hex_to_deimal': 'Ipakita ang hex patungong decimal conversion',
'sound': 'TUNOG',
'sound_settigs': 'Settings ng Audio',
'start_with_autoplay': 'Magsimula nang may autoplay',
'stop_autoplay': 'ITIGIL ANG AUTOPLAY',
'stop_on_loss': 'ITIGIL SA LOSS',
'stop_on_loss_tooltip': 'Gamitin ang Itigil sa Loss para itigil ang autobet pagkatapos matalo ng nakatakdang halaga.<br> Halimbawa, kung nagsimula ka sa $100 at itinakda mo ang Itigil sa Loss sa $25',
'stop_on_profit': 'ITIGIL SA PROFIT',
'stop_on_profit_tooltip': 'Gamitin ang Itigil sa Profit para itigil ang autobet pagkatapos kumita ng nakatakdang halaga.<br> Halimbawa, kung nagsimula ka sa $100 at itinakda mo ang Itigil sa Profit sa $25',
'there_are_no_active_torunament': 'Walang aktibong tournament sa panahong ito.',
'third': 'ikatlo',
'this_game_is': 'Ang larong ito ay',
'this_tool_recalculates': 'Muling kinakalkula ng tool na ito ang multiplier mula sa game hash. Maaari mong i-copy/paste ang kahit anong game hash para beripikahin ang multiplier.',
'time': 'ORAS',
'time_frame': 'Takdang Oras',
'to': 'HANGGANG',
'toggle_animation': 'I-toggle ang Animation',
'top_odds': 'Mga Nangungunang Odds',
'top_wins': 'Mga Nangungunang Panalo',
'total_bets': 'Total na Pusta',
'tournament': 'Tournament',
'tournament_information': 'Impormasyon ng Tournament',
'try_advanced_autoplay': 'Try new advanced autoplay feature! ',
'uncredited_wins': 'Mga panalong hindi na-credit',
'user': 'USER',
'user_and_points': 'USER & PUNTOS',
'username': 'Username',
'username_must_be_at_least': 'Ang username ay kailangang hindi bababa sa',
'verify': 'Beripikahin',
'vip_popup_text': 'Maaari kang pumili ng isa sa mga espesyal na VIP avatar na available lang sa aming mga VIP na miyembro!',
'vip_popup_title': 'Congratulations sa pagiging VIP player',
'waiting_for_the_next_round': 'Naghihintay para sa susunod na round...',
'warnings_are_calculated': ' • Kinakalkula ang mga panalo sa pamamagitan ng pag-multiply sa taya ng pusta sa multiplier sa panahon ng Cashout event',
'watch_take_off': 'Panoorin habang umaangat ang iyong Maswerteng Rocket at tumataas ang iyong panalo.',
'watch_the_rocket': 'Panoorin ang Rocket',
'we_have_noticed': 'Napansin naming ito ang iyong unang beses na naglalaro ng "Rocketman". Gusto mo bang makakita ng mabilis na video na nagpapaliwanag kung paano ito laruin?',
'week': 'Linggo',
'welcome_to_rocketman': 'Welcome sa Rocketman - isang bago at kapana-panabik na laro! Sa Rocketman, may tsansa kang manalo ng hanggang 20.000x ng iyong orihinal na taya.',
'what_is_provably_fair': 'Ano ang Provably fair?',
'will_be_deposited': 'Ay madedeposito sa balanse ng player sa unang regular na panalo',
'win': 'PANALO',
'win_amount': 'Halaga ng Panalo',
'win_gifs': 'PANALO',
'winner': 'nanalo',
'wins': 'mga panalo',
'write_a_replay': 'Magsulat ng tugon...',
'year': 'Taon',
'yes': 'OO',
'yes_start_the_round': 'Oo, simulan ang round',
'you_have_cashed_out': 'Nag-cash out ka, ang panalo mo ay ',
'you_have_won': 'NANALO KA',
'your_bet_is_lost': 'matatalo ang pusta mo.',
'current': 'Kasalukuyan',
'previous': 'Nakaraang',
'claim': 'Urimaikōravum',
'this_feature_will_give': 'inta amcam, araṭṭaiyil uḷḷa cīraṟṟa payaṉarkaḷukku tērnteṭukkappaṭṭa tokaiyai vaḻaṅkum',
'amount_per_player': 'oru vīrarukkut tokai',
'no_of_players': 'vīrarkaḷiṉ eṇṇikkai',
'meteor_shower': 'erikal poḻivu',
'claim_your_free_bet': 'I-claim ang Iyong Libreng Taya',
'meteor_shower_total': 'Kabuuan ng Pag-ulan ng Meteor',
'shower': 'ULAN',
'total': 'Kabuuan',
'meteor_shower_incoming': 'Papasok na Meteor Shower, tingnan ang chat!',
'insurance': 'INSURANCE',
'insurance_paragraph': 'Ang mga manlalaro ay maaaring bumili ng insurance para sa kanilang taya sa pamamagitan ng pag-click sa checkbox na "Bet Insurance" sa tabi ng pindutan ng taya. Ang insurance ay nagkakahalaga ng 10% ng kabuuang halaga ng taya at babayaran ang halaga ng taya sa manlalaro kung ang rocket ay sumabog sa isang 1.00 multiplier.',
'insure_your_bet': 'SIGURADO ANG IYONG pustahan',
'bet_insurance_promo_text': 'Piliin ang opsyong "Bet Insurance" upang masiguro ang iyong taya mula sa pag-crash ng rocket sa 1.00 multiplier.',
'bet_insurance_promo_text_helper': 'Magbasa pa sa seksyong "Mga Panuntunan sa Laro"...',
'game_explanation_1': 'Piliin ang iyong multiplier, at kung ang multiplier ng laro ay lumampas sa iyong napiling multiplier, ang iyong panalo',
'game_explanation_2': 'Iyong Multiplier x Iyong Taya',
'retail_max_mp_1': 'Manalo hanggang sa',
'retail_max_mp_2': 'ang taya mo!',
'play_multibets': 'Maglaro',
'multibets': 'MGA MULTIBETS',
'bonus_game': 'Pōṉas viḷaiyāṭṭu',
'all_wins_doubled': 'aṉaittu veṟṟikaḷum iraṭṭippākiṉa!',
'multibets_paragraph_1': 'upang madagdagan ang iyong mga posibilidad!',
'multibets_paragraph_2': 'Maaari mong pagsamahin ang mga taya sa hanggang sampung round sa isang multibet at manalo ng malalaking payout!',
'multibets_paragraph_3': 'Sa tuwing nanalo ang isang round, ang panalong dibidendo mula sa round na iyon ay itataya sa susunod na round, hanggang sa manalo ang lahat ng round.',
'bonus_launch': 'Manalo ng x2 sa lahat ng taya sa round na "Bonus Launch"!',
'multiplier_history': 'Kasaysayan ng Multiplier',
'will_start_soon': 'magsisimula sa lalong madaling panahon',
'hurry_up_and_place_your_bets': 'Magmadali at ilagay ang iyong mga taya!',
'active_bets': 'Mga Aktibong Taya',
'shop': 'MAMILI',
'game_meteor_shower_paragraph': 'Ang Meteor Shower ay nagbibigay ng isang libreng taya ng isang paunang natukoy na halaga sa isang paunang natukoy na bilang ng mga manlalaro. Maaari itong simulan alinman sa pamamagitan ng server o ng isang manlalaro. Kapag na-activate na ang Meteor Shower, may ilalabas na mensahe sa laro, na nagdidirekta sa mga user na tingnan ang chat kung saan matatagpuan ang mensahe ng Meteor Shower. Maaaring kunin ng mga manlalaro ang libreng taya sa pamamagitan ng pag-click sa pindutang "I-claim". Ang mga libreng taya ay iginagawad sa mga manlalaro na unang nag-click sa "Claim" na buton. Ang paggamit ng mga autoclicker o anumang uri ng automation software upang tularan ang mga pag-click ng manlalaro at mag-claim ng mga premyo ay ipinagbabawal at maaaring magresulta sa pagbabawal sa feature na ito.',
'claming_failed': 'Nabigo ang pag-claim',
'player_on_blacklist': 'Nasa blacklist ang player',
'you_can_claim_ticket': 'Maaari ka lamang mag-claim ng ticket kung ikaw ay aktibong manlalaro na may regular na taya',
'you_already_have_free_tickets': 'Mayroon ka nang mga libreng tiket, mangyaring gastusin ito muna',
'please_play_the_game_in_portait_mode': 'Pakisuyo, laruin ang laro sa portrait mode.',
};
langArr.xe = {
'your_insurance_returns_you': 'Your insurance returns you',
'bet_insurance': 'Bet Insurance',
'max_bet_is': 'Max bet is',
'already_active_bet_of': 'and there is already active bet of',
'1_from_game_history': '1. From game history',
'2_in_settings_window': '2. In settings window',
'3_online_calculator': '3. Online calculator',
'active_tournament_1': 'Active tournament ',
'active_tournament_2': 'has no plays yet.',
'add_another_bet': 'Add another play',
'advanced_autoplay': 'ADVANCED AUTOPLAY',
'advanced_autoplay_for_bet': 'Advanced Autoplay',
'alien_saucer_free_bets': 'Alien Saucer Free Plays',
'all_bets': 'All Plays',
'all_in_your_hands': 'All in your hands!',
'allow': 'Allow',
'at': 'at',
'austronaut_promo_credit': 'Astronaut Promo Coins',
'auto_play_and_autocash': 'AUTOPLAY AND AUTOCOLLECT ',
'autoplay': 'Autoplay',
'autoplay_and_cashout': '02. Auto Play & auto collect',
'back_to_home': 'Back to home',
'balance': 'Balance',
'bet': 'PLAY',
'bet_amount': 'PLAY AMOUNT',
'bet_and_cashout': '01. Play & Win',
'bet_bets_tab': 'COINS',
'bet_failed': 'Play Failed',
'bet_statistic': 'Play Statistic',
'bet_time': 'Play Time',
'bet_wins': 'Top Wins',
'bets': 'Plays',
'biggest_odds': 'Biggest Odds',
'bonus_type': 'BONUS TYPE',
'bonusing_history': 'Bonusing History',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot winners',
'but_remember_paragraph': 'But remember, if you did not have time to make a Collect before the Lucky Rocket explodes,',
'calculate_raw_mp_to_decimal': 'Calculate Raw Multiplier from decimal, and round it down to nearest integer',
'cancel': 'Cancel',
'cant_post_media_content': 'Can\'t post media content frequently',
'cashed_out': 'Cashed out',
'cashout': 'Collect',
'cashout_at': 'COLLECT AT',
'cashout_before': 'Collect before the rocket explodes and the coins are yours!',
'change_avatar': 'Change Avatar',
'change_username': 'Change Username',
'characters_long': 'characters long',
'chat': 'Chat',
'chat_room': 'Chat Room',
'checking_for_spam': 'Checking for spam...',
'close': 'Close',
'coeff': 'COEFF.',
'collect_win': 'Collect Win',
'combined_seed': 'Combined seed',
'company': 'Company',
'congratulations_you_have_won_free_bets': 'Congratulations! You have won Bonus Plays! You can play',
'convert_first_13_to_decimal': ' Convert first 13 bytes to decimal',
'd': 'd',
'date': 'DATE',
'date_and_time': 'Date and Time',
'deny': 'Deny',
'detailed_game_rules': 'Detailed Game Rules',
'dissconnection_policy': 'DISCONNECTION POLICY',
'easy_as_one_two_three': 'Easy as one, two, three',
'enter_username': 'Please enter desired username',
'error_handling': 'ERROR HANDLING',
'exit_full_screen': 'Exit full-screen mode',
'first': 'first',
'first_time_playing': 'First time playing "Rocketman"?',
'folow_the_three_easy_steps': 'Follow the three easy steps, and enjoy Rocketman! ',
'free_bets': 'FREE PLAYS',
'free_bets_desc': 'You won free plays! You can take advantage of free plays at any time by pressing the start button.',
'free_bets_header': 'Free Plays',
'from': 'FROM',
'fullscreen_mode': 'For the better user experience the game will start in fullscreen mode.',
'function': 'Function',
'game_functions': 'Game Functions',
'game_history': 'GAME HISTORY',
'game_r_1_paragraph': 'A player may activate the autoplay option where a play is automatically placed for each new round using the play amount from the previous play.',
'game_r_10_paragraph': 'Once the promo coins are awarded, for any play that a player places, the coins will first be deducted from promo coins until it\'s used up, before continuing playing coins from the player\'s balance.',
'game_r_11_paragraph': 'The promo coins can not be collected, but any winning on such plays are coined to the player in full.',
'game_r_12_paragraph': 'Promo coins have a 30 day validity period after which they expire and can not be used.',
'game_r_13_paragraph': 'A player may be randomly awarded with free plays. A popup graphic will appear on screen notifying the player about awarded free plays, number of plays and amount per play.',
'game_r_14_paragraph': 'Once the free plays are awarded player may choose when to start the free play round. Once the free plays round is started, automatic play with the specified play is placed, and it is up to the player to collect at any given moment. If the player disconnects during free plays, free plays will still take place and they will follow the “Disconnection Policy”.',
'game_r_15_paragraph': 'Free plays have a 30 day validity period after which they expire and can not be used.',
'game_r_16a_paragraph': 'The overall theoretical return to player is ',
'game_r_17_paragraph': 'RTP percentages are continuously monitored and verified by independent third parties.',
'game_r_18_paragraph': 'If connection is lost after a play is placed, the play will not be cancelled. If the multiplier reaches 20.000x your play will be collected and coins will be applied to your account.',
'game_r_19_paragraph': 'You can chat in game with other players. Enter your message in the chat field, or share your winning play with other players.',
'game_r_2_paragraph': 'An autocollect multiplier can be entered in the “Autocollect” field, and when entered, the game will automatically collect if the set multiplier is reached.',
'game_r_20_paragraph': 'Players who misuse the Chat by abusing other players, or by using inappropriate and/or foul language will have their Chat privileges disabled.',
'game_r_21_paragraph': 'Rocketman is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through the drop down menu.',
'game_r_22_paragraph': 'By clicking on the “My Plays" button a player may review his latest plays. For further play history (eg. longer periods), a player may need to access the player history within the gaming platform itself.',
'game_r_23_paragraph': 'In event of an error rounds may not start on time. If that is the case our support team will try to resolve the issue as soon as possible, and resume with regular game rounds. In case of an issue with a game round itself, all plays will be cancelled and play amount returned to players.',
'game_r_24_paragraph': 'Network latency is unavoidable part of internet connectivity. We consider all plays and collects valid only at the time they reach our server, regardless of the network latency duration. ',
'game_r_3_paragraph': 'A player can use shortcut keys to quickly place a play or collect in the game. The key mappings are as follows',
'game_r_4_paragraph': '1% from each stake is dedicated to a progressive jackpot fund. This fund serves progressive jackpot system',
'game_r_5_paragraph': ' • Jackpot – Highest level, biggest prize, least frequent',
'game_r_6_paragraph': ' • Rocketpot – Medium level, medium prize',
'game_r_7_paragraph': ' • Boosterpot – Lowest level, low prize, most frequent ',
'game_r_8_paragraph': 'All jackpot levels are awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known. Jackpot is won by a first player whose play causes the jackpot to go over the mystery value. Jackpot winner is announced after the round is finished.',
'game_r_8a_paragraph': 'Note: Jackpot winner can be only one single Rocketman player actively playing in the round when the jackpot has been triggered. There is no possibility that if the player have won the jackpot, would have to share it with any other Rocketman player.',
'game_r_9_paragraph': 'A player may be randomly awarded with promo coins. A popup graphic will appear on screen notifying the player about awarded promo coins and its amount.',
'game_round': 'game rounds!',
'game_rules': 'Game Rules',
'game_rules_1_paragraph': 'Rocketman is a gambling entertainment of new generation. Win many times more in seconds! Rocketmanis built on a',
'game_rules_10_paragraph': ' • Auto Play is activated from “Auto” tab in Play Panel, by checking “Auto Play checkbox. After activated, plays will place automatically, but for cash out, you should press “Collect” button in each round. If you want play to cash out coins automatically, then use',
'game_rules_11_paragraph': '• Auto C is available from “Auto” tab on play panel. After activating, your play will be automatically cashed out, when reaches entered coefficient.',
'game_rules_2_paragraph': ', which is currently the only real guarantee of honesty in the gambling industry.',
'game_rules_3_paragraph': '• The win multiplier starts at 1x and grows more and more as the Lucky Plane takes off.',
'game_rules_4_paragraph': ' • Your winnings are the multiplier at which you made a Collect multiplied by your play coins. ',
'game_rules_5_paragraph': '• Before the start of each round, our provably fair random number generator generates the coefficient at which the Lucky Plane will fly away. You can check the honesty of this generation by clicking on opposite the result in the History tab.',
'game_rules_6_paragraph': '• Select amount and press Play button to make a play. ',
'game_rules_7_paragraph': '• You can make two plays simultaneously, by adding second play panel. To add second play panel press plus icon, located on top right corner of first play panel.',
'game_rules_8_paragraph': ' • Press "Collect" button to cash out your winnings. Your win is play coins multiplied by collect coefficient.',
'game_rules_9_paragraph': '• Your play is lost, if you didn’t cash out before plane flies away.',
'general_rules': 'GENERAL RULES',
'gifs': 'GIFS',
'go_back': 'Go Back',
'good_job_buddy': 'Good job buddy, keep it going',
'h': 'h',
'hashed_in_sha_512': 'Hashed in SHA512',
'hex': 'Hex',
'hey_username': 'Hey',
'hide_hex_to_decimal': 'Hide hex to decimal conversion',
'highscore': 'High Score',
'how_it_works': 'How it works ?',
'how_to_check': 'How to check ?',
'how_to_play': 'How to play',
'if_the_maximum_multiplier': '• If the maximum multiplier of 20.000 is reached, all open plays are automatically collected at the maximum multiplier',
'increase': 'Increase',
'input_bet_amount': 'Input play amount and place your play before takeoff.',
'insufficient_funds': 'Insufficient coins',
'it_blew_up': 'It blew up! Oh well...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpot winners',
'key': 'Key',
'load_more': 'LOAD MORE',
'loading': 'Loading',
'loading_next_round': 'Loading next round....',
'loss_gifs': 'LOSS',
'lost_connection': 'Lost Connection',
'make_a_bet': 'Make a play',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'maximum_payout': 'Maximum payout is ',
'menu': 'Menu',
'min_bet_is': 'Min play is',
'month': 'Month',
'more_details': 'More Details',
'more_information': 'More information',
'multiplier': 'Multiplier',
'multiplier_starts': '• The multiplier starts at 1 and goes up to 20.000',
'multipliers_larger': 'Multipliers larger than x20.000 is capped at max multiplier value',
'music_settings': 'Music Settings',
'my_bet_history': 'My Play History',
'my_bets': 'My Plays',
'my_bonysing_history': 'My Bonusing History',
'network_latency': 'NETWORK LATENCY',
'network_latency_content': 'Network latency is unavoidable part of internet connectivity. We consider all plays and collects valid only at the time they reach our server, regardless of the network latency duration.',
'network_latency_title': 'NETWORK LATENCY',
'no_thanks': 'No, thanks!',
'not_right_now': 'Not right now',
'off': 'off',
'oh_snap': 'Oh Snap!',
'ok_close': 'Okay, Close!',
'ok_thanks': 'Okay, Thanks!',
'on': 'on',
'on_loss': 'ON LOSS',
'on_win': 'ON WIN',
'online': 'Online',
'per_bet': 'per play',
'pick_your_avatar_img': 'Pick your avatar image',
'place_bet_with_last': 'Play with last selected coins ',
'play_now': 'Play Now',
'player_1_seed': 'Player 1 seed',
'player_2_seed': 'Player 2 seed',
'player_3_seed': 'Player 3 seed',
'please_wait_active_bets': 'Please wait for current play to complete in order to activate free plays',
'points': 'POINTS',
'powered_by': 'Powered by',
'previous_hand': 'Previous Hand',
'prize': 'PRIZE',
'prizes': 'prizes',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'promo_credit': 'Promo Credit',
'promo_credit_condradulations': 'Congratulations! You have won an astronomically good Promo coins that you can use in the game!',
'promo_credits': 'PROMO COINS',
'promo_history': 'Promo History',
'provably_fair': 'Provably Fair',
'provably_fair_1_paragraph': '\'Rocketman\' is based on cryptographic technology called \'Provably Fair\'. This technology guarantees 100% fairness of game result. With this technology, it\'s impossible for any third party to interfere in game process.',
'provably_fair_2_paragraph': 'Result of each round (Game\'s \'Fly away\' coefficient ) is not generated on our servers. It\'s generated with help of round players and is fully transparent. This way, it\'s impossible for anyone to manipulate game output. Also, anyone can check and conf',
'provably_fair_3_paragraph': 'Round result is generated from four independent participants of the round',
'provably_fair_4_paragraph': 'When round starts, game merges server seed with three client seeds. From merged symbols is generated SHA512 hash, and from this hash - game result.',
'provably_fair_5_paragraph': 'You can check fairness of each round from game history, by clicking on green icon. In opened window, you will see server seed, 3 pair of players seeds, combined hash and round result.',
'provably_fair_6_paragraph': 'Hashed version of next rounds server seed is available publicly in settings window (In user menu, check "Provably Fair Settings" and then "Next server seed SHA256"). You can also change your client seed here. If you want to participate in round result gen',
'provably_fair_7_paragraph': 'Correctness of hash can be checked in any online calculator, for example',
'provably_fair_system': 'provably fair system',
'quick_explanation': 'Quick explenation',
'randomly_generated_server_hash': 'Randomly generated server HASH',
'rank': 'RANK',
'raw_multiplier': 'raw multiplier',
'raw_multiplier_to_edged': 'Raw Multiplier to Edged Multiplier, using',
'remove_additional_bet': 'Remove additional play',
'reset': 'Reset',
'return_to_player': 'RETURN TO PLAYER',
'rocketman_is_pure_excitement': 'Rocketman is pure excitement! Risk and win.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot winners',
'round': 'Round',
'round_id': 'Round Id',
'round_multiplier': 'Round Multiplier',
'round_results': 'round results',
'rounds': 'rounds',
'rtp': 'RTP',
'rules': 'rules',
'rules_details': 'Tournament rules',
'save': 'Save',
'schedule': 'schedule',
'second': 'second',
'seconds_left': 's left',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'PLEASE WAIT FOR CURRENT BETS TO COMPLETE',
'min_autocashout_value_must_be': 'MIN AUTOCASHOUT VALUE MUST BE',
'min_bet_value_must_be': 'MIN BET VALUE MUST BE',
'server_seed': 'Server Seed',
'session_dissconnected': 'Session Disconnected',
'multisession_not_allowed': 'Multisession is not allowed',
'set_auto_bet': 'SET AUTO PLAY',
'set_auto_cashout': 'SET AUTO COLLECT',
'shourtcut_keys': 'SHORTCUT KEYS',
'show_hex_to_deimal': 'Show hex to decimal conversion',
'sound': 'SOUND',
'sound_settigs': 'Sound Settings',
'start_with_autoplay': 'Start with autoplay',
'stop_autoplay': 'STOP AUTOPLAY',
'stop_on_loss': 'STOP ON LOSS',
'stop_on_loss_tooltip': 'Use Stop on Loss to stop autoplay after losing a specified amount.<br/> For example, if you start with $100 and you set Stop on Loss to $25',
'stop_on_profit': 'STOP ON PROFIT',
'stop_on_profit_tooltip': 'Use Stop on Profit to stop autoplay after profiting a specified amount.<br/> For example, if you start with $100 and you set Stop on Profit to $25',
'there_are_no_active_torunament': 'There are no active tournaments at this moment.',
'third': 'third',
'this_game_is': 'This game is',
'this_tool_recalculates': 'This tool re-calculates the multiplier from the game hash. You may copy/paste any game hash to verify the multiplier.',
'time': 'TIME',
'time_frame': 'Time Frame',
'to': 'TO',
'toggle_animation': 'Toggle Animation',
'top_odds': 'Top Odds',
'top_wins': 'Top Wins',
'total_bets': 'Total Plays',
'tournament': 'Tournament',
'tournament_information': 'Tournament information',
'try_advanced_autoplay': 'Try new advanced autoplay feature! ',
'uncredited_wins': 'Uncredited wins',
'user': 'USER',
'user_and_points': 'USER & POINTS',
'username': 'Username',
'username_must_be_at_least': 'Username must be at least',
'verify': 'Verify',
'vip_popup_text': 'You may choose one of the special VIP avatars available exclusively to our VIP members!',
'vip_popup_title': 'Congratulations on becoming a VIP player',
'waiting_for_the_next_round': 'Waiting for the next round...',
'warnings_are_calculated': ' • Winnings are calculated by multiplying the play coins by the multiplier at the moment of the Collect event',
'watch_take_off': 'Watch as your Lucky Rocket takes off and your winnings increase.',
'watch_the_rocket': 'Watch the Rocket',
'we_have_noticed': '`We have noticed it\'s your first time playing "Rocketman". Would you like to see a quick video explaining how to play it?',
'week': 'Week',
'welcome_to_rocketman': 'Welcome to Rocketman - a novel and exciting game! With Rocketman you have a chance of winning up to 20.000x your original coin play.',
'what_is_provably_fair': 'What is Provably fair?',
'will_be_deposited': 'Will be deposited to players balance with first regular win',
'win': 'WIN',
'win_amount': 'Win Amount',
'win_gifs': 'WIN',
'winner': 'winner',
'wins': 'wins',
'write_a_replay': 'Write a reply ...',
'year': 'Year',
'yes': 'YES',
'yes_start_the_round': 'Yes, start the round',
'you_have_cashed_out': 'You have collected, your win is ',
'you_have_won': 'YOU HAVE WON',
'your_bet_is_lost': 'your play is lost.',
'current': 'Current',
'previous': 'Previous',
'claim': 'Claim',
'this_feature_will_give': 'This feature will give selected amount to random users in chat',
'amount_per_player': 'Amount Per Player',
'no_of_players': 'Number of players',
'meteor_shower': 'Meteor Shower',
'claim_your_free_bet': 'Claim Your Free Bet',
'meteor_shower_total': 'Meteor Shower Total',
'shower': 'SHOWER',
'total': 'Total',
'meteor_shower_incoming': 'Meteor Shower incoming, look at chat!',
'insurance': 'INSURANCE',
'insurance_paragraph': 'Players may purchase insurance for their bet by clicking on the "Bet Insurance" checkbox beside the bet button. Insurance costs 10% of the total bet amount and will pay back the bet amount to the player should the rocket explode at a 1.00 multiplier.',
'insure_your_bet': 'INSURE YOUR BET',
'bet_insurance_promo_text': 'Select "Bet Insurance" option to insure your bet from rocket crashing at 1.00 multiplier.',
'bet_insurance_promo_text_helper': 'Read more in "Game Rules" section...',
'game_explanation_1': 'Choose your multiplier, and if the game multiplier exceeds your chosen multiplier, your win will be',
'game_explanation_2': 'Your Multiplier x Your Bet',
'retail_max_mp_1': 'Win up to',
'retail_max_mp_2': 'your bet!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'Win x2 on all bets in the „Bonus Launch“ round!',
'multiplier_history': 'Multiplier History',
'will_start_soon': 'will start soon',
'hurry_up_and_place_your_bets': 'Hurry up and place your bets!',
'active_bets': 'Active Bets',
'shop': 'SHOP',
'game_meteor_shower_paragraph': 'Meteor Shower awards one free bet of a predefined value to a predefined number of players. It can be initiated either by the server or by a player. Once Meteor Shower is activated, a message drops in-game, directing users to look at the chat where the Meteor Shower message is located. Players can claim the free bet by clicking on the “Claim” button. Free bets are awarded to players who click on the “Claim” button first. Using autoclickers or any kind of automation software to emulate player clicks and claim prizes is prohibited and may result in a ban from this feature.',
'claming_failed': 'Claiming failed',
'player_on_blacklist': 'The player is on the blacklist',
'you_can_claim_ticket': 'You can claim ticket only if you are active player with regular bets',
'you_already_have_free_tickets': 'You already have free tickets, please spend it first',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
};
langArr.da = {
'your_insurance_returns_you': 'Din forsikring returnerer dig',
'bet_insurance': 'Bet forsikring',
'max_bet_is': 'Max indsats er',
'already_active_bet_of': 'og der er allerede aktiv indsats af',
'1_from_game_history': '1. Fra spilhistorie',
'2_in_settings_window': '2. I indstillingsvinduet',
'3_online_calculator': '3. Online lommeregner',
'active_tournament_1': 'Aktiv turnering',
'active_tournament_2': 'har ingen indsatser endnu.',
'add_another_bet': 'Tilføj endnu en indsats',
'advanced_autoplay': 'AVANCERET AUTOSPIL',
'advanced_autoplay_for_bet': 'Avanceret Autoplay For Bet',
'alien_saucer_free_bets': 'Alien Saucer Free Bets',
'all_bets': 'Alle væddemål',
'all_in_your_hands': 'Alt i dine hænder!',
'allow': 'Give lov til',
'at': 'på',
'austronaut_promo_credit': 'Astronaut Promo Credit',
'auto_play_and_autocash': 'AUTOSPIL OG AUTOCASHOUT',
'autoplay': 'Automatisk afspilning',
'autoplay_and_cashout': '02. Autospil og automatisk udbetaling',
'back_to_home': 'Tilbage til hjemmet',
'balance': 'Balance',
'bet': 'VÆDDE',
'bet_amount': 'INDSATSBELØB',
'bet_and_cashout': '01. Bet & udbetaling',
'bet_bets_tab': 'INDSATS',
'bet_failed': 'Bet mislykkedes',
'bet_statistic': 'Bet statistik',
'bet_time': 'Indsatstid',
'bet_wins': 'Bet vinder',
'bets': 'Væddemål',
'biggest_odds': 'Største odds',
'bonus_type': 'BONUSTYPE',
'bonusing_history': 'Bonus historie',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot vindere',
'but_remember_paragraph': 'Men husk, hvis du ikke havde tid til at lave en udbetaling, før Lucky Rocket eksploderer,',
'calculate_raw_mp_to_decimal': 'Beregn rå multiplikator fra decimal, og rund den ned til nærmeste heltal',
'cancel': 'Afbestille',
'cant_post_media_content': 'Kan ikke poste medieindhold ofte',
'cashed_out': 'Udbetalt',
'cashout': 'Udbetale',
'cashout_at': 'Udbetaling KL',
'cashout_before': 'Udbetaling før raketten eksploderer, og pengene er dine!',
'change_avatar': 'Skift avatar',
'change_username': 'Skift brugernavn',
'characters_long': 'tegn lange',
'chat': 'Snak',
'chat_room': 'Chatrum',
'checking_for_spam': 'Søger for spam...',
'close': 'Tæt',
'coeff': 'COEFF.',
'collect_win': 'Saml vinde',
'combined_seed': 'Kombineret frø',
'company': 'Selskab',
'congratulations_you_have_won_free_bets': 'Tillykke! Du har vundet bonusvæddemål! Du kan satse',
'convert_first_13_to_decimal': ' Konverter de første 13 bytes til decimaler',
'd': 'd',
'date': 'DATO',
'date_and_time': 'Dato og tid',
'deny': 'Nægte',
'detailed_game_rules': 'Detaljerede spilleregler',
'dissconnection_policy': 'AFBRYDNINGSPOLITIK',
'easy_as_one_two_three': 'Nem som en, to, tre',
'enter_username': 'Indtast venligst det ønskede brugernavn',
'error_handling': 'FEJLHÅNDTERING',
'exit_full_screen': 'Afslut fuldskærmstilstand',
'first': 'først',
'first_time_playing': 'Første gang du spiller "Rocketman"?',
'folow_the_three_easy_steps': 'Følg de tre nemme trin, og nyd Rocketman!',
'free_bets': 'GRATIS INDSATS',
'free_bets_desc': 'Du har vundet gratis væddemål! Du kan til enhver tid drage fordel af gratis væddemål ved at trykke på startknappen.',
'free_bets_header': 'Gratis væddemål',
'from': 'FRA',
'fullscreen_mode': 'For den bedre brugeroplevelse vil spillet starte i fuldskærmstilstand.',
'function': 'Fungere',
'game_functions': 'Spil funktioner',
'game_history': 'SPILHISTORIE',
'game_r_1_paragraph': 'En spiller kan aktivere autoplay-indstillingen, hvor et væddemål automatisk placeres for hver ny runde ved hjælp af indsatsbeløbet fra det forrige væddemål.',
'game_r_10_paragraph': 'Når promo-kreditterne er tildelt, for ethvert væddemål, som en spiller placerer, vil pengene først blive trukket fra promo-kreditter, indtil de er brugt op, før du fortsætter med at hæve penge fra spillerens tegnebog.',
'game_r_11_paragraph': 'Kampagnekreditterne kan ikke udbetales, men enhver gevinst på sådanne væddemål krediteres spilleren fuldt ud.',
'game_r_12_paragraph': 'Kampagnekreditter har en gyldighedsperiode på 30 dage, hvorefter de udløber og kan ikke bruges.',
'game_r_13_paragraph': 'En spiller kan tilfældigt tildeles gratis væddemål. En pop op-grafik vises på skærmen, der informerer spilleren om tildelte gratis væddemål, antal væddemål og beløb pr. væddemål.',
'game_r_14_paragraph': 'Når de gratis væddemål er tildelt, kan spilleren vælge, hvornår den gratis væddemålsrunde skal starte. Når free bets-runden er startet, placeres automatisk bet med den specificerede indsats, og det er op til spilleren at udbetale på ethvert givet tidspunkt. Hvis spilleren afbryder forbindelsen under gratis væddemål, vil gratis væddemål stadig finde sted, og de vil følge "Afbrydelsespolitikken".',
'game_r_15_paragraph': 'Gratis væddemål har en gyldighedsperiode på 30 dage, hvorefter de udløber og kan ikke bruges.',
'game_r_16a_paragraph': 'Den samlede teoretiske tilbagevenden til spilleren er ',
'game_r_17_paragraph': 'RTP-procenter overvåges og verificeres løbende af uafhængige tredjeparter.',
'game_r_18_paragraph': 'Hvis forbindelsen afbrydes efter et væddemål er placeret, vil væddemålet ikke blive annulleret. Hvis multiplikatoren når 20.000x vil dit væddemål automatisk blive udbetalt, og kreditter vil blive anvendt på din konto.',
'game_r_19_paragraph': 'Du kan chatte i spillet med andre spillere. Indtast din besked i chatfeltet, eller del dit vindende væddemål med andre spillere.',
'game_r_2_paragraph': 'En autocashout-multiplikator kan indtastes i "Autocashout"-feltet, og når den indtastes, vil spillet automatisk udbetale, hvis den indstillede multiplikator nås.',
'game_r_20_paragraph': 'Spillere, der misbruger chatten ved at misbruge andre spillere eller ved at bruge upassende og/eller grimt sprog, vil få deres chat-privilegier deaktiveret.',
'game_r_21_paragraph': 'Rocketman er designet med smuk baggrundsmusik og lydeffekter i tankerne. Hvis du ønsker at deaktivere musik og/eller lydeffekter, kan du gøre det via rullemenuen.',
'game_r_22_paragraph': 'Ved at klikke på knappen "Mine indsatser" kan en spiller gennemgå sine seneste indsatser. For yderligere spillehistorik (f.eks. længere perioder), skal en spiller muligvis få adgang til spillerhistorikken på selve spilleplatformen.',
'game_r_23_paragraph': 'I tilfælde af fejl starter runder muligvis ikke til tiden. Hvis det er tilfældet, vil vores supportteam forsøge at løse problemet så hurtigt som muligt og genoptage med almindelige spilrunder. I tilfælde af et problem med selve en spilrunde, vil alle væddemål blive annulleret, og indsatsbeløbet returneres til spillerne.',
'game_r_24_paragraph': 'Netværksforsinkelse er en uundgåelig del af internetforbindelsen. Vi anser alle væddemål og udbetalinger kun for gyldige på det tidspunkt, de når vores server, uanset netværkets latenstid.',
'game_r_3_paragraph': 'En spiller kan bruge genvejstaster til hurtigt at placere en indsats eller udbetaling i spillet. Nøglekortlægningerne er som følger',
'game_r_4_paragraph': '1% af hver indsats dedikeres til en progressiv jackpotfond. Denne fond tjener det progressive jackpotsystem',
'game_r_5_paragraph': '• Jackpot – Højeste niveau, største præmie, mindst hyppigt',
'game_r_6_paragraph': ' • Rocketpot – Mellem niveau, medium præmie',
'game_r_7_paragraph': ' • Boosterpot – Laveste niveau, lav præmie, hyppigst',
'game_r_8_paragraph': 'Alle jackpot-niveauer tildeles ved hjælp af en "Mystery" eller "must-hit-by" mekanik. Værdien af ​​hver "must-hit-by" jackpot bestemmes umiddelbart efter den foregående jackpot er vundet af en tilfældig talgenerator og gemt som en krypteret værdi i fjerndatabasen. Værdien er krypteret og dermed skjult og kendes ikke. Jackpot vindes af en første spiller, hvis indsats får jackpotten til at gå over den mystiske værdi. Jackpotvinderen annonceres efter runden er færdig.',
'game_r_8a_paragraph': 'Bemærk: Jackpotvinderen kan kun være én enkelt Rocketman-spiller, der aktivt spiller i runden, når jackpotten er blevet udløst. Der er ingen mulighed for, at hvis spilleren har vundet jackpotten, skal han dele den med enhver anden Rocketman-spiller.',
'game_r_9_paragraph': 'En spiller kan tilfældigt tildeles promo-kreditter. En pop op-grafik vises på skærmen, der informerer spilleren om tildelte promo-kreditter og dets beløb.',
'game_round': 'spillerunder!',
'game_rules': 'Spilleregler',
'game_rules_1_paragraph': 'Rocketman er en gambling underholdning af ny generation. Vind mange gange mere på få sekunder! Rocketmanis bygget på en',
'game_rules_10_paragraph': ' • Autospil aktiveres fra fanen "Auto" i indsatspanelet ved at markere afkrydsningsfeltet "Auto bet". Efter aktivering vil væddemål placeres automatisk, men for udbetaling skal du trykke på knappen "udbetaling" i hver runde. Hvis du ønsker at væddemålet skal udbetales automatisk, så brug',
'game_rules_11_paragraph': '• Automatisk udbetaling er tilgængelig fra fanen "Auto" på indsatspanelet. Efter aktivering vil din indsats automatisk blive udbetalt, når den indtastede koefficient når.',
'game_rules_2_paragraph': ', som i øjeblikket er den eneste reelle garanti for ærlighed i spilleindustrien.',
'game_rules_3_paragraph': '• Gevinstmultiplikatoren starter ved 1x og vokser mere og mere, efterhånden som Lucky Plane letter.',
'game_rules_4_paragraph': ' • Dine gevinster er den multiplikator, hvormed du lavede en udbetaling ganget med din indsats.',
'game_rules_5_paragraph': '• Før starten af ​​hver runde genererer vores beviseligt rimelige tilfældige tal-generator koefficienten, hvormed Lucky Plane vil flyve væk. Du kan tjekke denne generations ærlighed ved at klikke på over for resultatet i fanen Historie.',
'game_rules_6_paragraph': '• Vælg beløb, og tryk på knappen "Bet" for at foretage en indsats.',
'game_rules_7_paragraph': '• Du kan lave to indsatser samtidigt ved at tilføje et andet indsatspanel. For at tilføje det andet indsatspanel skal du trykke på plusikonet, placeret i øverste højre hjørne af det første indsatspanel.',
'game_rules_8_paragraph': ' • Tryk på knappen "Udbetaling" for at udbetale dine gevinster. Din gevinst er satset ganget med udbetalingskoefficient.',
'game_rules_9_paragraph': '• Dit væddemål er tabt, hvis du ikke udbetalte før flyet fløj væk.',
'general_rules': 'GENERELLE REGLER',
'gifs': 'GIFS',
'go_back': 'Gå tilbage',
'good_job_buddy': 'Godt arbejde kammerat, fortsæt med det',
'h': 'h',
'hashed_in_sha_512': 'Hashed i SHA512',
'hex': 'Hex',
'hey_username': 'Hej',
'hide_hex_to_decimal': 'Skjul hex til decimalkonvertering',
'highscore': 'Højeste score',
'how_it_works': 'Hvordan det virker ?',
'how_to_check': 'Hvordan tjekker man?',
'how_to_play': 'Hvordan man spiller',
'if_the_maximum_multiplier': '• Hvis den maksimale multiplikator på 20.000 nås, udbetales alle åbne væddemål automatisk med den maksimale multiplikator',
'increase': 'Øge',
'input_bet_amount': 'Indtast indsatsbeløb og placer din indsats før start.',
'insufficient_funds': 'Ikke nok penge',
'it_blew_up': 'Det blæste i luften! Åh altså...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpot vindere',
'key': 'Nøgle',
'load_more': 'LÆS MERE',
'loading': 'Indlæser',
'loading_next_round': 'Indlæser næste runde....',
'loss_gifs': 'TAB',
'lost_connection': 'Tabt forbindelse',
'make_a_bet': 'Lav et væddemål',
'malfunction_voids': 'Funktionsfejl annullerer alle betalinger og afspilninger.',
'maximum_payout': 'Maksimal udbetaling er ',
'menu': 'Menu',
'min_bet_is': 'Min indsats er',
'month': 'Måned',
'more_details': 'Flere detaljer',
'more_information': 'Mere information',
'multiplier': 'Multiplikator',
'multiplier_starts': '• Multiplikatoren starter ved 1 og går op til 20.000',
'multipliers_larger': 'Multiplikatorer større end x20.000 er begrænset til maksimal multiplikatorværdi',
'music_settings': 'Musikindstillinger',
'my_bet_history': 'Min væddemålshistorie',
'my_bets': 'Mine væddemål',
'my_bonysing_history': 'Min bonushistorie',
'network_latency': 'NETVÆRKSLATENCY',
'network_latency_content': 'Netværksforsinkelse er en uundgåelig del af internetforbindelsen. Vi anser alle væddemål og udbetalinger kun for gyldige på det tidspunkt, de når vores server, uanset netværkets latenstid.',
'network_latency_title': 'NETVÆRKSLATENCY',
'no_thanks': 'Nej tak!',
'not_right_now': 'Ikke lige nu',
'off': 'af',
'oh_snap': 'Oh snap!',
'ok_close': 'Okay, tæt på!',
'ok_thanks': 'Okay tak!',
'on': 'på',
'on_loss': 'PÅ TABS',
'on_win': 'PÅ VIND',
'online': 'Online',
'per_bet': 'pr. indsats',
'pick_your_avatar_img': 'Vælg dit avatarbillede',
'place_bet_with_last': 'Placer et væddemål med sidst valgte indsats',
'play_now': 'Spil nu',
'player_1_seed': 'Spiller 1 frø',
'player_2_seed': 'Spiller 2 frø',
'player_3_seed': 'Spiller 3 frø',
'please_wait_active_bets': 'Vent venligst på, at nuværende væddemål er fuldført for at aktivere gratis væddemål',
'points': 'PUNKTER',
'powered_by': 'Drevet af',
'previous_hand': 'Forrige Hand',
'prize': 'PRÆMIE',
'prizes': 'præmier',
'progressive_jackpot': 'PROGRESSIV JACKPOT',
'promo_credit': 'Promo kredit',
'promo_credit_condradulations': 'Tillykke! Du har vundet en astronomisk god Promo-kredit, som du kan bruge i spillet!',
'promo_credits': 'PROMO KREDITTER',
'promo_history': 'Promo historie',
'provably_fair': 'Beviselig Fair',
'provably_fair_1_paragraph': '\'Rocketman\' er baseret på kryptografisk teknologi kaldet \'Provably Fair\'. Denne teknologi garanterer 100 % retfærdighed i spillets resultat. Med denne teknologi er det umuligt for nogen tredjepart at blande sig i spilprocessen.',
'provably_fair_2_paragraph': 'Resultatet af hver runde (Spillets \'Flyv væk\'-koefficient) genereres ikke på vores servere. Det er genereret ved hjælp af runde spillere og er fuldt gennemsigtigt. På denne måde er det umuligt for nogen at manipulere spillets output. Alle kan også kontrollere og bekræfte spillets retfærdighed.',
'provably_fair_3_paragraph': 'Rundens resultat genereres fra fire uafhængige deltagere i runden',
'provably_fair_4_paragraph': 'Når runden starter, fusionerer spillet serverseed med tre klientseed. Fra flettede symboler genereres SHA512 hash, og fra denne hash - spilresultat.',
'provably_fair_5_paragraph': 'Du kan tjekke retfærdigheden af ​​hver runde fra spilhistorikken ved at klikke på det grønne ikon. I det åbne vindue vil du se serverseed, 3 par spillere seeds, kombineret hash og runde resultat.',
'provably_fair_6_paragraph': 'Hashed version af næste rundes serverseed er tilgængelig offentligt i indstillingsvinduet (I brugermenuen, tjek "Provably Fair Settings" og derefter "Next server seed SHA256"). Du kan også ændre dit klientseed her. Hvis du ønsker at deltage i generering af runderesultater, skal du sørge for, at du er mellem de første 3 spillere, der satser i den runde.',
'provably_fair_7_paragraph': 'Korrektheden af ​​hash kan f.eks. kontrolleres i enhver online lommeregner',
'provably_fair_system': 'beviseligt retfærdigt system',
'quick_explanation': 'Hurtig forklaring',
'randomly_generated_server_hash': 'Tilfældigt genereret server HASH',
'rank': 'RANG',
'raw_multiplier': 'rå multiplikator',
'raw_multiplier_to_edged': 'Raw Multiplikator til Edged Multiplikator, vha',
'remove_additional_bet': 'Fjern yderligere indsats',
'reset': 'Nulstil',
'return_to_player': 'TILBAGE TIL SPILLEREN',
'rocketman_is_pure_excitement': 'Rocketman er ren spænding! Risiko og vind.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot vindere',
'round': 'Rund',
'round_id': 'Runde Id',
'round_multiplier': 'Rund multiplikator',
'round_results': 'runde resultater',
'rounds': 'runder',
'rtp': 'RTP',
'rules': 'regler',
'rules_details': 'Turneringsregler',
'save': 'Gemme',
'schedule': 'tidsplan',
'second': 'anden',
'seconds_left': 's tilbage',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'VENLIGST VENLIGST VENLIGST TIL AT AKTUELLE VEDDEmål FULDFØRES',
'min_autocashout_value_must_be': 'MIN AUTOCASHOUT VÆRDI SKAL VÆRE',
'min_bet_value_must_be': 'MIN VÆRDI SKAL VÆRE',
'server_seed': 'Server frø',
'session_dissconnected': 'Session afbrudt',
'multisession_not_allowed': 'Multisession er ikke tilladt',
'set_auto_bet': 'INDSTIL AUTO INDSATS',
'set_auto_cashout': 'INDSTIL AUTOUDBUD',
'shourtcut_keys': 'GENVEJSTASTER',
'show_hex_to_deimal': 'Vis hex til decimal konvertering',
'sound': 'LYD',
'sound_settigs': 'Lydindstillinger',
'start_with_autoplay': 'Start med autoplay',
'stop_autoplay': 'STOP AUTOSPIL',
'stop_on_loss': 'STOP VED TABS',
'stop_on_loss_tooltip': 'Brug Stop ved tab til at stoppe autobet efter at have tabt et angivet beløb.<br/> Hvis du f.eks. starter med $100, og du indstiller Stop ved tab til $25',
'stop_on_profit': 'STOP PÅ PROFIT',
'stop_on_profit_tooltip': 'Brug Stop on Profit til at stoppe autobet efter at have opnået et bestemt beløb.<br/> Hvis du f.eks. starter med $100, og du indstiller Stop on Profit til $25',
'there_are_no_active_torunament': 'Der er ingen aktive turneringer i øjeblikket.',
'third': 'tredje',
'this_game_is': 'Dette spil er',
'this_tool_recalculates': 'Dette værktøj genberegner multiplikatoren fra spillets hash. Du kan kopiere/indsætte enhver spilhash for at bekræfte multiplikatoren.',
'time': 'TID',
'time_frame': 'Tidsramme',
'to': 'TIL',
'toggle_animation': 'Slå animation til/fra',
'top_odds': 'Top odds',
'top_wins': 'Topgevinster',
'total_bets': 'Samlede indsatser',
'tournament': 'Turnering',
'tournament_information': 'Turneringsoplysninger',
'try_advanced_autoplay': 'Prøv den nye avancerede autoplay-funktion!',
'uncredited_wins': 'Ukrediterede sejre',
'user': 'BRUGER',
'user_and_points': 'BRUGER & PUNKTER',
'username': 'Brugernavn',
'username_must_be_at_least': 'Brugernavn skal være mindst',
'verify': 'Verificere',
'vip_popup_text': 'Du kan vælge en af ​​de specielle VIP-avatarer, der udelukkende er tilgængelige for vores VIP-medlemmer!',
'vip_popup_title': 'Tillykke med at blive VIP-spiller',
'waiting_for_the_next_round': 'Venter på næste runde...',
'warnings_are_calculated': ' • Gevinster beregnes ved at gange indsatsen med multiplikatoren på tidspunktet for udbetalingsbegivenheden',
'watch_take_off': 'Se, når din Lucky Rocket starter, og dine gevinster stiger.',
'watch_the_rocket': 'Se raketten',
'we_have_noticed': 'Vi har bemærket, at det er første gang, du spiller "Rocketman". Vil du gerne se en hurtig video, der forklarer, hvordan man spiller det?',
'week': 'Uge',
'welcome_to_rocketman': 'Velkommen til Rocketman - et nyt og spændende spil! Med Rocketman har du en chance for at vinde op til 20.000 gange din oprindelige indsats.',
'what_is_provably_fair': 'Hvad er beviseligt retfærdigt?',
'will_be_deposited': 'Vil blive indbetalt til spillerens saldo med den første almindelige gevinst',
'win': 'VINDE',
'win_amount': 'Gevinstbeløb',
'win_gifs': 'VINDE',
'winner': 'vinder',
'wins': 'vinder',
'write_a_replay': 'Skriv et svar...',
'year': 'År',
'yes': 'JA',
'yes_start_the_round': 'Ja, start runden',
'you_have_cashed_out': 'Du har udbetalt, din gevinst er',
'you_have_won': 'DU HAR VUNDET',
'your_bet_is_lost': 'din indsats er tabt.',
'current': 'Nuværende',
'previous': 'Tidligere',
'claim': 'Påstand',
'this_feature_will_give': 'Denne funktion vil give det valgte beløb til tilfældige brugere i chatten',
'amount_per_player': 'Beløb pr. spiller',
'no_of_players': 'Antal spillere',
'meteor_shower': 'Meteor regn',
'claim_your_free_bet': 'Gør krav på dit gratis væddemål',
'meteor_shower_total': 'Meteorbyger i alt',
'shower': 'REGN',
'total': 'Total',
'meteor_shower_incoming': 'Meteor Shower indkommende, se chat!',
'insurance': 'FORSIKRING',
'insurance_paragraph': 'Spillere kan købe en forsikring for deres indsats ved at klikke på afkrydsningsfeltet "Væddemålsforsikring" ved siden af ​​indsatsknappen. Forsikring koster 10% af det samlede indsatsbeløb og vil tilbagebetale indsatsbeløbet til spilleren, hvis raketten eksploderer med en multiplikator på 1,00.',
'insure_your_bet': 'FORSIKKER DIN VÆDDE',
'bet_insurance_promo_text': 'Vælg muligheden "Bet Insurance" for at sikre dit væddemål fra raketnedbrud ved en multiplikator på 1,00.',
'bet_insurance_promo_text_helper': 'Læs mere i afsnittet "Spilleregler"...',
'game_explanation_1': 'Vælg din multiplikator, og hvis spilmultiplikatoren overstiger din valgte multiplikator, vil din gevinst være',
'game_explanation_2': 'Din multiplikator x din indsats',
'retail_max_mp_1': 'Vind op til',
'retail_max_mp_2': 'din indsats!',
'play_multibets': 'Spil',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPIL',
'all_wins_doubled': 'Alle sejre fordoblet!',
'multibets_paragraph_1': 'for at øge dine odds!',
'multibets_paragraph_2': 'Du kan kombinere indsatser på op til ti runder til et enkelt multibet og vinde massive udbetalinger!',
'multibets_paragraph_3': 'Hver gang en runde vindes, satses vinderudbytte fra den runde i næste runde, indtil alle runder vinder.',
'bonus_launch': 'Vind x2 på alle væddemål i "Bonus Launch"-runden!',
'multiplier_history': 'Multiplikator historie',
'will_start_soon': 'starter snart',
'hurry_up_and_place_your_bets': 'Skynd dig og placer dine indsatser!',
'active_bets': 'Aktive væddemål',
'shop': 'BUTIK',
'game_meteor_shower_paragraph': 'Meteor Shower giver et gratis væddemål af en foruddefineret værdi til et foruddefineret antal spillere. Det kan startes enten af ​​serveren eller af en spiller. Når Meteor Shower er aktiveret, falder der en besked i spillet, som beder brugerne om at se på chatten, hvor Meteor Shower-meddelelsen er placeret. Spillere kan gøre krav på gratisvæddemålet ved at klikke på knappen "Gør krav". Gratis væddemål tildeles spillere, der først klikker på knappen "Gør krav". Brug af autoklikkere eller enhver form for automatiseringssoftware til at efterligne spillerklik og hævde præmier er forbudt og kan resultere i et forbud mod denne funktion.',
'claming_failed': 'Krav mislykkedes',
'player_on_blacklist': 'Spilleren er på sortlisten',
'you_can_claim_ticket': 'Du kan kun kræve billet, hvis du er aktiv spiller med almindelige indsatser',
'you_already_have_free_tickets': 'Du har allerede gratis billetter, så brug dem først',
'please_play_the_game_in_portait_mode': 'Venligst spil spillet i portrættilstand.',
};
langArr.nl = {
'your_insurance_returns_you': 'Uw verzekering keert terug',
'bet_insurance': 'Weddenschapsverzekering',
'max_bet_is': 'Maximale inzet is',
'already_active_bet_of': 'en er is al een actieve weddenschap van',
'1_from_game_history': '1. Uit de spelgeschiedenis',
'2_in_settings_window': '2. In het instellingenvenster',
'3_online_calculator': '3. Onlinecalculator',
'active_tournament_1': 'Actief toernooi',
'active_tournament_2': 'heeft nog geen weddenschappen.',
'add_another_bet': 'Voeg nog een weddenschap toe',
'advanced_autoplay': 'GEAVANCEERDE AUTOPLAY',
'advanced_autoplay_for_bet': 'Geavanceerde autoplay voor weddenschappen',
'alien_saucer_free_bets': 'Gratis weddenschappen op Alien Saucer',
'all_bets': 'Alle weddenschappen',
'all_in_your_hands': 'Alles in jouw handen!',
'allow': 'Toestaan',
'at': 'bij',
'austronaut_promo_credit': 'Promotegoed voor astronauten',
'auto_play_and_autocash': 'AUTOPLAY EN AUTOCASHOUT',
'autoplay': 'Automatisch afspelen',
'autoplay_and_cashout': '02. Automatisch spelen en automatische uitbetaling',
'back_to_home': 'Terug naar huis',
'balance': 'Evenwicht',
'bet': 'INZET',
'bet_amount': 'INZETBEDRAG',
'bet_and_cashout': '01. Inzetten en uitbetalen',
'bet_bets_tab': 'INZET',
'bet_failed': 'Inzet mislukt',
'bet_statistic': 'Statistiek van weddenschappen',
'bet_time': 'Wedden tijd',
'bet_wins': 'Inzet wint',
'bets': 'Weddenschappen',
'biggest_odds': 'Grootste kansen',
'bonus_type': 'BONUSTYPE',
'bonusing_history': 'Bonusgeschiedenis',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Winnaars van de Boosterpot',
'but_remember_paragraph': 'Maar onthoud: als je geen tijd hebt om een ​​uitbetaling te doen voordat de Lucky Rocket ontploft,',
'calculate_raw_mp_to_decimal': 'Bereken de ruwe vermenigvuldiger vanaf decimaal en rond deze naar beneden af ​​op het dichtstbijzijnde gehele getal',
'cancel': 'Annuleren',
'cant_post_media_content': 'Kan media-inhoud niet vaak posten',
'cashed_out': 'Uitbetaald',
'cashout': 'Uitbetalen',
'cashout_at': 'UITBETALEN BIJ',
'cashout_before': 'Uitbetaling voordat de raket ontploft en het geld van jou is!',
'change_avatar': 'Avatar wijzigen',
'change_username': 'Gebruikersnaam wijzigen',
'characters_long': 'karakters lang',
'chat': 'Chatten',
'chat_room': 'Chat room',
'checking_for_spam': 'Controleren op spam...',
'close': 'Dichtbij',
'coeff': 'COEFF.',
'collect_win': 'Verzamel overwinning',
'combined_seed': 'Gecombineerd zaad',
'company': 'Bedrijf',
'congratulations_you_have_won_free_bets': 'Gefeliciteerd! Je hebt bonusweddenschappen gewonnen! U kunt inzetten',
'convert_first_13_to_decimal': ' Converteer de eerste 13 bytes naar decimaal',
'd': 'D',
'date': 'DATUM',
'date_and_time': 'Datum en tijd',
'deny': 'Ontkennen',
'detailed_game_rules': 'Gedetailleerde spelregels',
'dissconnection_policy': 'ONTKOPPELINGSBELEID',
'easy_as_one_two_three': 'Makkelijk als één, twee, drie',
'enter_username': 'Vul de gewenste gebruikersnaam in',
'error_handling': 'FOUTAFHANDELING',
'exit_full_screen': 'Sluit de modus Volledig scherm af',
'first': 'Eerst',
'first_time_playing': 'Speel je voor het eerst \'Rocketman\'?',
'folow_the_three_easy_steps': 'Volg de drie eenvoudige stappen en geniet van Rocketman!',
'free_bets': 'GRATIS WEDDENSCHAPPEN',
'free_bets_desc': 'Je hebt gratis weddenschappen gewonnen! U kunt op elk moment profiteren van gratis weddenschappen door op de startknop te drukken.',
'free_bets_header': 'Gratis weddenschappen',
'from': 'VAN',
'fullscreen_mode': 'Voor een betere gebruikerservaring start het spel op volledig scherm.',
'function': 'Functie',
'game_functions': 'Spelfuncties',
'game_history': 'SPELGESCHIEDENIS',
'game_r_1_paragraph': 'Een speler kan de autoplay-optie activeren, waarbij voor elke nieuwe ronde automatisch een weddenschap wordt geplaatst met behulp van het inzetbedrag van de vorige weddenschap.',
'game_r_10_paragraph': 'Zodra de promocredits zijn toegekend, wordt het geld voor elke weddenschap die een speler plaatst eerst van de promocredits afgetrokken totdat het opgebruikt is, voordat het geld uit de portemonnee van de speler wordt opgenomen.',
'game_r_11_paragraph': 'De promocredits kunnen niet worden uitbetaald, maar eventuele winsten op dergelijke weddenschappen worden volledig aan de speler toegekend.',
'game_r_12_paragraph': 'Promotegoeden hebben een geldigheidsduur van 30 dagen, waarna ze vervallen en niet kunnen worden gebruikt.',
'game_r_13_paragraph': 'Een speler kan willekeurig worden beloond met gratis weddenschappen. Er verschijnt een pop-upafbeelding op het scherm die de speler informeert over de toegekende gratis weddenschappen, het aantal weddenschappen en het bedrag per weddenschap.',
'game_r_14_paragraph': 'Zodra de gratis weddenschappen zijn toegekend, kan de speler kiezen wanneer hij de gratis weddenschapsronde wil starten. Zodra de gratis weddenschapsronde is gestart, wordt een automatische weddenschap met de opgegeven inzet geplaatst en is het aan de speler om op elk gewenst moment uit te betalen. Als de speler de verbinding verbreekt tijdens gratis weddenschappen, zullen gratis weddenschappen nog steeds plaatsvinden en zullen ze het “Verbindingsbeleid” volgen.',
'game_r_15_paragraph': 'Gratis weddenschappen hebben een geldigheidsduur van 30 dagen, waarna ze vervallen en niet kunnen worden gebruikt.',
'game_r_16a_paragraph': 'De algemene theoretische teruggave aan de speler is ',
'game_r_17_paragraph': 'RTP-percentages worden continu gemonitord en geverifieerd door onafhankelijke derde partijen.',
'game_r_18_paragraph': 'Als de verbinding wordt verbroken nadat een weddenschap is geplaatst, wordt de weddenschap niet geannuleerd. Als de vermenigvuldiger 20.000x bereikt, wordt uw weddenschap automatisch uitbetaald en worden de credits op uw account toegepast.',
'game_r_19_paragraph': 'Je kunt in het spel met andere spelers chatten. Voer uw bericht in het chatveld in of deel uw winnende weddenschap met andere spelers.',
'game_r_2_paragraph': 'Een autocasout-vermenigvuldiger kan worden ingevoerd in het veld “Autocashout” en wanneer deze wordt ingevoerd, wordt het spel automatisch uitbetaald als de ingestelde vermenigvuldiger wordt bereikt.',
'game_r_20_paragraph': 'Van spelers die de chat misbruiken door andere spelers te misbruiken of door ongepast en/of grof taalgebruik te gebruiken, worden de chatrechten uitgeschakeld.',
'game_r_21_paragraph': 'Rocketman is ontworpen met prachtige achtergrondmuziek en geluidseffecten in gedachten. Als u muziek en/of geluidseffecten wilt uitschakelen, kunt u dit doen via het vervolgkeuzemenu.',
'game_r_22_paragraph': 'Door op de knop “Mijn weddenschappen” te klikken, kan een speler zijn laatste weddenschappen bekijken. Voor verdere speelgeschiedenis (bijvoorbeeld langere perioden) kan het zijn dat een speler toegang nodig heeft tot de spelersgeschiedenis binnen het spelplatform zelf.',
'game_r_23_paragraph': 'Bij een fout kunnen rondes niet op tijd starten. Als dat het geval is, zal ons ondersteuningsteam proberen het probleem zo snel mogelijk op te lossen en de reguliere spelrondes hervatten. In het geval van een probleem met een spelronde zelf, worden alle weddenschappen geannuleerd en wordt het inzetbedrag teruggegeven aan de spelers.',
'game_r_24_paragraph': 'Netwerklatentie is een onvermijdelijk onderdeel van internetconnectiviteit. We beschouwen alle weddenschappen en uitbetalingen alleen als geldig op het moment dat ze onze server bereiken, ongeacht de netwerklatentieduur.',
'game_r_3_paragraph': 'Een speler kan sneltoetsen gebruiken om snel een weddenschap of uitbetaling in het spel te plaatsen. De belangrijkste toewijzingen zijn als volgt',
'game_r_4_paragraph': '1% van elke inzet wordt toegewezen aan een progressieve jackpotfonds. Deze fonds dient het progressieve jackpotsysteem',
'game_r_5_paragraph': ' • Jackpot – Hoogste niveau, grootste prijs, minst frequent',
'game_r_6_paragraph': ' • Rocketpot – Gemiddeld niveau, gemiddelde prijs',
'game_r_7_paragraph': ' • Boosterpot – Laagste niveau, lage prijs, meest voorkomende',
'game_r_8_paragraph': 'Alle jackpotniveaus worden toegekend met behulp van een "Mystery"- of "must-hit-by"-mechanisme. De waarde van elke "must-hit-by" jackpot wordt onmiddellijk bepaald nadat de voorgaande jackpot is gewonnen door een generator van willekeurige getallen en opgeslagen als een gecodeerde waarde in de database op afstand. De waarde is gecodeerd en dus verborgen en niet bekend. De jackpot wordt gewonnen door een eerste speler wiens inzet ervoor zorgt dat de jackpot de mysteriewaarde overschrijdt. De winnaar van de jackpot wordt bekend gemaakt nadat de ronde is afgelopen.',
'game_r_8a_paragraph': 'Opmerking: De winnaar van de jackpot kan slechts één Rocketman-speler zijn die actief speelt in de ronde wanneer de jackpot is geactiveerd. Er is geen mogelijkheid dat als de speler de jackpot heeft gewonnen, hij deze met een andere Rocketman-speler zou moeten delen.',
'game_r_9_paragraph': 'Een speler kan willekeurig promocredits krijgen. Er verschijnt een pop-upafbeelding op het scherm die de speler informeert over de toegekende promocredits en het bedrag ervan.',
'game_round': 'spelrondes!',
'game_rules': 'Spelregels',
'game_rules_1_paragraph': 'Rocketman is een gokentertainment van de nieuwe generatie. Win binnen enkele seconden vele malen meer! Rocketmanis gebouwd op een',
'game_rules_10_paragraph': ' • Auto Play wordt geactiveerd vanaf het tabblad “Auto” in het weddenschapspaneel, door het selectievakje “Auto Bet” aan te vinken. Na activering worden weddenschappen automatisch geplaatst, maar voor uitbetaling moet u in elke ronde op de knop “uitbetalen” drukken. Als je wilt dat de weddenschap automatisch wordt uitbetaald, gebruik dan',
'game_rules_11_paragraph': '• Automatische uitbetaling is beschikbaar via het tabblad “Auto” in het weddenschapspaneel. Na activering wordt uw weddenschap automatisch uitbetaald wanneer de ingevoerde coëfficiënt wordt bereikt.',
'game_rules_2_paragraph': ', wat momenteel de enige echte garantie is voor eerlijkheid in de gokindustrie.',
'game_rules_3_paragraph': '• De winstvermenigvuldiger begint bij 1x en wordt steeds groter naarmate het Lucky Plane opstijgt.',
'game_rules_4_paragraph': ' • Uw winst is de vermenigvuldiger waarmee u een uitbetaling heeft gedaan, vermenigvuldigd met uw inzet.',
'game_rules_5_paragraph': '• Vóór het begin van elke ronde genereert onze aantoonbaar eerlijke generator van willekeurige getallen de coëfficiënt waarbij het Lucky Plane wegvliegt. U kunt de eerlijkheid van deze generatie controleren door op het tabblad Geschiedenis naast het resultaat te klikken.',
'game_rules_6_paragraph': '• Selecteer het bedrag en druk op de knop “Inzet” om een ​​weddenschap te plaatsen.',
'game_rules_7_paragraph': '• U kunt twee weddenschappen tegelijk plaatsen door een tweede inzetpaneel toe te voegen. Om een ​​tweede inzetpaneel toe te voegen, drukt u op het pluspictogram in de rechterbovenhoek van het eerste inzetpaneel.',
'game_rules_8_paragraph': ' • Druk op de knop “Uitbetalen” om uw winst uit te betalen. Uw winst wordt vermenigvuldigd met de uitbetalingscoëfficiënt.',
'game_rules_9_paragraph': '• Je weddenschap is verloren als je niet hebt uitbetaald voordat het vliegtuig wegvliegt.',
'general_rules': 'ALGEMENE REGELS',
'gifs': 'GIFS',
'go_back': 'Ga terug',
'good_job_buddy': 'Goed bezig vriend, ga zo door',
'h': 'H',
'hashed_in_sha_512': 'Gehasht in SHA512',
'hex': 'Hex',
'hey_username': 'Hoi',
'hide_hex_to_decimal': 'Conversie van hexadecimaal naar decimaal verbergen',
'highscore': 'Hoogste score',
'how_it_works': 'Hoe het werkt ?',
'how_to_check': 'Hoe te controleren ?',
'how_to_play': 'Hoe te spelen',
'if_the_maximum_multiplier': '• Als de maximale vermenigvuldiger van 20.000 wordt bereikt, worden alle openstaande weddenschappen automatisch uitbetaald tegen de maximale vermenigvuldiger',
'increase': 'Toename',
'input_bet_amount': 'Voer het inzetbedrag in en plaats uw weddenschap voordat u vertrekt.',
'insufficient_funds': 'Onvoldoende middelen',
'it_blew_up': 'Het ontplofte! Ach ja...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpotwinnaars',
'key': 'Sleutel',
'load_more': 'MEER LADEN',
'loading': 'Bezig met laden',
'loading_next_round': 'Volgende ronde laden....',
'loss_gifs': 'VERLIES',
'lost_connection': 'Verbinding verloren',
'make_a_bet': 'Wedden',
'malfunction_voids': 'Door een storing zijn alle Pays and Plays ongeldig.',
'maximum_payout': 'Maximale uitbetaling bedraagt ',
'menu': 'Menu',
'min_bet_is': 'Min inzet is',
'month': 'Maand',
'more_details': 'Meer details',
'more_information': 'Meer informatie',
'multiplier': 'Vermenigvuldiger',
'multiplier_starts': '• De vermenigvuldiger begint bij 1 en loopt op tot 20.000',
'multipliers_larger': 'Vermenigvuldigers groter dan x20.000 zijn beperkt tot de maximale vermenigvuldigingswaarde',
'music_settings': 'Muziekinstellingen',
'my_bet_history': 'Mijn wedgeschiedenis',
'my_bets': 'Mijn weddenschappen',
'my_bonysing_history': 'Mijn bonusgeschiedenis',
'network_latency': 'NETWERK VERTRAGING',
'network_latency_content': 'Netwerklatentie is een onvermijdelijk onderdeel van internetconnectiviteit. We beschouwen alle weddenschappen en uitbetalingen alleen als geldig op het moment dat ze onze server bereiken, ongeacht de netwerklatentieduur.',
'network_latency_title': 'NETWERK VERTRAGING',
'no_thanks': 'Nee bedankt!',
'not_right_now': 'Niet nu',
'off': 'uit',
'oh_snap': 'Oh nee!',
'ok_close': 'Oké, dichtbij!',
'ok_thanks': 'Oke dank je wel!',
'on': 'op',
'on_loss': 'OP VERLIES',
'on_win': 'OP WINNEN',
'online': 'Online',
'per_bet': 'per weddenschap',
'pick_your_avatar_img': 'Kies je avatarafbeelding',
'place_bet_with_last': 'Plaats een weddenschap met de laatst geselecteerde inzet',
'play_now': 'Nu afspelen',
'player_1_seed': 'Speler 1 zaad',
'player_2_seed': 'Speler 2 zaad',
'player_3_seed': 'Speler 3 zaad',
'please_wait_active_bets': 'Wacht tot de huidige weddenschappen zijn voltooid voordat u de gratis weddenschappen kunt activeren',
'points': 'PUNTEN',
'powered_by': 'Aangedreven door',
'previous_hand': 'Vorige hand',
'prize': 'PRIJS',
'prizes': 'prijzen',
'progressive_jackpot': 'PROGRESSIEVE JACKPOT',
'promo_credit': 'Promotegoed',
'promo_credit_condradulations': 'Gefeliciteerd! Je hebt een astronomisch goed Promo-tegoed gewonnen dat je in het spel kunt gebruiken!',
'promo_credits': 'PROMO KREDIETEN',
'promo_history': 'Promotiegeschiedenis',
'provably_fair': 'Aantoonbaar eerlijk',
'provably_fair_1_paragraph': '\'Rocketman\' is gebaseerd op cryptografische technologie genaamd \'Provably Fair\'. Deze technologie garandeert 100% eerlijkheid van het spelresultaat. Met deze technologie is het onmogelijk dat een derde partij zich in het spelproces mengt.',
'provably_fair_2_paragraph': 'Het resultaat van elke ronde (de \'Fly away\'-coëfficiënt van het spel) wordt niet gegenereerd op onze servers. Het wordt gegenereerd met behulp van rondespelers en is volledig transparant. Op deze manier is het voor niemand mogelijk om de game-uitvoer te manipuleren. Ook kan iedereen de eerlijkheid van het spel controleren en bevestigen.',
'provably_fair_3_paragraph': 'Het ronderesultaat wordt gegenereerd op basis van vier onafhankelijke deelnemers aan de ronde',
'provably_fair_4_paragraph': 'Wanneer de ronde begint, voegt het spel server-seed samen met drie client-seeds. Uit samengevoegde symbolen wordt SHA512-hash gegenereerd, en uit deze hash - spelresultaat.',
'provably_fair_5_paragraph': 'Je kunt de eerlijkheid van elke ronde controleren in de spelgeschiedenis door op het groene pictogram te klikken. In het geopende venster zie je serverzaad, 3 paar spelerszaden, gecombineerde hash en ronderesultaat.',
'provably_fair_6_paragraph': 'De gehashte versie van de server-seed voor volgende rondes is publiekelijk beschikbaar in het instellingenvenster (vink in het gebruikersmenu "Aantoonbaar eerlijke instellingen" aan en vervolgens "Volgende server-seed SHA256"). U kunt hier ook uw klantzaad wijzigen. Als u wilt deelnemen aan het genereren van ronderesultaten, zorg er dan voor dat u tussen de eerste 3 spelers zit die in die ronde inzetten.',
'provably_fair_7_paragraph': 'De juistheid van hash kan bijvoorbeeld in elke online rekenmachine worden gecontroleerd',
'provably_fair_system': 'aantoonbaar eerlijk systeem',
'quick_explanation': 'Snelle uitleg',
'randomly_generated_server_hash': 'Willekeurig gegenereerde server-HASH',
'rank': 'RANG',
'raw_multiplier': 'ruwe vermenigvuldiger',
'raw_multiplier_to_edged': 'Ruwe vermenigvuldiger naar scherpe vermenigvuldiger, met behulp van',
'remove_additional_bet': 'Verwijder extra inzet',
'reset': 'Opnieuw instellen',
'return_to_player': 'TERUG NAAR SPELER',
'rocketman_is_pure_excitement': 'Rocketman is pure opwinding! Risico en win.',
'rocketpot': 'Raketpot',
'rocketpot_winners': 'Winnaars van Rocketpot',
'round': 'Ronde',
'round_id': 'Ronde ID',
'round_multiplier': 'Ronde vermenigvuldiger',
'round_results': 'ronde resultaten',
'rounds': 'rondes',
'rtp': 'RTP',
'rules': 'reglement',
'rules_details': 'Toernooiregels',
'save': 'Redden',
'schedule': 'schema',
'second': 'seconde',
'seconds_left': 's links',
'server_hash': 'Server-HASH',
'please_wait_current_bets': 'GELIEVE TE WACHTEN TOT DE HUIDIGE WEDDENSCHAPPEN VOLTOOID ZIJN',
'min_autocashout_value_must_be': 'MIN. AUTOCASHOUT-WAARDE MOET ZIJN',
'min_bet_value_must_be': 'MIN. INZETWAARDE MOET ZIJN',
'server_seed': 'Serverzaad',
'session_dissconnected': 'Sessie verbroken',
'multisession_not_allowed': 'Multisessie is niet toegestaan',
'set_auto_bet': 'AUTOMATISCHE INZET INSTELLEN',
'set_auto_cashout': 'AUTOMATISCHE UITBETALING INSTELLEN',
'shourtcut_keys': 'SNELTOETSEN',
'show_hex_to_deimal': 'Conversie van hexadecimaal naar decimaal weergeven',
'sound': 'GELUID',
'sound_settigs': 'Geluidsinstellingen',
'start_with_autoplay': 'Begin met automatisch afspelen',
'stop_autoplay': 'STOP HET AUTOMATISCH SPELEN',
'stop_on_loss': 'STOP BIJ VERLIES',
'stop_on_loss_tooltip': 'Gebruik Stop on Loss om autobet te stoppen nadat u een bepaald bedrag heeft verloren.<br/> Als u bijvoorbeeld begint met $ 100 en u Stop on Loss instelt op $ 25',
'stop_on_profit': 'STOP BIJ WINST',
'stop_on_profit_tooltip': 'Gebruik Stop on Profit om autobet te stoppen nadat u een bepaald bedrag heeft gewonnen.<br/> Als u bijvoorbeeld begint met $ 100 en u Stop on Profit instelt op $ 25',
'there_are_no_active_torunament': 'Er zijn momenteel geen actieve toernooien.',
'third': 'derde',
'this_game_is': 'Dit spel is',
'this_tool_recalculates': 'Deze tool berekent de vermenigvuldiger opnieuw op basis van de game-hash. Je kunt elke game-hash kopiëren/plakken om de vermenigvuldiger te verifiëren.',
'time': 'TIJD',
'time_frame': 'Tijdsspanne',
'to': 'NAAR',
'toggle_animation': 'Schakel animatie in',
'top_odds': 'Topkansen',
'top_wins': 'Topoverwinningen',
'total_bets': 'Totaal aantal weddenschappen',
'tournament': 'Toernooi',
'tournament_information': 'Toernooi informatie',
'try_advanced_autoplay': 'Probeer de nieuwe geavanceerde autoplay-functie!',
'uncredited_wins': 'Niet-genoemde overwinningen',
'user': 'GEBRUIKER',
'user_and_points': 'GEBRUIKER & PUNTEN',
'username': 'Gebruikersnaam',
'username_must_be_at_least': 'Gebruikersnaam moet minimaal zijn',
'verify': 'Verifiëren',
'vip_popup_text': 'Je kunt een van de speciale VIP-avatars kiezen die exclusief beschikbaar zijn voor onze VIP-leden!',
'vip_popup_title': 'Gefeliciteerd met het worden van een VIP-speler',
'waiting_for_the_next_round': 'Wachten op de volgende ronde...',
'warnings_are_calculated': ' • Winsten worden berekend door de inzet te vermenigvuldigen met de vermenigvuldiger op het moment van het Cashout-evenement',
'watch_take_off': 'Kijk hoe je Lucky Rocket opstijgt en je winst toeneemt.',
'watch_the_rocket': 'Kijk naar de raket',
'we_have_noticed': '\'We hebben gemerkt dat dit de eerste keer is dat je \'Rocketman\' speelt. Wil je een korte video zien waarin wordt uitgelegd hoe je het speelt?',
'week': 'Week',
'welcome_to_rocketman': 'Welkom bij Rocketman - een nieuw en spannend spel! Met Rocketman heb je een kans om tot 20.000x je oorspronkelijke inzet te winnen.',
'what_is_provably_fair': 'Wat is aantoonbaar eerlijk?',
'will_be_deposited': 'Wordt op het spelerssaldo gestort bij de eerste reguliere winst',
'win': 'WINNEN',
'win_amount': 'Winstbedrag',
'win_gifs': 'WINNEN',
'winner': 'winnaar',
'wins': 'wint',
'write_a_replay': 'Schrijf een reactie ...',
'year': 'Jaar',
'yes': 'JA',
'yes_start_the_round': 'Ja, start de ronde',
'you_have_cashed_out': 'Je hebt uitbetaald, je winst is',
'you_have_won': 'JE HEBT GEWONNEN',
'your_bet_is_lost': 'uw weddenschap is verloren.',
'current': 'Huidig',
'previous': 'Vorig',
'claim': 'Claim',
'this_feature_will_give': 'Deze functie geeft een geselecteerd bedrag aan willekeurige gebruikers in de chat',
'amount_per_player': 'Amount Per Player',
'no_of_players': 'Number of players',
'meteor_shower': 'Meteor Shower',
'claim_your_free_bet': 'Claim uw gratis weddenschap',
'meteor_shower_total': 'Meteorenzwerm Totaal',
'shower': 'REGEN',
'total': 'Totaal',
'meteor_shower_incoming': 'Meteorenregen komt eraan, kijk naar de chat!',
'insurance': 'VERZEKERING',
'insurance_paragraph': 'Spelers kunnen een verzekering voor hun weddenschap afsluiten door op het selectievakje "Weddenverzekering" naast de inzetknop te klikken. De verzekering kost 10% van het totale inzetbedrag en betaalt het inzetbedrag terug aan de speler als de raket ontploft met een vermenigvuldiger van 1,00.',
'insure_your_bet': 'VERZEKER UW WEDDENSCHAP',
'bet_insurance_promo_text': 'Selecteer de optie "Inzetverzekering" om uw weddenschap te verzekeren tegen raketcrashes met een vermenigvuldiger van 1,00.',
'bet_insurance_promo_text_helper': 'Lees meer in de sectie "Spelregels"...',
'game_explanation_1': 'Kies uw vermenigvuldiger, en als de spelvermenigvuldiger de door u gekozen vermenigvuldiger overschrijdt, is uw winst',
'game_explanation_2': 'Uw vermenigvuldiger x uw inzet',
'retail_max_mp_1': 'Win tot',
'retail_max_mp_2': 'jouw weddenschap!',
'play_multibets': 'Toneelstuk',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUSSPEL',
'all_wins_doubled': 'Alle overwinningen verdubbeld!',
'multibets_paragraph_1': 'om uw kansen te vergroten!',
'multibets_paragraph_2': 'Je kunt weddenschappen op maximaal tien ronden combineren tot één enkele multibet en enorme uitbetalingen winnen!',
'multibets_paragraph_3': 'Elke keer dat een ronde wordt gewonnen, wordt het winnende dividend uit die ronde ingezet in de volgende ronde, totdat alle rondes winnend zijn.',
'bonus_launch': 'Win x2 op alle weddenschappen in de “Bonus Launch” ronde!',
'multiplier_history': 'Vermenigvuldigingsgeschiedenis',
'will_start_soon': 'zal binnenkort beginnen',
'hurry_up_and_place_your_bets': 'Schiet op en plaats uw weddenschappen!',
'active_bets': 'Actieve weddenschappen',
'shop': 'WINKEL',
'game_meteor_shower_paragraph': 'Meteor Shower kent één gratis weddenschap met een vooraf gedefinieerde waarde toe aan een vooraf bepaald aantal spelers. Het kan worden geïnitieerd door de server of door een speler. Zodra de meteorenregen is geactiveerd, verschijnt er een bericht in de game, waarin gebruikers worden gevraagd naar de chat te kijken waar het bericht van de meteorenregen zich bevindt. Spelers kunnen de gratis weddenschap claimen door op de knop “Claim” te klikken. Gratis weddenschappen worden toegekend aan spelers die eerst op de knop ‘Claim’ klikken. Het gebruik van autoclickers of enige vorm van automatiseringssoftware om spelersklikken te emuleren en prijzen te claimen is verboden en kan leiden tot een verbod op deze functie.',
'claming_failed': 'Claimen is mislukt',
'player_on_blacklist': 'De speler staat op de zwarte lijst',
'you_can_claim_ticket': 'Je kunt alleen een ticket claimen als je een actieve speler bent met reguliere weddenschappen',
'you_already_have_free_tickets': 'U heeft al gratis tickets, besteed deze eerst',
'please_play_the_game_in_portait_mode': 'Speel het spel in portretmodus.',
};
langArr.s1 = {
'your_insurance_returns_you': 'Your insurance returns you',
'bet_insurance': 'Game Insurance',
'max_bet_is': 'Max play is',
'already_active_bet_of': 'and there is already active game of',
'1_from_game_history': '1. From game history',
'2_in_settings_window': '2. In settings window',
'3_online_calculator': '3. Online calculator',
'active_tournament_1': 'Active tournament ',
'active_tournament_2': 'has no plays yet.',
'add_another_bet': 'Add another play',
'advanced_autoplay': 'ADVANCED AUTOPLAY',
'advanced_autoplay_for_bet': 'Advanced Autoplay',
'alien_saucer_free_bets': 'Alien Saucer Free Plays',
'all_bets': 'All Plays',
'all_in_your_hands': 'All in your hands!',
'allow': 'Allow',
'at': 'at',
'austronaut_promo_credit': 'Astronaut Promo Credit',
'auto_play_and_autocash': 'AUTOPLAY AND AUTOCOLLECT ',
'autoplay': 'Autoplay',
'autoplay_and_cashout': '02. Auto Play & auto collect',
'back_to_home': 'Back to home',
'balance': 'Balance',
'bet': 'PLAY',
'bet_amount': 'PLAY AMOUNT',
'bet_and_cashout': '01. Play & Win',
'bet_bets_tab': 'COINS',
'bet_failed': 'Play Failed',
'bet_statistic': 'Play Statistic',
'bet_time': 'Play Time',
'bet_wins': 'Top Wins',
'bets': 'Plays',
'biggest_odds': 'Biggest Odds',
'bonus_type': 'BONUS TYPE',
'bonusing_history': 'Bonusing History',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Boosterpot winners',
'but_remember_paragraph': 'But remember, if you did not have time to make a Collect before the Lucky Rocket explodes,',
'calculate_raw_mp_to_decimal': 'Calculate Raw Multiplier from decimal, and round it down to nearest integer',
'cancel': 'Cancel',
'cant_post_media_content': 'Can\'t post media content frequently',
'cashed_out': 'Cashed out',
'cashout': 'Collect',
'cashout_at': 'COLLECT AT',
'cashout_before': 'Collect before the rocket explodes and the coins are yours!',
'change_avatar': 'Change Avatar',
'change_username': 'Change Username',
'characters_long': 'characters long',
'chat': 'Chat',
'chat_room': 'Chat Room',
'checking_for_spam': 'Checking for spam...',
'close': 'Close',
'coeff': 'COEFF.',
'collect_win': 'Collect Win',
'combined_seed': 'Combined seed',
'company': 'Company',
'congratulations_you_have_won_free_bets': 'Congratulations! You have won Bonus Plays! You can play',
'convert_first_13_to_decimal': ' Convert first 13 bytes to decimal',
'd': 'd',
'date': 'DATE',
'date_and_time': 'Date and Time',
'deny': 'Deny',
'detailed_game_rules': 'Detailed Game Rules',
'dissconnection_policy': 'DISCONNECTION POLICY',
'easy_as_one_two_three': 'Easy as one, two, three',
'enter_username': 'Please enter desired username',
'error_handling': 'ERROR HANDLING',
'exit_full_screen': 'Exit full-screen mode',
'first': 'first',
'first_time_playing': 'First time playing "Rocketman"?',
'folow_the_three_easy_steps': 'Follow the three easy steps, and enjoy Rocketman! ',
'free_bets': 'FREE PLAYS',
'free_bets_desc': 'You won free plays! You can take advantage of free plays at any time by pressing the start button.',
'free_bets_header': 'Free Plays',
'from': 'FROM',
'fullscreen_mode': 'For the better user experience the game will start in fullscreen mode.',
'function': 'Function',
'game_functions': 'Game Functions',
'game_history': 'GAME HISTORY',
'game_r_1_paragraph': 'A player may activate the autoplay option where a play is automatically placed for each new round using the play amount from the previous play.',
'game_r_10_paragraph': 'Once the promo credits are awarded, for any play that a player places, the money will first be deducted from promo credits until it\'s used up, before continuing withdrawing money from the players wallet.',
'game_r_11_paragraph': 'The promo credits can not be collected, but any winning on such plays are credited to the player in full.',
'game_r_12_paragraph': 'Promo credits have a 30 day validity period after which they expire and can not be used.',
'game_r_13_paragraph': 'A player may be randomly awarded with free plays. A popup graphic will appear on screen notifying the player about awarded free plays, number of plays and amount per play.',
'game_r_14_paragraph': 'Once the free plays are awarded player may choose when to start the free play round. Once the free plays round is started, automatic play with the specified stake is placed, and it is up to the player to collect at any given moment. If the player disconnects during free plays, free plays will still take place and they will follow the “Disconnection Policy”.',
'game_r_15_paragraph': 'Free plays have a 30 day validity period after which they expire and can not be used.',
'game_r_16a_paragraph': 'The overall theoretical return to player is ',
'game_r_17_paragraph': 'RTP percentages are continuously monitored and verified by independent third parties.',
'game_r_18_paragraph': 'If connection is lost after a play is placed, the play will not be cancelled. If the multiplier reaches 20.000x your play will be collected and coins will be applied to your account.',
'game_r_19_paragraph': 'You can chat in game with other players. Enter your message in the chat field, or share your winning play with other players.',
'game_r_2_paragraph': 'An autocollect multiplier can be entered in the “Autocollect” field, and when entered, the game will automatically collect if the set multiplier is reached.',
'game_r_20_paragraph': 'Players who misuse the Chat by abusing other players, or by using inappropriate and/or foul language will have their Chat privileges disabled.',
'game_r_21_paragraph': 'Rocketman is designed with beautiful background music and sound effects in mind. Should you wish to disable music and/or sound effects you may do so through the drop down menu.',
'game_r_22_paragraph': 'By clicking on the “My Plays" button a player may review his latest plays. For further play history (eg. longer periods), a player may need to access the player history within the gaming platform itself.',
'game_r_23_paragraph': 'In event of an error rounds may not start on time. If that is the case our support team will try to resolve the issue as soon as possible, and resume with regular game rounds. In case of an issue with a game round itself, all plays will be cancelled and stake amount returned to players.',
'game_r_24_paragraph': 'Network latency is unavoidable part of internet connectivity. We consider all plays and collects valid only at the time they reach our server, regardless of the network latency duration. ',
'game_r_3_paragraph': 'A player can use shortcut keys to quickly place a play or collect in the game. The key mappings are as follows',
'game_r_4_paragraph': '1% from each stake is dedicated to a progressive jackpot fund. This fund serves progressive jackpot system',
'game_r_5_paragraph': ' • Jackpot – Highest level, biggest prize, least frequent',
'game_r_6_paragraph': ' • Rocketpot – Medium level, medium prize',
'game_r_7_paragraph': ' • Boosterpot – Lowest level, low prize, most frequent ',
'game_r_8_paragraph': 'All jackpot levels are awarded using a "Mystery" or "must-hit-by" mechanics. The value of every "must-hit-by" jackpot is determined immediately after the preceding jackpot is won by a random number generator and stored as an encrypted value in the remote database. The value is encrypted, and thus hidden and is not known. Jackpot is won by a first player whose stake causes the jackpot to go over the mystery value. Jackpot winner is announced after the round is finished.',
'game_r_8a_paragraph': 'Note: Jackpot winner can be only one single Rocketman player actively playing in the round when the jackpot has been triggered. There is no possibility that if the player have won the jackpot, would have to share it with any other Rocketman player.',
'game_r_9_paragraph': 'A player may be randomly awarded with promo credits. A popup graphic will appear on screen notifying the player about awarded promo credits and its amount.',
'game_round': 'game rounds!',
'game_rules': 'Game Rules',
'game_rules_1_paragraph': 'Rocketman is a playing entertainment of new generation. Win many times more in seconds! Rocketmanis built on a',
'game_rules_10_paragraph': ' • Auto Play is activated from “Auto” tab in Play Panel, by checking “Auto Play checkbox. After activated, plays will place automatically, but for cash out, you should press “Collect” button in each round. If you want play to cash out coins automatically, then use',
'game_rules_11_paragraph': '• Auto C is available from “Auto” tab on play panel. After activating, your play will be automatically cashed out, when reaches entered coefficient.',
'game_rules_2_paragraph': ', which is currently the only real guarantee of honesty in the playing industry.',
'game_rules_3_paragraph': '• The win multiplier starts at 1x and grows more and more as the Lucky Plane takes off.',
'game_rules_4_paragraph': ' • Your winnings are the multiplier at which you made a Collect multiplied by your play coins. ',
'game_rules_5_paragraph': '• Before the start of each round, our provably fair random number generator generates the coefficient at which the Lucky Plane will fly away. You can check the honesty of this generation by clicking on opposite the result in the History tab.',
'game_rules_6_paragraph': '• Select amount and press Play button to make a play. ',
'game_rules_7_paragraph': '• You can make two plays simultaneously, by adding second play panel. To add second play panel press plus icon, located on top right corner of first play panel.',
'game_rules_8_paragraph': ' • Press "Collect" button to cash out your winnings. Your win is play coins multiplied by collect coefficient.',
'game_rules_9_paragraph': '• Your play is lost, if you didn’t cash out before plane flies away.',
'general_rules': 'GENERAL RULES',
'gifs': 'GIFS',
'go_back': 'Go Back',
'good_job_buddy': 'Good job buddy, keep it going',
'h': 'h',
'hashed_in_sha_512': 'Hashed in SHA512',
'hex': 'Hex',
'hey_username': 'Hey',
'hide_hex_to_decimal': 'Hide hex to decimal conversion',
'highscore': 'High Score',
'how_it_works': 'How it works ?',
'how_to_check': 'How to check ?',
'how_to_play': 'How to play',
'if_the_maximum_multiplier': '• If the maximum multiplier of 20.000 is reached, all open plays are automatically collected at the maximum multiplier',
'increase': 'Increase',
'input_bet_amount': 'Input play amount and place your play before takeoff.',
'insufficient_funds': 'Insufficient funds',
'it_blew_up': 'It blew up! Oh well...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Jackpot winners',
'key': 'Key',
'load_more': 'LOAD MORE',
'loading': 'Loading',
'loading_next_round': 'Loading next round....',
'loss_gifs': 'LOSS',
'lost_connection': 'Lost Connection',
'make_a_bet': 'Make a play',
'malfunction_voids': 'Malfunction voids all Pays and Plays.',
'maximum_payout': 'Maximum payout is ',
'menu': 'Menu',
'min_bet_is': 'Min play is',
'month': 'Month',
'more_details': 'More Details',
'more_information': 'More information',
'multiplier': 'Multiplier',
'multiplier_starts': '• The multiplier starts at 1 and goes up to 20.000',
'multipliers_larger': 'Multipliers larger than x20.000 is capped at max multiplier value',
'music_settings': 'Music Settings',
'my_bet_history': 'My Play History',
'my_bets': 'My Plays',
'my_bonysing_history': 'My Bonusing History',
'network_latency': 'NETWORK LATENCY',
'network_latency_content': 'Network latency is unavoidable part of internet connectivity. We consider all plays and collects valid only at the time they reach our server, regardless of the network latency duration.',
'network_latency_title': 'NETWORK LATENCY',
'no_thanks': 'No, thanks!',
'not_right_now': 'Not right now',
'off': 'off',
'oh_snap': 'Oh Snap!',
'ok_close': 'Okay, Close!',
'ok_thanks': 'Okay, Thanks!',
'on': 'on',
'on_loss': 'ON LOSS',
'on_win': 'ON WIN',
'online': 'Online',
'per_bet': 'per play',
'pick_your_avatar_img': 'Pick your avatar image',
'place_bet_with_last': 'Play with last selected coins ',
'play_now': 'Play Now',
'player_1_seed': 'Player 1 seed',
'player_2_seed': 'Player 2 seed',
'player_3_seed': 'Player 3 seed',
'please_wait_active_bets': 'Please wait for current play to complete in order to activate free plays',
'points': 'POINTS',
'powered_by': 'Powered by',
'previous_hand': 'Previous Hand',
'prize': 'PRIZE',
'prizes': 'prizes',
'progressive_jackpot': 'PROGRESSIVE JACKPOT',
'promo_credit': 'Promo Credit',
'promo_credit_condradulations': 'Congratulations! You have won an astronomically good Promo credit that you can use in the game!',
'promo_credits': 'PROMO CREDITS',
'promo_history': 'Promo History',
'provably_fair': 'Provably Fair',
'provably_fair_1_paragraph': '\'Rocketman\' is based on cryptographic technology called \'Provably Fair\'. This technology guarantees 100% fairness of game result. With this technology, it\'s impossible for any third party to interfere in game process.',
'provably_fair_2_paragraph': 'Result of each round (Game\'s \'Fly away\' coefficient ) is not generated on our servers. It\'s generated with help of round players and is fully transparent. This way, it\'s impossible for anyone to manipulate game output. Also, anyone can check and conf',
'provably_fair_3_paragraph': 'Round result is generated from four independent participants of the round',
'provably_fair_4_paragraph': 'When round starts, game merges server seed with three client seeds. From merged symbols is generated SHA512 hash, and from this hash - game result.',
'provably_fair_5_paragraph': 'You can check fairness of each round from game history, by clicking on green icon. In opened window, you will see server seed, 3 pair of players seeds, combined hash and round result.',
'provably_fair_6_paragraph': 'Hashed version of next rounds server seed is available publicly in settings window (In user menu, check "Provably Fair Settings" and then "Next server seed SHA256"). You can also change your client seed here. If you want to participate in round result gen',
'provably_fair_7_paragraph': 'Correctness of hash can be checked in any online calculator, for example',
'provably_fair_system': 'provably fair system',
'quick_explanation': 'Quick explenation',
'randomly_generated_server_hash': 'Randomly generated server HASH',
'rank': 'RANK',
'raw_multiplier': 'raw multiplier',
'raw_multiplier_to_edged': 'Raw Multiplier to Edged Multiplier, using',
'remove_additional_bet': 'Remove additional play',
'reset': 'Reset',
'return_to_player': 'RETURN TO PLAYER',
'rocketman_is_pure_excitement': 'Rocketman is pure excitement! Risk and win.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Rocketpot winners',
'round': 'Round',
'round_id': 'Round Id',
'round_multiplier': 'Round Multiplier',
'round_results': 'round results',
'rounds': 'rounds',
'rtp': 'RTP',
'rules': 'rules',
'rules_details': 'Tournament rules',
'save': 'Save',
'schedule': 'schedule',
'second': 'second',
'seconds_left': 's left',
'server_hash': 'Server HASH',
'please_wait_current_bets': 'PLEASE WAIT FOR CURRENT PLAYS TO COMPLETE',
'min_autocashout_value_must_be': 'MIN AUTOCASHOUT VALUE MUST BE',
'min_bet_value_must_be': 'MIN PLAY VALUE MUST BE',
'server_seed': 'Server Seed',
'session_dissconnected': 'Session Disconnected',
'multisession_not_allowed': 'Multisession is not allowed',
'set_auto_bet': 'SET AUTO PLAY',
'set_auto_cashout': 'SET AUTO COLLECT',
'shourtcut_keys': 'SHORTCUT KEYS',
'show_hex_to_deimal': 'Show hex to decimal conversion',
'sound': 'SOUND',
'sound_settigs': 'Sound Settings',
'start_with_autoplay': 'Start with autoplay',
'stop_autoplay': 'STOP AUTOPLAY',
'stop_on_loss': 'STOP ON LOSS',
'stop_on_loss_tooltip': 'Use Stop on Loss to stop autoplay after losing a specified amount.<br/> For example, if you start with $100 and you set Stop on Loss to $25',
'stop_on_profit': 'STOP ON PROFIT',
'stop_on_profit_tooltip': 'Use Stop on Profit to stop autoplay after profiting a specified amount.<br/> For example, if you start with $100 and you set Stop on Profit to $25',
'there_are_no_active_torunament': 'There are no active tournaments at this moment.',
'third': 'third',
'this_game_is': 'This game is',
'this_tool_recalculates': 'This tool re-calculates the multiplier from the game hash. You may copy/paste any game hash to verify the multiplier.',
'time': 'TIME',
'time_frame': 'Time Frame',
'to': 'TO',
'toggle_animation': 'Toggle Animation',
'top_odds': 'Top Odds',
'top_wins': 'Top Wins',
'total_bets': 'Total Plays',
'tournament': 'Tournament',
'tournament_information': 'Tournament information',
'try_advanced_autoplay': 'Try new advanced autoplay feature! ',
'uncredited_wins': 'Uncredited wins',
'user': 'USER',
'user_and_points': 'USER & POINTS',
'username': 'Username',
'username_must_be_at_least': 'Username must be at least',
'verify': 'Verify',
'vip_popup_text': 'You may choose one of the special VIP avatars available exclusively to our VIP members!',
'vip_popup_title': 'Congratulations on becoming a VIP player',
'waiting_for_the_next_round': 'Waiting for the next round...',
'warnings_are_calculated': ' • Winnings are calculated by multiplying the play coins by the multiplier at the moment of the Collect event',
'watch_take_off': 'Watch as your Lucky Rocket takes off and your winnings increase.',
'watch_the_rocket': 'Watch the Rocket',
'we_have_noticed': '`We have noticed it\'s your first time playing "Rocketman". Would you like to see a quick video explaining how to play it?',
'week': 'Week',
'welcome_to_rocketman': 'Welcome to Rocketman - a novel and exciting game! With Rocketman you have a chance of winning up to 20.000x your original coin play.',
'what_is_provably_fair': 'What is Provably fair?',
'will_be_deposited': 'Will be deposited to players balance with first regular win',
'win': 'WIN',
'win_amount': 'Win Amount',
'win_gifs': 'WIN',
'winner': 'winner',
'wins': 'wins',
'write_a_replay': 'Write a reply ...',
'year': 'Year',
'yes': 'YES',
'yes_start_the_round': 'Yes, start the round',
'you_have_cashed_out': 'You have cashed out, your win is ',
'you_have_won': 'YOU HAVE WON',
'your_bet_is_lost': 'your play is lost.',
'current': 'Current',
'previous': 'Previous',
'claim': 'Claim',
'this_feature_will_give': 'This feature will give selected amount to random users in chat',
'amount_per_player': 'Amount Per Player',
'no_of_players': 'Number of players',
'meteor_shower': 'Meteor Shower',
'claim_your_free_bet': 'Claim Your Free Play',
'meteor_shower_total': 'Meteor Shower Total',
'shower': 'SHOWER',
'total': 'Total',
'meteor_shower_incoming': 'Meteor Shower incoming, look at chat!',
'insurance': 'INSURANCE',
'insurance_paragraph': 'Players may purchase insurance for their bet by clicking on the "Bet Insurance" checkbox beside the bet button. Insurance costs 10% of the total bet amount and will pay back the bet amount to the player should the rocket explode at a 1.00 multiplier.',
'insure_your_bet': 'INSURE YOUR BET',
'bet_insurance_promo_text': 'Select "Bet Insurance" option to insure your bet from rocket crashing at 1.00 multiplier.',
'bet_insurance_promo_text_helper': 'Read more in "Game Rules" section...',
'game_explanation_1': 'Choose your multiplier, and if the game multiplier exceeds your chosen multiplier, your win will be',
'game_explanation_2': 'Your Multiplier x Your Bet',
'retail_max_mp_1': 'Win up to',
'retail_max_mp_2': 'your bet!',
'play_multibets': 'Play',
'multibets': 'MULTIBETS',
'bonus_game': 'BONUS GAME',
'all_wins_doubled': 'All wins doubled!',
'multibets_paragraph_1': 'to increase your odds!',
'multibets_paragraph_2': 'You may combine bets on up to ten rounds into a single multibet and win massive payouts!',
'multibets_paragraph_3': 'Each time a round is won, winning dividend from that round is wagered on the next round, until all rounds are winning.',
'bonus_launch': 'Win x2 on all bets in the „Bonus Launch“ round!',
'multiplier_history': 'Multiplier History',
'will_start_soon': 'will start soon',
'hurry_up_and_place_your_bets': 'Hurry up and place your bets!',
'active_bets': 'Active Bets',
'shop': 'SHOP',
'game_meteor_shower_paragraph': 'Meteor Shower awards one free play of a predefined value to a predefined number of players. It can be initiated either by the server or by a player. Once Meteor Shower is activated, a message drops in-game, directing users to look at the chat where the Meteor Shower message is located. Players can claim the free play by clicking on the “Claim” button. Free plays are awarded to players who click on the “Claim” button first. Using autoclickers or any kind of automation software to emulate player clicks and claim prizes is prohibited and may result in a ban from this feature.',
'claming_failed': 'Claiming failed',
'player_on_blacklist': 'The player is on the blacklist',
'you_can_claim_ticket': 'You can claim ticket only if you are active player with regular bets',
'you_already_have_free_tickets': 'You already have free tickets, please spend it first',
'please_play_the_game_in_portait_mode': 'Please play the game in portrait mode.',
};
langArr.sk = {
'your_insurance_returns_you': 'Poistenie sa vám vráti',
'bet_insurance': 'Poistenie stávky',
'max_bet_is': 'Max. stávka je',
'already_active_bet_of': 'a už je tu aktívna stávka',
'1_from_game_history': '1. Z histórie hry',
'2_in_settings_window': '2. V okne nastavení',
'3_online_calculator': '3. Online kalkulačka',
'active_tournament_1': 'Aktívny turnaj ',
'active_tournament_2': 'nemá zatiaľ žiadne stávky.',
'add_another_bet': 'Pridať ďalšiu stávku',
'advanced_autoplay': 'POKROČILÁ AUTOMATICKÁ HRA',
'advanced_autoplay_for_bet': 'Pokročilá automatická hra pre stávku',
'alien_saucer_free_bets': 'Stávky zdarma lietajúceho taniera',
'all_bets': 'Všetky stávky',
'all_in_your_hands': 'Všetko je vo vašich rukách!',
'allow': 'Povoliť',
'at': 'v',
'austronaut_promo_credit': 'Promo kredit astronauta',
'auto_play_and_autocash': 'AUTOMATICKÁ HRA A AUTOMATICKÝ VÝBER ',
'autoplay': 'Automatická hra',
'autoplay_and_cashout': '02. Automatická hra & automatický výber',
'back_to_home': 'Späť domov',
'balance': 'Zostatok',
'bet': 'STÁVKA',
'bet_amount': 'VÝŠKA STÁVKY',
'bet_and_cashout': '01. Stávka & výber',
'bet_bets_tab': 'VKLAD',
'bet_failed': 'Stávka sa nepodarila',
'bet_statistic': 'Štatistika stávky',
'bet_time': 'Čas stávky',
'bet_wins': 'Stávka vyhráva',
'bets': 'Stávky',
'biggest_odds': 'Najvyšší kurz',
'bonus_type': 'TYP BONUSU',
'bonusing_history': 'História bonusov',
'boosterpot': 'Boosterpot',
'boosterpot_winners': 'Výhercovia Boosterpot-u',
'but_remember_paragraph': 'Ale pamätajte, že ak ste nestihli urobiť výber pred výbuchom Šťastnej rakety,',
'calculate_raw_mp_to_decimal': 'Vypočítajte hrubý násobič z desatinného čísla a zaokrúhlite ho nadol na najbližšie celé číslo',
'cancel': 'Zrušiť',
'cant_post_media_content': 'Ne/môžete často zverejňovať mediálny obsah',
'cashed_out': 'Vybraté',
'cashout': 'Výber',
'cashout_at': 'VÝBER O',
'cashout_before': 'Vyberte peniaze skôr, ako raketa vybuchne, a peniaze sú vaše!',
'change_avatar': 'Zmeniť avatara',
'change_username': 'Zmeniť používateľské meno',
'characters_long': 'znakov dlhé',
'chat': 'Chat',
'chat_room': 'Chatovacia miestnosť',
'checking_for_spam': 'Kontrolovanie nevyžiadanej pošty...',
'close': 'Zatvoriť',
'coeff': 'KOEF.',
'collect_win': 'Zobrať výhru',
'combined_seed': 'Kombinované jadro',
'company': 'Spoločnosť',
'congratulations_you_have_won_free_bets': 'Gratulujeme! Vyhrali ste bonusové stávky! Môžete staviť',
'convert_first_13_to_decimal': ' Preveďte prvých 13 bajtov na desiatkové číslo',
'd': 'd',
'date': 'DÁTUM',
'date_and_time': 'Dátum a čas',
'deny': 'Odmietnuť',
'detailed_game_rules': 'Podrobné pravidlá hry',
'dissconnection_policy': 'ZÁSADY ODPOJENIA',
'easy_as_one_two_three': 'Jednoduché ako jeden, dva, tri',
'enter_username': 'Zadajte požadované používateľské meno',
'error_handling': 'SPRÁVA CHÝB',
'exit_full_screen': 'Ukončiť režim celej obrazovky',
'first': 'najskôr',
'first_time_playing': 'Hráte hru „Rocketman“ prvýkrát?',
'folow_the_three_easy_steps': 'Postupujte podľa troch jednoduchých krokov a vychutnajte si Rocketman-a! ',
'free_bets': 'STÁVKY ZDARMA',
'free_bets_desc': 'Vyhrali ste stávky zdarma! Stlačením tlačidla štart môžete kedykoľvek využiť stávky zdarma.',
'free_bets_header': 'Stávky zdarma',
'from': 'OD',
'fullscreen_mode': 'Pre lepší používateľský zážitok sa hra spustí v režime celej obrazovky.',
'function': 'Funkcia',
'game_functions': 'Herné funkcie',
'game_history': 'HISTÓRIA HRY',
'game_r_1_paragraph': 'Hráč si môže aktivovať možnosť automatickej hry, v ktorej je v každom novom kole automaticky podaná stávka s použitím sumy predchádzajúcej stávky.',
'game_r_10_paragraph': 'Po udelení promo kreditov sa za každú stávku, ktorú hráč podá, najkôr odpočítajú peniaze z promo kreditov, kým sa nevyčerpajú, a až potom sa pokračuje vo výbere peňazí z hráčovej peňaženky.',
'game_r_11_paragraph': 'Promo kredity nie je možné vyplatiť, ale akékoľvek výhry z takýchto stávok sa hráčovi pripíšu v plnej výške.',
'game_r_12_paragraph': 'Promo kredity majú 30-dňovú platnosť, po jej uplynutí ich platnosť vyprší a nie je možné ich použiť.',
'game_r_13_paragraph': 'Hráč môže stávky zdarma získať náhodne. Na obrazovke sa objaví okienko s informáciou o udelených stávkach zdarma, počte stávok a o sume za stávku.',
'game_r_14_paragraph': 'Po udelení stávok zdarma si hráč môže vybrať, kedy začne kolo stávok zdarma. Po spustení kola stávok zdarma je podaná automatická stávka s určeným vkladom a je na hráčovi, či si v danom okamihu vyberie peniaze. Ak sa hráč počas stávok zdarma odpojí, budú sa naďalej uzatvárať v súlade so „Zásadami odpojenia“.',
'game_r_15_paragraph': 'Stávky zdarma majú 30-dňovú platnosť, po jej uplynutí ich platnosť vyprší a nie je možné ich použiť.',
'game_r_16a_paragraph': 'Celková teoretická návratnosť hráča je ',
'game_r_17_paragraph': 'Percentá RTP sú priebežne monitorované a overované nezávislými tretími stranami.',
'game_r_18_paragraph': 'Ak dôjde k strate spojenia po podaní stávky, stávka nebude zrušená. Ak násobič dosiahne 20 000x, vaša stávka bude automaticky vyplatená a na váš účet budú pripísané kredity.',
'game_r_19_paragraph': 'V hre môžete chatovať s ostatnými hráčmi. Zadajte svoju správu do chatového poľa alebo sa podeľte o svoju výhernú stávku s ostatnými hráčmi.',
'game_r_2_paragraph': 'Do poľa „Automatický výber“ môžete zadať násobič automatického výberu a po jeho zadaní hra automaticky vyplatí peniaze, v prípade, že dostiahnete nastavený násobič.',
'game_r_20_paragraph': 'Hráčom, ktorí zneužívajú chat tým, že urážajú ostatných hráčov alebo používajú nevhodné a/alebo vulgárne výrazy, bude zablokovaná možnosť chatovať.',
'game_r_21_paragraph': 'Hra Rocketman je navrhnutá s dôrazom na príjemnú hudbu v pozadí a zvukové efekty. Ak chcete vypnúť hudbu a/alebo zvukové efekty, môžete tak urobiť prostredníctvom rozbaľovacieho menu.',
'game_r_22_paragraph': 'Kliknutím na tlačidlo „Moje stávky“ si hráč môže prezrieť svoje posledné stávky. Pre podrobnejšiu históriu hry (napr. dlhšie obdobie) môže hráč potrebovať prístup k histórii hráčov v rámci samotnej hernej platformy.',
'game_r_23_paragraph': 'V prípade chyby sa kolá nemusia začať načas. V takom prípade sa náš tím podpory pokúsi problém čo najskôr vyriešiť a pokračovať v bežných kolách hry. V prípade problému so samotným herným kolom budú všetky stávky zrušené a hráčom bude vrátená vložená suma.',
'game_r_24_paragraph': 'Oneskorenie siete je nevyhnutnou súčasťou internetového pripojenia. Všetky stávky a výbery považujeme za platné až v okamihu, keď dorazia na náš server, bez ohľadu na dĺžku oneskorenia siete. ',
'game_r_3_paragraph': 'Hráč môže v hre použiť klávesové skratky pre rýchle podanie stávky alebo výber. Klávesové mapovania sú nasledujúce',
'game_r_4_paragraph': '1 % z každej stávky ide do fondu progresívneho jackpotu. Tento fond slúži systému progresívneho jackpotu',
'game_r_5_paragraph': ' • Jackpot - Najvyššia úroveň, najväčšia cena, najmenej častý',
'game_r_6_paragraph': ' • Rocketpot - Stredná úroveň, stredná cena',
'game_r_7_paragraph': ' • Boosterpot - Najnižšia úroveň, nízka cena, najčastejší ',
'game_r_8_paragraph': 'Všetky úrovne jackpotov sú udelené pomocou mechanizmu „Záhada“ alebo „musí-padnúť-do“. Hodnota každého jackpotu „musí-padnúť-do“ je určená bezprostredne po výhre predchádzajúceho jackpotu generátorom náhodných čísel a uloží sa ako zašifrovaná hodnota do vzdialenej databázy. Hodnota je zašifrovaná, a teda skrytá a je neznáma. Jackpot vyhráva prvý hráč, ktorého vklad spôsobí, že jackpot prekročí záhadnú hodnotu. Výherca jackpotu je vyhlásený po skončení kola.',
'game_r_8a_paragraph': 'Poznámka: Výhercom jackpotu môže byť iba jeden hráč Rocketman aktívne hrajúci v kole, v ktorom bol jackpot spustený. Nie je možné, aby sa hráč, ktorý vyhral jackpot, musel oň podeliť s iným hráčom hry Rocketman.',
'game_r_9_paragraph': 'Hráč môže promo kredity získať náhodne. Na obrazovke sa objaví okienko s informáciou o udelených promo kreditoch a ich výške.',
'game_round': 'herné kolá!',
'game_rules': 'Pravidlá hry',
'game_rules_1_paragraph': 'Rocketman je hazardná zábava novej generácie. Vyhrajte mnohonásobne viac v priebehu niekoľkých sekúnd! Rocketman je založený na',
'game_rules_10_paragraph': ' • Automatická hra je aktivovaná v záložke „Auto“ na paneli stávok zaškrtnutím políčka „Automatická stávka“. Po aktivácii budú stávky podané automaticky, ale pre výber peňazí je potrebné v každom kole stlačiť tlačidlo „výber“. Ak chcete, aby sa stávka automaticky vybrala, použite',
'game_rules_11_paragraph': '• Funkcia Automatický výber hotovosti je dostupná na karte „Auto“ na paneli stávok. Po aktivácii bude vaša stávka automaticky vyplatená, keď dosiahne zadaný koeficient.',
'game_rules_2_paragraph': ', ktorá je v súčasnosti jedinou skutočnou zárukou čestnosti v odvetví hazardných hier.',
'game_rules_3_paragraph': '• Výherný násobič začína na 1× a rastie, keď šťastné lietadlo vzlietne.',
'game_rules_4_paragraph': ' • Vaše výhry predstavujú násobok, s ktorým ste uskutočnili výber hotovosti, vynásobený vašou stávkou. ',
'game_rules_5_paragraph': '• Pred začiatkom každého kola náš preukázateľne spravodlivý generátor náhodných čísel vygeneruje koeficient, pri ktorom lietadlo šťastia odletí. Poctivosť tohto generovania môžete skontrolovať kliknutím na opačnú stranu výsledku v záložke História.',
'game_rules_6_paragraph': '• Vyberte sumu a stlačte tlačidlo „Staviť“ pre podanie stávky. ',
'game_rules_7_paragraph': '• Môžete uzavrieť dve stávky súčasne, a to pridaním druhého stávkového panela. Ak chcete pridať druhý stávkový panel, stlačte ikonu plus v pravom hornom rohu prvého stávkového panela.',
'game_rules_8_paragraph': ' • Stlačením tlačidla „Výber“ vyplatíte svoje výhry. Vaša výhra je vynásobená koeficientom výberu hotovosti.',
'game_rules_9_paragraph': '• Vaša stávka prehrá, ak ste ju nevyplatili skôr, ako lietadlo odletelo.',
'general_rules': 'VŠEOBECNÉ PRAVIDLÁ',
'gifs': 'GIFY',
'go_back': 'Ísť späť',
'good_job_buddy': 'Skvelá práca, kamoš, len tak ďalej',
'h': 'h',
'hashed_in_sha_512': 'Hashované v SHA512',
'hex': 'Hex',
'hey_username': 'Hej',
'hide_hex_to_decimal': 'Skryť hex do desiatkovej sústavy',
'highscore': 'Vysoké skóre',
'how_it_works': 'Ako to funguje?',
'how_to_check': 'Ako to skontrolovať?',
'how_to_play': 'Ako hrať',
'if_the_maximum_multiplier': '• Ak dosiahnete maximálny násobič 20 000, všetky otvorené stávky sa automaticky vyplatia s maximálnym násobičom',
'increase': 'Zvýšiť',
'input_bet_amount': 'Zadajte výšku stávky a podajte stávku pred odletom.',
'insufficient_funds': 'Nedostatok finančných prostriedkov',
'it_blew_up': 'Vybuchlo to! No dobre...',
'jackpot': 'Jackpot',
'jackpot_winners': 'Výhercovia jackpotu',
'key': 'Kľúč',
'load_more': 'NAČÍTAŤ VIAC',
'loading': 'Načítavanie',
'loading_next_round': 'Načítanie ďalšieho kola...',
'loss_gifs': 'STRATA',
'lost_connection': 'Stratené spojenie',
'make_a_bet': 'Uzavrieť stávku',
'malfunction_voids': 'Porucha ruší všetky výplaty a hry.',
'maximum_payout': 'Maximálna výplata je ',
'menu': 'Menu',
'min_bet_is': 'Min. Stávka je',
'month': 'Mesiac',
'more_details': 'Viac podrobností',
'more_information': 'Viac informácií',
'multiplier': 'Násobič',
'multiplier_starts': '• Násobič začína na hodnote 1 a dosahuje až 20 000',
'multipliers_larger': 'Násobiče väčšie ako x20 000 sú obmedzené na maximálnu hodnotu násobiča',
'music_settings': 'Nastavenia hudby',
'my_bet_history': 'História mojich stávok',
'my_bets': 'Moje stávky',
'my_bonysing_history': 'História môjho bonusovania',
'network_latency': 'ONESKORENIE SIETE',
'network_latency_content': 'Oneskorenie siete je nevyhnutnou súčasťou internetového pripojenia. Všetky stávky a výbery považujeme za platné až v okamihu, keď dorazia na náš server, bez ohľadu na dĺžku oneskorenia siete.',
'network_latency_title': 'ONESKORENIE SIETE',
'no_thanks': 'Nie, ďakujem!',
'not_right_now': 'Nie práve teraz',
'off': 'vyp',
'oh_snap': 'Ó, sakra!',
'ok_close': 'Dobre, zavrieť!',
'ok_thanks': 'Dobre, ďakujem!',
'on': 'zap.',
'on_loss': 'NA STRATU',
'on_win': 'NA VÝHRU',
'online': 'Online',
'per_bet': 'za stávku',
'pick_your_avatar_img': 'Vyberte si obrázok avatara',
'place_bet_with_last': 'Podajte stávku s posledným vybraným vkladom ',
'play_now': 'Hrať teraz',
'player_1_seed': 'Nasadenie hráča 1',
'player_2_seed': 'Nasadenie hráča 2',
'player_3_seed': 'Nasadenie hráča 3',
'please_wait_active_bets': 'Pre aktiváciu stávok zdarma počkajte na dokončenie aktuálnych stávok',
'points': 'BODY',
'powered_by': 'Poháňané',
'previous_hand': 'Predošlá ruka',
'prize': 'CENA',
'prizes': 'ceny',
'progressive_jackpot': 'PROGRESÍVNY JACKPOT',
'promo_credit': 'Promo kredit',
'promo_credit_condradulations': 'Gratulujeme! Vyhrali ste astronomicky dobrý promo kredit, ktorý môžete využiť v hre!',
'promo_credits': 'PROMO KREDITY',
'promo_history': 'Promo história',
'provably_fair': 'Preukázateľne spravodlivé',
'provably_fair_1_paragraph': '\'Rocketman\' je založený na kryptografickej technológii \'Preukázateľne spravodlivé\'. Táto technológia zaručuje 100 % spravodlivosť výsledkov hry. Vďaka tejto technológii nie je možné, aby do procesu hry zasahovala akákoľvek tretia strana.',
'provably_fair_2_paragraph': 'Výsledok každého kola (koeficient hry\'Fly away\' ) nie je generovaný na našich serveroch. Jegenerovaný pomocou hráčov kôl a je úplne transparentný. Takto nie jemožné, aby niekto manipuloval s výstupom hry. Každý si môže taktiež skontrolovať a potvrdiť férovosť hry.',
'provably_fair_3_paragraph': 'Výsledok kola je generovaný zo štyroch nezávislých účastníkov kola',
'provably_fair_4_paragraph': 'Keď sa začne kolo, hra prepojí jadro servera s tromi jadrami klientov. Zo zlúčených symbolov je vygenerovaný hash SHA512 a z tohto hashu - výsledok hry.',
'provably_fair_5_paragraph': 'Spravodlivosť každého kola si môžete overiť v histórii hry kliknutím na zelenú ikonu. V otvorenom okne sa zobrazí jadro servera, 3 páry jadier hráčov, kombinovaný hash a výsledok kola.',
'provably_fair_6_paragraph': 'Hashovaná verzia jadra ďalšieho servera je verejne dostupná v okne nastavení (v používateľskom menu zaškrtnite „Nastavenia Preukázateľne spravodlivé“ a potom „Ďalšie jadro servera SHA256“). Tu môžete zmeniť aj jadro vášho klienta. Ak sa chcete zúčastniť na generovaní výsledkov kola, uistite sa, že ste medzi prvými 3 hráčmi, ktorí v danom kole uzavreli stávku.',
'provably_fair_7_paragraph': 'Správnosť hashu je možné skontrolovať v akejkoľvek online kalkulačke, napr.',
'provably_fair_system': 'preukázateľne spravodlivý systém',
'quick_explanation': 'Rýchle vysvetlenie',
'randomly_generated_server_hash': 'Náhodne vygenerovaný HASH servera',
'rank': 'POZÍCIA',
'raw_multiplier': 'hrubý násobič',
'raw_multiplier_to_edged': 'Hrubý násobič na hranatý násobič pomocou',
'remove_additional_bet': 'Odstrániť dodatočnú stávku',
'reset': 'Resetovať',
'return_to_player': 'NÁVRATNOSŤ HRÁČA',
'rocketman_is_pure_excitement': 'Rocketman je číre vzrušenie! Riskujte a vyhrajte.',
'rocketpot': 'Rocketpot',
'rocketpot_winners': 'Výhercovia rocketpot-u',
'round': 'Kolo',
'round_id': 'ID kola',
'round_multiplier': 'Násobič kola',
'round_results': 'výsledky kola',
'rounds': 'kolá',
'rtp': 'RTP',
'rules': 'pravidlá',
'rules_details': 'Pravidlá turnaja',
'save': 'Uložiť',
'schedule': 'program',
'second': 'sekunda',
'seconds_left': 'zostáva',
'server_hash': 'HASH servera',
'please_wait_current_bets': 'POČKAJTE NA DOKONČENIE AKTUÁLNYCH STÁVOK',
'min_autocashout_value_must_be': 'MIN. HODNOTA AUTOMATICKÉHO VÝBERU HOTOVOSTI MUSÍ BYŤ',
'min_bet_value_must_be': 'MIN. VÝŠKA STÁVKY MUSÍ BYŤ',
'server_seed': 'Jadro servera',
'session_dissconnected': 'Relácia odpojená',
'multisession_not_allowed': 'Multisession nie je povolená',
'set_auto_bet': 'NASTAVIŤ AUTOMATICKÚ STÁVKU',
'set_auto_cashout': 'NASTAVIŤ AUTOMATICKÝ VÝBER HOTOVOSTI',
'shourtcut_keys': 'KLÁVESNICOVÉ SKRATKY',
'show_hex_to_deimal': 'Odkryť hex do desiatkovej sústavy',
'sound': 'ZVUK',
'sound_settigs': 'Nastavenia zvuku',
'start_with_autoplay': 'Začať s automatickou hrou',
'stop_autoplay': 'ZASTAVIŤ AUTOMATICKÚ HRU',
'stop_on_loss': 'ZASTAVIŤ NA STRATU',
'stop_on_loss_tooltip': 'Použite zastaviť na stratu pre zastavenie automatickej stávky po strate určitej sumy.<br/> Napríklad, ak začnete so 100 $ a nastavíte zastaviť na stratu na 25 $',
'stop_on_profit': 'ZASTAVIŤ NA ZISK',
'stop_on_profit_tooltip': 'Použite zastaviť na zisk pre zastavenie automatickej stávky po zisku určitej sumy.<br/> Napríklad, ak začnete so 100 $ a nastavíte zastaviť na zisk na 25 $',
'there_are_no_active_torunament': 'V tejto chvíli nie sú k dispozícii žiadne aktívne turnaje.',
'third': 'tretia',
'this_game_is': 'Táto hra je',
'this_tool_recalculates': 'Tento nástroj prepočíta násobič z herného hashu. Pre overenie násobiča môžete skopírovať/vložiť akýkoľvek herný hash.',
'time': 'ČAS',
'time_frame': 'Časový rámec',
'to': 'NA',
'toggle_animation': 'Zapnúť animácie',
'top_odds': 'Top kurz',
'top_wins': 'Top výhry',
'total_bets': 'Celkové stávky',
'tournament': 'Turnaj',
'tournament_information': 'Informácie o turnaji',
'try_advanced_autoplay': 'Vyskúšajte novú pokročilú funkciu automatickej hry! ',
'uncredited_wins': 'Nepripísané výhry',
'user': 'POUŽÍVATEĽ',
'user_and_points': 'POUŽÍVATEĽ & BODY',
'username': 'Používateľské meno',
'username_must_be_at_least': 'Používateľské meno musí byť aspoň',
'verify': 'Overiť',
'vip_popup_text': 'Môžete si vybrať jeden zo špeciálnych VIP avatarov, ktoré sú k dispozícii výhradne pre našich VIP členov!',
'vip_popup_title': 'Gratulujeme, že ste sa stali VIP hráčom',
'waiting_for_the_next_round': 'Čakáme na ďalšie kolo...',
'warnings_are_calculated': ' • Výhry sú vypočítané vynásobením stávkového vkladu násobičom v okamihu výberu hotovosti',
'watch_take_off': 'Sledujte, ako vaša šťastná raketa vzlietne a vaše výhry sa zvýšia.',
'watch_the_rocket': 'Sledujte raketu',
'we_have_noticed': '„Všimli sme si,že hru „Rocketman“ hráte prvýkrát. Chceli by ste si pozrieť krátke video s vysvetlením, ako ju hrať?',
'week': 'Týždeň',
'welcome_to_rocketman': 'Vitajte v hre Rocketman - novej a vzrušujúcej hre! V hre Rocketman máte šancu vyhrať až 20 000-násobok pôvodnej stávky.',
'what_is_provably_fair': 'Čo je preukázateľne spravodlivé?',
'will_be_deposited': 'Bude pripísaný na zostatok hráčov s prvou obyčajnou výhrou',
'win': 'VÝHRA',
'win_amount': 'Výška výhry',
'win_gifs': 'VÝHRA',
'winner': 'výherca',
'wins': 'vyhráva',
'write_a_replay': 'Napíšte odpoveď...',
'year': 'Rok',
'yes': 'ÁNO',
'yes_start_the_round': 'Áno, začať kolo',
'you_have_cashed_out': 'Vybrali ste peniaze, vaša výhra je ',
'you_have_won': 'VYHRALI STE',
'your_bet_is_lost': 'vaša stávka prehrala.',
'current': 'Aktuálna',
'previous': 'Predošlá',
'claim': 'Vyžiadať',
'this_feature_will_give': 'Táto funkcia pridelí vybranú sumu náhodným používateľom v chate',
'amount_per_player': 'Čiastka na hráča',
'no_of_players': 'Počet hráčov',
'meteor_shower': 'Meteorický dážď',
'claim_your_free_bet': 'Vyžiadať svoju stávku zdarma',
'meteor_shower_total': 'Meteorický dážď celkovo',
'shower': 'DÁŽĎ',
'total': 'Celkovo',
'meteor_shower_incoming': 'Prichádza meteorický dážď, pozrite sa na chat!',
'insurance': 'POISTENIE',
'insurance_paragraph': 'Hráči si môžu zakúpiť poistenie pre svoju stávku kliknutím na políčko „Poistenie stávky“ vedľa tlačidla stávky. Poistenie stojí 10 % z celkovej sumy stávky a v prípade výbuchu rakety s násobičom 1,00 vráti hráčovi stavenú sumu.',
'insure_your_bet': 'POISTIŤ SI STÁVKU',
'bet_insurance_promo_text': 'Výberom možnosti „Poistenie stávky“ poistíte svoju stávku proti zrúteniu rakety s násobičom 1,00.',
'bet_insurance_promo_text_helper': 'Viac informácií nájdete v časti „Pravidlá hry“...',
'game_explanation_1': 'Vyberte si svoj násobič a ak násobič v hre presiahne vami zvolený násobič, vaša výhra bude',
'game_explanation_2': 'Váš násobič x vaša stávka',
'retail_max_mp_1': 'Vyhrajte až do',
'retail_max_mp_2': 'vašej stávky!',
'play_multibets': 'Hrať',
'multibets': 'MULTI STÁVKY',
'bonus_game': 'BONUSOVÁ HRA',
'all_wins_doubled': 'Všetky výhry sú zdvojnásobené!',
'multibets_paragraph_1': 'na zvýšenie svojej šance!',
'multibets_paragraph_2': 'Stávky až na desať kôl môžete spojiť do jednej kombinovanej stávky a vyhrať vysoké výplaty!',
'multibets_paragraph_3': 'Zakaždým, keď vyhráte kolo, výherná dividenda z tohto kola sa vsadí na ďalšie kolo, až kým nie sú všetky kolá výherné.',
'bonus_launch': 'Vyhrajte x2 na všetky stávky v kole „bonusové spustenie“!',
'multiplier_history': 'História násobiča',
'will_start_soon': 'sa začne čoskoro',
'hurry_up_and_place_your_bets': 'Poponáhľajte sa a uzavrite stávky!',
'active_bets': 'Aktívne stávky',
'shop': 'OBCHOD',
'game_meteor_shower_paragraph': 'Meteorický dážď udeľuje jednu stávku zdarma v stanovenej hodnote vopred určenému počtu hráčov. Môže ho iniciovať server alebo hráč. Po aktivácii meteorického dažďa sa v hre zobrazí správa, ktorá používateľov odkáže, aby sa pozreli do chatu, kde sa správa ohľadom meteorického dažďa nachádza. Hráči môžu požiadať o stávku zdarma kliknutím na tlačidlo „Vyžiadať“. Stávky zdarma získajú hráči, ktorí kliknú na tlačidlo „Vyžiadať“ ako prví. Používanie automatických kliknutí alebo akéhokoľvek automatizačného softvéru na napodobňovanie kliknutí hráčov a získavanie výhier je zakázané a môže viesť k zákazu používania tejto funkcie.',
'claming_failed': 'Vyžiadanie zlyhalo',
'player_on_blacklist': 'Hráč je na blackliste',
'you_can_claim_ticket': 'O tiket môžete požiadať iba vtedy, ak ste aktívnym hráčom s pravidelnými stávkami',
'you_already_have_free_tickets': 'Už máte tikety zdarma, prosím, najkôr ich použite',
'please_play_the_game_in_portait_mode': 'Prosím, hrajte hru v režime na výšku.',
};
